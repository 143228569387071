import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ChunkSettings, FileRestrictions, SuccessEvent, UploadEvent,ErrorEvent } from '@progress/kendo-angular-upload';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { Manageattachementservice } from 'src/app/common/services/manageattachement/manageattachement.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-manage-attachment',
  templateUrl: './add-manage-attachment.component.html',
  styleUrls: ['./add-manage-attachment.component.scss']
})
export class AddManageAttachmentComponent implements OnInit {

  constructor(private manageattachementservice: Manageattachementservice,
    public toastr: ToastrManager,private globalService:GlobalService,private router: Router,public dialog: DialogRef,) 
    {
      
     }
  public warnondelete: boolean = false;
  public deleteType: any = 'Soft Delete';
  isAdmin: boolean = false;
  @Input() public  lineItemID:any;
  @Input() public SupplementaryID:any;
  message :string='Please Upload File';
  ngOnInit(): void {
    this.loadUploadURL();
  }

  headers: any;
  headersObj: any;
  private apifolder: string = '';
  private baseUrl: string = '';
  private selectedClient: any;
  uploadSaveUrl: string = '';
  uploadRemoveUrl: string = '';
  withCredential: boolean = false;
  @Input() public fiscalYear:any;
  public FileStatus:any;
  public ErrorFileStatus:any;
  AdditionalFileRestrictions: FileRestrictions = {
    allowedExtensions: ['.xls', '.xlsx','.doc','.docx','.pdf','.ppt','.pptx'],
    maxFileSize: 10485760,
  };

  public chunkSettings: ChunkSettings = {
    size: 1048576,
  };
  
  loadUploadURL()
  {
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    let tokenValue: any = localStorage.getItem('OPENAMSESSION');
    this.headers = {
      'Authorization-Token':
        'Bearer ' +
        JSON.parse(tokenValue).access_token,
    };
    let selected: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selected);
    this.uploadSaveUrl =
      this.baseUrl +
      this.apifolder +
      '/AnalysisTreeView/UploadSupplementaryFile?ClientdbName=' +
      this.selectedClient.DBName+'&lineItemID='+this.lineItemID+'&fiscalYear='+this.fiscalYear;

    this.uploadRemoveUrl = 'removeUrl';
  }

  cancelClick() {
    this.warnondelete=false;
    this.dialog.close();
  }
  uploadEventHandler(e: UploadEvent) {
    let tokenValue:any;
    tokenValue=localStorage.getItem('OPENAMSESSION');
   e.headers = e.headers.append(
     'Authorization-Token',
     'Bearer ' +
     JSON.parse(tokenValue).access_token
   );
 }

 public success(e: SuccessEvent): void {
  if (e.files.length > 0) {
    if (e.response.status==200) {
      this.warnondelete=false;
      this.dialog.close();
      this.toastr.successToastr('File upload successfully.', 'Success');
      this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
    }
  }
}

public error(e: ErrorEvent): void {
  if(e.response.status==800)
    this.ErrorFileStatus='The file name should not be longer than 50 characters.';
  
}

}
