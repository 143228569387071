<kendo-dialog-titlebar (click)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Delete line item</div>
</kendo-dialog-titlebar>
<div style="display: flex">
  <p>
    <span
      class="Appkit4-icon icon-circle-warning-outline"
      style="font-size: 24px; color: #d04a02"
    ></span>
  </p>
  <p style="margin-left: 10px; font-size: medium">
    You have selected to delete the selected line item(s) <br />
    <br />
    Are you sure you wish to continue?
  </p>
</div>
<!-- Click to Actions -->
<div class="clickToActions" style="padding: 10px 0px 0px 0px">
  <ap-button
    [btnType]="'secondary'"
    [label]="'No'"
    (click)="cancelClick()"
    class="cancelButton"
    style="width: 100px"
  ></ap-button>
  <ap-button
    [btnType]="'primary'"
    [label]="'Yes'"
    themeColor="primary"
    (click)="deleteLineItem()"
    class="okButton"
    style="margin-right: 6px"
  ></ap-button>
</div>

<kendo-dialog
  title="Delete line item"
  *ngIf="warnondelete"
  (close)="this.dialog.close()"
  [minWidth]="250"
  [width]="500"
>
  <div style="display: flex">
    <p>
      <span
        class="Appkit4-icon icon-circle-warning-outline"
        style="font-size: 24px; color: #d04a02"
      ></span>
    </p>
    <p style="margin-left: 10px; font-size: medium; white-space: pre-line">
      {{ responceData }}
    </p>
  </div>

  <div class="clickToActions" *ngIf="isInfo" style="padding: 10px 0px 0px 0px">
    <ap-button
      [btnType]="'primary'"
      [label]="'OK'"
      themeColor="primary"
      class="okButton"
      (click)="this.dialog.close()"
    ></ap-button>
  </div>
  <div class="clickToActions" *ngIf="!isInfo" style="padding: 10px 0px 0px 0px">
    <ap-button
      [btnType]="'secondary'"
      [label]="'No'"
      (click)="this.dialog.close()"
      class="cancelButton"
      style="width: 100px"
    ></ap-button>
    <ap-button
      [btnType]="'primary'"
      [label]="'Yes'"
      themeColor="primary"
      (click)="deleteLinkLineItem()"
      class="okButton"
      style="margin-right: 6px"
    ></ap-button>
  </div>
</kendo-dialog>
