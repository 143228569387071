import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActionsLayout,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { AnalysisEditTaxaccountComponent } from '../analysis-line-item/analysis-edit-taxaccount/analysis-edit-taxaccount.component';
@Component({
  selector: 'app-display-account-popup',
  templateUrl: './display-account-popup.component.html',
  styleUrls: ['./display-account-popup.component.scss'],
})
export class DisplayAccountPopupComponent implements OnInit {
  @Input() LineitemNode: any;
  AccountingAreaName: any;
  TaxAccountCodeName: any;

  constructor(
    private globalService: GlobalService,
    private dialogService: DialogService,
    public dialog: DialogRef,
    private router: Router,
    public toastr: ToastrManager
  ) {}
  public statementDesc: string = '';
  public linkItemData: any = [];
  public filterlinkItemData: any = [];
  public node: any;
  public selectedCallback = (args: any) => args.dataItem;
  public mySelectionScope: string[] = [];
  public actionsLayout: ActionsLayout = 'normal';
  @Input() data: any;
  ngOnInit(): void {
    this.AccountingAreaName = this.LineitemNode.name;
    this.TaxAccountCodeName = this.data;
  }

  cancelClick() {
    this.dialog.close('onCancel');
  }
  edit() {
    this.dialog.close(this.mySelectionScope[0]);

    //   const dialogRef = this.dialogService.open({
    //     content: AnalysisEditTaxaccountComponent,
    //     width: 950,
    //     height:595
    //   });

    // const newTaxAccount = dialogRef.content.instance as AnalysisEditTaxaccountComponent;
    // dialogRef.result.subscribe((r: any) => {
    // });
    // newTaxAccount.gridBusinessType=node.BusinessTypeID;
    // console.log( newTaxAccount.gridBusinessType=node.BusinessTypeID,"BusinessIdacd")
    // // newTaxAccount.Satement=node.Satementid;
    // // newTaxAccount.BlockId=node.id;
    // // newTaxAccount.gridBusinessType = formObject.businessTypeId;
    // // console.log(   newTaxAccount.gridBusinessType = formObject.businessTypeId,"gridBusinessType")
    // // newTaxAccount.gridBusinessType = formObject.businessTypeId;
    // newTaxAccount.isCreateFromLI=true;
    // newTaxAccount.LICreateTaxData=node;
    // newTaxAccount.TaxAccountCodeId=response[0].TaxAccountCodeId;
    // console.log(response[0])
    // console.log(newTaxAccount.LICreateTaxData=node,"IdLICreateTaxData")
  }
}
