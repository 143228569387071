import { Component, OnInit, Input } from '@angular/core';
import {
  DialogContentBase,
  ActionsLayout,
  DialogAction,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from '@progress/kendo-angular-dialog';
import { StatutoryTreatmentService } from 'src/app/common/services/statutorytreatment/statutorytreatment.service';

@Component({
  selector: 'app-delete-statutorytreatment',
  templateUrl: './delete-statutorytreatment.component.html',
  styleUrls: ['./delete-statutorytreatment.component.scss'],
})
export class DeleteStatutorytreatmentComponent
  extends DialogContentBase
  implements OnInit
{
  @Input() public delStatutoryTreatmentdata: any;

  dialogTitle: any = "Delete Statutory Treatment's";
  confirmText: any;
  warnondelete: boolean = false;
  public actionsLayout: ActionsLayout = 'normal';

  delStatutoryTreatmentDetails: any;
  constructor(
    public dialog: DialogRef,
    public toastr: ToastrManager,
    private dialogService: DialogService,
    private statutoryTreatmentService: StatutoryTreatmentService
  ) {
    super(dialog);
  }

  ngOnInit(): void {}

  cancelClick() {
    this.dialog.close();
  }

  deleteStatutoryTreatment() {
    this.statutoryTreatmentService
      .deleteStatutoryTreatment(this.delStatutoryTreatmentdata.Id)
      .subscribe(
        (res: any) => {
          if (res == 'Statutory Treatment is deleted successfully') {
            this.toastr.successToastr(
              'Statutory treatment is deleted successfully',
              'Success'
            );
            this.dialog.close();
          } else if (res == 'Unable to delete Statutory Treatment(s)') {
            this.toastr.warningToastr(
              'Unable to delete Statutory Treatment(s)',
              'Warning!'
            );
            // this.warnondelete=true;
            this.dialog.close();
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
            this.dialog.close();
          }
        },
        (error) => {
          console.log(error);
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.dialog.close();
        }
      );
  }
}
