import { Component, OnInit } from '@angular/core';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { DragAndDropScrollSettings } from '@progress/kendo-angular-treeview';
// import { FilterExpression } from "@progress/kendo-angular-filter";
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { TreeViewFilterSettings } from '@progress/kendo-angular-treeview';
import { forkJoin, Observable } from 'rxjs';
import { SelectableSettings } from '@progress/kendo-angular-treeview';

import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import {
  TreeItemDropEvent,
  DropPosition,
  TreeItem,
} from '@progress/kendo-angular-treeview';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { UploadglaccountComponent } from './uploadglaccount/uploadglaccount.component';
@Component({
  selector: 'app-map-gl-account',
  templateUrl: './map-gl-account.component.html',
  styleUrls: ['./map-gl-account.component.scss'],
})
// export class MapGlAccountComponent  {

//   public allowCopy = true;

//   public furnishing: any[] = [
//     {
//       id: 1,
//       text: "Furniture",
//       items: [
//         { id: 2, text: "Tables & Chairs" },
//         { id: 3, text: "Sofas" },
//         { id: 4, text: "Occasional Furniture" },
//       ],
//     },
//     {
//       id: 5,
//       text: "Decor",
//       items: [
//         { id: 6, text: "Bed Linen" },
//         { id: 7, text: "Curtains & Blinds" },
//         { id: 8, text: "Carpets" },
//       ],
//     },
//   ];
//   public furnishing1: any[] = [
//     {
//       id: 1,
//       text: "Furniture",
//       items: [
//         { id: 2, text: "Tables & Chairs",items: [
//           { id: 2, text: "Tables & Chairs" },
//           { id: 3, text: "Sofas" },
//           { id: 4, text: "Occasional Furniture" },
//         ], },
//         { id: 3, text: "Sofas" },
//         { id: 4, text: "Occasional Furniture" },
//       ],
//     },
//     {
//       id: 5,
//       text: "Decor",
//       items: [
//         { id: 6, text: "Bed Linen" },
//         { id: 7, text: "Curtains & Blinds" },
//         { id: 8, text: "Carpets" },
//       ],
//     },
//   ];
//   red="red"
//   public maintenance: any[] = [
//     {
//       id: 9,
//       text: "Storage",
//       items: [
//         { id: 10, text: "Wall Shelving" },
//         { id: 11, text: "Floor Shelving" },
//         { id: 12, text: "Kids Storage" },
//       ],
//     },
//     {
//       id: 13,
//       text: "Lights",
//       items: [
//         { id: 14, text: "Ceiling" },
//         { id: 15, text: "Table" },
//         { id: 16, text: "Floor" },
//       ],
//     },
//   ];

//   list: [] = [];
// aaa(eve: Event){
//   console.log("fsbdfhdsfhsdvfvsd")

// }

// public myFilterSettings: TreeViewFilterSettings = {
//   ignoreCase: false,
//   operator: "startswith",
// };
// checkedKeys:any
// mainIds: any[] = [];
// public onFocus(): void {
//   this.mainIds=[]
//   this.furnishing.forEach(ele=>{
//     this.mainIds.push(ele.id)
//   })
//   console.log("TreeView is focused",this.checkedKeys);
//   let items: any[]=[];
//   this.furnishing1.forEach((ele1: any)=>{
//       ele1.items.forEach((ele: { id: any; })=>{
//         let val=0;
//          if(ele.id==this.checkedKeys[0]){
//           items=ele1.items
//       }})})
//       let exists=false;
//       items.forEach((ele: any)=>{
//         if(ele.id==this.checkedKeys[this.checkedKeys.length-1])
//         exists=true;
//       })
//       if(exists==false && !this.mainIds.includes(this.checkedKeys[this.checkedKeys.length-1])){
//         this.checkedKeys.pop(this.checkedKeys[this.checkedKeys.length-1])
//         this.checkedKeys=[]
//       }
// }
// aaa1(eve:Event ){
//   console.log("fsbdfhdsfhsdvfvsd")
//   // if(this.list.)
// }
// public onSelectionChange(event: TreeItem): void {
//   console.log("selectionChange", event);
//   this.checkedKeys.push(event.dataItem.id)
// }
// public scrollEnabled = true;
//     public scrollStep = 1;
//     public scrollInterval = 1;

//     public get scrollSettings(): DragAndDropScrollSettings {
//         return {
//             enabled: this.scrollEnabled,
//             step: this.scrollStep || 1,
//             interval: this.scrollInterval || 1
//         };
//     }

//   public handleDrop(event: TreeItemDropEvent): void {
//     this.mainIds=[]
//   this.furnishing.forEach(ele=>{
//     this.mainIds.push(ele.id)
//   })
//   if(this.mainIds.includes(event.sourceItem.item.dataItem.id))
//   {
//     event.sourceItem.item.dataItem.items.forEach((ele: { id: any; })=>{

//             if (this.allowCopy ) {
//               event.preventDefault();

//               // clone the item and its children and assign them new IDs
//               const itemWithNewId = this.assignNewIds(
//                 ele,
//                 "id",
//                 "items"
//               );

//               // if the target is an empty placeholder, remove it and push the new item to the destination tree nodes
//               if (event.destinationItem.item.dataItem.placeholder) {
//                 const placeholderItemIndex = event.destinationTree.nodes.indexOf(
//                   event.destinationItem.item.dataItem
//                 );
//                 event.destinationTree.nodes.splice(
//                   placeholderItemIndex,
//                   1,
//                   itemWithNewId
//                 );
//                 return;
//               }

//               // manually insert the new item and its children at the targeted position
//               if (event.dropPosition === DropPosition.Over) {
//                 event.destinationItem.item.dataItem.items =
//                   event.destinationItem.item.dataItem.items || [];
//                 event.destinationItem.item.dataItem.items.push(itemWithNewId);

//                 if (
//                   !event.destinationTree.isExpanded(
//                     event.destinationItem.item.dataItem,
//                     event.destinationItem.item.index
//                   )
//                 ) {
//                   event.destinationTree.expandNode(
//                     event.destinationItem.item.dataItem,
//                     event.destinationItem.item.index
//                   );
//                 }
//               } else {
//                 const parentChildren: any[] = event.destinationItem.parent
//                   ? event.destinationItem.parent.item.dataItem.items
//                   : event.destinationTree.nodes;

//                 const shiftIndex = event.dropPosition === DropPosition.After ? 1 : 0;
//                 const targetIndex =
//                   parentChildren.indexOf(event.destinationItem.item.dataItem) +
//                   shiftIndex;

//                 parentChildren.splice(targetIndex, 0, itemWithNewId);
//               }
//             }

//         })
//   }
//    else if(this.checkedKeys.length>1){
//       this.checkedKeys.forEach((element: any) => {
//         this.furnishing1.forEach((ele1: any)=>{
//           ele1.items.forEach((ele: { id: any; })=>{

//           if(ele.id==element){
//             if (this.allowCopy ) {
//               event.preventDefault();

//               // clone the item and its children and assign them new IDs
//               const itemWithNewId = this.assignNewIds(
//                 ele,
//                 "id",
//                 "items"
//               );

//               // if the target is an empty placeholder, remove it and push the new item to the destination tree nodes
//               if (event.destinationItem.item.dataItem.placeholder) {
//                 const placeholderItemIndex = event.destinationTree.nodes.indexOf(
//                   event.destinationItem.item.dataItem
//                 );
//                 event.destinationTree.nodes.splice(
//                   placeholderItemIndex,
//                   1,
//                   itemWithNewId
//                 );
//                 return;
//               }

//               // manually insert the new item and its children at the targeted position
//               if (event.dropPosition === DropPosition.Over) {
//                 event.destinationItem.item.dataItem.items =
//                   event.destinationItem.item.dataItem.items || [];
//                 event.destinationItem.item.dataItem.items.push(itemWithNewId);

//                 if (
//                   !event.destinationTree.isExpanded(
//                     event.destinationItem.item.dataItem,
//                     event.destinationItem.item.index
//                   )
//                 ) {
//                   event.destinationTree.expandNode(
//                     event.destinationItem.item.dataItem,
//                     event.destinationItem.item.index
//                   );
//                 }
//               } else {
//                 const parentChildren: any[] = event.destinationItem.parent
//                   ? event.destinationItem.parent.item.dataItem.items
//                   : event.destinationTree.nodes;

//                 const shiftIndex = event.dropPosition === DropPosition.After ? 1 : 0;
//                 const targetIndex =
//                   parentChildren.indexOf(event.destinationItem.item.dataItem) +
//                   shiftIndex;

//                 parentChildren.splice(targetIndex, 0, itemWithNewId);
//               }
//             }
//           }
//         })
//         })

//       });
//       console.log(this.checkedKeys)
//       this.checkedKeys=[]
//       console.log(this.furnishing)
//     }
//     // prevent the default to prevent the drag-and-drop directive from emitting `addItem` and inserting items with duplicate IDs
//     else if (this.allowCopy ) {
//       event.preventDefault();

//       // clone the item and its children and assign them new IDs
//       const itemWithNewId = this.assignNewIds(
//         event.sourceItem.item.dataItem,
//         "id",
//         "items"
//       );

//       // if the target is an empty placeholder, remove it and push the new item to the destination tree nodes
//       if (event.destinationItem.item.dataItem.placeholder) {
//         const placeholderItemIndex = event.destinationTree.nodes.indexOf(
//           event.destinationItem.item.dataItem
//         );
//         event.destinationTree.nodes.splice(
//           placeholderItemIndex,
//           1,
//           itemWithNewId
//         );
//         return;
//       }

//       // manually insert the new item and its children at the targeted position
//       if (event.dropPosition === DropPosition.Over) {
//         event.destinationItem.item.dataItem.items =
//           event.destinationItem.item.dataItem.items || [];
//         event.destinationItem.item.dataItem.items.push(itemWithNewId);

//         if (
//           !event.destinationTree.isExpanded(
//             event.destinationItem.item.dataItem,
//             event.destinationItem.item.index
//           )
//         ) {
//           event.destinationTree.expandNode(
//             event.destinationItem.item.dataItem,
//             event.destinationItem.item.index
//           );
//         }
//       } else {
//         const parentChildren: any[] = event.destinationItem.parent
//           ? event.destinationItem.parent.item.dataItem.items
//           : event.destinationTree.nodes;

//         const shiftIndex = event.dropPosition === DropPosition.After ? 1 : 0;
//         const targetIndex =
//           parentChildren.indexOf(event.destinationItem.item.dataItem) +
//           shiftIndex;

//         parentChildren.splice(targetIndex, 0, itemWithNewId);
//       }
//     }

//   }

//   // recursively clones and assigns new ids to the root level item and all its children
//   private assignNewIds(item: any, idField: string, childrenField: string): any {
//     const result = Object.assign({}, item, { [idField]: Math.random() });

//     if (result[childrenField] && result[childrenField].length) {
//       result[childrenField] = result[childrenField].map((childItem: any) =>
//         this.assignNewIds(childItem, idField, childrenField)
//       );
//     }

//     return result;
//   }

// }
export class MapGlAccountComponent {
  public furnishing0: any[] = [];
  public allowCopy = true;
  public filtered = false;
  public filtered1 = false;
  public checkedKeys: string[] = [];
  public checkMode1 = true;
  public checkMode2 = false;
  isLoading: boolean = false;
  public furnishing: any[] = [];
  checkboxSelected = false;
  checkboxDisabled = false;
  showCheckboxLabel = true;
  tempState = false;
  isNodeSelected: boolean = true;

  // myFunction(){
  //     // // console.log("Search String : ",this.searchString)
  //     if(this.checkMode1){
  //         this.handleFilter(this.searchString)
  //     }else{
  //         this.handleFilter1(this.searchString)
  //     }
  // }
  change1() {
    this.checkMode1 = !this.checkMode1;
    this.checkMode2 = !this.checkMode2;
  }
  // change2(){
  //     return  (!this.checkMode1 && !this.checkMode2)
  //  }
  expand: any[] = [];
  public filterTerm = '';
  public actionsLayout: ActionsLayout = 'normal';
  constructor(
    private globalService: GlobalService,
    private dialogService: DialogService
  ) {
    for (let i = 1; i < 2000; i++) {
      this.expand.push(i);
    }
    this.filter1 = '';
    this.filter2 = '';
    this.filter = null;
    this.sort = 'Account code';
    this.expand = [this.expand];

    this.getRightTree(0);
  }
  IsMapped = 0;
  getRightTree(id: any) {
    this.globalService.getAllMappingAccount(this.checked).then(
      (response: any) => {
        this.maintenance = response;
        // this.furnishing0= this.furnishing;
        this.parsedData1 = this.maintenance;
        this.mainIds = [];
        this.parsedData1.forEach((ele) => {
          this.mainIds.push(ele.id);
        });
        if (id == 0) {
          this.getLeftTree('', null, this.parsedData1[0].BusinessTypeID);
          this.getAz(this.parsedData1[0].BusinessTypeID);
        }

        if (this.singleExpand.length == 0) {
          if (localStorage.getItem('expendedforUploadMap')) {
            let localVal: any = localStorage.getItem('expendedforUploadMap');

            this.singleExpand[0] = JSON.parse(localVal);
            localStorage.removeItem('expendedforUploadMap');
          } else {
            this.singleExpand.push(this.parsedData1[0].id);
            this.parsedData1[0].items.forEach((element: any) => {
              this.singleExpand.push(element.id);
              // this.nestedCode(element)
            });
            this.singleExpand = [this.singleExpand];
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  az: any;
  azDrp: any[] = [];
  getAz(id: any) {
    this.globalService.getazDroplist(id).then(
      (response: any) => {
        this.az = response;

        this.azDrp = [];
        this.azDrp.push('A-Z');
        this.az.forEach((ele: any) =>
          this.azDrp.push({ text: ele.Description, id: ele.StatementGroupId })
        );
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  filter: any;
  sort: any;
  public onItemClickFilter(eve: any): void {
    if (eve.text == 'Mapped') this.filter = true;
    else if (eve.text == 'All') this.filter = null;
    else this.filter = false;
    if (this.mainBusinessId == undefined)
      this.getLeftTree(
        this.filter,
        this.sort,
        this.parsedData[0].BusinessTypeID
      );
    else this.getLeftTree(this.filter, this.sort, this.mainBusinessId);
  }
  isAz = false;
  public onItemClickDrp(eve: any) {
    this.isAz = false;
    if (eve == 'A-Z') {
      this.getRightTree(0);
    } else {
      if (this.mainBusinessId == undefined)
        this.mainBusinessId = this.parsedData1[0].BusinessTypeID;
      this.globalService.getAZ(this.mainBusinessId, eve.id, this.checked).then(
        (response: any) => {
          this.maintenance = response;
          // this.furnishing0= this.furnishing;
          this.parsedData1 = this.maintenance;
          this.mainIds = [];
          this.parsedData1.forEach((ele) => {
            this.mainIds.push(ele.id);
          });
          this.isAz = true;

          if (this.singleExpand.length == 0) {
            this.singleExpand.push(this.parsedData1[0].id);
            this.parsedData1[0].items.forEach((element: any) => {
              this.singleExpand.push(element.id);
              // this.nestedCode(element)
            });
            this.singleExpand = [this.singleExpand];
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }
  public onItemClickDrpNew(eve: any) {
    if (this.mainBusinessId == undefined)
      this.mainBusinessId = this.parsedData1[0].BusinessTypeID;
    this.globalService.getAZ(this.mainBusinessId, eve.id, this.checked).then(
      (response: any) => {
        this.maintenance = response;
        // this.furnishing0= this.furnishing;
        this.parsedData1 = this.maintenance;
        this.mainIds = [];
        this.parsedData1.forEach((ele) => {
          this.mainIds.push(ele.id);
        });
        this.isAz = true;

        if (this.singleExpand.length == 0) {
          this.singleExpand.push(this.parsedData1[0].id);
          this.parsedData1[0].items.forEach((element: any) => {
            this.singleExpand.push(element.id);
            // this.nestedCode(element)
          });
          this.singleExpand = [this.singleExpand];
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  collapseAll() {
    this.singleExpand[0] = [];
    this.isNodeSelected = false;
  }
  expandAllData() {
    this.singleExpand = [];
    let a;
    this.parsedData1.forEach((ele) => {
      ele.items.forEach((ele1: any) => {
        this.singleExpand.push(ele1.id);
        ele1.items.forEach((ele2: any) => {
          this.singleExpand.push(ele2.id);
          ele2.items.forEach((ele3: any) => {
            this.singleExpand.push(ele3.id);
            ele3.items.forEach((ele4: any) => {
              this.singleExpand.push(ele4.id);
            });
          });
        });
      });
      this.singleExpand.push(ele.id);

      this.isLoading = true;
    });
    this.isLoading = false;
    this.singleExpand = [this.singleExpand];
  }

  expandAll() {
    this.isNodeSelected = true;
    this.singleExpand = [];
    let a;
    this.parsedData1.forEach((ele) => {
      if (this.mainBusinessId == undefined)
        a = this.parsedData1[0].BusinessTypeID;
      else a = this.mainBusinessId;
      if (ele.BusinessTypeID == a) {
        ele.items.forEach((ele1: any) => {
          this.singleExpand.push(ele1.id);
          ele1.items.forEach((ele2: any) => {
            this.singleExpand.push(ele2.id);
            ele2.items.forEach((ele3: any) => {
              this.singleExpand.push(ele3.id);
              ele3.items.forEach((ele4: any) => {
                this.singleExpand.push(ele4.id);
              });
            });
          });
        });
        this.singleExpand.push(ele.id);
      }
      this.isLoading = true;
    });
    this.isLoading = false;
    this.singleExpand = [this.singleExpand];
  }
  public onItemClick(eve: any): void {
    this.sort = eve.text;
    if (this.mainBusinessId == undefined)
      this.getLeftTree(
        this.filter,
        this.sort,
        this.parsedData[0].BusinessTypeID
      );
    else this.getLeftTree(this.filter, this.sort, this.mainBusinessId);
  }
  getLeftTree(filter: any, sort: any, business: any) {
    if (filter == undefined || filter == null) filter = '';
    if (sort == undefined) sort = null;
    if (business == undefined) business = this.parsedData1[0].BusinessTypeID;

    this.globalService.getAllLineItemsDetails(filter, sort, business).then(
      (response: any) => {
        this.furnishing = [response];
        this.furnishing0 = this.furnishing;
        this.parsedData = this.furnishing;

        this.disabledKeys = [];
        if (this.parsedData[0] != undefined)
          this.parsedData[0].items.forEach((ele1: any) => {
            if (ele1.items != undefined)
              ele1.items.forEach((ele2: any) => {
                if (ele2.items != undefined)
                  ele2.items.forEach((ele3: any) => {
                    ele3.items.forEach((ele4: any) => {
                      if (ele4.IsMapped) {
                        if (ele4.text != undefined)
                          this.disabledKeys.push(ele4.text);
                      }
                    });
                  });
              });
          });
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  mainIds: any[] = [];
  public checkedItems: Array<String> = [];
  public checkedItems1: Array<String> = [];
  public sortList: any[] = [
    {
      text: 'Account code',
    },
    {
      text: 'Account name',
    },
  ];
  public view: any[] = [
    {
      text: 'Unmapped',
    },
    {
      text: 'Mapped',
    },
    {
      text: 'All',
    },
  ];
  public iconClass = 'fas fa-sort';
  public show(index: String): boolean {
    // if(this.filtered){
    //     return false;
    // }
    if (this.checkedItems.length) {
      if (this.checkedItems[0].split('_')[0] !== index.split('_')[0]) {
        return false;
      }
    }
    if (index.indexOf('_') >= 0) {
      return true;
    } else {
      return false;
    }
  }
  public keys: string[] = [];

  /**
   * A function that checks whether a given node index exists in the expanded keys collection.
   * If the index can be found, the node is marked as expanded.
   */
  public isExpanded = (dataItem: any, index: string) => {
    return this.keys.indexOf(index) > -1;
  };

  /**
   * A `collapse` event handler that will remove the node hierarchical index
   * from the collection, collapsing its children.
   */
  public handleCollapse(node: any) {
    this.keys = this.keys.filter((k) => k !== node.index);
  }

  /**
   * An `expand` event handler that will add the node hierarchical index
   * to the collection, expanding the its children.
   */
  public handleExpand(node: any) {
    this.keys = this.keys.concat(node.index);
  }

  public onChange(event: Event, index: String): void {
    const isItemChecked = this.checkedItems.indexOf(index) >= 0;

    if ((event.target as HTMLInputElement).checked && !isItemChecked) {
      this.checkedItems = [...this.checkedItems, index];
    }

    if (!(event.target as HTMLInputElement).checked && isItemChecked) {
      this.checkedItems = this.checkedItems.filter((i) => i !== index);
    }

    if (this.checkedItems.length > 1) {
      // console.log(this.checkedItems)
      let a = this.checkedItems[0].split('_')[0];
      // // console.log(index);
      for (let i = 1; i < this.checkedItems.length; i++) {
        // // console.log(this.checkedItems[i].charAt(0));
        // console.log(this.checkedItems[i].split('_')[0]!==a);
        if (this.checkedItems[i].split('_')[0] !== a) {
          // // console.log(this.checkedItems[i].charAt(0));
          this.checkedItems.splice(i);
          return;
        }
      }
      // console.log(this.checkedItems)
    }
  }
  public onChange1(event: Event, index: String): void {
    const isItemChecked = this.checkedItems1.indexOf(index) >= 0;

    if ((event.target as HTMLInputElement).checked && !isItemChecked) {
      this.checkedItems1 = [...this.checkedItems1, index];
    }

    if (!(event.target as HTMLInputElement).checked && isItemChecked) {
      this.checkedItems1 = this.checkedItems1.filter((i) => i !== index);
    }

    if (this.checkedItems1.length > 1) {
      // console.log(this.checkedItems)
      let a = this.checkedItems1[0].split('_')[0];
      // // console.log(index);
      for (let i = 1; i < this.checkedItems1.length; i++) {
        // // console.log(this.checkedItems[i].charAt(0));
        // console.log(this.checkedItems[i].split('_')[0]!==a);
        if (this.checkedItems1[i].split('_')[0] !== a) {
          // // console.log(this.checkedItems[i].charAt(0));
          this.checkedItems1.splice(i);
          return;
        }
      }
      // console.log(this.checkedItems)
    }
  }
  public isChecked1(index: String): boolean {
    if (this.checkedItems1.indexOf(index) >= 0) {
      return true;
    } else {
      return false;
    }
  }
  public isChecked(index: String): boolean {
    if (this.checkedItems.indexOf(index) >= 0) {
      return true;
    } else {
      return false;
    }
  }
  public maintenance: any[] = [];
  selectedItem: any;
  singleExpand: any[] = [];
  azData = 'A-Z';
  mainBusinessId: any;
  public onExpand(event: TreeItem): void {
    //this.singleExpand=[]
    if (event.dataItem.BusinessTypeID != undefined) {
      this.isNodeSelected = true;
      this.disabledKeys = [];
      this.mainBusinessId = event.dataItem.BusinessTypeID;
      this.getLeftTree('', null, this.mainBusinessId);

      if (this.isAz == false) {
        this.azDrp = [];
        this.azData = 'A-Z';
      } else {
        this.singleExpand.push(this.parsedData1[0].id);
      }
      this.getAz(event.dataItem.BusinessTypeID);
    }

    if (this.mainIds.includes(event.dataItem.id)) {
      this.selectedItem = event.dataItem;
      this.singleExpand = [];

      this.singleExpand.push(event.dataItem.id);
      event.dataItem.items.forEach((element: any) => {
        this.singleExpand.push(element.id);
      });
      // this.nestedCode(element)

      this.singleExpand = [this.singleExpand];
    }
    // this.nestedCode(event.dataItem)
  }
  RemoveElementFromStringArray(element: string) {
    this.disabledKeys.forEach((value, index) => {
      if (value == element) this.disabledKeys.splice(index, 1);
    });
  }
  public opened = true;
  public isResizable = true;

  public openClose(isOpened: boolean): void {
    this.opened = isOpened;
  }

  public onCollapse(event: TreeItem): void {
    if (event.dataItem.BusinessTypeID != undefined) {
      this.isNodeSelected = false;

      this.mainBusinessId = event.dataItem.BusinessTypeID;
      this.disabledKeys = [];
    }
  }
  exportData() {
    if (this.mainBusinessId == undefined)
      this.mainBusinessId = this.parsedData1[0].BusinessTypeID;
    this.isLoading = true;
    this.globalService.getExport(this.mainBusinessId).subscribe(
      (response) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const fileName = 'MapGLExport' + timestamp;
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }
  public isDisabled = (dataItem: any) => {
    return dataItem.id === 10;
  };
  public disabledKeys: any[] = [];
  public handleDrop(event: TreeItemDropEvent): void {
    if (this.allowCopy) {
      event.preventDefault();
      let itemWithNewId: any[] = [];
      let eventTemp1 = event.sourceItem.parent?.item.dataItem.text;
      let eventTemp2 = event.destinationItem.parent?.parent?.item.dataItem.text;
      if (event.sourceItem.item.dataItem.items == undefined) {
        if (event.sourceItem.item.dataItem.items) {
          // console.log("entered if loop");
          // console.log(event)
          event.sourceItem.item.dataItem.items.forEach((element: any) => {
            let item = this.assignNewIds(element, 'id', 'items');
            // console.log(element)
            itemWithNewId.push(item);
          });
        } else {
          // console.log("entered else loop");
          if (this.checkedItems.length && this.filtered) {
            // console.log("ITS filtered and checked items are there")
            this.checkedItems.forEach((item: any) => {
              // console.log("inside loop : ",this.parsedData[item.split('_')[0]].items[item.split('_')[1]])
              itemWithNewId.push(
                this.assignNewIds(
                  this.parsedData[item.split('_')[0]].items[item.split('_')[1]],
                  'id',
                  'items'
                )
              );
            });
          }
          if (this.checkedItems.length) {
            this.checkedItems.forEach((item: any) => {
              let a = item.split('_');
              let data = this.furnishing[a[0]];
              for (let i = 1; i < a.length; i++) {
                data = data.items[a[i]];
              }
              let destinationId, sourceId;
              destinationId = event.destinationItem.item.dataItem.dbid;
              sourceId = event.sourceItem.item.dataItem.dbId;
              if (destinationId != undefined && sourceId != undefined) {
                let a = {
                  StatutoryAccountCodeMappingId: 0,
                  fk_StatutoryAccountCodes: data.dbId,
                  fk_TaxAccountCode: destinationId,
                };
                this.globalService
                  .insertMappingAccount(a)
                  .then((response: any) => {
                    if (this.azData == 'A-Z') this.getRightTree(1);
                    else this.onItemClickDrpNew(this.azData);
                    this.getLeftTree(this.filter, null, this.mainBusinessId);
                  });
              }

              itemWithNewId.push(this.assignNewIds(data, 'id', 'items'));
            });
          } else {
            itemWithNewId.push(
              this.assignNewIds(event.sourceItem.item.dataItem, 'id', 'items')
            );
          }
        }
        // IG6 1bt
        if (this.checkedItems.length == 0) {
          let destinationId, sourceId;
          destinationId = event.destinationItem.item.dataItem.dbid;
          sourceId = event.sourceItem.item.dataItem.dbId;
          if (destinationId != undefined && sourceId != undefined) {
            let a = {
              StatutoryAccountCodeMappingId: 0,
              fk_StatutoryAccountCodes: sourceId,
              fk_TaxAccountCode: destinationId,
            };
            this.globalService.insertMappingAccount(a).then((response: any) => {
              if (this.azData == 'A-Z') this.getRightTree(1);
              else this.onItemClickDrpNew(this.azData);
              this.getLeftTree(this.filter, null, this.mainBusinessId);
            });
          }
        }
      } else if (event.sourceItem.item.dataItem.items) {
        // console.log("entered if loop");
        // console.log(event)
        event.sourceItem.item.dataItem.items.forEach((element: any) => {
          if (!element.IsMapped) {
            let item = this.assignNewIds(element, 'id', 'items');
            // console.log(element)
            itemWithNewId.push(item);
          }
        });
        let destinationId, sourceId;

        let count = 0;
        itemWithNewId.forEach((ele: any) => {
          count++;
          destinationId = event.destinationItem.item.dataItem.dbid;
          sourceId = ele.dbId;
          if (destinationId != undefined && sourceId != undefined) {
            let a = {
              StatutoryAccountCodeMappingId: 0,
              fk_StatutoryAccountCodes: sourceId,
              fk_TaxAccountCode: destinationId,
            };
            this.globalService.insertMappingAccount(a).then((response: any) => {
              if (count == itemWithNewId.length) {
                if (this.azData == 'A-Z') this.getRightTree(1);
                else this.onItemClickDrpNew(this.azData);
                this.getLeftTree(this.filter, null, this.mainBusinessId);
              }
            });
          }
        });
      }
      if (eventTemp1 == eventTemp2)
        if (
          (event.destinationItem.item.dataItem.items.length == 0 &&
            event.destinationItem.item.dataItem.IsMapped == undefined) ||
          (event.destinationItem.item.dataItem.items.length > 0 &&
            event.destinationItem.item.dataItem.items[0].IsMapped != undefined)
        )
          itemWithNewId.forEach((element) => {
            // if the target is an empty placeholder, remove it and push the new item to the destination tree nodes

            if (event.destinationItem.item.dataItem.placeholder) {
              // // console.log(event.destinationItem.item.dataItem.placeholder);
              const placeholderItemIndex = event.destinationTree.nodes.indexOf(
                event.destinationItem.item.dataItem
              );
              event.destinationTree.nodes.splice(
                placeholderItemIndex,
                1,
                element
              );
              return;
            }

            // manually insert the new item and its children at the targeted position
            if (event.dropPosition === DropPosition.Over) {
              event.destinationItem.item.dataItem.items =
                event.destinationItem.item.dataItem.items || [];
              event.destinationItem.item.dataItem.items.push(element);

              if (
                !event.destinationTree.isExpanded(
                  event.destinationItem.item.dataItem,
                  event.destinationItem.item.index
                )
              ) {
                event.destinationTree.expandNode(
                  event.destinationItem.item.dataItem,
                  event.destinationItem.item.index
                );
              }
            } else {
              const parentChildren: any[] = event.destinationItem.parent
                ? event.destinationItem.parent.item.dataItem.items
                : event.destinationTree.nodes;

              const shiftIndex =
                event.dropPosition === DropPosition.After ? 1 : 0;
              const targetIndex =
                parentChildren.indexOf(event.destinationItem.item.dataItem) +
                shiftIndex;

              parentChildren.splice(targetIndex, 0, element);
            }
          });

      this.checkedItems = [];
    }
    // this.nestedCode(this.selectedItem)
  }

  // recursively clones and assigns new ids to the root level item and all its children
  private assignNewIds(item: any, idField: string, childrenField: string): any {
    const result = Object.assign({}, item, { [idField]: Math.random() });
    // // console.log(result)

    if (result[childrenField] && result[childrenField].length) {
      result[childrenField] = result[childrenField].map((childItem: any) =>
        this.assignNewIds(childItem, idField, childrenField)
      );
    }

    return result;
  }
  searchString = '';

  public parsedData: any[] = [];
  complex() {
    if (this.azData != 'A-Z') {
      this.onItemClickDrp(this.azData);
    } else {
      this.getRightTree(1);
    }
  }
  public parsedData1: any[] = [];
  public searchRes: any[] = [];
  public opened1 = false;
  public checked = false;
  public close(status: string): void {
    this.opened1 = false;
    let deleteItems: any[] = [];
    if (status == 'yes') {
      this.checkedItems1.forEach((item: any) => {
        if (
          item.split('_')[5] != undefined &&
          this.parsedData1[item.split('_')[0]].items[item.split('_')[1]].items[
            item.split('_')[2]
          ].items[item.split('_')[3]].items[item.split('_')[4]].items[
            item.split('_')[5]
          ].dbid != undefined
        )
          deleteItems.push(
            this.parsedData1[item.split('_')[0]].items[item.split('_')[1]]
              .items[item.split('_')[2]].items[item.split('_')[3]].items[
              item.split('_')[4]
            ].items[item.split('_')[5]].dbid
          );
        else if (
          this.parsedData1[item.split('_')[0]].items[item.split('_')[1]].items[
            item.split('_')[2]
          ].items[item.split('_')[3]].items[item.split('_')[4]].dbid !=
          undefined
        )
          deleteItems.push(
            this.parsedData1[item.split('_')[0]].items[item.split('_')[1]]
              .items[item.split('_')[2]].items[item.split('_')[3]].items[
              item.split('_')[4]
            ].dbid
          );
      });

      this.isLoading = true;
      this.globalService
        .deleteMappingAccount(deleteItems)
        .then((response: any) => {
          let a = deleteItems.length;
          while (a != 0) {
            this.singleExpand.pop();
            a--;
          }
          this.checkedItems1 = [];
          if (this.azData == 'A-Z') this.getRightTree(1);
          else this.onItemClickDrpNew(this.azData);
          this.getLeftTree(this.filter, null, this.mainBusinessId);
          this.isLoading = false;
        });
    }
  }

  public open(): void {
    if (this.checkedItems1.length > 0) this.opened1 = true;
  }
  filter1: any;
  filter2: any;
  public handleFilter(value: any): void {
    this.filtered = false;
    // console.log("Filter value : ",value)
    if (this.filter1) {
      this.parsedData = this.search(this.furnishing, this.filter1);
      this.filtered = true;
    } else {
      this.parsedData = this.furnishing;
    }
  }
  clearData(ele: any) {
    console.log(ele);
    if (ele == '') {
      this.getRightTree(0);
      this.singleExpand = [];
      this.singleExpand.push(this.parsedData1[0].id);
      this.parsedData1[0].items.forEach((element: any) => {
        this.singleExpand.push(element.id);
        // this.nestedCode(element)
      });
      this.singleExpand = [this.singleExpand];
    }
  }
  public handleFilter1(value: any): void {
    // this.getRightTree(0)
    this.filtered1 = false;

    this.searchRes = [];
    let temp: any[] = [];
    this.maintenance.forEach((ele) => {
      temp.push(ele);
    });
    if (this.filter2) {
      this.search1(temp, this.filter2);
      this.filtered1 = true;

      let a: any[] = [];
      this.searchRes.forEach((ele) => {
        a.push(ele.dbid);
      });
      temp.forEach((ele) => {
        ele.items.forEach((ele1: any) => {
          ele1.items.forEach((ele2: any) => {
            let c: any[] = [];
            ele2.items.forEach((ele3: any) => {
              let b: any[] = [];
              if (
                ele3.text.toLowerCase().includes(this.filter2.toLowerCase())
              ) {
                c.push(ele3);
              }
              // if (a.includes(ele3.dbid)) c.push(ele3);
              ele3.items.forEach((ele4: any) => {
                if (
                  ele4.text.toLowerCase().includes(this.filter2.toLowerCase())
                ) {
                  b.push(ele4);
                }
              });

              ele3.items = b;
              if (ele3.items.length == 0) {
                ele2.items = [];
              }
            });
            if (c.length > 0) {
              ele2.items = c;
            }
          });
        });
      });
      this.parsedData1 = temp;
      this.expandAllData();
    } else {
      this.getRightTree(0);
      // this.parsedData1 = this.maintenance;
    }
    // this.singleExpand=[]
    //this.singleExpand.push(this.parsedData1[0].id)
  }

  public search1(items: any[], term: string): any[] {
    return items.reduce(
      (acc, item) => {
        if (
          this.contains(item.text, term) &&
          item.items != undefined &&
          item.items[0] != undefined &&
          item.items[0].AccountNumber
        ) {
          // if (!item.items) {
          // acc.push(item);
          this.searchRes.push(item);
          //   }
        }
        if (this.contains(item.text, term) && !item.items) {
          // if (!item.items) {
          acc.push(item);
          this.searchRes.push(item);
          //   }
        } else if (item.items && item.items.length > 0) {
          const newItems = this.search1(item.items, term);

          if (newItems.length > 0) {
            acc.push({
              id: item.id,
              text: item.text,
              items: newItems,
              BusinessTypeID: item.BusinessTypeID,
            });
          } else {
            acc.push({ id: item.id, text: item.text, items: [] });
          }
        }

        return acc;
      },

      []
    );
  }
  public search(items: any[], term: string): any[] {
    return items.reduce((acc, item) => {
      if (this.contains(item.text, term) && !item.items) {
        // if (!item.items) {
        acc.push(item);

        //   }
      } else if (item.items && item.items.length > 0) {
        const newItems = this.search(item.items, term);

        if (newItems.length > 0) {
          acc.push({ id: item.id, text: item.text, items: newItems });
        } else {
          acc.push({ id: item.id, text: item.text, items: [] });
        }
      }

      return acc;
    }, []);
  }

  public contains(text: string, term: string): boolean {
    if (text != undefined && text != null)
      return text.toLowerCase().indexOf((term || '').toLowerCase()) >= 0;
    return false;
  }

  exportUnmappedData() {
    if (this.mainBusinessId == undefined)
      this.mainBusinessId = this.parsedData1[0].BusinessTypeID;
    this.isLoading = true;
    this.globalService.getUnmappedExport(this.mainBusinessId).subscribe(
      (response) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const fileName = 'UnMappedGLAccount' + timestamp;
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();

        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }
  public handleSelection(event: any): void {
    //console.log(event);
    // this.isNodeSelected = true;
  }

  uploadData() {
    localStorage.setItem(
      'expendedforUploadMap',
      JSON.stringify(this.singleExpand[0])
    );
    const dialogRef = this.dialogService.open({
      content: UploadglaccountComponent,
      width: 550,
    });
    const uploadDetails = dialogRef.content
      .instance as UploadglaccountComponent;
    dialogRef.result.subscribe((r: any) => {});
  }
}
