import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap, finalize } from 'rxjs/operators';
//import { LoaderService } from './loader.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { MyGlobalObject } from '../myglobalobject';
import { GlobalService } from '../globalservice/globalservice.service';
import getPkce from 'oauth-pkce';
@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private globalService: GlobalService,
    public myGlobalObject: MyGlobalObject,
    private authenticationservice: AuthenticationService
  ) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.totalRequests++;
    //console.log('intercept');

    return next.handle(request).pipe(
      catchError((error) => {
        console.log(error);
        if (error instanceof HttpErrorResponse && error.status === 401 ) {
          if (error.error !='User details are not available') {
            return this.handle401Error(request, next);
          }
          else{
            throw error;
          }
        } else {
          //console.log(error);
          return throwError(error);
        }
      }),
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests == 0) {
        }
      })
    );
  }
  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      // let token = JSON.parse(localStorage.getItem('OPENAMSESSION') || '').refresh_token
      let tokenValue: any = localStorage.getItem('OPENAMSESSION');
      return this.globalService
        .getTokenDetailsFromRefreshToken(JSON.parse(tokenValue).refresh_token)
        .pipe(
          switchMap((data: any) => {
            this.isRefreshing = false;
            localStorage.setItem('OPENAMSESSION', JSON.stringify(data));
            localStorage.setItem('tokenCreated', Date.now().toString());
            return next.handle(this.addToken(request, data.access_token));
          }),
          catchError((err) => {
            var challengeVerifier = { verifier: '', challenge: '' };
            getPkce(50, (error, { verifier, challenge }) => {
              if (!error) {
                //console.log({ verifier, challenge });
                challengeVerifier = { verifier, challenge };
                //console.log(challengeVerifier);
                localStorage.setItem('verifier', challengeVerifier.verifier);
                this.authenticationservice.performLogin(
                  challengeVerifier.challenge
                );
              }
            });
            return throwError(() => new Error(err));
          })
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }
}
