<div class="header">{{ heading }}</div>
<div class="subHeader" style="margin: 0px 15px 0px 15px">
  Expression name and scope
</div>
<div class="container-fluid" style="padding: 0px 15px 0px 15px">
  <div class="row">
    <form class="k-form" [formGroup]="addExpressionForm">
      <kendo-formfield>
        <kendo-label [for]="txtName" text="Expression name*"></kendo-label>
        <input formControlName="txtName" kendoTextBox #txtName required />

        <kendo-formerror>{{ descriptionErrorMessage }}</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield style="margin-top: 0px">
        <kendo-label text="Current expression"> </kendo-label>
        <kendo-textarea
          resizable="auto"
          formControlName="txtExpression"
          [readonly]="true"
        >
        </kendo-textarea>
      </kendo-formfield>
    </form>

    <div class="container-fluid" style="padding: 0px; margin-top: 10px">
      <div class="row" style="display: flex; margin: 0px 15px 0px 15px">
        <div style="display: flex; padding: 0px; margin-bottom: 10px">
          <div>
            <mat-card-title style="padding: 0px"
              ><b> {{ mode }} </b></mat-card-title
            >
          </div>
          <div style="width: 60%"></div>
          <div>
            <mat-card-title style="padding: 0px"
              ><b> View generated expression </b></mat-card-title
            >
          </div>
        </div>

        <div
          style="
            width: 60%;
            border: 1px solid #d9d9d9;
            padding: 0px 12px 12px 12px;
          "
        >
          <div>
            <h5 *ngIf="!isDisplay">Loading expression data..</h5>
            <div *ngIf="!isDisplay">
              <kendo-chunkprogressbar
                [chunkCount]="10"
                [min]="-10"
                [max]="10"
                [indeterminate]="true"
                [value]="30"
                style="width: 100%"
              ></kendo-chunkprogressbar>
            </div>
            <br />

            <query-builder
              style="padding: 0px"
              class="container-fluid"
              *ngIf="isDisplay"
              [(ngModel)]="query"
              [config]="config"
              (ngModelChange)="onQueryBuilderChange($event)"
            >
              <ng-container
                *queryButtonGroup="
                  let ruleset;
                  let addRule = addRule;
                  let addRuleSet = addRuleSet;
                  let removeRuleSet = removeRuleSet;
                  let getDisabledState = getDisabledState
                "
              >
                <ap-button
                  type="button"
                  [btnType]="'secondary'"
                  (click)="addRule()"
                  [disabled]="getDisabledState()"
                  class="two"
                >
                  + Add filter</ap-button
                >&nbsp;&nbsp;
                <ap-button
                  type="button"
                  class="one"
                  (click)="addRuleSet()"
                  [btnType]="'secondary'"
                  [disabled]="getDisabledState()"
                >
                  + Add group</ap-button
                >&nbsp;&nbsp;
                <ap-button
                  type="button"
                  class="two"
                  [btnType]="'secondary'"
                  *ngIf="removeRuleSet"
                  (click)="removeRuleSet()"
                >
                  - Remove group
                </ap-button>
              </ng-container>

              <ng-container
                *queryOperator="
                  let rule;
                  let operators = operators;
                  let onChange = onChange
                "
              >
                <select
                  class="input-control"
                  [(ngModel)]="rule.operator"
                  (ngModelChange)="onChange(rule)"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="onChangeOperator(rule)"
                >
                  <option *ngFor="let value of operators" [value]="value">
                    {{ value.split("~")[0] }}
                  </option>
                </select>
              </ng-container>

              <ng-container
                *queryInput="let rule; type: 'number'; let field = field"
              >
                <ng-container
                  *ngIf="
                    !(
                      rule.operator.split('~')[0] === 'Is Empty' ||
                      rule.operator.split('~')[0] === 'Is Not Empty'
                    )
                  "
                >
                  <div #group1
                  [ngStyle]="{
                    display:
                      (rule.value && rule.value.toString().split('~').length == 1) || (!rule.value)
                        ? 'block'
                        : 'none'
                  }"
                  >
                    <br />
                    <input
                      type="number"
                      class="input-control"
                      [(ngModel)]="rule.value"
                      [placeholder]="'Enter value'"
                    />
                    <br /><br />
                    <div style="width: fit-content">
                      <ap-button
                        class="ap-button ap-button-primary"
                        (click)="toggleChange(group1, group2, rule)"
                        [btnType]="'primary'"
                        themeColor="primary"
                        style="font-weight: 500"
                      >
                        Select keyword group
                      </ap-button>
                    </div>
                  </div>
                  <div
                    #group2
                    [ngStyle]="{
                      display:
                        rule.value && rule.value.toString().split('~').length == 2
                          ? 'block'
                          : 'none'
                    }"
                  >
                    <br />
                    <select
                      name="demo"
                      class="input-control"
                      id="#"
                      [(ngModel)]="rule.value"
                    >
                      <option value="" disabled selected hidden>
                        Select keyword group
                      </option>
                      <option
                        *ngFor="let obj of keywordgroup"
                        [ngValue]="obj.id + '~' + obj.name"
                      >
                        {{ obj.name }}
                      </option>
                    </select>
                    <br /><br />
                    <div style="width: fit-content">
                      <ap-button
                        [btnType]="'primary'"
                        themeColor="primary"
                        class="ap-button ap-button-primary"
                        (click)="toggleChange(group2, group1, rule)"
                      >
                        Select custom value
                      </ap-button>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container
                *queryInput="let rule; type: 'string'; let field = field"
              >
                <ng-container
                  *ngIf="
                    !(
                      rule.operator.split('~')[0] === 'Is Empty' ||
                      rule.operator.split('~')[0] === 'Is Not Empty'
                    )
                  "
                >
                  <!-- {{rule | json}} -->
                  <div #group1
                  [ngStyle]="{
                    display:
                      (rule.value && rule.value.split('~').length == 1) || (!rule.value)
                        ? 'block'
                        : 'none'
                  }"
                  >
                    <br />
                    <input
                      type="text"
                      class="input-control"
                      [(ngModel)]="rule.value"
                      [placeholder]="'Enter value'"
                    />
                    <br /><br />
                    <div style="width: fit-content">
                      <ap-button
                        [btnType]="'primary'"
                        themeColor="primary"
                        style="padding: 8px 12px !important; width: 180px"
                        (click)="toggleChange(group1, group2, rule)"
                      >
                        Select keyword group
                      </ap-button>
                    </div>
                  </div>
                  <div
                    #group2
                    [ngStyle]="{
                      display:
                        rule.value && rule.value.split('~').length == 2
                          ? 'block'
                          : 'none'
                    }"
                  >
                    <br />
                    <select
                      name="demo"
                      class="input-control"
                      id="#"
                      [(ngModel)]="rule.value"
                    >
                      <option value="" disabled selected hidden>
                        Select keyword group
                      </option>
                      <option
                        *ngFor="let obj of keywordgroup"
                        [ngValue]="obj.id + '~' + obj.name"
                      >
                        {{ obj.name }}
                      </option>
                    </select>
                    <br /><br />
                    <div style="width: fit-content">
                      <ap-button
                        [btnType]="'primary'"
                        themeColor="primary"
                        style="padding: 8px 12px !important; width: 180px"
                        (click)="toggleChange(group2, group1, rule)"
                      >
                        Select custom value
                      </ap-button>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container
                *queryInput="let rule; type: 'date'; let field = field"
              >
                <ng-container
                  *ngIf="
                    !(
                      rule.operator.split('~')[0] === 'Is Empty' ||
                      rule.operator.split('~')[0] === 'Is Not Empty'
                    )
                  "
                >
                  <div #group1
                  [ngStyle]="{
                    display:
                      (rule.value && rule.value.split('~').length == 1) || (!rule.value)
                        ? 'block'
                        : 'none'
                  }"
                  >
                    <br />
                    <input
                      type="date"
                      class="input-control"
                      [(ngModel)]="rule.value"
                      [placeholder]="'Enter value'"
                    />
                    <br /><br />
                    <div style="width: fit-content">
                      <button
                        class="buttonstyle"
                        (click)="toggleChange(group1, group2, rule)"
                      >
                        Select keyword group
                      </button>
                    </div>
                  </div>
                  <div
                    #group2
                    [ngStyle]="{
                      display:
                        rule.value && rule.value.split('~').length == 2
                          ? 'block'
                          : 'none'
                    }"
                  >
                    <br />
                    <select
                      name="demo"
                      class="input-control"
                      id="#"
                      [(ngModel)]="rule.value"
                    >
                      <option value="" disabled selected hidden>
                        Select keyword group
                      </option>
                      <option
                        *ngFor="let obj of keywordgroup"
                        [ngValue]="obj.id + '~' + obj.name"
                      >
                        {{ obj.name }}
                      </option>
                    </select>
                    <br /><br />
                    <div style="width: fit-content">
                      <ap-button
                        [btnType]="'primary'"
                        themeColor="primary"
                        class="ap-button ap-button-primary"
                        (click)="toggleChange(group2, group1, rule)"
                      >
                        Select custom value
                      </ap-button>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container
                *queryInput="let rule; type: 'decimal'; let field = field"
              >
                <ng-container
                  *ngIf="
                    !(
                      rule.operator.split('~')[0] === 'Is Empty' ||
                      rule.operator.split('~')[0] === 'Is Not Empty'
                    )
                  "
                >
                  <div #group1
                  [ngStyle]="{
                    display:
                      (rule.value && rule.value.split('~').length == 1) || (!rule.value)
                        ? 'block'
                        : 'none'
                  }"
                  >
                    <br />
                    <input
                      type="number"
                      class="input-control"
                      [(ngModel)]="rule.value"
                      [placeholder]="'Enter value'"
                    />
                    <br /><br />
                    <div style="width: fit-content">
                      <button
                        class="buttonstyle"
                        (click)="toggleChange(group1, group2, rule)"
                      >
                        Select keyword group
                      </button>
                    </div>
                  </div>
                  <div
                    #group2
                    [ngStyle]="{
                      display:
                        rule.value && rule.value.split('~').length == 2
                          ? 'block'
                          : 'none'
                    }"
                  >
                    <br />
                    <select
                      name="demo"
                      class="input-control"
                      id="#"
                      [(ngModel)]="rule.value"
                    >
                      <option value="" disabled selected hidden>
                        Select keyword group
                      </option>
                      <option
                        *ngFor="let obj of keywordgroup"
                        [ngValue]="obj.id + '~' + obj.name"
                      >
                        {{ obj.name }}
                      </option>
                    </select>
                    <br /><br />
                    <div style="width: fit-content">
                      <ap-button
                        [btnType]="'primary'"
                        themeColor="primary"
                        class="ap-button ap-button-primary"
                        (click)="toggleChange(group2, group1, rule)"
                      >
                        Select custom value
                      </ap-button>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </query-builder>
          </div>
        </div>
        <div
          style="
            width: 38%;
            border: 1px solid #d9d9d9;
            margin-left: 25px;
            padding: 12px;
          "
        >
          <br />
          <div
            class="expression"
            [innerHTML]="query | transformexpression"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div class="clickToActions" style="padding: 10px 0px">
    <ap-button
      kendoButton
      [btnType]="'secondary'"
      [label]="'Cancel'"
      class="cancelButton"
      (onClick)="closeButtonClick()"
    ></ap-button>
    <ap-button
      kendoButton
      [btnType]="'primary'"
      [label]="'Save'"
      class="okButton"
      style="margin-right: 6px"
      [disabled]="!addExpressionForm.valid"
      (onClick)="saveExpressionforNew()"
    ></ap-button>
  </div>
</div>
