import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MyGlobalObject } from '../../myglobalobject';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root',
})
export class TaxoutcomereportserviceService {
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getTaxAccountAnalysisReport: '/Export/GetTaxAccountAnalysisReport'
    };
  }

  getTaxAccountAnalysisReport(taxAccountReport:any): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('ClientdbName',this.selectedClient.DBName)
    .set('fiscalYear',taxAccountReport.fiscalYear)
    .set('businessTypeID',taxAccountReport.businesstypeID)
    .set('legalEntityID',taxAccountReport.legalEntity)
    .set('isExcludeData',taxAccountReport.isExcludeData);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getTaxAccountAnalysisReport;
      return this.http.get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        observe: 'response',
        responseType: 'blob' as 'json', 
      });
  }
}