import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ActionsLayout,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { AnalysisReviewService } from 'src/app/common/services/analysisReview/analysis-review.service';
import { AnalysisTaxaccountComponent } from '../analysis-taxaccount/analysis-taxaccount.component';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-analysis-new-line',
  templateUrl: './analysis-new-line.component.html',
  styleUrls: ['./analysis-new-line.component.scss'],
})
export class AnalysisNewLineComponent implements OnInit {
  @Input() public nodedata: any;
  @Input() public nameDesc: any;
  @Input() public BlockId: any;
  @Input() public Satement: any;
  @Input() public node: any;
  @Input() public isNew: any;
  @Input() public isSummary: any;
  @Input() public popupCaption: any;
  selectedClient: string | any;
  requireFurtherAnalysis: boolean = false;
  BusinessTypeId: any;
  BusinessDesc: any;
  AccountClassDescriptionList: any;
  AccountClassDescriptionListDB: any;
  AccountingAreaDescriptionList: any;
  AccountingAreaDescriptionListDB: any;
  newLineItemForm: FormGroup | any;
  descriptionErrorMessage: any = 'Description is required';
  AccountingAreaID: any = 'AccountingAreaID is required';
  AccountClass: any = 'AccountClass is required';
  public actionsLayout: ActionsLayout = 'normal';
  accountclassErrorMessage: any = 'Account Class is required';
  complexMessage: any = 'This needs analysing further';
  Complex: number = 0;
  AccountError: boolean = false;
  SatementId: any;
  Id: any;
  AccountingArea: any;
  nodeD: any;
  isNewD: any;
  isSummaryD: any;
  popupCaptionD: any;
  drpPSubAnalysisList: any;
  statementForm: any;
  statementGroupType: any;
  statementPath: any;
  layoutitem: any;

  constructor(
    public dialog: DialogRef,
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private globalService: GlobalService,
    private analysisService: AnalysisReviewService,
    private router: Router
  ) {
    this.newLineItemForm = this.fb.group({
      Description: [''],
      AccountingAreaID: [''],
      AccountClass: [''],
      ParentLineItem: null,
      CreateTaxAccount: true,
    });
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  ngOnInit(): void {
    this.BusinessTypeId = this.nodedata;
    this.SatementId = this.Satement;
    this.Id = this.BlockId;
    this.nodeD = this.node;
    this.isNewD = this.isNew;
    this.isSummaryD = this.isSummary;
    this.popupCaptionD = this.popupCaption;
    this.nodeD = this.node;
    this.getRefData();
    this.getStatementBlockByStatementID();
  }
  getRefData() {
    this.globalService
      .getAllAccountClass()
      .then((res) => {
        this.AccountClassDescriptionListDB = res;
        this.AccountClassDescriptionList =
          this.AccountClassDescriptionListDB.slice();
      })
      .catch((err) => {});
    this.globalService
      .getAllLineItemsByBusinessType(this.BusinessTypeId)
      .then((res) => {
        this.AccountingAreaDescriptionListDB = res;
        this.AccountingAreaDescriptionList =
          this.AccountingAreaDescriptionListDB.slice();
      })
      .catch((err) => {});
  }
  getStatementBlockByStatementID() {
    this.globalService
      .getAllLineItemsByBusinessType(this.BusinessTypeId)
      .then((res) => {
        this.AccountingAreaDescriptionListDB = res[0];

        this.AccountingAreaDescriptionList =
          this.AccountingAreaDescriptionListDB.slice();
      })
      .catch((err) => {});
  }
  handleParentLineItemChange(value: any) {
    if (value != undefined) {
      this.newLineItemForm.controls['CreateTaxAccount'].setValue(true);
      this.newLineItemForm.controls['CreateTaxAccount'].disable();
    } else {
      this.newLineItemForm.controls['CreateTaxAccount'].enable();
    }
  }
  handleAccountClassChange(value: any) {
    if (value == undefined) {
      this.Complex = 1;
    } else if (value.Description === 'Complex') {
      this.Complex = 2;
      this.newLineItemForm.controls['ParentLineItem'].setValue(null);
      this.newLineItemForm.controls['ParentLineItem'].disable();
      this.newLineItemForm.controls['CreateTaxAccount'].setValue(true);
      this.newLineItemForm.controls['CreateTaxAccount'].disable();
      this.requireFurtherAnalysis = true;
    } else {
      this.newLineItemForm.controls['ParentLineItem'].enable();
      this.newLineItemForm.controls['CreateTaxAccount'].enable();
      this.requireFurtherAnalysis = false;
    }
    if (value.Description == 'Simple') {
      this.Complex = 0;
    }
  }

  handleFilterParentLineItem(value: any) {
    this.AccountingAreaDescriptionList =
      this.AccountingAreaDescriptionListDB.filter(
        (s: any) =>
          s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
  }
  cancelClick() {
    this.dialog.close();
  }
  saveLineItem() {
    var formObject = this.newLineItemForm.getRawValue();
    let AccountingAreaVal, AccountingAreaIdVal;
    if (formObject.AccountClass.ID == 3 || formObject.ParentLineItem == null) {
      AccountingAreaVal = null;
      const accountclassErrorMessage = 'This needs analysing further';
      AccountingAreaIdVal = '';
    } else {
      AccountingAreaVal = formObject.ParentLineItem.LineID;
      AccountingAreaIdVal = formObject.ParentLineItem.AccountingAreaID;
    }
    let LineItemObject = {
      ID: 0,
      AccountingAreaID: null,
      Description: formObject.Description,
      RequireFurtherAnalysis: this.requireFurtherAnalysis,
      AccountDerivation: null,
      AmountDisplayIndicator: true,
      TaxChartOfAccounts: this.BusinessTypeId,
      AccountClass: formObject.AccountClass.ID,
      AccountAreaGroup: null,
      StatementLevel: null,
      AccountingArea: AccountingAreaVal,
      HelpId: '',
    };

    let LineItemObject2 = {};
    this.analysisService
      .insertLineItem(LineItemObject)
      .then((res) => {
        if (res) {
          this.AccountingArea = res.Item1.ID;
          LineItemObject2 = [
            {
              ID: 0,
              BlockId: this.Id,
              AccountingArea: this.AccountingArea,
              DisplayOrder: 0,
              Statement: this.SatementId,
            },
          ];
          this.Linklineitem(LineItemObject2);
          if (res.Item2 == 'Line Item added successfully') {
            this.toastr.successToastr(
              'Line item added successfully',
              'Success'
            );
            this.displaytreePack;
            if (this.newLineItemForm.getRawValue().CreateTaxAccount == true) {
              const dialogRef = this.dialogService.open({
                content: AnalysisTaxaccountComponent,
                width: 950,
                height: 595,
              });
              const newTaxAccount = dialogRef.content
                .instance as AnalysisTaxaccountComponent;
              dialogRef.result.subscribe((r: any) => {});
              newTaxAccount.gridBusinessType = this.BusinessTypeId;
              newTaxAccount.isCreateFromLI = true;
              newTaxAccount.LICreateTaxData = res.Item1;
              newTaxAccount.nameDesc = this.BusinessDesc;
            } else {
              this.dialog.close();
            }
          }
          this.dialog.close();
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.displaytreePack;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  Linklineitem(LineItemObject2: any) {
    this.analysisService
      .insertAnalysisLineItem(LineItemObject2)
      .then((res) => {
        if (res == 200) {
          this.toastr.successToastr(res, 'Success');
          this.displaytreePack;
        } else if (res == 'Analysis Statement already exists') {
          this.toastr.errorToastr(res, 'Failed');
          this.displaytreePack;
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  onHighlightClick(data: any) {}
  displaytreePack() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }
}
