import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MyGlobalObject } from '../../myglobalobject';
@Injectable({
  providedIn: 'root',
})
export class AnalysisstatementmoveService {
  private params: any;
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private mockApiUrl: any;
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    this.apiUrl = {
      statementMoveup: '/AnalysisTreeView/StatementMoveUp',
      statementMovedown: '/AnalysisTreeView/StatementMoveDown',
      statementMovetop: '/AnalysisTreeView/StatementMoveTop',
      statementMovebottom: '/AnalysisTreeView/StatementMoveBottom',
      getstatementOrder: '/AnalysisTreeView/GetStatementOrder',
    };
  }
  moveAnalysisStatementUp(statementId: number): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementID', statementId);

    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.statementMoveup;
    return this.http.post(this.apiHost, statementId, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  moveAnalysisStatementDown(statementId: number): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementID', statementId);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.statementMovedown;
    return this.http.post(this.apiHost, statementId, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  moveAnalysisStatementTop(statementId: number): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementID', statementId);

    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.statementMovetop;
    return this.http.post(this.apiHost, statementId, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  moveAnalysisStatementBottom(statementId: number): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementID', statementId);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.statementMovebottom;
    return this.http.post(this.apiHost, statementId, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  getStatementOrder(statementID: number): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementID', statementID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getstatementOrder;
    return this.http.get<any>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
}
