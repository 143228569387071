import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  DialogRef,
  DialogContentBase,
  ActionsLayout,
} from '@progress/kendo-angular-dialog';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../common/globalservice/globalservice.service';
import { MyGlobalObject } from '../common/myglobalobject';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-clientselectpopup',
  templateUrl: './clientselectpopup.component.html',
  styleUrls: ['./clientselectpopup.component.scss'],
})
export class ClientselectpopupComponent
  extends DialogContentBase
  implements OnInit
{
  @Input()
  public name!: string;
  public client!: string;
  public formGroup!: FormGroup;
  public actionsLayout: ActionsLayout = 'normal';
  public clientdata: Array<{ text: string; value: number }> = [
    { text: 'ABC', value: 1 },
    { text: 'XYZ', value: 2 },
    { text: 'CIsco', value: 3 },
  ];
  clientsList: any = [];
  public listItems: Array<{ text: string; value: number }> = [
    { text: 'Small', value: 1 },
    { text: 'Medium', value: 2 },
    { text: 'Large', value: 3 },
  ];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  constructor(
    public dialog: DialogRef,
    private cookieService: CookieService,
    private router: Router,
    private fb: FormBuilder,
    public myGlobalObject: MyGlobalObject,
    private globalService: GlobalService
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      client: [this.client, Validators.required],
    });
    this.fetchClientList();
    // this.clientdata=[{'text':'client1','value':'client1'},{'text':'client2','value':'client2'}];
  }
  fetchClientList() {
    this.globalService
      .fetchClientsList()
      .then((res) => {
        this.myGlobalObject.mappedClientsForUser = res;
        let clientsListDB = res;
        this.clientsList = clientsListDB.map((obj: any) => {
          let clientstemp = {
            text: '',
            value: '',
          };
          clientstemp['text'] = obj.ClientEntityName;
          clientstemp['value'] = obj.ClientId;
          return clientstemp;
        });
      })
      .catch((err) => {});
  }
  handleFilter(value: any) {
    this.clientsList = this.clientsList.filter(
      (s: any) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  public onClose(ev: any): void {
    //this.formGroup.get('name').markAsTouched();
    // prevent dialog from closing on clicking the `X` (close) button
    let selected: any = localStorage.getItem('SELECTEDCLIENT');
    let displaySelectedClient = JSON.parse(selected);
    if (
      displaySelectedClient != '' &&
      displaySelectedClient != null &&
      displaySelectedClient != undefined
    ) {
      this.dialog.close();
    } else {
      ev.preventDefault();
      this.logout();
    }
  }

  public onCancelAction(): void {
    //this.dialog.close({ text: 'Cancel' });
    let selected: any = localStorage.getItem('SELECTEDCLIENT');
    let displaySelectedClient = JSON.parse(selected);
    if (
      displaySelectedClient != '' &&
      displaySelectedClient != null &&
      displaySelectedClient != undefined
    ) {
      this.dialog.close();
    } else {
      this.logout();
    }
  }

  public onConfirmAction(): void {
    this.dialog.close({ text: 'Submit', themeColor: 'primary' });
    window.location.reload();
  }
  logout(): void {
    // Logout  OpenAM session
    // Hard  OpenAM token as logout does not clears.
    // this.cookieService.set('OPENAMSESSION', '');
    // this.cookieService.delete('OPENAMSESSION');
    // this.cookieService.set('SELECTEDCLIENT', '');
    // this.cookieService.delete('SELECTEDCLIENT');
    localStorage.removeItem('OPENAMSESSION');
    localStorage.removeItem('tokenCreated');
    localStorage.removeItem('SELECTEDCLIENT');
    localStorage.removeItem('selectedFiscalYear');
    localStorage.removeItem('isVisited');
    localStorage.removeItem('verifier');
    localStorage.removeItem('selectedTreenode');
    window.location.href = environment.openam.logoutUrl;
  }
}
