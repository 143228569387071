import { Component, OnInit } from '@angular/core';
import {
  ChunkSettings,
  FileRestrictions,
  SuccessEvent,
  UploadEvent,
} from '@progress/kendo-angular-upload';

import {
  DialogRef,
  DialogService,
  ActionsLayout,
} from '@progress/kendo-angular-dialog';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Worksheet } from 'src/app/_models/worksheet';
import { KeywordGroupFormListComponent } from '../keyword-group-form-list/keyword-group-form-list.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-keyword-import',
  templateUrl: './keyword-import.component.html',
  styleUrls: ['./keyword-import.component.scss'],
})
export class KeywordImportComponent implements OnInit {
  uploadSaveUrl: string = '';
  uploadRemoveUrl: string = '';
  fileMap: any = [];
  headers: any;
  headersObj: any;
  withCredential: boolean = false;
  private selectedClient: any;
  httpParams: any;
  fileName: string = '';
  fileId: string | undefined;
  kendoUploadButton: any = document.querySelector('.k-upload-button');
  private baseUrl: string = '';
  private apifolder: string = '';
  isNextVisible: boolean = false;
  public myFiles: any[] = [];
  worksheet!: Worksheet;
  worksheets: Worksheet[] = [];
  public value: string[] = [];
  worksheetParams: string[] = [];
  isLoading: boolean = false;
  actionsLayout: ActionsLayout = 'normal';

  public chunkSettings: ChunkSettings = {
    size: 1048576,
  };

  constructor(
    private globalService: GlobalService,
    public toastr: ToastrManager,
    private cookieService: CookieService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    let tokenValue: any = localStorage.getItem('OPENAMSESSION');
    this.headers = {
      'Authorization-Token': 'Bearer ' + JSON.parse(tokenValue).access_token,
    };
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);

    this.uploadSaveUrl =
      this.baseUrl +
      this.apifolder +
      '/Keyword/UploadExcelToContainer?ClientdbName=' +
      this.selectedClient.DBName;

    this.uploadRemoveUrl = 'removeUrl';
  }

  keywordFileRestrictions: FileRestrictions = {
    allowedExtensions: ['.xls', '.xlsx'],
  };

  uploadEventHandler(e: UploadEvent) {
    let tokenValue: any = localStorage.getItem('OPENAMSESSION');
    e.headers = e.headers.append(
      'Authorization-Token',
      'Bearer ' + JSON.parse(tokenValue).access_token
    );
  }

  closeButtonClick() {
    this.router.navigate(['/manageKeyword']);
  }

  public success(e: SuccessEvent): void {
    if (e.files.length > 0) {
      this.fileName = e.response.body.FileName;
      if (e.response.body.FileUploaded == true) {
        this.getSheetNamesFromContainer(this.fileName);
      }
    }
  }

  resetWorksheetData(): void {
    this.worksheet = {
      name: '',
    };
  }

  getSheetNamesFromContainer(fileName: string): void {
    this.resetWorksheetData();
    this.value = [];
    this.isLoading = true;
    this.globalService.getSheetNamesFromContainer(fileName).subscribe(
      (response) => {
        this.worksheets = response;

        this.isLoading = false;
        this.isNextVisible = true;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  finalImport(): void {
    this.isLoading = true;
    this.worksheetParams = [];
    this.value.map((key) => {
      this.worksheetParams.push(key);
    });

    if (this.worksheetParams.length == 0) {
      this.toastr.infoToastr(
        'Select one or more sheets from the list above to import data from',
        'Information'
      );
      this.isLoading = false;
      return;
    }

    this.globalService
      .ImportKeywords(this.worksheetParams, this.fileName)
      .subscribe(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.toastr.successToastr(response.body, 'Success');
            this.router.navigate(['/manageKeyword']);
          } else {
            this.toastr.errorToastr(response.body, 'Error');
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }
}
