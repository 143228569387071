<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 18px; line-height: 1.1em">
    Choose tax accounts to edit
  </div>
</kendo-dialog-titlebar>

<mat-card class="userCard">
  <mat-card-title>
    <h6 style="margin-left: 31px">Accounting Area: {{ AccountingAreaName }}</h6>
  </mat-card-title>
  <mat-card-content>
    <div id="mainContent" class="container-fluid" style="overflow: auto">
      <div class="container">
        <p for="search">Choose a tax account to edit from this list below:</p>
        <div>
          <span>Tax accounts</span>
        </div>
        <br />
        <kendo-grid
          [data]="data"
          scrollable="none"
          [selectable]="true"
          [kendoGridSelectBy]="selectedCallback"
          [(selectedKeys)]="mySelectionScope"
        >
          <kendo-grid-column
            field="ShortDescription"
            title="Description"
            [headerStyle]="{
              'font-weight': 'bold',
              'text-align': 'center',
              color: '#d04a02'
            }"
          ></kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<!-- <p style="margin-left:25px;font-weight: bold;">Accounting Area -  </p> -->

<kendo-dialog-actions [layout]="actionsLayout" style="margin-left: 62%">
  <ap-button
    [btnType]="'secondary'"
    [label]="'Cancel'"
    (onClick)="cancelClick()"
  ></ap-button>
  <ap-button
    [btnType]="'primary'"
    (onClick)="edit()"
    [label]="'Edit'"
  ></ap-button>
</kendo-dialog-actions>
