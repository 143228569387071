import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { AdministrationService } from 'src/app/common/services/administration/administration.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
@Component({
  selector: 'app-unlocksegment',
  templateUrl: './unlocksegment.component.html',
  styleUrls: ['./unlocksegment.component.scss'],
})
export class UnlocksegmentComponent implements OnInit {
  selectedYear: any;
  isLoading: boolean = false;
  segmentList: any;

  constructor(
    private adminService: AdministrationService,
    private globalService: GlobalService,
    private router: Router,
    public toastr: ToastrManager
  ) {}

  ngOnInit(): void {
    // if (localStorage.getItem('selectedFiscalYear')) {
    //   let year: any = localStorage.getItem('selectedFiscalYear');
    //   this.selectedYear = JSON.parse(year).FiscalYearId;
    // }
    this.getAllLockedSegments();
  }
  getAllLockedSegments() {
    this.adminService.getAllRuuningSegmentsLockDetail().subscribe(
      (response: any) => {
        this.segmentList = response;
      },
      (err) => {
        console.log('error from api', err);
        this.isLoading = false;
      }
    );
  }
  unlockButtonClick(data: any) {
    this.globalService.UnLockSegment(data.SegmentName).subscribe(
      (response: any) => {
        this.toastr.successToastr('Segment unlocked successfully', 'Success');
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/unlocksegment']));
      },
      (error) => {
        this.toastr.errorToastr(
          'Something went wrong try after sometime',
          'Oops!'
        );
        console.log(error);
      }
    );
  }
}
