<!-- Header -->
<div class="header" style="font-size:20px">Manage GL accounts</div>
<!-- GL account details -->
<div class="container-fluid" style="padding:0px 24px 10px 24px">
  <div class="row">
    <div class="col-md-8 col-xs-12">
      <form class="k-form" [formGroup]="editGLAccountForm">
        <!-- <ap-field [title]="'GL account id:'" [required]="true">
          <input appkit-field formControlName="StatutoryCoAId" [disabled]=true/>                    
          <div *ngIf="editGLAccountForm.controls['StatutoryCoAId'].invalid && (editGLAccountForm.controls['StatutoryCoAId'].dirty || editGLAccountForm.controls['StatutoryCoAId'].touched)" id="errormessage" aria-live="polite" class="ap-field-email-validation-error">Please enter GL Account id</div>
        </ap-field> -->
        <kendo-formfield>
          <kendo-label text="GL account id*"></kendo-label>
          <input kendoTextBox required #StatutoryCoAId formControlName="StatutoryCoAId" />
          <kendo-formerror *ngIf="editGLAccountForm.controls['StatutoryCoAId'].errors?.['required']">Please enter GL Account id</kendo-formerror>
          <kendo-formerror *ngIf="editGLAccountForm.controls['StatutoryCoAId'].errors?.['maxlength']">Maximum length of GL Account id is 20</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label text="GL account number*"></kendo-label>
          <input kendoTextBox required #AccountNumber formControlName="AccountNumber" />
          <kendo-formerror *ngIf="editGLAccountForm.controls['AccountNumber'].errors?.['required']">Please enter GL Account number</kendo-formerror>
          <kendo-formerror *ngIf="editGLAccountForm.controls['AccountNumber'].errors?.['maxlength']">Maximum length of GL Account number is 20</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label text="Short description*"></kendo-label>
          <input kendoTextBox required #ShortDescription formControlName="ShortDescription" />
          <kendo-formerror *ngIf="editGLAccountForm.controls['ShortDescription'].errors?.['required']">Please enter Short Description</kendo-formerror>
          <kendo-formerror *ngIf="editGLAccountForm.controls['ShortDescription'].errors?.['maxlength']">Maximum length of Short Description is 25</kendo-formerror>
        </kendo-formfield>
        <!-- <br/>
        <br/> -->
        <!-- <ap-field [title]="'GL Account number:'" [required]="true">
          <input appkit-field formControlName="AccountNumber" [disabled]=true/>                    
          <div *ngIf="editGLAccountForm.controls['AccountNumber'].invalid && (editGLAccountForm.controls['AccountNumber'].dirty || editGLAccountForm.controls['AccountNumber'].touched)" id="errormessage" aria-live="polite" class="ap-field-email-validation-error">Please enter GL Account number</div>
          <div *ngIf="editGLAccountForm.controls['AccountNumber'].invalid && (editGLAccountForm.controls['AccountNumber'].dirty || editGLAccountForm.controls['AccountNumber'].touched)" id="errormessage1" aria-live="polite" class="ap-field-email-validation-error">Unable to save this data. It will not be unique in the database</div>
        </ap-field>
        <br/> -->
        <!-- <ap-field [title]="'Short Description'" [required]="true">
          <input appkit-field formControlName="ShortDescription"/>
          <div *ngIf="editGLAccountForm.controls['ShortDescription'].invalid && (editGLAccountForm.controls['ShortDescription'].dirty || editGLAccountForm.controls['ShortDescription'].touched)" id="errormessage" aria-live="polite" class="ap-field-email-validation-error">Please enter GL Short Description</div>
        </ap-field> -->
        <!-- <ap-dropdown [list]='AccountTypeIDList' [selectType]="'single'" [title]="'Statutory treatment'" formControlName="AccountTypeId"
        (onSelect)="onSelectDropdown($event)">
        <ng-container *ngFor="let item of AccountTypeIDList; let i = index;">
            <ap-dropdown-list-item #dropdownListItem [optionName]="'AccountTypeID'" [optionValue]="'Id'" [item]="item" 
                (onSelectItem)="onSelectItem($event)">
            </ap-dropdown-list-item>
        </ng-container>
        </ap-dropdown> -->
    
        <!-- <ap-field [title]="'Long Description'">
          <textarea appkit-field formControlName="LongDescription"></textarea>
          <ap-field-counter [value]="textareaDemo"></ap-field-counter>
        </ap-field>
        <br/> -->
        <kendo-formfield>
          <kendo-label text="Long description"></kendo-label>
          <kendo-textarea
            #longDesc
            [style.width.%]="100"
            formControlName="LongDescription"
          >
          </kendo-textarea>
          <kendo-formerror *ngIf="editGLAccountForm.controls['LongDescription'].errors?.['maxlength']">Maximum length of Long description is 255</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="BalanceSheetPandLIndicator" text="Balance or profit/loss">
              <span style="padding-left: 2%"><input type="radio" required id="Balance" class="k-radio" value="B" data-bind="checked: Balance" formControlName="BalanceSheetPandLIndicator" #BalanceSheetPandLIndicator kendoRadioButton/>&nbsp;
              <label class="k-radio-label" for="Balance">Balance</label> &nbsp;</span>
              <span style="padding-left: 2%"><input type="radio"  required id="Profit/loss" class="k-radio" value="P" data-bind="checked: Balance" formControlName="BalanceSheetPandLIndicator" #BalanceSheetPandLIndicator kendoRadioButton/>&nbsp;
              <label class="k-radio-label" for="Profit/loss">Profit/loss</label></span></kendo-label>
              <kendo-formerror>{{BalanceSheetPandLIndicatorErrorMessage}}</kendo-formerror>
        </kendo-formfield>
        <!-- <ng-container *ngFor="let item of list2">
          <ap-radio [title]="'Balance or profit/loss'" [required]="true" formControlName="BalanceSheetPandLIndicator" [value]="item.name" [styleClass]="'demo-item'"
            [(ngModel)]="groupRadioSelect" [disabled]="radioDisabled"
            (onChange)="onSelected($event)">
              <span *ngIf="showRadioLabel">{{ item.name }}</span>
          </ap-radio> 
        </ng-container>-->
        <br/>
        <kendo-formfield>
          <kendo-label [for]="AccountTypeId" text="Statutory treatment:"></kendo-label>
          <kendo-combobox
            #AccountTypeId
            formControlName="AccountTypeId"
            [data]="AccountTypeIdList"
            [filterable]="true"
            textField="Description"
            valueField="Id"
            (filterChange)="handleFilterStatutorytreatment($event)"
          >
          </kendo-combobox>
        </kendo-formfield>
      </form>
      <!-- Click to Actions -->
      <div class="clickToActions" style="padding-top: 10px;">
          <ap-button class="cancelButton" [btnType]="'secondary'" [label]="'Cancel'" (click)="cancelClick()"></ap-button>
          <ap-button class="okButton" [btnType]="'primary'" [label]="'Save'" [disabled]="!editGLAccountForm.valid"
          themeColor="primary"
          (click)="saveEditGL()" style="margin-right: 6px;"></ap-button>
      </div>
    </div>
    <div class="col-md-4 col-xs-12"></div>
  </div>
</div>
