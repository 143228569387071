<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Delete business type</div>
</kendo-dialog-titlebar>
<div style="display: flex">
  <p>
    <span
      class="Appkit4-icon icon-circle-warning-outline"
      style="font-size: 24px; color: #d04a02"
    ></span>
  </p>
  <p style="margin-left: 10px; font-size: medium">
    Are you sure you want to delete <b>{{ delBTdata.Description }}</b> business
    type?
  </p>
</div>
<!-- Click to Actions -->
<div class="clickToActions" style="padding: 10px 0px 0px 0px">
  <ap-button
    [btnType]="'secondary'"
    [label]="'No'"
    (click)="cancelClick()"
    class="cancelButton"
    style="width: 100px"
  ></ap-button>
  <ap-button
    [btnType]="'primary'"
    [label]="'Yes'"
    themeColor="primary"
    (click)="deleteBT()"
    class="okButton"
    style="margin-right: 6px"
  ></ap-button>
</div>

<kendo-dialog
  title="Delete business type"
  *ngIf="warnondelete"
  (close)="this.dialog.close()"
  [minWidth]="250"
  [width]="500"
>
  <div style="display: flex">
    <p>
      <span
        class="Appkit4-icon icon-circle-warning-outline"
        style="font-size: 24px; color: #d04a02"
      ></span>
    </p>
    <p style="margin-left: 10px; font-size: medium">
      The following business types could not be deleted as there is data
      associated with them:
      <br />

      {{ delBTdata.BusinessTypeId }}/{{ delBTdata.Description }} <br />
      <br />

      To delete you must first delete all the data associated with the listed
      business types.
    </p>
  </div>

  <!-- <div style="position: relative; width: 100%">
    <div style="float: left; width: 10%">
      <p>
        <span
          class="Appkit4-icon icon-circle-warning-outline"
          style="color: #d04a02; font-size: 24px"
        ></span>
      </p>
    </div>
    <div style="float: right; width: 90%">
      <p>
        The following business types could not be deleted as there is data
        associated with them:
      </p>
      <p>-{{ delBTdata.BusinessTypeId }}/{{ delBTdata.Description }}</p>

      <p>
        To delete you must first delete all the data associated with the listed
        business types.
      </p>
    </div>
  </div> -->
  <div class="clickToActions" style="padding: 10px 0px 0px 0px">
    <ap-button
      [btnType]="'primary'"
      [label]="'OK'"
      themeColor="primary"
      class="okButton"
      (click)="this.dialog.close()"
    ></ap-button>
  </div>
</kendo-dialog>
