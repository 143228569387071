import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DialogRef,
  DialogContentBase,
  ActionsLayout,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { ToastrManager } from 'ng6-toastr-notifications';
import { BusinesstypegridpopupComponent } from '../businesstypegridpopup/businesstypegridpopup.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addbusinesstype',
  templateUrl: './addbusinesstype.component.html',
  styleUrls: ['./addbusinesstype.component.scss'],
})
export class AddbusinesstypeComponent implements OnInit {
  @Input() public BTId: any;
  @Input() public copyBTId: any;

  selectedClient: any;
  countryList: any;
  countryListDB: any;
  industryList: any;
  industryListDB: any;
  addBTForm: FormGroup;
  isEdit: boolean = false;
  isCopy: boolean = false;
  isDuplicateEntry: boolean = false;
  BTDetailsfromDb: any;
  copiedDescription: any;
  descriptionErrorMessage: string = 'Description is required';
  public actionsLayout: ActionsLayout = 'normal';
  constructor(
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private router: Router,
    private globalService: GlobalService
  ) {
    this.addBTForm = this.fb.group({
      country: [''],
      industry: [''],
      businessTypeId: [''],
      description: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.BTId = history.state.btID;
    this.copyBTId = history.state.copyBtID;
    this.getRefData();

    this.selectedClient = localStorage.getItem('SELECTEDCLIENT');
    if (this.BTId) {
      this.isEdit = true;
      this.getBTDetailsById(this.BTId);
    }
    if (this.copyBTId) {
      this.isCopy = true;
      this.getBTDetailsById(this.copyBTId);
    }
  }
  getRefData() {
    this.globalService
      .getAllCountry()
      .then((res) => {
        this.countryListDB = res;
        this.countryList = this.countryListDB.slice();
      })
      .catch((err) => {});
    this.globalService
      .getAllIndustry()
      .then((res) => {
        this.industryListDB = res;
        this.industryList = this.industryListDB.slice();
      })
      .catch((err) => {});
  }
  getBTDetailsById(BTId: any) {
    this.globalService
      .getBusinessTypeById(BTId)
      .then((res) => {
        if (res) {
          this.BTDetailsfromDb = res;
          if (this.isEdit) {
            this.addBTForm.controls['businessTypeId'].setValue(
              this.BTDetailsfromDb.BusinessTypeId
            );
            this.addBTForm.controls['businessTypeId'].disable();
          }
          if (this.isCopy) {
            this.copiedDescription = this.BTDetailsfromDb.Description;
            this.isDuplicateEntry = true;
          }
          this.addBTForm.controls['description'].setValue(
            this.BTDetailsfromDb.Description
          );
          this.addBTForm.controls['country'].setValue(
            this.BTDetailsfromDb.CountryId
          );
          this.addBTForm.controls['industry'].setValue(
            this.BTDetailsfromDb.IndustryId
          );
        }
      })
      .catch((err) => {});
  }
  handleFilterCountry(value: any) {
    this.countryList = this.countryListDB.filter(
      (s: any) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  handleFilterIndustry(value: any) {
    this.industryList = this.industryListDB.filter(
      (s: any) =>
        s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  cancelClick() {
    this.router.navigate(['/manageBusinessType']);
  }

  saveBT() {
    var formObject = this.addBTForm.getRawValue();

    if (this.isCopy && formObject.description == this.copiedDescription) {
      this.addBTForm.markAllAsTouched();
      this.descriptionErrorMessage =
        'Please modify Description as it is not unique';
      this.addBTForm.controls['description'].setErrors({ incorrect: true });
      return;
    }
    this.isDuplicateEntry = false;
    let cID, IId, BTId;
    if (formObject.country) {
      if (typeof formObject.country === 'object')
        cID = formObject.country.CountryId;
      else cID = formObject.country;
    } else {
      cID = null;
    }
    if (formObject.industry) {
      if (typeof formObject.industry === 'object')
        IId = formObject.industry.IndustryId;
      else IId = formObject.industry;
    } else {
      IId = null;
    }
    if (this.isEdit) BTId = formObject.businessTypeId;
    else BTId = 0;

    let BTobject = {
      BusinessTypeId: BTId,
      TCoAID: '',
      Description: formObject.description,
      Library: false,
      CountryId: cID,
      IndustryId: IId,
      HelpId: '',
    };
    if (this.isEdit) {
      this.globalService
        .updateBusinessType(BTobject)
        .then((res) => {
          if (res == 'Business Type updated successfully') {
            this.toastr.successToastr(
              'Business type updated successfully',
              'Success'
            );
            this.router.navigate(['/manageBusinessType']);
          } else if (res == 'Business Type Description already exists') {
            this.addBTForm.controls['description'].markAsTouched();
            this.descriptionErrorMessage =
              'Please modify Description as it is not unique';
            this.addBTForm.controls['description'].setErrors({
              incorrect: true,
            });
            return;
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
            this.router.navigate(['/manageBusinessType']);
          }
        })
        .catch((err) => {});
    } else {
      this.globalService
        .saveBusinessType(BTobject)
        .then((res) => {
          if (res == 'Business Type added successfully') {
            this.toastr.successToastr(
              'Business type added successfully',
              'Success'
            );
            this.router.navigate(['/manageBusinessType']);
          } else if (res == 'Business Type Description already exists') {
            this.addBTForm.controls['description'].markAsTouched();
            this.descriptionErrorMessage =
              'Please modify Description as it is not unique';
            this.addBTForm.controls['description'].setErrors({
              incorrect: true,
            });
            return;
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
            this.router.navigate(['/manageBusinessType']);
          }
        })
        .catch((err) => {});
    }
  }
}
