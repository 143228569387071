import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { AnalysisstatementgridpopupComponent } from 'src/app/analysisStatement/analysisstatementgridpopup/analysisstatementgridpopup.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AnalysisReviewGridPopupComponent } from '../analysis-review-grid-popup/analysis-review-grid-popup.component';
import { Router } from '@angular/router';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-summary-statement',
  templateUrl: './summary-statement.component.html',
  styleUrls: ['./summary-statement.component.scss'],
})
export class SummaryStatementComponent implements OnInit {
  constructor(
    private dialogService: DialogService,
    public dialog: DialogRef,
    private globalService: GlobalService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    private router: Router
  ) {
    this.statementForm = this.fb.group({
      pSubAnalysis: [''],
      txtDescription: ['', [Validators.required]],
      drpLayout: ['', [Validators.required]],
    });
  }
  statementPath: string = '';
  statementGroupType: string = '';
  drpPSubAnalysisList: any[] = [];
  drpLayoutList: any[] = [];
  lineItemList: any;
  STList: any;
  TaxOutcomeList: any;
  statementForm: FormGroup | any;
  public actionsLayout: ActionsLayout = 'normal';
  warnondelete: boolean = false;
  layoutitem: any;
  isNew: boolean = false;
  isSummary: boolean = false;
  node: any;
  @Input() public popupCaption: string = '';

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  ngOnInit(): void {
    this.getAllStatementLayout();
    this.getStatementBlockByStatementID();
  }

  public saveNewAnalysisSatement() {
    var formObject = this.statementForm.getRawValue();
    let blockId, layoutID;
    let parentStatement =
      this.node.type == 'AS' ? this.node.id : this.node.Satementid;
    if (formObject.pSubAnalysis) {
      if (typeof formObject.pSubAnalysis === 'object')
        blockId = formObject.pSubAnalysis.StatementLayoutBlockId;
      else blockId = formObject.pSubAnalysis;
    } else {
      blockId = 0;
    }

    if (formObject.drpLayout) {
      if (typeof formObject.drpLayout === 'object')
        layoutID = formObject.drpLayout.StatementLayoutId;
      else layoutID = formObject.drpLayout;
    } else {
      layoutID = 0;
    }

    let ASobject = {
      ID: this.isNew == true ? 0 : this.node.id,
      FiscalYear: 2020,
      Description: formObject.txtDescription,
      BlockId: blockId,
      ParentStatement: parentStatement,
      ParentStatementGroup: this.node.type == 'AP' ? this.node.id : null,
      Layout: layoutID,
      StatementLevel: 2,
      DisplayOrder: 0,
      IsSummary: this.isSummary,
    };

    this.globalService
      .insertAnalysisStatement(ASobject)
      .then((res) => {
        if (
          res == 'Analysis Statement added successfully' ||
          res == 'Analysis Statement updated successfully'
        ) {
          this.toastr.successToastr(this.capitalize(res), 'Success');
          this.displaytreePack();
        } else if (res == 'Analysis Statement already exists') {
          this.toastr.errorToastr(this.capitalize(res), 'Failed');
          //this.displaytreePack();
          this.dialog.close();
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          //this.displaytreePack();
          this.dialog.close();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  cancelClick() {
    this.dialog.close();
  }

  getAllStatementLayout() {
    this.globalService
      .getAllStatementLayout()
      .then((res) => {
        this.drpLayoutList = res;
      })
      .catch((err) => {});
  }
  getStatementBlockByStatementID() {
    let statementGroupId = this.node.type == 'AP' ? this.node.id : 0;
    let statementID =
      this.node.Satementid == 0 ? this.node.id : this.node.Satementid;
    let isEdit = !this.isNew;
    this.globalService
      .getStatementBlockByStatementID(statementID, statementGroupId, isEdit)
      .then((res) => {
        if (res != null && this.node.Satementid != 0) {
          this.drpPSubAnalysisList = res.Item2;
          if (!this.isNew)
            this.statementForm.controls['pSubAnalysis'].setValue(
              res.Item1.BlockId
            );
          else if (this.isNew && this.node.Satementid > 0)
            this.statementForm.controls['pSubAnalysis'].setValue(this.node.id);
        } else {
          this.statementForm.controls['pSubAnalysis'].disable();
        }
        if (res != null) {
          this.statementGroupType = res.Item1.statementGroupTypeDesc;
          if (res.Item1.statementPath.indexOf('/') > 0) {
            let statementName =
              res.Item1.statementPath.split('/')[
                res.Item1.statementPath.split('/').length - 1
              ];
            if (statementName == this.node.name) {
              var lastIndex = res.Item1.statementPath.lastIndexOf('/');
              res.Item1.statementPath = res.Item1.statementPath.substring(
                0,
                lastIndex
              );
            }
          }

          this.statementPath = res.Item1.statementPath;
          if (!this.isNew) {
            this.statementForm.controls['txtDescription'].setValue(
              res.Item1.StatementDesc
            );
            this.statementForm.controls['drpLayout'].setValue(
              res.Item1.LayoutId
            );
            this.layoutitem = res.Item1;
          }
        }
      })
      .catch((err) => {});
  }
  openAnalysisStatement() {
    const dialogRef1 = this.dialogService.open({
      content: AnalysisstatementgridpopupComponent,
      width: 1200,
      height: 600,
    });
    const clientInfo1 = dialogRef1.content
      .instance as AnalysisstatementgridpopupComponent;
    dialogRef1.result.subscribe((r1: any) => {});
  }

  displaytreePack() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }
  public selectedParentTax: any;
  public onItemClickDrp(eve: any) {
    this.selectedParentTax = eve.StatementLayoutId;
    if (this.selectedParentTax != undefined && this.selectedParentTax != null) {
      if (this.layoutitem.LayoutId != this.selectedParentTax && !this.isNew)
        this.warnondelete = true;
    }
  }

  public changeLayout(changeLayout: boolean) {
    this.warnondelete = false;
    if (!changeLayout) {
      this.statementForm.controls['drpLayout'].setValue(
        this.layoutitem.LayoutId
      );
    } else {
      this.statementForm.controls['drpLayout'].setValue(this.selectedParentTax);
    }
  }
  public capitalize(text:any){
    return text[0].toUpperCase()+text.substr(1).toLowerCase()
  }
}
