<!-- Header -->
<div class="header" style="height: 40px; margin-bottom: 0px; font-size: 20px">
  Expression definition
</div>
<div id="mainContent" class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <!-- search bar and action -->
      <div class="searchContainer">
        <ap-search
          [placeholder]="'Search here'"
          [searchType]="'secondary'"
          (onSearch)="onSearch($event)"
          style="width: 100%; margin-right: 24px; padding: 0px !important"
          [disabled]="false"
        ></ap-search>
        <ap-button
          [icon]="'plus-outline'"
          (click)="newButtonClick()"
          [compact]="true"
          [add]="true"
          kendoTooltip
          title="New"
        ></ap-button>
      </div>
      <!-- grid data -->
      <kendo-grid
        [data]="gridView"
        [pageSize]="pageSize"
        [skip]="skip"
        [pageable]="true"
        (pageChange)="pageChange($event)"
        class="autoHeight"
        [reorderable]="true"
      >
        <kendo-grid-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'left'
          }"
          field="IsActive"
          title="Actions"
          [width]="140"
        >
          <ng-template kendoGridCellTemplate let-data>
            <button
              kendoButton
              kendoTooltip
              title="Edit"
              name="edit"
              (click)="editButtonClick(data)"
              style="margin-right: 5px"
            >
              <span class="Appkit4-icon icon-edit-outline"></span>
            </button>
            <button
              kendoButton
              kendoTooltip
              title="Copy"
              name="copy"
              (click)="copyButtonClick(data)"
              style="margin-right: 5px"
            >
              <span class="Appkit4-icon icon-copy-outline"></span>
            </button>
            <button
              kendoButton
              kendoTooltip
              title="Delete"
              name="delete"
              (click)="deleteButtonClick(data)"
            >
              <span class="Appkit4-icon icon-delete-outline"></span>
            </button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="RuleDescription"
          title="Rule description"
          [width]="120"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="RuleDefinition"
          title="Rule definition"
          [width]="450"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="truncatetext">
              {{ dataItem.RuleDefinition }}
            </span>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      <div class="clickToActions" style="padding-top: 10px">
        <ap-button
          [btnType]="'primary'"
          (onClick)="allocationrulebuttonClick()"
          style="width: 180px"
          >Allocation rules</ap-button
        >
      </div>
    </div>
  </div>
</div>
