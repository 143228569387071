import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ImportService } from 'src/app/common/services/importService/import.service';
import { MenubarService } from 'src/app/common/menubarService/menubar.service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import {
  TreeItemDropEvent,
  DropPosition,
  TreeItem,
} from '@progress/kendo-angular-treeview';
import {
  ChunkSettings,
  FileInfo,
  FileRestrictions,
  SelectEvent,
  SuccessEvent,
  UploadEvent,
} from '@progress/kendo-angular-upload';
import { Worksheet } from 'src/app/_models/worksheet';
@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit {
  Complex: boolean = false;
  isLoading: boolean = false;
  isConfirmOpen: boolean = false;
  isCancelDisabled: boolean = false;
  isBackDisabled: boolean = true;
  isProcessingSuccess: boolean = false;
  reversalCheckboxSelected: boolean = true;
  isImportSuccess: boolean = false;
  isImportValidationSuccess: boolean = false;
  isGlAccountFiscalyearDataAvailable: boolean = false;
  isImportFailure: boolean = false;
  isErrorAvailable: boolean = false;
  processingOrchestrationId: string = '';
  public sourceColumns: any[] = [];
  public sourceColumnModified: any[] = [];
  public destinationColumnModified: any[] = [];
  public destinationFields: any[] = [];
  public preProcessingImportData: any;
  afterImportDetails: any;
  fiscalYrGLAcctDetail : any[] = [];
  noOfRowsInFile : string = '0';
  importOutputSummaryDetail: string = '';
  failureMessage: string = '';
  uploadSaveUrl: string = '';
  minus: boolean = true;
  Selectworksheet: boolean = false;
  minusTwo: boolean = true;
  baseUrl: string = '';
  apifolder: string = '';
  withCredential: boolean = false;
  private selectedClient: any;
  fileName: string = '';
  fileId: any;
  orchestrationId: string = '';
  sheetsList: Worksheet[] = [];
  sheetslist1: any = [];
  selectedsheets: string[] = [];
  isNextVisible: boolean = false;
  isNextDisabled: boolean = true;
  importstartPayload: {} = {};
  importProcessingStartPayload: any;
  public chunkSettings: ChunkSettings = {
    size: 1048576,
  };
  myRestrictions: FileRestrictions = {
    allowedExtensions: ['.xls', '.xlsx'],
  };
  transdownloadXLStartPayload: { inputFileName: any } | undefined;

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private globalService: GlobalService,
    private importService: ImportService,
    public menubarService: MenubarService
  ) {}

  ngOnInit(): void {
    this.menubarService.hide();
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;

    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);

    this.uploadSaveUrl =
      this.baseUrl +
      this.apifolder +
      '/TransactionImport/TransactionChunkUploadSave';
  }

  singleExpand: any[] = [];
  activeIndex: number = 0;
  radioDisabled = false;
  showRadioLabel = true;
  groupRadioSelect: string = 'Yes';

  list2 = [{ name: 'Yes', key: 'A' }];

  orientation = 'horizontal';
  readonly = true;
  // activeIndex = 3;
  space = 190;
  steps = [
    { label: 'Import wizard' },
    { label: 'Select worksheet' },
    { label: 'Data validation' },
    { label: 'Data column mapping' },
    { label: 'Data import' },
  ];

  onActiveIndexChange(index: number) {}

  minusButton(event: any) {
    this.minus = false;
  }
  minusButton2(event: any) {
    this.minusTwo = false;
  }
  hidecontent(event: any) {
    this.minus = true;
  }
  hidecontenttwo(event: any) {
    this.minusTwo = true;
  }
  checkboxSelected = true;
  checkboxDisabled = false;
  showCheckboxLabel = true;
  tempState = false;

  onClick(event: any): void {}
  onCheckboxchange(event: any) {
    //this.isNextDisabled=false;
    let array1 = this.sheetslist1.filter((obj: any) => obj.selected == true);

    if (array1.length > 0) this.isNextDisabled = false;
    else this.isNextDisabled = true;
  }
  handleNextButton() {
    this.isNextDisabled = true;
    this.isBackDisabled = false;
    if (this.activeIndex == 1) {
      this.preprocessingfile();
    }
    if (this.activeIndex == 2) {
      this.isNextDisabled = false;
    }
    if (this.activeIndex == 3) {
      this.isBackDisabled = true;
      this.isNextDisabled = true;
      //this.isCancelDisabled = true;
      this.importTransactionprocessing();
    }
    if (this.activeIndex < this.steps.length - 1)
      this.activeIndex = this.activeIndex + 1;
  }
  handleBackButton() {
    if (this.activeIndex > 0) this.activeIndex = this.activeIndex - 1;
    if (this.activeIndex < 2) this.isCancelDisabled = false;
    if (this.activeIndex == 1) this.isCancelDisabled = true;
    if (this.activeIndex == 0) this.isBackDisabled = true;
  }
  preprocessingfile() {
    this.isLoading = true;

    let array1 = this.sheetslist1.filter((obj: any) => obj.selected == true);

    this.selectedsheets = array1.map((item: any) => {
      return item.sheetname;
    });

    this.importstartPayload = {
      fileName: this.fileId + '/' + this.fileName,
      isFirstRowAsHeaderRow: true,
      sheetNames: this.selectedsheets,
      isTbReversalNeedToCreate: this.reversalCheckboxSelected,
      clientDbName: this.selectedClient.DBName,
    };
    this.importService
      .importTransactionsPreProcessingStart(this.importstartPayload)
      .subscribe(
        (response: any) => {
          if (response) {
            this.orchestrationId = response.orchestrationId;
            this.getstatus();
          }
          //this.isNextVisible = true;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  public nonMandate: any[] = [];
  getstatus() {
    this.importService
      .importTransactionsPreProcessingStatus(this.orchestrationId)
      .subscribe(
        (response: any) => {
          if (
            response.orchestrationRuntimeStatus == 'Completed' ||
            response.orchestrationRuntimeStatus == 'Failed'
          ) {
            this.isLoading = false;
            this.isNextDisabled = false;
            if (
              response.orchestrationRuntimeStatus == 'Completed' &&
              response.isPreProcessFileCompletedWithSuccess
            ) {
              this.isProcessingSuccess = true;
              this.handleNextButton();
              this.preProcessingImportData = response;
              this.destinationFields =
                response.sourceAndDestinationColumnsWithMappingDetail.destinationFields;
              this.sourceColumns =
                response.sourceAndDestinationColumnsWithMappingDetail.sourceColumns;
              this.destinationFields.forEach((ele, value) => {
                if (ele.mandatory == false) {
                  this.nonMandate.push(ele);
                  //this.destinationFields.splice(value, 1);
                }
              });

              this.sourceColumnModified = [
                {
                  name: 'Source Data Columns',
                  sampleData: this.sourceColumns,
                  type: 'folder',
                },
              ];
              this.isNextDisabled = this.checkmapping();
              this.getData();
              this.destinationColumnModified = [
                {
                  name: 'Destination Data Columns',
                  mapping: this.destinationFields,
                  type: 'folder',
                },
              ];
            } else if (
              response.orchestrationRuntimeStatus == 'Failed' ||
              !response.isPreProcessFileCompletedWithSuccess
            ) {
              this.isProcessingSuccess = false;
              this.isNextDisabled = true;
              this.failureMessage = response.preProcessingValidationMessage;
            }
          } else this.getstatus();
        },
        (error: any) => {
          console.log(error);
          return error;
        }
      );
  }
  public checkedItems: Array<String> = [];
  public checkedItems1: Array<String> = [];
  public isChecked(index: String): boolean {
    if (this.checkedItems.indexOf(index) >= 0) {
      return true;
    } else {
      return false;
    }
  }
  public async handleDrop(event: TreeItemDropEvent) {
    // prevent the default to prevent the drag-and-drop directive from emitting `addItem` and inserting items with duplicate IDs

    if (
      event.dropPosition != 0 ||
      event.destinationItem.parent != null ||
      event.destinationItem.item.dataItem.mapping != null
    )
      event.preventDefault();
    if (
      event.sourceItem.item.dataItem.dataTypeStringValue == 'DateTimeDataType'
    ) {
      if (
        event.destinationItem.item.dataItem.dataTypeStringValue !=
          'DateTimeDataType' &&
        event.destinationItem.item.dataItem.dataTypeStringValue !=
          'StringDataType'
      )
        event.preventDefault();
    }
    if (
      event.destinationItem.item.dataItem.dataTypeStringValue ==
        'DateTimeDataType' &&
      event.sourceItem.item.dataItem.dataTypeStringValue != 'DateTimeDataType'
    )
      event.preventDefault();
    if (
      (event.destinationItem.item.dataItem.dataTypeStringValue ==
        'IntegerDataType' ||
        event.destinationItem.item.dataItem.dataTypeStringValue ==
          'DecimalDataType' ||
        event.destinationItem.item.dataItem.dataTypeStringValue ==
          'DoubleDataType') &&
      event.sourceItem.item.dataItem.dataTypeStringValue == 'StringDataType'
    ) {
      event.preventDefault();
    }

    if (!event.isDefaultPrevented()) {
      if (event.sourceItem.item.dataItem.name.includes(' ^(')) {
        var y: number = event.sourceItem.item.dataItem.name
          .split(' ^(')[1]
          .split(')')[0];
        y++;
        event.sourceItem.item.dataItem.name =
          event.sourceItem.item.dataItem.name.split(' ^(')[0] + ' ^(' + y + ')';
      } else
        event.sourceItem.item.dataItem.name =
          event.sourceItem.item.dataItem.name + ' ^(1)';
    }

    await new Promise((f) => setTimeout(f, 1000));
    this.getData1();
    this.isNextDisabled = this.checkmapping();
  }
  checkmapping() {
    //this.destinationFields.filter()

    var mandatoryArray = this.destinationFields.filter(function (el) {
      return el.mandatory == true;
    });
    var newArray = mandatoryArray.filter(function (el) {
      return el.mapping != null;
    });

    if (newArray.length == mandatoryArray.length) return false;
    else return true;
  }
  getData1() {
    let i = 0;
    this.singleExpand = [];

    let childFields: any[] = [];
    this.destinationFields.forEach((ele, index) => {
      ele.id = index + 1;
      if (ele.mapping != null && ele.mapping[0].name.includes(' ^(')) {
        ele.mapping[0].name = ele.mapping[0].name.split(' ^(')[0];
      }
      this.singleExpand.push(index++);
      if (ele.mapping != null) childFields.push(ele.mapping[0].name);
    });
    this.destinationFields.forEach((ele, index) => {
      ele.id = index + 1;

      this.singleExpand.push(ele.id);
    });
  }
  getData() {
    let i = 0;
    this.singleExpand = [];

    let childFields: any[] = [];
    this.destinationFields.forEach((ele, index) => {
      ele.id = index + 1;
      if (ele.mapping != null && ele.mapping[0].name.includes(' ^(')) {
        ele.mapping[0].name = ele.name.split(' ^(')[0];
      }
      this.singleExpand.push(ele.id);
      if (ele.mapping != null) childFields.push(ele.mapping[0].name);
    });
    this.sourceColumns.forEach((ele, index) => {
      ele.id = index + 1;
      let i = 0;
      childFields.forEach((ele1) => {
        if (ele1.split(' ^(')[0] == ele.name.split(' ^(')[0]) {
          i++;
        }
      });
      if (i != 0) {
        if (ele.name.includes(' ^(')) {
          ele.name = ele.name.split(' ^(')[0] + ' ^(' + i + ')';
        } else ele.name = ele.name + ' ^(' + i + ')';
      } else ele.name = ele.name;
      if (i == 0) {
        ele.name = ele.name.split(' ^(')[0];
      }
    });
  }
  importTransactionprocessing() {
    this.isLoading = true;
    this.sourceColumns.forEach((ele) => {
      ele.name = ele.name.split(' ^(')[0];
    });
    this.nonMandate.forEach((ele) => {
      this.destinationFields.push(ele);
    });
    this.importProcessingStartPayload = {
      fileName: this.fileId + '/' + this.fileName,
      isFirstRowAsHeaderRow: true,
      sheetNames: this.selectedsheets,
      isTbReversalNeedToCreate: this.reversalCheckboxSelected,
      clientDbName: this.selectedClient.DBName,
      sourceAndDestinationColumnsWithMappingDetail: {
        id: this.preProcessingImportData
          .sourceAndDestinationColumnsWithMappingDetail.id,
        sourceColumns: this.sourceColumns,
        destinationFields: this.destinationFields,
        mappingType:
          this.preProcessingImportData
            .sourceAndDestinationColumnsWithMappingDetail.mappingType,
      },
      noOfRowsInFile : this.noOfRowsInFile
    };
    
    this.importService
      .importTransactionValidationProcessingStart(this.importProcessingStartPayload)
      .subscribe(
        (response: any) => {
          if (response) {
            this.processingOrchestrationId = response.orchestrationId;
            this.getImportValidationProcessingStatus();
          }
          //this.isNextVisible = true;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  getImportValidationProcessingStatus() {
    this.importService
      .importTransactionValidationProcessingStatus(this.processingOrchestrationId)
      .subscribe(
        (response: any) => {
          if (
            response.orchestrationRuntimeStatus == 'Completed' ||
            response.orchestrationRuntimeStatus == 'Failed'
          ) {
            this.isLoading = false;

            this.afterImportDetails = response;
            this.isImportValidationSuccess = true;
            this.noOfRowsInFile = this.afterImportDetails.noOfRowsInFile;
            if (this.afterImportDetails.fiscalYrGLAcctDetail.length > 0 ) {
              this.fiscalYrGLAcctDetail = this.afterImportDetails.fiscalYrGLAcctDetail;
              this.isGlAccountFiscalyearDataAvailable = true;
            }
            else{
              this.continueImport('yes');
            }
          } else this.getImportValidationProcessingStatus();
        },
        (error: any) => {
          console.log(error);
          return error;
        }
      );
  }

  getProcessingStatus() {
    this.importService
      .importTransactionProcessingStatus(this.processingOrchestrationId)
      .subscribe(
        (response: any) => {
          if (
            response.orchestrationRuntimeStatus == 'Completed' ||
            response.orchestrationRuntimeStatus == 'Failed'
          ) {
            this.isLoading = false;

            this.afterImportDetails = response;
            this.isImportSuccess = true;
            this.importOutputSummaryDetail = response.importOutputSummaryDetail;
            if (this.afterImportDetails.isAnyErrorRows) {
              this.isErrorAvailable = true;
            }
          } else this.getProcessingStatus();
        },
        (error: any) => {
          console.log(error);
          return error;
        }
      );
  }
  public open(): void {
    if (this.checkedItems.length > 0) {
      this.close1('yes');
      this.getData();
      this.isNextDisabled = this.checkmapping();
    }

    //this.opened1 = true;
  }
  close1(status: any) {
    this.checkedItems.forEach((ele) => {
      let i = ele.split('_')[0];
      var y: number = +i;
      this.destinationFields[y].mapping = null;
    });

    this.checkedItems = [];
  }
  public onChange(event: Event, index: String): void {
    const isItemChecked = this.checkedItems.indexOf(index) >= 0;

    if ((event.target as HTMLInputElement).checked && !isItemChecked) {
      this.checkedItems = [...this.checkedItems, index];
    }

    if (!(event.target as HTMLInputElement).checked && isItemChecked) {
      this.checkedItems = this.checkedItems.filter((i) => i !== index);
    }
  }
  clearAll() {
    this.isNextDisabled = true;
    this.destinationFields.forEach((ele) => {
      ele.mapping = null;
    });
    this.getData();
  }
  finish() {
    this.unlockClient();
    this.menubarService.show();
    this.router.navigate(['/home']).then(() => {
      window.location.reload();
    });
  }
  uploadEventHandler(e: UploadEvent) {
    let tokenValue: any = localStorage.getItem('OPENAMSESSION');
    e.headers = e.headers.append(
      'Authorization-Token',
      'Bearer ' + JSON.parse(tokenValue).access_token
    );
    e.data = {
      ClientdbName: this.selectedClient.DBName,
    };
  }
  public success(e: SuccessEvent): void {
    if (e.files.length > 0) {
      this.fileName = e.files[0].name;
      this.fileId = e.files[0].uid?.toString();

      if (e.response.body.FileUploaded == true) {
        this.getSheetNames(this.fileId, this.fileName);
      }
    }
    this.isNextVisible = true;
    this.isNextDisabled = false;
  }
  getSheetNames(fileId: string, fileName: string): void {
    this.importService
      .getSheetNamesTransactions(fileId + '/' + fileName)
      .subscribe(
        (response: any) => {
          this.sheetslist1 = response.map((value: string) => {
            return {
              sheetname: value,
              selected: false,
            };
          });
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  getImportTextFileReport() {
    this.importService
      .ExportTransactionReportTextFile(
        this.afterImportDetails.importReportTextFileName
      )
      .subscribe(
        (response: any) => {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          const fileName = this.afterImportDetails.importReportTextFileName;
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  downloadErrorExcel() {
    this.isLoading = true;

    this.transdownloadXLStartPayload = {
      inputFileName: this.afterImportDetails.inputFileName,
    };
    this.importService
      .ImportTransactionErrorRowExcelDownloadStart(
        this.transdownloadXLStartPayload
      )
      .subscribe(
        (response: any) => {
          if (response) {
            this.processingOrchestrationId = response.orchestrationId;
            this.getdownloadXLstatus(response.orchestrationId);
          }
          //this.isNextVisible = true;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  getdownloadXLstatus(instanceId: string) {
    this.importService
      .GetImportTransactionErrorRowExcelDownloadStatus(instanceId)
      .subscribe(
        (response: any) => {
          if (
            response.body.orchestrationRuntimeStatus == 'Completed' ||
            response.body.orchestrationRuntimeStatus == 'Failed'
          ) {
            if (response.body.orchestrationRuntimeStatus == 'Completed') {
              this.downloadErrorRowExcelFile(response.body);
            } else if (response.body.orchestrationRuntimeStatus == 'Failed') {
            }
          } else this.getdownloadXLstatus(instanceId);
        },
        (error: any) => {
          console.log(error);
          return error;
        }
      );
  }
  downloadErrorRowExcelFile(downloadErrorFileName: any) {
    this.importService
      .downloadTransactionErrorExcelFile(
        downloadErrorFileName.importErrorRowExcelFileName
      )
      .subscribe(
        (response: any) => {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          const fileName = downloadErrorFileName.importErrorRowExcelFileName;
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
          this.isLoading = false;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  public close(status: string): void {
    if (status == 'yes') {
      this.isConfirmOpen = false;
      this.unlockClient();
      this.menubarService.show();
      this.router.navigate(['/home']);
    } else {
      this.isConfirmOpen = false;
    }
  }
  handleCancelButton() {
    this.isConfirmOpen = true;
  }

  public continueImport(status: string): void {
    if (status == 'yes') {
      this.isLoading = true;
      this.isGlAccountFiscalyearDataAvailable = false;
      this.importProcessingStartPayload.noOfRowsInFile = this.noOfRowsInFile;
      this.importService
      .importTransactionProcessingStart(this.importProcessingStartPayload)
      .subscribe(
        (response: any) => {
          if (response) {
            this.processingOrchestrationId = response.orchestrationId;
            this.getProcessingStatus();
          }
          //this.isNextVisible = true;
        },
        (error: any) => {
          console.log(error);
        }
      );
      
    } else {
      this.isGlAccountFiscalyearDataAvailable = false;
      this.unlockClient();
      this.menubarService.show();
      this.router.navigate(['/home']);
    }
  }
  unlockClient() {
    this.globalService.UnLockSegment('Import transactions').subscribe(
      (response) => {},
      (error) => {
        console.log(error);
      }
    );
  }
  onPopState(event: any) {
    this.unlockClient();
  }
}
