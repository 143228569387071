<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%;"></kendo-chunkprogressbar>
</div>
<div class="header">Manage transaction lines</div>
<div id="mainContent" class="container-fluid">
  <div class="row">
    <!-- <div class="col-md-12 col-xs-12"></div> -->
    <div class="col-md-12 col-xs-12">
      <!-- search bar and action -->
      <div class="searchContainer">
        <ap-search
          [placeholder]="'Search here'"
          [searchType]="'secondary'"
          style="width: 100%; margin-right: 2px; padding: 0px !important"
          [disabled]="false"
          (onSearch)="onFilter($event)"></ap-search>
      </div>
      <!-- Sub Header -->
      <div class="subHeader">Manage GL accounts</div>
      <!-- Data grid -->
      <kendo-grid
        [data]="transactionitemList"
        scrollable="virtual"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        class="gridHeight"
        [selectable]="true"
        (selectionChange)='onGridSelectionChange($event)'
        [reorderable]="true">
        <kendo-grid-checkbox-column
          [showSelectAll]="true" [width]="45"></kendo-grid-checkbox-column>
        <kendo-grid-column
          field="AccountNumber"
          title="GL accounts"
          [width]="130"
          [headerStyle]="{
          'font-weight': 'bold',
          'text-align': 'left',
          color: '#d04a02'
          }"></kendo-grid-column>
        <kendo-grid-column
          field="LongDescription"
          title="Description"
          [width]="420"
          [headerStyle]="{
          'font-weight': 'bold',
          'text-align': 'left',
          color: '#d04a02'
          }"></kendo-grid-column>
        <kendo-grid-column
          field="Statement_Analysis"
          title="Analysis statement"
          [width]="250"
          [headerStyle]="{
          'font-weight': 'bold',
          'text-align': 'left',
          color: '#d04a02'
          }"></kendo-grid-column>
        <kendo-grid-column
          field="Amount"
          title="Amount"
          [width]="150"
          [style]="{'text-align': 'right'}"
          [headerStyle]="{
          'font-weight': 'bold',
          'text-align': 'right',
          color: '#d04a02'
          }">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [style]="
              AddColorFormat(
              dataItem.Amount | tonumber
              )
              ">
              {{
              (dataItem.Amount == null ? dataItem.Amount : dataItem.Amount)
              | tonumber
              }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="TranCount"
          title="Number of transactions"
          [width]="150"
          [headerStyle]="{
          'font-weight': 'bold',
          'text-align': 'left',
          color: '#d04a02'
          }"></kendo-grid-column>
      </kendo-grid>

      <kendo-formfield style="position: absolute;
        ">
        <ap-checkbox
          (onChange)="onTrialBalancechange($event)"
          [(ngModel)]="selectedTrialBalance"
          formControlName="lineitem"
          #lineitem>
          <span>Exclude trial balance data</span>
        </ap-checkbox>
      </kendo-formfield>
      <div class="clickToActions" style="padding-top: 10px;">
        <ap-button [btnType]="'primary'" [label]="'Delete'"
          (click)="deleteTransactionData()" style="margin-left: 7px;"></ap-button>
      </div>
    </div>
  </div>
</div>

<kendo-dialog
  title="Delete transactions"
  *ngIf="warnondelete"
  (close)="cancelClick()"
  [minWidth]="250"
  [width]="500">
  <div style="display: flex">
    <p>
      <span
        class="Appkit4-icon icon-circle-warning-outline"
        style="font-size: 24px; color: #d04a02"></span>
    </p>
    <p style="margin-left: 10px; font-size: 14px">
      {{messagePop}}
    </p>
  </div>
  <!-- Click to Actions -->
  <div class="clickToActions" style="padding: 10px 0px 0px 0px;"
    *ngIf="ondeleteTran">
    <ap-button
      [btnType]="'secondary'"
      [label]="'No'"
      (click)="cancelClick()"
      class="cancelButton"
      style="width: 100px;"></ap-button>
    <ap-button
      [btnType]="'primary'"
      [label]="'Yes'"
      themeColor="primary"
      (click)="deleteTran()"
      class="okButton"
      style="margin-right: 6px;"></ap-button>
  </div>
