<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Delete line item</div>
</kendo-dialog-titlebar>

<div style="display: flex" *ngIf="warnondelete">
  <p>
    <span
      class="Appkit4-icon icon-circle-warning-outline"
      style="color: #d04a02; font-size: 24px"
    ></span>
  </p>
  <p style="margin-left: 10px; text-align: left; font-size: medium">
    {{ responceData }}
  </p>
</div>
<div style="float: right" *ngIf="warnondelete">
  <ap-button
    [btnType]="'primary'"
    [label]="'OK'"
    themeColor="primary"
    (click)="this.dialog.close()"
    class="okButton"
  ></ap-button>
</div>

<div style="display: flex" *ngIf="!warnondelete">
  <p>
    <span
      class="Appkit4-icon icon-circle-warning-outline"
      style="font-size: 24px; color: #d04a02"
    ></span>
  </p>
  <p style="margin-left: 10px; font-size: medium">
    You have selected to delete the selected line items(s) <br /><br />Are you
    sure you wish to continue?
  </p>
</div>
<!-- Click to Actions -->
<div class="clickToActions" *ngIf="!warnondelete">
  <ap-button
    [btnType]="'secondary'"
    [label]="'No'"
    (click)="cancelClick()"
    class="cancelButton"
    style="width: 100px"
  ></ap-button>
  <ap-button
    [btnType]="'primary'"
    [label]="'Yes'"
    themeColor="primary"
    (click)="deleteLineItem()"
    style="margin-right: 6px"
    class="okButton"
  ></ap-button>
</div>

<!-- <kendo-dialog title="Delete line items" *ngIf="warnondelete" (close)="this.dialog.close()" [minWidth]="250"
    [width]="600" [height]="250">
    <div style="display: flex;">
        <p><span class="Appkit4-icon icon-circle-warning-outline" style="color:#d04a02; font-size: 24px;"></span></p>
        <p style="margin-left: 14px; text-align: left; font-size: medium;">
        {{responceData}}
        </p>
    </div>
    <div style="float: right;">
        <ap-button [btnType]="'primary'" [label]="'Ok'" 
        themeColor="primary"
        (click)="this.dialog.close()"></ap-button>
    </div>
</kendo-dialog> -->
