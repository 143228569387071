import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

//import { AnalysisPack } from 'src/app/analysis-pack';

import { LegalEntity } from 'src/app/_models/legalEntity';
import { AnalysisPack } from 'src/app/_models/analysis-pack';

@Injectable({
  providedIn: 'root',
})
export class AnalysispackService {
  private params: any;
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private mockApiUrl: any;
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    //  this.apiPort = environment.apiPort;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getLegalEntityByBusinessType:
        '/LegalEntities/GetLegalEntitiesForBusinessType',
      saveAnalysisPack: '/AnalysisTreeView/InsertAnalysisPack',
      deleteAnalysisPack: '/AnalysisTreeView/DeleteAnalysisPack',
      getAnalysisPackById: '/AnalysisTreeView/getAnalysisPackById',
      UpdtaeAnalysisPack: '/AnalysisTreeView/UpdateAnalysisPack',
      getAlllinkAnalysisStatementData:
        '/AnalysisTreeView/GetLinkStatementByStatementGroup',
      insertSummaryStatement: '/AnalysisTreeView/InsertSummaryStatement',
      unLinkAnalysisStatement: '/AnalysisTreeView/UnlinkAnalysisStatement',
    };
  }
  getAllLegalEntityByBusinessType(
    BusinessTypeId: number
  ): Observable<LegalEntity[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('bussinessTypeId', BusinessTypeId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getLegalEntityByBusinessType;
    return this.http.get<LegalEntity[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getAnalysisPackByID(statementGroupId: number): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementGroupId', statementGroupId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAnalysisPackById;
    return this.http.get<any>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  saveAnalysisPack(apDetails: AnalysisPack): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.saveAnalysisPack;

    return this.http.post(this.apiHost, apDetails, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  deleteAnalysisPack(statementGroupId: number): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      // .set('statementGroupId', 21);
      .set('statementGroupId', statementGroupId);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteAnalysisPack;
    return this.http
      .post(this.apiHost, statementGroupId, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  updateAnalysisPack(apDetails: any): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.UpdtaeAnalysisPack;
    return this.http.post(this.apiHost, apDetails, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  insertSummaryStatement(request: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    // let apiURL=(request.ID==0?this.apiUrl.insertSummaryStatement:this.apiUrl.updateAnalysisStatement)
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.insertSummaryStatement;
    return this.http
      .post(this.apiHost, request, {
        headers: headersObj,
        withCredentials: false,
        //responseType: ' ',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }
  getAlllinkAnalysisStatementData(statementId: number): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      //.set('statementId',27);
      .set('statementId', statementId);

    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getAlllinkAnalysisStatementData;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }
  unLinkAnalysisStatement(
    parentStatementID: number,
    childStatementID: number
  ): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('parentStatementID', parentStatementID)
      .set('childStatementID', childStatementID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.unLinkAnalysisStatement;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }
}
