export const APoptions: any[] = [
  {
    text: 'Edit analysis pack',
  },

  {
    text: 'Delete analysis pack',
  },
  {
    text: 'Export analysis pack',
  },
  {
    text: 'New summary statement',
  },
  {
    text: 'New analysis statement',
  },
];
export const subLEOPtions: any[] = [
  {
    text: 'new',
  },
  {
    text: 'sub',
  },
];
export const LEOPtions: any[] = [
  {
    text: 'New analysis pack',
  },
];
export const SSOPtions: any[] = [
  {
    text: 'Edit analysis statement',
  },
  {
    text: 'Delete analysis statement',
  },
  // {
  //   text: 'Export analysis statement',
  // },
  {
    text: 'Move',
  },
];
export const ASoptions: any[] = [
  {
    text: 'Edit analysis statement',
  },
  {
    text: 'Delete analysis statement',
  },
  // {
  //   text: 'Export analysis statement',
  // },
  {
    text: 'Move',
  },
];
export const SAoptions: any[] = [
  {
    text: 'New analysis statement',
  },
  {
    text: 'Link line item',
  },
  {
    text: 'New line item',
  },
];
export const CLIoptions: any[] = [
  {
    text: 'Move',
    items: [
      { text: 'Up' },
      { text: 'Down' },
      { text: 'Bottom' },
      { text: 'Top' },
    ],
  },
  {
    text: 'Edit line item',
  },
  {
    text: 'Unlink line item',
  },
  {
    text: 'Delete line item',
  },
  {
    text: 'Edit tax account',
  },

  {
    text: 'Offline allocation',
  },

  {
    text: 'Add attachment',
  },
  {
    text: 'Remove attachment',
  },
  // {
  //   text: 'Create adjustmant journals',
  // },
  // {
  //   text: 'Manage transactions',
  // },
];
export const SLIoptions: any[] = [
  {
    text: 'Move',
  },
  {
    text: 'Edit line item',
  },
  {
    text: 'Unlink line item',
  },
  {
    text: 'Delete line item',
  },
  {
    text: 'Edit tax account',
  },
  {
    text: 'Add attachment',
  },
  {
    text: 'Remove attachment',
  },
  // {
  //   text: 'Create adjustmant journals',
  // },
  // {
  //   text: 'Manage transactions',
  // },
];

export const SS_SAoptions: any[] = [
  {
    text: 'Link line item',
  },
  {
    text: 'Link analysis statement',
  },
];

export const SS_ASoptions: any[] = [
  {
    text: 'Move',
    items: [
      { text: 'Up' },
      { text: 'Down' },
      { text: 'Bottom' },
      { text: 'Top' },
    ],
  },
  {
    text: 'Unlink analysis statement',
  },
];

export const SS_LEoptions: any[] = [
  {
    text: 'Add attachment',
  },
  {
    text: 'Remove attachment',
  },
];
export const SS_LIOptions: any[] = [
  {
    text: 'Move',
  },
  {
    text: 'Unlink line item',
  },
  {
    text: 'Add attachment',
  },
  {
    text: 'Remove attachment',
  },
  {
    text: 'Manage transactions',
  },
];
export const SS_Childoptions: any[] = [];
export const ULI_Childoptions: any[] = [
  {
    text: 'Edit line item',
  },
  {
    text: 'Delete line item',
  },
  {
    text: 'Edit tax account',
  },
  {
    text: 'Offline allocation',
  },
  {
    text: 'Add attachment',
  },
  {
    text: 'Remove Attachment',
  },
  // {
  //   text: 'Create adjustmant journals',
  // },
  // {
  //   text: 'Manage transactions',
  // },
];
