import { Component, OnInit, Input } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
  aggregateBy,
} from '@progress/kendo-data-query';

@Component({
  selector: 'app-account-summery',
  templateUrl: './account-summery.component.html',
  styleUrls: ['./account-summery.component.scss'],
})
export class AccountSummeryComponent implements OnInit {
  public isLoading: boolean = false;
  @Input() GLaccountSummary: any;
  // public fiscalYear;
  public gridData: any = [];
  TotalAmount: any;
  total: any;
  public aggregates: any[] = [{ field: 'TotalAmount', aggregate: 'sum' }];
  public state: State = {};
  public ColorFormat(amount: any) {
    return amount >= 0 ? 'color: black' : 'color: red';
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.GLaccountSummary = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }
  public sort: SortDescriptor[] = [
    {
      field: 'AccountNumber',
      dir: 'asc',
    },
  ];
  constructor(public dialog: DialogRef) {}

  ngOnInit(): void {
    console.log(this.GLaccountSummary);
    this.gridData = this.GLaccountSummary;
    this.state = {
      skip: 0,
      take: this.GLaccountSummary.total,
    };
    this.GLaccountSummary = process(this.GLaccountSummary, this.state);
    this.total = aggregateBy(this.GLaccountSummary.data, this.aggregates);
  }
  closeButtonClick() {
    this.dialog.close();
  }
}
