<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>
<div class="header" style="margin-bottom: 0px">Manage keyword groups</div>
<mat-card class="userCard" style="padding-top: 10px">
  <!-- <mat-card-title style="font-size: 18px; line-height: 1.5em;background-color: #D04A02;color: white;">
    &nbsp;Manage Keyword Groups
  </mat-card-title> -->
  <kendo-treelist
    [data]="keywordData"
    style="width: 100%"
    [kendoTreeListHierarchyBinding]="keywordData"
    childrenField="items"
    [sortable]="{ mode: 'multiple' }"
    (add)="addHandler($event)"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
    (save)="saveHandler($event)"
    (cancel)="cancelHandler($event)"
    class="keywordTableResponsive"
    kendoTreeListExpandable
    expandBy="id"
    [(expandedKeys)]="expandedNames"
    (expandedKeysChange)="onExpandedNamesChange($event)"
  >
    <ng-template kendoTreeListToolbarTemplate>
      <ap-button
        kendoTreeListAddCommand
        [btnType]="'primary'"
        [label]="'Add new'"
      ></ap-button>

      <ap-button
        [btnType]="'secondary'"
        [label]="'Import keywords'"
        (onClick)="importHandler($event)"
      ></ap-button>
      <ap-button
        [btnType]="'secondary'"
        [label]="'Export keywords'"
        (onClick)="exportHandler($event)"
      ></ap-button>
    </ng-template>
    <kendo-treelist-column
      [expandable]="true"
      field="description"
      title="Keyword"
    >
    </kendo-treelist-column>

    <kendo-treelist-command-column [width]="170" title="Actions">
      <ng-template
        kendoTreeListCellTemplate
        let-isNew="isNew"
        let-cellContext="cellContext"
      >
        <!-- "Add Child" command directive, will not be visible in edit mode -->
        <button [kendoTreeListAddCommand]="cellContext" title="Add child">
          <span class="Appkit4-icon icon-circle-plus-outline"></span>
        </button>

        <!-- "Edit" command directive, will not be visible in edit mode -->

        <button [kendoTreeListEditCommand]="cellContext" title="Edit">
          <span class="Appkit4-icon icon-edit-outline"></span>
        </button>

        <!-- "Remove" command directive, will not be visible in edit mode -->
        <button [kendoTreeListRemoveCommand]="cellContext" title="Delete">
          <span class="Appkit4-icon icon-delete-outline"></span>
        </button>

        <!-- "Save" command directive, will be visible only in edit mode -->
        <button
          [kendoTreeListSaveCommand]="cellContext"
          title="{{ isNew ? 'Add' : 'Update' }}"
        >
          <span class="Appkit4-icon icon-save-outline"></span>
        </button>

        <!-- "Cancel" command directive, will be visible only in edit mode -->
        <button
          [kendoTreeListCancelCommand]="cellContext"
          icon="cancel"
          title="{{ isNew ? 'Discard changes' : 'Cancel' }}"
        ></button>
      </ng-template>
    </kendo-treelist-command-column>
  </kendo-treelist>
</mat-card>
