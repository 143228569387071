<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Help</div>
</kendo-dialog-titlebar>

<div style="display: flex; margin-top: 5px">
  <p style="margin-left: 8px; font-size: medium">
    Please contact your PwC relationship team for assistance.
  </p>
</div>
<!-- Click to Actions -->
<div class="clickToActions">
  <ap-button
    class="okButton"
    [btnType]="'primary'"
    [label]="'OK'"
    themeColor="primary"
    (click)="cancelClick()"
    style="margin-right: 6px"
  ></ap-button>
</div>
