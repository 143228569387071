<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Manage tax account</div>
</kendo-dialog-titlebar>

<mat-card class="userCard" *ngIf="isEdit" style="padding: 0px">
  <h6>
    Line item details - Business type:
    {{ AccountingAreaDescriptionListDB?.TaxChartDescription }}
  </h6>
  <mat-card-content>
    <div>
      <form class="k-form" [formGroup]="taxAccountForm">
        <kendo-formfield>
          <kendo-label text="Tax account"></kendo-label>
          <input [maxlength]="50" formControlName="taxAccount" kendoTextBox />
        </kendo-formfield>
        <!-- <ap-field [title]="'Tax Account'">
          <input appkit-field formControlName="taxAccount" />
        </ap-field>
        <br /> -->
        <!-- <ap-field [title]="'Short Description'" [required]="true">
          <input appkit-field formControlName="shortDesc" />
          <div
            *ngIf="taxAccountForm.controls['shortDesc'].invalid && (taxAccountForm.controls['shortDesc'].dirty || taxAccountForm.controls['shortDesc'].touched)"
            id="errormessage" aria-live="polite" class="ap-field-email-validation-error">Please enter short Description
          </div>
        </ap-field><br /><br /> -->
        <kendo-formfield>
          <kendo-label text="Short description *"></kendo-label>
          <input
            kendoTextBox
            #shortDesc
            [maxlength]="50"
            formControlName="shortDesc"
          />
          <kendo-formerror>Please enter short Description</kendo-formerror>
        </kendo-formfield>
        <!-- <ap-field [title]="'Long Description'">
          <textarea appkit-field maxlength="420" formControlName="longDesc"></textarea>

        </ap-field><br /> -->
        <kendo-formfield>
          <kendo-label text="Long description"></kendo-label>
          <kendo-textarea
            #longDesc
            [style.width.%]="100"
            [maxlength]="420"
            formControlName="longDesc"
          >
          </kendo-textarea>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            [for]="indicator"
            text="P&L,B/S indicator*"
          ></kendo-label>
          <kendo-combobox
            #indicator
            required
            formControlName="indicator"
            [data]="dropdownIndicator"
            textField="label"
            valueField="value"
          >
          </kendo-combobox>
          <kendo-formerror>P&L,B/S indicator is required</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            [for]="parentTaxAccount"
            [text]="parentTaxAccLabel"
          ></kendo-label>
          <kendo-combobox
            #parentTaxAccount
            formControlName="parentTaxAccount"
            [data]="parentTaxAccountList"
            [filterable]="true"
            textField="shortdescription"
            valueField="TaxAccountCodeId"
            placeholder="Select parent tax Account"
            (valueChange)="handleParentTaxAccountChange($event)"
            (filterChange)="handleFilterParentTaxaccount($event)"
          >
          </kendo-combobox>
          <kendo-formerror>Parent tax account is required</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="AccountClass" text="Account class*"></kendo-label>
          <kendo-combobox
            #AccountClass
            required
            formControlName="accountClass"
            [data]="AccountClassDescriptionList"
            textField="Description"
            valueField="ID"
            (valueChange)="handleAccountClassChange($event)"
          >
          </kendo-combobox>
          <kendo-formerror>Account class is required</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="lineitem" text="Line item*"></kendo-label>
          <kendo-combobox
            #lineitem
            required
            formControlName="lineItem"
            [data]="lineItemList"
            textField="Description"
            valueField="ID"
            (valueChange)="handleLineItemChange($event)"
          >
          </kendo-combobox>
          <kendo-formerror>Line Item is required</kendo-formerror>
          <span class="showAllCheck">
            <ap-checkbox
              formControlName="showAll"
              (onChange)="onShowAllChange($event)"
            >
              <span>Show all</span>
            </ap-checkbox>
          </span>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            [for]="statutoryTreatment"
            text="Statutory treatment"
          ></kendo-label>
          <kendo-combobox
            #statutoryTreatment
            formControlName="statutoryTreatment"
            [data]="STList"
            textField="Description"
            valueField="Id"
            (valueChange)="handleSTChange($event)"
          >
          </kendo-combobox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="taxOutcome" text="Tax outcome"></kendo-label>
          <kendo-combobox
            #taxOutcome
            formControlName="taxOutcome"
            [data]="TaxOutcomeList"
            textField="ShortDescription"
            valueField="_id"
            (valueChange)="handleTOChange($event)"
          >
          </kendo-combobox>
        </kendo-formfield>
        <kendo-formfield>
          <span style="display: flex">
            <ap-checkbox
              formControlName="highlighted"
              (onChange)="onHighlightClick($event)"
            >
              <span>Highlighted</span>
            </ap-checkbox>
            <ap-checkbox
              formControlName="taxSpecific"
              (onChange)="onTaxSpecificClick($event)"
              style="margin-left: 16px"
            >
              <span>Tax specific</span>
            </ap-checkbox>
          </span>
        </kendo-formfield>
      </form>
    </div>
  </mat-card-content>
</mat-card>

<div class="clickToActions" style="padding: 10px">
  <ap-button
    [btnType]="'secondary'"
    class="cancelButton"
    [label]="'Cancel'"
    (onClick)="cancelClick()"
  ></ap-button>
  <ap-button
    [btnType]="'primary'"
    [disabled]="taxAccountForm.invalid"
    [label]="'Save'"
    (onClick)="saveNewTaxAccount()"
    class="okButton"
    style="margin-right: 6px"
  ></ap-button>
</div>
