import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { AddEvent, CancelEvent, DataBindingDirective, EditEvent, GridComponent, GridDataResult, RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
import { CookieService } from 'ngx-cookie-service';
import { StatutoryTreatmentService } from '../common/services/statutorytreatment/statutorytreatment.service';
import { MyGlobalObject } from '../common/myglobalobject';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { DeleteStatutorytreatmentComponent } from './delete-statutorytreatment/delete-statutorytreatment.component';
import { CreateEditStatutorytreatmentComponent } from './create-edit-statutorytreatment/create-edit-statutorytreatment.component';
import { Observable } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-statutorytreatment',
  templateUrl: './statutorytreatment.component.html',
  styleUrls: ['./statutorytreatment.component.scss'],
})
export class StatutorytreatmentComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective | any;
  public actionsLayout: ActionsLayout = 'normal';
  public accounttypes: any;
  public gridData: any = [];
  selectedClient: string | any;
  searchText: string = '';
  public view: Observable<GridDataResult> | undefined;
  statutoryTreatmentForm: any;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 5,
  };
  
   data: any;
  private editedRowIndex: number | undefined;
  action: any;
  constructor(
    // public dialog: DialogRef,
    private cookieService: CookieService,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private statutoryTreatmentService: StatutoryTreatmentService,
    public toastr: ToastrManager
  ) {
    //super(dialog);
    this.loadgridData();
  }

  ngOnInit(): void {
    this.getAllStatutoryTreatment();
    this.selectedClient = this.cookieService.get('SELECTEDCLIENT');
  }

  public onStateChange(state: State): void {
    this.gridState = state;

    this.getAllStatutoryTreatment();
  }

  public addHandler(args: AddEvent): void {
    this.closeEditor(args.sender);
    this.statutoryTreatmentForm = new FormGroup({
      Description: new FormControl(
        "",
        Validators.compose([
          Validators.minLength(1),
          Validators.maxLength(50),
          Validators.required,           
          Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')
        ])
      )});
    args.sender.addRow(this.statutoryTreatmentForm);
  }

  public editHandler(args: EditEvent): void {
    const { dataItem } = args;
    this.closeEditor(args.sender);

    this.statutoryTreatmentForm = new FormGroup({
      Description: new FormControl(
        dataItem.Description,
        Validators.compose([
          Validators.minLength(1),
          Validators.maxLength(50),
          Validators.required,           
          Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')
        ]))
    });
    this.data=dataItem
    this.editedRowIndex = args.rowIndex;
    // put the row in edit mode, with the `FormGroup` build above
    args.sender.editRow(args.rowIndex, this.statutoryTreatmentForm);
  }

  public cancelHandler(args: CancelEvent): void {
    // close the editor for the given row
    this.closeEditor(args.sender, args.rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    if(isNew)
    {
      this.action = 'new';
    }
    else
    {
      this.action = 'edit';
    }
    this.savestatutorytreatment();
    sender.closeRow(rowIndex);
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    // close the editor
    grid.closeRow(rowIndex);
    // reset the helpers
    this.editedRowIndex = undefined;
    this.statutoryTreatmentForm = undefined;
  }

  getAllStatutoryTreatment() {
    this.statutoryTreatmentService
      .getAllStatutoryTreatment()
      .then((res: any[]) => {
        //  console.log(res);
        this.accounttypes = res;
        this.gridData = res;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public loadgridData(): void {
    // console.log("loadgridData funct");
    this.getAllStatutoryTreatment();
  }

  openStatutoryTreatmentPopup(data: any): void {
    const dialogRef = this.dialogService.open({
      content: DeleteStatutorytreatmentComponent,
      width: 500,
      height: 'auto',
    });
    const delStatutoryTreatmentDetails = dialogRef.content
      .instance as DeleteStatutorytreatmentComponent;
    dialogRef.result.subscribe((r: any) => {
      this.getAllStatutoryTreatment();
      this.searchText = '';
    });
    delStatutoryTreatmentDetails.delStatutoryTreatmentdata = data;
  }

  public sort: SortDescriptor[] = [
    {
      field: 'Description',
      dir: 'asc',
    },
  ];
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.accounttypes = {
     data : orderBy(this.gridData, this.sort),
    total: this.gridData.length
     }
  }
  
  public onFilter(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.accounttypes = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'Description',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      this.loadgridData();
    }
    //this.dataBinding.skip = 0;
  }

  savestatutorytreatment() {
    if (this.action == 'edit') {
      let data = {
        Id: this.data.Id,
        AccountTypeID: this.statutoryTreatmentForm.controls.Description.value,
        Description: this.statutoryTreatmentForm.controls.Description.value,
      };
      this.statutoryTreatmentService.editStatutoryTreatment(data).subscribe(
        (res: any) => {
          this.searchText = '';
          if (res == 'Statutory Treatment updated successfully') {
            this.toastr.successToastr(
              'Statutory Treatment updated successfuly',
              'Success'
            );
            this.getAllStatutoryTreatment();
          } else if (res == 'Statutory Treatment Description already exists') {
            this.toastr.errorToastr(
              'Statutory Treatment Description already exists',
              'Failed'
            );
          } else if (res == 'Statutory Treatment is not available') {
            this.toastr.warningToastr(
              'Statutory Treatment is not available',
              'Warning!'
            );
          }
        },
        (error) => {
          console.log(error);
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      );
    } else if (this.action == 'new') {
      let data = {
        Id: 0,
        AccountTypeID: this.statutoryTreatmentForm.controls.Description.value,
        Description: this.statutoryTreatmentForm.controls.Description.value,
      };
      this.statutoryTreatmentService.insertStatutoryTreatment(data).subscribe(
        (res: any) => {
          this.searchText = '';
          if (res == 'Statutory Treatment added successfully') {
            this.toastr.successToastr(
              'Statutory Treatment added successfully',
              'Success'
            );
            this.getAllStatutoryTreatment();
          } else if (res == 'Statutory Treatment Description already exists') {
            this.toastr.errorToastr(
              'Statutory Treatment Description already exists',
              'Failed'
            );
          }
        },
        (error) => {
          console.log(error);
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      );
    }
  }
  public removeHandler(args: RemoveEvent): void {
    this.openStatutoryTreatmentPopup(args.dataItem);
  }
}
