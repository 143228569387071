import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { StatementDetailService } from '../common/services/statementDetail/statementDetail-service';
import { StatementDetailExportType } from '../_models/statementDetailExportType';
import {
  statementDetailTemplateProcessingStartBody,
  statementDetailTemplateProcessingStartResponse,
} from '../_models/statementDetailTemplateProcessingStart.ts';
import { CookieService } from 'ngx-cookie-service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-statement-details-export',
  templateUrl: './statement-details-export.component.html',
  styleUrls: ['./statement-details-export.component.scss'],
})
export class StatementDetailsExportComponent implements OnInit {
  statementDetailExportForm!: FormGroup;

  businessTypes: StatementDetailExportType[] = [];
  selectedbusinessType: StatementDetailExportType | undefined;
  selectedbusinessTypeId: number = 0;
  legalEntities: StatementDetailExportType[] = [];
  selectedLegalEntity: StatementDetailExportType | undefined;
  selectedLegalEntityId: number = 0;
  analysisPacks: StatementDetailExportType[] = [];
  selectedAnalysisPack: StatementDetailExportType | undefined;
  selectedAnalysisPackId: number = 0;
  statements: StatementDetailExportType[] = [];
  isLoading: boolean = false;
  public value: any;
  public isChecked = false;
  public exportLineItemValue: boolean = false;
  statementDetailTemplateProcessingBody!: statementDetailTemplateProcessingStartBody;
  private selectedClient: any;
  processingOrchestrationId: string = '';
  afterExportDetails!: statementDetailTemplateProcessingStartResponse;
  isExportSuccess: boolean = false;
  exportProcessingErrorMessage: string = '';
  selectedStatementIds: number[] = [];

  constructor(
    private fb: FormBuilder,
    private statementDetailService: StatementDetailService,
    private cookieService: CookieService,
    public toastr: ToastrManager
  ) {}

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  ngOnInit(): void {
    this.setupForm();
    this.getBusinessTypes();
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
  }

  setupForm(): void {
    this.statementDetailExportForm = this.fb.group({
      businessType: '',
      legalEntity: '',
      analysisPack: '',
    });
  }

  public get isIndet() {
    return (
      this.value.length !== 0 && this.value.length !== this.statements.length
    );
  }

  public get toggleAllText() {
    return this.isChecked ? 'Deselect All' : 'Select All';
  }

  public isItemSelected(item: StatementDetailExportType) {
    return this.value.some(
      (x: StatementDetailExportType) => x.LookUpId === item.LookUpId
    );
  }

  public onClick() {
    this.isChecked = !this.isChecked;
    this.value = this.isChecked ? this.statements : [];
  }

  public onValueChange(event: any) {
    // this.isChecked = this.value.length === this.statements.length;
    this.isChecked = event.length === this.statements.length;
  }

  handleBusinessTypeChange(businessType: StatementDetailExportType): void {
    this.selectedbusinessType = businessType;
    this.selectedLegalEntity = undefined;
    this.legalEntities = [];
    this.selectedbusinessTypeId = 0;
    this.selectedLegalEntityId = 0;
    this.selectedAnalysisPackId = 0;
    this.selectedAnalysisPack = undefined;
    this.analysisPacks = [];
    this.value = [];
    this.statements = [];
    if (this.selectedbusinessType != undefined) {
      this.selectedbusinessTypeId = this.selectedbusinessType.LookUpId;
      this.getLegalEntities(businessType.LookUpId);
    }
  }

  handleLegalEntityChange(legalEntity: StatementDetailExportType): void {
    this.selectedLegalEntity = legalEntity;
    this.selectedAnalysisPack = undefined;
    this.selectedAnalysisPackId = 0;
    this.analysisPacks = [];
    this.value = [];
    this.statements = [];
    if (this.selectedLegalEntity != undefined) {
      this.selectedLegalEntityId = legalEntity.LookUpId;
      this.getAnalysisPacks(this.selectedbusinessTypeId, legalEntity.LookUpId);
    }
  }

  handleAnalysisPackChange(analysispack: StatementDetailExportType): void {
    this.selectedAnalysisPack = analysispack;
    this.value = [];
    this.statements = [];
    if (this.selectedAnalysisPack != undefined) {
      this.selectedAnalysisPackId = analysispack.LookUpId;
      this.getStatements(analysispack.LookUpId);
    }
  }

  getBusinessTypes(): void {
    this.isLoading = true;
    this.businessTypes = [];
    this.statementDetailService.getAllBusinessTypeDetail().subscribe(
      (response) => {
        this.businessTypes = response;
        if (this.businessTypes.length == 1) {
          this.selectedbusinessType = this.businessTypes[0];
          this.handleBusinessTypeChange(this.selectedbusinessType);
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getLegalEntities(businessTypeId: number): void {
    this.isLoading = true;
    this.legalEntities = [];
    this.statementDetailService
      .GetLegalEntitiesByBusinessTypeId(businessTypeId)
      .subscribe(
        (response) => {
          this.legalEntities = response;
          if (this.legalEntities.length == 1) {
            this.selectedLegalEntity = this.legalEntities[0];
            this.handleLegalEntityChange(this.selectedLegalEntity);
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  getAnalysisPacks(businessTypeId: number, legalEntityId: number): void {
    this.isLoading = true;
    this.analysisPacks = [];
    this.statementDetailService
      .GetStatementGroupsByBusTypeAndLegalEntityId(
        businessTypeId,
        legalEntityId
      )
      .subscribe(
        (response) => {
          this.analysisPacks = response;
          if (this.analysisPacks.length == 1) {
            this.selectedAnalysisPack = this.analysisPacks[0];
            this.handleAnalysisPackChange(this.selectedAnalysisPack);
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  getStatements(statementGroupId: number): void {
    this.isLoading = true;
    // this.analysisPacks = [];
    this.statements = [];
    this.statementDetailService
      .GetAnalysisStatementsForStatementGroupId(statementGroupId)
      .subscribe(
        (response) => {
          this.statements = response;
          // if(this.statements.length==1){
          //   this.value=this.statements[0]
          //   this.onValueChange(this.value)
          // }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  generate(): void {
    if (this.value.length == 0) {
      this.toastr.errorToastr('Select statement for export.', 'Error');
      return;
    }
    this.selectedStatementIds = [];
    this.value.map((st: StatementDetailExportType) => {
      this.selectedStatementIds.push(st.LookUpId);
    });
    this.statmentDetailExportprocessing();
  }

  statmentDetailExportprocessing() {
    this.isLoading = true;
    this.statementDetailTemplateProcessingBody = {
      businessTypeId: this.selectedbusinessTypeId,
      legalEntityId: this.selectedLegalEntityId,
      statementGroupId: this.selectedAnalysisPackId,
      statementIds: this.selectedStatementIds,
      isExportExistingLineItem: this.exportLineItemValue,
      clientDbName: this.selectedClient.DBName,
    };
    this.statementDetailService
      .PostExportStatementDetailTemplateProcessingStart(
        this.statementDetailTemplateProcessingBody
      )
      .subscribe(
        (response: statementDetailTemplateProcessingStartResponse) => {
          if (response) {
            this.processingOrchestrationId = response.orchestrationId;
            this.getProcessingStatus();
          }
          //this.isNextVisible = true;
        },
        (error: any) => {
          this.isLoading = false;
          this.toastr.errorToastr('Export statement failed', 'Error');
          console.log(error);
        }
      );
  }
  getProcessingStatus() {
    this.statementDetailService
      .GetExportStatementDetailTemplateProcessingStatus(
        this.processingOrchestrationId
      )
      .subscribe(
        (response: any) => {
          this.afterExportDetails = response.body;
          if (
            this.afterExportDetails.orchestrationRuntimeStatus == 'Completed' ||
            this.afterExportDetails.orchestrationRuntimeStatus == 'Failed'
          ) {
            if (
              this.afterExportDetails.orchestrationRuntimeStatus == 'Completed'
            ) {
              if (
                this.afterExportDetails
                  .isExportProcessingCompletedWithSuccess == true
              ) {
                this.downloadStatementDetailTemplateExcelFile(
                  this.afterExportDetails.generatedExportFileName
                );
              } else {
                this.exportProcessingErrorMessage =
                  this.afterExportDetails.exportProcessingErrorMessage;

                this.toastr.errorToastr('Export statement failed', 'Error');
              }
            } else if (
              this.afterExportDetails.orchestrationRuntimeStatus == 'Failed'
            ) {
              this.exportProcessingErrorMessage =
                this.afterExportDetails.exportProcessingErrorMessage;

              this.toastr.errorToastr('Export statement failed', 'Error');
            }
            this.isLoading = false;
            this.isExportSuccess = true;
          } else this.getProcessingStatus();
        },
        (error: any) => {
          this.isLoading = false;
          this.toastr.errorToastr('Export statement failed', 'Error');
          console.log(error);
          return error;
        }
      );
  }

  downloadStatementDetailTemplateExcelFile(generatedExportFileName: any) {
    this.statementDetailService
      .downloadStatementDetailTemplateExcelFile(generatedExportFileName)
      .subscribe(
        (response: any) => {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          const fileName = generatedExportFileName;
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
          this.isLoading = false;
        },
        (error: any) => {
          this.isLoading = false;
          this.toastr.errorToastr('Export statement failed', 'Error');
          console.log(error);
        }
      );
  }
}
