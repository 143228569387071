import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { ToastrManager } from 'ng6-toastr-notifications';
import { element } from 'protractor';
import { AllocationRulesService } from 'src/app/common/services/allocationrule/allocation-rules.service';
import { DeleteAllocationRuleComponent } from '../delete-allocation-rule/delete-allocation-rule.component';
import { JournalService } from 'src/app/common/services/journal/journal-service';
import { FiscalYear } from 'src/app/_models/fiscalYear';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { faL } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-manage-allocation-rules',
  templateUrl: './manage-allocation-rules.component.html',
  styleUrls: ['./manage-allocation-rules.component.scss'],
})
export class ManageAllocationRulesComponent implements OnInit {
  constructor(
    private allocationRulesService: AllocationRulesService,
    private router: Router,
    private journalService: JournalService,
    private dialogService: DialogService,
    public toastr: ToastrManager
  ) {
    this.loadgridData();
  }
  runData = false;
  run() {
    this.runData = true;
    this.getAllocationRulesByIds();
  }
  public allocationRules: any;
  public gridData: any[] = [];
  isLoading: boolean = false;
  searchText: string = '';
  ngOnInit(): void {}

  public onFilter(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.allocationRules = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'Priority',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Name',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'TargetAccount',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Habitat',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      this.loadgridData();
    }
  }
  public loadgridData(): void {
    this.getAllOnlineallocationList();
  }
  clear(ele: any) {
    this.allocationRules = this.gridData;
  }
  getElemet(id: any) {}
  clickedItems: any[] = [];
  onHighlightClick1(element: any, event: any) {
    let add: any[] = [];
    if (!event.target.checked) {
      element.autoAllocationTransactionPreview.forEach((ele1: any, j: any) => {
        ele1.IsSelected= false;
      });
    } else {
      element.autoAllocationTransactionPreview.forEach((ele1: any, j: any) => {
        ele1.IsSelected= true;
      });
    }
  }
  onHighlightClick(element: any) {
    let remove: any[] = [];
    let add: any[] = [];
    if (element.autoAllocationTransactionPreview != undefined) {
      this.clickedItems.forEach((ele: any, i: any) => {
        if (element.Id == ele) {
          element.autoAllocationTransactionPreview.forEach(
            (ele1: any, j: any) => {
              //this.clickedChild.splice(j,1);
              remove.push(element.Id + '_' + ele.DbTransactionId);
            }
          );
        } else {
          if (!this.clickedItems.includes(element.Id)) {
            element.autoAllocationTransactionPreview.forEach(
              (ele1: any, j: any) => {
                //this.clickedChild.push(ele1);
                add.push(element.Id + '_' + ele1.DbTransactionId);
              }
            );
          }
        }
      });
      remove.forEach((ele: any, i: any) => {
        let index = this.clickedChild.indexOf(ele);
        this.clickedChild.splice(index, 1);
      });
      add.forEach((ele: any) => {
        this.clickedChild.push(ele);
      });
    }
    if (this.clickedItems.includes(element.Id))
      this.clickedItems.forEach((item, index) => {
        if (item === element.Id) {
          this.clickedItems.splice(index, 1);
        } else {
          this.clickedItems.push(item);
        }
      });
    else {
      this.clickedItems.push(element.Id);
      if (element.autoAllocationTransactionPreview != undefined)
        element.autoAllocationTransactionPreview.forEach((ele: any) => {
          this.clickedChild.push(element.Id + '_' + ele.DbTransactionId);
        });
    }
    this.getLatestFiscalDetail();
  }
  checkIfchecked(event: any) {
    if (this.clickedItems.includes(event.Id)) {
      return true;
    } else return false;
  }
  onHighlightClickC(element: any, mainEle: any, event: any) {
    let checkVal = false;
    if (!event.target.checked)
    {
      element.IsSelected = false;
      checkVal = true;
    }
    else
    {
      element.IsSelected = true;
      for(var i=0; i < mainEle.autoAllocationTransactionPreview.length; ++i) {
        if (!mainEle.autoAllocationTransactionPreview[i].IsSelected) {
          checkVal = true;
          break;
        }
      }
    }
    if (!checkVal) mainEle.tablecheckbox = true;
    else mainEle.tablecheckbox = false;
  }
  trackByTransactionPreviewId(index : number, transactionPreview: any){
      return transactionPreview.AutoAllocationTransactionPreviewId;
  }
  fiscalYear: any;
  fiscalYearValue: any;
  getLatestFiscalDetail(): void {
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.fiscalYear = JSON.parse(year);
    this.fiscalYearValue = this.fiscalYear.FiscalYearId;
    // this.isLoading = true;
    // this.journalService.getLatestFiscalDetail().subscribe(
    //   (response) => {
    //     this.fiscalYear = response;
    //     this.fiscalYearValue = this.fiscalYear.FiscalYearId;
    //   },
    //   (error) => {
    //     console.log(error);
    //     // this.isLoading = false;
    //   }
    // );
  }
  public allocationRules1: any;
  public gridData1: any[] = [];

  closePop() {
    this.runData = false;
    this.clickedChild = [];
    this.clickedItems = [];
    this.allocationRules1 = [];
    this.searchText = '';
    this.sort = [
      {
        field: 'Priority',
        dir: 'asc',
      },
    ];
    this.loadgridData();
  }
  setId: any;
  expand(id: any) {
    if (id.autoAllocationTransactionPreview.length > 0) {
      if (this.setId == '') this.setId = id.Id;
      else if (this.setId == id.Id) this.setId = '';
      else if (this.setId != id.Id) this.setId = id.Id;
      else this.setId = '';
    }
  }
  isSet(id: any) {
    if (id == this.setId) {
      return true;
    } else return false;
  }
  lookUpSection: { Rule: any; id: any }[] = [];
  getAllocationRulesByIds(): void {
    this.isLoading = true;
    let ids = '';

    var sortedArray: string[] = this.clickedItems.sort((n1, n2) => {
      if (n1 > n2) {
        return 1;
      }

      if (n1 < n2) {
        return -1;
      }

      return 0;
    });

    let unique: any[] = [];
    this.clickedItems.forEach((ele) => {
      if (!unique.includes(ele)) {
        unique.push(ele);
      }
    });
    this.clickedItems = unique;

    this.clickedItems.forEach((ele) => {
      if (ids == '') ids = ele;
      else if (ele != undefined) ids = ids + ',' + ele;
    });
    this.clickedItems = [];
    this.allocationRulesService
      .getAllocationRulesByIds(ids, this.fiscalYearValue)
      .subscribe(
        (response) => {
          response = response.map((data: any) => {
            data['tablecheckbox'] = false;
            return data;
          });
          this.allocationRules1 = response;
          this.setId = '';
          this.gridData1 = response;

          this.isLoading = false;

          this.allocationRules1.forEach((ele: any) => {
            ele.autoAllocationTransactionPreview.forEach((ele1: any) => {
              this.lookUpSection.push({
                Rule: ele.Ruleheading,
                id: ele1.DbTransactionId,
              });
            });
          });
          this.clickedItems = [];
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  public isCheckedC(index: any, a: any): boolean {
    if (this.clickedChild.includes(a.Id + '_' + index.DbTransactionId)) {
      return true;
    } else {
      return false;
    }
  }
  // public isChecked(index: any): boolean {
  //   if (this.clickedItems.includes(index.Id)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  public isChecked(index: any): boolean {
    let tst = true;
    index.autoAllocationTransactionPreview.forEach((ele: any) => {
      if (!this.clickedChild.includes(index.Id + '_' + ele.DbTransactionId)) {
        tst = false;
      }
    });
    return tst;
  }
  bgColor = 'white';
  selectall() {
    this.isLoading = true;

    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
    this.clickedItems = [];
    this.clickedChild = [];
    this.allocationRules1.forEach((element: any) => {
      if (element.HasExportedOfflineReferral == false) {
        this.clickedItems.push(element.Id);
      }
      element.autoAllocationTransactionPreview.forEach((ele: any) => {
        if (ele.HasExportedOfflineReferral == false) {
          ele.IsSelected = true;
        }
        else ele.IsSelected = false;
      });
      element.tablecheckbox = true;
    });
  }
  clickedChild: any[] = [];

  getAllOnlineallocationList(): void {
    this.isLoading = true;
    this.allocationRulesService.getAllocationRulesDataList().subscribe(
      (response) => {
        this.allocationRules = response;
        this.gridData = response;

        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  public editButtonClick(rowDetails: any): void {
    this.router.navigate(['/editAllocationRule/edit/', rowDetails.Id]);
  }

  deleteButtonClick(rowDetails: any): void {
    const dialogRef = this.dialogService.open({
      content: DeleteAllocationRuleComponent,
      width: 500,
      height: 200,
    });
    const deleteAllocationRulePopup = dialogRef.content
      .instance as DeleteAllocationRuleComponent;
    dialogRef.result.subscribe((r: any) => {
      this.searchText = '';
      this.getAllOnlineallocationList();
    });
    deleteAllocationRulePopup.delAllocationRuledata = rowDetails;
  }

  openAddAllocationRule() {
    this.router.navigate(['/newAllocationRule/null']);
  }

  public copyButtonClick(rowDetails: any): void {
    this.router.navigate(['/editAllocationRule/copy/', rowDetails.Id]);
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.allocationRules = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }

  public sort: SortDescriptor[] = [
    {
      field: 'Priority',
      dir: 'asc',
    },
  ];
  createJournals() {
    let data: {
      automaticAllocationId: any;
      allocationPriority: any;
      targetTaxAccountId: any;
      description: any;
      transactionIds: any[];
    }[] = [];
    this.allocationRules1.forEach((ele: any) => {
      let transaction: any[] = [];
      ele.autoAllocationTransactionPreview.forEach((ele1: any) => {
        if (ele1.IsSelected) {
          transaction.push(ele1.DbTransactionId);
        }
      });
      if (transaction.length > 0)
        data.push({
          automaticAllocationId: ele.Id,
          allocationPriority: ele.Priority,
          targetTaxAccountId: ele.TaxAccountCodeDetails.TaxAccountCodeId,
          description: ele.Name,
          transactionIds: transaction,
        });
    });
    this.isLoading = true;
    this.allocationRulesService.createJournals(data).then((res) => {
      this.resData = res;
      this.isLoading = false;
      this.openPopup();
      this.closePop();
    });
  }
  resData: any;
  displayStyle = 'none';
  getStatus() {
    let anyItemSelected = false;
    if(this.allocationRules1 != null)
    {
      for(var h=0; h<this.allocationRules1.length; ++h) {
        for(var i=0; i < this.allocationRules1[h].autoAllocationTransactionPreview.length; ++i) {
          if (this.allocationRules1[h].autoAllocationTransactionPreview[i].IsSelected) {
            anyItemSelected = true;
            break;
          }
        }
      }
  };
    if (anyItemSelected == false) return true;
    else return false;
  }
  selectUnallocated() {
    this.clickedChild = [];
    this.allocationRules1.forEach((ele: any) => {
      if (!ele.IsDuplicate)
        ele.autoAllocationTransactionPreview.forEach((ele1: any) => {
          if (ele1.HasExportedOfflineReferral == false) {
            ele1.IsSelected = true;
            ele.tablecheckbox = true;
          }
          else ele1.IsSelected = false;
        });
    });
  }
  openPopup() {
    this.displayStyle = 'block';
  }
  closePopup() {
    this.displayStyle = 'none';
  }
  export() {
    this.isLoading = true;
    let data: { AllocationRule: any; transactionID: any }[] = [];

    this.allocationRules1.forEach((ele: any) => {
      let transaction = '';
      ele.autoAllocationTransactionPreview.forEach((ele1: any) => {
        if (ele1.IsSelected) {
          if (transaction != '')
            transaction = transaction + ',' + ele1.DbTransactionId;
          else transaction = ele1.DbTransactionId;
        }
      });
      if (transaction != '')
        data.push({
          AllocationRule: ele.Ruleheading,
          transactionID: transaction.toString(),
        });
    });
    this.allocationRulesService.getExcel(data).subscribe(
      (response) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const fileName = 'RuleTransactions' + timestamp;
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }
  exportReport() {
    this.isLoading = true;
    this.allocationRulesService.getReport('0').subscribe(
      (response) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const fileName = 'AllocationRuleReport' + timestamp;
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  moveupPriority(rowDetails: any) {
    this.allocationRulesService.moveupPriority(rowDetails.Id).subscribe(
      (res) => {
        if (res == 'Allocation Rule priority updated successfully') {
          this.toastr.successToastr(res, 'Success');
          this.loadgridData();
        } else if (res == '') {
        } else {
          this.toastr.errorToastr(res, 'Failed');
        }
      },
      (error) => {
        this.isLoading = false;
        this.toastr.errorToastr(
          'Failed to update priority of Allocation Rule',
          'Error'
        );
        console.log(error);
      }
    );
  }

  movedownPriority(rowDetails: any) {
    this.allocationRulesService.movedownPriority(rowDetails.Id).subscribe(
      (res) => {
        if (res == 'Allocation Rule priority updated successfully') {
          this.toastr.successToastr(res, 'Success');
          this.loadgridData();
        } else if (res == '') {
        } else {
          this.toastr.errorToastr(res, 'Failed');
        }
      },
      (error) => {
        this.isLoading = false;
        this.toastr.errorToastr(
          'Failed to update priority of Allocation Rule',
          'Error'
        );
        console.log(error);
      }
    );
  }
  public clickDefineExpressions(): void {
    this.router.navigate(['./defineExpressions']);
  }
  roundedPercentage(myValue: number) {
    var result = myValue;

    return result.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  selectAllrules(event: any) {
    if (event.target.checked) {
      let allitemsarray = this.allocationRules.map((item: any) => item.Id);
      this.clickedItems.push(...allitemsarray);
    } else this.clickedItems = [];
    this.getLatestFiscalDetail();
  }
}
