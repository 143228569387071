import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { AnalysisReviewService } from 'src/app/common/services/analysisReview/analysis-review.service';

@Component({
  selector: 'app-delete-manage-attachement',
  templateUrl: './delete-manage-attachement.component.html',
  styleUrls: ['./delete-manage-attachement.component.scss']
})
export class DeleteManageAttachementComponent implements OnInit {

  constructor(private analysisReviewService: AnalysisReviewService,
    public toastr: ToastrManager,private globalService:GlobalService,private router: Router,public dialog: DialogRef,) { }
  public warnondelete: boolean = false;
  public deleteType: any = 'Soft Delete';
  isAdmin: boolean = false;
  @Input() public suppIds:any;
  @Input() public fiscalYear:any;
  ngOnInit(): void {
    this.getUserRole();
  }

  
  getUserRole()
  {
    this.globalService
          .getLoggedUserInfo()
          .then((response) => {
            if (response != undefined) {
              if (response == 'Super Admin' || response == 'Admin') {
                this.isAdmin = true;
                this.warnondelete=true;
              } else {
                this.isAdmin = false;
                this.warnondelete=false;    
                this.deleteData();
              }
            }
          })
          .catch((error) => {});
  }
  
  selectDeleteType(node: any) {}

  deleteData()
  {
    this.DeleteAttachement();
  }

  DeleteAttachement()
  {
    let isSoftDelete=true;
    if(this.deleteType=='Pemanent Delete')
    {
      isSoftDelete=false;
    }
    this.analysisReviewService
      .deleteAttachment(this.suppIds.toString(),isSoftDelete,this.fiscalYear)
      .subscribe((response) => {
        if (response == 'Attachment deleted successfully.'
        || response == 'Attachment archived successfully.') {
          this.warnondelete=false;
          this.dialog.close();
          this.toastr.successToastr(response, 'Success');
          this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      });   
  }

  cancelClick() {
    this.warnondelete=false;
    this.dialog.close();
  }


}
