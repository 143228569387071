import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from '../common/globalservice/globalservice.service';
import { MyGlobalObject } from '../common/myglobalobject';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { NewlineitemComponent } from './newlineitem/newlineitem.component';
import { DeletelineitemComponent } from './deletelineitem/deletelineitem.component';
import { EditlineitemComponent } from './editlineitem/editlineitem.component';
import { CopylineitemComponent } from './copylineitem/copylineitem.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lineitems',
  templateUrl: './lineitems.component.html',
  styleUrls: ['./lineitems.component.scss'],
})
export class LineitemsComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective | any;
  public lineItems: any;
  public actionsLayout: ActionsLayout = 'normal';
  public isLoadId = false;
  public lineItemsGridForm: FormGroup | any;
  businesstypeList: any;
  businesstypeListDB: any;
  requireFurtherAnalysis: boolean = false;
  public gridData: any = [];
  selectedClient: string | any;
  BusinessTypeID: any = 1;
  AccountClass: number | any;
  defaultBT: any;
  data: any;
  constructor(
    // public dialog: DialogRef,
    private cookieService: CookieService,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private router: Router,
    private globalService: GlobalService
  ) {
    // super(dialog);
    this.lineItemsGridForm = new FormGroup({
      businessTypeId: new FormControl(),
    });

    // this.getRefData()
  }

  ngOnInit(): void {
    this.getRefData();
    this.selectedClient = localStorage.getItem('SELECTEDCLIENT');
  }

  async getRefData() {
    await this.globalService
      .getAllBusinessTypes()
      .then((res) => {
        this.businesstypeListDB = res;
        this.businesstypeList = this.businesstypeListDB.slice();

        if (this.isLoadId) {
          var value =
            this.lineItemsGridForm.controls['businessTypeId'].value[
              'BusinessTypeId'
            ];

          this.getAllLineItemsByBusinessType(value);
        } else {
          this.lineItemsGridForm.controls['businessTypeId'].setValue(
            this.businesstypeListDB[0]
          );
          if (history.state.BusinessType)
            this.lineItemsGridForm.controls['businessTypeId'].setValue(
              history.state.BusinessType
            );
        }
      })
      .catch((err) => {});
    this.loadgridData();
  }

  getAllLineItemsByBusinessType(BusinessTypeId: any) {
    this.globalService
      .getAllLineItemsByBusinessType(BusinessTypeId)
      .then((res: any[]) => {
        this.lineItems = res;

        this.gridData = res;
      })
      .catch((err: any) => {});
  }

  handleBTchange(selBT: any) {
    this.getAllLineItemsByBusinessType(selBT.BusinessTypeId);
  }

  handleFilterBusinessTypeId(value: any) {
    this.businesstypeList = this.businesstypeListDB.filter(
      (s: any) =>
        s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public onFilter(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.lineItems = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'AccountingAreaID',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Description',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'AccountClassDescription',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'AccountingAreaDescription',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'HelpId',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      this.loadgridData();
    }
    //this.dataBinding.skip = 0;
  }

  //sorting feature
  public sort: SortDescriptor[] = [
    {
      field: 'AccountingAreaID',
      dir: 'asc',
    },
  ];
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.lineItems = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }

  public loadgridData(): void {
    this.getAllLineItemsByBusinessType(
      this.lineItemsGridForm.getRawValue().businessTypeId.BusinessTypeId
    );
  }
  // New line item popup
  openNewLineItemPopup() {
    var formObject = this.lineItemsGridForm.getRawValue();
    this.router.navigate(['/addNewLineItem'], {
      state: {
        BusinessType: formObject.businessTypeId,
      },
    });
  }
  // Edit line item popup
  openEditLineItemPopup(rowDetails: any) {
    var formObject = this.lineItemsGridForm.getRawValue();
    this.router.navigate(['/editLineItem'], {
      state: {
        editLineItemId: rowDetails.LineID,
        BusinessType: formObject.businessTypeId,
      },
    });
  }
  // copy line item
  openCopyLineItemPopup(rowDetails: any) {
    var formObject = this.lineItemsGridForm.getRawValue();
    this.router.navigate(['/copyLineItem'], {
      state: {
        copyLineItemId: rowDetails.LineID,
        BusinessType: formObject.businessTypeId,
      },
    });
  }
  //Delete line item
  openDeleteLineItemPopup(data: any): void {
    var formObject = this.lineItemsGridForm.getRawValue();
    const dialogRef = this.dialogService.open({
      content: DeletelineitemComponent,
      width: 500,
      height: 'auto',
    });
    const delLIDetails = dialogRef.content.instance as DeletelineitemComponent;
    dialogRef.result.subscribe((r: any) => {});
    delLIDetails.delLIdata = data.LineID;
    delLIDetails.BusinessType = formObject.businessTypeId;
  }
}
