<!-- Header -->
<div class="header">Manage habitats</div>
<div id="mainContent" class="container-fluid">
  <div class="row">
    <!-- <div class="col-md-12 col-xs-12"></div> -->
    <div class="col-md-12 col-xs-12">
      <!-- search bar and add new -->
      <div class="searchContainer">
        <ap-search
          [placeholder]="'Search here'"
          [searchType]="'secondary'"
          style="width: 100%; margin-right: 24px; padding: 0px !important"
          [disabled]="false"
          (onSearch)="onFilter($event)"
        ></ap-search>
        <ap-button
          [icon]="'plus-outline'"
          kendoTooltip
          title="New"
          [compact]="true"
          [add]="true"
          (onClick)="actionButton('new', null)"
        ></ap-button>
      </div>
      <!-- Sub Header -->
      <div class="subHeader">Habitats</div>
      <!-- Data grid -->
      <kendo-grid
        [data]="habitats"
        scrollable="virtual"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        [kendoGridSelectBy]="selectedCallback"
        [(selectedKeys)]="mySelection"
        [resizable]="true"
        [selectable]="true"
        [resizable]="true"
        [reorderable]="true"
      >
        <kendo-grid-command-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'left'
          }"
          [width]="40"
          field="IsActive"
          title="Actions"
        >
          <ng-template kendoGridCellTemplate let-data>
            <button
              kendoButton
              name="edit"
              (click)="actionButton('edit', data)"
              kendoTooltip
              title="Edit"
              style="margin-right: 5px"
            >
              <span class="Appkit4-icon icon-edit-outline"></span>
            </button>
            <button
              kendoButton
              name="delete"
              kendoTooltip
              title="Delete"
              (click)="openDeleteHabitatPopup(data)"
            >
              <span class="Appkit4-icon icon-delete-outline"></span>
            </button>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column
          field="Name"
          title="Name"
          [width]="320"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
      </kendo-grid>
      <!-- Click to Actions -->
      <!-- <div class="clickToActions" style="padding: 20px 0px">
        <ap-button
          [btnType]="'primary'"
          [label]="'Reassign'"
          themeColor="primary"
          (click)="actionButton('reassign')"
          class="okButton"
          style="width: 100px;"
          ></ap-button>
        <p class="paragraphText" style="padding: 15px">
          Reassign related child accounts for all complex accounts across all
          habitats
        </p>
      </div> -->
      <div style="margin-top: 5px"><b>Reassign</b></div>
      <div style="display: flex">
        <div>
          <p class="paragraphText" style="margin: 5px 0px">
            Reassign related child accounts for all complex accounts across all
            habitats
          </p>
        </div>
        <ap-button
          [btnType]="'primary'"
          [label]="'Reassign'"
          themeColor="primary"
          (click)="actionButton('reassign')"
          class="okButton"
          style="width: 100px; position: absolute; right: 1%"
        ></ap-button>
      </div>
    </div>
    <!-- <div class="col-md-1 col-xs-12"></div> -->
  </div>
</div>
