import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CollectofflinereferralService {
  private selectedClient: any;
  private baseUrl: string = '';
  private importAPIUrl: string = '';
  private apifolder: string = '';
  public apiUrl: any;
  private apiHost: string = '';
  tokenValue: any;
  constructor(
    public http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.importAPIUrl = environment.importapiUrl;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    this.apiUrl = {
      ValidateImportOfflineReferral:
        '/OfflineReferral/ValidateImportOfflineReferral',
      ImportOfflineReferralProcessingStart:
        '/ImportOfflineReferralProcessingStart',
      GetImportOfflineReferralProcessingStatus:
        '/GetImportOfflineReferralProcessingStatus/',
      DownloadImportOfflineReferralReport:
        '/DownloadImportOfflineReferralReport',
      GetOfflineReferralViewExcel:
        '/OfflineReferral/GetOfflineReferralViewExcel',
      initiateReimportOfflineReferral:
        '/OfflineReferral/InitiateReImportOfflineReferral',
    };
  }
  ValidateImportOfflineReferral(fileName: string): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);

    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.ValidateImportOfflineReferral +
      '?ClientdbName=' +
      this.selectedClient.DBName +
      '&fileName=' +
      fileName;
    return this.http.put<any>(
      this.apiHost,
      {},
      {
        headers,
      }
    );
  }
  importOfflineReferralProcessingStart(tbprocessStartPayload: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.ImportOfflineReferralProcessingStart;
    return this.http.post(this.apiHost, tbprocessStartPayload, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  getImportOfflineReferralProcessingStatus(instanceId: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.GetImportOfflineReferralProcessingStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  downloadImportOfflineReferralReport(
    importReportTextFileName: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('FileName', importReportTextFileName);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.DownloadImportOfflineReferralReport;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'text',
    });
  }
  downloadOfflineReferralViewExcel(offlinereferralId: number): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('offlineReferralId', offlinereferralId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.GetOfflineReferralViewExcel;
    return this.http.post(
      this.apiHost,
      {},
      {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        observe: 'response',
        responseType: 'blob' as 'json',
      }
    );
  }
  initiateReimportOfflineReferral(id: number): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);

    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.initiateReimportOfflineReferral +
      '?ClientdbName=' +
      this.selectedClient.DBName +
      '&offlineReferralId=' +
      id;
    return this.http.put<any>(
      this.apiHost,
      {},
      {
        headers,
      }
    );
  }
}
