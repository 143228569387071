import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
//import { MyGlobalObject } from '../MyGlobalObject';
import { environment } from '../../../environments/environment';
//import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { MyGlobalObject } from '../myglobalobject';
import { KeywordGroupFolder } from 'src/app/_models/keywordGroupFolder';
import { KeywordGroup } from 'src/app/_models/keywordGroup';
import { Keyword } from 'src/app/_models/keyword';
import { KeywordData } from 'src/app/_models/keywordData';
import { KeywordDeleteData } from 'src/app/_models/keywordDeleteData';
import { Worksheet } from 'src/app/_models/worksheet';
import { MenubarService } from 'src/app/common/menubarService/menubar.service';
import { promise } from 'protractor';
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private params: any;
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private mockApiUrl: any;
  private apiHost: string;
  tokenValue: any;
  runpayload: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    public menubarService: MenubarService,
    private cookieService: CookieService //  private toastr: ToastrManager
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    //  this.apiPort = environment.apiPort;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getLoggedInUserDetails: '/User/GetLoggedInUserRole',
      getClientsList: '/User/GetAllClientDetails',
      getAllBT: '/BusinessTypes/GetAllBusinessType',
      getAllCountry: '/CommonEnlighten/GetAllCountry',
      getAllIndustry: '/CommonEnlighten/GetAllIndustry',
      getAllCurrency: '/CommonEnlighten/GetAllCurrency',
      getAllCompany: '/CommonEnlighten/GetAllCompany',
      addBusinessType: '/BusinessTypes/InsertBusinessType',
      updateBusinessType: '/BusinessTypes/UpdateBusinessType',
      getBusinessTypebyId: '/BusinessTypes/GetBusinessTypeById',
      deleteBusinessType: '/BusinessTypes/DeleteBusinessType',
      getAllLE: '/LegalEntities/GetAllLegalEntities',
      getLegalEntityById: '/LegalEntities/GetLegalEntityById',
      updateLegalEntity: '/LegalEntities/UpdateLegalEntity',
      addLegalEntity: '/LegalEntities/InsertLegalEntity',
      deleteLegalEntity: '/LegalEntities/DeleteLegalEntity',
      addStatementLayout: '/AnalysisStatement/InsertStatementLayout',
      copyInsertStatementLayout: '/AnalysisStatement/InsertCopyLayoutStatement',
      updateStatementLayout: '/AnalysisStatement/UpdateStatementLayout',
      getAllChildNode: '/AnalysisStatement/GetAllChildNode',
      getAllStatementLayout: '/AnalysisStatement/GetAllStatementLayout',
      newSubAnalysis: '/AnalysisStatement/InsertUpdateStatementLayoutBlock',
      statementUsedBy: '/AnalysisStatement/GetAllStatementUsedBy',
      deleteStatementLayout: '/AnalysisStatement/DeleteStatementLayout',
      deleteSubAnalysisStatementLayout:
        '/AnalysisStatement/DeleteStatementLayoutBlock',
      statementBlockMoveUp: '/AnalysisStatement/StatementBlockMoveUp',
      statementBlockMoveDown: '/AnalysisStatement/StatementBlockMoveDown',
      getAllLineItems: '/LineItem/GetAllLineItems',
      getLineItemById: '/LineItem/GetLineItemById',
      insertLineItem: '/LineItem/InsertLineItem',
      getParentLineItem: '/LineItem/GetParentLineItem',
      deleteLineItem: '/LineItem/DeleteLineItem',
      updateLineItem: '/LineItem/UpdateLineItem',
      getAllLineItemsByBusinessType: '/LineItem/GetAllLineItemsByBusinessType',
      getAllAccountClass: '/CommonEnlighten/GetAllAccountClass',
      getAllTaxAccountCode: '/TaxAccountCode/GetTaxAccountCode',
      insertTaxAccountCode: '/TaxAccountCode/InsertTaxAccountCode',
      updateTaxAccountCode: '/TaxAccountCode/UpdateTaxAccountCode',
      getTaxAccountParentData: '/TaxAccountCode/GetTaxAccountParentData',
      getTaxaccountLineitem: '/TaxAccountCode/GetTaxAccountLineItem',
      getAllTaxOutcomes: '/TaxOutcomes/GetAllTaxOutcomes',
      getDistinctStatutoryAccountCodes:
        '/GLAccount/GetDistinctStatutoryAccountCodes',
      getAllAccountTypes: '/StatutoryTreatment/getAllAccountTypes',
      getDeleteTaxAccountdata: '/TaxAccountCode/GetDeleteTaxAccountData',
      deleteTaxAccountCode: '/TaxAccountCode/DeleteTaxAccountCode',
      getAllStatutoryAccountCodeByCode:
        '/GLAccount/GetAllStatutoryAccountCodeByCode',
      insertStatutoryAccountCode: '/GLAccount/InsertStatutoryAccountCode',
      getStatutoryAccountCodesById: '/GLAccount/GetStatutoryAccountCodesById',
      updateStatutoryAccountCode: '/GLAccount/UpdateStatutoryAccountCode',
      deleteStatutoryAccountCode: '/GLAccount/DeleteStatutoryAccountCode',
      getAllAccountType: '/CommonEnlighten/GetAllAccountType',
      getAllKeywordData: '/Keyword/GetAllKeywordData',
      getAllKeywordGroupFolder: '/Keyword/GetAllKeywordGroupFolder',
      getAllKeywordGroup: '/Keyword/GetAllKeywordGroup',
      editKeywordGroupFolder: '/Keyword/EditKeywordGroupFolder',
      editKeywordGroup: '/Keyword/EditKeywordGroup',
      editKeyword: '/Keyword/EditKeywords',
      createKeywordFolder: '/Keyword/CreateKeywordFolder',
      createKeywordGroup: '/Keyword/CreateKeywordGroup',
      createKeyword: '/Keyword/CreateKeyword',
      deleteKeywordGroupFolder: '/Keyword/DeleteKeywordGroupFolder',
      deleteKeywordGroup: '/Keyword/DeleteKeywordGroup',
      GetTaxAccountByLineItem: '/AnalysisTreeView/GetTaxAccountByLineItem',
      deleteKeyword: '/Keyword/DeleteKeyword',
      getAllMappingAccount: '/MapGLAccount/GetAllMappingAccount',
      export: '/MapGLAccount/ExportMapGLAccount',
      getAllLineItemsDetails: '/MapGLAccount/GetAllLineItemsDetails',
      deleteMappingAccount: '/MapGLAccount/DeleteMappingAccount',
      insertMappingAccount: '/MapGLAccount/InsertMappingAccount',
      getAZ: '/MapGLAccount/GetAllMappedAccountByStatementGroup',
      azDroplist: '/MapGLAccount/GetStatementGroupByBusinessType',
      exportKeywords: '/Keyword/ExportKeywords',
      importKeywords: '/Keyword/ImportKeywords',
      getSheetNamesFromContainer: '/Keyword/GetSheetNamesFromContainer',
      getAnalysisReviewTreeData: '/AnalysisTreeView/GetTreeViewData',
      getStatementBlockByStatementID:
        '/AnalysisTreeView/GetStatementBlockByStatementID',
      insertAnalysisStatement: '/AnalysisTreeView/InsertAnalysisStatement',
      updateAnalysisStatement: '/AnalysisTreeView/UpdateAnalysisStatement',
      deleteAnalysisStatement: '/AnalysisTreeView/DeleteAnalysisStatement',
      getUnusedLineItem: '/AnalysisTreeView/GetUnUsedLineItems',
      addLinkLineItem: '/AnalysisTreeView/AddLinkLineItem',
      unLinkLineItem: '/AnalysisTreeView/UnLinkLineItem',
      lockSegment: '/Locking/LockSegment',
      unlockSegment: '/Locking/UnLockSegment',
      SegmentLockDetail: '/Locking/GetSegmentLockDetail',
      getPreferences: '/Administration/GetPreferences',
      getAllFiscalYear: '/CommonEnlighten/GetAllFiscalYear',
      getLatestFiscalYear: '/CommonEnlighten/GetLatestFiscalDetail',
      GetSupplimentaryViewExcel: '/OfflineReferral/GetSupplimentaryExcel',
      getLayoutColumnData: '/AnalysisStatement/GetStatementLayoutColumn',
      updateStatementLayoutColumn:
        '/AnalysisStatement/UpdateStatementLayoutColumn',
      getSupplimentaryFileName: '/OfflineReferral/GetSupplimentaryFileName',
      deleteLinkLineItem: '/AnalysisTreeView/DeleteLinkLineItem',
      getFiscalYearforExport: '/Export/FiscalYear',
      generateGLAccountReport: '/GLAccountReport/GenerateGLAccountReport',
      getPeriodsForGLAccountReport:
        '/GLAccountReport/GetPeriodsForGLAccountReport',
        exportUnMapped: '/MapGLAccount/DownloadUnMappedAccounts',
        DownloadResponseFile: '/MapGLAccount/DownloadResponseFile',
    };
    //mock Json url to check in local
    this.mockApiUrl = {
      refData: './assets/data/referentialData.json',
      config: './assets/data/labelConfigurations.json',
      azuredevops: './assets/data/azuredevops.json',
      permission: './assets/data/permission.json',
      loggedinuserdetails: './assets/data/userDeetails.json',
      userdata: './assets/data/userData.json',
      userSearch: './assets/data/usersearch.json',
    };
    // this.prepareRequests();
  }

  //openAM Integration
  getOpenAMUserInfo(
    varURL: string,
    params: {},
    access_token: any
  ): Promise<any> {
    return this.postUserInfo(varURL, params, access_token)
      .toPromise()
      .then(
        (response) => {
          return response;
        },
        (err) => {
          return err;
        }
      );
  }
  postUserInfo(
    host: string,
    jsondata: any,
    access_token: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + access_token,
    };
    const headersObj = new HttpHeaders(headers);
    return this.http
      .post(host, jsondata, {
        headers: headersObj,
        withCredentials: false,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getTokenDetails(code: any): Observable<any> {
    var headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = environment.openam.token_endpoint;
    let body = new URLSearchParams();
    let codeVerifier: any = localStorage.getItem('verifier');
    body.set('code', code);
    body.set('client_id', environment.openam.client_id);
    body.set('code_verifier', codeVerifier);
    body.set('redirect_uri', environment.openam.app_url);
    body.set('grant_type', 'authorization_code');

    return this.http.post<string>(this.apiHost, body, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  //getToken from refreshToken
  getTokenDetailsFromRefreshToken(refreshToken: string): Observable<any> {
    var headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = environment.openam.token_endpoint;
    let codeVerifier: any = localStorage.getItem('verifier');
    let body = new URLSearchParams();
    body.set('refresh_token', refreshToken);
    body.set('client_id', environment.openam.client_id);
    body.set('code_verifier', codeVerifier);
    body.set('scope', 'openid');
    body.set('grant_type', 'refresh_token');
    return this.http.post<any>(this.apiHost, body, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  //getlogged in userdetails
  getLoggedUserInfo(): Promise<any> {
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //     const token = JSON.parse(
    //   '{"' +
    //     decodeURI(this.tokenValue)
    //       .replace(/"/g, '\\"')
    //       .replace(/&/g, '","')
    //       .replace(/=/g, '":"') +
    //     '"}'
    // );
    // console.log(token.access_token)
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getLoggedInUserDetails;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err: any) => {
        this.myGlobalObject.authorizationErrorMessage = err.error;
        this.menubarService.hide();
        this.router.navigate(['/unauthorized']);
        // return err;
      });
  }
  fetchClientsList(): Promise<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getClientsList;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
      })
      .toPromise()
      .then((response: any) => {
        if (response.length == 0) {
          this.myGlobalObject.authorizationErrorMessage =
            'No clients available for this user.';
          this.router.navigate(['/unauthorized']);
        }
        return response;
      })
      .catch((err) => {});
  }
  getAllBusinessTypes(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getAllBT;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  getazDroplist(id: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('businessTypeID', id);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.azDroplist;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  GetTaxAccountByLineItem(lineItemID: number): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('lineItemID', lineItemID);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.GetTaxAccountByLineItem;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  getAllCountry(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getAllCountry;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  getAllIndustry(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getAllIndustry;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  saveBusinessType(BTdetails: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.addBusinessType;
    return this.http
      .post(this.apiHost, BTdetails, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  updateBusinessType(BTdetails: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.updateBusinessType;
    return this.http
      .post(this.apiHost, BTdetails, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  getBusinessTypeById(BTId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('BusinessTypeId', BTId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getBusinessTypebyId;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  //delete businesstype details
  deleteLegalEntityById(LEId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('LegalEntityId', LEId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteLegalEntity;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        if (err.error == 'Reference Constraint Violated : 500') {
          return err.error;
        }
      });
  }
  getAllCurrency(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getAllCurrency;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  //legal entity
  getAllLegalEntities(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getAllLE;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  saveLegalEntity(LEdetails: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.addLegalEntity;
    return this.http
      .post(this.apiHost, LEdetails, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  //delete businesstype details
  deleteBusinessTypeById(BTId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('BusinessTypeId', BTId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteBusinessType;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        if (err.error == 'Reference Constraint Violated : 500') {
          return err.error;
        }
      });
  }
  updateLegalEntity(LEdetails: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.updateLegalEntity;
    return this.http
      .post(this.apiHost, LEdetails, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  getLegalEntityById(LEId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('LegalEntityId', LEId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getLegalEntityById;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  saveStatementLayout(SLdetails: any, type: string): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    if (type == 'new')
      this.apiHost =
        this.baseUrl + this.apifolder + this.apiUrl.addStatementLayout;
    else if (type == 'update')
      this.apiHost =
        this.baseUrl + this.apifolder + this.apiUrl.updateStatementLayout;
    else if (type == 'copyInsert')
      this.apiHost =
        this.baseUrl + this.apifolder + this.apiUrl.copyInsertStatementLayout;
    return this.http
      .post(this.apiHost, SLdetails, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  getAllStatementLayout(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllStatementLayout;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  public getAllChildNode(id: any): any {
    return this.fetch(id);
  }

  private fetch(id: any): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getAllChildNode;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })

      .pipe(
        map((response: any) => {
          var finalresult = [];
          finalresult.push(response[id]);
          // for(let i=0; i<response.length; i++){
          //      if(response[id] == id){
          //       finalresult.push(response[id]);
          //     }
          // }
          return finalresult[0];
        })
      );
  }

  saveAnalysisStatement(ASdetails: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.newSubAnalysis;
    return this.http
      .post(this.apiHost, ASdetails, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  getASDetailsById(ASId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('StatementLayoutId', ASId);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getASDetailsById;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  getAllStatementUsedBy(StatementLayoutId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementLayoutId', StatementLayoutId);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.statementUsedBy;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  deleteStatementLayout(StatementLayoutId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementLayoutId', StatementLayoutId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteStatementLayout;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }
  deleteSubAnalysisStatementLayout(StatementLayoutBlockId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementLayoutBlockId', StatementLayoutBlockId);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.deleteSubAnalysisStatementLayout;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }
  statementBlockMoveUp(StatementLayoutBlockId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementLayoutBlockId', StatementLayoutBlockId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.statementBlockMoveUp;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }
  statementBlockMoveDown(StatementLayoutBlockId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementLayoutBlockId', StatementLayoutBlockId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.statementBlockMoveDown;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }
  getAllLineItems(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getAllLineItems;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  getLineItemById(LineItemId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('lineItemId', LineItemId);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getLineItemById;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }
  getAllLineItemsByBusinessType(BusinessTypeID: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('businessTypeID', BusinessTypeID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllLineItemsByBusinessType;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }
  insertLineItem(_LIobject: {
    AccountingAreaID: any;
    Description: any;
    RequireFurtherAnalysis: boolean;
    AccountDerivation: null;
    AmountDisplayIndicator: boolean;
    TaxChartOfAccounts: number;
    AccountClass: number;
    AccountAreaGroup: null;
    StatementLevel: null;
    AccountingArea: number;
    HelpId: string;
  }): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.insertLineItem;
    return this.http
      .post(this.apiHost, JSON.stringify(_LIobject), {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  getParentLineItem(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getParentLineItem;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  deleteLineItem(LineItemId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('lineItemId', LineItemId);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.deleteLineItem;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }
  updateLineItem(LIDetails: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.updateLineItem;
    return this.http
      .post(this.apiHost, LIDetails, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  getAllAccountClass(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllAccountClass;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  //Tax Accounts

  getAllTaxAccountCode(TaxChartAccountId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('taxChartAccountId', TaxChartAccountId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllTaxAccountCode;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }

  insertTaxAccountCode(TAobject: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.insertTaxAccountCode;
    return this.http
      .post(this.apiHost, TAobject, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  updateTaxAccountCode(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.updateTaxAccountCode;
    return this.http
      .post(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  getTaxAccountParentData(ParentDatapayload: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getTaxAccountParentData;
    return this.http
      .post(this.apiHost, ParentDatapayload, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  getTaxaccountLineitem(TAlineItempayload: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getTaxaccountLineitem;
    return this.http
      .post(this.apiHost, TAlineItempayload, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  //Tax Outcomes

  getAllTaxOutcomes(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllTaxOutcomes;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  //StatutoryTreatment

  getAllAccountTypes(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllAccountTypes;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  //delete tax account
  getdeleteTaxAccountData(TaxChartAccountCode: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('taxChartAccountId', TaxChartAccountCode);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getDeleteTaxAccountdata;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }
  getAllAccountType(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllAccountType;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  //GL Accounts

  getAllStatutoryAccountCodeByCode(StatutoryCoAId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statutoryCoAId', StatutoryCoAId);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getAllStatutoryAccountCodeByCode;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }

  getDistinctStatutoryAccountCodes(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getDistinctStatutoryAccountCodes;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  insertStatutoryAccountCode(StatutoryGLObject: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.insertStatutoryAccountCode;
    return this.http
      .post(this.apiHost, StatutoryGLObject, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  getStatutoryAccountCodesById(StatutoryAccountCodeId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statutoryAccountCodeId', StatutoryAccountCodeId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getStatutoryAccountCodesById;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }

  updateStatutoryAccountCode(editStatutoryGLObject: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.updateStatutoryAccountCode;
    return this.http
      .post(this.apiHost, editStatutoryGLObject, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  deleteStatutoryAccountCode(StatutoryAccountCodeId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statutoryAccountCodeId', StatutoryAccountCodeId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteStatutoryAccountCode;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }

  getAllKeywordGroupFolders(): Observable<KeywordGroupFolder[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllKeywordGroupFolder;
    return this.http.get<KeywordGroupFolder[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getAllKeywordGroup(
    vocabularyGroupFolderId: number
  ): Observable<KeywordGroup[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('vocabularyGroupFolderId', vocabularyGroupFolderId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllKeywordGroup;
    return this.http.get<KeywordGroup[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getAllKeywordsData(): Observable<KeywordData[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllKeywordData;
    return this.http.get<KeywordData[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  editKeyWordFolder(
    keywordGroupFolder: KeywordGroupFolder
  ): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set(
        'vocabularyGroupFolderId',
        keywordGroupFolder.VocabularyGroupFolderId
      )
      .set('description', keywordGroupFolder.Name);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.editKeywordGroupFolder;
    return this.http.put<string>(this.apiHost, null, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  editKeywordGroup(keyword: Keyword): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('vocabularyGroupId', keyword.vocabularyId)
      .set('description', keyword.description);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.editKeywordGroup;
    return this.http.put<string>(this.apiHost, null, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  editKeyword(keyword: Keyword): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('vocabularyGroupId', keyword.vocabularyGroupId)
      .set('vocabularyId', keyword.vocabularyId)
      .set('description', keyword.description);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.editKeyword;
    return this.http.put<string>(this.apiHost, null, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  createKeywordFolder(keyword: KeywordGroupFolder): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('folderName', keyword.Name);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.createKeywordFolder;
    return this.http.post<string>(this.apiHost, null, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  CreateKeywordGroup(keyword: KeywordGroupFolder): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('groupName', keyword.Name)
      .set('folderID', keyword.VocabularyGroupFolderId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.createKeywordGroup;
    return this.http.post<string>(this.apiHost, null, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  CreateKeyword(keyword: KeywordGroupFolder): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('keywordName', keyword.Name)
      .set('groupID', keyword.VocabularyGroupFolderId);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.createKeyword;
    return this.http.post<string>(this.apiHost, null, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  DeleteKeywordGroupFolder(keyword: any): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('vocabularyGroupFolderId', keyword);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteKeywordGroupFolder;
    return this.http.delete<string>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  DeleteKeywordGroup(keyword: any): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('vocabularyGroupId', keyword);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteKeywordGroup;
    return this.http.delete<string>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  DeleteKeyword(keyword: any): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('vocabularyId', keyword);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.deleteKeyword;
    return this.http.delete<string>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }
  getAllMappingAccount(complex: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('isComplex', complex);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllMappingAccount;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  getExport(id: any): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('businessTypeID', id);

    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.export;
    return this.http.post(this.apiHost, httpParams, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
  getAZ(
    businessTypeID: any,
    statementGroupID: any,
    isComplex: boolean
  ): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('businessTypeID', businessTypeID)
      .set('statementGroupID', statementGroupID)
      .set('isComplex', isComplex);

    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getAZ;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  getAllLineItemsDetails(filter: any, sort: any, business: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('filterType', filter)
      .set('businessTypeID', business)
      .set('sortType', sort);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllLineItemsDetails;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  ExportKeywords(keyWordParams: number[]): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.exportKeywords;
    return this.http.post(this.apiHost, keyWordParams, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
  deleteMappingAccount(deleteData: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteMappingAccount;
    return this.http
      .post(this.apiHost, deleteData, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        if (err.error == 'Reference Constraint Violated : 500') {
          return err.error;
        }
      });
  }

  insertMappingAccount(request: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.insertMappingAccount;
    return this.http
      .post(this.apiHost, [request], {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        if (err.error == 'Reference Constraint Violated : 500') {
          return err.error;
        }
      });
  }
  ImportKeywords(worksheetParams: string[], fileName: string): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fileName', fileName);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.importKeywords;
    return this.http.post<string>(this.apiHost, worksheetParams, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'text' as 'json',
    });
  }
  getSheetNamesFromContainer(fileName: string): Observable<Worksheet[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fileName', fileName);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getSheetNamesFromContainer;
    return this.http.get<Worksheet[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getAnalysisReviewTreeData(reportName: string): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let year: any = localStorage.getItem('selectedFiscalYear');
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set(
        'fiscalYear',
        year == 'null' || year == null ? 0 : JSON.parse(year).FiscalYearId
      )
      .set('reportName', reportName);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAnalysisReviewTreeData;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  getStatementBlockByStatementID(
    statementID: number,
    statemenGroupID: number,
    isEdit: boolean
  ): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementID', statementID)
      .set('statementGroupID', statemenGroupID)
      .set('isEdit', isEdit);

    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getStatementBlockByStatementID;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  insertAnalysisStatement(request: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    let apiURL =
      request.ID == 0
        ? this.apiUrl.insertAnalysisStatement
        : this.apiUrl.updateAnalysisStatement;
    this.apiHost = this.baseUrl + this.apifolder + apiURL;
    return this.http
      .post(this.apiHost, request, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }

  deleteAnalysisStatement(statementId: number): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementId', statementId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteAnalysisStatement;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }

  getUnusedLineItem(statementID: number): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementId', statementID);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getUnusedLineItem;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  addLinkItem(request: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.addLinkLineItem;
    return this.http
      .post(this.apiHost, request, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }

  unLinklineItem(request: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.unLinkLineItem;
    return this.http
      .post(this.apiHost, request, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }

  LockSegment(segmentName: string): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('segmentName', segmentName);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.lockSegment;
    return this.http.get<any>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  UnLockSegment(segmentName: string): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('segmentName', segmentName);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.unlockSegment +
      '?ClientdbName=' +
      this.selectedClient.DBName +
      '&segmentName=' +
      segmentName;
    return this.http.put<any>(
      this.apiHost,
      {},
      {
        headers,
      }
    );
  }
  segmentLockDetail(segmentName: string): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('segmentName', segmentName);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.SegmentLockDetail;
    return this.http.get<any>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  public fetchClientPreferences() {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getPreferences;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  public getLatestFiscalYear() {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getLatestFiscalYear;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  public getAllFiscalYear() {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getAllFiscalYear;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  getFiscalYearforExport() {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getFiscalYearforExport;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  downloadSupplimentaryViewExcel(supllimentaryID: number): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    let year: any = localStorage.getItem('selectedFiscalYear');
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('supplimentaryId', supllimentaryID)
      .set('fiscalYear', JSON.parse(year).FiscalYearId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.GetSupplimentaryViewExcel;
    return this.http.post(
      this.apiHost,
      {},
      {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        observe: 'response',
        responseType: 'blob' as 'json',
      }
    );
  }

  public getLayoutColumnData(childNodeLayoutId: number) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('childNodeLayoutId', childNodeLayoutId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getLayoutColumnData;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  savestatementColumn(columnID: number, description: string): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('childNodeLayoutId', columnID)
      .set('childDescription', description);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.updateStatementLayoutColumn;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  public GetSupplimentaryFileName(supllimentaryID: number) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    let year: any = localStorage.getItem('selectedFiscalYear');
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('supplimentaryId', supllimentaryID)
      .set('fiscalYear', JSON.parse(year).FiscalYearId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getSupplimentaryFileName;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  deleteLinkLineItem(LineItemId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('lineItemId', LineItemId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteLinkLineItem;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }

  GetPeriodsForGLAccountReport(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    let year: any = localStorage.getItem('selectedFiscalYear');
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', JSON.parse(year).FiscalYearId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getPeriodsForGLAccountReport;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  generateGLAccountReportRun(
    busTypeId: any,
    legalEntityId: any,
    statementID: any,
    runpayload: any
  ): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('busTypeId', busTypeId)
      .set('legalEntityId', legalEntityId)
      .set('statementId', statementID)
      .set('fiscalYear', JSON.parse(year).FiscalYearId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.generateGLAccountReport;
    return this.http.post(this.apiHost, runpayload, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getUnmappedExport(id: any): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('businessTypeID', id);

    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.exportUnMapped;
    return this.http.post(this.apiHost, httpParams, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  DownloadResponseFile(response: any): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fileResponse', response);

    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.DownloadResponseFile;
    return this.http.post(this.apiHost, httpParams, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
