import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gl-account',
  templateUrl: './gl-account.component.html',
  styleUrls: ['./gl-account.component.scss']
})
export class GlAccountComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
