import { Component, OnInit, Input } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { orderBy, process, SortDescriptor, State, } from '@progress/kendo-data-query';
import { SelectionEvent } from '@progress/kendo-angular-grid';
@Component({
  selector: 'app-offine-referalls',
  templateUrl: './offine-referalls.component.html',
  styleUrls: ['./offine-referalls.component.scss']
})
export class OffineReferallsComponent implements OnInit {
  public isLoading: boolean = false;
  @Input() offlineAccountSummary: any;
  // public fiscalYear;
  public gridData: any = [];
  public mySelection: number[] = [];
  public ColorFormat(amount: any) {
    return amount >= 0 ? 'color: black' : 'color: red';
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.offlineAccountSummary = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }
  public sort: SortDescriptor[] = [
    {
      field: 'AccountNumber',
      dir: 'asc',
    },
  ];
  constructor(public dialog: DialogRef,) { }

  ngOnInit(): void {
    console.log(this.offlineAccountSummary)
  }
  closeButtonClick() {
    this.dialog.close();
  }
  public onGridSelectionChange(event: SelectionEvent) {
    // if (event.selectedRows != undefined) {
    //   this.selectedRowData = event.selectedRows[0].dataItem;
    //   if (event.selectedRows[0].dataItem.Editable == true) {
    //     this.isReimportdisabled = false;
    //   } else this.isReimportdisabled = true;
    // }
  }
  public IsSupplementaryChange(event: Event, dataItem: any): void {
    // this.offlineService
    //   .UpdateOfflineReferralSupplementary(
    //     dataItem.Id,
    //     (event.target as HTMLInputElement).checked
    //   )
    //   .subscribe((response) => {});
  }


}

