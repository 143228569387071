import { Component, Input, OnInit } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  DialogContentBase,
  ActionsLayout,
  DialogAction,
} from '@progress/kendo-angular-dialog';
import { AnalysispackService } from 'src/app/common/services/analysispackservice/analysispack.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
@Component({
  selector: 'app-delete-analysis-pack-popup',
  templateUrl: './delete-analysis-pack-popup.component.html',
  styleUrls: ['./delete-analysis-pack-popup.component.scss'],
})
export class DeleteAnalysisPackPopupComponent implements OnInit {
  @Input() public delAnalysisPackdata: any;
  selectedClient: any;

  warnondelete: boolean = false;
  public actionsLayout: ActionsLayout = 'normal';
  constructor(
    public dialog: DialogRef,
    public toastr: ToastrManager,
    private dialogService: DialogService,
    private router: Router,
    private analysisPackService: AnalysispackService
  ) {}
  public apId: any;
  public node: any;

  ngOnInit(): void {}
  deletePack() {
    let statementGroupId = this.node.type == 'AP' ? this.node.id : 0;

    this.analysisPackService
      .deleteAnalysisPack(statementGroupId)
      .then((res: any) => {
        if (res == 'Analysis Pack is deleted successfully') {
          this.toastr.successToastr('Analysis pack is deleted successfully', 'Success');
          this.displaytreePack();
        } else if (res == 'Analysis Pack Cannot delete') {
          this.toastr.warningToastr(
            'Sorry...you cannot delete this pack as it have children nodes',
            'Warning!'
          );
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
        this.dialog.close();
      })
      .catch((err: any) => {
        this.toastr.errorToastr(
          'Something went wrong try after sometime',
          'Oops!'
        );
        console.log('Error from Api');
        console.log(err);
      });
  }

  displaytreePack() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }

  cancelClick() {
    this.dialog.close();
  }
}
