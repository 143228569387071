<!-- <div>
<app-header></app-header>
</div> -->

<!-- <kendo-dialog-titlebar (close)="closeButtonClick()">
  <div style="font-size: 20px; line-height: 1.3em">
    Manage analysis statement layouts
  </div>
</kendo-dialog-titlebar> -->
<div class="header">Manage analysis statement layouts</div>

<div
  id="mainContent"
  style="
    overflow: scroll;
    width: 44%;
    float: left;
    margin: 0px 10px 0px 10px;
    border: 1px solid #d9d9d9;
    padding-top: 5px;
  "
>
  <kendo-treeview
    #treeview
    [nodes]="analysisStatement"
    [textField]="['Description', 'ChildDescription']"
    kendoTreeViewExpandable
    kendoTreeViewSelectable
    [hasChildren]="hasChildren"
    [children]="fetchChildren"
    (nodeClick)="onNodeClick($event)"
    [isSelected]="isItemSelected"
    (selectionChange)="handleSelection($event)"
  >
    <!-- <ng-template kendoTreeViewNodeTemplate let-dataItem>
    <span class="k-icon {{dataItem.icon}}"></span>
    {{dataItem.Description}}
</ng-template> -->
    <ng-template kendoTreeViewNodeTemplate let-dataItem>
      <span
        *ngIf="dataItem.Description && dataItem.LayoutTypeId == 1"
        class="Appkit4-icon icon-document-text-outline"
        style="cursor: pointer; color: #a7452c"
      ></span>
      <span
        *ngIf="dataItem.Description && dataItem.LayoutTypeId == 2"
        class="Appkit4-icon icon-document-text-outline"
        style="cursor: pointer; color: #a7452c"
      ></span>
      <span
        *ngIf="
          dataItem.ChildDescription &&
          dataItem.ParentStatementLayoutId != 0 &&
          dataItem.Type == 'A'
        "
        class="Appkit4-icon icon-workflow-outline"
        style="cursor: pointer; color: #a7452c"
      ></span>
      <span
        *ngIf="
          dataItem.ChildDescription &&
          dataItem.ParentStatementLayoutId != 0 &&
          dataItem.Type == 'C'
        "
        class="Appkit4-icon icon-table-data-compressed-outline"
        style="cursor: pointer; color: #a7452c"
      ></span>

      {{ dataItem.Description }} {{ dataItem.ChildDescription }}
    </ng-template>
  </kendo-treeview>
</div>

<!-- <div class="container-fluid">
  <kendo-dialog-actions
    [layout]="actionsLayout"
    style="position: fixed; top:53%;bottom: 38%; left: 45%;right: 45%; border-top: 0px"
  >
    <button
      kendoButton
      class="hover"
      
      [disabled]="ismoveDown"
      style="color: #d04a02; display: flex;background-color: transparent;border-color: #d04a02;opacity: 1;cursor: pointer !important;"
      (click)="onMoveDownButtonClick($event)"
    >
      <span class="material-icons">keyboard_arrow_down</span>
    </button>
    
  </kendo-dialog-actions>
</div>
<div class="container-fluid">
  <kendo-dialog-actions
    [layout]="actionsLayout"
    style="position: fixed; top: 45%;bottom: 45%; left: 45%;right:45% ; border-top: 0px"
  >
    <button
      kendoButton
      class="hover"

      [disabled]="ismoveUp"
      style="color: #d04a02; display: flex;background-color: transparent;border-color: #d04a02;opacity: 1;cursor: pointer;"
      (click)="onMoveUpButtonClick($event)"
    >
      <span class="material-icons">keyboard_arrow_up</span>
    </button>
  </kendo-dialog-actions>
</div> -->
<div class="container-fluid" style="padding-right: 0px !important">
  <mat-card-title>
    <div class="wrapper">
      <kendo-tabstrip
        [ngStyle]="{ width: '54.3%', height: 'calc(100vh - 210px)' }"
        (tabSelect)="onTabSelect($event)"
        style="position: fixed; right: 0.5%"
      >
        <kendo-tabstrip-tab title="Preview" [selected]="true">
          <ng-template kendoTabContent>
            <div class="">
              <kendo-grid
                [data]="previewData"
                scrollable="none"
                style="
                  position: absolute;
                  margin-top: -1%;
                  right: -1%;
                  left: 0%;
                "
              >
                <kendo-grid-column
                  *ngIf="SAType == 'StatementAnalysis'"
                  field="Description"
                  title="Description"
                  [width]="1000"
                  [headerStyle]="{
                    'font-weight': 'bold',
                    'text-align': 'center',
                    color: '#d04a02'
                  }"
                ></kendo-grid-column>
                <kendo-grid-column
                  *ngIf="SAType == 'StatementAnalysis'"
                  field="Amount"
                  title="Amount"
                  [width]="1000"
                  [headerStyle]="{
                    'font-weight': 'bold',
                    'text-align': 'center',
                    color: '#d04a02'
                  }"
                ></kendo-grid-column>
                <kendo-grid-column
                  *ngIf="SAType == 'BFStatement'"
                  field="Description"
                  title="Description"
                  [width]="120"
                  [headerStyle]="{
                    'font-weight': 'bold',
                    'text-align': 'left',
                    color: '#d04a02'
                  }"
                ></kendo-grid-column>
                <kendo-grid-column
                  *ngIf="SAType == 'BFStatement'"
                  field="Balancebfwd"
                  title="Balance b/fwd"
                  [width]="120"
                  [headerStyle]="{
                    'font-weight': 'bold',
                    'text-align': 'left',
                    color: '#d04a02'
                  }"
                ></kendo-grid-column>
                <kendo-grid-column
                  *ngIf="SAType == 'BFStatement'"
                  field="Movements"
                  title="Movements"
                  [width]="120"
                  [headerStyle]="{
                    'font-weight': 'bold',
                    'text-align': 'left',
                    color: '#d04a02'
                  }"
                ></kendo-grid-column>
                <kendo-grid-column
                  *ngIf="SAType == 'BFStatement'"
                  field="Balancecfwd"
                  title="Balance c/fwd"
                  [width]="120"
                  [headerStyle]="{
                    'font-weight': 'bold',
                    'text-align': 'left',
                    color: '#d04a02'
                  }"
                ></kendo-grid-column>
              </kendo-grid>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [disabled]="usedBydisable" title="Used by">
          <ng-template kendoTabContent>
            <div class="content">
              <kendo-grid
                scrollable="none"
                [data]="usedByData"
                style="
                  position: absolute;
                  margin-top: -8%;
                  right: -1%;
                  left: 0%;
                "
              >
                <kendo-grid-column
                  field="Type"
                  title="Type"
                  [width]="1000"
                  [headerStyle]="{
                    'font-weight': 'bold',
                    'text-align': 'center',
                    color: '#d04a02'
                  }"
                ></kendo-grid-column>
                <kendo-grid-column
                  field="AnalysisStatementPath"
                  title="Analysis Statement Path"
                  [width]="1000"
                  [headerStyle]="{
                    'font-weight': 'bold',
                    'text-align': 'center',
                    color: '#d04a02'
                  }"
                ></kendo-grid-column>
              </kendo-grid>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </mat-card-title>
</div>

<div
  class="clickToActions"
  style="
    padding: 20px 0px 20px 15px;
    display: flex;
    position: fixed;
    right: 0.5%;
    bottom: 0%;
  "
>
  <ap-button
    [btnType]="'secondary'"
    [label]="'Close'"
    (click)="closeButtonClick()"
  ></ap-button>
  <button
    kendoButton
    class="btn-pwc-primary"
    [disabled]="isCopy"
    (click)="copyButtonClick($event)"
    style="color: #fff"
  >
    Copy
  </button>

  <button
    kendoButton
    class="btn-pwc-primary"
    [disabled]="isdelEnable"
    (click)="deleteButtonClick($event)"
    style="color: #fff"
  >
    Delete
  </button>

  <button
    kendoButton
    class="btn-pwc-primary"
    [disabled]="isEdit"
    (click)="editButtonClick($event)"
    style="color: #fff"
  >
    Edit
  </button>

  <button
    kendoButton
    class="btn-pwc-primary"
    [disabled]="isChild"
    (click)="newSubanalysisClick($event)"
    style="color: #fff"
  >
    New sub-analysis
  </button>

  <button
    kendoButton
    class="btn-pwc-primary"
    (click)="openAddBFASLayoutPopup()"
    style="color: #fff"
  >
    New BF statement layout
  </button>

  <button
    kendoButton
    class="btn-pwc-primary"
    (click)="openAddASLayoutPopup()"
    style="color: #fff"
  >
    New analysis statement layout
  </button>
</div>
