import { Component, OnInit, Input } from '@angular/core';
import {
  DialogContentBase,
  ActionsLayout,
  DialogAction,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from '@progress/kendo-angular-dialog';
import { HabitatService } from '../../common/services/habitatservice/habitat.service';
import { HabitatsComponent } from '../habitats.component';

@Component({
  selector: 'app-deletehabitats',
  templateUrl: './deletehabitats.component.html',
  styleUrls: ['./deletehabitats.component.scss'],
})
export class DeletehabitatsComponent
  extends DialogContentBase
  implements OnInit
{
  @Input() public delHabitatdata: any;

  dialogTitle: any = "Delete Habitat's";
  confirmText: any;
  warnondelete: boolean = false;
  public actionsLayout: ActionsLayout = 'normal';

  delHabitatDetails: any;
  constructor(
    public dialog: DialogRef,
    public toastr: ToastrManager,
    private dialogService: DialogService,
    private habitatService: HabitatService
  ) {
    super(dialog);
  }

  ngOnInit(): void {}

  cancelClick() {
    this.dialog.close();
  }

  deleteHabitat() {
    this.habitatService
      .deleteHabitat(this.delHabitatdata.HabitatId)
      .then((res: any) => {
        if (res == 'Habitat is deleted successfully') {
          this.toastr.successToastr(res, 'Success');
        } else if (res != 'habitat is deleted successfully') {
          // this.warnondelete=true;
          this.toastr.errorToastr(
            'Sorry...you cannot delete as this habitat is already in use',
            'Oops!'
          );
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
        this.dialog.close();
      })
      .catch((err: any) => {
        this.toastr.errorToastr(
          'Something went wrong try after sometime',
          'Oops!'
        );
        this.dialog.close();
        console.log(err);
      });
  }
}
