import { Component, OnInit,Input } from '@angular/core';
import { DialogContentBase, ActionsLayout, DialogAction} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DialogService, DialogRef,DialogCloseResult} from '@progress/kendo-angular-dialog';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';

@Component({
  selector: 'app-deletestatementlayout',
  templateUrl: './deletestatementlayout.component.html',
  styleUrls: ['./deletestatementlayout.component.scss']
})
export class DeletestatementlayoutComponent extends DialogContentBase implements OnInit {
  @Input() public delSLDetails: any;
  dialogTitle:any="Delete statement layout(s)";
  confirmText:any;
  public actionsLayout: ActionsLayout = 'normal';
  constructor(
    public dialog: DialogRef,     
    public toastr: ToastrManager,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) { 
    super(dialog);
  }

  ngOnInit(): void {
    if(this.delSLDetails.StatementLayoutId){
      this.dialogTitle="Delete statement layout(s)";
      this.confirmText="statement layout(s).";
    }else if(this.delSLDetails.ParentStatementLayoutId){
      this.dialogTitle="Delete  analysis statement layout sub-analysis"
    this.confirmText="analysis statement layout sub-analysis.";
    }
  }
  cancelClick(){
    this.dialog.close();
  }
  deleteStatementLayout(){
    if(this.delSLDetails.StatementLayoutId){
    this.globalService.deleteStatementLayout(this.delSLDetails.StatementLayoutId).then((res: any) => {   
      
      if(res == "Analysis Statement is deleted successfully"){
        
        this.toastr.successToastr(
          'Analysis statement is deleted successfully',
          'Success'
        );
        this.dialog.close();
      }else{
        this.toastr.errorToastr('Something went wrong try after sometime', 'Oops!');
      }
      
      
    }).catch((err: any) => {
      
    })
    }else if(this.delSLDetails.ParentStatementLayoutId){
      this.globalService.deleteSubAnalysisStatementLayout(this.delSLDetails.ChildNodeLayoutId).then((res: any) => {    

        if(res == "Analysis Block is deleted successfully"){
          
          this.toastr.successToastr(
            "Analysis block is deleted successfully",
            'Success'
          );
          this.dialog.close();
        }else{
          this.toastr.errorToastr('Something went wrong try after sometime', 'Oops!');
        }
        
        
      }).catch((err: any) => {
        
      })
    }
  }
}
