<div class="header">Offline allocation import wizard</div>

<mat-card class="userCard" style="padding-top: 10px">
  <div class="OfflineImport">
    <div class="container2">
      <div class="row">
        <div class="col-12" style="margin-bottom: 5px">
          <ap-progress-stepper-set
            [orientation]="orientation"
            [(activeIndex)]="activeIndex"
            [space]="space"
            [readonly]="readonly"
          >
            <ap-progress-stepper
              *ngFor="let tab of steps; let i = index"
              [label]="tab.label"
            >
              <!-- Set the content of every stepper panel here -->
            </ap-progress-stepper>
            <div *ngIf="isLoading">
              <kendo-chunkprogressbar
                [chunkCount]="10"
                [min]="-10"
                [max]="10"
                [indeterminate]="true"
                [value]="30"
                style="width: 100%"
              ></kendo-chunkprogressbar>
            </div>
          </ap-progress-stepper-set>
        </div>
      </div>
    </div>
    <!-- Import wizard content here -->
    <div
      class="card"
      *ngIf="activeIndex == 0"
      style="height: calc(100vh - 250px)"
    >
      <div class="card-body card_body">
        <div class="" style="font-size: 35px">
          <div class="">
            <div class="ap-container" style="padding: 0px">
              <div class="title1" style="margin-bottom: 0px">
                <h5 style="text-align: left">
                  Welcome to the import offline allocation wizard
                </h5>
                <!-- <p>With supporting text below as a natural lead-in to additional content.</p> -->
                <p style="margin-bottom: 10px">
                  The offline allocation import wizard allows you to import
                  analysed information previously extracted from the system.
                </p>
                <!-- <p>
                  You can import the information from one of the following
                  sources: Microsoft Access Microsoft Excel.
                </p> -->
                <p style="margin-bottom: 10px">
                  Click select files to import data.
                </p>

                <div class="card">
                  <div
                    class="card-body card_body"
                    style="padding: 10px 0px; height: calc(100vh - 420px)"
                  >
                    <div class="ap-container">
                      <div class="row">
                        <div class="col-4">
                          <div class="title">
                            <p style="margin-bottom: 10px">
                              Select the file to import the data from
                            </p>
                          </div>
                          <div class="col-8"></div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-1 Appkit4-iconFirst">
                          <span
                            class="Appkit4-icon icon-folder-opened-outline"
                          ></span>
                        </div>
                        <div
                          class="col-9 input-class uploadfile-css"
                          style="width: 91%; padding: 10px"
                        >
                          <kendo-upload
                            [saveUrl]="uploadSaveUrl"
                            (upload)="uploadEventHandler($event)"
                            [withCredentials]="withCredential"
                            [multiple]="false"
                            (success)="success($event)"
                            [restrictions]="myRestrictions"
                            [chunkable]="chunkSettings"
                          >
                          </kendo-upload>
                          <div *ngIf="fileName" class="displayfile">
                            <div>Uploaded file : {{ this.fileName }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card"
      *ngIf="activeIndex == 1"
      style="height: calc(100vh - 250px)"
    >
      <div class="card-body card_body">
        <div
          class="ap-container app-content"
          style="padding: 0px; margin-top: 10px"
        >
          <div class="row" *ngIf="!isLoading">
            <div class="col" style="padding: 0px">
              <div
                class="card"
                style="text-align: center; height: calc(100vh - 330px)"
              >
                <div class="card-header Pre-processing" *ngIf="validationError">
                  <span
                    class="Appkit4-icon icon-circle-warning-outline"
                    style="font-size: 24px; color: red; margin-right: 10px"
                  ></span>
                  <b style="color: red">Validation failed</b>
                </div>
                <div class="card-body Pre-processing" *ngIf="validationError">
                  <p class="card-text">
                    Import failed: {{ validationErrorMessage }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card"
      *ngIf="activeIndex == 2"
      style="height: calc(100vh - 250px)"
    >
      <div class="card-body card_body">
        <div class="ap-container app-content" style="padding: 0px">
          <div class="row" *ngIf="!isLoading">
            <div class="col" style="padding: 0px">
              <div class="card-body Pre-processing" style="padding: 0px">
                <p style="margin-bottom: 10px">
                  New tax accounts have been detected.
                </p>
                <div>
                  <p style="margin-bottom: 10px">
                    The following tax accounts will be added to the system:
                  </p>
                  <kendo-grid
                    [data]="taxAccounts"
                    scrollable="virtual"
                    [resizable]="true"
                    style="height: calc(100vh - 380px)"
                  >
                    <kendo-grid-column
                      field="TaxAccountCode"
                      title="Tax account code"
                      [width]="120"
                      [headerStyle]="{
                        'font-weight': 'bold',
                        'text-align': 'left',
                        color: '#d04a02'
                      }"
                    ></kendo-grid-column>
                    <kendo-grid-column
                      field="ShortDescription"
                      title="Tax account name"
                      [width]="320"
                      [headerStyle]="{
                        'font-weight': 'bold',
                        'text-align': 'left',
                        color: '#d04a02'
                      }"
                    ></kendo-grid-column>
                    <kendo-grid-column
                      field="ParentTaxAccountCode"
                      title="Parent tax account"
                      [width]="220"
                      [headerStyle]="{
                        'font-weight': 'bold',
                        'text-align': 'left',
                        color: '#d04a02'
                      }"
                    ></kendo-grid-column>
                  </kendo-grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card"
      *ngIf="activeIndex == 3"
      style="height: calc(100vh - 250px)"
    >
      <div class="card-body card_body">
        <div class="ap-container app-content" style="padding: 0px">
          <div class="row" *ngIf="!isLoading">
            <div class="col" style="padding: 0px">
              <div class="card-body Pre-processing" style="padding: 0px">
                <div class="section">
                  <p>
                    Would you like to add supplementary calculations to the
                    analysis pack?
                  </p>
                  <div class="wrap">
                    <input
                      type="radio"
                      name="supplementaryCalc"
                      value="Yes"
                      #yes
                      [(ngModel)]="model.supplementaryCalc"
                      kendoRadioButton
                      checked
                    />&nbsp;&nbsp;
                    <kendo-label [for]="yes" text="Yes"></kendo-label>
                  </div>
                </div>
                <div class="section">
                  <div class="wrap">
                    <input
                      type="radio"
                      name="supplementaryCalc"
                      value="No"
                      #no
                      [(ngModel)]="model.supplementaryCalc"
                      kendoRadioButton
                    />&nbsp;&nbsp;
                    <kendo-label [for]="no" text="No"></kendo-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card"
      *ngIf="activeIndex == 4"
      style="height: calc(100vh - 250px)"
    >
      <div class="card-body card_body">
        <div class="ap-container app-content" style="padding: 0px">
          <div class="row" style="margin-top: 10px">
            <div class="col-12" style="padding: 0px">
              <div class="title1" *ngIf="!isLoading && isImportSuccess">
                <b style="font-size: 14px">Import completed</b>
                <p style="margin-bottom: 10px">
                  Offline allocation information have been imported.
                </p>
              </div>
            </div>
            <div class="col-12" style="padding: 0px">
              <div class="card">
                <div class="card-body title1">
                  <p class="summaryDetail">
                    {{ importSummaryDetail }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12" style="padding: 0px">
              <div class="card">
                <div
                  class="card-body title1"
                  style="
                    padding: 20px 0px;
                    height: calc(100vh - 500px);
                    display: flex;
                  "
                >
                  <div style="margin-right: 10px">
                    <p *ngIf="isImportSuccess">
                      <ap-button
                        style="margin-left: 10px"
                        type="button"
                        [btnType]="'primary'"
                        [label]="'Download summary'"
                        (onClick)="downloadImportOfflineReferralReport()"
                      >
                      </ap-button
                      ><br /><span style="margin-left: 20px; margin-top: 10px">
                        Click the button to download<br /><span
                          style="margin-left: 20px; margin-top: 10px"
                        >
                          summary report</span
                        ></span
                      >
                    </p>
                  </div>
                  <div>
                    <p *ngIf="isImportSuccess">
                      <ap-button
                        style="width: 190px"
                        type="button"
                        [btnType]="'primary'"
                        [label]="'Download excel'"
                        (onClick)="downloadOfflineReferralExcelFile()"
                      >
                      </ap-button
                      ><br /><span>
                        Click the button to download<br /><span>
                          uploaded excel</span
                        ></span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fixed-bottom">
      <div class="row">
        <div class="card" style="border: none">
          <div class="card-body">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end" style="">
              <ap-button
                type="button"
                [btnType]="'secondary'"
                [label]="'Back'"
                style="width: 100px"
                [disabled]="isBackDisabled"
                (onClick)="handleBackButton()"
              >
              </ap-button>
              <ap-button
                type="button"
                [btnType]="'primary'"
                [label]="'Next'"
                class="okButton"
                [disabled]="isNextDisabled"
                (onClick)="handleNextButton()"
              >
              </ap-button>
              <ap-button
                *ngIf="activeIndex == 4 && !this.isLoading"
                type="button"
                [btnType]="'primary'"
                [label]="'Finish'"
                class="okButton"
                (click)="finish()"
              >
              </ap-button>
              <ap-button
                *ngIf="activeIndex != 4"
                type="button"
                [btnType]="'secondary'"
                [disabled]="isCancelDisabled"
                [label]="'Cancel'"
                style="width: 100px"
                (click)="handleCancelButton()"
              >
              </ap-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog
    title="Cancel wizard"
    *ngIf="isConfirmOpen"
    (close)="close('no')"
    [minWidth]="250"
    [width]="500"
  >
    <div style="display: flex">
      <p>
        <span
          class="Appkit4-icon icon-circle-warning-outline"
          style="font-size: 24px; color: #d04a02"
        ></span>
      </p>
      <p style="margin-left: 10px; font-size: medium">
        Are you sure you wish to cancel the import wizard?
      </p>
    </div>

    <div class="clickToActions" style="padding: 10px 0px 0px 0px">
      <ap-button
        [btnType]="'secondary'"
        [label]="'No'"
        style="width: 100px"
        (click)="close('no')"
      ></ap-button>
      <ap-button
        [btnType]="'primary'"
        [label]="'Yes'"
        themeColor="primary"
        class="okButton"
        (click)="close('yes')"
        style="margin-right: 6px"
      ></ap-button>
    </div>
  </kendo-dialog>
</mat-card>
