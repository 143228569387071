<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Link line item</div>
</kendo-dialog-titlebar>
<div>
  <h6>Analysis statement: {{ statementDesc }}</h6>
  <ap-search
    [placeholder]="'Search here'"
    [searchType]="'secondary'"
    style="width: 100%;  padding: 0px !important"
    [disabled]="false"
    (onSearch)="onFilter($event)"
  ></ap-search>
</div>

<mat-card class="userCard" style="padding: 0px">
  <!-- <mat-card-title>
    <h6>Analysis Statement: {{ statementDesc }}</h6>
  </mat-card-title> -->
  <mat-card-content>
    <div>
        <!-- <form>
          <div class="form-group">
            <label for="search" class="font" style="margin-top: 1.5%"
              >Search for</label
            ><br />
            <input
              [style.width.px]="550"
              placeholder="Search here..."
              kendoTextBox
              (input)="onFilter($event)"
              type="text"
              class="form-control"
            />
            <kendo-grid-spacer></kendo-grid-spacer>
          </div>
        </form> -->
        
        <label for="search" class="font" style="margin: 10px 0px" 
          >Choose one or more line items to add from the list belows:</label
        >
        <div style="margin-bottom: 5px">
          <span>Line item</span>
        </div>
        <kendo-grid
          [data]="filterlinkItemData"
          scrollable="virtual"
          [selectable]="true"
          [kendoGridSelectBy]="selectedCallback"
          [(selectedKeys)]="mySelectionScope"
          [height]="205"
        >
          <kendo-grid-column
            field="Description"
            [headerStyle]="{
              'font-weight': 'bold',
              'text-align': 'left',
              color: '#d04a02'
            }"
          ></kendo-grid-column>
        </kendo-grid>
      </div>
     </mat-card-content
></mat-card>
<kendo-dialog-actions>
  <div class="clickToActions" style="padding: 12px">
    <ap-button
      [btnType]="'secondary'"
      [label]="'Cancel'"
      (onClick)="cancelClick()"
      class="cancelButton"
    ></ap-button>

    <ap-button
      [btnType]="'primary'"
      class="okButton"
      style="margin-right: 6px"
      [label]="'Link'"
      (onClick)="linlLineitem()"
    ></ap-button>
  </div>
</kendo-dialog-actions>
