<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Link analysis statement</div>
</kendo-dialog-titlebar>

<div>
  <h6>Summary statement name:&nbsp;<b>{{ statementDesc }}</b></h6>
  <ap-search
    [placeholder]="'Search here'"
    [searchType]="'secondary'"
    style="width: 100%;  padding: 0px !important"
    [disabled]="false"
    (onSearch)="onFilter($event)"
  ></ap-search>
</div>
<mat-card class="userCard" style="padding: 0px">
  <!-- <mat-card-title>
    <h6>
      Summary statement name:&nbsp;<b>{{ statementDesc }}</b>
    </h6>
  </mat-card-title> -->
  <mat-card-content>
    <!-- Analysis Statement: {{statementDesc}} -->
    <div>
      <!-- <form>
        <div class="form-group">
          <label for="search" class="font" style="margin-top: 1.5%"
            >Search for</label
          ><br />
          <input
            [style.width.px]="680"
            placeholder="Search here..."
            kendoTextBox
            (input)="onFilter($event)"
            type="text"
            class="form-control"
          />
          <kendo-grid-spacer></kendo-grid-spacer>
        </div>
      </form> -->
      <label for="search" class="font" style="margin: 10px 0px"
        ><b>Choose an analysis statement to add from the list below:</b></label
      >
      <span><b>Analysis statements:</b></span>
      <br />
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-grid
        [data]="filterlinkStatementData"
        scrollable="none"
        [selectable]="true"
        [kendoGridSelectBy]="selectedCallback"
        [(selectedKeys)]="mySelectionScope"
      >
        <kendo-grid-column
          field="Description"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <!-- <kendo-grid-column field="From" 
                  [headerStyle] ="{'font-weight' : 'bold', 'text-align': 'left', 'color': '#d04a02'}">
                </kendo-grid-column>      -->
      </kendo-grid>
    </div>  
  <div class="clickToActions" style="padding-top: 10px">
    <ap-button
      [btnType]="'secondary'"
      [label]="'Cancel'"
      class="cancelButton"
      (onClick)="cancelClick()"
    ></ap-button>
    <ap-button
      [btnType]="'primary'"
      [label]="'Link'"
      class="okButton"
      style="margin-right: 6px"
      (onClick)="linkAnalysisStatement()"
    ></ap-button>
  </div>

