import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { MyGlobalObject } from 'src/app/common/myglobalobject';

@Pipe({
  name: 'tonumber',
})
export class NumberPipe implements PipeTransform {
  private currencyPipe: CurrencyPipe | undefined
    constructor(public myGlobalObject: MyGlobalObject){}
  transform(value: any, args?: any): any {
    let returnValue=value;
    if(this.myGlobalObject.clientPreferences!=null && this.myGlobalObject.clientPreferences!=undefined)
    {
    if(this.myGlobalObject.clientPreferences.CreditPositive!='Debit' && value!=0)
    {
      if(value>0)
      {
        returnValue=-Math.abs(value)
      }
        else
        {
        returnValue=Math.abs(value)
      }
    }
    if(this.myGlobalObject.clientPreferences.DisplayFormat.indexOf(')')>0 && returnValue<0)
    {
      returnValue=Math.abs(returnValue);
      returnValue='('+returnValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })+')';
    }
  }
  else
  {
    returnValue= returnValue;
  }
    return ((returnValue==null || returnValue==undefined)?returnValue: returnValue.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }));;
  }
}