import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { OfflineReferralService } from 'src/app/common/services/offlineReferral/offlineReferral-service';
import { Router } from '@angular/router';
import { AnalysispackService } from '../common/services/analysispackservice/analysispack.service';
import { SelectionEvent } from '@progress/kendo-angular-grid';
import { CollectofflinereferralService } from '../common/services/collectOfflineReferral/collectofflinereferral.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { BusinessType } from '../_models/businessType';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LegalEntity } from '../_models/legalEntity';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
@Component({
  selector: 'app-manage-offline-referrals',
  templateUrl: './manage-offline-referrals.component.html',
  styleUrls: ['./manage-offline-referrals.component.scss'],
})
export class ManageOfflineReferralsComponent implements OnInit {
  selectedRowData: any;
  mode: string = '';
  isTaxAmtData: boolean = false;
  addJournalForm!: FormGroup;
  selectedBusinessType: BusinessType | undefined;
  // selectedLegalEntity!: null;
  selectedLegalEntity!: LegalEntity | any;
  businessTypeId: any;
  isLoading: boolean = false;
  deleteId: number = 0;
  gridData: any = [];
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private offlineService: OfflineReferralService,
    public toastr: ToastrManager,

    private globalService: GlobalService,
    private analysisPackService: AnalysispackService,
    public collectOfflineReferralService: CollectofflinereferralService
  ) {}
  businessTypes = [];
  legalEntities: LegalEntity[] = [];
  // gridData = [];
  isReimportdisabled: boolean = true;
  ngOnInit(): void {
    this.setupForm();
    this.getBusinessTypeList();
  }

  setupForm(): void {
    this.addJournalForm = this.fb.group({
      businessType: [''],
      legalEntity: [''],
    });
  }

  handleBusinessTypeChange(businessType: BusinessType): void {
    this.selectedBusinessType = businessType;
    this.legalEntities = [];
    this.selectedLegalEntity = null;
    if (businessType != undefined) {
      this.businessTypeId = businessType.BusinessTypeId;
      this.getLegalEntityList(businessType.BusinessTypeId);
    }
  }

  getBusinessTypeList(): void {
    // this.isLoading = true;
    this.globalService.getAllBusinessTypes().then(
      (response: any) => {
        //console.log(response,"res");
        this.businessTypes = response;
        //console.log(this.businessTypes,"res");
        if (this.businessTypes.length == 1) {
          this.selectedBusinessType = this.businessTypes[0];
          this.handleBusinessTypeChange(this.selectedBusinessType);
        }
        // this.isLoading = false;
      },
      (error) => {
        //console.log(error);
        // this.isLoading = false;
      }
    );
  }

  handleLegalEntityChange(legalEntitity: LegalEntity): void {
    this.selectedLegalEntity = legalEntitity;
    if (this.selectedLegalEntity != undefined) {
      this.getGridData();
    }
    // if(this.legalEntities = []){
    //   this.offlineRef = []
    //   console.log(this.offlineRef,"offlineRefnull")

    // }
  }

  getLegalEntityList(businessTypeId: number): void {
    // this.isLoading = true;
    this.analysisPackService
      .getAllLegalEntityByBusinessType(businessTypeId)
      .subscribe(
        (response: any) => {
          //console.log(response,"L");
          this.legalEntities = response;
          if (this.legalEntities.length == 1) {
            this.selectedLegalEntity = this.legalEntities[0];
            this.handleLegalEntityChange(this.selectedLegalEntity);
          }
          // this.isLoading = false;
        },
        (error) => {
          //console.log(error);
          // this.isLoading = false;
        }
      );
  }
  getGridData() {
    this.analysisPackService;
    this.offlineRef = [];

    this.offlineService
      .getmanageOfflineRef(this.selectedLegalEntity.ID)
      .subscribe((response) => {
        this.gridData = response;
        this.offlineRef = response;
      });
  }

  periods = [];
  selectedPeriodFrom: any;
  offlineRef: any = [];
  public sort: SortDescriptor[] = [
    {
      field: 'Ref',
      dir: 'asc',
    },
  ];
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.offlineRef = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }
  onItemClick(event: any) {}
  delete(data: any) {
    this.deleteId = data.Id;
    this.opened1 = true;
  }
  onClearSearch(event: any) {}
  public mySelection: number[] = [];
  onFilter(eve: any) {}

  close() {
    this.router.navigate(['/analysisreview']);
  }
  opened1 = false;
  closePop() {
    this.opened1 = false;
  }

  deleteData() {
    //let data = this.offlineRef[this.mySelection[0]];
    this.isLoading = true;
    this.offlineService
      .deleteOfflineReferal(this.deleteId)
      .subscribe((response) => {
        if (response == 'Offline referral deleted successfully.') {
          this.isLoading = false;
          this.toastr.successToastr(
            'Offline referral deleted successfully.',
            'Success'
          );
          this.opened1 = false;
          this.getGridData();
          // this.offlineRef = [];
        } else {
          this.isLoading = false;
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.opened1 = false;
        }
      });
    this.offlineService
      .getmanageOfflineRef(this.selectedLegalEntity.LegalEntityID)
      .subscribe((response) => {
        this.offlineRef = response;
      });
  }
  getStatus() {
    if (this.mySelection.length != 0) return false;
    else return true;
  }
  reimport() {
    this.collectOfflineReferralService
      .initiateReimportOfflineReferral(this.selectedRowData.Id)
      .subscribe(
        (response: any) => {
          if (response) {
            this.router.navigate(['/collectofflineReferrals']);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  onCheckboxChange(eve: any) {}
  onLegacyChange(eve: any) {
    this.offlineRef = [];
    // this.selectedLegalEntity = legalEntitity;?]
    this.offlineService
      .getmanageOfflineRef(this.selectedPeriodFrom.LegalEntityID)
      .subscribe((response) => {
        this.offlineRef = response;
      });
  }
  public onGridSelectionChange(event: SelectionEvent) {
    if (event.selectedRows != undefined) {
      this.selectedRowData = event.selectedRows[0].dataItem;
      if (event.selectedRows[0].dataItem.Editable == true) {
        this.isReimportdisabled = false;
      } else this.isReimportdisabled = true;
    }
  }
  downloadOfflineReferralExcelFile() {
    this.collectOfflineReferralService
      .downloadOfflineReferralViewExcel(this.selectedRowData.Id)
      .subscribe(
        (response: any) => {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          const fileName = this.selectedRowData.ExportFileName;
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  public IsSupplementaryChange(event: Event, dataItem: any): void {
    this.offlineService
      .UpdateOfflineReferralSupplementary(
        dataItem.Id,
        (event.target as HTMLInputElement).checked
      )
      .subscribe((response) => {});
  }
}
