import { Component, OnInit,Input, ViewChild,TemplateRef } from '@angular/core';
import { DialogRef, DialogContentBase, ActionsLayout ,DialogService} from '@progress/kendo-angular-dialog';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { AddbusinesstypeComponent } from '../addbusinesstype/addbusinesstype.component';
import { DeletebusinesstypeComponent } from '../deletebusinesstype/deletebusinesstype.component';
import { FabPositionMode } from '@progress/kendo-angular-buttons';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { orderBy, process, SortDescriptor, State } from "@progress/kendo-data-query";
import { Router } from '@angular/router';


@Component({
  selector: 'app-businesstypegridpopup',
  templateUrl: './businesstypegridpopup.component.html',
  styleUrls: ['./businesstypegridpopup.component.scss']
})
export class BusinesstypegridpopupComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective | any;
  public actionsLayout: ActionsLayout = 'normal';
  public positionMode: FabPositionMode = "fixed";
  businessTypes:any;
  public gridData :any= [];
  constructor(
    private cookieService: CookieService,    
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private router: Router,
    private globalService: GlobalService) {
    this.loadgridData();
}

  ngOnInit(): void {
    this.getAllBusinessTypes();
  }
  getAllBusinessTypes(){
    this.globalService.getAllBusinessTypes().then((res) => { 
      this.businessTypes = res;
      this.gridData=res;
    }).catch((err) => {
    });
  }
  openAddBusinessPopup(){
    this.router.navigate(['/addBusinessType']);
  }
  //edit button click
  public editButtonClick(rowDetails:any):void{
    this.router.navigate(['/addBusinessType'],{state: { btID: rowDetails.BusinessTypeId}});    
  }
  //copy button click
  public copyButtonClick(rowDetails:any):void{
    this.router.navigate(['/addBusinessType'],{state: { copyBtID: rowDetails.BusinessTypeId}});
  }
  deleteButtonClick(data:any):void{
    
    const dialogRef = this.dialogService.open({
      content: DeletebusinesstypeComponent,
      width: 500,
      height:'auto'
    });
    const delBTDetails = dialogRef.content.instance as DeletebusinesstypeComponent;
    dialogRef.result.subscribe((r: any) => {
      this.getAllBusinessTypes();   
    });    
    delBTDetails.delBTdata = data;
  }
  //search grid
public onFilter(event: {originEvent: any, queryStr: string}): void {
  const inputValue = event.queryStr;  
  this.businessTypes = process(this.gridData, {
      filter: {
          logic: "or",
          filters: [
              {
                  field: 'BusinessTypeId',
                  operator: 'contains',
                  value: inputValue
              },
              {
                  field: 'Description',
                  operator: 'contains',
                  value: inputValue
              },
              {
                  field: 'CountryName',
                  operator: 'contains',
                  value: inputValue
              },
              {
                  field: 'IndustryName',
                  operator: 'contains',
                  value: inputValue
              },
              {
                  field: 'HelpId',
                  operator: 'contains',
                  value: inputValue
              }
          ]
      }
  }).data;
  if(inputValue == ""){
    this.loadgridData();
  }
  //this.dataBinding.skip = 0;
}
  //sorting feature
  public sort: SortDescriptor[] = [
    {
      field: "BusinessTypeId",
      dir: "asc",
    },
  ];
  public sortChange(sort: SortDescriptor[]): void{
    this.sort = sort;
    this.businessTypes = {
      data : orderBy(this.gridData, this.sort),
      total: this.gridData.length
    }
  }
  public loadgridData(): void {
    this.getAllBusinessTypes();  
  }
}
