<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 18px; line-height: 1.3em">Edit Tax Account</div>
</kendo-dialog-titlebar>
<div>
  <!-- <p style="margin-left:160px;font-size: medium;"><span  class="k-icon k-i-warning"></span></p>  -->
  <p
    style="
      margin-left: 40px;
      margin-top: 20px;
      font-size: medium;
      font-weight: bold;
    "
  >
    The selected line item has no tax accounts
  </p>
</div>

<kendo-dialog-actions>
  <ap-button
    [btnType]="'secondary'"
    [label]="'OK'"
    (click)="cancelClick()"
    style="float: right"
  ></ap-button>
</kendo-dialog-actions>
