import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { GLaccountsgridpopupComponent } from '../glaccountsgridpopup/glaccountsgridpopup.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-editglaccounts',
  templateUrl: './editglaccounts.component.html',
  styleUrls: ['./editglaccounts.component.scss'],
})
export class EditglaccountsComponent implements OnInit {
  editGLAccountForm: FormGroup | any;
  public actionsLayout: ActionsLayout = 'normal';
  textareaDemo = '';
  selectedItem = {};
  isEdit: boolean | any;
  AccountTypeIdList: any;
  //dropdownlist
  AccountTypeIDList: any;
  selectedItem1 = {};
  item: any;
  BalanceSheetPandLIndicatorErrorMessage: any =
    'Balance or profit/loss is required';

  GLDetailsfromDb: any;
  editGLAccountId: any;

  AccountTypeIDListDB: any;
  BusinessTypeId: any;
  @Input() BusinessType: any;
  BusinessDesc: any;
  selectedClient: string | any;
  AccountTypeIdListDB: any;

  onSelectDropdown(event: any) {}
  onSelectItem(event: any) {}

  //radio
  // radioDisabled = false;
  //       showRadioLabel = true;
  //       groupRadioSelect: string="Balance";

  //       list2 = [
  //         {name: 'Balance', key: 'B'},
  //         {name: 'Profit/loss', key: 'P'},

  //       ];

  //       onSelected(event:any): void {
  //         console.log(event);
  //       }

  constructor(
    // public dialog: DialogRef,
    private cookieService: CookieService,
    private router: Router,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {
    //super(dialog);
    this.editGLAccountForm = this.fb.group({
      StatutoryCoAId: ['', [Validators.minLength(1), Validators.maxLength(20)]],
      BalanceSheetPandLIndicator: [''],
      AccountNumber: ['', [Validators.minLength(1), Validators.maxLength(20)]],
      ShortDescription: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(25),
          Validators.required,
        ],
      ],
      LongDescription: [
        '',
        [Validators.minLength(1), Validators.maxLength(255)],
      ],
      StatutoryAccountCodeId: 0,
      AccountTypeId: 0,
    });
  }

  ngOnInit(): void {
    this.editGLAccountId = history.state.editGLAccountId;
    this.BusinessType = history.state.BusinessType;
    this.getRefData();
    this.selectedClient = localStorage.getItem('SELECTEDCLIENT');

    if (this.editGLAccountId) {
      this.isEdit = true;
      this.getStatutoryAccountCodesById(this.editGLAccountId);
    }
  }

  getRefData() {
    this.globalService
      .getAllAccountType()
      .then((res) => {
        this.AccountTypeIdListDB = res;
        this.AccountTypeIdList = this.AccountTypeIdListDB.slice();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleFilterStatutorytreatment(value: any) {
    this.AccountTypeIdList = this.AccountTypeIdListDB.filter(
      (s: any) =>
        s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  getStatutoryAccountCodesById(StatutoryAccountCodeId: any) {
    this.globalService
      .getStatutoryAccountCodesById(StatutoryAccountCodeId)
      .then((res) => {
        if (res) {
          this.GLDetailsfromDb = JSON.parse(res);
          if (this.isEdit) {
            this.editGLAccountForm.controls['StatutoryCoAId'].setValue(
              this.GLDetailsfromDb.StatutoryCoAId
            );
            //this.editGLAccountForm.controls['StatutoryCoAId'].disable();
            this.editGLAccountForm.controls['AccountNumber'].setValue(
              this.GLDetailsfromDb.AccountNumber
            );
            //this.editGLAccountForm.controls['AccountNumber'].disable();
            this.editGLAccountForm.controls['ShortDescription'].setValue(
              this.GLDetailsfromDb.ShortDescription
            );
            this.editGLAccountForm.controls['LongDescription'].setValue(
              this.GLDetailsfromDb.LongDescription
            );
            this.editGLAccountForm.controls[
              'BalanceSheetPandLIndicator'
            ].setValue(this.GLDetailsfromDb.BalanceSheetPandLIndicator);
            this.editGLAccountForm.controls['StatutoryAccountCodeId'].setValue(
              this.GLDetailsfromDb.StatutoryAccountCodeId
            );
            this.editGLAccountForm.controls['AccountTypeId'].setValue(
              this.GLDetailsfromDb.AccountTypeId
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  cancelClick() {
    this.router.navigate(['/glAccounts'], {
      state: {
        selectItemOnClose: this.BusinessType,
      },
    });
    //this.dialog.close();
    // const dialogRef =  this.dialogService.open({
    //   content: GLaccountsgridpopupComponent,
    //   width: 1200,
    //   height:600
    // });
    // const editGLAccountsClose = dialogRef.content.instance as GLaccountsgridpopupComponent;
    // editGLAccountsClose.selectItemOnClose=this.BusinessType
  }

  saveEditGL() {
    var formObject = this.editGLAccountForm.getRawValue();

    let accTypeID;
    if (formObject.AccountTypeId) {
      accTypeID = formObject.AccountTypeId.Id;
    }
    let editStatutoryGLObject = {
      StatutoryCoAId: formObject.StatutoryCoAId,
      BalanceSheetPandLIndicator: formObject.BalanceSheetPandLIndicator,
      AccountNumber: formObject.AccountNumber,
      ShortDescription: formObject.ShortDescription,
      LongDescription: formObject.LongDescription,
      StatutoryAccountCodeId:
        this.GLDetailsfromDb.StatutoryAccountCodeId.toString(),
      AccountTypeId: accTypeID,
    };

    this.globalService
      .updateStatutoryAccountCode(editStatutoryGLObject)
      .then((res) => {
        if (res == 'GL Account updated successfully') {
          this.toastr.successToastr(
            'GL account updated successfully',
            'Success'
          );
          this.displayGrid();
        } else if (
          res ==
          'Combination of Account Number and Account Id already exists for other Record'
        ) {
          this.toastr.errorToastr(
            'Combination of account number and account Id already exists for other Record',
            'Failed'
          );
          return;
         // this.displayGrid();
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.displayGrid();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  displayGrid() {
    this.router.navigate(['/glAccounts'], {
      state: {
        selectItemOnClose: this.BusinessType,
      },
    });
    // const dialogRef = this.dialogService.open({
    //   content: GLaccountsgridpopupComponent,
    //   width: 1200,
    //   height:600
    // });
    // const editGLAccountsSave = dialogRef.content.instance as GLaccountsgridpopupComponent;
    // editGLAccountsSave.selectItemOnClose=this.BusinessType
    //this.dialog.close();
  }
}
