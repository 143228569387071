import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { OfflineReferralLegalEntity } from 'src/app/_models/offlineReferralLegalEntity';
import { OfflineReferralTaxAccount } from 'src/app/_models/offlineReferralTaxAccount';
import { OfflineReferralDataFilters } from 'src/app/_models/offlineReferralDataFilters';
import { offlineReferralOperatorFilter } from 'src/app/_models/offlineReferralOperatorFilter';
import { offlineReferralTransactionSelected } from 'src/app/_models/offlineReferralTransactionSelected';
// import { OfflineReferralTransactionResponse } from 'src/app/_models/offlineReferralTransactionResponse';
import { offlineReferralExportTransactionBody } from 'src/app/_models/offlineReferralExportTransactionBody';
import { GridSetting } from 'src/app/_models/gridSetting';

@Injectable({
  providedIn: 'root',
})
export class OfflineReferralService {
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private apiHost: string;
  private importAPIUrl: string = '';
  tokenValue: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.importAPIUrl = environment.importapiUrl;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getFiscalYearForOfflineReferral:
        '/OfflineReferral/GetFiscalYearForOfflineReferral',
      getManageOfflineReferrals: '/OfflineReferral/GetManageOfflineReferrals',
      getLegalEntitesByYear: '/OfflineReferral/GetLegalEntitesByYear',
      getPeriodsByFiscalYear: '/OfflineReferral/GetPeriodsByFiscalYear',
      deleteOfflineReferral: '/OfflineReferral/DeleteOfflineReferral',
      UpdateOfflineReferralSupplementary:
        '/OfflineReferral/UpdateOfflineReferralSupplementary',

      getDataFiltersForOfflineReferral:
        '/OfflineReferral/GetDataFiltersForOfflineReferral',
      getFilterData: '/OfflineReferral/GetFilterData',
      getTaxAccountsForOfflineReferral:
        '/OfflineReferral/GetTaxAccountsForOfflineReferral',
      getFilteredTransactions: '/OfflineReferral/GetFilteredTransactions',
      getExportDataItems: '/OfflineReferral/GetExportDataItems',
      validateExportFilteredTransaction:
        '/OfflineReferral/ValidateExportFilteredTransaction',
      exportFilteredTransaction: '/OfflineReferral/ExportFilteredTransaction',
      getAllocateTransactionViewData:
        '/ManageTransaction/GetAllocateTransactionViewData',
      getAllocateTransactionViewDataCount:
        '/ManageTransaction/GetAllocateTransactionViewDataCount',
      getFilteredTransactionsCount:
        '/OfflineReferral/GetFilteredTransactionsCount',
      exportOfflineReferralProcessingStart:
        '/ExportOfflineReferralProcessingStart  ',
      getExportOfflineReferralProcessingStatus:
        '/GetExportOfflineReferralProcessingStatus/',
      downloadOfflineReferralExcelFile: '/DownloadOfflineReferralExcelFile',
      getGridColumnList: '/CommonEnlighten/getGridColumnList',
      saveGridColumnList: '/CommonEnlighten/SaveGridColumnList',
    };
  }
  deleteOfflineReferal(id: any): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('offlineAllocationId', id);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteOfflineReferral;
    return this.http.delete<string>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }
  getFiscalYearForOfflineReferral(): Observable<number[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getFiscalYearForOfflineReferral;
    return this.http.get<number[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getLegalEntitesByYear(
    fiscalYear: number
  ): Observable<OfflineReferralLegalEntity[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getLegalEntitesByYear;
    return this.http.get<OfflineReferralLegalEntity[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getPeriodsByFiscalYear(fiscalYear: number): Observable<number[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getPeriodsByFiscalYear;
    return this.http.get<number[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getDataFiltersForOfflineReferral(): Observable<OfflineReferralDataFilters[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getDataFiltersForOfflineReferral;
    return this.http.get<OfflineReferralDataFilters[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getFilterData(
    filterId: number,
    fiscalYear: number,
    legalEntityId: number
  ): Observable<offlineReferralOperatorFilter[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('filterId', filterId)
      .set('fiscalYear', fiscalYear)
      .set('legalEntityId', legalEntityId);

    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getFilterData;
    return this.http.get<offlineReferralOperatorFilter[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getTaxAccountsForOfflineReferral(
    taxChartOfAccountId: number
  ): Observable<OfflineReferralTaxAccount[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('taxChartOfAccountId', taxChartOfAccountId);

    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getTaxAccountsForOfflineReferral;
    return this.http.get<OfflineReferralTaxAccount[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getmanageOfflineRef(legalEntityId: number): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    let year: any = localStorage.getItem('selectedFiscalYear');
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('legalEntityId', legalEntityId)
      .set('fiscalYear', JSON.parse(year).FiscalYearId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getManageOfflineReferrals;
    return this.http.post<string>(this.apiHost, null, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'json',
    });
  }
  PostFilteredTransactions(
    fiscalYear: string,
    legalEntityId: number,
    transactionsType: string,
    mergeFilterData: boolean,
    startIndex: number,
    maxRows: number,
    body: offlineReferralTransactionSelected[]
  ): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear)
      .set('legalEntityId', legalEntityId)
      .set('transactionsType', transactionsType)
      .set('mergeFilterData', mergeFilterData)
      .set('startIndex', startIndex)
      .set('maxRows', maxRows);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getFilteredTransactions;
    return this.http.post<string>(this.apiHost, body, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  PostFilteredTransactionsCount(
    fiscalYear: string,
    legalEntityId: number,
    transactionsType: string,
    mergeFilterData: boolean,
    body: offlineReferralTransactionSelected[]
  ): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear)
      .set('legalEntityId', legalEntityId)
      .set('transactionsType', transactionsType)
      .set('mergeFilterData', mergeFilterData);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getFilteredTransactionsCount;
    return this.http.post<string>(this.apiHost, body, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  PostExport(body: number[]): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getExportDataItems;
    return this.http.post<string>(this.apiHost, body, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  PostValidateExport(
    body: offlineReferralExportTransactionBody
  ): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.validateExportFilteredTransaction;
    return this.http.post<any>(this.apiHost, body, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  PostExportFilteredTransaction(
    mergeFilterData: boolean,
    body: offlineReferralExportTransactionBody
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('mergeFilterData', mergeFilterData);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.exportFilteredTransaction;
    return this.http.post(this.apiHost, body, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getGridSetting(gridName: string): Observable<GridSetting[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('gridName', gridName);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getGridColumnList;
    return this.http.get<GridSetting[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  UpdateOfflineReferralSupplementary(
    id: number,
    IsSupplementary: boolean
  ): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);

    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.UpdateOfflineReferralSupplementary +
      '?ClientdbName=' +
      this.selectedClient.DBName +
      '&offlineAllocationId=' +
      id +
      '&isSupplementary=' +
      IsSupplementary;
    return this.http.put<any>(
      this.apiHost,
      {},
      {
        headers,
      }
    );
  }

  PostExportOfflineReferralProcessingStart(
    body: offlineReferralExportTransactionBody
  ): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.exportOfflineReferralProcessingStart;
    return this.http.post(this.apiHost, body, {
      headers: headersObj,
      withCredentials: false,
    });
  }

  GetExportOfflineReferralProcessingStatus(
    instanceId: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.getExportOfflineReferralProcessingStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      observe: 'response',
      //responseType: 'blob' as 'json',
    });
  }

  downloadOfflineReferralExcelFile(
    generatedExportFileName: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('FileName', generatedExportFileName);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.downloadOfflineReferralExcelFile;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  PostGridColumnList(body: GridSetting[], gridName: string): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('gridName', gridName);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.saveGridColumnList;

    return this.http.post<any>(this.apiHost, body, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }
}
