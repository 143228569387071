import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-no-line-item',
  templateUrl: './no-line-item.component.html',
  styleUrls: ['./no-line-item.component.scss']
})
export class NoLineItemComponent implements OnInit {

  constructor(public dialog: DialogRef) { }

  ngOnInit(): void {
  }
  cancelClick() {
    this.dialog.close();
  }

}
