import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DialogRef, DialogContentBase, ActionsLayout ,DialogService, DialogAction} from '@progress/kendo-angular-dialog';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { FabPositionMode } from '@progress/kendo-angular-buttons';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { orderBy, process, SortDescriptor, State } from "@progress/kendo-data-query";
import { ToastrManager } from 'ng6-toastr-notifications';
import { AddlegalentityComponent } from '../addlegalentity/addlegalentity.component';
import { DeletelegalentityComponent } from '../deletelegalentity/deletelegalentity.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-legalentitygridpopup',
  templateUrl: './legalentitygridpopup.component.html',
  styleUrls: ['./legalentitygridpopup.component.scss']
})
export class LegalentitygridpopupComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective | any;
  public actionsLayout: ActionsLayout = 'normal';
  public positionMode: FabPositionMode = "fixed";
  public legalEntities:any;
  public gridData :any= [];

  constructor( 
    private cookieService: CookieService,    
    public myGlobalObject: MyGlobalObject,
    public toastr: ToastrManager,
    private dialogService: DialogService,
    private router: Router,
    private globalService: GlobalService) {
    
    this.loadgridData();
}

  ngOnInit(): void {
    this.getAllLegalEntities();
  }
  

  getAllLegalEntities(){
    this.globalService.getAllLegalEntities().then((res) => {  
      this.legalEntities = res;
      this.gridData=res;
    }).catch((err) => {
    });
  }

//Edit business type
public editButtonClick(rowDetails:any):void{
  this.router.navigate(['/addLegalEntities'],{state: { leId: rowDetails.ID}});
}
//Copy legal entity
public copyButtonClick(rowDetails:any):void{
  this.router.navigate(['/addLegalEntities'],{state: { copyLEId: rowDetails.ID}});
}
//add legal entity
  openAddLegalentityPopup(){
     this.router.navigate(['/addLegalEntities']);
  }
  //search grid
public onFilter(event: {originEvent: any, queryStr: string}): void {
  const inputValue = event.queryStr;  
  this.legalEntities = process(this.gridData, {
      filter: {
          logic: "or",
          filters: [
            {
              field: 'LegalEntityID',
              operator: 'contains',
              value: inputValue
          },
              {
                  field: 'BusinessType',
                  operator: 'contains',
                  value: inputValue
              },
              {
                  field: 'Description',
                  operator: 'contains',
                  value: inputValue
              },
              {
                  field: 'CountryName',
                  operator: 'contains',
                  value: inputValue
              },
              {
                  field: 'IndustryName',
                  operator: 'contains',
                  value: inputValue
              },
              {
                  field: 'CurrencyName',
                  operator: 'contains',
                  value: inputValue
              }
          ]
      }
  }).data;
  if(inputValue == ""){
    this.loadgridData();
  }
  //this.dataBinding.skip = 0;
}
//sorting feature
public sort: SortDescriptor[] = [
  {
    field: "Description",
    dir: "asc",
  },
];
public sortChange(sort: SortDescriptor[]): void{
  this.sort = sort;
  this.legalEntities = {
    data : orderBy(this.gridData, this.sort),
    total: this.gridData.length
  }
}
public loadgridData(): void {
  this.getAllLegalEntities();  
}
deleteButtonClick(data:any):void{
    
  const dialogRef = this.dialogService.open({
    content: DeletelegalentityComponent,
    width: 500,
    height:'auto'
  });
  const delBTDetails = dialogRef.content.instance as DeletelegalentityComponent;
  dialogRef.result.subscribe((r: any) => {
    this.getAllLegalEntities();   
  });    
  delBTDetails.delLEdata = data;
}

}
