import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { LegalEntity } from 'src/app/_models/legalEntity';
import { OnlineAllocation } from 'src/app/_models/onlineAllocation';
import { BusinessType } from 'src/app/_models/businessType';
import { JournalCategory } from 'src/app/_models/journalCategory';
import { FiscalYear } from 'src/app/_models/fiscalYear';
import { TaxAccount } from 'src/app/_models/taxAccount';
import { GlTaxAccount } from 'src/app/_models/glTaxAccount';
import { SaveAdjustmentModel } from 'src/app/_models/saveAdjustmentModel';
import { EditJournalResponse } from 'src/app/_models/editJournalResponse';
import { AdjustmentDetailResponse } from 'src/app/_models/adjustmentDetailResponse';
import { AddTransaction } from 'src/app/_models/addTransaction';
import {
  SaveMovementModel,
  TransactionList,
} from 'src/app/_models/saveMovementModel';

@Injectable({
  providedIn: 'root',
})
export class JournalService {
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getAllOnlineallocationList: '/Journal/GetAllOnlineallocationList',
      getAllLegalEntityByTaxChartOfAccount:
        '/Journal/GetAllLegalEntityByTaxChartOfAccount',
      getAllBusinessTypeList: '/Journal/GetAllBusinessTypeList',
      getAllJournalCategoryList: '/Journal/GetAllJournalCategoryList',
      getPeriodByFiscalYear: '/Journal/GetPeriodByFiscalYear',
      getLatestFiscalDetail: '/CommonEnlighten/GetLatestFiscalDetail',
      getAccountsList: '/Journal/GetAccountsList',
      getGLTaxAccountsList: '/Journal/GetGLTaxAccountsList',
      saveNewEditAdjustment: '/Journal/SaveNewEditAdjustment',
      getSupplementaryCalculationFromContainer:
        '/Journal/GetSupplementaryCalculationFromContainer',
      getEditJournal: '/Journal/GetEditJournal',
      getAdjustmentDetail: '/Journal/GetAdjustmentDetail',
      deleteOnlineallocation: '/Journal/DeleteOnlineallocation',
      addTransaction: '/Journal/AddTransaction',
      GetAllocateTransactionViewData:
        '/ManageTransaction/GetAllocateTransactionViewData',
      saveNewEditMovement: '/Journal/SaveNewMovement',
      getAllTransaction: '/Journal/GetAllTransaction',
    };
  }

  getAllOnlineallocationList(): Observable<OnlineAllocation[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllOnlineallocationList;
    return this.http.get<OnlineAllocation[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  GetAllocateTransactionViewData(
    taxAccountID: number,
    legalEntityID: number,
    fiscalYear: number
  ): Observable<TaxAccount[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('taxAccountID', taxAccountID)
      .set('legalEntity', legalEntityID)
      .set('fiscalYear', fiscalYear);

    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.GetAllocateTransactionViewData;
    return this.http.get<TaxAccount[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getAllBusinessTypeList(): Observable<BusinessType[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllBusinessTypeList;
    return this.http.get<BusinessType[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getAllLegalEntityByTaxChartOfAccount(
    taxChartOfAccountId: number
  ): Observable<LegalEntity[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('taxChartOfAccountId', taxChartOfAccountId);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getAllLegalEntityByTaxChartOfAccount;
    return this.http.get<LegalEntity[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getAllJournalCategoryList(): Observable<JournalCategory[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllJournalCategoryList;
    return this.http.get<JournalCategory[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getPeriodByFiscalYear(fiscalYear: number): Observable<string[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getPeriodByFiscalYear;
    return this.http.get<string[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getLatestFiscalDetail(): Observable<FiscalYear> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getLatestFiscalDetail;
    return this.http.get<FiscalYear>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  GetTaxAccounts(
    businessTypeId: number,
    legalEntityID: number
  ): Observable<TaxAccount[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('businessTypeId', businessTypeId)
      .set('legalEntityID', legalEntityID);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getAccountsList;
    return this.http.get<TaxAccount[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  GetGlTaxAccounts(
    businessTypeId: number,
    legalEntityID: number
  ): Observable<GlTaxAccount[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('businessTypeId', businessTypeId)
      .set('legalEntityID', legalEntityID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getGLTaxAccountsList;
    return this.http.get<GlTaxAccount[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  SaveNewEditAdjustment(
    saveAdjustment: SaveAdjustmentModel
  ): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.saveNewEditAdjustment;
    return this.http.post<string>(this.apiHost, saveAdjustment, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  SaveNewEditMovement(saveMovement: SaveMovementModel): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.saveNewEditMovement;
    return this.http.post<string>(this.apiHost, saveMovement, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  GetSupplementaryCalculationFromContainer(
    onlineAllocationId: number,
    fileName: string,
    fileUid: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('onlineAllocationId', onlineAllocationId)
      .set('fileName', fileName)
      .set('fileGUID', fileUid);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getSupplementaryCalculationFromContainer;
    return this.http.get<any>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'blob' as 'json',
    });
  }

  GetEditJournal(onlineAllocationId: number): Observable<EditJournalResponse> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('onlineAllocationId', onlineAllocationId);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getEditJournal;
    return this.http.get<EditJournalResponse>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  GetAdjustmentDetail(
    onlineAllocationId: number
  ): Observable<AdjustmentDetailResponse[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('onlineAllocationId', onlineAllocationId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAdjustmentDetail;
    return this.http.get<AdjustmentDetailResponse[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  DeleteOnlineallocation(deleteJournals: number[]): Observable<string> {
    const deleteBody = deleteJournals;
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteOnlineallocation;
    return this.http.delete<string>(this.apiHost, {
      headers: headersObj,
      body: deleteBody,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  getAddTransactionDetail(
    legalEntityId: number,
    fiscalYear: number,
    statutoryAccountCodeId: number,
    onlineAllocationId: number
  ): Observable<AddTransaction[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('legalEntityId', legalEntityId)
      .set('fiscalYear', fiscalYear)
      .set('statutoryAccountCodeId', statutoryAccountCodeId)
      .set('onlineAllocationId', onlineAllocationId);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.addTransaction;
    return this.http.get<AddTransaction[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  GetAllTransaction(onlineAllocationId: number): Observable<TransactionList[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('onlineAllocationId', onlineAllocationId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllTransaction;
    return this.http.get<TransactionList[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
}
