import { Component, Input, OnInit, VERSION } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AllocationRulesService } from 'src/app/common/services/allocationrule/allocation-rules.service';
import { QueryBuilderConfig } from 'angular2-query-builder';
import { forkJoin, Observable } from 'rxjs';
import { SharedserviceMoveService } from 'src/app/common/services/moveTransactions/sharedservice-move.service';

@Component({
  selector: 'app-edit-expression',
  templateUrl: './edit-expression.component.html',
  styleUrls: ['./edit-expression.component.scss'],
})
export class EditExpressionComponent implements OnInit {
  public textOperators: any[] = [
    'Is equal to',
    'Is not equal to',
    'Contains',
    'Does not contain',
    'Begins with',
    'Does not begin with',
    'Ends with',
    'Does not end with',
    'Is Empty',
    'Is Not Empty',
  ];
  public numberOperators: any[] = [
    'Is =',
    'Is not =',
    'Is <',
    'Is <=',
    'Is >',
    'Is >=',
    'Is Empty',
    'Is Not Empty',
  ];
  public keywordgroup: any[] = [];
  public isipDisplay: boolean = true;
  public expressionName: String = '';
  public isinputerror: boolean = false;
  name = 'Angular ' + VERSION.major;
  query: any = {
    condition: 'and',
    rules: [],
  };

  config: QueryBuilderConfig = {
    fields: {},
  };
  isDisplay: boolean = Object.keys(this.config['fields']).length > 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private allocationRulesService: AllocationRulesService,
    public toastr: ToastrManager,
    private sharedService: SharedserviceMoveService
  ) {}

  addExpressionForm!: FormGroup;
  selectedexpressionName: any | undefined;
  mode: string = '';
  expressionId: number = 0;
  isLoading: boolean = false;
  descriptionErrorMessage: string = 'Name is required';
  expressionDetail: any;
  heading: string = '';
  shorcut = false;
  routedata: any = [];
  ngOnInit(): void {
    this.setupForm();
    this.getColumns();
    this.requestDataFromAPIs().subscribe((res) => {
      this.formQueryBuilderJson(res);
      this.getKeywordGroup(res[1]);
    });

    if (
      this.route.snapshot.paramMap.get('id') != '' &&
      this.route.snapshot.paramMap.get('id') != null
    ) {
      if (this.route.snapshot.routeConfig?.path?.includes('copy') == true) {
        this.mode = 'Copy';
        this.heading = 'Copy expression';
      } else if (
        this.route.snapshot.routeConfig?.path?.includes('edit') == true
      ) {
        this.mode = 'Edit';
        this.heading = 'Edit expression';
      }

      this.expressionId = Number(this.route.snapshot.paramMap.get('id'));
      if (this.route.snapshot.paramMap.get('id')?.includes('S')) {
        this.expressionId = Number(
          this.route.snapshot.paramMap.get('id')?.split('S')[0]
        );
      }
      this.getExpressionRuleById(this.expressionId);
    } else {
      this.mode = 'Add';
      this.expressionId = 0;
    }
    this.expressionId = Number(
      this.route.snapshot.paramMap.get('id')?.split('S')[0]
    );

    if (this.route.snapshot.paramMap.get('id')?.includes('S')) {
      this.shorcut = true;
      let a = this.route.snapshot.paramMap.get('id')?.split('S')[1];
      this.expressionId = Number(
        this.route.snapshot.paramMap.get('id')?.split('S')[0]
      );
    } else {
      this.shorcut = false;
    }
    this.sharedService.getRoutedata().subscribe((data) => {
      this.routedata.push(data);
    });
  }

  getExpressionRuleById(expressionId: number): void {
    if (this.route.snapshot.paramMap.get('id')?.includes('S')) {
      this.expressionId = Number(
        this.route.snapshot.paramMap.get('id')?.split('S')[0]
      );
    }
    this.isLoading = true;
    this.allocationRulesService.getExpressionRuleById(expressionId).subscribe(
      (response: any) => {
        this.expressionDetail = response;
        if (
          this.expressionDetail != undefined &&
          this.expressionDetail != null
        ) {
          if (this.mode != 'Copy') {
            this.addExpressionForm.controls['txtName'].setValue(
              this.expressionDetail.RuleDescription
            );

            this.addExpressionForm.controls['txtExpression'].setValue(
              this.expressionDetail.RuleFormatted
            );
          } else {
            this.addExpressionForm.controls['txtName'].setValue(
              this.expressionDetail.RuleDescription + '--Copy'
            );

            this.addExpressionForm.controls['txtExpression'].setValue(
              this.expressionDetail.RuleFormatted
            );
          }
        }

        let value: any = JSON.parse(response.RuleExpression);
        let obj: any = { condition: '', rules: [] };
        if (value.filters) {
          obj.condition = value.logic.toLowerCase();
          let rules = value.filters;
          for (let i = 0; i < rules.length; i++) {
            if (rules[i].logic) {
              let subobj: any = {
                condition: rules[i].logic.toLowerCase(),
                rules: [],
              };
              let rulesArr = [];
              for (let j = 0; j < rules[i].filters.length; j++) {
                if (rules[i].filters[j].field && rules[i].filters[j].Data) {
                  let ruleobj: any = {
                    field: 'fk_ProjectId~Project',
                    operator: 'Is =~Int32',
                    value: 11,
                  };
                  ruleobj.field = rules[i].filters[j].field
                    ? rules[i].filters[j].fieldId +
                      '~' +
                      rules[i].filters[j].field
                    : '';
                  ruleobj.operator = rules[i].filters[j]._operator
                    ? rules[i].filters[j]._operator +
                      '~' +
                      rules[i].filters[j].dataType.split('.').pop()
                    : '';

                  // ruleobj.value =
                  //   rules[i].filters[j].KeywordGroup == 'KeywordGroup'
                  //     ? rules[i].filters[j].Data
                  //     : rules[i].filters[j].KeywordGroup;

                  if(rules[i].filters[j].isAdHoc === true)
                  {
                      ruleobj.value = rules[i].filters[j].Data;
                  }
                  else
                  {
                      ruleobj.value = rules[i].filters[j].Data + '~' + rules[i].filters[j].KeywordGroup;
                  }

                  // rules[i].filters[j].Data +
                  //   '~' +
                  //   rules[i].filters[j].KeywordGroup;
                  rulesArr.push(ruleobj);
                }
              }
              subobj.rules = rulesArr;
              obj.rules = [...obj.rules, subobj];
            } else if (rules[i].field && rules[i].Data) {
              let ruleobj: any = {
                field: 'fk_ProjectId~Project',
                operator: 'Is =~Int32',
                value: 11,
              };
              ruleobj.field = rules[i].field
                ? rules[i].fieldId + '~' + rules[i].field
                : '';
              ruleobj.operator = rules[i]._operator
                ? rules[i]._operator + '~' + rules[i].dataType.split('.').pop()
                : '';

              // ruleobj.value =
              //   rules[i].KeywordGroup == 'KeywordGroup'
              //     ? rules[i].Data
              //     : rules[i].KeywordGroup;

              if(rules[i].isAdHoc === true)
              {
                  ruleobj.value = rules[i].Data;
              }
              else
              {
                  ruleobj.value = rules[i].Data + '~' + rules[i].KeywordGroup;
              }

              //rules[i].Data + '~' + rules[i].KeywordGroup;

              obj.rules = [...obj.rules, ruleobj];
            } else if (
              rules[i]._operator.split('~')[0] === 'Is Empty' ||
              rules[i]._operator.split('~')[0] === 'Is Not Empty'
            ) {
              let ruleobj: any = {
                field: 'fk_ProjectId~Project',
                operator: 'Is =~Int32',
                // value: '',
              };
              ruleobj.field = rules[i].field
                ? rules[i].fieldId + '~' + rules[i].field
                : '';
              ruleobj.operator = rules[i]._operator
                ? rules[i]._operator + '~' + rules[i].dataType.split('.').pop()
                : '';
              //  ruleobj.value ='';
              obj.rules = [...obj.rules, ruleobj];
            }
          }
        }

        this.query = obj;
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
    if (this.mode == 'Copy') {
      this.expressionId = 0;
    }
  }

  setupForm(): void {
    this.addExpressionForm = this.fb.group({
      txtName: ['', Validators.required],
      txtExpression: [''],

      account: 1,
    });
    this.addExpressionForm.controls['txtExpression'].disable();
  }

  updateExpressionRule(expressionObj: any) {
    this.allocationRulesService.SaveEditExpressionRule(expressionObj).subscribe(
      (response) => {
        if (response == 'Rule expression is updated successfully') {
          this.toastr.successToastr(response, 'Success');
          if (
            this.shorcut &&
            Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]) ==
              Number(this.route.snapshot.paramMap.get('id')?.split('S')[0])
          ) {
            this.router.navigate([
              '/newAllocationRule/' +
                Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]),
            ]);
          } else if (this.shorcut && this.mode == 'Copy')
            this.router.navigate([
              '/editAllocationRule/' +
                this.routedata[0][0] +
                '/' +
                Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]) +
                'S' +
                Number(this.route.snapshot.paramMap.get('id')?.split('S')[0]),
            ]);
          else if (this.shorcut && this.mode == 'Edit')
            this.router.navigate([
              '/editAllocationRule/' +
                this.routedata[0][0] +
                '/' +
                Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]) +
                'S' +
                Number(this.route.snapshot.paramMap.get('id')?.split('S')[0]),
            ]);
          else this.router.navigate(['/defineExpressions']);
        } else if (
          response ==
          'Unable to save this data. it will not be unique in the database'
        ) {
          this.addExpressionForm.controls['txtName'].markAsTouched();
          this.descriptionErrorMessage =
            'Please modify name as it is not unique';
          this.addExpressionForm.controls['txtName'].setErrors({
            incorrect: true,
          });

          return;
        } else {
          this.toastr.warningToastr(response, 'Failed');
        }
      },
      (error) => {
        this.isLoading = false;
        this.toastr.errorToastr('Failed to Save Expression', 'Error');

        console.log(error);
      }
    );
  }
  insertExpressionRule(expressionObj: any) {
    this.allocationRulesService.SaveEditExpressionRule(expressionObj).subscribe(
      (response) => {
        if (response == 'Rule expression is added successfully') {
          this.toastr.successToastr(response, 'Success');
          if (
            this.shorcut &&
            Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]) ==
              Number(this.route.snapshot.paramMap.get('id')?.split('S')[0])
          ) {
            this.router.navigate([
              '/newAllocationRule/' +
                Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]),
            ]);
          } else if (this.shorcut && this.mode == 'Copy') {
            if (this.route.snapshot.paramMap.get('id')?.includes('NaN')) {
              this.router.navigate(['/newAllocationRule/null']);
            } else
              this.router.navigate([
                '/editAllocationRule/' +
                  this.routedata[0][0] +
                  '/' +
                  Number(
                    this.route.snapshot.paramMap.get('id')?.split('S')[1]
                  ) +
                  'S' +
                  Number(this.route.snapshot.paramMap.get('id')?.split('S')[0]),
              ]);
          } else if (this.shorcut && this.mode == 'Edit')
            this.router.navigate([
              '/editAllocationRule/' +
                this.routedata[0][0] +
                '/' +
                Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]) +
                'S' +
                Number(this.route.snapshot.paramMap.get('id')?.split('S')[0]),
            ]);
          else this.router.navigate(['/defineExpressions']);
        } else if (
          response ==
          'Unable to save this data. it will not be unique in the database'
        ) {
          this.addExpressionForm.controls['txtName'].markAsTouched();
          this.descriptionErrorMessage =
            'Please modify name as it is not unique';
          this.addExpressionForm.controls['txtName'].setErrors({
            incorrect: true,
          });

          return;
        } else {
          this.toastr.warningToastr(response, 'Failed');
        }
      },
      (error) => {
        this.isLoading = false;
        this.toastr.errorToastr('Failed to Save Expression', 'Error');

        console.log(error);
      }
    );
  }
  getColumns() {
    this.allocationRulesService
      .getExpressionDataColumns()
      .subscribe((res: any) => {});
  }

  onQueryBuilderChange(event: any) {}

  requestDataFromAPIs(): Observable<any[]> {
    return forkJoin([
      this.allocationRulesService.getExpressionDataColumns(),
      this.allocationRulesService.getAllKeywords(),
    ]);
  }

  formQueryBuilderJson(res: any[]) {
    let expressionDataColumn: any[] = res[0];
    let configs: any = {};
    for (let i = 0; i < expressionDataColumn.length; i++) {
      let obj = {
        name: expressionDataColumn[i]['Name'],
        type: '',
        operators: [''],
      };
      if (expressionDataColumn[i]['Type'] == 'Int32') {
        obj.type = 'number';
        obj.operators = this.numberOperators.map((op) => op + '~Int32');
      }

      if (expressionDataColumn[i]['Type'] == 'DateTime') {
        obj.type = 'date';
        obj.operators = this.numberOperators.map((op) => op + '~DateTime');
      }

      if (expressionDataColumn[i]['Type'] == 'String') {
        obj.type = 'string';
        obj.operators = this.textOperators.map((op) => op + '~String');
      }
      if (expressionDataColumn[i]['Type'] == 'Decimal') {
        obj.type = 'string';
        obj.operators = this.numberOperators.map((op) => op + '~Decimal');
      }
      configs[
        expressionDataColumn[i]['PropertyName'] +
          '~' +
          expressionDataColumn[i]['Name']
      ] = obj;
    }
    this.config.fields = configs;
    this.isDisplay = true;
    return configs;
  }

  getKeywordGroup(res: any[]) {
    let keywords = [];
    for (let i = 0; i < res.length; i++) {
      keywords.push(
        ...res[i].items.map((obj: any) => {
          return {
            name: obj.description + ' (from ' + res[i].description + ')',
            id: obj.id,
          };
        })
      );
    }

    this.keywordgroup = keywords;
  }

  toggleChange(e1: HTMLDivElement, e2: HTMLDivElement, rule: any) {
    if (e1.style.display == '' || e1.style.display == 'block') {
      e1.setAttribute('style', 'display:none;');
      e2.setAttribute('style', 'display:block;');
    } else {
      e1.setAttribute('style', 'display:block;');
      e2.setAttribute('style', 'display:none;');
    }
    rule.value = '';
  }

  jsonstringify(json: any): String {
    return JSON.stringify(json);
  }

  saveExpressionforNew() {
    let value: any = this.query;
    let obj: any = { logic: '', filters: [] };

    if (value.rules) {
      obj.logic = value.condition.toUpperCase();
      let filters = value.rules;
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].condition) {
          let subobj: any = {
            logic: filters[i].condition.toUpperCase(),
            filters: [],
          };
          let filterArr = [];
          for (let j = 0; j < filters[i].rules.length; j++) {
            if (filters[i].rules[j].field && filters[i].rules[j].value) {
              let ruleobj: any = {
                field: '',
                fieldId: '',
                _operator: '',
                dataType: '',
                isAdHoc: false,
                Data: '',
                KeywordGroup: '',
              };
              ruleobj.field = filters[i].rules[j].field
                ? filters[i].rules[j].field.split('~')[1]
                : '';
              ruleobj.fieldId = filters[i].rules[j].field
                ? filters[i].rules[j].field.split('~')[0]
                : '';
              ruleobj._operator = filters[i].rules[j].operator
                ? filters[i].rules[j].operator.split('~')[0]
                : '';
              ruleobj.dataType = filters[i].rules[j].operator
                ? 'System.' + filters[i].rules[j].operator.split('~')[1]
                : '';
              ruleobj.isAdHoc =
                filters[i].rules[j].value &&
                filters[i].rules[j].value.toString().split('~').length > 1
                  ? false
                  : true;
              ruleobj.Data = filters[i].rules[j].value
                ? filters[i].rules[j].value.toString().split('~')[0].trim()
                : '';
              // console.log(filters[i].rules[j].value.toString().split('~')[0])
              //console.log((filters[i].rules[j].value.toString().split('~')[0]).trim())
              ruleobj.KeywordGroup =
                filters[i].rules[j].value &&
                filters[i].rules[j].value.toString().split('~').length > 1
                  ? filters[i].rules[j].value.toString().split('~')[1]
                  : 'KeywordGroup';
              filterArr.push(ruleobj);
            }
          }
          subobj.filters = filterArr;
          obj.filters = [...obj.filters, subobj];
        } else if (filters[i].field && filters[i].value) {
          let ruleobj: any = {
            field: '',
            fieldId: '',
            _operator: '',
            dataType: '',
            isAdHoc: false,
            Data: '',
            KeywordGroup: '',
          };
          ruleobj.field = filters[i].field
            ? filters[i].field.split('~')[1]
            : '';
          ruleobj.fieldId = filters[i].field
            ? filters[i].field.split('~')[0]
            : '';
          ruleobj._operator = filters[i].operator
            ? filters[i].operator.split('~')[0]
            : '';
          ruleobj.dataType = filters[i].operator
            ? 'System.' + filters[i].operator.split('~')[1]
            : '';
          ruleobj.isAdHoc =
            filters[i].value &&
            filters[i].value.toString().split('~').length > 1
              ? false
              : true;
          ruleobj.Data = filters[i].value
            ? filters[i].value.toString().split('~')[0].trim()
            : '';
          // console.log(filters[i].value.toString().split('~')[0])
          //console.log((filters[i].value.toString().split('~')[0]).trim())
          ruleobj.KeywordGroup =
            filters[i].value &&
            filters[i].value.toString().split('~').length > 1
              ? filters[i].value.toString().split('~')[1]
              : 'KeywordGroup';
          obj.filters = [...obj.filters, ruleobj];
        } else if (
          filters[i].operator.split('~')[0] === 'Is Empty' ||
          filters[i].operator.split('~')[0] === 'Is Not Empty'
        ) {
          let ruleobj: any = {
            field: '',
            fieldId: '',
            _operator: '',
            dataType: '',
            // isAdHoc: false,
            // Data: '',
            // KeywordGroup: '',
          };
          ruleobj.field = filters[i].field
            ? filters[i].field.split('~')[1]
            : '';
          ruleobj.fieldId = filters[i].field
            ? filters[i].field.split('~')[0]
            : '';
          ruleobj._operator = filters[i].operator
            ? filters[i].operator.split('~')[0]
            : '';
          ruleobj.dataType = filters[i].operator
            ? 'System.' + filters[i].operator.split('~')[1]
            : '';
          obj.filters = [...obj.filters, ruleobj];
        }
      }
    }

    let isexpressionvalid = obj.filters.length > 0 ? true : false;
    if (isexpressionvalid) {
      obj.filters.forEach((element: any) => {
        if (element.field) {
        } else if (element.filters.length > 0) {
        } else {
          isexpressionvalid = false;
        }
      });
    }
    var formObject = this.addExpressionForm.getRawValue();
    if (formObject.txtName == '') {
      this.isinputerror = true;
    } else {
      this.isinputerror = false;
    }

    if (isexpressionvalid && !this.isinputerror) {
      if (this.mode == 'Copy') {
        this.expressionId = 0;
      }
      let expressionDetail = {
        RuleId: this.expressionId,
        RuleDescription: formObject.txtName,
        RuleDefinition: JSON.stringify(obj),
        RuleType: 0,
      };

      if (this.expressionId > 0) {
        this.updateExpressionRule(expressionDetail);
      } else if (this.expressionId == 0) {
        this.insertExpressionRule(expressionDetail);
      }
    } else {
      if (this.isinputerror) {
        this.toastr.errorToastr(
          'Please enter expression Name in the Input box',
          'Input - Missing Expression Name'
        );
      } else {
        if (this.mode == 'Copy') {
          this.expressionId = 0;
        }
        let expressionDetail = {
          RuleId: this.expressionId,
          RuleDescription: formObject.txtName,
          RuleDefinition: this.expressionDetail.RuleExpression,
          RuleType: 0,
        };
        if (this.expressionId > 0) {
          this.updateExpressionRule(expressionDetail);
        } else if (this.expressionId == 0) {
          this.insertExpressionRule(expressionDetail);
        }
        // this.toastr.errorToastr('Please validate the expression data and structure','Expression - Data Invalid / Misisng ')
      }
    }
  }
  onChangeOperator(rule: any) {
    if (
      rule.operator.split('~')[0] === 'Is Empty' ||
      rule.operator.split('~')[0] === 'Is Not Empty'
    ) {
      rule.value = '';
    }
  }
  closeButtonClick() {
    if (
      this.shorcut &&
      Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]) ==
        Number(this.route.snapshot.paramMap.get('id')?.split('S')[0])
    ) {
      this.router.navigate([
        '/newAllocationRule/' +
          Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]),
      ]);
    } else if (this.shorcut && this.mode == 'Copy') {
      if (this.route.snapshot.paramMap.get('id')?.includes('NaN')) {
        this.router.navigate(['/newAllocationRule/null']);
      } else {
        this.router.navigate([
          '/editAllocationRule/' +
            this.routedata[0][0] +
            '/' +
            Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]) +
            'S' +
            Number(this.route.snapshot.paramMap.get('id')?.split('S')[0]),
        ]);
      }
    } else if (this.shorcut && this.mode == 'Edit')
      this.router.navigate([
        '/editAllocationRule/' +
          this.routedata[0][0] +
          '/' +
          Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]) +
          'S' +
          Number(this.route.snapshot.paramMap.get('id')?.split('S')[0]),
      ]);
    else this.router.navigate(['/defineExpressions']);
  }
}
