import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AllocationRulesService } from 'src/app/common/services/allocationrule/allocation-rules.service';
import { BusinessType } from 'src/app/_models/businessType';
import { ToastrManager } from 'ng6-toastr-notifications';
//import { NewallocationRuleService } from 'src/app/common/services/newallocationRule/newallocation-rule.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { forkJoin, Observable } from 'rxjs';
import {
  Component,
  VERSION,
  OnInit,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { QueryBuilderConfig } from 'angular2-query-builder';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { SharedserviceMoveService } from 'src/app/common/services/moveTransactions/sharedservice-move.service';
import { CustomColumnsDetailsComponent } from '../manage-allocation-rules/custom-columns-details/custom-columns-details.component';
import {
  DialogCloseResult,
  DialogRef,
  DialogService,
  WindowService,
} from '@progress/kendo-angular-dialog';
@Component({
  selector: 'app-new-expression',
  templateUrl: './new-expression.component.html',
  styleUrls: ['./new-expression.component.scss'],
})
export class NewExpressionComponent implements OnInit, AfterViewInit {
  public textOperators: any[] = [
    'Is equal to',
    'Is not equal to',
    'Contains',
    'Does not contain',
    'Begins with',
    'Does not begin with',
    'Ends with',
    'Does not end with',
    'Is Empty',
    'Is Not Empty',
  ];
  public numberOperators: any[] = [
    'Is =',
    'Is not =',
    'Is <',
    'Is <=',
    'Is >',
    'Is >=',
    'Is Empty',
    'Is Not Empty',
  ];
  public keywordgroup: any[] = [];
  public isipDisplay: boolean = true;
  public expressionName: String = '';
  public isinputerror: boolean = false;
  public isempty: boolean = true;

  name = 'Angular ' + VERSION.major;
  query: any = {
    condition: 'and',
    rules: [],
  };

  config: QueryBuilderConfig = {
    fields: {},
  };

  isDisplay: boolean = Object.keys(this.config['fields']).length > 0;
  keywordsList: any[] = [];
  isLoading: boolean = false;
  selectedkeyword: any;
  descriptionErrorMessage: string = 'Name is required';
  expressionId: number = 0;
  mode: string = '';
  shorcut = false;
  routedata: any = [];
  createPrePopulate: any;
  dropdownDatacolumn: any;
  ismatchColumnsFlag: boolean = false;
  createFromFilter: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private newallocationService: AllocationRulesService,
    public toastr: ToastrManager,
    private sharedService: SharedserviceMoveService,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.requestDataFromAPIs().subscribe((res) => {
      res[0] = res[0].filter(
        (data: any) => data.PropertyName != 'fk_CurrencyId'
      );
      this.formQueryBuilderJson(res);
      this.getKeywordGroup(res[1]);
    });
    this.mode = 'Add';
    this.expressionId = 0;
    this.expressionId = Number(
      this.route.snapshot.paramMap.get('id')?.split('S')[0]
    );

    if (this.route.snapshot.paramMap.get('id')?.includes('S')) {
      this.shorcut = true;
      let a = this.route.snapshot.paramMap.get('id')?.split('S')[1];
      this.expressionId = Number(
        this.route.snapshot.paramMap.get('id')?.split('S')[0]
      );
    } else {
      this.shorcut = false;
    }
    //console.log(this.sharedService.getRoutedata());
    this.sharedService.getRoutedata().subscribe((data) => {
      this.routedata.push(data);
    });
    // console.log(this.routedata[0][0])
  }

  ngAfterViewInit(): void {}

  onQueryBuilderChange(event: any) {}

  requestDataFromAPIs(): Observable<any[]> {
    return forkJoin([
      this.newallocationService.getExpressionDataColumns(),
      this.newallocationService.getAllKeywords(),
    ]);
  }

  formQueryBuilderJson(res: any[]) {
    this.dropdownDatacolumn = res[0];
    let expressionDataColumn: any[] = res[0];
    let configs: any = {};
    /*crete expression from filter start*/
    if (this.myGlobalObject.createExpressionShortcut.length > 0) {
      this.createPrePopulate = this.myGlobalObject.createExpressionShortcut;
      this.createFromFilter = true;
      this.myGlobalObject.createExpressionShortcut = [];
      let querypopulateData: any = [];
      // console.log(this.createPrePopulate);
      // console.log(this.dropdownDatacolumn);
      for (let i = 0; i < this.createPrePopulate.length; i++) {
        if (
          this.createPrePopulate[i].DbColumnName.toLowerCase().includes('date')
        )
          this.createPrePopulate[i].Data = this.createPrePopulate[i].Data.split(
            '/'
          )
            .reverse()
            .join('-');
      }
      for (let i = 0; i < this.createPrePopulate.length; i++) {
        //console.log(this.createPrePopulate[i]);
        let checkVal = this.dropdownDatacolumn.filter((data: any) => {
          if (this.createPrePopulate[i]['DbColumnName'] == 'DocumentNo')
            return (
              data.PropertyName.split(' ').join('').toUpperCase() ==
              this.createPrePopulate[i]['DbColumnName'].toUpperCase()
            );
          else if (this.createPrePopulate[i]['DbColumnName'] == 'AssetCode')
            return data.Name == 'Asset';
          else
            return (
              data.Name.split(' ').join('').toUpperCase() ==
              this.createPrePopulate[i]['DbColumnName'].toUpperCase()
            );
        });
        if (checkVal.length > 0) {
          let temp_obj: any = {};
          temp_obj.field = checkVal[0].PropertyName + '~' + checkVal[0].Name;
          temp_obj.operator =
            this.createPrePopulate[i].operatorType + '~' + checkVal[0].Type;
          temp_obj.value = this.createPrePopulate[i].Data;
          querypopulateData.push(temp_obj);
        } else {
          this.ismatchColumnsFlag = true;
        }
      }
      if (this.ismatchColumnsFlag) {
        this.toastr.infoToastr(
          'Columns that are matched only will populate',
          'Info'
        );
      }
      console.log(querypopulateData);
      this.query = {
        condition: 'and',
        rules: querypopulateData,
      };
    }
    /*crete expression from filter end*/
    for (let i = 0; i < expressionDataColumn.length; i++) {
      let obj = {
        name: expressionDataColumn[i]['Name'],
        type: '',
        operators: [''],
      };
      if (expressionDataColumn[i]['Type'] == 'Int32') {
        obj.type = 'number';
        obj.operators = this.numberOperators.map((op) => op + '~Int32');
      }

      if (expressionDataColumn[i]['Type'] == 'DateTime') {
        obj.type = 'date';
        obj.operators = this.numberOperators.map((op) => op + '~DateTime');
      }

      if (expressionDataColumn[i]['Type'] == 'String') {
        obj.type = 'string';
        obj.operators = this.textOperators.map((op) => op + '~String');
      }
      if (expressionDataColumn[i]['Type'] == 'Decimal') {
        obj.type = 'string';
        obj.operators = this.numberOperators.map((op) => op + '~Decimal');
      }
      configs[
        expressionDataColumn[i]['PropertyName'] +
          '~' +
          expressionDataColumn[i]['Name']
      ] = obj;
    }
    this.config.fields = configs;
    this.isDisplay = true;

    return configs;
  }

  getKeywordGroup(res: any[]) {
    let keywords = [];
    for (let i = 0; i < res.length; i++) {
      keywords.push(
        ...res[i].items.map((obj: any) => {
          return {
            name: obj.description + ' (from ' + res[i].description + ')',
            id: obj.id,
          };
        })
      );
    }

    this.keywordgroup = keywords;
    this.selectedkeyword = this.keywordgroup[0];
  }

  toggleChange(e1: HTMLDivElement, e2: HTMLDivElement, rule: any) {
    if (e1.style.display == '' || e1.style.display == 'block') {
      e1.setAttribute('style', 'display:none;');
      e2.setAttribute('style', 'display:block;');
    } else {
      e1.setAttribute('style', 'display:block;');
      e2.setAttribute('style', 'display:none;');
    }
    rule.value = '';
  }

  jsonstringify(json: any): String {
    return JSON.stringify(json);
  }

  saveExpression() {
    let value: any = this.query;
    let obj: any = { logic: '', filters: [] };

    if (value.rules) {
      obj.logic = value.condition.toUpperCase();
      let filters = value.rules;
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].condition) {
          let subobj: any = {
            logic: filters[i].condition.toUpperCase(),
            filters: [],
          };
          let filterArr = [];
          for (let j = 0; j < filters[i].rules.length; j++) {
            if (filters[i].rules[j].field && filters[i].rules[j].value) {
              let ruleobj: any = {
                field: '',
                fieldId: '',
                _operator: '',
                dataType: '',
                isAdHoc: false,
                Data: '',
                KeywordGroup: '',
              };
              ruleobj.field = filters[i].rules[j].field
                ? filters[i].rules[j].field.split('~')[1]
                : '';
              ruleobj.fieldId = filters[i].rules[j].field
                ? filters[i].rules[j].field.split('~')[0]
                : '';
              ruleobj._operator = filters[i].rules[j].operator
                ? filters[i].rules[j].operator.split('~')[0]
                : '';
              ruleobj.dataType = filters[i].rules[j].operator
                ? 'System.' + filters[i].rules[j].operator.split('~')[1]
                : '';
              ruleobj.isAdHoc =
                filters[i].rules[j].value &&
                filters[i].rules[j].value.toString().split('~').length > 1
                  ? false
                  : true;
              ruleobj.Data = filters[i].rules[j].value
                ? filters[i].rules[j].value.toString().split('~')[0].trim()
                : '';
              ruleobj.KeywordGroup =
                filters[i].rules[j].value &&
                filters[i].rules[j].value.toString().split('~').length > 1
                  ? filters[i].rules[j].value.toString().split('~')[1]
                  : 'KeywordGroup';
              filterArr.push(ruleobj);
            }
          }
          subobj.filters = filterArr;
          obj.filters = [...obj.filters, subobj];
        } else if (filters[i].field && filters[i].value) {
          let ruleobj: any = {
            field: '',
            fieldId: '',
            _operator: '',
            dataType: '',
            isAdHoc: false,
            Data: '',
            KeywordGroup: '',
          };
          ruleobj.field = filters[i].field
            ? filters[i].field.split('~')[1]
            : '';
          ruleobj.fieldId = filters[i].field
            ? filters[i].field.split('~')[0]
            : '';
          ruleobj._operator = filters[i].operator
            ? filters[i].operator.split('~')[0]
            : '';
          ruleobj.dataType = filters[i].operator
            ? 'System.' + filters[i].operator.split('~')[1]
            : '';
          ruleobj.isAdHoc =
            filters[i].value &&
            filters[i].value.toString().split('~').length > 1
              ? false
              : true;
          ruleobj.Data = filters[i].value
            ? filters[i].value.toString().split('~')[0].trim()
            : '';
          ruleobj.KeywordGroup =
            filters[i].value &&
            filters[i].value.toString().split('~').length > 1
              ? filters[i].value.toString().split('~')[1]
              : 'KeywordGroup';
          obj.filters = [...obj.filters, ruleobj];
        } else if (
          filters[i].operator.split('~')[0] === 'Is Empty' ||
          filters[i].operator.split('~')[0] === 'Is Not Empty'
        ) {
          let ruleobj: any = {
            field: '',
            fieldId: '',
            _operator: '',
            dataType: '',
            // isAdHoc: false,
            // Data: '',
            // KeywordGroup: '',
          };
          ruleobj.field = filters[i].field
            ? filters[i].field.split('~')[1]
            : '';
          ruleobj.fieldId = filters[i].field
            ? filters[i].field.split('~')[0]
            : '';
          ruleobj._operator = filters[i].operator
            ? filters[i].operator.split('~')[0]
            : '';
          ruleobj.dataType = filters[i].operator
            ? 'System.' + filters[i].operator.split('~')[1]
            : '';
          obj.filters = [...obj.filters, ruleobj];
        }
      }
    }

    let isexpressionvalid = obj.filters.length > 0 ? true : false;
    if (isexpressionvalid) {
      obj.filters.forEach((element: any) => {
        if (element.field) {
        } else if (element.filters.length > 0) {
        } else {
          isexpressionvalid = false;
        }
      });
    }

    if (this.expressionName == '') {
      this.isinputerror = true;
    } else {
      this.isinputerror = false;
    }
    if (isexpressionvalid && !this.isinputerror) {
      let ipobj = {
        RuleId: 0,
        RuleDescription: this.expressionName,
        RuleDefinition: JSON.stringify(obj),
        RuleType: 0,
      };
      this.newallocationService.SaveEditExpressionRule(ipobj).subscribe(
        (res) => {
          if (res == 'Rule expression is added successfully') {
            //this.toastr.successToastr(res, 'Success');
            if (this.createFromFilter) {
              this.createFromFilter = false;
              this.toastr.successToastr(res, 'Success');
              this.router.navigate(['/analysisreview']);
            } else if (
              this.shorcut &&
              Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]) ==
                Number(this.route.snapshot.paramMap.get('id')?.split('S')[0])
            ) {
              this.toastr.successToastr(res, 'Success');
              this.router.navigate([
                '/newAllocationRule/' +
                  Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]),
              ]);
            } else if (
              this.route.snapshot.paramMap.get('id')?.includes('NaN') ||
              this.route.snapshot.paramMap
                .get('id')
                ?.toLowerCase()
                ?.includes('undefined')
            ) {
              this.myGlobalObject.newExpressionfromNewRule = true;
              this.router.navigate(['/newAllocationRule/null']);
              this.toastr.successToastr(
                'Rule expression is added successfully',
                'Success'
              );
            } else if (this.shorcut && this.mode == 'Add') {
              this.router.navigate([
                '/editAllocationRule/' +
                  this.routedata[0][0] +
                  '/' +
                  Number(
                    this.route.snapshot.paramMap.get('id')?.split('S')[1]
                  ) +
                  'S' +
                  Number(this.route.snapshot.paramMap.get('id')?.split('S')[0]),
              ]);
              this.toastr.successToastr(
                'Rule expression is added successfully and please select that expression from the dropdown',
                'Success'
              );
            } else {
              this.toastr.successToastr(res, 'Success');
              this.router.navigate(['./defineExpressions']);
            }
          } else if (res == '') {
          } else {
            this.toastr.errorToastr(res, 'Failed');
          }
        },
        (error) => {
          console.log(error);
          this.toastr.errorToastr('Failed to add expression data', 'Failed');
        }
      );
    } else {
      if (this.isinputerror) {
        this.toastr.errorToastr(
          'Please enter expression name in the input box',
          'Input - Missing expression name'
        );
      } else {
        this.toastr.errorToastr(
          'Please validate the expression data and structure',
          'Expression - Data invalid / Missing '
        );
      }
    }
  }
  onInputchange(e: any) {
    if (this.expressionName == '') {
      this.isinputerror = true;
    }
    this.isinputerror = false;
  }

  AddKeyWordGroup() {
    this.router.navigate(['/addKeyword']);
  }
  closeButtonClick() {
    if (this.route.snapshot.paramMap.get('id')?.includes('NaN')) {
      this.router.navigate(['/newAllocationRule/null']);
    } else if (
      this.shorcut &&
      Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]) ==
        Number(this.route.snapshot.paramMap.get('id')?.split('S')[0])
    ) {
      this.router.navigate([
        '/newAllocationRule/' +
          Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]),
      ]);
    } else if (this.shorcut && this.mode == 'Add')
      this.router.navigate([
        '/editAllocationRule/' +
          this.routedata[0][0] +
          '/' +
          Number(this.route.snapshot.paramMap.get('id')?.split('S')[1]) +
          'S' +
          Number(this.route.snapshot.paramMap.get('id')?.split('S')[0]),
      ]);
    else this.router.navigate(['/defineExpressions']);
  }
  onChangeOperator(rule: any) {
    if (
      rule.operator.split('~')[0] === 'Is Empty' ||
      rule.operator.split('~')[0] === 'Is Not Empty'
    ) {
      rule.value = '';
    }
  }
  eyeIconClick() {
    const dialogRef = this.dialogService.open({
      content: CustomColumnsDetailsComponent,
      width: 600,
      height: 'auto',
    });
  }
}
