<div *ngIf="isLoading">
  <kendo-chunkprogressbar [chunkCount]="10" [min]="-10" [max]="10" [indeterminate]="true" [value]="30"
    style="width: 100%"></kendo-chunkprogressbar>
</div>
<!-- Header -->
<div class="header" style="margin-bottom: 0px; font-size: 20px">
  Movement journal
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <form class="k-form autoHeight" [formGroup]="addJournalForm" style="margin-top: 10px">
        <kendo-formfield>
          <kendo-label text="Financial year: " style="font-weight: bold">
            <!-- &nbsp;&nbsp;&nbsp;<kendo-label
              >{{ fiscalYearValue == 0 ? "" : fiscalYearValue }}
            </kendo-label> -->
            <span style="margin-left: 10px;">{{ fiscalYearValue == 0 ? "" : fiscalYearValue }}</span>
          </kendo-label>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="businessType" text="Business type:">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <kendo-combobox #businessType formControlName="businessType" [(ngModel)]="selectedBusinessType"
              [data]="businessTypes" style="width: 30%" textField="Description" valueField="BusinessTypeId"
              placeholder="Select business type" (valueChange)="handleBusinessTypeChange($event)" [disabled]="
                isTaxAmtData ||
                mode == 'Edit' ||
                callingType == 'manageTransaction'
              ">
            </kendo-combobox>
            &nbsp;* &nbsp;&nbsp; Legal entity:
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <kendo-combobox #legalEntity formControlName="legalEntity" [data]="legalEntities"
              [(ngModel)]="selectedLegalEntity" style="width: 30%" textField="Description" valueField="ID"
              placeholder="Select legal entity" (valueChange)="handleLegalEntityChange($event)" [disabled]="
                isTaxAmtData ||
                mode == 'Edit' ||
                callingType == 'manageTransaction'
              ">
            </kendo-combobox>
            &nbsp;*
          </kendo-label>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="journalRef" text="Journal Ref:">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input formControlName="journalRef" style="width: 30%" kendoTextBox #journalRef />

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Journal description: &nbsp;
            <input formControlName="journalDescription" style="width: 30%" kendoTextBox #journalDescription />
            &nbsp;*
          </kendo-label>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="journalNarrative" text="Journal narrative:">
            &nbsp;&nbsp; &nbsp;&nbsp;
            <input formControlName="journalNarrative" style="width: 30%" kendoTextBox #journalNarrative />

            &nbsp;&nbsp; &nbsp;&nbsp; Journal category: &nbsp;&nbsp;
            &nbsp;&nbsp;
            <kendo-combobox #journalCategory formControlName="journalCategory" [data]="journalCategories"
              style="width: 30%" textField="Name" valueField="JournalCategoryId" placeholder="Select journal category"
              [kendoDropDownFilter]="filterSettings">
            </kendo-combobox>
            &nbsp;*
          </kendo-label>
        </kendo-formfield>
        <kendo-formfield>
          <!-- Journal period:
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        <kendo-combobox
                        #journalPeriod
                        formControlName="journalPeriod"
                        [data]="periods"
                        style="width: 30%;"
                        placeholder="Select Journal period..."
                        (valueChange)="handleJournalPeriodChange($event)"
                        *ngIf="isJourneyPeriodNew==false"
                        >
                        </kendo-combobox>
                        <input *ngIf="isJourneyPeriodNew" formControlName="journalPeriodNew" style="width: 30%;" kendoTextBox #journalPeriodNew/>
                        &nbsp;*
                        &nbsp; -->
          Journal date: &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
          <kendo-datepicker formControlName="journalDate" #journalDate [format]="format" [(value)]="value"
            style="width: 30%" calendarType="classic">
          </kendo-datepicker>
          &nbsp;*
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="glAccount" text="From GL account:">
            &nbsp;&nbsp;&nbsp;
            <kendo-combobox #glAccount formControlName="glAccount" [(ngModel)]="selectedGlAccount"
              [data]="glTaxAccounts" style="width: 30%" textField="ShortDescription" valueField="StatutoryAccountCodeId"
              placeholder="Select GL account" (valueChange)="handleGlAccountChange($event, 0)" [disabled]="
                isNewButtonClick ||
                mode == 'Edit' ||
                callingType == 'manageTransaction'
              " [kendoDropDownFilter]="filterSettings">
            </kendo-combobox>
            &nbsp;* &nbsp;&nbsp;&nbsp; From tax account: &nbsp;&nbsp;
            <kendo-combobox #taxAccount formControlName="taxAccount" [(ngModel)]="selectedTaxAccount"
              [data]="taxAccounts" style="width: 30%" textField="ShortDescription" valueField="TaxAccountCodeId"
              placeholder="Select tax account" [kendoDropDownFilter]="filterSettings"
              (valueChange)="handleTaxAccountChange($event)" [disabled]="
                isNewButtonClick ||
                mode == 'Edit' ||
                callingType == 'manageTransaction'
              ">
            </kendo-combobox>
            &nbsp;* &nbsp;&nbsp;
            <ap-button [icon]="'plus-outline'" [compact]="true" [add]="true" [disabled]="selectedGlAccount == undefined"
              (onClick)="openAddTransaction()" kendoTooltip title="New"></ap-button>
          </kendo-label>
        </kendo-formfield>

        <div *ngIf="isTransationAddClick || isTransationEditClick">
          <button kendoButton class="actionButton chooseColumnsButton btn-pwc-primary" (click)="openWindow()"
            [disabled]="isLoading" style="margin-top: 5px; margin-bottom: 5px">
            Choose columns
          </button>
          <kendo-window *ngIf="opened" [draggable]="isDraggable" (close)="openClose(false)" [width]="300" [height]="300"
            [(state)]="windowState" title="Choose columns"
            style="position: fixed !important; margin-top: 2% !important">
            <dl *ngFor="let column of columns" class="">
              <label class="k-checkbox-label" for="{{ column }}" style="margin-top: 5px">
                <input type="checkbox" id="{{ column }}" kendoCheckBox [checked]="!isHidden(column)"
                  (change)="hideColumn(column)" />
                {{ column }}</label>
            </dl>
            &nbsp;
            <ap-button [btnType]="'primary'" [label]="'Save'" themeColor="primary" (click)="saveColumn()"
              style="margin-right: 6px"></ap-button>
          </kendo-window>
        </div>
        <kendo-tabstrip (tabClose)="onClose($event)" (tabSelect)="onSelect($event)" #tabstrip>
          <kendo-tabstrip-tab title="Transactions" [selected]="true">
            <ng-template kendoTabContent>
              <div *ngIf="isGridVisible">
                <br />
                <kendo-grid [kendoGridBinding]="addTransactionsFinal" [sort]="gridState.sort!" style="height: 280px"
                  [reorderable]="true">
                  <kendo-grid-column title="Actions" [width]="120">
                    <ng-template kendoGridCellTemplate let-data>
                      <button kendoButton (click)="editTransaction(data)" kendoTooltip title="Edit">
                        <span class="Appkit4-icon icon-edit-outline"></span>
                      </button>
                      &nbsp;
                      <button kendoButton (click)="deleteButtonClick(data)" kendoTooltip title="Delete">
                        <span class="Appkit4-icon icon-delete-outline"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <!-- <kendo-grid-column field="DataRow"  title="DataRow"  ></kendo-grid-column>
                                <kendo-grid-column field="ID"  title="ID"  ></kendo-grid-column>
                                <kendo-grid-column field="_id"  title="_id"  ></kendo-grid-column> -->
                  <!-- <kendo-grid-column field="Mode"  title="Mode" [width]="60" ></kendo-grid-column>
                                <kendo-grid-column field="OnlineAllocationElementId"  title="Online allocation element id"  ></kendo-grid-column>
                                <kendo-grid-column field="JournalId"  title="Journal id"  ></kendo-grid-column> -->
                  <kendo-grid-column field="Description" title="Description"></kendo-grid-column>
                  <kendo-grid-column field="DebitCredit" title="CR/DR" [width]="80"></kendo-grid-column>
                  <kendo-grid-column field="UnallocatedAmount" [hidden]="true" title="Unallocated"
                    [width]="120"></kendo-grid-column>
                  <kendo-grid-column field="JournalAmount" [hidden]="true" title="Move"
                    [width]="150"></kendo-grid-column>
                  <kendo-grid-column field="UnallocatedAmountText" title="Unallocated"
                    [width]="150"></kendo-grid-column>
                  <kendo-grid-column field="JournalAmountText" title="Move" [width]="120"></kendo-grid-column>
                  <kendo-grid-column field="TaxAccountCodeId" [hidden]="true" title="Destination tax a/c"
                    [width]="140"></kendo-grid-column>
                  <kendo-grid-column field="ShortDescription" title="Destination tax a/c"
                    [width]="140"></kendo-grid-column>
                  <kendo-grid-column field="TransactionTypeDescription" title="Transaction type"
                    [width]="120"></kendo-grid-column>
                  <kendo-grid-column field="fk_StatutoryAccountCodeId" title="GL account"
                    [width]="120"></kendo-grid-column>
                </kendo-grid>
              </div>

              <div *ngIf="isTransationAddClick">
                <label class="k-label" [for]="selectAll" text="Select all" style="margin-bottom: 5px; margin-top: 5px">
                  <input type="checkbox" [(ngModel)]="IsSelectAll" (click)="changeSelectAll()" kendoCheckBox
                    formControlName="selectAll" #selectAll />
                  &nbsp;&nbsp;Select All
                </label>

                <kendo-grid #gridTransaction style="width: 100%; height: 200px" [data]="(view | async)!"
                  [selectable]="true" (cellClick)="cellClickHandler($event)" (cellClose)="cellCloseHandler($event)"
                  [navigable]="true" [sort]="gridState.sort!">
                  <kendo-grid-column field="IsChecked" title="Select" [width]="70">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <input type="checkbox" kendoCheckBox (click)="toggle(dataItem)" [checked]="dataItem.IsChecked" />
                    </ng-template>
                  </kendo-grid-column>
                  <!-- <kendo-grid-column
                                field="DataRow"
                                title="DataRow"
                            ></kendo-grid-column>
                            <kendo-grid-column
                                field="ID"
                                title="ID"
                            ></kendo-grid-column>
                            <kendo-grid-column
                            field="_id"
                            title="_id"
                        ></kendo-grid-column> -->
                  <kendo-grid-column field="Description" title="Description" [width]="200"></kendo-grid-column>
                  <kendo-grid-column field="UnallocatedAmount" title="Unallocated" editor="numeric"></kendo-grid-column>
                  <kendo-grid-column field="JournalAmount" title="Move" editor="numeric"></kendo-grid-column>
                  <kendo-grid-column field="UnallocatedAmountText" title="Unallocated" editor="numeric" [width]="150"
                    [hidden]="true"></kendo-grid-column>
                  <kendo-grid-column field="JournalAmountText" title="Move" editor="numeric" [width]="150"
                    [hidden]="true"></kendo-grid-column>
                  <kendo-grid-column *ngFor="let col of gridColumns" field="{{ col.field }}" title="{{ col.title }}"
                    format="{{ col.format }}" [filter]="col.type" [width]="100"></kendo-grid-column>
                </kendo-grid>
              </div>
              <div *ngIf="isTransationEditClick">
                <kendo-grid #gridTransaction style="width: 100%; height: 115px" [data]="editTrans" [selectable]="true"
                  (cellClick)="cellClickHandlerTr($event)" (cellClose)="cellCloseHandlerTr($event)" [navigable]="true"
                  [sort]="gridState.sort!">
                  <!-- <kendo-grid-column
                                field="DataRow"
                                title="DataRow"
                            ></kendo-grid-column>
                            <kendo-grid-column
                                field="ID"
                                title="ID"
                            ></kendo-grid-column>
                            <kendo-grid-column
                                field="_id"
                                title="_id"
                            ></kendo-grid-column> -->
                  <kendo-grid-column field="Description" title="Description" [width]="200"></kendo-grid-column>
                  <kendo-grid-column field="UnallocatedAmount" title="Unallocated" editor="numeric"
                    [width]="150"></kendo-grid-column>

                  <kendo-grid-column field="UnallocatedAmountText" title="Unallocated" editor="numeric" [hidden]="true"
                    [width]="150"></kendo-grid-column>
                  <kendo-grid-column field="JournalAmount" title="Move" editor="numeric"
                    [width]="150"></kendo-grid-column>
                  <kendo-grid-column field="JournalAmountText" title="Move" editor="numeric" [hidden]="true"
                    [width]="150"></kendo-grid-column>
                  <kendo-grid-column *ngFor="let col of gridColumns" field="{{ col.field }}" title="{{ col.title }}"
                    format="{{ col.format }}" [filter]="col.type" [width]="100"></kendo-grid-column>
                </kendo-grid>
              </div>
              <div *ngIf="!isGridVisible">
                <kendo-formfield>
                  To Tax Account: &nbsp;&nbsp;
                  <kendo-combobox #taxAccountTr formControlName="taxAccountTr" [(ngModel)]="selectedTaxAccountTr"
                    [data]="taxAccountTrs" style="width: 30%" textField="ShortDescription" valueField="TaxAccountCodeId"
                    placeholder="Select tax account" (valueChange)="handleTaxAccountTrChange($event)">
                  </kendo-combobox>
                  &nbsp;*
                </kendo-formfield>
                <br />
                <div class="d-grid gap-2 d-md-flex justify-content-md-end" style="margin-right: 100px">
                  <ap-button kendoButton [btnType]="'primary'" [label]="'Update'" [disabled]="
                      this.isGridEdited || selectedTaxAccountTr == undefined
                    " (onClick)="saveAddTransaction()"></ap-button>
                  &nbsp;
                  <ap-button kendoButton [btnType]="'secondary'" [label]="'Cancel'"
                    (onClick)="closeAddTransaction()"></ap-button>
                </div>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="Supplementary calculations">
            <ng-template kendoTabContent>
              1. Add supplementay calculations:
              <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl"
                (upload)="uploadEventHandler($event)" [withCredentials]="withCredential" [multiple]="false"
                (success)="success($event)" [restrictions]="supplementaryFileRestrictions" [chunkable]="chunkSettings"
                style="width: 60%">
              </kendo-upload>
              &nbsp;
              <div style="margin-bottom: 5px">
                File name:&nbsp; {{ fileName.replace("null.null", "") }}
              </div>
              <div *ngIf="isFileUploaded">
                <div class="d-grid gap-2 d-md-flex justify-content-md-start" style="margin-right: 100px">
                  <ap-button kendoButton [btnType]="'secondary'" [label]="'View'"
                    (onClick)="viewButtonClick()"></ap-button>

                  <ap-button kendoButton [btnType]="'secondary'" [label]="'Remove'"
                    (onClick)="removeButtonClick()"></ap-button>
                </div>
                <kendo-formfield>
                  <kendo-label [for]="supplementayTaxAccount" text="2. Select assicated tax account:">
                  </kendo-label>

                  <kendo-combobox #supplementayTaxAccount formControlName="supplementayTaxAccount"
                    [data]="supplementayTaxAccounts" style="width: 30%" textField="TaxAccountDescription"
                    valueField="TaxAccountCodeId" placeholder="Select tax Account" [disabled]="!isFileUploaded">
                  </kendo-combobox>
                  &nbsp;*
                </kendo-formfield>
                <kendo-formfield>
                  <kendo-label [for]="includeSupplementay" text="3. Include supplementary calculations in the analysis
                    tax pack export.">
                    &nbsp;&nbsp;
                    <input type="checkbox" kendoCheckBox [disabled]="!isFileUploaded"
                      formControlName="includeSupplementay" #includeSupplementay />
                  </kendo-label>
                </kendo-formfield>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </form>
      <div *ngIf="isGridVisible" class="clickToActions" style="padding: 20px 0px">
        <ap-button kendoButton class="cancelButton okButton" [btnType]="'secondary'" [label]="'Cancel'"
          (onClick)="closeButtonClick()"></ap-button>
        <ap-button kendoButton class="okButton" style="margin-right: 6px" [btnType]="'primary'" [label]="'Save'"
          [disabled]="!addJournalForm.valid || this.isGridEdited" (onClick)="saveJournal()"></ap-button>
      </div>
    </div>
  </div>
</div>