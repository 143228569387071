 <div class="header">Tax outcome analysis</div>
<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%;"></kendo-chunkprogressbar>
</div>
<div class="container-fluid autoHeight">
  <div class="row">
    <div class="col-md-12 col-xs-12" style="font-size: 14px; margin-bottom:
      10px;">Select the tax outcomes you wish to report on and the periods you
      wish to analyse</div>
  </div>
  <form class="k-form" [formGroup]="taxoutcomeMappingForm">
    <kendo-formfield>
      <kendo-label>
        <span>
          <div class="gridHeader" style="font-weight:bold">Available tax
            outcomes</div>
          <kendo-grid
            [data]="taxoutcomeList"
            [kendoGridSelectBy]="selectedtaxOutcome"
            [(selectedKeys)]="mySelectedtaxOutcome"
            [resizable]="true"
            [height]="200"
            [selectable]="true" [hideHeader]="true">
            <kendo-grid-column [headerStyle]="{'font-weight' : 'bold',
              'text-align': 'center', 'color': '#d04a02'}"
              field="ShortDescription"></kendo-grid-column>
          </kendo-grid>
        </span>
        <div>
          <ap-button kendoButton (click)="taxoutcomeforwardScope()"
            [compact]="true" [btnType]="'secondary'" style="width:50px;
            margin:15px 24px 0px 24px;"><span class="material-icons">keyboard_arrow_right</span></ap-button>
          <ap-button kendoButton (click)="taxoutcomebackwardScope()"
            [compact]="true" [btnType]="'secondary'" style="width:50px; margin:
            0 24px;"><span class="material-icons">keyboard_arrow_left</span></ap-button>
          <ap-button kendoButton (click)="taxoutcomeforwardScopeAll()"
            [compact]="true" [btnType]="'secondary'" style="width:50px; margin:
            0 24px;"><span class="material-icons">keyboard_double_arrow_right</span></ap-button>
          <ap-button kendoButton (click)="taxoutcomebackwardScopeAll()"
            [compact]="true" [btnType]="'secondary'" style="width:50px; margin:
            0 24px;"><span class="material-icons">keyboard_double_arrow_left</span></ap-button>
        </div>
        <span>
          <div class="gridHeader" style="font-weight: bold;">Tax outcomes to
            report</div>
          <kendo-grid
            #taxOutComeMappingGrid
            [data]="taxoutcomeMappingList"
            [kendoGridSelectBy]="selectedtaxOutcome"
            [(selectedKeys)]="mySelectedtaxOutcomeMapping"
            [resizable]="true"
            [height]="200"
            [selectable]="true" [hideHeader]="true">
            <kendo-grid-column [headerStyle]="{'font-weight' : 'bold',
              'text-align': 'center', 'color': '#d04a02'}"
              field="ShortDescription"></kendo-grid-column>
          </kendo-grid>
        </span>
      </kendo-label>
    </kendo-formfield>
    <kendo-formfield orientation="horizontal">
      <div class="gridHeader" style="font-weight: bold;">Report type</div>
      <ul class="k-radio-list k-list-horizontal">
        <li class="k-radio-item">
          <input
            type="radio"
            name="report"
            value="Value"
            #values
            [(ngModel)]="reportType"
            kendoRadioButton
            formControlName="report"
            (ngModelChange)="selectReportType($event)"
            checked
            />
          <kendo-label [for]="values" text="Values"></kendo-label>
        </li>
        <li class="k-radio-item">
          <input
            type="radio"
            name="report"
            value="Percentage"
            #percentageData1
            [(ngModel)]="reportType"
            kendoRadioButton
            formControlName="report"
            (ngModelChange)="selectReportType($event)"
            />
          <kendo-label [for]="percentageData1" text="Percentage (%)"></kendo-label>
        </li>
      </ul>
    </kendo-formfield>
    <div class="gridHeader" style="font-weight: bold; margin-top: 10px;">Select
      years (APs)/period</div>
    <kendo-formfield orientation="horizontal">
      <kendo-label>
        <span>
          <span style="display: flex; margin-bottom: 10px;">
            <ul class="k-radio-list k-list-horizontal">
              <li class="k-radio-item">
                <input
                  type="radio"
                  name="period"
                  value="Year"
                  #pyears
                  [(ngModel)]="periodType"
                  kendoRadioButton
                  formControlName="period"
                  (ngModelChange)="selectperiodType($event)"
                  />
                <kendo-label [for]="pyears" text="Prior years(APs)"></kendo-label>
              </li>
            </ul>
            &nbsp;&nbsp;&nbsp;
            <kendo-combobox
              #pycombo
              formControlName="pycombo"
              [data]="pyList"
              [filterable]="true"
              textField="label"
              valueField="value"
              width="40%"
              [(ngModel)]="selectedperiodYear"
              (valueChange)="handlepycomboChange($event)"
              [disabled]="isperiodvalueEnable"
              style="width: 79%;">
            </kendo-combobox>
          </span>
          <div class="gridHeader" style="font-weight: bold;">Available Period</div>
          <kendo-grid
            [data]="availablePeriodList"
            [kendoGridSelectBy]="selectedPeriod"
            [(selectedKeys)]="mySelectedavailablePeriod"
            [resizable]="true"
            [height]="200"
            [selectable]="true" [hideHeader]="true">
            <kendo-grid-column [headerStyle]="{'font-weight' : 'bold',
              'text-align': 'center', 'color': '#d04a02'}" field="Display"></kendo-grid-column>
          </kendo-grid>
        </span>
        <div style="margin-top: 35px;">
          <ap-button kendoButton (click)="periodforwardScope()" [compact]="true"
            [btnType]="'secondary'" style="width:50px; margin: 15px 24px 0px
            24px;"><span class="material-icons">keyboard_arrow_right</span></ap-button>
          <ap-button kendoButton (click)="periodbackwardScope()"
            [compact]="true" [btnType]="'secondary'" style="width:50px; margin:
            0 24px;"><span class="material-icons">keyboard_arrow_left</span></ap-button>
          <ap-button kendoButton (click)="periodforwardScopeAll()"
            [compact]="true" [btnType]="'secondary'" style="width:50px; margin:
            0 24px;"><span class="material-icons">keyboard_double_arrow_right</span></ap-button>
          <ap-button kendoButton (click)="periodbackwardScopeAll()"
            [compact]="true" [btnType]="'secondary'" style="width:50px; margin:
            0 24px;"><span class="material-icons">keyboard_double_arrow_left</span></ap-button>
        </div>
        <span>
          <span style="display: flex; margin-bottom: 10px;">
            <ul class="k-radio-list k-list-horizontal">
              <li class="k-radio-item">
                <input
                  type="radio"
                  name="period"
                  value="Period"
                  #priorperiod
                  [(ngModel)]="periodType"
                  kendoRadioButton
                  formControlName="period"
                  (ngModelChange)="selectperiodType($event)"
                  />
                <kendo-label [for]="priorperiod" text="Prior period"></kendo-label>
              </li>
            </ul>
            &nbsp;&nbsp;&nbsp;
            <kendo-combobox
              #ppcombo
              formControlName="ppcombo"
              [data]="ppList"
              [filterable]="true"
              textField="label"
              valueField="value"
              width="25%"
              [(ngModel)]="selectedperiodPercentage"
              (valueChange)="handleppcomboChange($event)"
              [disabled]="isperiodpercentageEnable"
              style="width: 83%;">
            </kendo-combobox>
          </span>
          <div class="gridHeader" style="font-weight:bold">Periods to report</div>
          <kendo-grid
            [data]="periodtoReportList"
            #periodReport
            [kendoGridSelectBy]="selectedPeriod"
            [(selectedKeys)]="mySelectedperiodtoReport"
            [resizable]="true"
            [height]="200"
            [selectable]="true" [hideHeader]="true">
            <kendo-grid-column [headerStyle]="{'font-weight' : 'bold',
              'text-align': 'center', 'color': '#d04a02'}" field="Display"></kendo-grid-column>
          </kendo-grid>
        </span>

      </kendo-label>
    </kendo-formfield>
  </form>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <div class="clickToActions" style="padding: 10px 0px">
        <ap-button [btnType]="'secondary'" [label]="'Cancel'" class="okButton"
          (click)="closeButtonClick()"></ap-button>
        <ap-button [btnType]="'primary'" [label]="'Save'" class="okButton"
          themeColor="primary"
          (click)="saveChanges()"
          [disabled]="!taxoutcomeMappingForm.valid" style="margin-right: 6px;"></ap-button>
      </div>
    </div>
  </div>
</div>