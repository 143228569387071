import { Component, OnInit,Input } from '@angular/core';
import { DialogContentBase, ActionsLayout, DialogAction} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DialogService, DialogRef,DialogCloseResult} from '@progress/kendo-angular-dialog';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';


@Component({
  selector: 'app-deletelegalentity',
  templateUrl: './deletelegalentity.component.html',
  styleUrls: ['./deletelegalentity.component.scss']
})
export class DeletelegalentityComponent extends DialogContentBase implements OnInit {
  @Input() public delLEdata: any;
  public actionsLayout: ActionsLayout = 'normal';
  warnondelete:boolean=false;
  constructor( 
    public dialog: DialogRef,     
    public toastr: ToastrManager,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {
    super(dialog);
   }

  ngOnInit(): void {
  }
  cancelClick(){
    this.dialog.close();
  }
  deleteBT(){
    this.globalService.deleteLegalEntityById(this.delLEdata.ID).then((res: any) => {    
      
      if(res == "Legal Entity is deleted successfully"){
        
        this.toastr.successToastr(
          "Legal entity is deleted successfully",
          'Success'
        );
        this.dialog.close();
      }else if(res =="Reference Constraint Violated : 500"){
        //this.dialog.close();
        this.warnondelete=true;
      }
      else{
        this.toastr.errorToastr('Something went wrong try after sometime', 'Oops!');
      }
      
      
    }).catch((err: any) => {
    })
  }
}
