import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenubarService } from 'src/app/common/menubarService/menubar.service';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImportstatementdetailsService } from '../common/services/importstatementdetails/importstatementdetails.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { StatementDetailExportType } from '../_models/statementDetailExportType';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import {
  ChunkSettings,
  FileInfo,
  FileRestrictions,
  SelectEvent,
  SuccessEvent,
  UploadEvent,
} from '@progress/kendo-angular-upload';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-import-statement-details',
  templateUrl: './import-statement-details.component.html',
  styleUrls: ['./import-statement-details.component.scss'],
})
export class ImportStatementDetailsComponent implements OnInit {
  isLoading: boolean = false;
  activeIndex: number = 0;
  orientation = 'horizontal';
  readonly = true;
  space = 190;
  steps = [
    { label: 'Select analysis pack' },
    { label: 'Import wizard' },
    { label: 'Select worksheet' },
    { label: 'Duplicates' },
    { label: ' Importing data' },
  ];
  private selectedClient: any;
  isConfirmOpen: boolean = false;
  isCancelDisabled: boolean = false;
  isBackDisabled: boolean = true;
  isNextDisabled: boolean = true;
  importForm!: FormGroup;
  businessTypes: StatementDetailExportType[] = [];
  selectedBusinessType: StatementDetailExportType | undefined;
  legalEntities: StatementDetailExportType[] = [];
  selectedLegalEntity: StatementDetailExportType | undefined;
  analysispacks: StatementDetailExportType[] = [];
  selectedAnalysisPack: StatementDetailExportType | undefined;
  legalEntityId: any;
  businessTypeId: any;
  uploadSaveUrl: string = '';
  baseUrl: any;
  apifolder: any;
  headers: any;
  headersObj: any;
  public chunkSettings: ChunkSettings = {
    size: 1048576,
  };
  myRestrictions: FileRestrictions = {
    allowedExtensions: ['.xls', '.xlsx'],
  };
  withCredential: boolean = false;
  fileName: string = '';
  fileId: any;
  sheetsList: any;
  checkboxDisabled = false;
  tempState = false;
  isImportDuplicate: boolean = false;
  importProcessingStartPayload: any;
  selectedsheets: string[] = [];
  processingOrchestrationId: any;
  afterImportDetails: any;
  isImportSuccess: boolean = false;
  isErrorAvailable: boolean = false;
  importOutputSummaryDetail: any;
  isStaticErrorDisplay: boolean = false;
  isReportavailable: boolean = false;

  constructor(
    public menubarService: MenubarService,
    private router: Router,
    private fb: FormBuilder,
    private importstatementdetailsService: ImportstatementdetailsService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.importForm = this.fb.group({
      legalEntity: ['', Validators.required],
      businessType: ['', Validators.required],
      analysisPack: ['', Validators.required],
    });
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    this.getBusinessTypeList();
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.uploadSaveUrl =
      this.baseUrl +
      this.apifolder +
      '/StatementDetails/StatementDetailTemplateChunkUploadSave';
  }
  handleNextButton() {
    this.isNextDisabled = true;
    this.isBackDisabled = false;

    if (this.activeIndex == 2) {
      this.isNextDisabled = false;
    }
    if (this.activeIndex == 3) {
      this.isBackDisabled = true;
      this.isNextDisabled = true;
      this.importGlAccountImportprocessing();
    }
    if (this.activeIndex < this.steps.length - 1)
      this.activeIndex = this.activeIndex + 1;
  }
  handleBackButton() {
    if (this.activeIndex > 0) this.activeIndex = this.activeIndex - 1;
    if (this.activeIndex < 2) this.isCancelDisabled = false;
    if (this.activeIndex == 1) this.isCancelDisabled = true;
    if (this.activeIndex == 0) this.isBackDisabled = true;
  }
  handleCancelButton() {
    this.isConfirmOpen = true;
  }
  finish() {
    this.menubarService.show();
    this.router.navigate(['/home']);
  }
  getBusinessTypeList(): void {
    this.isLoading = true;
    this.importstatementdetailsService.getAllBusinessTypeDetail().subscribe(
      (response) => {
        //console.log(response,"res");
        this.businessTypes = response;

        if (this.businessTypes.length == 1) {
          this.selectedBusinessType = this.businessTypes[0];
          this.handleBusinessTypeChange(this.selectedBusinessType);
        }
        this.isLoading = false;
      },
      (error) => {
        //console.log(error);
        this.isLoading = false;
      }
    );
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  handleBusinessTypeChange(businessType: StatementDetailExportType): void {
    this.selectedBusinessType = businessType;
    this.selectedLegalEntity = undefined;
    this.legalEntities = [];

    if (businessType != undefined) {
      this.businessTypeId = businessType.LookUpId;
      this.getLegalEntityList(businessType.LookUpId);
    }
  }
  getLegalEntityList(businessTypeId: number) {
    this.isLoading = true;
    this.importstatementdetailsService
      .getLegalEntitiesByBusinessTypeId(businessTypeId)
      .subscribe(
        (response) => {
          //console.log(response,"res");
          this.legalEntities = response;
          if (this.legalEntities.length == 1) {
            this.selectedLegalEntity = this.legalEntities[0];
            this.handleLegalEntityChange(this.selectedLegalEntity);
          }

          this.isLoading = false;
        },
        (error) => {
          //console.log(error);
          this.isLoading = false;
        }
      );
  }
  handleLegalEntityChange(legalEntity: StatementDetailExportType): void {
    this.selectedLegalEntity = legalEntity;
    this.analysispacks = [];
    this.selectedAnalysisPack = undefined;
    if (legalEntity != undefined) {
      this.legalEntityId = legalEntity.LookUpId;
      this.getanalysispackList(this.businessTypeId, this.legalEntityId);
    }
  }
  handleanalysisPackChange(analysisPack: StatementDetailExportType): void {
    if (analysisPack != undefined) this.isNextDisabled = false;
    else this.isNextDisabled = true;
  }
  getanalysispackList(businessTypeId: number, legalEntityId: number) {
    this.isLoading = true;
    this.importstatementdetailsService
      .getStatementGroupsByBusTypeAndLegalEntityId(
        businessTypeId,
        legalEntityId
      )
      .subscribe(
        (response) => {
          //console.log(response,"res");
          this.analysispacks = response;

          if (this.analysispacks.length == 1) {
            this.selectedAnalysisPack = this.analysispacks[0];
            this.handleanalysisPackChange(this.selectedAnalysisPack);
          }
          this.isLoading = false;
        },
        (error) => {
          //console.log(error);
          this.isLoading = false;
        }
      );
  }
  uploadEventHandler(e: UploadEvent) {
    let tokenValue: any = localStorage.getItem('OPENAMSESSION');
    e.headers = e.headers.append(
      'Authorization-Token',
      'Bearer ' + JSON.parse(tokenValue).access_token
    );
    e.data = {
      ClientdbName: this.selectedClient.DBName,
    };
  }
  public success(e: SuccessEvent): void {
    if (e.files.length > 0) {
      this.fileName = e.files[0].name;
      this.fileId = e.files[0].uid?.toString();

      if (e.response.body.FileUploaded == true) {
        this.getSheetNames(this.fileId, this.fileName);
      }
    }

    this.isNextDisabled = false;
  }
  getSheetNames(fileId: string, fileName: string): void {
    this.importstatementdetailsService
      .getSheetNames(fileId + '/' + fileName)
      .subscribe(
        (response: any) => {
          this.sheetsList = response.map((value: string) => {
            return {
              sheetname: value,
              selected: false,
            };
          });
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  onCheckboxchange(event: any) {
    let array1 = this.sheetsList.filter((obj: any) => obj.selected == true);

    if (array1.length > 0) this.isNextDisabled = false;
    else this.isNextDisabled = true;
  }
  importGlAccountImportprocessing() {
    this.isLoading = true;
    let array1 = this.sheetsList.filter((obj: any) => obj.selected == true);
    this.selectedsheets = array1.map((item: any) => {
      return item.sheetname;
    });
    this.importProcessingStartPayload = {
      fileName: this.fileId + '/' + this.fileName,
      businessTypeId: this.businessTypeId,
      legalEntityId: this.legalEntityId,
      statementGroupId: this.selectedAnalysisPack?.LookUpId,
      sheetNames: this.selectedsheets,
      clientDbName: this.selectedClient.DBName,
      isImportDuplicates: this.isImportDuplicate,
    };
    this.importstatementdetailsService
      .importStatementDetailTemplateProcessingStart(
        this.importProcessingStartPayload
      )
      .subscribe(
        (response: any) => {
          if (response) {
            this.processingOrchestrationId = response.orchestrationId;
            this.getProcessingStatus();
          }
          //this.isNextVisible = true;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  getProcessingStatus() {
    this.importstatementdetailsService
      .getImportStatementDetailTemplateProcessingStatus(
        this.processingOrchestrationId
      )
      .subscribe(
        (response: any) => {
          if (
            response.orchestrationRuntimeStatus == 'Completed' ||
            response.orchestrationRuntimeStatus == 'Failed'
          ) {
            this.isLoading = false;

            this.afterImportDetails = response;
            this.isImportSuccess = true;
            this.importOutputSummaryDetail = response.importOutputSummaryDetail;
            if (
              this.afterImportDetails.validationFailureReportTextFileName !=
                null &&
              !this.afterImportDetails.isImportProcessingCompletedWithSuccess
            ) {
              this.isErrorAvailable = true;
            }
            if (
              this.afterImportDetails.validationFailureReportTextFileName ==
                null &&
              !this.afterImportDetails.isImportProcessingCompletedWithSuccess
            ) {
              this.isStaticErrorDisplay = true;
              this.isBackDisabled = false;
            }
            if (this.afterImportDetails.importReportTextFileName != null) {
              this.isReportavailable = true;
            }
          } else this.getProcessingStatus();
        },
        (error: any) => {
          console.log(error);
          return error;
        }
      );
  }
  getImportFileReport() {
    let reportFileName;
    if (this.isErrorAvailable)
      reportFileName =
        this.afterImportDetails.validationFailureReportTextFileName;
    else if (this.isReportavailable)
      reportFileName = this.afterImportDetails.importReportTextFileName;
    this.importstatementdetailsService
      .downloadStatementDetailImportReportTextFile(reportFileName)
      .subscribe(
        (response: any) => {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          let reportFileNameDownload;
          if (this.isErrorAvailable)
            reportFileNameDownload =
              this.afterImportDetails.validationFailureReportTextFileName;
          else if (this.isReportavailable)
            reportFileNameDownload =
              this.afterImportDetails.importReportTextFileName;
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = reportFileNameDownload;
          a.href = window.URL.createObjectURL(blob);
          a.click();
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  public close(status: string): void {
    if (status == 'yes') {
      this.isConfirmOpen = false;
      this.menubarService.show();
      this.router.navigate(['/home']);
    } else {
      this.isConfirmOpen = false;
    }
  }
}
