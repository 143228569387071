<div class="header">Statement details import wizard</div>
<mat-card class="userCard">
  <div>
    <div class="container2">
      <div class="row">
        <div class="col-12">
          <ap-progress-stepper-set
            [orientation]="orientation"
            [(activeIndex)]="activeIndex"
            [space]="space"
            [readonly]="readonly"
          >
            <ap-progress-stepper
              *ngFor="let tab of steps; let i = index"
              [label]="tab.label"
            >
              <!-- Set the content of every stepper panel here -->
            </ap-progress-stepper>
            <div *ngIf="isLoading">
              <kendo-chunkprogressbar
                [chunkCount]="10"
                [min]="-10"
                [max]="10"
                [indeterminate]="true"
                [value]="30"
                style="width: 100%"
              ></kendo-chunkprogressbar>
            </div>
          </ap-progress-stepper-set>
        </div>
      </div>
    </div>
    <div
      class="card"
      *ngIf="activeIndex == 0"
      style="height: calc(100vh - 230px)"
    >
      <div class="card-body card_body">
        <div class="col-md-12 col-xs-12" style="padding: 0px">
          <!-- <div><b>Select analysis pack</b></div> -->
          Select the analysis pack you would like to import the statement
          details into
        </div>
        <form class="k-form" style="margin-top: 10px" [formGroup]="importForm">
          <div class="row">
            <kendo-formfield>
              <kendo-label text="Business type*"> </kendo-label>
              <kendo-combobox
                #businessType
                formControlName="businessType"
                [(ngModel)]="selectedBusinessType"
                [data]="businessTypes"
                textField="LookUpDescription"
                valueField="LookUpId"
                [kendoDropDownFilter]="filterSettings"
                placeholder="Select business type"
                (valueChange)="handleBusinessTypeChange($event)"
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>
          <div class="row">
            <kendo-formfield>
              <kendo-label text="Legal entity*"> </kendo-label>
              <kendo-combobox
                #legalEntity
                formControlName="legalEntity"
                [data]="legalEntities"
                [(ngModel)]="selectedLegalEntity"
                textField="LookUpDescription"
                [kendoDropDownFilter]="filterSettings"
                valueField="LookUpId"
                placeholder="Select legal entity"
                (valueChange)="handleLegalEntityChange($event)"
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>
          <div class="row">
            <kendo-formfield>
              <kendo-label text="Analysis pack*"> </kendo-label>
              <kendo-combobox
                #legalEntity
                formControlName="analysisPack"
                [data]="analysispacks"
                [(ngModel)]="selectedAnalysisPack"
                textField="LookUpDescription"
                [kendoDropDownFilter]="filterSettings"
                valueField="LookUpId"
                placeholder="Select analysis pack"
                (valueChange)="handleanalysisPackChange($event)"
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>
        </form>
      </div>
    </div>
    <!-- Import wizard content here -->
    <div
      class="card"
      *ngIf="activeIndex == 1"
      style="height: calc(100vh - 230px)"
    >
      <div class="card-body card_body">
        <div class="" style="font-size: 35px">
          <div class="">
            <div class="ap-container" style="padding: 0px">
              <div class="title1" style="margin-bottom: 0px">
                <p style="text-align: left; font-size: 14px; font-weight: bold">
                  Select source file
                </p>
                <!-- <p>With supporting text below as a natural lead-in to additional content.</p> -->

                <div class="card">
                  <div
                    class="card-body card_body"
                    style="padding: 10px 0px; height: calc(100vh - 410px)"
                  >
                    <div class="ap-container">
                      <div class="row">
                        <div class="col-1 Appkit4-iconFirst"></div>
                        <div class="col-4" style="padding: 0px">
                          <div class="title">
                            <p>Select the file to import the data from</p>
                          </div>
                          <div class="col-7"></div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-1 Appkit4-iconFirst">
                          <span
                            class="Appkit4-icon icon-folder-opened-outline"
                          ></span>
                        </div>
                        <div
                          class="col-9 input-class uploadfile-css"
                          style="width: 91%; padding: 10px"
                        >
                          <kendo-upload
                            [saveUrl]="uploadSaveUrl"
                            [chunkable]="chunkSettings"
                            (upload)="uploadEventHandler($event)"
                            [withCredentials]="withCredential"
                            [restrictions]="myRestrictions"
                            [multiple]="false"
                            (success)="success($event)"
                          >
                          </kendo-upload>
                          <div *ngIf="fileName" class="displayfile">
                            <div>Uploaded file : {{ this.fileName }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Select worksheet content here -->
    <div
      class="card"
      *ngIf="activeIndex == 2"
      style="height: calc(100vh - 250px)"
    >
      <div class="card-body card_body">
        <div class="ap-container app-content" style="padding: 0px">
          <div class="row">
            <div class="col-12">
              <div class="title1">
                <p style="margin-bottom: 10px">
                  Select one or more sheets from the list below to import the
                  data
                </p>
              </div>
            </div>
            <div class="col-12">
              <div
                class="card"
                style="
                  height: calc(100vh - 370px);
                  overflow-y: scroll;
                  overflow-x: hidden;
                "
              >
                <div class="card-body title1">
                  <ul style="padding-left: 0px">
                    <li
                      *ngFor="let item of sheetsList"
                      [ngStyle]="{ 'list-style-type': 'none' }"
                    >
                      <ap-checkbox
                        (onChange)="onCheckboxchange($event)"
                        [(ngModel)]="item.selected"
                        [disabled]="checkboxDisabled"
                        [indeterminate]="tempState"
                      >
                        <span>{{ item.sheetname }}</span>
                      </ap-checkbox>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card"
      *ngIf="activeIndex == 3"
      style="height: calc(100vh - 250px)"
    >
      <div class="card-body card_body">
        <div
          class="ap-container app-content"
          style="font-size: 35px; padding-left: 0px"
        >
          <div class="row">
            <div class="col">
              <div class="Pre-processing">
                <p style="margin-bottom: 10px">
                  Specify if you would like to import duplicate line items.
                </p>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="!isLoading">
            <div class="col-12">
              <div
                class="card-body"
                style="
                  text-align: center;
                  height: calc(100vh - 370px);
                  background-color: white;
                "
              >
                <div
                  class="card-header Pre-processing"
                  style="
                    border-bottom: none;
                    background-color: white;
                    padding: 0px;
                  "
                >
                  <p>
                    If you select to import duplicates the duplicated line items
                    will be appended with a unique number.
                  </p>
                  <p>
                    If you have exported existing line items, you should not
                    select this option to avoid creating duplicate line items.
                  </p>

                  <p>
                    <input
                      type="checkbox"
                      [(ngModel)]="isImportDuplicate"
                      #CreateTaxAccount
                      kendoCheckBox
                    />
                    Import duplicates
                  </p>
                </div>

                <!-- <div
                  class="card-body Pre-processing"
                  *ngIf="isProcessingSuccess"
                >
                  <p class="card-text">
                    Validation Success.Click next to continue.
                  </p>
                </div>
                <div
                  class="card-body Pre-processing"
                  *ngIf="!isProcessingSuccess"
                >
                  <p class="card-text">
                    Validation failed: {{ failureMessage }}
                  </p>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card"
      *ngIf="activeIndex == 4"
      style="height: calc(100vh - 240px)"
    >
      <div class="card-body card_body">
        <div
          class="ap-container app-content Pre-processing"
          style="padding: 0px"
        >
          <div class="row">
            <div class="col-12" style="margin-bottom: 10px">
              <div class="title1" *ngIf="!isLoading && isImportSuccess">
                <b style="font-size: 14px">Import complete</b>
                <p style="margin-bottom: 0px">
                  The import process is now complete.
                </p>
              </div>
              <div class="title1" *ngIf="!isLoading && isErrorAvailable">
                <p style="margin-bottom: 0px">
                  The file you have specified cannot be imported.You can view
                  the error report for further information.
                </p>
                <p style="margin-bottom: 0px">
                  Select back to choose another file or worksheet again.
                </p>
              </div>
              <div class="title1" *ngIf="!isLoading && isStaticErrorDisplay">
                <p style="margin-bottom: 0px">
                  The file you have specified cannot be imported.
                </p>
                <p style="margin-bottom: 0px">
                  Select back to choose another file or worksheet again.
                </p>
              </div>
            </div>
            <div
              class="col-12"
              *ngIf="importOutputSummaryDetail != null"
              style="padding-top: 10px"
            >
              <div class="card">
                <div class="card-body title1" style="padding: 10px 20px">
                  <p class="summaryDetail">{{ importOutputSummaryDetail }}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row"
            *ngIf="
              isImportSuccess &&
              (afterImportDetails.validationFailureReportTextFileName != null ||
                afterImportDetails.importReportTextFileName != null)
            "
          >
            <div class="col-12">
              <div class="card">
                <div
                  class="card-body title1"
                  style="padding: 10px 20px; display: flex"
                >
                  <p>
                    <ap-button
                      type="button"
                      [btnType]="'primary'"
                      [label]="'Download report'"
                      (onClick)="getImportFileReport()"
                      style="margin-bottom: 1px"
                    >
                    </ap-button
                    ><br /><span style="margin-top: 10px">
                      Click the button to download<br />summary report</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="fixed-bottom">
    <div class="row">
      <div class="card" style="border: 0px">
        <div class="card-body">
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <ap-button
              type="button"
              [btnType]="'secondary'"
              [label]="'Back'"
              [disabled]="isBackDisabled"
              (onClick)="handleBackButton()"
              class="okButton"
            >
            </ap-button>
            <ap-button
              type="button"
              [btnType]="'primary'"
              [label]="'Next'"
              [disabled]="isNextDisabled"
              (onClick)="handleNextButton()"
              class="okButton"
            >
            </ap-button>
            <ap-button
              *ngIf="activeIndex == 4 && !this.isLoading"
              type="button"
              [btnType]="'primary'"
              [label]="'Finish'"
              (click)="finish()"
              class="okButton"
            >
            </ap-button>
            <ap-button
              *ngIf="activeIndex != 4"
              type="button"
              [btnType]="'secondary'"
              [disabled]="isCancelDisabled"
              [label]="'Cancel'"
              (click)="handleCancelButton()"
              class="okButton"
            >
            </ap-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog
    title="Cancel wizard"
    *ngIf="isConfirmOpen"
    (close)="close('no')"
    [minWidth]="250"
    [width]="500"
  >
    <div style="display: flex">
      <p>
        <span
          class="Appkit4-icon icon-circle-warning-outline"
          style="font-size: 24px; color: #d04a02"
        ></span>
      </p>
      <p style="margin-left: 10px; font-size: medium">
        Are you sure you wish to cancel the import wizard?
      </p>
    </div>

    <div class="clickToActions" style="padding: 10px 0px 0px 0px">
      <ap-button
        [btnType]="'secondary'"
        [label]="'No'"
        style="width: 100px"
        (click)="close('no')"
      ></ap-button>
      <ap-button
        [btnType]="'primary'"
        [label]="'Yes'"
        themeColor="primary"
        (click)="close('yes')"
        class="okButton"
        style="margin-right: 6px"
      ></ap-button>
    </div>
  </kendo-dialog>
</mat-card>
