import {
  Component,
  Input,
  NgZone,
  OnInit,
  ViewChild,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import {
  DialogRef,
  DialogCloseResult,
  DialogService,
  WindowCloseResult,
  WindowRef,
  WindowService,
} from '@progress/kendo-angular-dialog';
import {
  ColumnBase,
  ExpandEvent,
  RowClassArgs,
} from '@progress/kendo-angular-treelist';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { AnalysisReviewLayoutComponent } from '../analysis-review-layout/analysis-review-layout.component';
import {
  subLEOPtions,
  ULI_Childoptions,
  LEOPtions,
  SAoptions,
  APoptions,
  ASoptions,
  CLIoptions,
  SLIoptions,
  SSOPtions,
  SS_SAoptions,
  SS_ASoptions,
  SS_LEoptions,
  SS_LIOptions,
  SS_Childoptions,
} from '../contextMenu';
import { Employee, employees } from '../employees';
import { Entry } from '../analysisReviewTree';
import { MenuSelectEvent } from '@progress/kendo-angular-menu';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MoveSubMenuPopupComponent } from '../move-sub-menu-popup/move-sub-menu-popup.component';
import { AttachmentSubMenuPopupComponent } from '../attachment-sub-menu-popup/attachment-sub-menu-popup.component';
import { AnalysisPackPopupComponent } from '../analysis-pack-popup/analysis-pack-popup.component';
import { SummaryStatementComponent } from '../summary-statement/summary-statement.component';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { DisplayAccountPopupComponent } from '../display-account-popup/display-account-popup.component';
import { NoLineItemComponent } from '../no-line-item/no-line-item.component';
import { DeleteStatementComponent } from '../delete-statement/delete-statement.component';
import { EditAnalysisPackComponent } from '../edit-analysis-pack/edit-analysis-pack.component';
import { LinkLineItemPopupComponent } from '../link-line-item-popup/link-line-item-popup.component';
import { UnlinkLineItemPopupComponent } from '../unlink-line-item-popup/unlink-line-item-popup.component';
import { DeleteAnalysisPackPopupComponent } from '../delete-analysis-pack-popup/delete-analysis-pack-popup.component';
import { AnalysisEditLineComponent } from '../analysis-line-item/analysis-edit-line/analysis-edit-line.component';
import { AnalysisDeleteLineComponent } from '../analysis-line-item/analysis-delete-line/analysis-delete-line.component';
import { AnalysisNewLineComponent } from '../analysis-line-item/analysis-new-line/analysis-new-line.component';
import { AnalysisTaxaccountComponent } from '../analysis-line-item/analysis-taxaccount/analysis-taxaccount.component';
import { AnalysisEditTaxaccountComponent } from '../analysis-line-item/analysis-edit-taxaccount/analysis-edit-taxaccount.component';
import { UnlinkAnalysisStatementPopupComponent } from '../unlink-analysis-statement-popup/unlink-analysis-statement-popup.component';
import { LinkAnalysisStatementPopupComponent } from '../link-analysis-statement-popup/link-analysis-statement-popup.component';
import { TaxOutcomeComponent } from '../tax-outcome/tax-outcome.component';
import { ActivatedRoute, Router } from '@angular/router';
import { first, take } from 'rxjs/operators';
import { TreeListComponent } from '@progress/kendo-angular-treelist';
import { element } from 'protractor';
import { LegendComponent } from '../legend/legend.component';
import { AnalysisReviewService } from 'src/app/common/services/analysisReview/analysis-review.service';
import { CompileNgModuleMetadata } from '@angular/compiler';
import { DeleteManageAttachementComponent } from '../delete-manage-attachement/delete-manage-attachement.component';
import { AddManageAttachmentComponent } from '../add-manage-attachment/add-manage-attachment.component';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { MoveTransactionsPopupComponent } from '../move-transactions-popup/move-transactions-popup.component';
@Component({
  selector: 'app-analysis-review-grid-popup',
  templateUrl: './analysis-review-grid-popup.component.html',
  styleUrls: ['./analysis-review-grid-popup.component.scss'],
})
export class AnalysisReviewGridPopupComponent implements OnInit {
  private currentSubscription!: Subscription;
  public data: Employee[] = employees;
  public LEOPtions: any[] = LEOPtions;
  public APoptions: any[] = APoptions;
  public SSoptions: any[] = SSOPtions;
  public ASoptions: any[] = ASoptions;
  public CLIoptions: any[] = CLIoptions;
  public SLIoptions: any[] = SLIoptions;
  public SAoptions: any[] = SAoptions;
  public subLEOPtions: any[] = subLEOPtions;
  public SS_SAoptions: any[] = SS_SAoptions;
  public SS_ASoptions: any[] = SS_ASoptions;
  public SS_LEoptions: any[] = SS_LEoptions;
  public SS_Childoptions: any[] = SS_Childoptions;
  public SS_LIOptions: any[] = SS_LIOptions;
  public ULI_Childoptions: any[] = ULI_Childoptions;
  isLoading: boolean = false;
  public selectedRow: any;
  public listindex: number = 0;
  tabs: any[] = [
    { label: 'Analysis statement', icon: 'folder-opened-outline' },
    { label: 'GL Accounts', icon: 'wallet-outline' },
    { label: 'Transactions', icon: 'change-outline' },
  ];

  activeIndex: number = 5;
  public panel: number = 50;
  public event: any;
  checked: any;
  public rootData: Entry[] = [];
  public displayRight: boolean = true;
  public toggleTaxOutcome: boolean = false;
  public lineItems: any;
  gridData: any;
  public lineItemsGridForm: FormGroup | any;
  businesstypeListDB: any;
  businesstypeList: any;
  isLoadId: any;
  sendData: any;
  public treeViewReport = 'TreeView';
  public reportType = 'Value';
  @ViewChild(TreeListComponent)
  public treelist: TreeListComponent | undefined;
  public IsResizable: boolean = true;
  selectedFiscalYear: any;
  public filename: string = '';
  targetLineid: string | null | undefined;
  draggedTransactionRows: any;
  targetText: any;
  constructor(
    private cookieService: CookieService,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private globalService: GlobalService,
    private windowService: WindowService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private analysisreviewService: AnalysisReviewService
  ) {}

  public openWindow(): void {
    const window: WindowRef = this.windowService.open({
      title: 'New Window',
      content: AnalysisReviewLayoutComponent,
      width: 1200,
    });

    window.result.subscribe((result) => {
      if (result instanceof WindowCloseResult) {
      }
    });
  }
  public ngAfterViewInit(): void {
    this.setDraggableRows();
  }
  ngOnInit(): void {
    /*retention*/
    if (localStorage.getItem('selectedTreenode')) {
      let val23: any = localStorage.getItem('selectedTreenode');
      console.log(JSON.parse(val23));
      this.selectedRow = JSON.parse(val23);
      this.activeIndex = 2;
    }
    /*retention*/
    if (localStorage.getItem('expanded')) {
      let a = localStorage.getItem('expanded')?.split(',');
      a?.forEach((ele: any) => {
        var y: number = +ele;
        this.expandedIds.push(y);
      });
    }

    if (
      this.router.url.indexOf('TaxOutComeReport') > 0 ||
      this.router.url.indexOf('ComparativeAnalysisReport') > 0
    ) {
      let reportName: any = this.router.url.split('/').pop();
      this.treeViewReport = reportName;
      this.IsResizable = true;
    } else {
      this.IsResizable = true;
    }
    this.getAnalysisReviewTreeData(this.treeViewReport);
  }
  private expandedIds: number[] = [];
  public isExpanded = (dataItem: any): boolean => {
    // console.log("expand"+this.expandedIds)

    return this.expandedIds.includes(dataItem.idNew);
  };
  public onCollapse(args: ExpandEvent): void {
    const index = this.expandedIds.indexOf(args.dataItem.idNew, 0);
    if (index > -1) {
      this.expandedIds.splice(index, 1);
    }
    args.dataItem.contents.forEach((ele: any) => {
      const index = this.expandedIds.indexOf(ele.idNew, 0);
      if (index > -1) {
        this.expandedIds.splice(index, 1);
      }
    });
    this.expandedIds = this.expandedIds.filter(
      (id) => id !== args.dataItem.idNew
    );
    localStorage.removeItem('expanded');
    localStorage.setItem('expanded', this.expandedIds + '');
  }

  public onExpand(args: ExpandEvent): void {
    // console.log("expand"+args.dataItem.id)
    this.expandedIds.push(args.dataItem.idNew);
    localStorage.removeItem('expanded');
    localStorage.setItem('expanded', this.expandedIds + '');
  }
  getlist(ele: any[]) {
    ele.forEach((ele1: any) => {
      ele1.idNew = this.i++;
      if (ele1.contents) this.getlist(ele1.contents);
    });
  }
  i = 0;
  getAnalysisReviewTreeData(reportName: string) {
    this.isLoading = true;
    this.globalService.getAnalysisReviewTreeData(reportName).then(
      (response: any) => {
        if (response != null && response != undefined && response.length > 0) {
          this.rootData = response;
          this.rootData.forEach((ele: any) => {
            ele.idNew = this.i++;
            ele.contents.forEach((ele1: any) => {
              ele1.idNew = this.i++;
              if (ele1.contents) this.getlist(ele1.contents);
            });
          });

          this.reportType = response[0].ValueType;
          if (
            this.router.url ===
              '/analysisreview/treeViewReport/TaxOutComeReport' ||
            this.router.url ===
              '/analysisreview/treeViewReport/ComparativeAnalysisReport'
          ) {
            this.fitColumns();
          }
        }
        this.isLoading = false;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  refresh() {
    localStorage.removeItem('expanded');
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }

  onSelect(menuitem: any, nodedata: any) {
    localStorage.removeItem('expanded');
    localStorage.setItem('expanded', this.expandedIds + '');
    if (menuitem.text == 'Attachments') this.openAttachmentPopup();
    if (menuitem.text == 'Move') this.openMovePopup(nodedata);
    if (menuitem.text == 'New analysis pack')
      this.openAnalysisPackPopup(nodedata);
    if (menuitem.text == 'Edit analysis pack')
      this.editAnalysisPackPopup(nodedata);
    if (menuitem.text == 'Edit analysis statement')
      this.summaryStatementPopup(nodedata, false, false, menuitem.text);
    if (menuitem.text == 'New summary statement')
      this.summaryStatementPopup(nodedata, true, true, menuitem.text);
    if (menuitem.text == 'New analysis statement')
      this.summaryStatementPopup(nodedata, true, false, menuitem.text);
    if (menuitem.text == 'Edit Summary statement')
      this.summaryStatementPopup(nodedata, false, true, menuitem.text);
    if (menuitem.text == 'Delete analysis statement')
      this.deleteStatementPopup(nodedata);
    if (menuitem.text == 'Link line item') this.linkLineitemPopup(nodedata);
    if (menuitem.text == 'Unlink line item') this.UnlinkLineitemPopup(nodedata);
    if (menuitem.text == 'Delete analysis pack')
      this.deleteAnalysisPackPopup(nodedata);
    if (menuitem.text == 'Edit line item') this.editlineItemPopup(nodedata);
    if (menuitem.text == 'Delete line item') this.deletelineItemPopup(nodedata);
    if (menuitem.text == 'New line item')
      this.newlineItemPopup(
        nodedata,
        false,
        false,
        menuitem.text,
        this.rootData
      );
    if (menuitem.text == 'Edit tax account') this.openEditTaxaccount(nodedata);
    if (menuitem.text == 'Link analysis statement')
      this.linkAnalysisStatementPopup(nodedata);
    if (menuitem.text == 'Unlink analysis statement')
      this.unlinkAnalysisStatementPopup(nodedata);
    if (menuitem.text == 'Offline allocation')
      this.getTaxAccountByLineItem(nodedata.id, nodedata.LegalEntityID);
    if (menuitem.text == 'Remove attachment')
      this.deleteAttachmentPopup(nodedata.SupplimentaryID);
    if (menuitem.text == 'Add attachment') this.AddAttachmentPopup(nodedata);
  }
  getTaxAccountByLineItem(id: any, legalEntityId: number) {
    this.analysisreviewService
      .getTaxAccountByLineItem(id)
      .subscribe((res: any) => {
        if (res) {
          // console.log(res)
          // console.log(res[0].TaxAccountCodeId)
          let year: any = localStorage.getItem('selectedFiscalYear');
          this.selectedFiscalYear = JSON.parse(year).FiscalYearId;
          let fp = '/offlineReferralBreakdownLevel/AnalyseReview/';
          // console.log(this.selectedFiscalYear)
          let sp = legalEntityId;
          let tp = res[0].TaxAccountCodeId;
          let urlRoute = fp + this.selectedFiscalYear + '/' + sp + '/' + tp;
          this.router.navigate([urlRoute]);
        }
      });
  }

  // Use an arrow function to capture the 'this' execution context of the class.
  public fetchChildren = (item: Entry): Entry[] => {
    if (item.type == 'SA') {
      var items = item.contents.filter((a) => a.type == 'AS' || a.type == 'SS');
      items.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
      var items1 = item.contents.filter(
        (a) => a.type == 'LI' || a.type == 'CLI'
      );
      items1.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
      item.contents = items;
      item.contents = item.contents.concat(items1);
    } else {
      item.contents.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    }
    if (item.type == 'SS') {
      for (let i = 0; i < item.contents.length; i++) {
        item.contents[i].summarySubAnalysis = true;
      }
    } else if (item.summarySubAnalysis == true && item.type == 'SA') {
      for (let i = 0; i < item.contents.length; i++) {
        item.contents[i].parentStatementAnalysis = true;
        item.contents[i].parentStatementID = item.Satementid;
        item.contents[i].isSummary = true;
      }
    } else if (item.parentStatementAnalysis == true && item.type == 'AS') {
      for (let i = 0; i < item.contents.length; i++) {
        item.contents[i].isSummary = true;
      }
    } else if (item.isSummary == true) {
      for (let i = 0; i < item.contents.length; i++) {
        item.contents[i].isSummary = true;
      }
    }
    return item.contents;
  };

  public hasChildren = (item: Entry): boolean => {
    return item.contents && item.contents.length > 0;
  };
  togglePanel() {
    this.displayRight = !this.displayRight;
    if (this.displayRight == true) {
      this.setindex(1);
    } else {
      this.setindex(0);
    }
  }
  openMovePopup(nodedata: any): void {
    const dialogRef = this.dialogService.open({
      content: MoveSubMenuPopupComponent,
      width: 400,
      height: 'auto',
    });
    dialogRef.result.subscribe((r: any) => {});
    const movepopup = dialogRef.content.instance as MoveSubMenuPopupComponent;
    movepopup.nodeData = nodedata;
    const moveLineItem = dialogRef.content
      .instance as MoveSubMenuPopupComponent;
    moveLineItem.lineItemID = nodedata;
  }
  openAttachmentPopup(): void {
    const dialogRef = this.dialogService.open({
      content: AttachmentSubMenuPopupComponent,
      width: 400,
      height: 300,
    });
  }
  deleteAnalysisPackPopup(node: any): void {
    const dialogRef = this.dialogService.open({
      content: DeleteAnalysisPackPopupComponent,
      width: 500,
      height: 'auto',
    });
    const deleteAPPopup = dialogRef.content
      .instance as DeleteAnalysisPackPopupComponent;

    deleteAPPopup.node = node;
    deleteAPPopup.delAnalysisPackdata = node;
  }
  openAnalysisPackPopup(nodedata: any): void {
    const dialogRef = this.dialogService.open({
      content: AnalysisPackPopupComponent,
      width: 700,
      height: 460,
    });
    dialogRef.result.subscribe((r: any) => {});
    const LEDetails = dialogRef.content.instance as AnalysisPackPopupComponent;
    dialogRef.result.subscribe((r: any) => {});
    LEDetails.nodeData = nodedata;
  }
  editAnalysisPackPopup(nodedata: any): void {
    const dialogRef = this.dialogService.open({
      content: EditAnalysisPackComponent,
      width: 700,
      height: 460,
    });
    dialogRef.result.subscribe((r: any) => {});
    const LEDetails = dialogRef.content.instance as EditAnalysisPackComponent;
    dialogRef.result.subscribe((r: any) => {});
    LEDetails.nodeData = nodedata;
    //LEDetails.isEdit = true;
  }
  summaryStatementPopup(
    node: any,
    isNew: boolean,
    isSummary: boolean,
    menuText: string
  ): void {
    const dialogRef = this.dialogService.open({
      content: SummaryStatementComponent,
      width: 700,
      height: 'auto',
    });

    const analysisStatPopup = dialogRef.content
      .instance as SummaryStatementComponent;
    analysisStatPopup.node = node;
    analysisStatPopup.isNew = isNew;
    analysisStatPopup.isSummary = isSummary;
    analysisStatPopup.popupCaption = menuText;
  }

  deleteStatementPopup(node: any): void {
    const dialogRef = this.dialogService.open({
      content: DeleteStatementComponent,
      width: 500,
      height: 'auto',
    });

    const deleteStatPopup = dialogRef.content
      .instance as DeleteStatementComponent;
    deleteStatPopup.statement = node.name;
    deleteStatPopup.statementid = node.id;
  }

  linkLineitemPopup(node: any): void {
    const dialogRef = this.dialogService.open({
      content: LinkLineItemPopupComponent,
      width: 700,
      height: 500,
    });

    const linklineitemPopup = dialogRef.content
      .instance as LinkLineItemPopupComponent;
    linklineitemPopup.node = node;
  }

  UnlinkLineitemPopup(node: any): void {
    const dialogRef = this.dialogService.open({
      content: UnlinkLineItemPopupComponent,
      width: 500,
      height: 'auto',
    });
    const unlinklineitemPopup = dialogRef.content
      .instance as UnlinkLineItemPopupComponent;
    unlinklineitemPopup.node = node;
  }

  newlineItemPopup(
    node: any,
    isNew: boolean,
    isSummary: boolean,
    menuText: string,
    rootData: any
  ) {
    const dialogRef = this.dialogService.open({
      content: AnalysisNewLineComponent,
      width: 700,
      height: 'auto',
    });
    dialogRef.result.subscribe((r: any) => {});
    const newlineItemPopup = dialogRef.content
      .instance as AnalysisNewLineComponent;
    newlineItemPopup.nodedata = node.BusinessTypeID;
    newlineItemPopup.Satement = node.Satementid;
    newlineItemPopup.BlockId = node.id;
    newlineItemPopup.node = node;
    newlineItemPopup.isNew = isNew;
    newlineItemPopup.isSummary = isSummary;
    newlineItemPopup.popupCaption = menuText;
  }

  editlineItemPopup(node: any) {
    const dialogRef = this.dialogService.open({
      content: AnalysisEditLineComponent,
      width: 700,
      height: 'auto',
    });
    dialogRef.result.subscribe((r: any) => {});
    const editlineItemPopup = dialogRef.content
      .instance as AnalysisEditLineComponent;
    editlineItemPopup.BusinessType = node.BusinessTypeID;
    editlineItemPopup.editLineItemId = node.id;
    editlineItemPopup.nodedata = node;
    editlineItemPopup.nodedata = node.BusinessTypeID;
  }

  deletelineItemPopup(node: any) {
    const dialogRef = this.dialogService.open({
      content: AnalysisDeleteLineComponent,
      width: 500,
      height: 'auto',
    });
    const editlineItemPopup = dialogRef.content
      .instance as AnalysisDeleteLineComponent;
    editlineItemPopup.BusinessType = node.BusinessTypeID;
    editlineItemPopup.delLIdata = node.id;
  }
  linkAnalysisStatementPopup(node: any): void {
    const dialogRef = this.dialogService.open({
      content: LinkAnalysisStatementPopupComponent,
      width: 700,
      height: 500,
    });
    const linkAnalysStatementPopup = dialogRef.content
      .instance as LinkAnalysisStatementPopupComponent;
    linkAnalysStatementPopup.node = node;
  }
  unlinkAnalysisStatementPopup(node: any): void {
    const dialogRef = this.dialogService.open({
      content: UnlinkAnalysisStatementPopupComponent,
      width: 500,
      height: 200,
    });
    const unlinkAnalysStatementPopup = dialogRef.content
      .instance as UnlinkAnalysisStatementPopupComponent;
    unlinkAnalysStatementPopup.node = node;
  }

  async openEditTaxaccount(node: any) {
    let response = await this.globalService.GetTaxAccountByLineItem(node.id);

    if (response.length == 0) {
      const dialogRef = this.dialogService.open({
        content: NoLineItemComponent,
        width: 700,
        height: 200,
      });
    } else if (response.length == 1) {
      const dialogRef = this.dialogService.open({
        content: AnalysisEditTaxaccountComponent,
        width: 700,
        height: 500,
      });

      const AnalysisEditTaxaccount = dialogRef.content
        .instance as AnalysisEditTaxaccountComponent;
      dialogRef.result.subscribe((r: any) => {});
      AnalysisEditTaxaccount.gridBusinessType = node.BusinessTypeID;
      AnalysisEditTaxaccount.isCreateFromLI = true;
      AnalysisEditTaxaccount.LICreateTaxData = node;
      AnalysisEditTaxaccount.nodedata = node.BusinessTypeID;
      AnalysisEditTaxaccount.TaxAccountCodeId = response[0].TaxAccountCodeId;
    } else {
      this.AddAccountPopup(response, node);
    }
  }
  AddAccountPopup(response: any, lineItemNode: any): void {
    const dialogRef = this.dialogService.open({
      content: DisplayAccountPopupComponent,
      width: 550,
      height: 380,
    });

    const displayAccountPopup = dialogRef.content
      .instance as DisplayAccountPopupComponent;
    displayAccountPopup.data = response;
    displayAccountPopup.LineitemNode = lineItemNode;
    dialogRef.result.subscribe((node: any) => {
      if (node != 'onCancel') {
        const dialogRef = this.dialogService.open({
          content: AnalysisEditTaxaccountComponent,
          width: 1000,
          height: 500,
        });
        const newTaxAccount = dialogRef.content
          .instance as AnalysisEditTaxaccountComponent;
        newTaxAccount.gridBusinessType = lineItemNode.BusinessTypeID;
        newTaxAccount.isCreateFromLI = true;
        newTaxAccount.LICreateTaxData = lineItemNode;
        newTaxAccount.TaxAccountCodeId = node.TaxAccountCodeId;
        newTaxAccount.nodedata = node.BusinessTypeID;
      }
    });
  }

  taxOutcomeReportPopup(): void {
    // const dialogRef = this.dialogService.open({
    //   content: TaxOutcomeComponent,
    //   width: 850,
    //   height: 500,
    // });
    this.router.navigate(['/taxOutComeOptions']);
  }

  Legend(): void {
    const dialogRef = this.dialogService.open({
      content: LegendComponent,
      width: 800,
      height: 165,
    });
  }

  loadTaxoutComegrid(): void {
    this.getAnalysisReviewTreeData('TaxOutComeReport');
  }

  rowSelect(event: any) {
    this.selectedRow = event.items[0].dataItem;
    /*retention*/
    localStorage.setItem('selectedTreenode', JSON.stringify(this.selectedRow));
    //let val23: any = localStorage.getItem('selectedTreenode');
    //console.log(JSON.parse(val23));
    /*retention*/
    this.activeIndex = 2;
  }
  /*retention*/
  public isSelected(dataItem: any, column?: ColumnBase, columnIndex?: number) {
    let storedData: any = localStorage.getItem('selectedTreenode');
    var check = false;
    if (storedData != null) {
      if (JSON.stringify(dataItem) === localStorage.getItem('selectedTreenode'))
        check = true;
      else if (
        dataItem.id == JSON.parse(storedData).id &&
        dataItem.type == JSON.parse(storedData).type
      )
        check = true;
    }
    return check;
  }
  /*retention*/

  roundedPercentage(myValue: number, totalValue: number, index: number) {
    var result = myValue;
    if (index != 0 && this.reportType == 'Percentage') {
      if (totalValue == 0 || myValue == 0) {
        return 0;
      }

      return Math.round((myValue / totalValue) * 100);
    } else if (index != 0 && this.reportType == 'Movement') {
      return myValue - totalValue;
    } else if (index != 0 && this.reportType == 'PercentageValue') {
      myValue = myValue - totalValue;
      if (totalValue == 0 && myValue == 0) {
        return '0';
      }
      var number = (myValue / totalValue) * 100;
      if ((totalValue > 0 && myValue == 0) || number > 1000) {
        return '999.00';
      }
      if(number<-1000)
      {
        return '-999.00'
      }

      return number.toFixed() + '.00';
    } else {
      return result;

      return result;
    }
  }

  // public ngAfterViewChecked(): void {
  //   this.fitColumns();
  // }
  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.Highlighted == true) {
      return { highlighted: true };
    } else {
      return { highlighted: false };
    }
  };
  public fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.treelist?.autoFitColumns();
      });
  }
  public setindex(i: any): void {
    this.listindex = i;
  }
  public diableSplitpanel(): void {
    this.displayRight = false;
  }
  public handleTaxOutcome(event: MenuSelectEvent): void {
    if (event.item.text == 'Options') {
      this.taxOutcomeReportPopup();
      this.setindex(5);
      //this.diableSplitpanel()
    }
    if (event.item.text == 'Toggle') {
      // this.toggleTaxOutcome = !this.toggleTaxOutcome
      this.toggleTax();
    }
  }
  public toggleTax(): void {
    if (this.router.url === '/analysisreview') {
      this.router.navigate([
        '/analysisreview/treeViewReport/',
        'TaxOutComeReport',
      ]);
    } else if (
      this.router.url === '/analysisreview/treeViewReport/TaxOutComeReport'
    ) {
      this.router.navigate(['/analysisreview']);
    }
  }
  public handleComparartiveAnalysis(event: MenuSelectEvent): void {
    if (event.item.text == 'Options') this.loadTaxoutComegrid();
    this.setindex(4);
    //this.diableSplitpanel()
  }

  public AddColorFormat(textData: any) {
    if (textData.indexOf(')') > 0 || textData.indexOf('-') != -1) {
      return 'color: red; text-align: right; padding: 0px';
    } else {
      return 'text-align: right; padding: 0px';
    }
  }

  getSupplimentaryFileName(suplimentaryID: number) {
    this.isLoading = true;
    this.globalService.downloadSupplimentaryViewExcel(suplimentaryID).subscribe(
      (response: any) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const fileName = 'SupplementaryExport_' + timestamp;
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.isLoading = false;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  getSupplimentaryExcel(suplimentaryID: number) {
    this.globalService
      .GetSupplimentaryFileName(suplimentaryID)
      .then((res) => {
        if (res != null && res != undefined) {
          this.filename = res.ExportFileName;
        } else {
          this.filename = 'Attachment.xlsx';
        }
        this.getSupplimentaryFileName(suplimentaryID);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  deleteAttachmentPopup(suplimentaryID: number) {
    if (
      suplimentaryID != 0 &&
      suplimentaryID != null &&
      suplimentaryID != undefined
    ) {
      const dialogRef = this.dialogService.open({
        content: DeleteManageAttachementComponent,
        width: 500,
        height: 'auto',
      });

      let year: any = localStorage.getItem('selectedFiscalYear');
      this.selectedFiscalYear = JSON.parse(year).FiscalYearId;
      const deleteManageAttachment = dialogRef.content
        .instance as DeleteManageAttachementComponent;
      deleteManageAttachment.suppIds = suplimentaryID;
      deleteManageAttachment.fiscalYear = this.selectedFiscalYear;
    }
  }

  AddAttachmentPopup(node: any) {
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.selectedFiscalYear = JSON.parse(year).FiscalYearId;
    const dialogRef = this.dialogService.open({
      content: AddManageAttachmentComponent,
      width: 500,
      height: 'auto',
    });
    const addManageAttachment = dialogRef.content
      .instance as AddManageAttachmentComponent;
    addManageAttachment.lineItemID = node.id;
    addManageAttachment.SupplementaryID = node.SupplimentaryID;
    addManageAttachment.fiscalYear = this.selectedFiscalYear;
  }
  public setDraggableRows(): void {
    this.currentSubscription = this.handleDragAndDrop();
    const tableRows: HTMLTableRowElement[] = Array.from(
      document.querySelectorAll('.k-grid-content .k-grid-table-wrap tbody tr')
    );
    console.log(tableRows[1]);
    // tableRows.forEach((row) => {
    //   this.renderer.setAttribute(row, 'draggable', 'true');
    // });
  }
  private handleDragAndDrop(): Subscription {
    const table: HTMLElement[] = Array.from(
      document.querySelectorAll('.k-grid-content .k-grid-table-wrap tbody')
    );
    console.log(table, 'table');
    const sub = new Subscription(() => {});
    const dragStart: Observable<DragEvent> = fromEvent<DragEvent>(
      table,
      'dragstart'
    );
    const dragOver: Observable<DragEvent> = fromEvent<DragEvent>(
      table,
      'dragover'
    );
    const dragEnd: Observable<DragEvent> = fromEvent<DragEvent>(
      table,
      'dragend'
    );
    const drop: Observable<DragEvent> = fromEvent<DragEvent>(table, 'drop');

    sub.add(
      dragStart.subscribe((e: DragEvent) => {
        // this.draggedRowEl = <HTMLTableRowElement>e.target;
        // if (this.draggedRowEl.tagName === 'TR') {
        //     this.draggedItem = findDataItem(this.data, this.draggedRowEl);
        // }
        console.log(e);
      })
    );

    sub.add(
      dragOver.subscribe((e: any) => {
        e.stopPropagation();
        e.preventDefault();
        // const element: HTMLElement = <HTMLElement>e.target;
        // console.log(element);
        //e.dataTransfer!.dropEffect = 'none';
        console.log(e.target.innerHTML);
        if (!e.target.innerHTML.includes('icon-paper-outline')) {
          e.dataTransfer!.dropEffect = 'none';
        }
      })
    );

    sub.add(
      dragEnd.subscribe((e: DragEvent) => {
        console.log(e);
        e.preventDefault();

        // removeDropHint(this.draggedRowEl);

        // if (this.draggedItem.id !== this.targetedItem.id && !this.isParentDragged) {
        //     this.draggedItem.managerId = this.newManagerId;
        //     this.zone.run(() => (this.data = [...this.data]));
        // }
      })
    );
    sub.add(
      drop.subscribe((e: any) => {
        console.log(e);
        let data = e.dataTransfer!.getData('list');
        this.draggedTransactionRows = JSON.parse(data);
        console.log(JSON.parse(data));
        const element: HTMLElement = <HTMLElement>e.target;
        console.log(
          Number(element.querySelector('[data-target]')?.classList[0])
        );
        console.log(
          Number(
            element.querySelector('[data-target]')?.getAttribute('data-target')
          )
        );
        this.targetLineid = element
          .querySelector('[data-target]')
          ?.getAttribute('data-target');
        e.preventDefault();
        this.targetText = e.target.innerText;
        this.openMoveTransactionConfirmPopup();
        // e.dataTransfer!.dropEffect = 'none';
        // if (e.target.innerHTML.includes('icon-folder-opened-fill')) {
        //   e.dataTransfer!.dropEffect = 'none';
        // }
      })
    );
    return sub;
  }
  openMoveTransactionConfirmPopup(): void {
    const dialogRef = this.dialogService.open({
      content: MoveTransactionsPopupComponent,
      width: 400,
      height: 'auto',
    });
    dialogRef.result.subscribe((r: any) => {});
    const moveTranspopup = dialogRef.content
      .instance as MoveTransactionsPopupComponent;
    moveTranspopup.transactionsList = this.draggedTransactionRows;
    moveTranspopup.targetLineItemDescription = this.targetText;
    moveTranspopup.dropLineItemId = this.targetLineid;
  }
}
