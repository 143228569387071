import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-attachment-sub-menu-popup',
  templateUrl: './attachment-sub-menu-popup.component.html',
  styleUrls: ['./attachment-sub-menu-popup.component.scss']
})
export class AttachmentSubMenuPopupComponent implements OnInit {

  public form: FormGroup ;
  public dataform: { [Key: string]: string } = {
    confirmation: "",
    
  };
  constructor(public dialog: DialogRef,) {
    
    this.form = new FormGroup({
      confirmation: new FormControl(this.dataform.confirmation, [
        Validators.required,
      ]),
      
    });
   }

  ngOnInit(): void {
  }
  cancelClick() {
    this.dialog.close();
  }
  closeButtonClick() {
    this.dialog.close();
  }
  

}
