<div class="header">Import transactions</div>

<mat-card class="userCard" style="padding-top: 10px">
  <!-- Trail balance navbar here -->
  <div class="transactionsImport">
    <div class="container2">
      <div class="row">
        <div class="col-12">
          <ap-progress-stepper-set
            [orientation]="orientation"
            [(activeIndex)]="activeIndex"
            [space]="space"
            [readonly]="readonly"
            (activeIndexChange)="onActiveIndexChange($event)"
          >
            <ap-progress-stepper
              *ngFor="let tab of steps; let i = index"
              [label]="tab.label"
            >
              <!-- Set the content of every stepper panel here -->
            </ap-progress-stepper>
            <div *ngIf="isLoading">
              <kendo-chunkprogressbar
                [chunkCount]="10"
                [min]="-10"
                [max]="10"
                [indeterminate]="true"
                [value]="30"
                style="width: 100%"
              ></kendo-chunkprogressbar>
            </div>
          </ap-progress-stepper-set>
        </div>
      </div>
    </div>

    <!-- Import wizard content here -->
    <div
      class="card"
      *ngIf="activeIndex == 0"
      style="height: calc(100vh - 250px)"
    >
      <div class="card-body card_body">
        <div class="" style="font-size: 35px">
          <div class="">
            <div class="ap-container" style="padding: 0px">
              <div class="title1" style="margin-bottom: 0px">
                <h5 style="text-align: left">
                  Welcome to the transactions import wizard
                </h5>
                <!-- <p>With supporting text below as a natural lead-in to additional content.</p> -->
                <p style="margin-bottom: 10px">
                  The transaction import wizard allows you to import GL account
                  data extracted from your accounting system.
                </p>
                <!-- <p style="margin-bottom: 10px">
                  You can import the information from one of the following
                  sources: Microsoft Excel.
                </p> -->
                <p style="margin-bottom: 10px">
                  Click select files to import data.
                </p>

                <div class="card">
                  <div
                    class="card-body card_body"
                    style="padding: 10px 0px; height: calc(100vh - 420px)"
                  >
                    <div class="ap-container">
                      <div class="row">
                        <div class="col-4">
                          <div class="title">
                            <p style="margin-bottom: 10px">
                              Select the file to import the data from
                            </p>
                          </div>
                          <div class="col-8"></div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-1 Appkit4-iconFirst">
                          <span
                            class="Appkit4-icon icon-folder-opened-outline"
                          ></span>
                        </div>
                        <div
                          class="col-9 input-class uploadfile-css"
                          style="width: 91%; padding: 10px"
                        >
                          <kendo-upload
                            [saveUrl]="uploadSaveUrl"
                            [chunkable]="chunkSettings"
                            (upload)="uploadEventHandler($event)"
                            [withCredentials]="withCredential"
                            [restrictions]="myRestrictions"
                            [multiple]="false"
                            (success)="success($event)"
                          >
                          </kendo-upload>
                          <div *ngIf="fileName" class="displayfile">
                            <div>Uploaded file : {{ this.fileName }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Select worksheet content here -->
    <div
      class="card"
      *ngIf="activeIndex == 1"
      style="height: calc(100vh - 250px)"
    >
      <div class="card-body card_body">
        <div
          class="ap-container app-content"
          style="padding: 0px; margin-top: 10px"
        >
          <div class="row">
            <div class="col-12">
              <div class="title1">
                <p style="margin-bottom: 10px">
                  Select one or more sheets from the list below to import the
                  data
                </p>
              </div>
            </div>
            <div class="col-12">
              <div
                class="card"
                style="
                  height: calc(100vh - 370px);
                  overflow-y: scroll;
                  overflow-x: hidden;
                "
              >
                <div class="card-body title1">
                  <ul style="padding-left: 0px">
                    <li
                      *ngFor="let item of sheetslist1"
                      [ngStyle]="{ 'list-style-type': 'none' }"
                    >
                      <ap-checkbox
                        (onChange)="onCheckboxchange($event)"
                        [(ngModel)]="item.selected"
                        [disabled]="checkboxDisabled"
                        [indeterminate]="tempState"
                      >
                        <span *ngIf="showCheckboxLabel">{{
                          item.sheetname
                        }}</span>
                      </ap-checkbox>
                    </li>
                  </ul>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <div class="reversal" style="margin-top: 5px">
            <p>
              <input
                type="checkbox"
                [(ngModel)]="reversalCheckboxSelected"
                #CreateTaxAccount
                kendoCheckBox
              />
              Create reversal for transactions imported
            </p>
          </div>

          <!-- <ap-checkbox [(ngModel)]="reversalCheckboxSelected" [indeterminate]="false">
            <span >Create reversal for transactions imported</span>
          </ap-checkbox> -->
        </div>
      </div>
    </div>

    <!-- DataValidation content here -->
    <div
      class="card"
      *ngIf="activeIndex == 2"
      style="height: calc(100vh - 250px)"
    >
      <div class="card-body card_body">
        <div class="ap-container" style="font-size: 35px">
          <div class="row">
            <div class="col">
              <div class="Pre-processing">
                <p style="margin-bottom: 10px">
                  Pre-processing selected file for import data
                </p>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="!isLoading">
            <div class="col">
              <div
                class="card"
                style="text-align: center; height: calc(100vh - 370px)"
              >
                <div
                  class="card-header Pre-processing"
                  *ngIf="isProcessingSuccess"
                >
                  <span
                    class="Appkit4-icon icon-circle-checkmark-outline"
                    style="color: green; font-size: 24px; margin-right: 5px"
                  ></span>
                  <b style="color: green"> Validation succeed </b>
                </div>
                <div
                  class="card-header Pre-processing"
                  *ngIf="!isProcessingSuccess"
                >
                  <span
                    class="Appkit4-icon icon-circle-warning-outline"
                    style="font-size: 24px; color: red; margin-right: 10px"
                  ></span>
                  <b style="color: red">Validation failed</b>
                </div>
                <div
                  class="card-body Pre-processing"
                  *ngIf="isProcessingSuccess"
                >
                  <p class="card-text">
                    Validation Success.Click next to continue.
                  </p>
                </div>
                <div
                  class="card-body Pre-processing"
                  *ngIf="!isProcessingSuccess"
                >
                  <p class="card-text">
                    Validation failed: {{ failureMessage }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Data Column Validation content here -->
    <div
      class="card"
      *ngIf="activeIndex == 3"
      style="height: calc(100vh - 200px)"
    >
      <div class="card-body" style="background-color: #f2f2f2">
        <div
          class="ap-container"
          style="font-size: 35px; padding-left: 10px; padding-right: 10px"
        >
          <div>
            <!-- <button
              (click)="open()"
              style="border-radius: 5px; padding: 4px 8px; float: right"
              class="Appkit4-icon icon-delete-outline"
              ap-tooltip
              [tooltipId]="'tooltipDesc'"
              ariaDescribedby="tooltipDelete"
              direction="top"
              [tooltipContent]="'Delete'"
            ></button> -->
            <button
              kendoButton
              (click)="open()"
              kendoTooltip
              title="Delete"
              style="float: right; margin-left: 5px"
            >
              <span class="Appkit4-icon icon-delete-outline"></span>
            </button>
            <!-- <button
              (click)="clearAll()"
              style="
                border-radius: 5px;
                padding: 4px 8px;
                float: right;
                margin-right: 15px;
              "
              ap-tooltip
              [tooltipId]="'tooltipClear'"
              ariaDescribedby="tooltipClear"
              direction="top"
              [tooltipContent]="'Clear all mapping'"
              class="Appkit4-icon icon-circle-delete-outline"
            ></button> -->
            <button
              kendoButton
              (click)="clearAll()"
              kendoTooltip
              title="Clear all mapping"
              style="float: right"
            >
              <span class="Appkit4-icon icon-close-outline"></span>
            </button>
          </div>
          <div class="row" style="display: flex">
            <div class="col-6" style="padding-left: 0px">
              <b><span style="font-size: 14px">Source data columns</span></b>
            </div>
            <div class="col-5" style="margin-left: 40px">
              <b
                ><span style="font-size: 14px"
                  >Transaction data columns</span
                ></b
              >
            </div>
          </div>
          <div class="row" style="display: flex">
            <div
              class="col-6"
              style="
                border: 1px solid rgba(0, 0, 0, 0.08);
                height: calc(100vh - 370px);
                overflow-y: scroll;
                padding-left: 0px;
              "
            >
              <kendo-treeview
                class="treeView1"
                #sourceTree
                [dropZoneTreeViews]="[destinationTree]"
                kendoTreeViewHierarchyBinding
                (nodeDrop)="handleDrop($event)"
                kendoTreeViewDragAndDrop
                emptyItemPlaceholder
                kendoTreeViewExpandable
                [expandedKeys]="[1]"
                [nodes]="sourceColumns"
                expandBy="id"
                style="margin-top: 2px; margin-bottom: 2px"
                ><hr />
                <ng-template
                  kendoTreeViewNodeTemplate
                  let-dataItem
                  let-index="index"
                >
                  <span
                    class="Appkit4-icon icon-folder-opened-outline"
                    *ngIf="dataItem.type == 'folder'"
                    style="color: #d04a02"
                  ></span>
                  <span
                    class="Appkit4-icon icon-data-outline"
                    *ngIf="dataItem.dataTypeStringValue == 'StringDataType'"
                    style="color: #d04a02"
                  ></span>
                  <span
                    class="Appkit4-icon icon-calculator-outline"
                    *ngIf="
                      dataItem.dataTypeStringValue == 'IntegerDataType' ||
                      dataItem.dataTypeStringValue == 'DecimalDataType' ||
                      dataItem.dataTypeStringValue == 'DoubleDataType'
                    "
                    style="color: #d04a02"
                  ></span>
                  <span
                    class="Appkit4-icon icon-calendar-outline"
                    *ngIf="dataItem.dataTypeStringValue == 'DateTimeDataType'"
                    style="color: #d04a02"
                  ></span>
                  <span
                    class="Appkit4-icon icon-help-question-outline"
                    *ngIf="dataItem.dataTypeStringValue == 'ObjectDataType'"
                    style="color: #d04a02"
                  ></span>
                  {{ dataItem.name }}
                </ng-template>
              </kendo-treeview>
            </div>

            <div
              class="col-6"
              style="
                border: 1px solid rgba(0, 0, 0, 0.08);
                height: calc(100vh - 370px);
                overflow-y: scroll;
                margin-left: 10px;
                width: 49%;
              "
            >
              <kendo-treeview
                class="treeView2"
                #destinationTree
                [nodes]="destinationFields"
                expandBy="id"
                kendoTreeViewDragAndDrop
                kendoTreeViewDragAndDropEditing
                textField="name"
                (nodeDrop)="handleDrop($event)"
                [(expandedKeys)]="singleExpand"
                kendoTreeViewHierarchyBinding
                childrenField="mapping"
                kendoTreeViewExpandable
                style="margin-top: 2px; margin-bottom: 2px"
                ><hr />
                <ng-template
                  kendoTreeViewNodeTemplate
                  let-dataItem
                  let-index="index"
                >
                  <input
                    kendoCheckBox
                    type="checkbox"
                    *ngIf="dataItem.sampleData != undefined"
                    (change)="onChange($event, index)"
                    [checked]="isChecked(index)"
                  />
                  <span
                    class="Appkit4-icon icon-folder-opened-outline"
                    *ngIf="dataItem.type == 'folder'"
                    style="color: #d04a02"
                  ></span>
                  <span
                    class="Appkit4-icon icon-data-outline"
                    *ngIf="dataItem.dataTypeStringValue == 'StringDataType'"
                    style="color: #d04a02"
                  ></span>
                  <span
                    class="Appkit4-icon icon-calculator-outline"
                    *ngIf="
                      dataItem.dataTypeStringValue == 'IntegerDataType' ||
                      dataItem.dataTypeStringValue == 'DecimalDataType' ||
                      dataItem.dataTypeStringValue == 'DoubleDataType'
                    "
                    style="color: #d04a02"
                  ></span>
                  <span
                    class="Appkit4-icon icon-calendar-outline"
                    *ngIf="dataItem.dataTypeStringValue == 'DateTimeDataType'"
                    style="color: #d04a02"
                  ></span>
                  <span
                    class="Appkit4-icon icon-help-question-outline"
                    *ngIf="dataItem.dataTypeStringValue == 'ObjectDataType'"
                    style="color: #d04a02"
                  ></span>
                  {{
                    dataItem.displayName ? dataItem.displayName : dataItem.name
                  }}
                  <span *ngIf="dataItem.mandatory == true" style="color: red"
                    >*</span
                  >
                </ng-template>
              </kendo-treeview>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Last Import data content here -->
    <div
      class="card"
      *ngIf="activeIndex == 4"
      style="height: calc(100vh - 250px)"
    >
      <div class="card-body card_body">
        <div class="ap-container app-content" style="padding: 0px">
          <div class="row">
            <div class="col-12">
              <div
                class="title1"
                *ngIf="
                  !isLoading &&
                  isImportSuccess &&
                  afterImportDetails.importProcessingErrorMessage !=
                    'Internal server error'
                "
              >
                <b style="font-size: 14px">Import process complete</b>
                <!-- <p>Transactions have been imported.</p> -->
              </div>
            </div>
            <div class="col-12" style="padding: 0px">
              <div class="card">
                <div class="card-body title1">
                  <p
                    class="summaryDetail"
                    style="margin-bottom: 0px; white-space: pre-line"
                  >
                    {{ importOutputSummaryDetail }}
                  </p>
                  <p
                    *ngIf="
                      !isLoading &&
                      afterImportDetails.importProcessingErrorMessage ==
                      'Internal server error'
                    "
                  >
                    Import failed .Try with another file.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12" style="padding: 0px">
              <div class="card">
                <div
                  class="card-body title1"
                  style="
                    padding: 20px 0px;
                    height: calc(100vh - 500px);
                    display: flex;
                  "
                >
                  <div style="margin-right: 10px">
                    <p
                      *ngIf="
                        isImportSuccess &&
                        afterImportDetails.importReportTextFileName != null
                      "
                    >
                      <ap-button
                        style="margin-left: 10px"
                        type="button"
                        [btnType]="'primary'"
                        [label]="'Download summary'"
                        (onClick)="getImportTextFileReport()"
                      >
                      </ap-button
                      ><br /><span style="margin-left: 20px"
                        >Click the button to download</span
                      >
                      <br /><span style="margin-left: 20px; margin-top: 10px">
                        summary report</span
                      >
                    </p>
                  </div>
                  <div>
                    <p *ngIf="isErrorAvailable">
                      <ap-button
                        type="button"
                        [btnType]="'primary'"
                        [label]="'Download error report'"
                        (onClick)="downloadErrorExcel()"
                      >
                      </ap-button
                      ><br /><span
                        >Click the button to download<br />
                        error report</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fixed-bottom">
      <div class="row">
        <div class="card" style="border: none">
          <div class="card-body">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <ap-button
                type="button"
                [btnType]="'secondary'"
                [label]="'Back'"
                [disabled]="isBackDisabled"
                style="width: 100px"
                (onClick)="handleBackButton()"
              >
              </ap-button>
              <ap-button
                type="button"
                [btnType]="'primary'"
                [label]="'Next'"
                class="okButton"
                [disabled]="isNextDisabled"
                (onClick)="handleNextButton()"
              >
              </ap-button>
              <ap-button
                *ngIf="activeIndex == 4 && !this.isLoading"
                type="button"
                [btnType]="'primary'"
                [label]="'Finish'"
                class="okButton"
                (click)="finish()"
              >
              </ap-button>
              <ap-button
                *ngIf="activeIndex != 4"
                type="button"
                [btnType]="'secondary'"
                style="width: 100px"
                [disabled]="isCancelDisabled"
                [label]="'Cancel'"
                (click)="handleCancelButton()"
              >
              </ap-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog
    title="Cancel wizard"
    *ngIf="isConfirmOpen"
    (close)="close('no')"
    [minWidth]="250"
    [width]="500"
  >
    <div style="display: flex">
      <p>
        <span
          class="Appkit4-icon icon-circle-warning-outline"
          style="font-size: 24px; color: #d04a02"
        ></span>
      </p>
      <p style="margin-left: 8px; font-size: medium">
        Are you sure you wish to cancel the import wizard?
      </p>
    </div>

    <div class="clickToActions" style="padding: 10px 0px 0px 0px">
      <ap-button
        [btnType]="'secondary'"
        [label]="'No'"
        (click)="close('no')"
        style="width: 100px"
      ></ap-button>
      <ap-button
        [btnType]="'primary'"
        [label]="'Yes'"
        themeColor="primary"
        (click)="close('yes')"
        class="okButton"
        style="margin-right: 6px"
      ></ap-button>
    </div>
  </kendo-dialog>

  <kendo-dialog
    title="Import wizard"
    *ngIf="!this.isLoading && this.isImportValidationSuccess && this.isGlAccountFiscalyearDataAvailable"
    (close)="continueImport('no')"
    [minWidth]="250"
    [width]="500"
  >
    <div style="display: flex">
      <p>
        <span
          class="Appkit4-icon icon-circle-warning-outline"
          style="font-size: 24px; color: #d04a02"
        ></span>
      </p>
      <p style="margin-left: 8px; font-size: medium;">
        Transaction data has already been imported for the following GL accounts and fiscal year.
        <span *ngFor="let obj of fiscalYrGLAcctDetail" >
          <br/>
          <br/>
          <span>Fiscal Year: {{obj.fiscalYear}}</span>
          <br/>
          <span>GL accounts: {{obj.glAccounts}}</span>
        </span>
        <br />
        <br />
        Do you wish to continue with the import?
      </p>
    </div>

    <div class="clickToActions" style="padding: 10px 0px 0px 0px">
      <ap-button
        [btnType]="'secondary'"
        [label]="'No'"
        (click)="continueImport('no')"
        style="width: 100px"
      ></ap-button>
      <ap-button
        [btnType]="'primary'"
        [label]="'Yes'"
        themeColor="primary"
        (click)="continueImport('yes')"
        class="okButton"
        style="margin-right: 6px"
      ></ap-button>
    </div>
  </kendo-dialog>
</mat-card>
