import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import {
  GroupDescriptor,
  GroupResult,
  groupBy,
} from '@progress/kendo-data-query';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { ShowDetailsService } from 'src/app/common/services/showDetails/show-details.service';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-analysis-statement',
  templateUrl: './analysis-statement.component.html',
  styleUrls: ['./analysis-statement.component.scss'],
})
export class AnalysisStatementComponent implements OnInit {
  @Input() selectedRow: any;
  public levelType: string = '';
  public isLoading: boolean = false;
  public gridView: any;
  public gridFooterData: any;
  public rowData: any;
  public statementId: any;
  public columnHeaders: any = [];
  public gridData: any = [];
  public groups: GroupDescriptor[] = [{ field: 'BlockId' }];
  public isGridFooterDisplay: boolean = false;
  uniqueId: string = '';
  constructor(
    private showDetailsService: ShowDetailsService,
    public myGlobalObject: MyGlobalObject
  ) {}

  ngOnInit(): void {
    this.assignData();
  }
  assignData() {
    if (this.selectedRow != undefined) {
      this.rowData = this.selectedRow;
      this.levelType = this.selectedRow.type;
      this.uniqueId = uuidv4();
      if (this.selectedRow.type == 'AS' || this.selectedRow.type == 'SS')
        this.statementId = this.selectedRow.id;
      else if (
        this.selectedRow.type == 'SA' ||
        this.selectedRow.type == 'LI' ||
        this.selectedRow.type == 'CLI'
      )
        this.statementId = this.selectedRow.Satementid;
      if (this.rowData.name != 'Unused Line Item' && this.statementId != 0) {
        if (this.uniqueId) this.loadData();
      }
    }
  }
  async loadData() {
    this.isLoading = true;
    let year: any = localStorage.getItem('selectedFiscalYear');
    await this.showDetailsService
      .getAnalysisStmtColumnHeader(this.statementId)
      .subscribe(
        (res) => {
          this.columnHeaders = res;
        },
        (err) => {
          console.log('error from api', err);
        }
      );
    await this.showDetailsService
      .getAnalysisStmtByID(
        year == 'null' || year == null ? 0 : JSON.parse(year).FiscalYearId,
        this.statementId,
        this.uniqueId
      )
      .subscribe(
        (res) => {
          this.gridData = res;

          if (res.length > 0) {
            if (
              this.gridData[0].GridFooterText != '' ||
              this.gridData[0].DisplayGridTotal == true
            ) {
              this.isGridFooterDisplay = true;
              this.gridFooterData = this.gridData[0];
              this.gridData.shift();
            } else {
              this.isGridFooterDisplay = false;
            }
            let uniqueBlockId = [
              ...new Set(this.gridData.map((item: any) => item.BlockId)),
            ].filter(function (el) {
              return el != null;
            });

            for (let i = 0; i < uniqueBlockId.length; i++) {
              let index = this.gridData.findIndex(
                (x: any) =>
                  x.BlockId == uniqueBlockId[i] && x.type == 'BlockHeader'
              );
              if (index > 0) {
                let slicedHeader = this.gridData.splice(index, 1);

                let insertIndex = this.gridData.findIndex(
                  (x: any) => x.BlockId == uniqueBlockId[i]
                );
                this.gridData.splice(insertIndex, 0, slicedHeader[0]);
              }
            }
          }
          this.gridView = groupBy(this.gridData, this.groups);
          this.isLoading = false;
        },
        (err) => {
          console.log('error from api', err);
        }
      );
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRow.previousValue != undefined) this.assignData();
  }
  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.FontStyle == 'Bold') {
      return { gold: true };
    } else {
      return { green: true };
    }
  };
  public getValues(blockId: number, column: string) {
    //console.log(column);
    let footer = '';
    let footerobj;
    if (
      column == 'Amount' ||
      column == 'Description' ||
      column == 'BalanceBroughtForward' ||
      column == 'BalanceCarriedForward' ||
      column == 'Movements'
    ) {
      footerobj = this.gridData.find(
        (x: any) => x.BlockId === blockId && x.BlockFooterText != null
      );
      //console.log(footerobj);
      if (footerobj) {
        if (column == 'Description') footer = footerobj.BlockFooterText;
        else if (column == 'Amount' && footerobj.DisplayBlockTotal)
          footer = footerobj.BlockTotalAmount;
        else if (column == 'BalanceBroughtForward')
          footer = footerobj.BalanceBroughtForward;
        else if (column == 'BalanceCarriedForward')
          footer = footerobj.BalanceCarriedForward;
        else if (column == 'Movements') footer = footerobj.Movements;
      } else footer = '';
    }

    //console.log(footerobj);
    return footer;
  }
  public getHeader(blockId: number, column: string) {
    //console.log(column);
    let header = '';
    let headerobj;

    if (
      column == 'Amount' ||
      column == 'Description' ||
      column == 'BalanceBroughtForward' ||
      column == 'BalanceCarriedForward' ||
      column == 'Movements'
    ) {
      headerobj = this.gridData.find(
        (x: any) => x.BlockId === blockId && x.type == 'BlockHeader'
      );
      if (headerobj) {
        if (column == 'Description') header = headerobj.BlockHeaderText;
        else if (column == 'Amount' && headerobj.DisplayBlockTotal)
          header = headerobj.BlockTotalAmount;
        else if (column == 'BalanceBroughtForward')
          header = headerobj.BalanceBroughtForward;
        else if (column == 'BalanceCarriedForward')
          header = headerobj.BalanceCarriedForward;
        else if (column == 'Movements') header = headerobj.Movements;
      } else header = '';
    }
    return header;
  }
  getGridFooter(columnName: string) {
    let gridFooter;
    if (columnName == 'Description')
      gridFooter = this.gridFooterData.GridFooterText;
    else if (columnName == 'Amount' && this.gridFooterData.DisplayGridTotal) {
      gridFooter = this.gridFooterData.GridTotalAmount;
    } else if (
      columnName == 'BalanceBroughtForward' ||
      columnName == 'BalanceCarriedForward' ||
      columnName == 'Movements'
    )
      gridFooter = this.gridFooterData[columnName];
    return gridFooter;
  }
  public isNumber(val: any): boolean {
    return typeof val === 'number';
  }
  public isNumberValue(val: any): any {
    let returnVal = 0;
    if (typeof val === 'number') returnVal = val;
    else returnVal = 0;
    return returnVal;
  }

  NumberFormat(value: any): any {
    let returnValue = value;
    if (value != null && value != undefined && typeof value === 'number') {
      if (
        this.myGlobalObject.clientPreferences != null &&
        this.myGlobalObject.clientPreferences != undefined
      ) {
        if (
          this.myGlobalObject.clientPreferences.CreditPositive != 'Debit' &&
          value != 0
        ) {
          if (value > 0) returnValue = -Math.abs(value);
          else returnValue = Math.abs(value);
        }
        if (
          this.myGlobalObject.clientPreferences.DisplayFormat.indexOf(')') >
            0 &&
          returnValue < 0
        ) {
          returnValue = Math.abs(returnValue);
          returnValue =
            '(' +
            returnValue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) +
            ')';
        } else {
          returnValue = returnValue.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
      } else {
        returnValue = returnValue.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    }
    return returnValue;
  }

  public Alignment(value: any) {
    let alignment = 'float: right';
    if (value != null && value != undefined && typeof value === 'number')
      alignment = alignment + 'float: right';
    return alignment;
  }

  public AddColorFormat(value: any) {
    let returnValue = value;
    let color = 'color:black';
    if (value != null && value != undefined && typeof value === 'number') {
      color = 'float:right;';
      if (
        this.myGlobalObject.clientPreferences != null &&
        this.myGlobalObject.clientPreferences != undefined
      ) {
        if (
          this.myGlobalObject.clientPreferences.CreditPositive != 'Debit' &&
          value != 0
        ) {
          if (value > 0) returnValue = -Math.abs(value);
          else returnValue = Math.abs(value);
        }
        if (returnValue < 0) {
          color = color + 'color:red';
        } else {
          color = color + 'color:black';
        }
      }
    } else {
      color = 'color:black';
    }
    return color;
  }

  public AddColorFormat1(value: any) {
    let returnValue = value;
    let color = 'color: black; font-style: normal';
    if (value != null && value != undefined && typeof value === 'number') {
      color = 'color: black; font-style: italic;float:right;';
      if (
        this.myGlobalObject.clientPreferences != null &&
        this.myGlobalObject.clientPreferences != undefined
      ) {
        if (
          this.myGlobalObject.clientPreferences.CreditPositive != 'Debit' &&
          value != 0
        ) {
          if (value > 0) returnValue = -Math.abs(Number(value));
          else returnValue = Math.abs(Number(value));
        }
        if (returnValue < 0) {
          color = 'color: red; font-style: italic;float:right';
        }
      }
    }
    return color;
  }

  public AddColorFormatHeader(value: any) {
    let returnValue = value;
    let color = 'color: black; float: right';
    if (value != null && value != undefined && typeof value === 'number') {
      color = 'color: black; float: right';
      if (
        this.myGlobalObject.clientPreferences != null &&
        this.myGlobalObject.clientPreferences != undefined
      ) {
        if (
          this.myGlobalObject.clientPreferences.CreditPositive != 'Debit' &&
          value != 0
        ) {
          if (value > 0) returnValue = -Math.abs(Number(value));
          else returnValue = Math.abs(Number(value));
        }
        if (returnValue < 0) {
          color = 'color: red; float: right';
        }
      }
    }
    return color;
  }
  exportData() {
    this.isLoading = true;
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.showDetailsService
      .downloadAnalysisStatement(
        JSON.parse(year).FiscalYearId,
        this.statementId,
        this.uniqueId
      )
      .subscribe(
        (response: any) => {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          const fileName = 'AnalysisStatementData';
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
          this.isLoading = false;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
}
