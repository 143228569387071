import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'rxjs/operators';

@Pipe({
  name: 'transformexpression',
  pure: false,
})
export class TransformexpressionPipe implements PipeTransform {
  transform(value: any, ...args: any): unknown {
    let obj: any = { logic: '', filters: [] };
    let statementString = [];
    if (value.rules) {
      obj.logic = value.condition.toUpperCase();
      let filters = value.rules;
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].condition) {
          let subobj: any = {
            logic: filters[i].condition.toUpperCase(),
            filters: [],
          };

          let subStatement = [];
          for (let j = 0; j < filters[i].rules.length; j++) {
            if (filters[i].rules[j].field && filters[i].rules[j].value) {
              let field = filters[i].rules[j].field.split('~')[1];
              let operator = filters[i].rules[j].operator.split('~')[0];
              let value;
              if (filters[i].rules[j].value.toString().includes('~')) {
                if (filters[i].rules[j].value.split('~').length < 2)
                  value = filters[i].rules[j].value;
                else value = filters[i].rules[j].value.split('~')[1];
              } else value = filters[i].rules[j].value;
              let statement;
              if (j == 0 && filters[i].rules.length == 1)
                statement = '(' + field + ' ' + operator + ' ' + value + ')';
              else if (j == 0)
                statement = '(' + field + ' ' + operator + ' ' + value;
              else if (j == filters[i].rules.length - 1)
                statement = field + ' ' + operator + ' ' + value + ')';
              else statement = field + ' ' + operator + ' ' + value;
              subStatement.push(statement);
            }
          }
          statementString.push(subStatement.join(' ' + subobj.logic + ' '));
          //obj.filters=[...obj.filters,subobj]
        } else if (filters[i].field && filters[i].value) {
          let field = filters[i].field.split('~')[1];
          let operator = filters[i].operator.split('~')[0];
          let value;
          let statement;
          if (filters[i].value.toString().includes('~')) {
            if (filters[i].value.split('~').length < 2)
              value = filters[i].value;
            else value = filters[i].value.split('~')[1];
          } else value = filters[i].value;
          statement = '(' + field + ' ' + operator + ' ' + value + ')';
          statementString.push(statement);
        } else if (
          filters[i].operator.split('~')[0] === 'Is Empty' ||
          filters[i].operator.split('~')[0] === 'Is Not Empty'
        ) {
          let field = filters[i].field.split('~')[1];
          let operator = filters[i].operator.split('~')[0];
          let statement;
          statement = '(' + field + ' ' + operator + ')';

          statementString.push(statement);
        }
      }
    }
    return statementString.join(' ' + obj.logic + ' ');
  }
}
