import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from '@appkit4/angular-components/shared';
import { ActionsLayout, DialogRef } from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { StatutoryTreatmentService } from 'src/app/common/services/statutorytreatment/statutorytreatment.service';

@Component({
  selector: 'app-create-edit-statutorytreatment',
  templateUrl: './create-edit-statutorytreatment.component.html',
  styleUrls: ['./create-edit-statutorytreatment.component.scss'],
})
export class CreateEditStatutorytreatmentComponent implements OnInit {
  statutoryTreatmentForm: any;
  @Input() data: any;
  @Input() action: any;
  @Input() public statutorytreatementEditdata: any;

  BusinessDesc: any;

  editLineItemForm: any;
  descriptionErrorMessage: any = ' Description is required';
  public actionsLayout: ActionsLayout = 'normal';

  AccountClassDescriptionList: any;
  AccountingAreaDescriptionList: any;
  requireFurtherAnalysis: boolean | any;
  AccountingAreaDescriptionListDB: any;
  selectedClient: string | any;
  BusinessTypeId: any;
  BusinessType: any;
  editLineItemId: any;
  isEdit: boolean | any;
  LIDetailsfromDb: any;
  taxOutcomeForm: any;
  mode: any;
  constructor(
    public dialog: DialogRef,
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private globalService: GlobalService,
    private statutorytreatment: StatutoryTreatmentService
  ) {
    this.statutoryTreatmentForm = this.fb.group({
      Description: '',
    });
  }

  ngOnInit(): void {
    // this.getRefData();
    this.selectedClient = localStorage.getItem('SELECTEDCLIENT');

    //console.log(this.statutoryTreatmentForm.controls['Description'])
    if (this.action == 'edit') {
      this.statutoryTreatmentForm.controls['Description'].setValue(
        this.statutorytreatementEditdata.Description
      );
      this.mode = 'Edit';
    } else if (this.action == 'new') {
      this.mode = 'New';
    }
  }

  getRefData() {
    this.globalService
      .getAllAccountClass()
      .then((res) => {
        this.AccountClassDescriptionList = res;
        this.AccountClassDescriptionList =
          this.AccountClassDescriptionList.slice();
      })
      .catch((err) => {
        console.log(err);
      });

    this.globalService
      .getParentLineItem()
      .then((res) => {
        this.AccountingAreaDescriptionListDB = res;
        this.AccountingAreaDescriptionList =
          this.AccountingAreaDescriptionListDB.slice();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  cancelClick() {
    this.dialog.close();
  }

  savestatutorytreatment() {
    if (this.action == 'edit') {
      let data = {
        Id: this.statutorytreatementEditdata.Id,
        AccountTypeID: this.statutoryTreatmentForm.controls.Description.value,
        Description: this.statutoryTreatmentForm.controls.Description.value,
      };
      this.statutorytreatment.editStatutoryTreatment(data).subscribe(
        (res: any) => {
          if (res == 'Statutory Treatment updated successfully') {
            this.toastr.successToastr(
              'Statutory Treatment updated successfuly',
              'Success'
            );
            this.dialog.close();
          } else if (res == 'Statutory Treatment Description already exists') {
            this.toastr.errorToastr(
              'Statutory Treatment Description already exists',
              'Failed'
            );
            this.dialog.close();
          } else if (res == 'Statutory Treatment is not available') {
            this.toastr.warningToastr(
              'Statutory Treatment is not available',
              'Warning!'
            );
            this.dialog.close();
          }
        },
        (error) => {
          console.log(error);
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.dialog.close();
        }
      );
    } else if (this.action == 'new') {
      let data = {
        Id: 0,
        AccountTypeID: this.statutoryTreatmentForm.controls.Description.value,
        Description: this.statutoryTreatmentForm.controls.Description.value,
      };
      this.statutorytreatment.insertStatutoryTreatment(data).subscribe(
        (res: any) => {
          if (res == 'Statutory Treatment added successfully') {
            this.toastr.successToastr(
              'Statutory Treatment added successfully',
              'Success'
            );
            this.dialog.close();
          } else if (res == 'Statutory Treatment Description already exists') {
            this.toastr.errorToastr(
              'Statutory Treatment Description already exists',
              'Failed'
            );
            this.dialog.close();
          }
        },
        (error) => {
          console.log(error);
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.dialog.close();
        }
      );
    }
  }
  omit_special_char(event: any) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }
}
