import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';

@Component({
  selector: 'app-unlink-line-item-popup',
  templateUrl: './unlink-line-item-popup.component.html',
  styleUrls: ['./unlink-line-item-popup.component.scss'],
})
export class UnlinkLineItemPopupComponent implements OnInit {
  @Input() public node: any;
  constructor(
    private globalService: GlobalService,
    private dialogService: DialogService,
    public dialog: DialogRef,
    private router: Router,
    public toastr: ToastrManager
  ) {}

  //public node:any;

  ngOnInit(): void {}

  deleteLinkLineItem() {
    let item = {
      ID: 0,
      Statement: this.node.Satementid,
      AccountingArea: this.node.id,
      BlockId: this.node.id,
      DisplayOrder: 0,
    };
    this.globalService
      .unLinklineItem(item)
      .then((res) => {
        if (res == 'Line Item Unlinked successfully') {
          this.toastr.successToastr('Line item unlinked successfully', 'Success');
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
        this.displaytreePack();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  displaytreePack() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }

  cancelClick() {
    this.dialog.close();
  }
}
