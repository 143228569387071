<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>
<div class="header" style="font-size: 20px">Master data analysis</div>
<mat-card class="userCard staticFooter" style="padding-top: 0px">
  <!-- <mat-card-title style="font-size: 18px; line-height: 1.5em;background-color: #D04A02;color: white;">
        &nbsp;Generate Statement Details Template
    </mat-card-title> -->
  <mat-card-content>
    <div>
      <form class="k-form" [formGroup]="statementDetailExportForm">
        <kendo-formfield>
          <div class="row">
            <div class="col-6" style="border: 0px solid; padding-top: 0%">
              <kendo-label
                [for]="businessTypes"
                text="Business type *"
              ></kendo-label>
              <kendo-combobox
                #businessType
                [data]="businessTypes"
                formControlName="businessType"
                textField="LookUpDescription"
                valueField="LookUpId"
                placeholder="Select business type"
                (valueChange)="handleBusinessTypeChange($event)"
                [(ngModel)]="selectedbusinessType"
                style="width: 100%"
                [kendoDropDownFilter]="filterSettings"
              >
              </kendo-combobox>
            </div>
            <div class="col-6" style="border: 0px solid">
              <kendo-label
                [for]="legalEntity"
                text="Legal entity *"
              ></kendo-label>
              <kendo-combobox
                #legalEntity
                formControlName="legalEntity"
                [data]="legalEntities"
                [(ngModel)]="selectedLegalEntity"
                style="width: 100%"
                textField="LookUpDescription"
                valueField="LookUpId"
                placeholder="Select legal entity"
                (valueChange)="handleLegalEntityChange($event)"
                [kendoDropDownFilter]="filterSettings"
              >
              </kendo-combobox>
            </div>
          </div>
        </kendo-formfield>
        <kendo-formfield>
          <div class="row">
            <div class="col-6" style="border: 0px solid">
              <kendo-label
                [for]="analysisPack"
                text="Analysis pack"
              ></kendo-label>
              <kendo-combobox
                #analysisPack
                formControlName="analysisPack"
                [data]="analysisPacks"
                [(ngModel)]="selectedAnalysisPack"
                style="width: 100%"
                textField="LookUpDescription"
                valueField="LookUpId"
                placeholder="Select analysis pack"
                (valueChange)="handleAnalysisPackChange($event)"
                [kendoDropDownFilter]="filterSettings"
              >
              </kendo-combobox>
            </div>
            <div class="col-6" style="border: 0px solid">
              <kendo-label text="Statement options"></kendo-label>
              <kendo-combobox
                #statementOptions
                formControlName="statementOptions"
                [data]="statements"
                [(ngModel)]="value"
                style="width: 100%"
                textField="LookUpDescription"
                valueField="LookUpId"
                placeholder="Select statement options"
                (valueChange)="onValueChange($event)"
                [kendoDropDownFilter]="filterSettings"
              >
              </kendo-combobox>
            </div>
          </div>
        </kendo-formfield>
      </form>

      <form class="k-form">
        <mat-card-title style="margin-top: 10px"
          >You have selected to perform master data analysis</mat-card-title
        >
        <span style="font-size: 16px"><b>Master data to report</b></span>
        <kendo-formfield style="margin-top: 2px">
          <kendo-label>
            <kendo-grid
              [kendoGridBinding]="columnData"
              [pageable]="false"
              [sortable]="false"
              style="height: 45vh; max-width: 46%"
              [kendoGridSelectBy]="selectedCallback"
              [(selectedKeys)]="mySelectionMasterData"
              [hideHeader]="true"
            >
              <kendo-grid-column
                field="DisplayName"
                title="Master Data to report"
                [headerStyle]="{
                  'font-weight': 'bold',
                  'text-align': 'left',
                  color: '#d04a02'
                }"
              ></kendo-grid-column>
            </kendo-grid>
            <div style="margin: 30px 10px 30px 10px; max-width: 4%">
              <ap-button
                kendoButton
                [btnType]="'secondary'"
                (click)="forwardMasterData()"
                style="width: 50px; height: 37px; margin-bottom: 5px"
                ><span class="material-icons"
                  >keyboard_arrow_right</span
                ></ap-button
              >
              <ap-button
                kendoButton
                [btnType]="'secondary'"
                style="width: 50px; height: 37px; margin-bottom: 5px"
                (click)="backwardMasterData()"
                ><span class="material-icons"
                  >keyboard_arrow_left</span
                ></ap-button
              >
              <ap-button
                kendoButton
                [btnType]="'secondary'"
                style="width: 50px; height: 37px; margin-bottom: 5px"
                (click)="forwardMasterDataAll()"
                ><span class="material-icons"
                  >keyboard_double_arrow_right</span
                ></ap-button
              >
              <ap-button
                kendoButton
                [btnType]="'secondary'"
                style="width: 50px; height: 37px; margin-bottom: 5px"
                (click)="backwardMasterDataAll()"
                ><span class="material-icons"
                  >keyboard_double_arrow_left</span
                ></ap-button
              >
            </div>
            <kendo-grid
              [kendoGridBinding]="rightColumn"
              [pageable]="false"
              [sortable]="false"
              [kendoGridSelectBy]="selectedCallback1"
              [(selectedKeys)]="mySelectionMasterData1"
              style="height: 45vh; max-width: 46%"
              [hideHeader]="true"
            >
              <kendo-grid-column
                [width]="50"
                field="DisplayName"
                title=""
                [headerStyle]="{
                  'font-weight': 'bold',
                  'text-align': 'left',
                  color: '#d04a02'
                }"
              ></kendo-grid-column>
            </kendo-grid>
            <div style="margin: 30px 10px 0px 10px; max-width: 3%">
              <ap-button
                kendoButton
                [btnType]="'secondary'"
                style="width: 50px; height: 37px; margin-bottom: 5px"
                (click)="onMoveUpButtonClick()"
              >
                <span class="material-icons">keyboard_arrow_up</span>
              </ap-button>
              <ap-button
                kendoButton
                (click)="onMoveDownButtonClick()"
                [btnType]="'secondary'"
                style="width: 50px; height: 37px; margin-bottom: 5px"
              >
                <span class="material-icons">keyboard_arrow_down</span>
              </ap-button>
            </div>
          </kendo-label>
        </kendo-formfield>

        <div class="row" style="margin-top: 8px">
          <span style="font-size: 16px !important">
            <b>Reporting options</b>
          </span>
          <div class="col-8" style="display: flex; margin-top: 8px">
            <div style="width: 15%">
              <ap-checkbox
                #CreateTaxAccount
                (onChange)="onGreportChange($event)"
                [disabled]="getDisableStatus1()"
              >
                <span style="font-size: 14px !important">Grouping report</span>
              </ap-checkbox>
            </div>
            <div style="width: 17%">
              <ap-checkbox
                #CreateTaxAccount
                (onChange)="onXyreportChange($event)"
                [disabled]="getDisableStatus()"
                [(ngModel)]="xyreport"
              >
                <span style="font-size: 14px">X-Y analysis report</span>
              </ap-checkbox>
            </div>
            <div style="width: 15%">
              <ap-checkbox
                #CreateTaxAccount
                (onChange)="rawChange($event)"
                [disabled]="getDisableStatusRaw()"
              >
                <span style="font-size: 14px">Raw data</span>
              </ap-checkbox>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
<div class="clickToActions btndiv" style="margin: 10px 30px">
  <ap-button
    [btnType]="'secondary'"
    [label]="'Close'"
    (click)="close()"
    style="margin-left: 7px"
    class="cancelButton okButton"
  ></ap-button>
  <!-- <ap-button [btnType]="'secondary'" [label]="'Report'" style="margin-left: 7px;"></ap-button> -->
  <ap-button
    [btnType]="'primary'"
    (click)="run()"
    [label]="'Run'"
    class="okButton"
    [disabled]="!(selectedbusinessType && selectedLegalEntity)"
  ></ap-button>
</div>
