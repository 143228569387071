import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ImportService } from 'src/app/common/services/importService/import.service';
import { GlaccountimportService } from 'src/app/common/services/importService/glaccountimport.service';
import { MenubarService } from 'src/app/common/menubarService/menubar.service';
import {
  ChunkSettings,
  FileInfo,
  FileRestrictions,
  SelectEvent,
  SuccessEvent,
  UploadEvent,
} from '@progress/kendo-angular-upload';
import {
  TreeItemDropEvent,
  DropPosition,
  TreeItem,
} from '@progress/kendo-angular-treeview';
import { Worksheet } from 'src/app/_models/worksheet';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-import-gl-account',
  templateUrl: './import-gl-account.component.html',
  styleUrls: ['./import-gl-account.component.scss'],
})
export class ImportGlAccountComponent implements OnInit {
  Complex: boolean = false;
  isLoading: boolean = false;
  isConfirmOpen: boolean = false;
  isCancelDisabled: boolean = false;
  isBackDisabled: boolean = true;
  isProcessingSuccess: boolean = false;
  reversalCheckboxSelected: boolean = true;
  isImportSuccess: boolean = false;
  isImportFailure: boolean = false;
  isErrorAvailable: boolean = false;
  processingOrchestrationId: string = '';
  public preProcessingImportData: any;
  afterImportDetails: any;
  importOutputSummaryDetail: string = '';
  failureMessage: string = '';
  uploadSaveUrl: string = '';
  Selectworksheet: boolean = false;
  baseUrl: string = '';
  apifolder: string = '';
  withCredential: boolean = false;
  private selectedClient: any;
  fileName: string = '';
  fileId: any;
  orchestrationId: string = '';
  sheetsList: Worksheet[] = [];
  sheetslist1: any = [];
  selectedsheets: string[] = [];
  isNextVisible: boolean = false;
  isNextDisabled: boolean = true;
  importstartPayload: {} = {};
  importProcessingStartPayload: any;
  public chunkSettings: ChunkSettings = {
    size: 1048576,
  };
  myRestrictions: FileRestrictions = {
    allowedExtensions: ['.xls', '.xlsx'],
  };
  gldownloadXLStartPayload: { inputFileName: any } | undefined;

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private GlaccountimportService: GlaccountimportService,
    private importService: ImportService,
    public menubarService: MenubarService
  ) {}

  ngOnInit(): void {
    this.menubarService.hide();
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);

    this.uploadSaveUrl =
      this.baseUrl +
      this.apifolder +
      '/GlAccountImport/GlAccountChunkUploadSave';
  }

  singleExpand: any[] = [];
  activeIndex: number = 0;
  orientation = 'horizontal';
  readonly = true;
  space = 190;
  steps = [
    { label: 'Import wizard' },
    { label: 'Select worksheet' },
    { label: 'Data validation' },
    { label: ' Data import' },
  ];

  onActiveIndexChange(index: number) {}
  checkboxSelected = true;
  checkboxDisabled = false;
  showCheckboxLabel = true;
  tempState = false;

  onCheckboxchange(event: any) {
    let array1 = this.sheetslist1.filter((obj: any) => obj.selected == true);

    if (array1.length > 0) this.isNextDisabled = false;
    else this.isNextDisabled = true;
  }
  handleNextButton() {
    this.isNextDisabled = true;
    this.isBackDisabled = false;
    if (this.activeIndex == 1) {
      this.preprocessingfile();
    }
    if (this.activeIndex == 2) {
      this.isBackDisabled = true;
      this.isNextDisabled = true;
      this.importGlAccountImportprocessing();
    }

    if (this.activeIndex < this.steps.length - 1)
      this.activeIndex = this.activeIndex + 1;
  }
  handleBackButton() {
    if (this.activeIndex > 0) this.activeIndex = this.activeIndex - 1;
    if (this.activeIndex < 2) this.isCancelDisabled = false;
    if (this.activeIndex == 1) this.isCancelDisabled = true;
    if (this.activeIndex == 0) this.isBackDisabled = true;
  }
  preprocessingfile() {
    this.isLoading = true;
    let array1 = this.sheetslist1.filter((obj: any) => obj.selected == true);
    this.selectedsheets = array1.map((item: any) => {
      return item.sheetname;
    });
    this.importstartPayload = {
      fileName: this.fileId + '/' + this.fileName,
      isFirstRowAsHeaderRow: true,
      sheetNames: this.selectedsheets,
      //isTbReversalNeedToCreate: this.reversalCheckboxSelected,
      clientDbName: this.selectedClient.DBName,
    };
    this.GlaccountimportService.importGLaccountPreProcessingStart(
      this.importstartPayload
    ).subscribe(
      (response: any) => {
        if (response) {
          this.orchestrationId = response.orchestrationId;
          this.getpreprocesstatus();
        }
        //this.isNextVisible = true;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  getpreprocesstatus() {
    this.GlaccountimportService.importGlaccountPreProcessingStatus(
      this.orchestrationId
    ).subscribe(
      (response: any) => {
        if (
          response.orchestrationRuntimeStatus == 'Completed' ||
          response.orchestrationRuntimeStatus == 'Failed'
        ) {
          this.isLoading = false;
          this.isNextDisabled = false;
          if (
            response.orchestrationRuntimeStatus == 'Completed' &&
            response.isPreProcessFileCompletedWithSuccess
          ) {
            this.isProcessingSuccess = true;
            this.preProcessingImportData = response;
          } else if (
            response.orchestrationRuntimeStatus == 'Failed' ||
            !response.isPreProcessFileCompletedWithSuccess
          ) {
            this.isProcessingSuccess = false;
            this.isNextDisabled = true;
            this.failureMessage = response.preProcessingValidationMessage;
          }
        } else this.getpreprocesstatus();
      },
      (error: any) => {
        console.log(error);
        return error;
      }
    );
  }
  public checkedItems: Array<String> = [];
  public checkedItems1: Array<String> = [];
  importGlAccountImportprocessing() {
    this.isLoading = true;
    this.importProcessingStartPayload = {
      fileName: this.fileId + '/' + this.fileName,
      isFirstRowAsHeaderRow: true,
      sheetNames: this.selectedsheets,
      clientDbName: this.selectedClient.DBName,
      sourceColumns: this.preProcessingImportData.sourceColumns,
    };
    this.GlaccountimportService.importGlAccountProcessingStart(
      this.importProcessingStartPayload
    ).subscribe(
      (response: any) => {
        if (response) {
          this.processingOrchestrationId = response.orchestrationId;
          this.getProcessingStatus();
        }
        //this.isNextVisible = true;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  getProcessingStatus() {
    this.GlaccountimportService.importGlAccountProcessingStatus(
      this.processingOrchestrationId
    ).subscribe(
      (response: any) => {
        if (
          response.orchestrationRuntimeStatus == 'Completed' ||
          response.orchestrationRuntimeStatus == 'Failed'
        ) {
          this.isLoading = false;

          this.afterImportDetails = response;
          this.isImportSuccess = true;
          this.importOutputSummaryDetail = response.importOutputSummaryDetail;
          if (this.afterImportDetails.isAnyErrorRows) {
            this.isErrorAvailable = true;
          }
        } else this.getProcessingStatus();
      },
      (error: any) => {
        console.log(error);
        return error;
      }
    );
  }
  finish() {
    this.menubarService.show();
    this.router.navigate(['/home']);
  }
  uploadEventHandler(e: UploadEvent) {
    let tokenValue: any = localStorage.getItem('OPENAMSESSION');
    e.headers = e.headers.append(
      'Authorization-Token',
      'Bearer ' + JSON.parse(tokenValue).access_token
    );
    e.data = {
      ClientdbName: this.selectedClient.DBName,
    };
  }
  public success(e: SuccessEvent): void {
    if (e.files.length > 0) {
      this.fileName = e.files[0].name;
      this.fileId = e.files[0].uid?.toString();

      if (e.response.body.FileUploaded == true) {
        this.getSheetNames(this.fileId, this.fileName);
      }
    }
    this.isNextVisible = true;
    this.isNextDisabled = false;
  }
  getSheetNames(fileId: string, fileName: string): void {
    this.GlaccountimportService.getSheetNamesGLaccountImport(
      fileId + '/' + fileName
    ).subscribe(
      (response: any) => {
        this.sheetslist1 = response.map((value: string) => {
          return {
            sheetname: value,
            selected: false,
          };
        });
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  getImportTextFileReport() {
    this.GlaccountimportService.ExportGlImportReportTextFile(
      this.afterImportDetails.importReportTextFileName
    ).subscribe(
      (response: any) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const fileName = this.afterImportDetails.importReportTextFileName;
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  downloadErrorExcel() {
    this.isLoading = true;

    this.gldownloadXLStartPayload = {
      inputFileName: this.afterImportDetails.inputFileName,
    };
    this.GlaccountimportService.ImportGLErrorRowExcelDownloadStart(
      this.gldownloadXLStartPayload
    ).subscribe(
      (response: any) => {
        if (response) {
          this.processingOrchestrationId = response.orchestrationId;
          this.getdownloadXLstatus(response.orchestrationId);
        }
        //this.isNextVisible = true;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  getdownloadXLstatus(instanceId: string) {
    this.GlaccountimportService.GetImportGLErrorRowExcelDownloadStatus(
      instanceId
    ).subscribe(
      (response: any) => {
        if (
          response.body.orchestrationRuntimeStatus == 'Completed' ||
          response.body.orchestrationRuntimeStatus == 'Failed'
        ) {
          if (response.body.orchestrationRuntimeStatus == 'Completed') {
            this.downloadErrorRowExcelFile(response.body);
          } else if (response.body.orchestrationRuntimeStatus == 'Failed') {
          }
        } else this.getdownloadXLstatus(instanceId);
      },
      (error: any) => {
        console.log(error);
        return error;
      }
    );
  }
  downloadErrorRowExcelFile(downloadErrorFileName: any) {
    this.GlaccountimportService.downloadGlImportErrorExcelFile(
      downloadErrorFileName.importErrorRowExcelFileName
    ).subscribe(
      (response: any) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const fileName = downloadErrorFileName.importErrorRowExcelFileName;
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.isLoading = false;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  public close(status: string): void {
    if (status == 'yes') {
      this.isConfirmOpen = false;
      this.menubarService.show();
      this.router.navigate(['/home']);
    } else {
      this.isConfirmOpen = false;
    }
  }
  handleCancelButton() {
    this.isConfirmOpen = true;
  }
}
