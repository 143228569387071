<!-- <kendo-dialog-titlebar (close)="cancelClick()">
    <div style="font-size: 18px; line-height: 1.1em;">
        Sub-analysis properties
    </div>
</kendo-dialog-titlebar>
<mat-card class="userCard">
    <mat-card-title>Sub-analysis properties</mat-card-title>
    <mat-card-content>
            <form class="k-form" [formGroup]="newSubAnalysisForm">
                <kendo-formfield>
                    <kendo-label [for]="BlockName" text="Sub-analysis name*"></kendo-label>
                    <input  style="width:90%" formControlName="BlockName" kendoTextBox #BlockName required /> 
                    <kendo-formerror>{{BlockNameErrorMessage}}</kendo-formerror>        
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="BlockHeaderText"  text="Sub-Analysis header"  style="justify-content: space-between;"><span>Include Total?</span></kendo-label>
                    <kendo-label>
                        <input  style="width:90%" formControlName="BlockHeaderText" kendoTextBox #BlockHeaderText>&nbsp;&nbsp;&nbsp;<span style="margin-top: 6px;margin-left:auto;margin-right:auto;">
                        <input type="checkbox" checked="checked" (change)="onCheckboxChange($event)" formControlName="BlockHeaderIncludeTotal"  #BlockHeaderIncludeTotal kendoCheckBox /></span>
                    </kendo-label>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="BlockFooterText" text="Sub-Analysis footer"></kendo-label>
                    <kendo-label>
                        <input  style="width:90%" formControlName="BlockFooterText" kendoTextBox #BlockFooterText>&nbsp;&nbsp;&nbsp;<span style="margin-top: 6px;margin-left:auto;margin-right:auto;">
                            <input type="checkbox" (change)="onCheckboxChange($event)" formControlName="BlockFooterIncludeTotal"  #BlockFooterIncludeTotal kendoCheckBox /></span></kendo-label>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="CalculateTotalAs" text="Calculate total as" style="width:90%;justify-content: space-between;">
                        <span ><input type="radio"  id="Sub-analysis total" class="k-radio" value="S" data-bind="checked: Subanalysistotal" formControlName="CalculateTotalAs" #CalculateTotalAs kendoRadioButton/>&nbsp;
                        <label class="k-radio-label" for="Sub-analysis total">Sub-analysis total</label> &nbsp;</span>
                        <span ><input type="radio"  id="Running total of Statement" class="k-radio" value="R" data-bind="checked: Subanalysistotal" formControlName="CalculateTotalAs" #CalculateTotalAs kendoRadioButton/>&nbsp;
                        <label class="k-radio-label" for="Running total of Statement">Running total of Statement</label></span>
                        <span class="required-asterisk" style="color:red" (click)=onCheckboxChange($event) *ngIf="mandatoryFlag">*required</span>
                    </kendo-label>
                        
                </kendo-formfield>

                <kendo-formfield  style="width:90%">
                    <kendo-label [for]="HideSubAnalysisInTree" text=""><span><input type="checkbox" formControlName="HideSubAnalysisInTree" #HideSubAnalysisInTree kendoCheckBox />&nbsp;Hide Sub-analysis in tree view</span></kendo-label>
                </kendo-formfield>
            </form>
    </mat-card-content>
</mat-card>
<div class="clickToActions" style="padding: 20px 0px;">        
        <ap-button type="button" [btnType]="'primary'" 
        [disabled]="!(mandatoryFlag ? (newSubAnalysisForm.valid && (newSubAnalysisForm.get('CalculateTotalAs').value =='R' || newSubAnalysisForm.get('CalculateTotalAs').value =='S')):newSubAnalysisForm.valid)"
        [label]="'Save'" (onClick)="saveNewSubAnalysis()">
        </ap-button>
        <ap-button [btnType]="'secondary'" [label]="'Cancel'" (onClick)="cancelClick()" style="margin-right: 6px;"></ap-button>
    </div>     -->

<!-- <kendo-dialog-titlebar (close)="cancelClick()">
        <div style="font-size: 20px; line-height: 1.3em">Sub-analysis properties</div>
      </kendo-dialog-titlebar>


<div class="col-md-12 col-xs-12" style="padding: 0px !important">

<div class="subHeader">Sub-analysis properties</div>
</div>
<mat-card class="userCard" style="padding: 0px">
    <mat-card-content>

      <form class="k-form" [formGroup]="newSubAnalysisForm">
        <kendo-formfield>
                    <kendo-label [for]="BlockName" text="Sub-analysis name*"></kendo-label>
                    <input  style="width:90%" formControlName="BlockName" kendoTextBox #BlockName required /> 
                    <kendo-formerror>{{BlockNameErrorMessage}}</kendo-formerror>        
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="BlockHeaderText"  text="Sub-Analysis header"  style="justify-content: space-between;"><span>Include Total?</span></kendo-label>
                    <kendo-label>
                        <input  style="width:90%" formControlName="BlockHeaderText" kendoTextBox #BlockHeaderText>&nbsp;&nbsp;&nbsp;<span style="margin-top: 6px;margin-left:auto;margin-right:auto;">
                        <input type="checkbox" checked="checked" (change)="onCheckboxChange($event)" formControlName="BlockHeaderIncludeTotal"  #BlockHeaderIncludeTotal kendoCheckBox /></span>
                    </kendo-label>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="BlockFooterText" text="Sub-Analysis footer"></kendo-label>
                    <kendo-label>
                        <input  style="width:90%" formControlName="BlockFooterText" kendoTextBox #BlockFooterText>&nbsp;&nbsp;&nbsp;<span style="margin-top: 6px;margin-left:auto;margin-right:auto;">
                            <input type="checkbox" (change)="onCheckboxChange($event)" formControlName="BlockFooterIncludeTotal"  #BlockFooterIncludeTotal kendoCheckBox /></span></kendo-label>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="CalculateTotalAs" text="Calculate total as" style="width:90%;justify-content: space-between;">
                        <span ><input type="radio"  id="Sub-analysis total" class="k-radio" value="S" data-bind="checked: Subanalysistotal" formControlName="CalculateTotalAs" #CalculateTotalAs kendoRadioButton/>&nbsp;
                        <label class="k-radio-label" for="Sub-analysis total">Sub-analysis total</label> &nbsp;</span>
                        <span ><input type="radio"  id="Running total of Statement" class="k-radio" value="R" data-bind="checked: Subanalysistotal" formControlName="CalculateTotalAs" #CalculateTotalAs kendoRadioButton/>&nbsp;
                        <label class="k-radio-label" for="Running total of Statement">Running total of Statement</label></span>
                        <span class="required-asterisk" style="color:red" (click)=onCheckboxChange($event) *ngIf="mandatoryFlag">*required</span>
                    </kendo-label>
                        
                </kendo-formfield>

                <kendo-formfield  style="width:90%">
                    <kendo-label [for]="HideSubAnalysisInTree" text=""><span><input type="checkbox" formControlName="HideSubAnalysisInTree" #HideSubAnalysisInTree kendoCheckBox />&nbsp;Hide Sub-analysis in tree view</span></kendo-label>
                </kendo-formfield>
            
      </form>
    </mat-card-content>
</mat-card>
      <div class="clickToActions" style="padding: 20px 0px">
        <ap-button
          [btnType]="'secondary'"
          class="cancelButton"
          [label]="'Cancel'"
          (click)="cancelClick()"
        ></ap-button>
        <ap-button
          [btnType]="'primary'"
          class="okButton"
          [label]="'Save'"
          [disabled]="!(mandatoryFlag ? (newSubAnalysisForm.valid && (newSubAnalysisForm.get('CalculateTotalAs').value =='R' || newSubAnalysisForm.get('CalculateTotalAs').value =='S')):newSubAnalysisForm.valid)"
          style="margin-right: 6px"
          themeColor="primary"
          (click)="saveNewSubAnalysis()"
        ></ap-button>
     
  </div> -->

<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Sub-analysis properties</div>
</kendo-dialog-titlebar>

<mat-card class="userCard" style="padding: 0px">
  <mat-card-title><b> Sub-analysis properties </b></mat-card-title>
  <mat-card-content>
    <div>
      <form class="k-form" [formGroup]="newSubAnalysisForm">
        <kendo-formfield>
          <kendo-label
            [for]="BlockName"
            text="Sub-analysis name*"
          ></kendo-label>
          <input
            style="width: 90%"
            formControlName="BlockName"
            [maxlength]="50"
            kendoTextBox
            #BlockName
            required
          />
          <kendo-formerror>{{ BlockNameErrorMessage }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            [for]="BlockHeaderText"
            text="Sub-analysis header"
            style="justify-content: space-between"
            ><span>Include total?</span></kendo-label
          >
          <kendo-label>
            <input
              style="width: 90%"
              formControlName="BlockHeaderText"
              [maxlength]="50"
              kendoTextBox
              #BlockHeaderText
            />&nbsp;&nbsp;&nbsp;<span
              style="margin-top: 6px; margin-left: auto; margin-right: auto"
            >
              <input
                type="checkbox"
                checked="checked"
                (change)="onCheckboxChange($event)"
                formControlName="BlockHeaderIncludeTotal"
                #BlockHeaderIncludeTotal
                kendoCheckBox
            /></span>
          </kendo-label>
        </kendo-formfield>

        <kendo-formfield>
          <kendo-label
            [for]="BlockFooterText"
            text="Sub-analysis footer"
          ></kendo-label>
          <kendo-label>
            <input
              style="width: 90%"
              formControlName="BlockFooterText"
              [maxlength]="50"
              kendoTextBox
              #BlockFooterText />&nbsp;&nbsp;&nbsp;<span
              style="margin-top: 6px; margin-left: auto; margin-right: auto"
            >
              <input
                type="checkbox"
                (change)="onCheckboxChange($event)"
                formControlName="BlockFooterIncludeTotal"
                #BlockFooterIncludeTotal
                kendoCheckBox /></span
          ></kendo-label>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            [for]="CalculateTotalAs"
            text="Calculate total as"
            style="width: 90%"
          >
            <span style="padding-left: 10px"
              ><input
                type="radio"
                id="Sub-analysis total"
                class="k-radio"
                value="S"
                data-bind="checked: Subanalysistotal"
                formControlName="CalculateTotalAs"
                #CalculateTotalAs
                kendoRadioButton
              />
              <label class="k-radio-label" for="Sub-analysis total"
                >Sub-analysis total</label
              >
              &nbsp;</span
            >
            <span style="padding-left: 10px"
              ><input
                type="radio"
                id="Running total of Statement"
                class="k-radio"
                value="R"
                data-bind="checked: Subanalysistotal"
                formControlName="CalculateTotalAs"
                #CalculateTotalAs
                kendoRadioButton
              />
              <label class="k-radio-label" for="Running total of Statement"
                >Running total of statement</label
              ></span
            >
            <span
              class="required-asterisk"
              style="color: red; padding-left: 30px"
              (click)="onCheckboxChange($event)"
              *ngIf="mandatoryFlag"
              >*required</span
            >
          </kendo-label>
        </kendo-formfield>

        <kendo-formfield>
          <kendo-label [for]="HideSubAnalysisInTree" text=""
            ><span
              ><input
                type="checkbox"
                formControlName="HideSubAnalysisInTree"
                #HideSubAnalysisInTree
                kendoCheckBox
              />&nbsp;Hide sub-analysis in tree view</span
            ></kendo-label
          >
        </kendo-formfield>
      </form>
    </div>
  </mat-card-content>
</mat-card>
<kendo-dialog-actions>
  <div class="clickToActions" style="padding-bottom: 20px">
    <ap-button
      [btnType]="'secondary'"
      [label]="'Cancel'"
      class="cancelButton"
      (click)="cancelClick()"
      style="margin-right: 6px"
    ></ap-button>

    <ap-button
      [btnType]="'primary'"
      [label]="'Save'"
      class="okButton"
      style="margin-right: 6px"
      themeColor="primary"
      [disabled]="
        !(mandatoryFlag
          ? newSubAnalysisForm.valid &&
            (newSubAnalysisForm.get('CalculateTotalAs').value == 'R' ||
              newSubAnalysisForm.get('CalculateTotalAs').value == 'S')
          : newSubAnalysisForm.valid)
      "
      themeColor="primary"
      (click)="saveNewSubAnalysis()"
    ></ap-button>
  </div>
</kendo-dialog-actions>
