import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DialogRef,
  DialogContentBase,
  ActionsLayout,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AnalysisstatementgridpopupComponent } from '../analysisstatementgridpopup/analysisstatementgridpopup.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addanalysisstatementlayout',
  templateUrl: './addanalysisstatementlayout.component.html',
  styleUrls: ['./addanalysisstatementlayout.component.scss'],
})
export class AddanalysisstatementlayoutComponent
  extends DialogContentBase
  implements OnInit
{
  @Input() public LayoutString: any;
  @Input() public editNodeData: any;
  @Input() public copyNodeData: any;
  isEdit: boolean = false;
  isCopy: boolean = false;
  addASLayoutForm: FormGroup;
  public actionsLayout: ActionsLayout = 'normal';
  descriptionErrorMessage: any = 'Description is required';
  constructor(
    public dialog: DialogRef,
    private router: Router,
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {
    super(dialog);
    this.addASLayoutForm = this.fb.group({
      description: [''],
      display: ['CR+'],
      footer: [''],
      displaytotalinfooter: [false],
    });
  }

  ngOnInit(): void {
    if (this.editNodeData) {
      this.isEdit = true;
      this.addASLayoutForm.controls['description'].setValue(
        this.editNodeData.Description
      );
      this.addASLayoutForm.controls['display'].setValue(
        this.editNodeData.DisplayAs
      );
      this.addASLayoutForm.controls['footer'].setValue(
        this.editNodeData.Footer
      );
      this.addASLayoutForm.controls['displaytotalinfooter'].setValue(
        this.editNodeData.DisplayTotal
      );
    }
    if (this.copyNodeData) {
      this.isCopy = true;
      this.addASLayoutForm.controls['description'].setValue(
        this.copyNodeData.Description
      );
      this.addASLayoutForm.controls['display'].setValue(
        this.copyNodeData.DisplayAs
      );
      this.addASLayoutForm.controls['footer'].setValue(
        this.copyNodeData.Footer
      );
      this.addASLayoutForm.controls['displaytotalinfooter'].setValue(
        this.copyNodeData.DisplayTotal
      );
    }
  }
  cancelClick() {
    this.dialog.close();
    // this.dialogService.open({
    //   content: AnalysisstatementgridpopupComponent,
    //   width: '100%',
    //   height:'100%'
    // });
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/manageanalysisstatementlayouts']));
  }
  saveASLayout() {
    var formObject = this.addASLayoutForm.getRawValue();
    let ASobj = {
      StatementLayoutId: 0,
      Description: formObject.description,
      DisplayAs: formObject.display,
      Footer: formObject.footer,
      DisplayTotal: formObject.displaytotalinfooter,
      LayoutType: this.LayoutString,
    };
    if (this.isEdit) {
      let ASobjupdate = {
        StatementLayoutId: this.editNodeData.StatementLayoutId,
        LayoutTypeId: this.editNodeData.LayoutTypeId,
        Description: formObject.description,
        DisplayAs: formObject.display,
        Footer: formObject.footer,
        DisplayTotal: formObject.displaytotalinfooter,
        LayoutType: this.LayoutString,
      };

      this.globalService
        .saveStatementLayout(ASobjupdate, 'update')
        .then((res) => {
          if (res == 'Analysis Statement updated successfully') {
            this.toastr.successToastr('Analysis statement updated successfully', 'Success');
            this.displayGrid();
          } else if (res == 'Analysis Statement Description already exists') {
            this.addASLayoutForm.controls['description'].markAsTouched();
            this.descriptionErrorMessage =
              'Please modify Description as it is not unique';
            this.addASLayoutForm.controls['description'].setErrors({
              incorrect: true,
            });
            return;
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
            this.displayGrid();
          }
        })
        .catch((err) => {});
    } else {
      let neworCopy;
      if (this.isCopy) {
        ASobj.StatementLayoutId = this.copyNodeData.StatementLayoutId;
        neworCopy = 'copyInsert';
      } else {
        neworCopy = 'new';
      }

      this.globalService
        .saveStatementLayout(ASobj, neworCopy)
        .then((res) => {
          if (res == 'Analysis Statement added successfully') {
            this.toastr.successToastr('Analysis statement added successfully', 'Success');
            this.displayGrid();
          } else if (res == 'Analysis Statement Description already exists') {
            this.addASLayoutForm.controls['description'].markAsTouched();
            this.descriptionErrorMessage =
              'Please modify Description as it is not unique';
            this.addASLayoutForm.controls['description'].setErrors({
              incorrect: true,
            });
            return;
          } else if (res == 'Analysis Statement already exists') {
            this.addASLayoutForm.controls['description'].markAsTouched();
            this.descriptionErrorMessage =
              'Please modify Description as it is not unique';
            this.addASLayoutForm.controls['description'].setErrors({
              incorrect: true,
            });
            return;
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
            this.displayGrid();
          }
        })
        .catch((err) => {});
    }
  }
  displayGrid() {
    // this.dialogService.open({
    //   content: AnalysisstatementgridpopupComponent,
    //   width: '100%',
    //   height:'100%'
    // });
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/manageanalysisstatementlayouts']));
    // this.router.navigate(['/manageanalysisstatementlayouts']);
  }
}
