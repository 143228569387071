import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  IterableDiffers,
  DoCheck,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowState } from '@progress/kendo-angular-dialog';
import { groupBy, GroupDescriptor } from '@progress/kendo-data-query';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ChooseColumn } from 'src/app/_models/choosecolumnsettings';
import {
  TransactionsGroupFolder,
  transactionGridColumnSeetings,
  GridSetting,
} from 'src/app/_models/transactionsGroupFolder';
import { ShowDetailsService } from '../../common/services/showDetails/show-details.service';
import {
  OfflineReferralChooseColumnFilters,
  offlineReferralChooseColumnOperatorData,
  OfflineReferralChooseColumnOperators,
} from 'src/app/_models/offlineReferralChooseColumnFilters';
import { v4 as uuidv4 } from 'uuid';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { formatDiagnosticsWithColorAndContext } from 'typescript';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import {
  SelectableSettings,
  SelectableMode,
  RowClassArgs,
  SelectionEvent,
} from '@progress/kendo-angular-grid';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-show-transactions',
  templateUrl: './show-transactions.component.html',
  styleUrls: ['./show-transactions.component.scss'],
})
export class ShowTransactionsComponent implements OnInit, OnChanges, DoCheck {
  @Input() selectedRow: any;
  aggregationObject: string[] = [];
  aggregationObjectAmount: string[] = [];
  aggregationObjectCustom4: string[] = [];
  aggregationObjectCustom5: string[] = [];
  aggregationObjectAbsoluteAmount: string[] = [];
  public columnMenu: boolean = true;
  public isLoading: boolean = false;
  public opened = false;
  public windowState: WindowState = 'default';
  public disableAdd: boolean = true;
  differ: any;
  legalEntityId: any;
  lineItemID: any;
  blockID: any;
  rowsCount: number = 0;
  amountAggregationvals: any = [];
  absAmountAggregationVals: any[] = [];
  custom4AggregationVals: any[] = [];
  custom5AggregationVals: any[] = [];
  totalPages: number = 0;
  currentPagenum: number = 1;
  startIndex: number = 0;
  sortType: string = 'desc';
  numberOperator: any = {
    'Is equal to': 'Is =',
    'Is not equal to': 'Is not =',
    'Is less than': 'Is <',
    'Is less than or equal to': 'Is <=',
    'Is greater than': 'Is >',
    'Is greater than or equal to': 'Is >=',
    'Is null': 'Is Empty',
    'Is not null': 'Is Not Empty',
  };
  selectedcolumnSort: any = {
    field: 'GLAccount',
    title: 'Gl account',
    type: 'String',
  };
  public openClose(isOpened: boolean): void {
    this.opened = isOpened;
  }
  public isDraggable = true;
  isbuttondisable: boolean = true;
  public value: TransactionsGroupFolder[] = [];
  transactionsGroupFolders: TransactionsGroupFolder[] = [];
  transactionsGroupFolder!: TransactionsGroupFolder;
  transactionParame: number[] = [];
  public rowData: any;
  public levelType: string = '';
  public gridData: TransactionsGroupFolder[] = [];
  public dataColumn: TransactionsGroupFolder[] = [];
  public sumAmt: number = 0;
  public sumCustom4: number = 0;
  public sumCustom5: number = 0;
  public sumAbsAmt: number = 0;
  public cntAmount: number = 0;
  public cntCustom4: number = 0;
  public cntCustom5: number = 0;
  public cntAbsAmt: number = 0;
  public maxAmount: number = 0;
  public maxCustom4: number = 0;
  public maxCustom5: number = 0;
  public maxAbsAmt: number = 0;
  public minAmount: number = 0;
  public minCustom4: number = 0;
  public minCustom5: number = 0;
  public minAbsAmt: number = 0;
  public avgAmount: number = 0;
  public avgCustom4: number = 0;
  public avgCustom5: number = 0;
  public avgAbsAmt: number = 0;
  public selectedFiscalYear: any;
  aggregateOptionsAmount: any[] = [
    { id: 'Average', label: 'Average', checked: false },
    { id: 'Count', label: 'Count', checked: false },
    { id: 'Maximum', label: 'Maximum', checked: false },
    { id: 'Minimum', label: 'Minimum', checked: false },
    { id: 'Sum', label: 'Sum', checked: false },
  ];
  aggregateOptionsAbsoluteAmount: any[] = [
    { id: 'Average', label: 'Average', checked: false },
    { id: 'Count', label: 'Count', checked: false },
    { id: 'Maximum', label: 'Maximum', checked: false },
    { id: 'Minimum', label: 'Minimum', checked: false },
    { id: 'Sum', label: 'Sum', checked: false },
  ];
  aggregateOptionsCustom4: any[] = [
    { id: 'Average', label: 'Average', checked: false },
    { id: 'Count', label: 'Count', checked: false },
    { id: 'Maximum', label: 'Maximum', checked: false },
    { id: 'Minimum', label: 'Minimum', checked: false },
    { id: 'Sum', label: 'Sum', checked: false },
  ];
  aggregateOptionsCustom5: any[] = [
    { id: 'Average', label: 'Average', checked: false },
    { id: 'Count', label: 'Count', checked: false },
    { id: 'Maximum', label: 'Maximum', checked: false },
    { id: 'Minimum', label: 'Minimum', checked: false },
    { id: 'Sum', label: 'Sum', checked: false },
  ];

  public statementId: any;

  public newColumns: any;

  public columns: string[] = [];
  public showTransactionColumn: any = [];
  public gridColumns: transactionGridColumnSeetings[] = [];
  gridColumn!: transactionGridColumnSeetings;

  gridSettings: GridSetting[] = [];

  public hiddenColumns: any[] = [];
  public isHidden(columnName: string): boolean {
    return this.hiddenColumns.indexOf(columnName) > -1;
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  initialized: boolean = true;
  isChooseFilter: boolean = false;
  chooseColumnFilters: OfflineReferralChooseColumnFilters[] = [];
  chooseColumnFilter!: OfflineReferralChooseColumnFilters;

  chooseColumnOperatorData: OfflineReferralChooseColumnOperators[] =
    offlineReferralChooseColumnOperatorData;

  filterChooseColumnOperatorData: OfflineReferralChooseColumnOperators[] = [];
  dataImportPayload: any;
  uniqueId: string = '';
  filterArr: any = [];
  aggregationArr: any = [];
  totalRecords: number = 0;
  displayedRecords: number = 0;
  public mySelection: any = [];
  errorDisplay: boolean = false;
  public currentGridRef: GridComponent | undefined;
  addfilter: FormGroup;
  constructor(
    private showDetailsService: ShowDetailsService,
    public toastr: ToastrManager,
    differs: IterableDiffers,
    private router: Router,
    public myGlobalObject: MyGlobalObject,
    private fb: FormBuilder
  ) {
    this.differ = differs.find([]).create();
    this.addfilter = this.fb.group({
      columnName: [''],
      Operator: [''],
      criteria: [''],
    });
  }
  public rowClass = (context: RowClassArgs) => {
    var rowclassname = 'gridRow';
    return rowclassname;
  };
  public selectedCallback = (args: any) => args.dataItem;
  public selectableSettings: SelectableSettings = {
    enabled: true,
    checkboxOnly: true,
    mode: 'multiple',
  };
  public selectedColumnName: any;
  public selectedOperator: any;
  public selectedtext: any;
  ngDoCheck() {
    const change = this.differ.diff(this.aggregationObject);
  }
  ngOnInit(): void {
    this.initialized = true;
    this.rowData = this.selectedRow;
    this.mySelection = [];
    this.uniqueId = uuidv4();

    this.levelType = this.selectedRow.type;
    this.selectedFiscalYear = localStorage.getItem('selectedFiscalYear');
    this.aggregateOptionsAmount = [
      { id: 'Average', label: 'Average', checked: false },
      { id: 'Count', label: 'Count', checked: false },
      { id: 'Maximum', label: 'Maximum', checked: false },
      { id: 'Minimum', label: 'Minimum', checked: false },
      { id: 'Sum', label: 'Sum', checked: false },
    ];
    this.getTransactionColumns();
    this.getGridData(0);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRow.previousValue != undefined) {
      // do stuff if this is not the initialization of "bar"
      this.uniqueId = uuidv4();
      this.rowData = this.selectedRow;
      this.mySelection = [];
      this.chooseColumnFilters = [];
      this.filterArr = [];
      this.currentPagenum = 1;
      this.clearAggregations();
      this.levelType = this.selectedRow.type;
      this.clearAll();
      this.getGridData(0);
    }
  }
  clearAll() {
    this.aggregateOptionsAmount = [
      { id: 'Average', label: 'Average', checked: false },
      { id: 'Count', label: 'Count', checked: false },
      { id: 'Maximum', label: 'Maximum', checked: false },
      { id: 'Minimum', label: 'Minimum', checked: false },
      { id: 'Sum', label: 'Sum', checked: false },
    ];
    this.aggregateOptionsAbsoluteAmount = [
      { id: 'Average', label: 'Average', checked: false },
      { id: 'Count', label: 'Count', checked: false },
      { id: 'Maximum', label: 'Maximum', checked: false },
      { id: 'Minimum', label: 'Minimum', checked: false },
      { id: 'Sum', label: 'Sum', checked: false },
    ];
    this.aggregateOptionsCustom4 = [
      { id: 'Average', label: 'Average', checked: false },
      { id: 'Count', label: 'Count', checked: false },
      { id: 'Maximum', label: 'Maximum', checked: false },
      { id: 'Minimum', label: 'Minimum', checked: false },
      { id: 'Sum', label: 'Sum', checked: false },
    ];
    this.aggregateOptionsCustom5 = [
      { id: 'Average', label: 'Average', checked: false },
      { id: 'Count', label: 'Count', checked: false },
      { id: 'Maximum', label: 'Maximum', checked: false },
      { id: 'Minimum', label: 'Minimum', checked: false },
      { id: 'Sum', label: 'Sum', checked: false },
    ];

    this.aggregationObject = [];
    this.aggregationObjectAbsoluteAmount = [];
    this.aggregationObjectAmount = [];
    this.aggregationObjectCustom4 = [];
    this.aggregationObjectCustom5 = [];
  }
  getGridData(startIndex: number) {
    if (this.selectedRow.type == 'AS' || this.selectedRow.type == 'SS') {
      this.statementId = this.selectedRow.id;
      this.legalEntityId = this.selectedRow.LegalEntityID;
      if (this.uniqueId) this.getTransactionsDetailsforStatement(startIndex);
    } else if (
      this.selectedRow.type == 'LI' ||
      this.selectedRow.type == 'CLI'
    ) {
      this.lineItemID = this.selectedRow.id;
      this.legalEntityId = this.selectedRow.LegalEntityID;
      if (this.uniqueId) this.getTransactionsDetailsforLineItem(startIndex);
    } else if (this.selectedRow.type == 'SA') {
      this.statementId = this.selectedRow.Satementid;
      this.blockID = this.selectedRow.id;
      this.legalEntityId = this.selectedRow.LegalEntityID;
      if (this.uniqueId) this.getTransactionsDetailsforSubAnalysis(startIndex);
    }
  }
  getTransactionColumns(): void {
    this.gridColumns = [];
    this.gridSettings = [];
    this.showDetailsService.getTransactionColumnList().subscribe(
      (response) => {
        this.showTransactionColumn = response;
        this.gridSettings = this.showTransactionColumn;
        this.gridSettings.map((gr, i) => {
          if (gr.Selected == true) {
            this.gridColumn = {
              field: gr.DbColumnName,
              title: gr.DisplayName,
              type:
                gr.Type == 'String' &&
                gr.DbColumnName.toLowerCase().includes('date')
                  ? 'DateTime'
                  : gr.Type == 'String' && !gr.DbColumnName.includes('Date')
                  ? 'text'
                  : 'numeric',
            };
            this.gridColumns.push(this.gridColumn);
            this.selectedcolumnSort = {
              field: 'GLAccount',
              title: 'Gl account',
              type: 'String',
            };
          } else {
            this.hiddenColumns.push(gr.DisplayName);
          }
          this.columns.push(gr.DisplayName);
        });
      },
      (err) => {
        console.log('err', err);
      }
    );
  }
  getTransactionsDetailsforStatement(startIndex: number): void {
    this.isLoading = true;
    let year: any = localStorage.getItem('selectedFiscalYear');
    // this.showDetailsService
    //   .getTransactionsforStatement(
    //     JSON.parse(year).FiscalYearId,
    //     this.statementId,
    //     this.legalEntityId
    //   )
    //   .subscribe(
    //     (response) => {
    //       this.gridData = response;
    //       this.rowsCount = response.length;
    //       this.transactionsGroupFolders = response;
    //       this.dataColumn = response;
    //       this.getChooseColumnSettingConfigure();
    //       this.isLoading = false;
    //     },
    //     (err) => {
    //       console.log('error from api', err);
    //       this.isLoading = false;
    //     }
    //   );
    this.dataImportPayload = {
      StatementID: this.statementId,
      FiscalYear:
        year == 'null' || year == null ? 0 : JSON.parse(year).FiscalYearId,
      LegalEntityId: this.legalEntityId,
      EmailID: this.myGlobalObject.loggedinUserDetail.mail,
      showDetailTransFilters: [],
      Guid: this.uniqueId,
      StartIndex: startIndex,
      MaxRows: 100,
      BlockID: 0,
      AccountingAreaID: 0,
      showDetailAggregations: [],
      sortColumn: this.selectedcolumnSort.field,
      sortType: this.sortType,
    };
    if (this.filterArr.length > 0) {
      this.dataImportPayload.showDetailTransFilters = this.filterArr;
      this.gridData = [];
      this.showDetailsService
        .getCachedTransactionsforStatementFilter(this.dataImportPayload)
        .subscribe(
          (response: any) => {
            // this.totalRecords = response.Item1;
            this.displayedRecords = response.Item2.length;
            this.totalRecords = response.Item1;
            this.totalPages = Math.ceil(this.totalRecords / 100);
            if (this.startIndex == 0) this.currentPagenum = 1;
            if (this.totalRecords > 0 && this.totalPages == 0)
              this.totalPages = 1;
            else if (this.totalRecords == 0 && this.totalPages == 0)
              this.totalPages = 0;
            this.gridData = response.Item2;
            this.rowsCount = response.Item2.length;
            this.transactionsGroupFolders = response.Item2;
            this.dataColumn = response.Item2;
            //this.getChooseColumnSettingConfigure();
            this.isLoading = false;
          },
          (err) => {
            console.log('error from api', err);
            this.isLoading = false;
          }
        );
    } else {
      this.gridData = [];
      this.showDetailsService
        .getCachedTransactionsforStatement(this.dataImportPayload)
        .subscribe(
          (response: any) => {
            this.totalRecords = response.Item1;
            this.displayedRecords = response.Item2.length;
            this.gridData = response.Item2;
            this.rowsCount = response.Item2.length;
            this.totalPages = Math.ceil(this.totalRecords / 100);
            if (this.startIndex == 0) this.currentPagenum = 1;
            if (this.totalRecords > 0 && this.totalPages == 0)
              this.totalPages = 1;
            else if (this.totalRecords == 0 && this.totalPages == 0)
              this.totalPages = 0;
            this.transactionsGroupFolders = response.Item2;
            this.dataColumn = response.Item2;
            //this.getChooseColumnSettingConfigure();
            this.isLoading = false;
          },
          (err) => {
            console.log('error from api', err);
            this.isLoading = false;
          }
        );
    }
  }

  getTransactionsDetailsforLineItem(startIndex: number) {
    this.isLoading = true;
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.dataImportPayload = {
      StatementID: this.statementId,
      FiscalYear:
        year == 'null' || year == null ? 0 : JSON.parse(year).FiscalYearId,
      LegalEntityId: this.legalEntityId,
      EmailID: this.myGlobalObject.loggedinUserDetail.mail,
      showDetailTransFilters: [],
      Guid: this.uniqueId,
      StartIndex: startIndex,
      MaxRows: 100,
      BlockID: 0,
      AccountingAreaID: this.lineItemID,
      showDetailAggregations: [],
      sortColumn: this.selectedcolumnSort.field,
      sortType: this.sortType,
    };
    if (this.filterArr.length > 0) {
      this.dataImportPayload.showDetailTransFilters = this.filterArr;
      this.gridData = [];
      this.showDetailsService
        .getCachedTransactionsforLineItemFilter(this.dataImportPayload)
        .subscribe(
          (response: any) => {
            this.totalRecords = response.Item1;
            this.displayedRecords = response.Item2.length;
            this.gridData = response.Item2;
            this.rowsCount = response.Item2.length;
            this.totalPages = Math.ceil(this.totalRecords / 100);
            if (this.startIndex == 0) this.currentPagenum = 1;
            if (this.totalRecords > 0 && this.totalPages == 0)
              this.totalPages = 1;
            else if (this.totalRecords == 0 && this.totalPages == 0)
              this.totalPages = 0;
            this.transactionsGroupFolders = response.Item2;
            this.dataColumn = response.Item2;
            this.getChooseColumnSettingConfigure();
            this.isLoading = false;
          },
          (err) => {
            console.log('error from api', err);
          }
        );
    } else {
      this.gridData = [];
      this.showDetailsService
        .getCachedTransactionsforLineItem(this.dataImportPayload)
        .subscribe(
          (response: any) => {
            this.totalRecords = response.Item1;
            this.displayedRecords = response.Item2.length;
            this.gridData = response.Item2;
            this.rowsCount = response.Item2.length;
            this.totalPages = Math.ceil(this.totalRecords / 100);
            if (this.startIndex == 0) this.currentPagenum = 1;
            if (this.totalRecords > 0 && this.totalPages == 0)
              this.totalPages = 1;
            else if (this.totalRecords == 0 && this.totalPages == 0)
              this.totalPages = 0;
            this.transactionsGroupFolders = response.Item2;
            this.dataColumn = response.Item2;
            this.getChooseColumnSettingConfigure();
            this.isLoading = false;
          },
          (err) => {
            console.log('error from api', err);
          }
        );
    }
  }
  getTransactionsDetailsforSubAnalysis(startIndex: number) {
    this.isLoading = true;
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.dataImportPayload = {
      StatementID: this.statementId,
      FiscalYear:
        year == 'null' || year == null ? 0 : JSON.parse(year).FiscalYearId,
      LegalEntityId: this.legalEntityId,
      EmailID: this.myGlobalObject.loggedinUserDetail.mail,
      showDetailTransFilters: [],
      Guid: this.uniqueId,
      StartIndex: startIndex,
      MaxRows: 100,
      BlockID: this.blockID,
      AccountingAreaID: this.lineItemID,
      showDetailAggregations: [],
      sortColumn: this.selectedcolumnSort.field,
      sortType: this.sortType,
    };
    if (this.filterArr.length > 0) {
      this.dataImportPayload.showDetailTransFilters = this.filterArr;
      this.gridData = [];
      this.showDetailsService
        .getCachedTransactionsforSubAnalysisFilter(this.dataImportPayload)
        .subscribe(
          (response: any) => {
            this.totalRecords = response.Item1;
            this.displayedRecords = response.Item2.length;
            this.gridData = response.Item2;
            this.rowsCount = response.Item2.length;
            this.totalPages = Math.ceil(this.totalRecords / 100);
            if (this.startIndex == 0) this.currentPagenum = 1;
            if (this.totalRecords > 0 && this.totalPages == 0)
              this.totalPages = 1;
            else if (this.totalRecords == 0 && this.totalPages == 0)
              this.totalPages = 0;
            this.transactionsGroupFolders = response.Item2;
            this.dataColumn = response.Item2;
            this.getChooseColumnSettingConfigure();
            this.isLoading = false;
          },
          (err) => {
            console.log('error from api', err);
          }
        );
    } else {
      this.gridData = [];
      this.showDetailsService
        .getCachedTransactionsforSubAnalysis(this.dataImportPayload)
        .subscribe(
          (response: any) => {
            this.totalRecords = response.Item1;
            this.displayedRecords = response.Item2.length;
            this.gridData = response.Item2;
            this.rowsCount = response.Item2.length;
            this.totalPages = Math.ceil(this.totalRecords / 100);
            if (this.startIndex == 0) this.currentPagenum = 1;
            if (this.totalRecords > 0 && this.totalPages == 0)
              this.totalPages = 1;
            else if (this.totalRecords == 0 && this.totalPages == 0)
              this.totalPages = 0;
            this.transactionsGroupFolders = response.Item2;
            this.dataColumn = response.Item2;
            this.getChooseColumnSettingConfigure();
            this.isLoading = false;
          },
          (err) => {
            console.log('error from api', err);
          }
        );
    }
  }

  public hideColumn(columnName: string): void {
    const hiddenColumns = this.hiddenColumns;

    if (!this.hiddenColumns.includes(columnName)) {
      hiddenColumns.push(columnName);
    } else {
      hiddenColumns.splice(hiddenColumns.indexOf(columnName), 1);
    }
  }
  getChooseColumnSettingConfigure() {
    this.showDetailsService
      .getChooseColumnSetting('ShowDetailsColumns')
      .subscribe((response) => {
        // this.newColumns = response;

        // this.columns = Object.keys(this.newColumns);

        // this.hiddenColumns = Object.keys(response).filter(
        //   (key) => !response[key]
        // );

        this.getCalculations();
      });
  }
  saveChooseColumnSettings() {
    this.isLoading = true;
    this.gridColumns = [];
    this.gridSettings.map((gr, i) => {
      if (this.hiddenColumns.includes(gr.DisplayName)) {
        gr.Selected = false;
      } else {
        gr.Selected = true;
        this.gridColumn = {
          field: gr.DbColumnName,
          title: gr.DisplayName,
          type: 'text',
        };
        this.gridColumns.push(this.gridColumn);
        this.selectedcolumnSort = {
          field: 'GLAccount',
          title: 'Gl account',
          type: 'String',
        };
      }
    });
    this.showDetailsService
      .saveTransactionColumnSettings(
        this.gridSettings,
        'ShowDetailsGridColumns'
      )
      .subscribe(
        (res) => {
          if (res) {
            this.toastr.successToastr(
              'Selected Grid columns saved successfully.',
              'Success'
            );
          } else {
            this.toastr.errorToastr(
              'Selected Grid columns saved failed.',
              'Error'
            );
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }
  getCalculations() {
    this.avgAmount =
      this.dataColumn
        .filter((item) => !isNaN(item.Amount))
        .reduce((sum, current) => sum + current.Amount, 0) /
        this.dataColumn.length || 0;
    this.avgCustom4 =
      this.dataColumn
        .filter((item) => !isNaN(item.Custom4))
        .reduce((sum, current) => sum + current.Custom4, 0) /
        this.dataColumn.length || 0;
    this.avgCustom5 =
      this.dataColumn
        .filter((item) => !isNaN(item.Custom5))
        .reduce((sum, current) => sum + current.Custom5, 0) /
        this.dataColumn.length || 0;
    this.avgAbsAmt =
      this.dataColumn
        .filter((item) => !isNaN(item.AbsoluteAmount))
        .reduce((sum, current) => sum + current.AbsoluteAmount, 0) /
        this.dataColumn.length || 0;
    this.sumAmt = this.dataColumn
      .filter((item) => !isNaN(item.Amount))
      .reduce((sum, current) => sum + current.Amount, 0);
    this.sumCustom4 = this.dataColumn
      .filter((item) => !isNaN(item.Custom4))
      .reduce((sum, current) => sum + current.Custom4, 0);
    this.sumCustom5 = this.dataColumn
      .filter((item) => !isNaN(item.Custom5))
      .reduce((sum, current) => sum + current.Custom5, 0);
    this.sumAbsAmt = this.dataColumn
      .filter((item) => !isNaN(item.AbsoluteAmount))
      .reduce((sum, current) => sum + current.AbsoluteAmount, 0);
    this.cntAmount =
      this.aggregationObject.length > 1
        ? this.dataColumn.length - 1
        : this.dataColumn.length;
    this.cntCustom4 =
      this.aggregationObject.length > 1
        ? this.dataColumn.length - 1
        : this.dataColumn.length;
    this.cntCustom5 =
      this.aggregationObject.length > 1
        ? this.dataColumn.length - 1
        : this.dataColumn.length;
    this.cntAbsAmt =
      this.aggregationObject.length > 1
        ? this.dataColumn.length - 1
        : this.dataColumn.length;
    this.maxAmount = Math.max(
      ...this.dataColumn
        .filter((item) => !isNaN(item.Amount))
        .map((item) => item.Amount)
    );
    this.maxCustom4 = Math.max(
      ...this.dataColumn
        .filter((item) => !isNaN(item.Custom4))
        .map((item) => item.Custom4)
    );
    this.maxCustom5 = Math.max(
      ...this.dataColumn
        .filter((item) => !isNaN(item.Custom5))
        .map((item) => item.Custom5)
    );
    this.maxAbsAmt = Math.max(
      ...this.dataColumn
        .filter((item) => !isNaN(item.AbsoluteAmount))
        .map((item) => item.AbsoluteAmount)
    );
    this.minAmount = Math.min(
      ...this.dataColumn
        .filter((item) => !isNaN(item.Amount))
        .map((item) => item.Amount)
    );
    this.minCustom4 = Math.min(
      ...this.dataColumn
        .filter((item) => !isNaN(item.Custom4))
        .map((item) => item.Custom4)
    );
    this.minCustom5 = Math.min(
      ...this.dataColumn
        .filter((item) => !isNaN(item.Custom5))
        .map((item) => item.Custom5)
    );
    this.minAbsAmt = Math.min(
      ...this.dataColumn
        .filter((item) => !isNaN(item.AbsoluteAmount))
        .map((item) => item.AbsoluteAmount)
    );
  }
  openWindow() {
    this.opened = true;
  }
  saveColumn() {
    this.saveChooseColumnSettings();
    this.openClose(false);
  }
  public aggregateMethod(event: any, type: string, column: string): void {
    let aggregateOptions = [];
    if (column == 'Amount') aggregateOptions = this.aggregateOptionsAmount;
    else if (column == 'Custom4')
      aggregateOptions = this.aggregateOptionsCustom4;
    else if (column == 'Custom5')
      aggregateOptions = this.aggregateOptionsCustom5;
    else if (column == 'AbsoluteAmount')
      aggregateOptions = this.aggregateOptionsAbsoluteAmount;
    aggregateOptions.map((obj) => {
      if (obj.id == type) {
        obj.checked = event.target.checked;
      }
    });
    var selectedValue = aggregateOptions.filter(function (el) {
      return el.id == type;
    });

    if (type === 'Average')
      this.averageAmount(column, selectedValue[0].checked, type);
    else if (type === 'Count')
      this.countAmount(column, selectedValue[0].checked, type);
    else if (type === 'Maximum')
      this.maximumAmount(column, selectedValue[0].checked, type);
    else if (type === 'Minimum')
      this.minimumAmount(column, selectedValue[0].checked, type);
    else if (type === 'Sum')
      this.sumAmount(column, selectedValue[0].checked, type);
  }

  public sumAmount(
    columnField: string,
    isChecked: boolean,
    type: string
  ): void {
    if (columnField == 'Amount')
      this.updateGrid(
        `Sum = ${this.sumAmt.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
    else if (columnField == 'AbsoluteAmount')
      this.updateGrid(
        `Sum = ${this.sumAbsAmt.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
    else if (columnField == 'Custom4')
      this.updateGrid(
        `Sum = ${this.sumCustom4.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
    else if (columnField == 'Custom5')
      this.updateGrid(
        `Sum = ${this.sumCustom5.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
  }

  public countAmount(
    columnField: string,
    isChecked: boolean,
    type: string
  ): void {
    if (columnField == 'Amount')
      this.updateGrid(
        `Count = ${this.cntAmount.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
    else if (columnField == 'Custom4')
      this.updateGrid(
        `Count = ${this.cntCustom4.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
    else if (columnField == 'Custom5')
      this.updateGrid(
        `Count = ${this.cntCustom5.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
    else if (columnField == 'AbsoluteAmount')
      this.updateGrid(
        `Count = ${this.cntAbsAmt.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
  }

  public maximumAmount(
    columnField: string,
    isChecked: boolean,
    type: string
  ): void {
    if (columnField == 'Amount')
      this.updateGrid(
        `Maximum = ${this.maxAmount.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
    else if (columnField == 'Custom4')
      this.updateGrid(
        `Maximum = ${this.maxCustom4.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
    else if (columnField == 'Custom5')
      this.updateGrid(
        `Maximum = ${this.maxCustom5.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
    else if (columnField == 'AbsoluteAmount')
      this.updateGrid(
        `Maximum = ${this.maxAbsAmt.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
  }

  public averageAmount(column: string, isChecked: boolean, type: string): void {
    if (column == 'Amount')
      this.updateGrid(
        `Average = ${this.avgAmount.toLocaleString()}`,
        isChecked,
        type,
        column
      );
    else if (column == 'AbsoluteAmount')
      this.updateGrid(
        `Average = ${this.avgAbsAmt.toLocaleString()}`,
        isChecked,
        type,
        column
      );
    else if (column == 'Custom4')
      this.updateGrid(
        `Average = ${this.avgCustom4.toLocaleString()}`,
        isChecked,
        type,
        column
      );
    else if (column == 'Custom5')
      this.updateGrid(
        `Average = ${this.avgCustom5.toLocaleString()}`,
        isChecked,
        type,
        column
      );
  }

  public minimumAmount(
    columnField: string,
    isChecked: boolean,
    type: string
  ): void {
    if (columnField == 'Amount')
      this.updateGrid(
        `Minimum = ${this.minAmount.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
    else if (columnField == 'AbsoluteAmount')
      this.updateGrid(
        `Minimum = ${this.minAbsAmt.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
    else if (columnField == 'Custom4')
      this.updateGrid(
        `Minimum = ${this.minCustom4.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
    else if (columnField == 'Custom5')
      this.updateGrid(
        `Minimum = ${this.minCustom5.toLocaleString()}`,
        isChecked,
        type,
        columnField
      );
  }

  public updateGrid(
    data: any,
    isChecked: boolean,
    type: string,
    columnName: string
  ): void {
    let transaction1 = data;
    //this.updateAggregateRow(transaction1);
    if (columnName == 'Amount') {
      if (isChecked == true) {
        this.aggregationObject.forEach((value, index) => {
          if (value.includes(type)) this.aggregationObject.splice(index, 1);
        });
        this.aggregationObject.push(data);
      } else
        this.aggregationObject.forEach((value, index) => {
          if (value.includes(type)) this.aggregationObject.splice(index, 1);
        });

      this.updateAggregateRow(columnName);
    } else if (columnName == 'Custom4') {
      if (isChecked == true) {
        this.aggregationObjectCustom4.forEach((value, index) => {
          if (value.includes(type))
            this.aggregationObjectCustom4.splice(index, 1);
        });
        this.aggregationObjectCustom4.push(data);
      } else
        this.aggregationObjectCustom4.forEach((value, index) => {
          if (value.includes(type))
            this.aggregationObjectCustom4.splice(index, 1);
        });

      this.updateAggregateRow(columnName);
    } else if (columnName == 'Custom5') {
      if (isChecked == true) {
        this.aggregationObjectCustom5.forEach((value, index) => {
          if (value.includes(type))
            this.aggregationObjectCustom5.splice(index, 1);
        });
        this.aggregationObjectCustom5.push(data);
      } else
        this.aggregationObjectCustom5.forEach((value, index) => {
          if (value.includes(type))
            this.aggregationObjectCustom5.splice(index, 1);
        });
      this.updateAggregateRow(columnName);
    } else if (columnName == 'AbsoluteAmount') {
      if (isChecked == true) {
        this.aggregationObjectAbsoluteAmount.forEach((value, index) => {
          if (value.includes(type))
            this.aggregationObjectAbsoluteAmount.splice(index, 1);
        });
        this.aggregationObjectAbsoluteAmount.push(data);
      } else
        this.aggregationObjectAbsoluteAmount.forEach((value, index) => {
          if (value.includes(type))
            this.aggregationObjectAbsoluteAmount.splice(index, 1);
        });
      this.updateAggregateRow(columnName);
    }
  }
  public updateAggregateRow(columnName: string): void {
    // if (this.rowsCount != this.gridData.length) this.gridData.shift();
    // let updateObj = {
    //   ID: 0,
    //   AbsoluteAmount: this.aggregationObjectAbsoluteAmount.toString(),
    //   AllocationType: null,
    //   Amount: this.aggregationObject.toString(),
    //   AssetCode: null,
    //   CostCentre: null,
    //   Currency: 'Euro',
    //   Custom1: null,
    //   Custom2: null,
    //   Custom3: null,
    //   Custom4: this.aggregationObjectCustom4.toString(),
    //   Custom5: this.aggregationObjectCustom5.toString(),
    //   Custom6: null,
    //   Custom7: null,
    //   Custom8: null,
    //   Custom9: null,
    //   Custom10: null,
    //   Custom11: null,
    //   Custom12: null,
    //   Custom13: null,
    //   Custom14: null,
    //   Custom15: null,
    //   Custom16: null,
    //   Custom17: null,
    //   Custom18: null,
    //   Custom19: null,
    //   Custom20: null,
    //   Custom21: null,
    //   Custom22: null,
    //   Custom23: null,
    //   Custom24: null,
    //   Custom25: null,
    //   Custom26: null,
    //   Custom27: null,
    //   Custom28: null,
    //   Custom29: null,
    //   Custom30: null,
    //   Custom31: null,
    //   Custom32: null,
    //   Custom33: null,
    //   Custom34: null,
    //   Custom35: null,
    //   Custom36: null,
    //   Custom37: null,
    //   Custom38: null,
    //   Custom39: null,
    //   Custom40: null,
    //   Custom41: null,
    //   Custom42: null,
    //   Custom43: null,
    //   Custom44: null,
    //   Custom45: null,
    //   Custom46: null,
    //   Custom47: null,
    //   Custom48: null,
    //   Custom49: null,
    //   Custom50: null,
    //   Customer: null,
    //   Description: '',
    //   Division: null,
    //   DocumentDate: '',
    //   DocumentNo: '',
    //   DocumentType: '',
    //   FiscalYear: 0,
    //   GLAccount: '',
    //   GLAccountDescription: '',
    //   JournalCategory: null,
    //   JournalDate: null,
    //   JournalDescription: null,
    //   JournalNarrative: null,
    //   JournalRef: null,
    //   LegalEntity: '',
    //   MatchID: null,
    //   Orders: null,
    //   Period: 12,
    //   PostingDate: '',
    //   Product: null,
    //   ProfitCentre: null,
    //   Project: null,
    //   PurchaseOrder: null,
    //   TaxAccount: '',
    //   TaxOutcome: '',
    //   TransactionDate: '',
    //   TransactionType: null,
    //   VATCode: null,
    //   Vendor: null,
    //   WBSElement: null,
    // };
    // this.gridData.unshift(updateObj);
  }
  public updateAggregateRow1(columnName: string): void {
    if (this.rowsCount != this.gridData.length) this.gridData.shift();
    let amountaggr = '160 \n 180 \n 190';
    let updateObj = {
      ID: 0,
      AbsoluteAmount: '',
      AllocationType: null,
      Amount: amountaggr,
      AssetCode: null,
      CostCentre: null,
      Currency: 'Euro',
      Custom1: null,
      Custom2: null,
      Custom3: null,
      Custom4: '',
      Custom5: '',
      Custom6: null,
      Custom7: null,
      Custom8: null,
      Custom9: null,
      Custom10: null,
      Custom11: null,
      Custom12: null,
      Custom13: null,
      Custom14: null,
      Custom15: null,
      Custom16: null,
      Custom17: null,
      Custom18: null,
      Custom19: null,
      Custom20: null,
      Custom21: null,
      Custom22: null,
      Custom23: null,
      Custom24: null,
      Custom25: null,
      Custom26: null,
      Custom27: null,
      Custom28: null,
      Custom29: null,
      Custom30: null,
      Custom31: null,
      Custom32: null,
      Custom33: null,
      Custom34: null,
      Custom35: null,
      Custom36: null,
      Custom37: null,
      Custom38: null,
      Custom39: null,
      Custom40: null,
      Custom41: null,
      Custom42: null,
      Custom43: null,
      Custom44: null,
      Custom45: null,
      Custom46: null,
      Custom47: null,
      Custom48: null,
      Custom49: null,
      Custom50: null,
      Customer: null,
      Description: '',
      Division: null,
      DocumentDate: '',
      DocumentNo: '',
      DocumentType: '',
      FiscalYear: 0,
      GLAccount: '',
      GLAccountDescription: '',
      JournalCategory: null,
      JournalDate: null,
      JournalDescription: null,
      JournalNarrative: null,
      JournalRef: null,
      LegalEntity: '',
      MatchID: null,
      Orders: null,
      Period: 12,
      PostingDate: '',
      Product: null,
      ProfitCentre: null,
      Project: null,
      PurchaseOrder: null,
      TaxAccount: '',
      TaxOutcome: '',
      TransactionDate: '',
      TransactionType: null,
      VATCode: null,
      Vendor: null,
      WBSElement: null,
    };

    this.gridData.unshift(updateObj);
  }
  public AddColorFormat(textData: any, amount: any) {
    var fontstyle = '';
    if (amount.indexOf(')') > 0 || amount.indexOf('-') != -1) {
      fontstyle =
        textData === 'Adjustments' ||
        textData === 'Journals in' ||
        textData === 'Journals out'
          ? 'italic'
          : 'none';
      return 'color: red; font-style: ' + fontstyle + ';';
    } else {
      fontstyle =
        textData === 'Adjustments' ||
        textData === 'Journals in' ||
        textData === 'Journals out'
          ? 'italic'
          : 'none';
      return 'color: black; font-style: ' + fontstyle + ';';
    }
  }

  public AddColorFormatForAbsolute(textData: any, amount: any) {
    var fontstyle = '';
    if (
      amount != null &&
      amount != undefined &&
      (amount.indexOf(')') > 0 || amount.indexOf('-') != -1)
    ) {
      fontstyle = textData === '' || textData === null ? 'none' : 'italic';
      return 'color: red; font-style: ' + fontstyle + ';';
    } else {
      fontstyle = textData === '' || textData === null ? 'none' : 'italic';
      return 'color: black; font-style: ' + fontstyle + ';';
    }
  }
  roundedPercentage(myValue: number) {
    var result = myValue;

    return result.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  public isNumber(val: any): boolean {
    return typeof val === 'number';
  }

  // new add filter
  downbuttonClick() {
    this.sortType = 'desc';
    this.getGridData(this.startIndex);
    // this.getTransactionViewData(this.startingRowPending, this.endingRowPending);
  }
  upbuttonClick() {
    this.sortType = 'asc';
    this.getGridData(this.startIndex);
    // this.getTransactionViewData(this.startingRowPending, this.endingRowPending);
  }
  chooseFilterColumnChange(dataItem: any, e: any) {
    if (e == undefined) {
      return;
    }

    this.chooseColumnFilters.map((od, i) => {
      if (od.Sno == dataItem.Sno) {
        od.ColumnName = e.field;
        od.DataType = e.type;
      }
    });

    this.filterChooseColumnOperatorData = this.chooseColumnOperatorData.filter(
      (f) => f.DataType == e.type
    );
    //this.checkData();
  }

  chooseFilterOperatorChange(dataItem: any, e: any) {
    if (e == undefined) {
      return;
    }

    this.chooseColumnFilters.map((od, i) => {
      if (od.Sno == dataItem.Sno) {
        od.Operator = e.OperatorText;
      }
    });
    //this.checkData();
  }

  chooseFilterCriteriaChange(dataItem: any, e: any) {
    if (e == undefined) {
      return;
    }

    // this.chooseColumnFilters.map((od, i) => {
    //   if (od.Sno == dataItem.Sno) {
    //     od.Criteria = e;
    //   }
    // });
    //this.checkData();
  }

  chooseSaveFilter() {
    this.isChooseFilter = false;
    this.clearAggregations();
    var arr2 = this.chooseColumnFilters.map((v) => ({
      DbColumnName: v.ColumnName,
      FilterType: v.Operator,
      Data: v.Criteria,
      DataType: v.DataType,
    }));
    this.filterArr = arr2;

    this.getGridData(0);
  }

  chooseCancelFilter() {
    this.isChooseFilter = false;
    this.chooseColumnFilters = [];
    this.clearAggregations();
    if (this.filterArr.length > 0) {
      this.filterArr = [];
      this.getGridData(0);
    }
  }

  chooseAddFilter() {
    let maxSno: number = 0;
    if (this.chooseColumnFilters.length > 0) {
      maxSno =
        this.chooseColumnFilters[this.chooseColumnFilters.length - 1].Sno;
    }
    var formObject = this.addfilter.getRawValue();
    this.chooseColumnFilter = {
      Sno: maxSno + 1,
      ColumnName: this.selectedColumnName.field,
      Operator: this.selectedOperator.OperatorText,
      Criteria: formObject.criteria,
      DataType: this.selectedOperator.DataType,
      ColumnNameDisplay: this.selectedColumnName.title,
    };
    this.chooseColumnFilters.push(this.chooseColumnFilter);
    this.selectedColumnName = '';
    this.selectedOperator = '';
    this.addfilter.controls['criteria'].setValue('');
  }
  disableAddButton() {
    var disableAdd = false;
    let criteriaVal = this.addfilter.controls['criteria'].value;
    if (
      this.selectedColumnName == '' ||
      this.selectedOperator == '' ||
      criteriaVal == ''
    ) {
      disableAdd = true;
    }
    return disableAdd;
  }
  openChooseFilter() {
    this.isChooseFilter = true;
    this.filterChooseColumnOperatorData = this.chooseColumnOperatorData;
  }
  openAggregate(col: any) {
    let selectedOptions: [] = [];
    let amtselectedOptions: any = this.aggregateOptionsAmount.filter(
      (data) => data.checked
    );
    let absAmtSelectedOptions: any = this.aggregateOptionsAbsoluteAmount.filter(
      (data) => data.checked
    );
    let custom4SelectedOptions: any = this.aggregateOptionsCustom4.filter(
      (data) => data.checked
    );
    let custom5SelectedOptions: any = this.aggregateOptionsCustom5.filter(
      (data) => data.checked
    );
    if (
      amtselectedOptions.length > 0 ||
      absAmtSelectedOptions.length > 0 ||
      custom4SelectedOptions.length > 0 ||
      custom5SelectedOptions.length > 0
    ) {
      if (col.field == 'Amount') selectedOptions = amtselectedOptions;
      else if (col.field == 'AbsoluteAmount')
        selectedOptions = absAmtSelectedOptions;
      else if (col.field == 'Custom4') selectedOptions = custom4SelectedOptions;
      else if (col.field == 'Custom5') selectedOptions = custom5SelectedOptions;
      this.aggregationArr = selectedOptions.map((v: any) => ({
        ColumnName: col.field,
        AggregationType: v.label,
        AggregationValue: 0,
      }));
      this.getCalculatedAggregation(col.field);
    }
    if (amtselectedOptions.length == 0) this.amountAggregationvals = [];
    if (absAmtSelectedOptions == 0) this.absAmountAggregationVals = [];
    if (custom4SelectedOptions == 0) this.custom4AggregationVals = [];
    if (custom5SelectedOptions == 0) this.custom5AggregationVals = [];
  }
  getCalculatedAggregation(columnName: string) {
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.dataImportPayload = {
      StatementID: this.statementId,
      FiscalYear:
        year == 'null' || year == null ? 0 : JSON.parse(year).FiscalYearId,
      LegalEntityId: this.legalEntityId,
      EmailID: this.myGlobalObject.loggedinUserDetail.mail,
      showDetailTransFilters: this.filterArr,
      Guid: this.uniqueId,
      StartIndex: 0,
      MaxRows: 100,
      BlockID: 0,
      AccountingAreaID: 0,
      showDetailAggregations: this.aggregationArr,
      sortColumn: this.selectedcolumnSort.field,
      sortType: this.sortType,
    };

    if (this.selectedRow.type == 'AS' || this.selectedRow.type == 'SS') {
      this.showDetailsService
        .getCachedTransactionsforStatementCount(this.dataImportPayload)
        .subscribe(
          (response: any) => {
            this.formatData(response, columnName);

            this.isLoading = false;
          },
          (err) => {
            console.log('error from api', err);
            this.isLoading = false;
          }
        );
    } else if (
      this.selectedRow.type == 'LI' ||
      this.selectedRow.type == 'CLI'
    ) {
      this.showDetailsService
        .getCachedTransactionsforLineItemCount(this.dataImportPayload)
        .subscribe(
          (response: any) => {
            this.formatData(response, columnName);

            this.isLoading = false;
          },
          (err) => {
            console.log('error from api', err);
            this.isLoading = false;
          }
        );
    } else if (this.selectedRow.type == 'SA') {
      this.showDetailsService
        .getCachedTransactionsforSubAnalysisCount(this.dataImportPayload)
        .subscribe(
          (response: any) => {
            this.formatData(response, columnName);

            this.isLoading = false;
          },
          (err) => {
            console.log('error from api', err);
            this.isLoading = false;
          }
        );
    }
  }
  formatData(response: any, columnName: string) {
    let commonAggArr = [];
    let amountaggr = response.filter(
      (data: any) => data.ColumnName == 'Amount'
    );
    let absAmountaggr = response.filter(
      (data: any) => data.ColumnName == 'AbsoluteAmount'
    );
    let custom4aggr = response.filter(
      (data: any) => data.ColumnName == 'Custom4'
    );
    let custom5aggr = response.filter(
      (data: any) => data.ColumnName == 'Custom5'
    );
    if (columnName == 'Amount') commonAggArr = amountaggr;
    else if (columnName == 'AbsoluteAmount') commonAggArr = absAmountaggr;
    else if (columnName == 'Custom4') commonAggArr = custom4aggr;
    else if (columnName == 'Custom5') commonAggArr = custom5aggr;
    let avgVal, countVal, maxVal, minVal, sumVal;
    let aggrArrVal = [];

    for (let i = 0; i < commonAggArr.length; i++) {
      if (commonAggArr[i].AggregationType == 'Average') {
        avgVal = commonAggArr[i].AggregationValue.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        aggrArrVal.push('Average = ' + avgVal);
      } else if (commonAggArr[i].AggregationType == 'Maximum') {
        maxVal = commonAggArr[i].AggregationValue.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        aggrArrVal.push('Maximum = ' + maxVal);
      } else if (commonAggArr[i].AggregationType == 'Sum') {
        sumVal = commonAggArr[i].AggregationValue.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        aggrArrVal.push('Sum = ' + sumVal);
      } else if (commonAggArr[i].AggregationType == 'Minimum') {
        minVal = commonAggArr[i].AggregationValue.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        aggrArrVal.push('Minimum = ' + minVal);
      } else if (commonAggArr[i].AggregationType == 'Count') {
        countVal = commonAggArr[i].AggregationValue;
        aggrArrVal.push('Count = ' + countVal);
      }

      if (columnName == 'Amount') this.amountAggregationvals = aggrArrVal;
      else if (columnName == 'AbsoluteAmount')
        this.absAmountAggregationVals = aggrArrVal;
      else if (columnName == 'Custom4')
        this.custom4AggregationVals = aggrArrVal;
      else if (columnName == 'Custom5')
        this.custom5AggregationVals = aggrArrVal;
    }
  }
  amountAggregate() {
    return this.amountAggregationvals;
  }
  absAmountAggregate() {
    return this.absAmountAggregationVals;
  }
  custom4Aggregate() {
    return this.custom4AggregationVals;
  }
  custom5Aggregate() {
    return this.custom5AggregationVals;
  }
  clearAggregations() {
    this.absAmountAggregationVals = [];
    this.amountAggregationvals = [];
    this.custom4AggregationVals = [];
    this.custom5AggregationVals = [];
    this.aggregateOptionsAmount = [
      { id: 'Average', label: 'Average', checked: false },
      { id: 'Count', label: 'Count', checked: false },
      { id: 'Maximum', label: 'Maximum', checked: false },
      { id: 'Minimum', label: 'Minimum', checked: false },
      { id: 'Sum', label: 'Sum', checked: false },
    ];
    this.aggregateOptionsAbsoluteAmount = [
      { id: 'Average', label: 'Average', checked: false },
      { id: 'Count', label: 'Count', checked: false },
      { id: 'Maximum', label: 'Maximum', checked: false },
      { id: 'Minimum', label: 'Minimum', checked: false },
      { id: 'Sum', label: 'Sum', checked: false },
    ];
    this.aggregateOptionsCustom4 = [
      { id: 'Average', label: 'Average', checked: false },
      { id: 'Count', label: 'Count', checked: false },
      { id: 'Maximum', label: 'Maximum', checked: false },
      { id: 'Minimum', label: 'Minimum', checked: false },
      { id: 'Sum', label: 'Sum', checked: false },
    ];
    this.aggregateOptionsCustom5 = [
      { id: 'Average', label: 'Average', checked: false },
      { id: 'Count', label: 'Count', checked: false },
      { id: 'Maximum', label: 'Maximum', checked: false },
      { id: 'Minimum', label: 'Minimum', checked: false },
      { id: 'Sum', label: 'Sum', checked: false },
    ];
  }
  exportData() {
    this.isLoading = true;
    // console.log(this.dataImportPayload);
    // console.log(this.aggregationArr);
    // console.log(this.gridColumns);
    // let array = this.gridColumns.map((i) => i.field);
    // console.log(array);
    this.dataImportPayload.showDetailAggregations = [];
    this.showDetailsService
      .downloadTransactionData(this.dataImportPayload)
      .subscribe(
        (response: any) => {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          const fileName = 'TransactionsData';
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
          this.isLoading = false;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  public AddColorFormatData(textData: any) {
    if (textData.indexOf(')') > 0 || textData.indexOf('-') != -1) {
      return 'color: red; text-align: right; padding: 0px';
    } else {
      return 'text-align: right; padding: 0px';
    }
  }
  backwardPendingDataToFirst() {
    this.startIndex = 0;
    this.currentPagenum = 1;
    this.getGridData(this.startIndex);
  }
  backwardPendingData() {
    this.startIndex = this.startIndex - 100;
    this.currentPagenum = this.currentPagenum - 1;
    this.getGridData(this.startIndex);
  }
  forwardPendingData() {
    this.startIndex = this.currentPagenum * 100;
    this.currentPagenum = this.currentPagenum + 1;
    this.getGridData(this.startIndex);
  }
  forwardPendingDataToLast() {
    this.startIndex = (this.totalPages - 1) * 100;
    this.currentPagenum = this.totalPages;
    this.getGridData(this.startIndex);
  }
  handleSortColumnChange(column: any) {
    this.selectedcolumnSort = column;
    if (column == undefined)
      this.selectedcolumnSort = {
        field: 'GLAccount',
        title: 'Gl account',
        type: 'String',
      };
    this.getGridData(this.startIndex);
  }
  createExpressionButtonClick() {
    var filterArray = this.filterArr.map((obj: any) => ({
      DbColumnName: obj.DbColumnName,
      FilterType: obj.FilterType,
      Data: obj.Data,
      DataType: obj.DataType,
      operatorType:
        obj.DataType == 'numeric' || obj.DataType == 'DateTime'
          ? this.numberOperator[obj.FilterType]
          : obj.FilterType,
    }));
    this.myGlobalObject.createExpressionShortcut = filterArray;
    this.router.navigate(['/newExpression/null']);
  }
  public onDragStart(e: DragEvent, grid: GridComponent): void {
    var isValidRows = true;
    this.currentGridRef = grid;
    const draggedElement: string = (e.target as HTMLElement).innerText;
    const dragElement: any = e.target as HTMLElement;
    console.log(this.selectedRow);
    if (this.mySelection.length < 1) {
      e.preventDefault();
    }

    for (let i = 0; i < this.mySelection.length; i++) {
      if (
        i > 0 &&
        !(
          this.mySelection[i - 1]['TaxAccount'] ==
          this.mySelection[i]['TaxAccount']
        )
      )
        this.errorDisplay = true;
      // if (
      //   this.mySelection[i - 1]['TaxAccount'] !=
      //     this.mySelection[i]['TaxAccount'] &&
      //   i != 0
      // ) {
      //   console.log('first');
      //   isValidRows = false;

      //   this.errorDisplay = true;
      // }
      if (this.mySelection[i].TransactionID == null) {
        console.log('second');
        isValidRows = false;

        this.errorDisplay = true;
      }
    }

    console.log(this.mySelection);
    e.dataTransfer!.setData('list', JSON.stringify(this.mySelection));
  }
  checkSelected() {
    console.log(this.mySelection);
  }
  public close(): void {
    this.errorDisplay = false;
  }
  checkData() {
    const every = this.chooseColumnFilters.every(
      (obj) => obj.ColumnName != '' && obj.Operator != ''
    );

    this.isbuttondisable = !every;
  }

  handleOperatorChange(event: any) {
    if (
      event.OperatorText == 'Is null' ||
      event.OperatorText == 'Is not null' ||
      event.OperatorText == 'Is empty' ||
      event.OperatorText == 'Is not empty'
    ) {
      this.disableAdd = false;
      this.addfilter.controls['criteria'].disable();
    } else {
      this.disableAdd = true;
      this.addfilter.controls['criteria'].enable();
    }
    this.selectedOperator = event;
  }

  handleColumnNameChange(ColumnName: any) {
    this.selectedColumnName = ColumnName;
    this.selectedOperator = '';
    this.filterChooseColumnOperatorData = this.chooseColumnOperatorData.filter(
      (f) => f.DataType == ColumnName.type
    );
    //this.checkData();
  }

  deleteFilter(item: any) {
    if (item != null && item != undefined) {
      var index = this.chooseColumnFilters.map((x) => x.Sno).indexOf(item.Sno);
      this.chooseColumnFilters.splice(index, 1).slice(0);
      for (let i = 0; i < this.chooseColumnFilters.length; i++) {
        this.chooseColumnFilters[i].Sno = i + 1;
      }
    }
  }
}
