import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  DialogService,
  DialogRef,
  ActionsLayout,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { AnalysispackService } from 'src/app/common/services/analysispackservice/analysispack.service';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
@Component({
  selector: 'app-link-analysis-statement-popup',
  templateUrl: './link-analysis-statement-popup.component.html',
  styleUrls: ['./link-analysis-statement-popup.component.scss'],
})
export class LinkAnalysisStatementPopupComponent implements OnInit {
  //@Input() public linkStatementnodedata: any;

  constructor(
    private globalService: GlobalService,
    private dialogService: DialogService,
    public dialog: DialogRef,
    private router: Router,
    public toastr: ToastrManager,
    private analysisPackService: AnalysispackService
  ) {}
  public statementDesc: string = '';

  public linkStatementData: any = [];
  public filterlinkStatementData: any = [];
  public node: any;
  public selectedCallback = (args: any) => args.dataItem;
  public mySelectionScope: string[] = [];
  public actionsLayout: ActionsLayout = 'normal';

  ngOnInit(): void {
    this.getAllItemData();
  }

  public onFilter(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.filterlinkStatementData = process(this.linkStatementData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'Description',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      this.getAllItemData();
    }
  }

  getAllItemData() {
    let statementId = this.node.type == 'SA' ? this.node.Satementid : 0;

    this.analysisPackService
      .getAlllinkAnalysisStatementData(statementId)
      .then((res: any) => {
        this.linkStatementData = res.Item1;
        this.filterlinkStatementData = res.Item1;
        this.statementDesc = res.Item2;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  cancelClick() {
    this.dialog.close();
  }

  linkAnalysisStatement() {
    let selectedStatement = '';
    let linkStatementList: any[] = [];
    if (this.mySelectionScope != null && this.mySelectionScope != undefined) {
      let selectedData: any = this.mySelectionScope;
      for (let i = 0; i < selectedData.length; i++) {
        let item = {
          StatementSummaryId: 0,
          StatementId: this.node.Satementid,
          ChildStatementId: selectedData[i].ID,
          BlockId: this.node.id,
          DisplayOrder: 0,
        };
        linkStatementList.push(item);
      }
      this.analysisPackService
        .insertSummaryStatement(linkStatementList)
        .then((response) => {
          if (response) {
            this.toastr.successToastr(
              'Statement(s) Linked Successfully',
              'Success'
            );
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
          }
          this.displaytreePack();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  displaytreePack() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }
}
