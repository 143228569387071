export interface OfflineReferralChooseColumnFilters {
  Sno: number;
  ColumnNameDisplay: string;
  ColumnName: string;
  Operator: string;
  Criteria: string;
  DataType: string;

}

export interface OfflineReferralChooseColumnOperators {
  DataType: string;
  OperatorText: string;
}

export interface OfflineReferralChooseColumnOperators {
  DataType: string;
  OperatorText: string;
}

export const offlineReferralChooseColumnOperatorData: OfflineReferralChooseColumnOperators[] =
  [
    {
      DataType: 'text',
      OperatorText: 'Is equal to',
    },
    {
      DataType: 'text',
      OperatorText: 'Is not equal to',
    },
    {
      DataType: 'text',
      OperatorText: 'Contains',
    },
    {
      DataType: 'text',
      OperatorText: 'Does not contain',
    },
    {
      DataType: 'text',
      OperatorText: 'Starts with',
    },
    {
      DataType: 'text',
      OperatorText: 'Ends with',
    },
    {
      DataType: 'text',
      OperatorText: 'Is null',
    },
    {
      DataType: 'text',
      OperatorText: 'Is not null',
    },
    {
      DataType: 'text',
      OperatorText: 'Is empty',
    },
    {
      DataType: 'text',
      OperatorText: 'Is not empty',
    },

    {
      DataType: 'numeric',
      OperatorText: 'Is equal to',
    },
    {
      DataType: 'numeric',
      OperatorText: 'Is not equal to',
    },
    {
      DataType: 'numeric',
      OperatorText: 'Is greater than or equal to',
    },
    {
      DataType: 'numeric',
      OperatorText: 'Is greater than',
    },
    {
      DataType: 'numeric',
      OperatorText: 'Is less than or equal to',
    },
    {
      DataType: 'numeric',
      OperatorText: 'Is less than',
    },
    {
      DataType: 'numeric',
      OperatorText: 'Is null',
    },
    {
      DataType: 'numeric',
      OperatorText: 'Is not null',
    },
    {
      DataType: 'DateTime',
      OperatorText: 'Is equal to',
    },
    {
      DataType: 'DateTime',
      OperatorText: 'Is not equal to',
    },
    {
      DataType: 'DateTime',
      OperatorText: 'Is greater than or equal to',
    },
    {
      DataType: 'DateTime',
      OperatorText: 'Is greater than',
    },
    {
      DataType: 'DateTime',
      OperatorText: 'Is less than or equal to',
    },
    {
      DataType: 'DateTime',
      OperatorText: 'Is less than',
    },
    {
      DataType: 'DateTime',
      OperatorText: 'Is null',
    },
    {
      DataType: 'DateTime',
      OperatorText: 'Is not null',
    },
  ];
