import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { NewtaxaccountComponent } from '../newtaxaccount/newtaxaccount.component';
import { DeletetaxaccountComponent } from '../deletetaxaccount/deletetaxaccount.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-taxaccountsgridpopup',
  templateUrl: './taxaccountsgridpopup.component.html',
  styleUrls: ['./taxaccountsgridpopup.component.scss'],
})
export class TaxaccountsgridpopupComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective | any;
  public taxAccounts: any = [];
  public actionsLayout: ActionsLayout = 'normal';
  businesstypeList: any;
  businesstypeListDB: any;
  isSeletedBT: boolean = false;
  public gridData: any = [];
  selectedClient: string | any;
  TaxChartAccountId: any = 1;
  BusinessTypeID: any;
  businessTypeValue: any = {};
  datavalue: any;
  constructor(
    //public dialog: DialogRef,
    private cookieService: CookieService,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {
    // super(dialog);
    this.loadgridData();
  }

  ngOnInit(): void {
    this.isSeletedBT = history.state.isSeletedBT;
    this.businessTypeValue = history.state.businessTypeValue;
    // this.getAllTaxAccountCode(this.TaxChartAccountId)
    // this.getRefData();
    this.selectedClient = localStorage.getItem('SELECTEDCLIENT');
  }
  getRefData() {
    this.globalService
      .getAllBusinessTypes()
      .then((res: any) => {
        this.businesstypeListDB = res;
        if (this.isSeletedBT) {
          this.getAllTaxAccountCode(this.businessTypeValue.BusinessTypeId);
        } else {
          this.businessTypeValue = res[0];
          this.getAllTaxAccountCode(this.businessTypeValue.BusinessTypeId);
        }
        this.businesstypeList = this.businesstypeListDB.slice();
      })
      .catch((err: any) => { });
  }

  getAllTaxAccountCode(TaxChartAccountId: any) {
    this.globalService
      .getAllTaxAccountCode(TaxChartAccountId)
      .then((res: any) => {
        this.taxAccounts = res;
        this.gridData = res;
      })
      .catch((err: any) => { });
  }

  handleBTchange(selBT: any) {
    this.getAllTaxAccountCode(selBT.BusinessTypeId);
  }

  handleFilterBusinessTypeId(value: any) {
    this.businesstypeList = this.businesstypeListDB.filter(
      (s: any) =>
        s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public onFilter(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.taxAccounts = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'TaxAccountCode',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'shortdescription',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'AccountingAreaDescription',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'AccountTypeDescription',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'AccountClassDescription',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'ParentTaxAccountCode',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'TaxOutcomeDescription',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      //this.loadgridData();
      this.getAllTaxAccountCode(this.businessTypeValue.BusinessTypeId);
    }
  }

  //sorting feature
  public sort: SortDescriptor[] = [
    {
      field: 'TaxAccountCode',
      dir: 'asc',
    },
  ];
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.taxAccounts = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }

  public loadgridData() {
    this.getRefData();
  }

  openNewTaxAccountsPopup() {
    this.router.navigate(['/newTaxAccount'], { queryParams: { id: this.businessTypeValue.BusinessTypeId } }), {
      state: {
        gridBusinessType: this.businessTypeValue,
        selectedTaxAccountBT: this.businessTypeValue,
      },
    };
  }

  openEditTaxAccountsPopup(data: any) {
    this.datavalue = data;
    this.router.navigate(['/newTaxAccount'], { queryParams: { id: this.businessTypeValue.BusinessTypeId, value: this.datavalue.TaxAccountCodeId } }), {
      state: {
        gridBusinessType: this.businessTypeValue,
        editTaxAccountbyCode: data,
      },
    };
  }

  openDeleteTaxAccountsPopup(data: any) {
    const dialogRef = this.dialogService.open({
      content: DeletetaxaccountComponent,
    });
    const deleteTaxAccount = dialogRef.content
      .instance as DeletetaxaccountComponent;
    dialogRef.result.subscribe((r: any) => {
      this.getAllTaxAccountCode(this.businessTypeValue.BusinessTypeId);
    });
    deleteTaxAccount.taxAccountRowData = data;
  }
}
