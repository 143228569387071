import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from '@appkit4/angular-components/shared';
import { ActionsLayout, DialogRef } from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { TaxBusinessOutcomeService } from 'src/app/common/services/taxbusinessoutcomeservice/tax-business-outcome.service';

@Component({
  selector: 'app-new-or-edit-taxoutcomes',
  templateUrl: './new-or-edit-taxoutcomes.component.html',
  styleUrls: ['./new-or-edit-taxoutcomes.component.scss'],
})
export class NewOrEditTaxoutcomesComponent implements OnInit {
  @Input() data: any;
  @Input() action: any;

  BusinessDesc: any;
  longDesLength: number = 50;
  editLineItemForm: any;
  shortdescriptionErrorMessage: any = 'Short Description is required';
  longdescriptionErrorMessage: any = 'Long Description is required';
  accountclassErrorMessage: any = 'This need further analysing';
  public actionsLayout: ActionsLayout = 'normal';

  AccountClassDescriptionList: any;
  AccountingAreaDescriptionList: any;
  requireFurtherAnalysis: boolean | any;
  AccountingAreaDescriptionListDB: any;
  selectedClient: string | any;
  BusinessTypeId: any;
  BusinessType: any;
  editLineItemId: any;
  isEdit: boolean | any;
  LIDetailsfromDb: any;
  taxOutcomeForm: any;

  constructor(
    public dialog: DialogRef,
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private globalService: GlobalService,
    private taxbusinessOutcome: TaxBusinessOutcomeService
  ) {
    this.taxOutcomeForm = this.fb.group({
      LongDescription: '',
      ShortDescription: '',
    });
  }

  ngOnInit(): void {
    this.getRefData();
    this.selectedClient = localStorage.getItem('SELECTEDCLIENT');

    if (this.editLineItemId) {
      this.isEdit = true;
      this.getLineItemById(this.editLineItemId);
    }
    if (this.action != 'new') {
      this.taxOutcomeForm.controls['LongDescription'].setValue(
        this.data.LongDescription
      );
      this.taxOutcomeForm.controls['ShortDescription'].setValue(
        this.data.ShortDescription
      );
    }
  }

  getLineItemById(editLineItemId: any) {
    this.globalService
      .getLineItemById(editLineItemId)
      .then((res) => {
        if (res) {
          this.LIDetailsfromDb = JSON.parse(res);
          if (this.isEdit) {
            this.editLineItemForm.controls['ID'].setValue(
              this.LIDetailsfromDb.AccountingAreaID
            );
            this.editLineItemForm.controls['ID'].disable();
            this.editLineItemForm.controls['AccountingAreaID'].setValue(
              this.LIDetailsfromDb.AccountingAreaID
            );
            this.editLineItemForm.controls['AccountingAreaID'].disable();
          }
          this.requireFurtherAnalysis =
            this.LIDetailsfromDb.RequireFurtherAnalysis;
          this.BusinessTypeId = this.LIDetailsfromDb.TaxChartOfAccounts;
          this.editLineItemForm.controls['Description'].setValue(
            this.LIDetailsfromDb.Description
          );
          this.editLineItemForm.controls['ParentLineItem'].setValue(
            this.LIDetailsfromDb.AccountingArea
          );
          this.editLineItemForm.controls['AccountClass'].setValue(
            this.LIDetailsfromDb.AccountClass
          );
          this.editLineItemForm.controls['AccountingArea'].setValue(
            this.LIDetailsfromDb.AccountingArea
          );
          this.editLineItemForm.controls['AmountDisplayIndicator'].setValue(
            this.LIDetailsfromDb.AmountDisplayIndicator
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getRefData() {
    this.globalService
      .getAllAccountClass()
      .then((res) => {
        this.AccountClassDescriptionList = res;
        this.AccountClassDescriptionList =
          this.AccountClassDescriptionList.slice();
      })
      .catch((err) => {
        console.log(err);
      });

    this.globalService
      .getParentLineItem()
      .then((res) => {
        this.AccountingAreaDescriptionListDB = res;
        this.AccountingAreaDescriptionList =
          this.AccountingAreaDescriptionListDB.slice();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleAccountClassChange(value: any) {
    if (value.Description == 'Complex') {
      this.editLineItemForm.controls['ParentLineItem'].disable();
      this.editLineItemForm.controls['CreateTaxAccount'].disable();
      this.requireFurtherAnalysis = true;
    } else {
      this.editLineItemForm.controls['ParentLineItem'].enable();
      this.editLineItemForm.controls['CreateTaxAccount'].enable();
      this.requireFurtherAnalysis = false;
    }
  }

  handleFilterParentLineItem(value: any) {
    this.AccountingAreaDescriptionList =
      this.AccountingAreaDescriptionListDB.filter(
        (s: any) =>
          s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
  }

  cancelClick() {
    this.dialog.close();
  }

  saveBusinessTaxOutcome() {
    if (this.action == 'edit') {
      let data = {
        _id: this.data._id,
        ShortDescription: this.taxOutcomeForm.controls.ShortDescription.value,
        LongDescription: this.taxOutcomeForm.controls.LongDescription.value,
        RateAllowableCY: this.data.RateAllowableCY,
        RateAllowableEventually: this.data.RateAllowableEventually,
        PermanentTimingDiff: this.data.PermanentTimingDiff,
        HelpId: this.data.HelpId,
        DefaultOption: this.data.DefaultOption,
      };
      this.taxbusinessOutcome.editBusinessOutcome(data).subscribe(
        (res: any) => {
          if (res == 'Tax Outcome updated successfully') {
            this.toastr.successToastr(
              'Tax outcome updated successfully',
              'Success'
            );
            this.dialog.close();
          } else if ((res = 'Tax Outcome Description already exists')) {
            this.toastr.warningToastr(
              'Tax Outcome Description already exists',
              'Warning!'
            );
            this.dialog.close();
          } else if ((res = 'Tax Outcome is not available')) {
            this.toastr.warningToastr(
              'Tax Outcome is not available',
              'Warning!'
            );
            this.dialog.close();
          } else {
            this.toastr.warningToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
            this.dialog.close();
          }
        },
        (error) => {
          console.log(error);
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.dialog.close();
        }
      );
    } else if (this.action == 'new') {
      let data = {
        _id: 0,
        ShortDescription: this.taxOutcomeForm.controls.ShortDescription.value,
        LongDescription: this.taxOutcomeForm.controls.LongDescription.value,
        RateAllowableCY: 0,
        RateAllowableEventually: 0,
        PermanentTimingDiff: true,
        HelpId: '',
        DefaultOption: true,
      };
      this.taxbusinessOutcome.inseetBusinessOutcome(data).subscribe(
        (res: any) => {
          if (res == 'Tax Outcome added successfully') {
            this.toastr.successToastr(
              'Tax outcome added successfully',
              'Success'
            );
            this.dialog.close();
          } else if ((res = 'Tax Outcome Description already exists')) {
            this.toastr.warningToastr(
              'Tax Outcome Description already exists',
              'Warning!'
            );
            this.dialog.close();
          } else if ((res = 'Tax Outcome is not available')) {
            this.toastr.warningToastr(
              'Tax Outcome is not available',
              'Warning!'
            );
            this.dialog.close();
          }
        },
        (error) => {
          console.log(error);
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.dialog.close();
        }
      );
    } else if (this.action == 'copy') {
      let data = {
        _id: this.data._id,
        ShortDescription: this.taxOutcomeForm.controls.ShortDescription.value,
        LongDescription: this.taxOutcomeForm.controls.LongDescription.value,
        RateAllowableCY: this.data.RateAllowableCY,
        RateAllowableEventually: this.data.RateAllowableEventually,
        PermanentTimingDiff: this.data.PermanentTimingDiff,
        HelpId: this.data.HelpId,
        DefaultOption: this.data.DefaultOption,
      };
      this.taxbusinessOutcome.inseetBusinessOutcome(data).subscribe(
        (res: any) => {
          if (res == 'Tax Outcome added successfully') {
            this.toastr.successToastr(
              'Tax outcome added successfully',
              'Success'
            );
            this.dialog.close();
          } else if (res == 'Tax Outcome already exists') {
            this.toastr.warningToastr('Tax outcome already exists', 'Warning!');
            this.dialog.close();
          } else if ((res = 'Tax Outcome Description already exists')) {
            this.toastr.warningToastr(
              'Tax Outcome Description already exists',
              'Warning!'
            );
            this.dialog.close();
          } else if ((res = 'Tax Outcome is not available')) {
            this.toastr.warningToastr(
              'Tax Outcome is not available',
              'Warning!'
            );
            this.dialog.close();
          }
        },
        (error) => {
          console.log(error);
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.dialog.close();
        }
      );
    }
  }
  omit_special_char(event: any) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }
}
