import { Component, OnInit, Input } from '@angular/core';
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from '@progress/kendo-angular-dialog';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-delete-keyword',
  templateUrl: './delete-keyword.component.html',
  styleUrls: ['./delete-keyword.component.scss'],
})
export class DeleteKeywordGroupComponent implements OnInit {
  @Input() public dataItem: any;
  deleteTitle: string = '';
  deleteMessage: string = '';
  deleteId: any = '';
  deleteLevel: any = '';
  constructor(
    public dialog: DialogRef,
    private dialogService: DialogService,
    private globalService: GlobalService,
    public toastr: ToastrManager
  ) {}

  ngOnInit(): void {
    if (this.dataItem.level === 'Folder') {
      this.deleteId = this.dataItem.id;
      this.deleteLevel = this.dataItem.level;
      this.deleteTitle = 'Delete ' + this.dataItem.level.toLowerCase();
      this.deleteMessage =
        'You have selected to permanently delete the selected folder. Any keyword groups and keywords within the folder will also be deleted. Are you sure you wish to continue?';
    } else if (this.dataItem.level === 'Group') {
      this.deleteId = this.dataItem.id;
      this.deleteLevel = this.dataItem.level;
      this.deleteTitle = 'Delete keyword ' + this.dataItem.level.toLowerCase();
      this.deleteMessage =
        'You have selected to permanently delete the selected keyword group. Any keywords associated with the group will also be deleted. Are you sure you wish to continue?';
    } else if (this.dataItem.level === 'Keyword') {
      this.deleteLevel = this.dataItem.level;
      this.deleteId = this.dataItem.id;
      this.deleteTitle = 'Delete ' + this.dataItem.level.toLowerCase();
      this.deleteMessage =
        'You have selected to permanently delete the selected keyword.  Are you sure you wish to continue?';
    }
  }

  deleteKeywordGroup() {
    if (this.dataItem.level === 'Folder') {
      this.globalService.DeleteKeywordGroupFolder(this.dataItem.id).subscribe(
        (response) => {
          if (response == 'The keyword Group Folder is deleted successfully') {
            this.toastr.successToastr(
              'The keyword group folder is deleted successfully',
              'Success'
            );
            this.dialog.close();
          } else {
            this.toastr.errorToastr(response, 'Error');
            return;
          }
        },
        (error) => {
          console.log(error);
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      );
    } else if (this.dataItem.level === 'Group') {
      this.globalService.DeleteKeywordGroup(this.deleteId).subscribe(
        (response) => {
          if (response == 'The keyword Group is deleted successfully.') {
            this.toastr.successToastr(
              'The keyword group is deleted successfully.',
              'Success'
            );
            this.dialog.close();
          } else {
            this.toastr.errorToastr(response, 'Error');
            this.dialog.close();
            return;
          }
        },
        (error) => {
          console.log(error);
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      );
    } else if (this.dataItem.level === 'Keyword') {
      this.globalService.DeleteKeyword(this.deleteId).subscribe(
        (response) => {
          if (response == 'The keyword is deleted successfully.') {
            this.toastr.successToastr(response, 'Success');
            this.dialog.close();
          } else {
            this.toastr.errorToastr(response, 'Error');
            this.dialog.close();
            return;
          }
        },
        (error) => {
          console.log(error);
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      );
    }
  }
  cancelClick() {
    this.dialog.close();
  }
}
