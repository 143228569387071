<!-- Header -->
<div class="header" style="font-size: 24px;">Manage line item</div>
<!-- Copy line item form -->
<div class="container-fluid" style="padding:0px 24px">
    <div class="row">
        <div class="col-md-8 col-xs-12">
            <h6>Line item details - Business type: {{BusinessDesc}}</h6>
            <form class="k-form" [formGroup]="copyLineItemForm">
                <kendo-formfield>
                    <kendo-label [for]="Description" text="Description:*"></kendo-label>
                    <input formControlName="Description" kendoTextBox #Description required />
                    <kendo-formerror>{{descriptionErrorMessage}}</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="AccountClass" text="Account class:*"></kendo-label>
                    <kendo-combobox #AccountClass required formControlName="AccountClass"
                        [data]="AccountClassDescriptionList" [filterable]="true" textField="Description" valueField="ID"
                        (valueChange)="handleAccountClassChange($event)">
                        
                    </kendo-combobox>
                    <kendo-formerror>{{accountclassErrorMessage}}</kendo-formerror>
                    <div *ngIf="this.copyLineItemForm.getRawValue().AccountClass"><div *ngIf="this.copyLineItemForm.getRawValue().AccountClass.ID==3">
                        <kendo-formerror>This needs analysing further</kendo-formerror>
                    </div></div>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="ParentLineItem" text="Parent line item:"></kendo-label>
                    <kendo-combobox #ParentLineItem formControlName="ParentLineItem"
                        [data]="AccountingAreaDescriptionList" [filterable]="true" textField="Description"
                        valueField="LineID" 
                        (valueChange)="handleParentLineItemChange($event)"
                        (filterChange)="handleFilterParentLineItem($event)">
                    </kendo-combobox>
                </kendo-formfield>
                <br />
                <kendo-formfield>
                    <kendo-label [for]="CreateTaxAccount" text="Create tax account:">&nbsp;&nbsp;<input type="checkbox"
                            #CreateTaxAccount kendoCheckBox formControlName="CreateTaxAccount" /></kendo-label>
                </kendo-formfield>
            </form>
            <!-- Click to Actions -->
            <div class="clickToActions" style="padding: 20px 0px;">
                
                <ap-button [btnType]="'secondary'" class="cancelButton" [label]="'Cancel'" (click)="cancelClick()" ></ap-button>
                <ap-button [btnType]="'primary'" class="okButton" [label]="'Save'" [disabled]="!copyLineItemForm.valid" style="margin-right: 6px;"
                themeColor="primary"
                (click)="saveCopyLineItem()"></ap-button>
            </div>
        </div>
        <div class="col-md-4 col-xs-12"></div>
    </div>
</div>
