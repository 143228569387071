<kendo-dialog-titlebar (close)="closeButtonClick()">
  <div style="font-size: 18px; line-height: 1.1em">Add keyword</div>
</kendo-dialog-titlebar>
<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>
<div class="container-fluid">
  <form class="k-form" [formGroup]="addKeywordForm">
    <div class="row">
      <div class="col-md-12 col-xs-12">
        <div class="dataColumnText" style="font-weight: bold">Data columns</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 col-xs-12">
        <kendo-formfield style="margin-top: 10px">
          <kendo-combobox
            #dataColumns
            formControlName="dataColumns"
            [kendoDropDownFilter]="filterSettings"
            [data]="dataColumnsList"
            [filterable]="true"
            textField="Name"
            valueField="PropertyName"
            width="25%"
            [(ngModel)]="selecteddataColumns"
            (valueChange)="handledataColumnChange($event)"
            placeholder="Select Data Column"
          >
          </kendo-combobox>
        </kendo-formfield>
        <div class="dataColumnText" style="font-weight: bold">Habitat</div>
        <kendo-formfield style="margin-top: 10px">
          <kendo-combobox
            #habitat
            formControlName="habitat"
            [kendoDropDownFilter]="filterSettings"
            [data]="habitatList"
            [filterable]="true"
            textField="Name"
            valueField="HabitatId"
            width="25%"
            [(ngModel)]="selectedhabitat"
            (valueChange)="handlehabitatChange($event)"
            placeholder="Choose habitat"
          >
          </kendo-combobox>
        </kendo-formfield>
        <div class="dataColumnText" style="font-weight: bold">Keyword list</div>
        <div class="searchContainer">
          <ap-search
            [placeholder]="'Search here'"
            [searchType]="'secondary'"
            style="width: 100%; padding: 0px !important"
            [disabled]="false"
            (onSearch)="onFilter($event)"
          ></ap-search>
        </div>
        <kendo-grid
          #firstGrid
          [data]="keywordCountList"
          [pageable]="false"
          [width]="37"
          [selectable]="true"
          (selectionChange)="onGridSelectionChange($event)"
          [sortable]="true"
          [sort]="sort"
          (sortChange)="sortChange($event)"
          class="autoGridHeight"
        >
          <kendo-grid-checkbox-column
            [showSelectAll]="true"
          ></kendo-grid-checkbox-column>
          <kendo-grid-column
            field="Keyword"
            title="Keyword"
            [headerStyle]="{
              'font-weight': 'bold',
              'text-align': 'left',
              color: '#d04a02'
            }"
          ></kendo-grid-column>
          <kendo-grid-column
            field="Count"
            title="Count"
            [headerStyle]="{
              'font-weight': 'bold',
              'text-align': 'left',
              color: '#d04a02'
            }"
          ></kendo-grid-column>
        </kendo-grid>
      </div>
      <div class="col-md-7 col-xs-12">
        <kendo-treelist
          style="margin-top: 11px"
          [kendoTreeListHierarchyBinding]="keywordData"
          childrenField="items"
          [sortable]="{ mode: 'multiple' }"
          (add)="addHandler($event)"
          (edit)="editHandler($event)"
          (remove)="removeHandler($event)"
          (save)="saveHandler($event)"
          (cancel)="cancelHandler($event)"
          kendoTreeListExpandable
          kendoTreeListSelectable
          expandBy="id"
          [(expandedKeys)]="expandedNames"
          (expandedKeysChange)="onExpandedNamesChange($event)"
          [selectable]="settings"
          [(selectedItems)]="selected"
          class="autoTreeHeight"
        >
          <ng-template kendoTreeListToolbarTemplate>
            <ap-button
              kendoTreeListAddCommand
              [btnType]="'primary'"
              [label]="'Add new'"
            ></ap-button>
            <ap-button
              [btnType]="'primary'"
              (click)="MapData()"
              [label]="'Map data'"
            ></ap-button>
            <kendo-label style="color: red" text="{{ keyWord }}"></kendo-label>
          </ng-template>
          <kendo-treelist-column
            [expandable]="true"
            field="description"
            title="Keyword"
          ></kendo-treelist-column>
          <kendo-treelist-command-column [width]="185">
            <ng-template
              kendoTreeListCellTemplate
              let-isNew="isNew"
              let-cellContext="cellContext"
            >
              <!-- "Add Child" command directive, will not be visible in edit mode -->
              <button [kendoTreeListAddCommand]="cellContext" title="Add child">
                <span class="Appkit4-icon icon-circle-plus-outline"></span>
              </button>
              <!-- "Edit" command directive, will not be visible in edit mode -->
              <button [kendoTreeListEditCommand]="cellContext" title="Edit">
                <span class="Appkit4-icon icon-edit-outline"></span>
              </button>
              <!-- "Remove" command directive, will not be visible in edit mode -->
              <button [kendoTreeListRemoveCommand]="cellContext" title="Delete">
                <span class="Appkit4-icon icon-delete-outline"></span>
              </button>
              <!-- "Save" command directive, will be visible only in edit mode -->
              <button
                [kendoTreeListSaveCommand]="cellContext"
                title="{{ isNew ? 'Add' : 'Update' }}"
              >
                <span class="Appkit4-icon icon-save-outline"></span>
              </button>
              <!-- "Cancel" command directive, will be visible only in edit mode -->
              <button
                [kendoTreeListCancelCommand]="cellContext"
                icon="cancel"
                title="{{ isNew ? 'Discard changes' : 'Cancel' }}"
              ></button>
            </ng-template>
          </kendo-treelist-command-column>
        </kendo-treelist>
      </div>
    </div>
  </form>
</div>
<!-- <kendo-dialog-actions>     -->
<!-- <div class="clickToActions" style="padding: 20px 0px;">
      <ap-button [btnType]="'primary'" [label]="'Save'" 
      themeColor="primary"
      (click)="saveChanges()"
      [disabled]="!taxoutcomeMappingForm.valid"></ap-button>
      <ap-button [btnType]="'secondary'" [label]="'Cancel'" (click)="closeButtonClick()" style="margin-right: 6px;"></ap-button>
    </div> -->
<!-- </kendo-dialog-actions> -->
