<kendo-dialog-titlebar (close)="onClose($event)">
  <div style="font-size: 20px; line-height: 1.3em">Select entity</div>
</kendo-dialog-titlebar>

<div>
  <form class="k-form" [formGroup]="formGroup">
    <kendo-formfield>
      <label for="client">Entity*</label>
      <kendo-combobox
        [data]="clientsList"
        formControlName="client"
        textField="text"
        valueField="value"
        placeholder="Select entity"
        [kendoDropDownFilter]="filterSettings"
      >
      </kendo-combobox>
      <kendo-formerror>Error: Entity is required</kendo-formerror>
    </kendo-formfield>
  </form>
</div>

<div class="clickToActions" style="padding-top: 22px">
  <ap-button
    [btnType]="'primary'"
    [label]="'Submit'"
    (onClick)="onConfirmAction()"
    [disabled]="!formGroup.valid"
    type="submit"
    style="margin-left: 7px"
  ></ap-button>
  <ap-button
    [btnType]="'secondary'"
    [label]="'Cancel'"
    (onClick)="onCancelAction()"
  ></ap-button>
  <!-- <button kendoButton class='btn-pwc-secondary' (click)="onCancelAction()">Cancel</button>
    <button kendoButton
    class='actionButton btn-pwc-primary'
      (click)="onConfirmAction()"
      themeColor="primary"
      [disabled]="!formGroup.valid"
    >
        Submit
    </button> -->
</div>
