<kendo-window
  *ngIf="opened"
  [draggable]="isDraggable"
  (close)="openClose(false)"
  [width]="300"
  [height]="300"
  [(state)]="windowState"
  title="Choose columns"
  style="position: fixed !important"
>
  <li *ngFor="let column of columns" class="">
    <input
      type="checkbox"
      id="{{ column }}"
      kendoCheckBox
      [checked]="!isHidden(column)"
      (change)="hideColumn(column)"
    />
    <label class="k-checkbox-label" for="{{ column }}">{{ column }}</label>
  </li>
  &nbsp;
  <ap-button
    [btnType]="'primary'"
    [label]="'Save'"
    themeColor="primary"
    (click)="saveColumn()"
    style="margin-right: 6px"
  ></ap-button>
</kendo-window>
<kendo-window
  *ngIf="isChooseFilter"
  [draggable]="isDraggable"
  (close)="openClose(false)"
  [width]="800"
  [height]="550"
  [(state)]="windowState"
  style="
    position: fixed !important;
    left: 250px !important;
    top: 0px !important;
  "
>
  <kendo-window-titlebar>
    <span class="k-window-title">Choose filter</span>
    <button kendoWindowMaximizeAction></button>
    <button kendoWindowRestoreAction></button>
  </kendo-window-titlebar>
  <div>
    <div *ngIf="isChooseFilter">
      <div>
        <form class="k-form" [formGroup]="addfilter">
          <kendo-formfield>
            <kendo-label [for]="columnName" text="Column name"></kendo-label>
            <kendo-combobox
              #columnName
              formControlName="columnName"
              [data]="gridColumns"
              [filterable]="true"
              textField="title"
              valueField="field"
              width="25%"
              [(ngModel)]="selectedColumnName"
              (valueChange)="handleColumnNameChange($event)"
              style="width: 83%"
              [kendoDropDownFilter]="filterSettings"
            >
            </kendo-combobox>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label [for]="Operator" text="Operator"></kendo-label>
            <kendo-combobox
              #Operator
              formControlName="Operator"
              [data]="filterChooseColumnOperatorData"
              [filterable]="true"
              textField="OperatorText"
              valueField="OperatorText"
              width="25%"
              [(ngModel)]="selectedOperator"
              (valueChange)="handleOperatorChange($event)"
              [kendoDropDownFilter]="filterSettings"
              style="width: 83%"
            >
            </kendo-combobox>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label [for]="criteria" text="Criteria*"></kendo-label>
            <input
              formControlName="criteria"
              kendoTextBox
              #criteria
              style="width: 83%"
            />

            <!-- <kendo-formerror>{{ descriptionErrorMessage }}</kendo-formerror> -->
            <kendo-formfield
              style="
                float: inline-end;
                position: relative;
                bottom: 20px;
                right: 70px;
              "
            >
              <ap-button
                [icon]="'plus-outline'"
                kendoButton
                [compact]="true"
                [add]="true"
                kendoTooltip
                title="Add"
                [disabled]="
                  disableAdd && this.addfilter.controls['criteria'].value == ''
                "
                (onClick)="chooseAddFilter()"
                style="margin-bottom: 5px"
              ></ap-button>
            </kendo-formfield>
          </kendo-formfield>
        </form>
      </div>
      <kendo-grid
        #gridOperator
        style="width: 100%; margin-top: 6px; height: 190px"
        [data]="chooseColumnFilters"
        [selectable]="true"
        [navigable]="true"
      >
        <kendo-grid-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'left'
          }"
          field="IsActive"
          title="Actions"
          [width]="80"
        >
          <ng-template kendoGridCellTemplate let-data>
            <button
              kendoButton
              name="delete"
              kendoTooltip
              title="Delete"
              (click)="deleteFilter(data)"
            >
              <span class="Appkit4-icon icon-delete-outline"></span>
            </button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="Sno"
          title="Sno"
          [width]="50"
        ></kendo-grid-column>

        <kendo-grid-column
          field="ColumnNameDisplay"
          title="Column name"
          [width]="250"
        >
        </kendo-grid-column>
        <kendo-grid-column field="Operator" title="Operator" [width]="250">
        </kendo-grid-column>
        <kendo-grid-column field="Criteria" title="Criteria">
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
  <div class="clickToActions" style="margin-top: 10px">
    <button
      kendoButton
      class="actionButton chooseColumnsButton"
      (click)="chooseCancelFilter()"
      [disabled]="isLoading"
      style="margin-top: 5px; margin-left: 5px"
    >
      Clear and close
    </button>
    <button
      kendoButton
      class="actionButton chooseColumnsButton"
      (click)="chooseSaveFilter()"
      [disabled]="isLoading || this.chooseColumnFilters.length < 1"
      style="margin-top: 5px"
    >
      Apply filter
    </button>
  </div>
</kendo-window>
<kendo-chunkprogressbar
  *ngIf="isLoading"
  [chunkCount]="10"
  [min]="-10"
  [max]="10"
  [indeterminate]="true"
  [value]="30"
  style="width: 100%"
></kendo-chunkprogressbar>
<!-- <div kendoTooltip
      showOn="none"
      [tooltipTemplate]="template"
      filter=".k-grid-content td"
      (mouseover)="showTooltip($event)"> -->
<div *ngIf="!isChooseFilter">
  <kendo-grid
    *ngIf="
      this.levelType == 'AS' ||
      this.levelType == 'SA' ||
      this.levelType == 'LI' ||
      this.levelType == 'SS' ||
      this.levelType == 'CLI'
    "
    [kendoGridBinding]="gridData"
    [resizable]="true"
    class="autoTreeHeight"
    [columnMenu]="columnMenu"
    scrollable="virtual"
    kendoTooltip
    [tooltipTemplate]="template"
    [reorderable]="true"
    #firstGrid
    (dragstart)="onDragStart($event, firstGrid)"
    [rowClass]="rowClass"
    [kendoGridSelectBy]="selectedCallback"
    [(selectedKeys)]="mySelection"
    [selectable]="selectableSettings"
  >
    <ng-template kendoGridToolbarTemplate>
      <button
        draggable="true"
        kendoButton
        class="dragButton"
        title="Drag to move transactions"
        (click)="checkSelected()"
        style="margin-right: 5px; height: 30px"
      >
        <span class="Appkit4-icon icon-move-outline"></span>
      </button>
      <button
        kendoButton
        (click)="exportData()"
        class="actionButton"
        [disabled]="isLoading"
        kendoTooltip
        title="Export to excel"
      >
        <span class="Appkit4-icon icon-download-outline"></span>
      </button>
      <button
        kendoButton
        class="actionButton"
        (click)="openWindow()"
        [disabled]="isLoading"
        kendoTooltip
        title="Choose columns"
      >
        <span class="Appkit4-icon icon-bridge-chart-outline"></span>
      </button>
      <button
        kendoButton
        class="actionButton chooseColumnsButton btn-pwc-primary"
        (click)="openChooseFilter()"
        [disabled]="isLoading"
        style="margin-left: 5px"
        *ngIf="!isChooseFilter"
      >
        {{ filterArr.length > 0 ? "Update filter" : "Choose filter" }}
      </button>

      <div class="row" style="width: 500px">
        <kendo-label text="Sort by:" style="font-size: 14px">
          <kendo-combobox
            [data]="gridColumns"
            style="width: 40%; margin-left: 10px"
            [(ngModel)]="selectedcolumnSort"
            [kendoDropDownFilter]="filterSettings"
            textField="title"
            valueField="field"
            (valueChange)="handleSortColumnChange($event)"
          >
          </kendo-combobox>
          &nbsp;&nbsp;
          <button
            kendoButton
            name="up"
            (click)="upbuttonClick()"
            [class.sortactivated]="sortType == 'asc'"
            style="margin-right: 5px; height: 30px"
          >
            <span class="Appkit4-icon icon-arrow-up-small-outline"></span>
          </button>
          &nbsp;
          <button
            kendoButton
            name="down"
            (click)="downbuttonClick()"
            [class.sortactivated]="sortType == 'desc'"
            style="margin-right: 5px; height: 30px"
          >
            <span class="Appkit4-icon icon-arrow-down-small-outline"></span>
          </button>
          <button
            [disabled]="filterArr.length < 1"
            kendoButton
            title="Create expression from filter"
            (click)="createExpressionButtonClick()"
            style="margin-right: 5px; height: 30px"
          >
            <span class="Appkit4-icon icon-query-outline"></span>
          </button>
        </kendo-label>
      </div>
    </ng-template>
    <span class="Appkit4-icon icon-xls-outline"></span>
    <span class="Appkit4-icon icon-xls-outline"></span>
    <kendo-grid-checkbox-column
      [showSelectAll]="true"
      [width]="45"
      [columnMenu]="false"
    ></kendo-grid-checkbox-column>
    <ng-container *ngFor="let column of gridColumns">
      <kendo-grid-column
        *ngIf="
          !(
            column.title == 'Amount' ||
            column.title == 'Absolute amount' ||
            column.title == 'Custom4' ||
            column.title == 'Custom5'
          )
        "
        title="{{ column.title }}"
        field="{{ column.field }}"
        [width]="
          column.field == 'GLAccount'
            ? 100
            : 'GLAccountDescription'
            ? 150
            : 'Description'
            ? 250
            : 'Taxaccount'
            ? 160
            : 'TaxOutcome'
            ? 115
            : 'LegalEntity'
            ? 80
            : 'FiscalYear'
            ? 30
            : 'DocumentType'
            ? 30
            : 'DocumentNo'
            ? 160
            : 'DocumentDate'
            ? 115
            : 'PostingDate'
            ? 115
            : 'TransactionDate'
            ? 115
            : 'Period'
            ? 30
            : 'Currency'
            ? 50
            : 'CostCentre'
            ? 115
            : 'Vendor'
            ? 160
            : 'Customer'
            ? 80
            : 'Division'
            ? 80
            : 'Product'
            ? 80
            : 'Project'
            ? 80
            : 'AssetCode'
            ? 30
            : 'VATCode'
            ? 30
            : 'PurchaseOrder'
            ? 80
            : 'Order'
            ? 80
            : 'WBSElement'
            ? 80
            : 'ProfitCentre'
            ? 80
            : 'MatchID'
            ? 80
            : 'AllocationType'
            ? 80
            : 'JournalDescription'
            ? 80
            : 'JournalRef'
            ? 80
            : 'JournalNarrative'
            ? 115
            : 'JournalCategory'
            ? 80
            : 'JournalDate'
            ? 115
            : 'TransactionType'
            ? 10
            : 30
        "
        [headerStyle]="{ 'font-weight': 'bold' }"
        [columnMenu]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            class="dottedtext"
            [ngStyle]="{
              color:
                isNumber(dataItem[column.field]) && dataItem[column.field] < 0
                  ? 'red'
                  : 'black',
              'font-style':
                dataItem.JournalDescription == '' ||
                dataItem.JournalDescription == null
                  ? 'none'
                  : 'italic'
            }"
            >{{ dataItem[column.field] }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <!-- Amount column field -->
      <kendo-grid-column
        *ngIf="column.title === 'Amount'"
        title="{{ column.title }}"
        field="{{ column.field }}"
        [width]="200"
        [headerStyle]="{ 'font-weight': 'bold' }"
        [style]="{ 'text-align': 'right' }"
      >
        <ng-template
          kendoGridColumnMenuTemplate
          let-service="service"
          let-column="column"
        >
          <li
            *ngFor="let aggregateOption of aggregateOptionsAmount"
            class=""
            style="margin: 4px 10px"
          >
            <input
              type="checkbox"
              id="{{ aggregateOption.id }}"
              kendoCheckBox
              [checked]="aggregateOption.checked"
              (change)="
                aggregateMethod($event, aggregateOption.id, column.field)
              "
            />
            <label class="k-checkbox-label" for="{{ aggregateOption.id }}">
              {{ aggregateOption.id }}
            </label>
          </li>
          <ap-button
            [btnType]="'primary'"
            [label]="'Apply'"
            themeColor="primary"
            (click)="openAggregate(column)"
            style="margin-right: 6px"
          ></ap-button>
        </ng-template>
        <ng-template kendoGridCellTemplate class="rightAlign" let-dataItem>
          <span
            class="dottedtext"
            [style]="
              AddColorFormat(
                dataItem.GLAccountDescription,
                dataItem.Amount | tonumber
              )
            "
            >{{
              (dataItem.Amount == null ? dataItem.Amount : dataItem.Amount)
                | tonumber
            }}
          </span>
        </ng-template>
        <ng-template
          kendoGridFooterTemplate
          let-column
          let-columnIndex="columnIndex"
        >
          <li *ngFor="let x of amountAggregate()">
            {{ x }}
          </li>
        </ng-template>
      </kendo-grid-column>
      <!-- AbsoluteAmount field -->
      <kendo-grid-column
        *ngIf="column.title === 'Absolute amount'"
        title="{{ column.title }}"
        field="{{ column.field }}"
        [width]="200"
        [headerStyle]="{ 'font-weight': 'bold' }"
        [style]="{ 'text-align': 'right' }"
      >
        <ng-template
          kendoGridColumnMenuTemplate
          let-service="service"
          let-column="column"
        >
          <li
            *ngFor="let aggregateOption of aggregateOptionsAbsoluteAmount"
            class=""
            style="margin: 4px 10px"
          >
            <input
              type="checkbox"
              id="{{ aggregateOption.id }}"
              kendoCheckBox
              [checked]="aggregateOption.checked"
              (change)="
                aggregateMethod($event, aggregateOption.id, column.field)
              "
            />
            <label class="k-checkbox-label" for="{{ aggregateOption.id }}">
              {{ aggregateOption.id }}
            </label>
          </li>
          <ap-button
            [btnType]="'primary'"
            [label]="'Apply'"
            themeColor="primary"
            (click)="openAggregate(column)"
            style="margin-right: 6px"
          ></ap-button>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            class="dottedtext"
            [ngStyle]="{
              color: dataItem.AbsoluteAmount < 0 ? 'red' : 'black',
              'font-style':
                dataItem.JournalDescription == '' ||
                dataItem.JournalDescription == null
                  ? 'none'
                  : 'italic'
            }"
            >{{
              dataItem.AbsoluteAmount == null
                ? dataItem.AbsoluteAmount
                : roundedPercentage(dataItem.AbsoluteAmount)
            }}
          </span>
        </ng-template>
        <ng-template
          kendoGridFooterTemplate
          let-column
          let-columnIndex="columnIndex"
        >
          <li *ngFor="let x of absAmountAggregate()">
            {{ x }}
          </li>
        </ng-template>
      </kendo-grid-column>
      <!-- Custom4 field -->
      <kendo-grid-column
        *ngIf="column.title === 'Custom4'"
        title="{{ column.title }}"
        field="{{ column.field }}"
        [width]="200"
        [headerStyle]="{ 'font-weight': 'bold' }"
      >
        <ng-template
          kendoGridColumnMenuTemplate
          let-service="service"
          let-column="column"
        >
          <li
            *ngFor="let aggregateOption of aggregateOptionsCustom4"
            class=""
            style="margin: 4px 10px"
          >
            <input
              type="checkbox"
              id="{{ aggregateOption.id }}"
              kendoCheckBox
              [checked]="aggregateOption.checked"
              (change)="
                aggregateMethod($event, aggregateOption.id, column.field)
              "
            />
            <label class="k-checkbox-label" for="{{ aggregateOption.id }}">{{
              aggregateOption.id
            }}</label>
          </li>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            class="dottedtext"
            [ngStyle]="{
              color: dataItem.Custom4 < 0 ? 'red' : 'black',
              'font-style':
                dataItem.JournalDescription == '' ||
                dataItem.JournalDescription == null
                  ? 'none'
                  : 'italic'
            }"
            >{{
              dataItem.Custom4 == null
                ? dataItem.Custom4
                : roundedPercentage(dataItem.Custom4)
            }}</span
          >
        </ng-template>
        <ng-template
          kendoGridFooterTemplate
          let-column
          let-columnIndex="columnIndex"
        >
          <li *ngFor="let x of custom4Aggregate()">
            {{ x }}
          </li>
        </ng-template>
      </kendo-grid-column>
      <!-- Custom5 field -->
      <kendo-grid-column
        *ngIf="column.title === 'Custom5'"
        title="{{ column.title }}"
        field="{{ column.field }}"
        [width]="200"
        [headerStyle]="{ 'font-weight': 'bold' }"
      >
        <ng-template
          kendoGridColumnMenuTemplate
          let-service="service"
          let-column="column"
        >
          <li
            *ngFor="let aggregateOption of aggregateOptionsCustom5"
            class=""
            style="margin: 4px 10px"
          >
            <input
              type="checkbox"
              id="{{ aggregateOption.id }}"
              kendoCheckBox
              [checked]="aggregateOption.checked"
              (change)="
                aggregateMethod($event, aggregateOption.id, column.field)
              "
            />
            <label class="k-checkbox-label" for="{{ aggregateOption.id }}">{{
              aggregateOption.id
            }}</label>
          </li>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            class="dottedtext"
            [ngStyle]="{
              color: dataItem.Custom5 < 0 ? 'red' : 'black',
              'font-style':
                dataItem.JournalDescription == '' ||
                dataItem.JournalDescription == null
                  ? 'none'
                  : 'italic'
            }"
            >{{
              dataItem.Custom5 == null
                ? dataItem.Custom5
                : roundedPercentage(dataItem.Custom5)
            }}</span
          >
        </ng-template>
        <ng-template
          kendoGridFooterTemplate
          let-column
          let-columnIndex="columnIndex"
        >
          <li *ngFor="let x of custom5Aggregate()">
            {{ x }}
          </li>
        </ng-template>
      </kendo-grid-column>
    </ng-container>

    <!-- <ng-template ngFor [ngForOf]="dynamicColumns" let-column>
    <kendo-grid-column
      title="{{ column.DisplayName }}"
      field="{{ column.DbColumnName }}"
      [width]="120"
      [headerStyle]="{ 'font-weight': 'bold' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem7>
        <span
          class="dottedtext"
        >
          {{dataItem[column.DbColumnName]}}
        </span>
      </ng-template>
    </kendo-grid-column>
    
  </ng-template> -->
    <kendo-grid-excel fileName="Transactions.xlsx"></kendo-grid-excel>
  </kendo-grid>
  <div class="row" *ngIf="!isLoading && displayedRecords != 0">
    <div
      class="col-5"
      style="border: 0px solid; margin-top: 10px; display: flex"
    >
      <ap-button
        kendoButton
        (click)="backwardPendingDataToFirst()"
        [btnType]="'secondary'"
        [disabled]="currentPagenum == 1"
        style="width: 50px; height: 37px; margin-right: 10px"
        ><span class="material-icons"
          >keyboard_double_arrow_left</span
        ></ap-button
      >

      <ap-button
        kendoButton
        (click)="backwardPendingData()"
        [btnType]="'secondary'"
        [disabled]="currentPagenum == 1"
        style="width: 50px; height: 37px; margin-right: 10px"
        ><span class="material-icons">keyboard_arrow_left</span></ap-button
      >

      <ap-button
        kendoButton
        (click)="forwardPendingData()"
        [btnType]="'secondary'"
        [disabled]="totalPages == currentPagenum"
        style="width: 50px; height: 37px; margin-right: 10px"
        ><span class="material-icons">keyboard_arrow_right</span></ap-button
      >

      <ap-button
        kendoButton
        (click)="forwardPendingDataToLast()"
        [btnType]="'secondary'"
        [disabled]="totalPages == currentPagenum"
        style="width: 50px; height: 37px"
        ><span class="material-icons"
          >keyboard_double_arrow_right</span
        ></ap-button
      >
      <p style="margin-left: 10px; margin-top: 10px">
        Page:{{ currentPagenum }}/{{ totalPages }}
      </p>
      &nbsp;&nbsp;&nbsp;
      <p *ngIf="!this.isLoading" style="margin-left: 10px; margin-top: 10px">
        Displaying {{ this.displayedRecords }} records out of
        {{ this.totalRecords }} records.
      </p>
    </div>
  </div>
  <ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
  </ng-template>
</div>
<kendo-dialog
  title="Manual journal creation"
  *ngIf="errorDisplay"
  (close)="close()"
  [minWidth]="250"
  [width]="500"
>
  <div style="display: flex">
    <p>
      <span
        class="Appkit4-icon icon-circle-warning-outline"
        style="font-size: 24px; color: #d04a02"
      ></span>
    </p>
    <p style="margin-left: 10px; font-size: medium">
      You cannot move journals between tax accounts.
    </p>
  </div>

  <div class="clickToActions" style="padding: 10px 0px 0px 0px">
    <ap-button
      [btnType]="'secondary'"
      [label]="'OK'"
      style="width: 100px"
      (click)="close()"
    ></ap-button>
  </div>
</kendo-dialog>
