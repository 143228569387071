import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { LineitemsComponent } from '../lineitems.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-editlineitem',
  templateUrl: './editlineitem.component.html',
  styleUrls: ['./editlineitem.component.scss'],
})
export class EditlineitemComponent implements OnInit {
  @Input() public BusinessType: any;
  @Input() public editLineItemId: any;
  BusinessDesc: any;
  editLineItemForm: FormGroup | any;
  descriptionErrorMessage: any = 'Description is required';
  accountclassErrorMessage: any = 'This need further analysing';
  public actionsLayout: ActionsLayout = 'normal';
  AccountClassDescriptionList: any;
  AccountingAreaDescriptionList: any;
  requireFurtherAnalysis: boolean | any;
  AccountingAreaDescriptionListDB: any;
  selectedClient: string | any;
  BusinessTypeId: any;
  //isEdit: boolean | any;
  LIDetailsfromDb: any;
  cID: any;
  editLineItemObject:
    | {
        ID: any;
        AccountingAreaID: any;
        Description: any;
        RequireFurtherAnalysis: boolean;
        AccountDerivation: null;
        AmountDisplayIndicator: boolean;
        TaxChartOfAccounts: any;
        AccountClass: any;
        AccountAreaGroup: null;
        StatementLevel: null;
        AccountingArea: any;
        HelpId: string;
        ParentLineItem: any;
      }
    | undefined;
  constructor(
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {
    this.editLineItemForm = this.fb.group({
      ID: 0,
      AccountingAreaID: 0,
      Description: [''],
      RequireFurtherAnalysis: true,
      CreateTaxAccount: false,
      AccountDerivation: [''],
      AmountDisplayIndicator: true,
      TaxChartOfAccounts: 0,
      AccountClass: 0,
      AccountAreaGroup: [''],
      StatementLevel: 0,
      AccountingArea: 0,
      HelpId: '',
      ParentLineItem: null,
    });
  }

  ngOnInit(): void {
    this.BusinessType = history.state.BusinessType;
    this.editLineItemId = history.state.editLineItemId;
    this.BusinessDesc = this.BusinessType.Description;
    this.editLineItemForm.controls['CreateTaxAccount'].disable();
    this.getLineItemById(this.editLineItemId);
    // if(this.editLineItemId){
    //   this.isEdit=true;

    // }
  }

  async getLineItemById(editLineItemId: any) {
    await this.globalService
      .getLineItemById(editLineItemId)
      .then((res) => {
        if (res) {
          this.LIDetailsfromDb = JSON.parse(res);

          this.editLineItemForm.controls['ID'].setValue(
            this.LIDetailsfromDb.LineID
          );
          this.editLineItemForm.controls['ID'].disable();
          this.editLineItemForm.controls['AccountingAreaID'].setValue(
            this.LIDetailsfromDb.LineID
          );
          this.editLineItemForm.controls['AccountingAreaID'].disable();

          this.requireFurtherAnalysis =
            this.LIDetailsfromDb.RequireFurtherAnalysis;
          this.BusinessTypeId = this.LIDetailsfromDb.TaxChartOfAccounts;
          this.editLineItemForm.controls['Description'].setValue(
            this.LIDetailsfromDb.Description
          );
          var lineItem = {
            LineID: this.LIDetailsfromDb.AccountingArea,
          };
          if (this.LIDetailsfromDb.AccountClass == 3)
            this.editLineItemForm.controls['ParentLineItem'].disable();
          else
            this.editLineItemForm.controls['ParentLineItem'].setValue(lineItem);
          this.editLineItemForm.controls['AccountClass'].setValue(
            this.LIDetailsfromDb.AccountClass
          );
          // this.editLineItemForm.controls['AccountingArea'].setValue(this.LIDetailsfromDb.AccountingArea);
          //this.editLineItemForm.controls['AmountDisplayIndicator'].setValue(this.LIDetailsfromDb.AmountDisplayIndicator);
        }
      })
      .catch((err) => {});

    this.getRefData();
  }

  getRefData() {
    this.globalService
      .getAllAccountClass()
      .then((res) => {
        this.AccountClassDescriptionList = res;
        this.AccountClassDescriptionList =
          this.AccountClassDescriptionList.slice();
      })
      .catch((err) => {});

    this.globalService
      .getAllLineItemsByBusinessType(this.BusinessTypeId)
      .then((res) => {
        let checkVal = this.LIDetailsfromDb.LineID;
        this.AccountingAreaDescriptionListDB = res.filter(function (el: any) {
          return el.LineID != checkVal;
        });
        this.AccountingAreaDescriptionList =
          this.AccountingAreaDescriptionListDB.slice();
      })
      .catch((err) => {});
  }

  handleAccountClassChange(value: any) {
    if (value.Description == 'Complex') {
      this.editLineItemForm.controls['ParentLineItem'].disable();
      this.editLineItemForm.controls['CreateTaxAccount'].disable();
      this.requireFurtherAnalysis = true;
    } else {
      this.editLineItemForm.controls['ParentLineItem'].enable();
      this.editLineItemForm.controls['CreateTaxAccount'].enable();
      this.requireFurtherAnalysis = false;
    }
  }

  handleFilterParentLineItem(value: any) {
    this.AccountingAreaDescriptionList =
      this.AccountingAreaDescriptionListDB.filter(
        (s: any) =>
          s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
  }

  cancelClick() {
    this.router.navigate(['/lineItems'], {
      state: {
        BusinessType: this.BusinessType,
      },
    });
    //this.dialog.close();
    // const dialogRef =this.dialogService.open({
    //   content: LineitemsComponent,
    //   width: 1200,
    //   height:600
    // });
    // const editLineItem = dialogRef.content.instance as LineitemsComponent;
    // var defaultBT ={"BusinessTypeId":this.BusinessTypeId,"Description":this.BusinessDesc,"Library":false,"CountryName":"UNITED KINGDOM","IndustryName":null,"HelpId":"","CountryId":231,"IndustryId":null};
    // dialogRef.result.subscribe((r: any) => {

    // });
    // editLineItem.isLoadId = true;
    // editLineItem.lineItemsGridForm = new FormGroup({
    //   businessTypeId: new FormControl(defaultBT)
    // });
  }

  saveEditLineItem() {
    var formObject = this.editLineItemForm.getRawValue();

    let AccountingAreaVal, AccountingAreaIdVal;
    if (formObject.AccountClass.ID == 3 || formObject.ParentLineItem == null) {
      this.requireFurtherAnalysis = true;
      AccountingAreaVal = null;
      AccountingAreaIdVal = '';
    } else {
      AccountingAreaVal = formObject.ParentLineItem.LineID;
      AccountingAreaIdVal = formObject.ParentLineItem.AccountingAreaID;
      this.requireFurtherAnalysis = true;
    }
    if (formObject.ParentLineItem == undefined) {
      formObject.ParentLineItem = null;
      this.requireFurtherAnalysis = true;
    }

    var lineId = null;
    var AccountClass = null;

    this.requireFurtherAnalysis = true;
    if (
      formObject.ParentLineItem != null &&
      formObject.ParentLineItem != undefined
    ) {
      lineId = formObject.ParentLineItem.LineID;
      AccountClass = this.LIDetailsfromDb.AccountClass;
      this.requireFurtherAnalysis = false;
    }
    try {
      this.editLineItemObject = {
        ID: this.editLineItemId,
        AccountingAreaID: formObject.AccountingAreaID.toString(),
        Description: formObject.Description,
        RequireFurtherAnalysis: this.requireFurtherAnalysis,
        AccountDerivation: null,
        AmountDisplayIndicator: true,
        TaxChartOfAccounts: this.BusinessTypeId,
        AccountClass: this.LIDetailsfromDb.AccountClass,
        AccountAreaGroup: null,
        StatementLevel: null,
        AccountingArea: lineId,
        HelpId: '',
        ParentLineItem: formObject.ParentLineItem,
      };
    } catch (_) {}

    this.globalService
      .updateLineItem(this.editLineItemObject)
      .then((res) => {
        if (res == 'Line Item updated successfully') {
          this.toastr.successToastr('Line item updated successfully', 'Success');
          this.displayGrid();
        } 
        else if(res == 'Line Item Description already exists')
        {
          this.editLineItemForm.controls['Description'].markAsTouched();
          this.descriptionErrorMessage =
            'Please modify Description as it is not unique';
          this.editLineItemForm.controls['Description'].setErrors({
            incorrect: true,
          });
          return;
        }
        else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.displayGrid();
        }
      })
      .catch((err) => {});
  }

  displayGrid() {
    this.router.navigate(['/lineItems'], {
      state: {
        BusinessType: this.BusinessType,
      },
    });
    //this.dialog.close();
    // const dialogRef = this.dialogService.open({
    //   content: LineitemsComponent,
    //   width: 1200,
    //   height:600
    // });
    // const editLineItem = dialogRef.content.instance as LineitemsComponent;
    // var defaultBT ={"BusinessTypeId":this.BusinessTypeId,"Description":this.BusinessDesc,"Library":false,"CountryName":"UNITED KINGDOM","IndustryName":null,"HelpId":"","CountryId":231,"IndustryId":null};
    // dialogRef.result.subscribe((r: any) => {

    // });
    // editLineItem.isLoadId = true;
    // editLineItem.lineItemsGridForm = new FormGroup({
    //   businessTypeId: new FormControl(defaultBT)
    // });
  }
}
