import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ActionsLayout, DialogRef } from '@progress/kendo-angular-dialog';
import { LegalEntity } from 'src/app/_models/legalEntity';
import { SelectEvent, TabCloseEvent } from '@progress/kendo-angular-layout';
import { JournalService } from 'src/app/common/services/journal/journal-service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { BusinessType } from 'src/app/_models/businessType';
import { JournalCategory } from 'src/app/_models/journalCategory';
import { FiscalYear } from 'src/app/_models/fiscalYear';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import {
  SupplementayTaxAccount,
  TaxAccount,
  TaxAccountAmount,
} from 'src/app/_models/taxAccount';
import { GlTaxAccount } from 'src/app/_models/glTaxAccount';
import {
  AddEvent,
  GridDataResult,
  CellClickEvent,
  CellCloseEvent,
  SaveEvent,
  CancelEvent,
  GridComponent,
  RemoveEvent,
} from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { anyChanged, Keys } from '@progress/kendo-angular-common';
import { map } from 'rxjs/operators';
import {
  SaveAdjustmentModel,
  SupplementaryCalculationFileMappingDTO,
  TaxAccountAmountDTOList,
} from 'src/app/_models/saveAdjustmentModel';
import { ToastrManager } from 'ng6-toastr-notifications';
import { EditService } from 'src/app/common/services/journal/edit.service';
import {
  ChunkSettings,
  FileRestrictions,
  SuccessEvent,
  UploadEvent,
} from '@progress/kendo-angular-upload';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AdjustmentDetailResponse } from 'src/app/_models/adjustmentDetailResponse';
import { EditJournalResponse } from 'src/app/_models/editJournalResponse';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-manage-adjustment-journal',
  templateUrl: './manage-adjustment-journal.component.html',
  styleUrls: ['./manage-adjustment-journal.component.scss'],
})
export class ManageAdjustmentJournalComponent implements OnInit {
  addJournalForm!: FormGroup;
  businessTypes: BusinessType[] = [];
  businessType!: BusinessType;
  legalEntities: LegalEntity[] = [];
  journalCategories: JournalCategory[] = [];
  periods: string[] = [];
  public actionsLayout: ActionsLayout = 'normal';
  isLoading: boolean = false;
  fiscalYears: FiscalYear[] = [];
  fiscalYear!: FiscalYear;
  fiscalYearValue: number = 0;
  value: Date = new Date();
  taxAccounts: TaxAccount[] = [];
  filteredTaxAccounts: TaxAccount[] = [];
  glTaxAccounts: GlTaxAccount[] = [];
  businessTypeId: number = 0;
  legalEntityId: number = 0;
  taxAccountAmount!: TaxAccountAmountDTOList;
  taxAccountAmounts: TaxAccountAmountDTOList[] = [];
  gridSno: number = 0;

  public view!: Observable<GridDataResult>;
  public formGroup!: FormGroup;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 100,
  };

  public changes = {};

  taxAccountAmt!: TaxAccountAmount;
  isTaxAmtData: boolean = false;
  saveAdjustment!: SaveAdjustmentModel;
  saveAdj: any;
  saveAdjTotal: any;
  totalAmt: number = 0;
  isGridEdited: boolean = false;
  uploadSaveUrl: string = '';
  uploadRemoveUrl: string = '';
  fileName: string = '';
  fileUid: string = '';

  private baseUrl: string = '';
  private apifolder: string = '';
  private selectedClient: any;

  supplementayTaxAccounts: SupplementayTaxAccount[] = [];
  supplementayFilteredTaxAccounts: SupplementayTaxAccount[] = [];
  supplementayTaxAccount!: SupplementayTaxAccount;
  supplementaryCalculationFileMappingDTO!: SupplementaryCalculationFileMappingDTO;
  isFileUploaded: boolean = false;
  editJournallResponses: EditJournalResponse[] = [];
  editJournallResponse!: EditJournalResponse;
  adjustmentDetailResponse!: AdjustmentDetailResponse;
  adjustmentDetailResponses: AdjustmentDetailResponse[] = [];
  mode: string = '';

  public pounds = Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  constructor(
    private fb: FormBuilder,
    private journalService: JournalService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public editService: EditService,
    public toastr: ToastrManager,
    private cookieService: CookieService
  ) {}
  selectedBusinessType: BusinessType | undefined;
  selectedLegalEntity: LegalEntity | undefined;
  selectedTaxAccount: TaxAccount | undefined;
  selectedGlAccount: GlTaxAccount | undefined;
  debitCreditType: string = 'debit';
  withCredential: boolean = false;
  isJourneyPeriodNew: boolean = false;
  onlineAllocationId: number = 0;

  ngOnInit(): void {
    this.setupForm();

    this.getLatestFiscalDetail();
    this.getBusinessTypeList();
    this.getAllJournalCategoryList();

    if (
      this.route.snapshot.paramMap.get('id') != '' &&
      this.route.snapshot.paramMap.get('id') != null
    ) {
      this.mode = 'Edit';

      this.onlineAllocationId = Number(this.route.snapshot.paramMap.get('id'));
      this.getEditJournal(this.onlineAllocationId);
      this.getAdjustmentDetail(this.onlineAllocationId);
    } else {
      this.mode = 'Add';
    }

    this.editService.clean();

    this.view = this.editService.pipe(
      map((data) => process(data, this.gridState))
    );

    this.editService.read();
    this.gridSno = 1;
    this.taxAccountAmounts = [];

    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);

    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;

    this.uploadSaveUrl =
      this.baseUrl +
      this.apifolder +
      '/Journal/UploadSupplementaryToContainer?ClientdbName=' +
      this.selectedClient.DBName;

    this.uploadRemoveUrl = 'removeUrl';
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  setupForm(): void {
    this.addJournalForm = this.fb.group({
      businessType: ['', Validators.required],
      legalEntity: ['', Validators.required],
      journalCategory: ['', Validators.required],
      journalPeriod: ['', Validators.required],
      journalPeriodNew: [''],
      journalRef: '',
      journalDescription: ['', Validators.required],
      journalNarrative: '',
      journalDate: new Date(),
      taxAccount: '',
      glAccount: '',
      amount: '',
      debitCredit: '',
      totalAmount: '',
      supplementayTaxAccount: '',
      includeSupplementay: '',
    });
  }

  initForm(): void {
    this.addJournalForm = this.fb.group({
      businessType: [this.saveAdjustment.BusinessTypeId, Validators.required],
      legalEntity: [this.saveAdjustment.LegalEntityId, Validators.required],
      journalCategory: [
        this.saveAdjustment.JournalCategoryId,
        Validators.required,
      ],
      journalPeriod: this.saveAdjustment.Period,
      journalPeriodNew: '',
      journalRef: this.saveAdjustment.Ref,
      journalDescription: [
        this.saveAdjustment.Description,
        Validators.required,
      ],
      journalNarrative: this.saveAdjustment.Narrative,
      journalDate: new Date(this.saveAdjustment.JournalDate),
      taxAccount: '',
      glAccount: '',
      amount: '',
      debitCredit: '',
      totalAmount: '',
      supplementayTaxAccount: this.editJournallResponse.TaxAccountCodeId,
      includeSupplementay: this.editJournallResponse.IncludeInExport,
    });
  }

  closeButtonClick() {
    this.router.navigate(['/manageJournal']);
  }

  public onClose(e: TabCloseEvent): void {}

  public onSelect(e: SelectEvent): void {}

  getBusinessTypeList(): void {
    this.isLoading = true;
    this.journalService.getAllBusinessTypeList().subscribe(
      (response) => {
        this.businessTypes = response;
        if (this.businessTypes.length > 0) {
          this.selectedBusinessType = this.businessTypes[0];
          this.handleBusinessTypeChange(this.selectedBusinessType);
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getLegalEntityList(businessTypeId: number): void {
    this.isLoading = true;
    this.journalService
      .getAllLegalEntityByTaxChartOfAccount(businessTypeId)
      .subscribe(
        (response) => {
          this.legalEntities = response;
          if (this.legalEntities.length > 0) {
            this.selectedLegalEntity = this.legalEntities[0];

            if (this.mode == 'Edit') {
              this.getGlTaxAccounts(
                this.editJournallResponse.BusinessTypeId,
                this.editJournallResponse.LegalEntityID
              );

              this.getTaxAccounts(
                this.editJournallResponse.BusinessTypeId,
                this.editJournallResponse.LegalEntityID
              );
            } else {
              this.handleLegalEntityChange(this.selectedLegalEntity);
            }
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  getAllJournalCategoryList(): void {
    this.isLoading = true;
    this.journalService.getAllJournalCategoryList().subscribe(
      (response) => {
        this.journalCategories = response;
        if (this.journalCategories.length > 0) {
          this.journalCategories[0];
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getPeriodByFiscalYear(fiscalYear: number): void {
    this.isLoading = true;
    this.journalService.getPeriodByFiscalYear(fiscalYear).subscribe(
      (response) => {
        this.periods = response;
        if (this.periods.length > 0) {
          this.periods[0];
        }
        this.periods.push('New');
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getLatestFiscalDetail(): void {
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.fiscalYearValue = JSON.parse(year).FiscalYearId;
    this.getPeriodByFiscalYear(this.fiscalYearValue);
    // this.isLoading = true;
    // this.journalService.getLatestFiscalDetail().subscribe(
    //   (response) => {
    //     this.fiscalYear = response;
    //     this.fiscalYearValue = this.fiscalYear.FiscalYearId;

    //     this.getPeriodByFiscalYear(this.fiscalYearValue);
    //     this.isLoading = false;
    //   },
    //   (error) => {
    //     console.log(error);
    //     this.isLoading = false;
    //   }
    // );
  }

  getTaxAccounts(businessTypeId: number, legalEntityID: number): void {
    this.isLoading = true;
    this.journalService.GetTaxAccounts(businessTypeId, legalEntityID).subscribe(
      (response) => {
        this.taxAccounts = response;
        if (this.taxAccounts.length > 0) {
          this.taxAccounts[0];
        }
        // this.getAdjustmentDetail(this.onlineAllocationId);
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getGlTaxAccounts(businessTypeId: number, legalEntityID: number): void {
    this.isLoading = true;
    this.journalService
      .GetGlTaxAccounts(businessTypeId, legalEntityID)
      .subscribe(
        (response) => {
          this.glTaxAccounts = response;
          if (this.glTaxAccounts.length > 0) {
            this.glTaxAccounts[0];
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  handleBusinessTypeChange(businessType: BusinessType): void {
    this.selectedBusinessType = businessType;
    this.legalEntities = [];
    this.selectedLegalEntity = undefined;
    this.selectedTaxAccount = undefined;
    this.selectedGlAccount = undefined;
    this.taxAccounts = [];
    this.glTaxAccounts = [];

    if (businessType != undefined) {
      this.businessTypeId = businessType.BusinessTypeId;
      this.getLegalEntityList(businessType.BusinessTypeId);
    }
  }

  handleLegalEntityChange(legalEntitity: LegalEntity): void {
    this.selectedLegalEntity = legalEntitity;
    this.selectedTaxAccount = undefined;
    this.selectedGlAccount = undefined;
    this.taxAccounts = [];
    this.glTaxAccounts = [];
    if (legalEntitity != undefined) {
      this.getTaxAccounts(this.businessTypeId, legalEntitity.ID);
      this.getGlTaxAccounts(this.businessTypeId, legalEntitity.ID);
    }
  }

  handleJournalPeriodChange(period: string) {
    if (period == 'New') {
      this.isJourneyPeriodNew = true;
    } else {
      this.isJourneyPeriodNew = false;
    }
  }

  handleGlAccountChange(glAccount: GlTaxAccount): void {
    if (glAccount != undefined) {
      this.selectedGlAccount = glAccount;
      this.selectedTaxAccount = undefined;
      this.filteredTaxAccounts = [];
      if (glAccount != undefined) {
        this.filteredTaxAccounts = this.taxAccounts.filter(
          (s: TaxAccount) => s.TaxAccountCodeId == glAccount.TaxAccountCodeId
        );
        if (this.filteredTaxAccounts.length > 0) {
          this.selectedTaxAccount = this.filteredTaxAccounts[0];
        }
      }
    }
  }

  handleTaxAccountChange(taxAccount: TaxAccount): void {
    this.selectedGlAccount = undefined;
  }

  resetSaveAdjustmentData(): void {
    this.saveAdjustment = {
      OnlineAllocationId: 0,
      FiscalYear: 0,
      BusinessTypeId: 0,
      LegalEntityId: 0,
      Ref: '',
      Description: '0',
      Narrative: '0',
      JournalCategoryId: 0,
      Period: 0,
      JournalDate: new Date(),
      Type: '',
      taxAccountAmountDTOList: [],
      supplementaryCalculationFileMappingDTO:
        this.supplementaryCalculationFileMappingDTO,
    };
  }

  backClick(): void {
    this.router.navigate(['/manageSelectJournal']);
  }

  public format: FormatSettings = {
    displayFormat: 'dd/MM/yyyy',
    inputFormat: 'dd/MM/yy',
  };

  resetTaxAccountAmount(): void {
    this.taxAccountAmount = {
      TaxAccountAmountId: 0,
      Amount: 0,
      TaxAccountCodeId: 0,
      DebitCredit: '',
      TaxAccountDescription: '',
      LineId: 0,
      AmountText: this.pounds.format(0).replace('£', ''),
    };
  }

  saveJournal(): void {
    this.isLoading = true;
    this.resetSaveAdjustmentData();
    const formObject = this.addJournalForm.getRawValue();

    if (this.mode == 'Add') {
      this.saveAdjustment.OnlineAllocationId = 0;
    } else {
      this.saveAdjustment.OnlineAllocationId = this.onlineAllocationId;
    }

    this.saveAdjustment.FiscalYear = this.fiscalYearValue;

    if (formObject.businessType.BusinessTypeId == undefined) {
      this.saveAdjustment.BusinessTypeId = formObject.businessType;
    } else {
      this.saveAdjustment.BusinessTypeId =
        formObject.businessType.BusinessTypeId;
    }

    if (formObject.legalEntity.ID == undefined) {
      this.saveAdjustment.LegalEntityId = formObject.legalEntity;
    } else {
      this.saveAdjustment.LegalEntityId = formObject.legalEntity.ID;
    }

    this.saveAdjustment.Ref = formObject.journalRef;
    this.saveAdjustment.Description = formObject.journalDescription;
    this.saveAdjustment.Narrative = formObject.journalNarrative;

    if (formObject.journalCategory.JournalCategoryId == undefined) {
      this.saveAdjustment.JournalCategoryId = formObject.journalCategory;
    } else {
      this.saveAdjustment.JournalCategoryId =
        formObject.journalCategory.JournalCategoryId;
    }

    if (this.isJourneyPeriodNew) {
      if (formObject.journalPeriodNew == '') {
        this.toastr.errorToastr('Please enter Journal Period.', 'Error');
        this.isLoading = false;
        return;
      }
      this.saveAdjustment.Period = formObject.journalPeriodNew;
    } else {
      if (formObject.journalPeriod == null || formObject.journalPeriod == '') {
        this.toastr.errorToastr('Please select Journal Period.', 'Error');
        this.isLoading = false;
        return;
      }
      this.saveAdjustment.Period = formObject.journalPeriod;
    }

    this.saveAdjustment.JournalDate = formObject.journalDate;
    this.saveAdjustment.Type = 'A';

    this.taxAccountAmounts = [];
    this.totalAmt = 0;
    this.editService.value.map((val) => {
      // this.resetTaxAccountAmount();
      this.taxAccountAmount = {
        TaxAccountAmountId: 0,
        Amount: 0,
        TaxAccountCodeId: 0,
        DebitCredit: '',
        TaxAccountDescription: '',
        LineId: 0,
        AmountText: this.pounds.format(0).replace('£', ''),
      };

      this.saveAdj = val;
      this.taxAccountAmount.Amount = this.saveAdj.Amount;
      (this.taxAccountAmount.AmountText = this.pounds
        .format(this.saveAdj.Amount)
        .replace('£', '')),
        (this.taxAccountAmount.TaxAccountAmountId = 0);
      this.taxAccountAmount.TaxAccountCodeId = this.saveAdj.TaxAccountCodeId;
      this.taxAccountAmount.DebitCredit =
        this.saveAdj.DebitCredit == 'debit' ? 'D' : 'C';
      this.taxAccountAmount.TaxAccountDescription =
        this.saveAdj.TaxAccountDescription;
      this.taxAccountAmount.LineId = 0;
      this.taxAccountAmounts.push(this.taxAccountAmount);
      this.totalAmt = Number(this.totalAmt) + Number(this.saveAdj.Amount);
    });

    if (this.fileName != '' && this.fileName != 'null.null') {
      if (
        formObject.supplementayTaxAccount == undefined ||
        formObject.supplementayTaxAccount == ''
      ) {
        this.toastr.errorToastr(
          'Please select associated tax account.',
          'Error'
        );
        this.isLoading = false;
        return;
      }

      this.supplementayFilteredTaxAccounts = [];

      if (formObject.supplementayTaxAccount.TaxAccountCodeId == undefined) {
        this.supplementayFilteredTaxAccounts =
          this.supplementayTaxAccounts.filter(
            (f) => f.TaxAccountCodeId == formObject.supplementayTaxAccount
          );
      } else {
        this.supplementayFilteredTaxAccounts =
          this.supplementayTaxAccounts.filter(
            (f) =>
              f.TaxAccountCodeId ==
              formObject.supplementayTaxAccount.TaxAccountCodeId
          );
      }

      if (this.supplementayFilteredTaxAccounts.length == 0) {
        this.toastr.errorToastr(
          'Please select associated tax account.',
          'Error'
        );
        this.isLoading = false;
        return;
      }

      this.supplementaryCalculationFileMappingDTO = {
        SupplementaryCalculationFileMappingId: 0,
        Name: '',
        FileGUID: '',
        IncludeInExport: true,
        TaxAccountCodeId: 0,
        IsStandAloneAttachment: false,
      };
      this.supplementaryCalculationFileMappingDTO.SupplementaryCalculationFileMappingId = 0;
      this.supplementaryCalculationFileMappingDTO.FileGUID = this.fileUid;
      this.supplementaryCalculationFileMappingDTO.Name = this.fileName;
      this.supplementaryCalculationFileMappingDTO.IsStandAloneAttachment =
        false;
      this.supplementaryCalculationFileMappingDTO.IncludeInExport =
        formObject.includeSupplementay == true ? true : false;

      if (formObject.supplementayTaxAccount.TaxAccountCodeId == undefined) {
        this.supplementaryCalculationFileMappingDTO.TaxAccountCodeId =
          formObject.supplementayTaxAccount;
      } else {
        this.supplementaryCalculationFileMappingDTO.TaxAccountCodeId =
          formObject.supplementayTaxAccount.TaxAccountCodeId;
      }

      this.saveAdjustment.supplementaryCalculationFileMappingDTO =
        this.supplementaryCalculationFileMappingDTO;
    }

    if (this.editService.value.length > 0) {
      if (this.totalAmt != 0) {
        this.toastr.errorToastr(
          'This journal cannot be saved as the amounts do not add up to zero.',
          'Error'
        );
        this.isLoading = false;
        return;
      }
    }

    this.saveAdjustment.taxAccountAmountDTOList = this.taxAccountAmounts;

    this.journalService.SaveNewEditAdjustment(this.saveAdjustment).subscribe(
      (response) => {
        if (
          response == 'Adjustment journal added successfully' ||
          response == 'Adjustment journal updated successfully'
        ) {
          this.toastr.successToastr(response, 'Success');
          this.isLoading = true;
          this.router.navigate(['/manageJournal']);
        } else {
          this.toastr.errorToastr(response, 'Error');
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.toastr.errorToastr('Failed to Save Journal', 'Error');
        console.log(error);
      }
    );
  }
  checkBalanceClick(): void {
    if (this.editService.value.length > 0) {
      this.totalAmt = 0;
      this.editService.value.map((val) => {
        this.saveAdjTotal = val;
        this.totalAmt =
          Number(this.totalAmt) + Number(this.saveAdjTotal.Amount);
      });
      if (this.totalAmt != 0) {
        this.toastr.errorToastr(
          'This journal cannot be saved as the amounts do not add up to zero.',
          'Error'
        );
      } else {
        this.toastr.infoToastr(
          'This journal can be saved as the amounts add up to zero.',
          'Information'
        );
      }
    }
  }

  addTaxAmtClick(): void {
    const formObject = this.addJournalForm.getRawValue();

    if (formObject.amount > 0 && formObject.taxAccount != undefined) {
      this.taxAccountAmt = {
        ID: this.gridSno,
        TaxAccountCodeId: formObject.taxAccount.TaxAccountCodeId,
        TaxAccountDescription: formObject.taxAccount.ShortDescription,
        DebitCredit: formObject.debitCredit,
        Amount:
          formObject.debitCredit == 'credit'
            ? '-' + formObject.amount
            : formObject.amount,
        AmountText:
          formObject.debitCredit == 'credit'
            ? '-' + this.pounds.format(formObject.amount).replace('£', '')
            : this.pounds.format(formObject.amount).replace('£', ''),
      };
      this.editService.create(this.taxAccountAmt);
      this.isTaxAmtData = true;

      this.totalAmt = 0;

      this.supplementayTaxAccounts = [];
      this.supplementayFilteredTaxAccounts = [];

      this.editService.value.map((val) => {
        this.saveAdjTotal = val;
        this.totalAmt =
          Number(this.totalAmt) + Number(this.saveAdjTotal.Amount);

        this.supplementayTaxAccount = {
          TaxAccountCodeId: 0,
          TaxAccountDescription: '',
        };
        this.supplementayTaxAccount.TaxAccountCodeId =
          this.saveAdjTotal.TaxAccountCodeId;
        this.supplementayTaxAccount.TaxAccountDescription =
          this.saveAdjTotal.TaxAccountDescription;
        this.supplementayFilteredTaxAccounts =
          this.supplementayTaxAccounts.filter(
            (f) => f.TaxAccountCodeId == this.saveAdjTotal.TaxAccountCodeId
          );
        if (this.supplementayFilteredTaxAccounts.length == 0) {
          this.supplementayTaxAccounts.push(this.supplementayTaxAccount);
        }
      });
    }

    this.gridSno++;
    this.isGridEdited = false;
  }

  public cellClickHandler(args: CellClickEvent): void {
    if (
      !args.isEdited &&
      !this.isReadOnly(args.column.field) &&
      args.column.field != undefined
    ) {
      args.sender.editCell(
        args.rowIndex,
        args.columnIndex,
        this.createFormGroup(args.dataItem)
      );
      this.isGridEdited = true;
    }
  }

  public cellCloseHandler(args: CellCloseEvent): void {
    const { formGroup, dataItem } = args;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (args.originalEvent && args.originalEvent.keyCode === Keys.Escape) {
        return;
      }

      this.editService.assignValues(dataItem, formGroup.value);
      this.editService.update(dataItem);
    }

    this.totalAmt = 0;

    this.editService.value.map((val) => {
      this.saveAdjTotal = val;
      this.totalAmt = Number(this.totalAmt) + Number(this.saveAdjTotal.Amount);
    });

    this.isGridEdited = false;
  }

  public removeHandler(args: RemoveEvent): void {
    this.editService.remove(args.dataItem);

    args.sender.cancelCell();

    this.totalAmt = 0;

    this.supplementayTaxAccounts = [];
    this.supplementayFilteredTaxAccounts = [];

    this.editService.value.map((val) => {
      this.saveAdjTotal = val;
      this.totalAmt = Number(this.totalAmt) + Number(this.saveAdjTotal.Amount);
      this.supplementayTaxAccount = {
        TaxAccountCodeId: 0,
        TaxAccountDescription: '',
      };
      this.supplementayTaxAccount.TaxAccountCodeId =
        this.saveAdjTotal.TaxAccountCodeId;
      this.supplementayTaxAccount.TaxAccountDescription =
        this.saveAdjTotal.TaxAccountDescription;
      this.supplementayFilteredTaxAccounts =
        this.supplementayTaxAccounts.filter(
          (f) => f.TaxAccountCodeId == this.saveAdjTotal.TaxAccountCodeId
        );
      if (this.supplementayFilteredTaxAccounts.length == 0) {
        this.supplementayTaxAccounts.push(this.supplementayTaxAccount);
      }
    });
    this.isGridEdited = false;
  }

  public createFormGroup(dataItem: TaxAccountAmount): FormGroup {
    return this.formBuilder.group({
      TaxAccountDescription: [dataItem.TaxAccountDescription],
      DebitCredit: [dataItem.DebitCredit],
      Amount: [
        dataItem.Amount,
        Validators.compose([
          Validators.required,
          // Validators.pattern('^[0-9]{1,9}'),
          Validators.pattern('^[-+]?[0-9]+$'),
        ]),
      ],
    });
  }

  private isReadOnly(field: string): boolean {
    const readOnlyColumns = ['TaxAccountDescription', 'DebitCredit'];
    return readOnlyColumns.indexOf(field) > -1;
  }

  uploadEventHandler(e: UploadEvent) {
    let tokenValue: any = localStorage.getItem('OPENAMSESSION');
    e.headers = e.headers.append(
      'Authorization-Token',
      'Bearer ' + JSON.parse(tokenValue).access_token
    );
  }

  public success(e: SuccessEvent): void {
    if (e.files.length > 0) {
      if (e.response.body.FileUploaded == true) {
        this.fileName = e.response.body.FileName;
        this.fileUid = e.response.body.FileUid;
        this.isFileUploaded = true;
      }
    }
  }

  supplementaryFileRestrictions: FileRestrictions = {
    allowedExtensions: ['.xls', '.xlsx'],
  };

  public chunkSettings: ChunkSettings = {
    size: 1048576,
  };

  public viewButtonClick(): void {
    this.getDownloadExcel();
  }

  public removeButtonClick(): void {
    this.fileName = '';
    this.fileUid = '';
    this.toastr.successToastr('File removed successfully.', 'Success');
  }

  getDownloadExcel(): void {
    this.isLoading = true;

    this.journalService
      .GetSupplementaryCalculationFromContainer(
        this.onlineAllocationId,
        this.fileName,
        this.fileUid
      )
      .subscribe(
        (response) => {
          const fileName = this.fileName;
          let blob: Blob = response;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
          this.toastr.successToastr(
            'Supplementary Calculation Data downloaded successfully.',
            'Success'
          );
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  getEditJournal(onlineAllocationId: number): void {
    this.isLoading = true;
    this.resetSaveAdjustmentData();
    this.journalService.GetEditJournal(onlineAllocationId).subscribe(
      (response) => {
        this.editJournallResponse = response;

        this.getLegalEntityList(this.editJournallResponse.BusinessTypeId);
        this.fiscalYearValue = this.editJournallResponse.FiscalYear;
        this.saveAdjustment.FiscalYear = this.editJournallResponse.FiscalYear;
        this.saveAdjustment.BusinessTypeId =
          this.editJournallResponse.BusinessTypeId;
        this.saveAdjustment.LegalEntityId =
          this.editJournallResponse.LegalEntityID;
        this.saveAdjustment.Ref = this.editJournallResponse.Ref;
        this.saveAdjustment.Description =
          this.editJournallResponse.OnlineAllocationDescription;
        this.saveAdjustment.Narrative = this.editJournallResponse.Narrative;
        this.saveAdjustment.JournalCategoryId =
          this.editJournallResponse.JournalCategoryId;
        this.saveAdjustment.Period = this.editJournallResponse.Period;
        this.saveAdjustment.JournalDate = this.editJournallResponse.JournalDate;

        this.fileName =
          this.editJournallResponse.Name +
          '.' +
          this.editJournallResponse.FileExtension;
        this.fileUid = this.editJournallResponse.FileGUID;
        this.initForm();

        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getAdjustmentDetail(onlineAllocationId: number): void {
    this.isLoading = true;
    this.resetSaveAdjustmentData();
    this.journalService
      .GetAdjustmentDetail(onlineAllocationId)
      .subscribe((response) => {
        this.adjustmentDetailResponses = response;

        this.supplementayTaxAccounts = [];

        this.editService.clean();
        this.adjustmentDetailResponses.map((adj, i) => {
          this.taxAccountAmt = {
            ID: i + 1,
            TaxAccountCodeId: adj.fk_TaxAccountCodeId,
            TaxAccountDescription: adj.TaxAccountDescription,
            DebitCredit: adj.DebitCredit == 'C' ? 'credit' : 'debit',
            Amount: adj.Amount,
            AmountText: this.pounds.format(adj.Amount).replace('£', ''),
          };
          this.editService.create(this.taxAccountAmt);

          this.supplementayTaxAccount = {
            TaxAccountCodeId: adj.fk_TaxAccountCodeId,
            TaxAccountDescription: adj.TaxAccountDescription,
          };

          this.supplementayFilteredTaxAccounts =
            this.supplementayTaxAccounts.filter(
              (f) =>
                f.TaxAccountCodeId ==
                this.supplementayTaxAccount.TaxAccountCodeId
            );
          if (this.supplementayFilteredTaxAccounts.length == 0) {
            this.supplementayTaxAccounts.push(this.supplementayTaxAccount);
          }
        });

        this.isFileUploaded = true;
      });
  }
}
