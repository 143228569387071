import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MyGlobalObject } from '../../myglobalobject';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AllocationRulesService {
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getAllocationRulesData: '/AllocationRules/GetAllocationRulesViewData',
      getAllBusinessTypeList: '/AllocationRules/GetBusinessTypes',
      getAllhabitatList: '/AllocationRules/GetHabitats',
      getAllexpressionList: '/AllocationRules/GetRulesForExpression',
      getAllComplexList: '/AllocationRules/GetComplexTaxAccounts',
      getExpressionByRuleId: '/AllocationRules/GetExpressionByRuleId',
      getAllSimpleList: '/AllocationRules/GetBusinessTypes',
      deleteAllocationRules: '/AllocationRules/DeleteAllocationRules',
      createJ: '/AllocationRules/CreateAutoAllocations',
      insertAllocationRules: '/AllocationRules/InsertAllocationRules',
      updateAllocationRules: '/AllocationRules/UpdateAllocationRules',
      getAllocationRuleDetail: '/AllocationRules/GetAllocationRuleDetail',
      getSimpleTaxAccounts: '/AllocationRules/GetSimpleTaxAccounts',
      getTaxAccountByCode: '/TaxAccountCode/GetTaxAccountCodeById',
      getAllocationRulesByIds: '/AllocationRules/GetAllocationRulesByIds',
      getAllocationReport: '/AllocationRules/GenerateReport',
      getAllocationExcel: '/AllocationRules/GetTransactionDataExcel',
      moveUpAllocationPriority: '/AllocationRules/MoveUpAllocationPriority',
      moveDownAllocationPriority: '/AllocationRules/MoveDownAllocationPriority',
      getDefineExpressionRules: '/AllocationRules/GetAllRuleExpression',
      getDefineExpressionsSearchList:
        '/AllocationRules/GetAllRuleExpressionByKeyWord',
      getRuleExpressionCount: '/AllocationRules/GetRuleExpressionCount',
      deleteRuleExpression: '/AllocationRules/DeleteRuleExpression',
      getExpressionDataColumns: '/AllocationRules/GetExpressionDataColumns',
      addUpdateExpressionRule: '/AllocationRules/AddUpdateExpressionRule',
      getExpressionRuleById: '/AllocationRules/GetExpressionRuleById',
      getAllKeywords: '/Keyword/GetAllKeywordData',
      getAllHabitats: '/AllocationRules/GetHabitats',
      getMatchingKeywords: '/AllocationRules/GetMatchingKeywords',
      saveColumnMapping: '/AllocationRules/SaveColumnMapping',
    };
  }
  getAllocationRulesByIds(ids: any, fiscalYearValue: any): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };

    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('FiscalYear', fiscalYearValue)
      .set('Ids', ids);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllocationRulesByIds;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getAllocationRulesDataList(): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllocationRulesData;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getAllBusinessTypeList(): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllBusinessTypeList;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getAllhabitatList(): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllhabitatList;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getAllexpressionList(): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllexpressionList;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getAllComplexList(SelectedAllocationId: string): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('SelectedAllocationId', SelectedAllocationId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllComplexList;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getExpressionByRuleId(ruleID: number): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('RuleId', ruleID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getExpressionByRuleId;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getAllocationRuleDetail(allocationID: number): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('allocationID', allocationID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllocationRuleDetail;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getSimpleTaxAccounts(
    ParentTaxAccountCode: number,
    BusinessTypeId: number
  ): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('ParentTaxAccountCode', ParentTaxAccountCode)
      .set('BusinessTypeId', BusinessTypeId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getSimpleTaxAccounts;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getTaxAccountDetailsByCode(taxAccountCodeId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('taxAccountCodeId', taxAccountCodeId);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getTaxAccountByCode;

    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }

  SaveNewAllocationRule(allocationRule: any): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.insertAllocationRules;
    return this.http.post<string>(this.apiHost, allocationRule, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  SaveEditAllocationRule(allocationRule: any): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.updateAllocationRules;
    return this.http.post<string>(this.apiHost, allocationRule, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  getReport(fileName: any): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fileName', fileName);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllocationReport;
    return this.http.post(this.apiHost, httpParams, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
  getExcel(data: any): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllocationExcel;
    return this.http.post(this.apiHost, data, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
  moveupPriority(allocationID: number): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('allocationID', allocationID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.moveUpAllocationPriority;
    return this.http.post<string>(this.apiHost, null, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  movedownPriority(allocationID: number): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('allocationID', allocationID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.moveDownAllocationPriority;
    return this.http.post<string>(this.apiHost, null, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }
  deleteAllocationRules(allocationID: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('allocationRuleids', allocationID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteAllocationRules;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.error == 'Reference Constraint Violated : 500') {
          return err.error;
        }
      });
  }
  createJournals(allocationID: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.createJ;
    return this.http
      .post(this.apiHost, allocationID, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.error == 'Reference Constraint Violated : 500') {
          return err.error;
        }
      });
  }
  getDefineExpressionsDataList(
    startIndex: number,
    maxRows: number
  ): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('startIndex', startIndex)
      .set('maxRows', maxRows);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getDefineExpressionRules;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getDefineExpressionsSearchList(
    keyword: any,
    startIndex: number,
    maxRows: number
  ): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('keyword', keyword)
      .set('startIndex', startIndex)
      .set('maxRows', maxRows);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getDefineExpressionsSearchList;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getDefineExpressionsTotalCount(keyword: any): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('keyword', keyword);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getRuleExpressionCount;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  deleteRuleExpression(allocationID: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('ruleId', allocationID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteRuleExpression;
    return this.http
      .delete(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
        // responseType: 'text' as 'json',
      })
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.error == 'Reference Constraint Violated : 500') {
          return err.error;
        }
      });
  }
  getExpressionDataColumns(): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getExpressionDataColumns;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getAllKeywords(): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getAllKeywords;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  SaveEditExpressionRule(expressionObj: any): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/problem+json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.addUpdateExpressionRule;

    return this.http.post<string>(this.apiHost, expressionObj, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }
  getExpressionRuleById(ruleID: number): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('RuleId', ruleID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getExpressionRuleById;
    return this.http.post<string>(this.apiHost, ruleID, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getExpressionDataColumnsList(): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getExpressionDataColumns;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  fetchAllHabitats(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getAllHabitats;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }

  getMatchingKeywords(
    columnName: string,
    fiscalYear: number,
    habitatId: number
  ): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('columnName', columnName)
      .set('fiscalYear', fiscalYear)
      .set('habitatId', habitatId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getMatchingKeywords;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }

  SaveColumnMapping(
    vocabularyModels: any,
    vocabularyGroupID: number
  ): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/problem+json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('vocabularyGroupID', vocabularyGroupID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.saveColumnMapping;

    return this.http.post<string>(this.apiHost, vocabularyModels, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }
}
