<!-- Header -->
<div class="header">Manage business types</div>
<div class="container-fluid">
  <!-- Sub Header -->
  <div class="col-md-12 col-xs-12" style="padding: 0px !important">
    <div class="subHeader">Business type details</div>
  </div>

  <!-- Add Business Type Form -->
  <div class="row">
    <div class="col-md-8 col-xs-12">
      <form class="k-form" [formGroup]="addBTForm">
        <kendo-formfield *ngIf="isEdit">
          <kendo-label
            [for]="businessTypeId"
            text="Business type id"
          ></kendo-label>
          <input
            formControlName="businessTypeId"
            kendoTextBox
            #businessTypeId
            [disabled]="true"
          />
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="description" text="Description*"></kendo-label>
          <input
            formControlName="description"
            kendoTextBox
            #description
            [maxlength]="50"
            required
          />

          <kendo-formerror>{{ descriptionErrorMessage }}</kendo-formerror>
        </kendo-formfield>
        <!-- <ap-field [title]="'Description'" [required]="true">
                    <input appkit-field formControlName="description" aria-errormessage="errormessage"/>
                    <div *ngIf="!addBTForm.controls['description'].valid" id="errormessage" aria-live="polite"  id="errormessage" aria-live="polite" class="ap-field-email-validation-error">Please enter a valid email address</div>
                  </ap-field><br/> -->
        <kendo-formfield>
          <kendo-label [for]="country" text="Default country"></kendo-label>
          <kendo-combobox
            #country
            formControlName="country"
            [data]="countryList"
            [filterable]="true"
            textField="Name"
            valueField="CountryId"
            (filterChange)="handleFilterCountry($event)"
          >
          </kendo-combobox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="industry" text="Default industry"></kendo-label>
          <kendo-combobox
            #industry
            formControlName="industry"
            [data]="industryList"
            [filterable]="true"
            textField="Description"
            valueField="IndustryId"
            (filterChange)="handleFilterIndustry($event)"
          >
          </kendo-combobox>
        </kendo-formfield>
      </form>
      <!-- Click to Actions -->
      <div class="clickToActions" style="padding: 20px 0px">
        <ap-button
          [btnType]="'secondary'"
          class="cancelButton"
          [label]="'Cancel'"
          class="okButton"
          (click)="cancelClick()"
        ></ap-button>
        <ap-button
          [btnType]="'primary'"
          class="okButton"
          [label]="'Save'"
          [disabled]="!addBTForm.valid"
          style="margin-right: 6px"
          themeColor="primary"
          (click)="saveBT()"
        ></ap-button>
      </div>
    </div>
    <div class="col-md-4 col-xs-12"></div>
  </div>
</div>
