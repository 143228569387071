import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AllocationRulesService } from 'src/app/common/services/allocationrule/allocation-rules.service';

@Component({
  selector: 'app-delete-allocation-rule',
  templateUrl: './delete-allocation-rule.component.html',
  styleUrls: ['./delete-allocation-rule.component.scss'],
})
export class DeleteAllocationRuleComponent implements OnInit {
  @Input() public delAllocationRuledata: any;

  constructor(
    public dialog: DialogRef,
    private route: ActivatedRoute,
    private router: Router,
    private allocationRulesService: AllocationRulesService,
    public toastr: ToastrManager
  ) {}

  ngOnInit(): void {}

  deleteRule() {
    this.allocationRulesService
      .deleteAllocationRules(this.delAllocationRuledata.Id)
      .then((res) => {
        if (res == 'Allocation Rule is deleted successfully') {
          this.toastr.successToastr(res, 'Success');
          this.displayGrid();
        } else {
          this.toastr.errorToastr(res, 'Failed');
          this.displayGrid();
        }
      })
      .catch((err: any) => {});
  }
  cancelClick() {
    this.dialog.close();
  }
  displayGrid() {
    this.router.navigate(['/manageAllocationRule']);
    this.dialog.close();
  }
}
