<kendo-dialog-titlebar (close)="closeButtonClick()">
    <div
        style="
        font-size: 18px;
        line-height: 1.1em;

        font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) 24px/24px
        var(--unnamed-font-family-helvetica-neue-lt-pro);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--default-color);
        text-align: left;
        font: normal normal medium 24px/24px Helvetica Neue LT Pro;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        ">
        GL account summary
    </div>
</kendo-dialog-titlebar>
<div *ngIf="isLoading">
    <kendo-chunkprogressbar
        [chunkCount]="10"
        [min]="-10"
        [max]="10"
        [indeterminate]="true"
        [value]="30"
        style="width: 100%"></kendo-chunkprogressbar>
</div>

<div class="row">
    <div class="col">
        <kendo-chart>
            <kendo-chart-tooltip>
                <ng-template kendoChartSeriesTooltipTemplate let-value="value">
                    Amount : {{ value.toLocaleString() }}
                </ng-template>
            </kendo-chart-tooltip>
            <kendo-chart-legend position="left"></kendo-chart-legend>
            <kendo-chart-series>
                <kendo-chart-series-item
                    type="pie"
                    [data]="changeValue"
                    field="TotalAmount"
                    categoryField="AccountNumber"
                    colorField="colorcode">
                </kendo-chart-series-item>
            </kendo-chart-series>
        </kendo-chart>
    </div>
</div>