<!-- Header -->
<div class="header">Manage offline allocation</div>
<div class="mainContent" class="container-fluid">
  <div *ngIf="isLoading">
    <kendo-chunkprogressbar
      [chunkCount]="10"
      [min]="-10"
      [max]="10"
      [indeterminate]="true"
      [value]="30"
      style="width: 100%"
    ></kendo-chunkprogressbar>
  </div>
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <!-- Select business type -->
      <div style="margin-bottom: -5px">
        <form class="k-form" [formGroup]="addJournalForm">
          <div class="form-group" style="margin-bottom: 10px">
            <kendo-combobox
              #businessType
              formControlName="businessType"
              [(ngModel)]="selectedBusinessType"
              [data]="businessTypes"
              textField="Description"
              valueField="BusinessTypeId"
              [kendoDropDownFilter]="filterSettings"
              placeholder="Select business type"
              (valueChange)="handleBusinessTypeChange($event)"
              style="width: 100%"
              placeholder="Select business type"
              required
              [kendoDropDownFilter]="filterSettings"
            >
            </kendo-combobox>
          </div>
          <div class="form-group" style="margin-bottom: 15px">
            <kendo-combobox
              #legalEntity
              formControlName="legalEntity"
              [data]="legalEntities"
              [(ngModel)]="selectedLegalEntity"
              style="width: 100%"
              textField="Description"
              [kendoDropDownFilter]="filterSettings"
              valueField="ID"
              placeholder="Select legal entity"
              (valueChange)="handleLegalEntityChange($event)"
              required
            >
            </kendo-combobox>
          </div>
        </form>
      </div>

      <!-- Search bar and action -->
      <div style="display: flex; padding-bottom: 10px">
        <ap-search
          [placeholder]="'Search here'"
          [searchType]="'secondary'"
          style="width: 100%; padding: 0px !important"
          [disabled]="false"
          (onSearch)="onFilter($event)"
          (onClear)="onClearSearch($event)"
        ></ap-search>
      </div>
      <!-- subHeader -->
      <kendo-grid
        [data]="offlineRef"
        scrollable="virtual"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        kendoGridSelectBy
        [(selectedKeys)]="mySelection"
        [resizable]="true"
        class="gridHeight"
        (selectionChange)="onGridSelectionChange($event)"
      >
        <kendo-grid-command-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'left'
          }"
          field="IsActive"
          title="Actions"
          [width]="70"
        >
          <ng-template kendoGridCellTemplate let-data>
            <button
              kendoButton
              name="view"
              kendoTooltip
              [disabled]="getStatus()"
              title="View"
              (click)="downloadOfflineReferralExcelFile()"
              style="margin-right: 5px"
            >
              <span class="Appkit4-icon icon-view-outline"></span>
            </button>
            <button
              kendoButton
              name="Re-import"
              kendoTooltip
              title="Re-import"
              (click)="reimport()"
              [disabled]="isReimportdisabled"
              style="margin-right: 5px"
            >
              <span class="Appkit4-icon icon-download-outline"></span>
            </button>
            <button
              kendoButton
              name="delete"
              kendoTooltip
              title="Delete"
              [disabled]="getStatus()"
              (click)="delete(data)"
            >
              <span class="Appkit4-icon icon-delete-outline"></span>
            </button>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'center'
          }"
          field="Ref"
          title="Ref"
          [width]="50"
        >
        </kendo-grid-column>
        <kendo-grid-column
          field="FiscalYear"
          title="Fiscal year"
          [width]="50"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="ExportFileName"
          title="Export filename"
          [width]="180"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="ExportDate"
          title="Export date"
          [width]="100"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Status"
          title="Status"
          [width]="50"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-command-column
          field="IsSupplementary"
          title="Supplementary"
          [width]="50"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
          [style]="{ 'text-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <input
              type="checkbox"
              kendoCheckBox
              [checked]="dataItem.IsSupplementary"
              (change)="IsSupplementaryChange($event, dataItem)"
            />
          </ng-template>
        </kendo-grid-command-column>
      </kendo-grid>

      <div class="row">
        <div class="clickToActions" style="padding-top: 15px">
          <ap-button
            [btnType]="'secondary'"
            (click)="close()"
            style="margin: 0px; width: 100px"
            [label]="'Close'"
          ></ap-button>
        </div>
      </div>
    </div>
  </div>
</div>
<kendo-dialog
  title="Delete offline allocation"
  *ngIf="opened1"
  (close)="closePop()"
  [minWidth]="250"
  [width]="500"
>
  <div style="display: flex">
    <p>
      <span
        class="Appkit4-icon icon-circle-warning-outline"
        style="font-size: 24px; color: #d04a02"
      ></span>
    </p>
    <p style="margin-left: 10px; font-size: medium">
      You have selected to delete an offline allocation. All journals associated
      with this offline allocation will be permanently removed.
      <br />
      <br />
      Are you sure you want to continue?
    </p>
  </div>
  <div class="clickToActions" style="padding: 10px 0px 0px 0px">
    <ap-button
      [btnType]="'secondary'"
      [label]="'No'"
      (click)="closePop()"
      style="width: 100px"
    ></ap-button>
    <ap-button
      [btnType]="'primary'"
      [label]="'Yes'"
      themeColor="primary"
      (click)="deleteData()"
      class="okButton"
      style="margin-right: 6px"
    ></ap-button>
  </div>
</kendo-dialog>
