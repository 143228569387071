<!--**** Popup for submenu Attachments in LineItem *****-->
<kendo-dialog-titlebar (close)="cancelClick()">
    <div style="font-size: 18px; line-height: 1.1em;">
         Attachments
    </div>
</kendo-dialog-titlebar>
<!-- <kendo-dialog  title="Attachments" *ngIf="openedAttachments" (close)="closeAttachmentPopup('cancel')" [height]="250" [width]="450"> -->
    
    <form class="k-form" [formGroup]="form">
      <kendo-formfield showHints="initial">
        <label class="k-label">Type of Confirmation</label>
        <ul class="k-radio-list">
          <li class="k-radio-item">
            <input type="radio" #add value="add" kendoRadioButton formControlName="confirmation"/>
            <kendo-label [for]="add" text="Add"></kendo-label>
          </li>
  
          <li class="k-radio-item">
            <input type="radio" #remove value="remove" kendoRadioButton formControlName="confirmation"/>
            <kendo-label  [for]="remove" text="Remove"></kendo-label>  
          </li>
        </ul>
       
        <kendo-formerror>Error: This field is required</kendo-formerror>
      </kendo-formfield>
      </form>
      <kendo-dialog-actions>
        <button kendoButton class="btn-pwc-secondary" (click)="cancelClick()">Cancel</button> &nbsp;&nbsp;
        <button kendoButton class='actionButton btn-pwc-customised'>Yes</button>
      </kendo-dialog-actions>
    <!-- </kendo-dialog> -->

    <!--**** Popup End for submenu Attachments in LineItem *****-->