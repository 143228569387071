<div *ngIf="isLoading">
    <kendo-chunkprogressbar 
      [chunkCount]="10"
      [min]="-10"
      [max]="10"
      [indeterminate]="true"
      [value]="30"
      style="width: 100%;"
    ></kendo-chunkprogressbar>
  </div>
<div class="header">Manage custom columns</div>
<div id="mainContent" class="container-fluid" >
  <div class="row">
    <!-- <div class="col-md-12 col-xs-12"></div> -->
    <div class="col-md-12 col-xs-12">
      <!-- search bar and action -->
      <div class="searchContainer">
        <ap-search
          [placeholder]="'Search here'"
          [searchType]="'secondary'"
          style="width: 100%; padding: 0px !important"
          [disabled]="false"
          (onSearch)="onFilter($event)"
        ></ap-search>
      </div>
      <!-- Sub Header -->
      <div class="subHeader">Custom names</div>
      <!-- Data grid -->
      <kendo-grid
      
        [data]="customColumnList"
        [sortable]="true"
        class="gridHeight" 
        [kendoGridReactiveEditing]="createFormGroup"
        [navigable]="true"
        (cancel)="cancelHandler($event)"
        (save)="saveHandler($event)"   
        [reorderable]="true"     
      >

     

      <kendo-grid-command-column title="Action" [width]="170" [headerStyle]="{'font-weight': 'bold','text-align': 'left',color: '#d04a02'}">
        <ng-template kendoGridCellTemplate let-isNew="isNew">
          <button kendoGridEditCommand  (click)="saveChanges($event)" kendoTooltip title="Edit">
            <span class="Appkit4-icon icon-edit-outline"></span></button>
          <button kendoGridSaveCommand >
            {{ isNew ? "Add" : "Update" }}
          </button>
          <button kendoGridCancelCommand>
            {{ isNew ? "Discard changes" : "Cancel" }}
          </button>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-column
      field="ColumnType"
      title="Column type"
      [width]="220"
      [headerStyle]="{
        'font-weight': 'bold',
        'text-align': 'left',
        color: '#d04a02'
      }"
    ></kendo-grid-column>
        <kendo-grid-column
          field="StaticColumn"
          title="Column"
          [width]="220"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="ColumnName"
          title="Name"
          [width]="320"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
       

        <kendo-grid-column
          field="RowCount"
          title="Row count"
          [width]="220"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
       
        <kendo-grid-column   [headerStyle] ="{'font-weight' : 'bold', 'color': '#d04a02', 'text-align': 'left'}"  [width]="100" [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-data>
            <input type="checkbox" [checked]="data.Show" (change)=onHighlightClick(data)  kendoCheckBox />
           </ng-template>
        </kendo-grid-column>
      
      </kendo-grid>     
      <div class="clickToActions" style="padding-top: 10px;">
        <ap-button [btnType]="'primary'" [label]="'Update'" (click)="UpdateEnableColumn()"  style="margin-left: 7px;" ></ap-button>        
      </div>
    </div>
  </div>
</div>
