<!-- <mat-card class="userCard">
  <div>
    <button kendoButton class="actionButton btn-pwc-primary" (click)="export()">
      Export
    </button>
  </div>
</mat-card> -->
<kendo-chunkprogressbar
  *ngIf="isLoading"
  [chunkCount]="10"
  [min]="-10"
  [max]="10"
  [indeterminate]="true"
  [value]="30"
  style="width: 100%"
></kendo-chunkprogressbar>

<kendo-grid
  *ngIf="
    this.levelType == 'AS' ||
    this.levelType == 'SA' ||
    this.levelType == 'LI' ||
    this.levelType == 'SS' ||
    this.levelType == 'CLI'
  "
  [kendoGridBinding]="gridData"
  [filterable]="true"
  [resizable]="true"
  scrollable="virtual"
  class="autoTreeHeight"
  [reorderable]="true"
  [columnMenu]="true"
>
  <ng-template kendoGridToolbarTemplate>
    <button
      kendoButton
      kendoGridExcelCommand
      class="actionButton"
      kendoTooltip
      title="Export to excel"
    >
      <span class="Appkit4-icon icon-download-outline"></span>
    </button>
  </ng-template>
  <span class="Appkit4-icon icon-xls-outline"></span>
  <span class="Appkit4-icon icon-xls-outline"></span>
  <!-- <kendo-grid-checkbox-column
[showSelectAll]="true"
[width]="20"
></kendo-grid-checkbox-column> -->
  <kendo-grid-column
    field="TaxAccount"
    title="Tax Account"
    [width]="100"
    [headerStyle]="{
      'font-weight': 'bold',
      'text-align': 'left',
      color: '#d04a02'
    }"
    [columnMenu]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span
        class="dottedtext"
        [ngStyle]="{
          'font-style':
            dataItem.GLAccountDescription === 'Adjustments' ||
            dataItem.GLAccountDescription === 'Journals in' ||
            dataItem.GLAccountDescription === 'Journals out'
              ? 'italic'
              : 'none'
        }"
      >
        {{ dataItem.TaxAccount }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="GLAccountDescription"
    title="GL Account Description"
    [width]="150"
    [headerStyle]="{
      'font-weight': 'bold',
      'text-align': 'left',
      color: '#d04a02'
    }"
    [columnMenu]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span
        class="dottedtext"
        [ngStyle]="{
          'font-style':
            dataItem.GLAccountDescription === 'Adjustments' ||
            dataItem.GLAccountDescription === 'Journals in' ||
            dataItem.GLAccountDescription === 'Journals out'
              ? 'italic'
              : 'none'
        }"
      >
        {{ dataItem.GLAccountDescription }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="Amount"
    title="Amount"
    filter="numeric"
    [width]="150"
    [headerStyle]="{
      'font-weight': 'bold',
      'text-align': 'left',
      color: '#d04a02'
    }"
    [style]="{ 'text-align': 'right' }"
    [columnMenu]="true"
  >
    <ng-template
      kendoGridColumnMenuTemplate
      let-service="service"
      let-column="column"
    >
      <li
        *ngFor="let aggregateOption of aggregateOptionsAmount"
        class=""
        style="margin: 4px 10px"
      >
        <input
          type="checkbox"
          id="{{ aggregateOption.id }}"
          kendoCheckBox
          [checked]="aggregateOption.checked"
          (change)="aggregateMethod($event, aggregateOption.id, column.field)"
        />
        <label class="k-checkbox-label" for="{{ aggregateOption.id }}">
          {{ aggregateOption.id }}
        </label>
      </li>
      <ap-button
        [btnType]="'primary'"
        [label]="'Apply'"
        themeColor="primary"
        (click)="openAggregate(column)"
        style="margin-right: 6px"
      ></ap-button>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span
        class="dottedtext"
        [style]="AddColorFormat(dataItem, dataItem.Amount | tonumber)"
      >
        {{ dataItem.Amount | tonumber }}
      </span>
    </ng-template>
    <ng-template
      kendoGridFooterTemplate
      let-column
      let-columnIndex="columnIndex"
    >
      <li *ngFor="let x of amountAggregate()">
        {{ x }}
      </li>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel fileName="GLAccountDetails.xlsx"></kendo-grid-excel>
</kendo-grid>
