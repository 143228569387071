<!-- Header -->
<div class="header" style="margin-bottom: 0px; font-size: 20px;">New journal</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 col-xs-12">
            <form>
                <div class="section">
                    <p>Chose which type of journal you would like to create from
                        the options below:</p>
                    <div class="wrap">
                        <input
                            type="radio"
                            name="journalType"
                            value="AdjustmentJournal"
                            #male
                            [(ngModel)]="model.journalType"
                            kendoRadioButton
                            checked
                            />&nbsp;&nbsp;
                        <kendo-label [for]="male" text="Adjustment Journal
                            (Summary Level)"></kendo-label>

                    </div>
                </div>
                <div class="section">
                    <div class="wrap">
                        <input
                            type="radio"
                            name="journalType"
                            value="MovementJournal"
                            #female
                            [(ngModel)]="model.journalType"
                            kendoRadioButton
                            />&nbsp;&nbsp;
                        <kendo-label [for]="female" text="Movement Journal
                            (Transaction Level)"></kendo-label>
                    </div>
                </div>
                <div>&nbsp;</div>
                <div class="d-grid gap-2 d-md-flex justify-content-md-start"
                    style="margin-right: 100px">
                    <ap-button kendoButton [btnType]="'secondary'"
                        [label]="'Cancel'" (onClick)="closeButtonClick()"
                        class="cancelButton okButton"></ap-button>

                    <ap-button kendoButton [btnType]="'primary'" [label]="'OK'"
                        (onClick)="openAddJournal()" class="okButton"></ap-button>
                </div>
            </form>
        </div>
    </div>
</div>
