import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { CookieService } from 'ngx-cookie-service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { TaxaccountserviceService } from 'src/app/common/services/taxaccounts/taxaccountservice.service';
import { getMissingNgModuleMetadataErrorData } from '@angular/compiler';

@Component({
  selector: 'app-deletetaxaccount',
  templateUrl: './deletetaxaccount.component.html',
  styleUrls: ['./deletetaxaccount.component.scss'],
})
export class DeletetaxaccountComponent
  extends DialogContentBase
  implements OnInit
{
  @Input() public taxAccountRowData: any;
  public mySelectionSecondGrid: any = [];
  public dropDownData: any = [];
  dialogWidth: any = 800;
  dialogHeight: any = 460;
  isNoMapping: boolean = false;
  hasAllocation: boolean = false;
  hasAccountMapping: boolean = false;
  deleteTaxAccCheckbox: boolean = false;
  delTaxAccPayload: any = [];
  hasGLmapping: boolean = false;
  GLMappingList: any = [];
  TAmappingList: any = [];
  allocationMappingList: any = [];
  hasTAmapping: boolean = false;
  public actionsLayout: ActionsLayout = 'normal';
  constructor(
    public dialog: DialogRef,
    private cookieService: CookieService,
    public myGlobalObject: MyGlobalObject,
    public toastr: ToastrManager,
    private dialogService: DialogService,
    private globalService: GlobalService,
    public TaxAccountService: TaxaccountserviceService
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    this.getDeleteData();
  }
  getAllTaxAccountCode(TaxChartAccountId: any) {
    this.globalService
      .getAllTaxAccountCode(TaxChartAccountId)
      .then((res: any) => {
        this.dropDownData = res;
      })
      .catch((err: any) => {});
  }
  getDeleteData() {
    this.globalService
      .getdeleteTaxAccountData(this.taxAccountRowData.TaxAccountCodeId)
      .then((res) => {
        if (
          res.Allocation == null &&
          (res.GLAccounts == null || res.GLAccounts.length == 0) &&
          res.JournalDesc.length == 0 &&
          res.TaxAccountCodes.length == 0
        ) {
          this.isNoMapping = true;
          this.dialogHeight = 230;
          this.dialogWidth = 600;
        } else {
          this.hasAccountMapping = true;
          if(res.GLAccounts == null && res.Allocation == null && res.TaxAccountCodes.length === 0){
            this.dialogHeight = 260;
            this.dialogWidth = 600;
          }
          if (res.GLAccounts != null && res.GLAccounts.length > 0) {
            this.hasGLmapping = true;
            this.GLMappingList = res.GLAccounts;
          }
          if (res.TaxAccountCodes.length > 0) {
            this.hasTAmapping = true;
            this.TAmappingList = res.TaxAccountCodes;
          }
          if (res.Allocation.length > 0) {
            this.dialogWidth = 800;

            this.hasAllocation = true;
            this.allocationMappingList = res.Allocation;
            this.getAllTaxAccountCode(
              this.taxAccountRowData.TaxChartOfAccountId
            );
          }
        }
      })
      .catch((err) => {});
  }
  public toggle(dataItem: any, field: string): void {
    dataItem[field] = !dataItem[field];
    if (field == 'delete') {
      if (dataItem[field] == true) {
        dataItem.destinationAccount = null;
      }
    }

    this.TaxAccCheckboxUpdate();
  }
  destinationAccChange(dataItem: any, e: any) {
    dataItem['delete'] = false;

    this.TaxAccCheckboxUpdate();
  }
  TaxAccCheckboxUpdate() {
    var result = this.allocationMappingList.every(this.isValid);
    if (result) {
      this.deleteTaxAccCheckbox = true;
    } else {
      this.deleteTaxAccCheckbox = false;
    }
  }
  public isValid(element: any) {
    return element.delete || element.destinationAccount != null;
  }
  public selectedCallback = (args: any) => args.dataItem;
  deleteTaxaccount() {
    let delTaxAccout;
    if (this.hasAllocation) {
      for (let i = 0; i < this.allocationMappingList.length; i++) {
        let temp_arr = {
          AllocationId: 0,
          OldTaxAccountId: this.taxAccountRowData.TaxAccountCodeId,
          NewTaxAccountId: 0,
          IsDelete: false,
        };

        temp_arr.AllocationId = this.allocationMappingList[i]._id;
        if (this.allocationMappingList[i].destinationAccount != null) {
          temp_arr.NewTaxAccountId =
            this.allocationMappingList[i].destinationAccount.TaxAccountCodeId;
        }
        temp_arr.IsDelete = this.allocationMappingList[i].delete;
        this.delTaxAccPayload.push(temp_arr);
      }
    } else {
      this.delTaxAccPayload = [
        {
          AllocationId: 0,
          OldTaxAccountId: this.taxAccountRowData.TaxAccountCodeId,
          NewTaxAccountId: 0,
          IsDelete: true,
        },
      ];
    }

    this.TaxAccountService.deleteTaxAccountCode(this.delTaxAccPayload)
      .then((res) => {
        if (res == 'Tax Account Code is deleted successfully') {
          this.toastr.successToastr('Tax account code is deleted successfully', 'Success');
          this.dialog.close();
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      })
      .catch((err) => {});
  }
  cancelClick() {
    this.dialog.close();
  }
}
