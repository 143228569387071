import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { ExportService } from 'src/app/common/services/export/export.service';
import { JournalService } from 'src/app/common/services/journal/journal-service';
import { BusinessType } from 'src/app/_models/businessType';
import { FiscalYear } from 'src/app/_models/fiscalYear';
import { LegalEntity } from 'src/app/_models/legalEntity';

@Component({
  selector: 'app-export-analysis-statements',
  templateUrl: './export-analysis-statements.component.html',
  styleUrls: ['./export-analysis-statements.component.scss'],
})
export class ExportAnalysisStatementsComponent implements OnInit {
  exportForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private globalService: GlobalService,
    private journalService: JournalService,
    private exportService: ExportService,
    public toastr: ToastrManager,
    private router: Router
  ) {}

  //selectedFiscalYear: FiscalYear | undefined ;
  year: any = localStorage.getItem('selectedFiscalYear');
  selectedFiscalYear = JSON.parse(this.year).FiscalYearId;
  selectedBusinessType: BusinessType | undefined;
  selectedLegalEntity!: LegalEntity | undefined;
  selectedAnalysisPack: any;
  selectedTaxEngine: any;
  taxengines: any;
  analysispacks: any;
  businessTypes: BusinessType[] = [];
  businessType!: BusinessType;
  legalEntities: LegalEntity[] = [];
  isLoading: boolean = false;
  fiscalYears: FiscalYear[] = [];
  fiscalYear!: FiscalYear;
  fiscalYearId: number = this.selectedFiscalYear;
  businessTypeId: number = 0;
  legalEntityId: number = 0;
  analysisPackId: number = 0;
  taxCheckboxState: boolean = true;
  exportCheckboxState: boolean = false;
  taxengineId: number = 0;

  ngOnInit(): void {
    this.setupForm();
    this.getFiscalYears();
    this.getBusinessTypeList();
    this.gettaxEngineList();
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  setupForm(): void {
    this.exportForm = this.fb.group({
      fiscalYear: ['', Validators.required],
      legalEntity: ['', Validators.required],
      businessType: ['', Validators.required],
      analysisPack: ['', Validators.required],
      taxEngine: ['', Validators.required],
      taxchecked: true,
    });
  }
  getFiscalYears(): void {
    this.isLoading = true;
    this.fiscalYears = [];
    this.globalService.getFiscalYearforExport().then(
      (response) => {
        this.fiscalYears = response;
        if (this.fiscalYears.length == 1) {
          this.selectedFiscalYear = this.fiscalYears[0];
          this.handleFiscalYearChange(this.selectedFiscalYear);
        }

        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }
  getBusinessTypeList(): void {
    this.isLoading = true;
    this.journalService.getAllBusinessTypeList().subscribe(
      (response) => {
        this.businessTypes = response;
        if (this.businessTypes.length == 1) {
          this.selectedBusinessType = this.businessTypes[0];
          this.handleBusinessTypeChange(this.selectedBusinessType);
        }
        this.isLoading = false;
      },
      (error) => {
        //console.log(error);
        this.isLoading = false;
      }
    );
  }

  getLegalEntityList(businessTypeId: number): void {
    this.isLoading = true;
    this.journalService
      .getAllLegalEntityByTaxChartOfAccount(businessTypeId)
      .subscribe(
        (response) => {
          this.legalEntities = response;
          if (this.legalEntities.length == 1) {
            this.selectedLegalEntity = this.legalEntities[0];
            this.handleLegalEntityChange(this.selectedLegalEntity);
          }
          this.isLoading = false;
        },
        (error) => {
          //console.log(error);
          this.isLoading = false;
        }
      );
  }
  getanalysispackList(businessTypeId: number, legalEntityId: number) {
    this.isLoading = true;
    this.exportService
      .getStatementGroupsByBusTypeAndLegalEntityId(
        businessTypeId,
        legalEntityId
      )
      .subscribe(
        (response) => {
          this.analysispacks = response;
          if (this.analysispacks.length == 1) {
            this.selectedAnalysisPack = this.analysispacks[0];
            this.handleAnalysisPackChange(this.selectedAnalysisPack);
          }

          this.isLoading = false;
        },
        (error) => {
          //console.log(error);
          this.isLoading = false;
        }
      );
  }
  gettaxEngineList() {
    this.isLoading = true;
    this.exportService.getTaxEngineFormat().subscribe(
      (response) => {
        this.taxengines = response;

        this.isLoading = false;
      },
      (error) => {
        //console.log(error);
        this.isLoading = false;
      }
    );
  }
  handleFiscalYearChange(fiscalYearValue: FiscalYear): void {
    //console.log(this.selectedFiscalYear)
    this.selectedFiscalYear = fiscalYearValue;
    this.fiscalYearId = fiscalYearValue.FiscalYearId;
  }
  handleBusinessTypeChange(businessType: BusinessType): void {
    this.selectedBusinessType = businessType;
    this.legalEntities = [];

    if (businessType != undefined) {
      this.businessTypeId = businessType.BusinessTypeId;
      this.getLegalEntityList(businessType.BusinessTypeId);
    }
  }

  handleLegalEntityChange(legalEntity: LegalEntity): void {
    this.selectedLegalEntity = legalEntity;
    this.analysispacks = [];
    if (legalEntity != undefined) {
      this.legalEntityId = legalEntity.ID;
      this.getanalysispackList(this.businessTypeId, this.legalEntityId);
    }
  }

  handleAnalysisPackChange(event: any) {
    this.analysisPackId = event.LookUpId;
  }
  taxCheckBox(event: any) {
    this.taxCheckboxState = event.checkboxState;
  }
  exportCheckBox(event: any) {
    this.exportCheckboxState = event.checkboxState;
  }
  handleTaxEngineChange(event: any) {
    this.selectedTaxEngine = event;
    this.taxengineId = event.ID;
  }
  close() {
    this.router.navigate(['/home']);
  }
  export() {
    this.isLoading = true;
    this.exportService
      .exportAnalysisStatement(
        this.fiscalYearId,
        this.businessTypeId,
        this.legalEntityId,
        this.analysisPackId,
        this.taxengineId,
        this.taxCheckboxState,
        this.exportCheckboxState
      )
      .subscribe(
        (response) => {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          const fileName = 'AnalysisStatementExport_' + timestamp;
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();

          this.toastr.successToastr('Data exported successfully.', 'Success');
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.toastr.errorToastr('Data export failed.', 'Error');
          console.log(error);
          return error;
        }
      );
  }
}
