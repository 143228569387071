import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { AnalysisReviewService } from 'src/app/common/services/analysisReview/analysis-review.service';

@Component({
  selector: 'app-analysis-edit-line',
  templateUrl: './analysis-edit-line.component.html',
  styleUrls: ['./analysis-edit-line.component.scss'],
})
export class AnalysisEditLineComponent implements OnInit {
  @Input() public BusinessType: any;
  @Input() public editLineItemId: any;
  @Input() public nodedata: any;
  @Input() public nameDesc: any;
  BusinessDesc: any;
  editLineItemForm: FormGroup | any;
  descriptionErrorMessage: any = 'Description is required';
  accountclassErrorMessage: any = 'This need further analysing';
  public actionsLayout: ActionsLayout = 'normal';
  AccountClassDescriptionList: any;
  AccountingAreaDescriptionList: any;
  requireFurtherAnalysis: boolean | any;
  AccountingAreaDescriptionListDB: any;
  BusinessTypeId: any;
  LIDetailsfromDb: any;

  editLineItemObject:
    | {
        ID: any;
        AccountingAreaID: any;
        Description: any;
        RequireFurtherAnalysis: boolean;
        AccountDerivation: null;
        AmountDisplayIndicator: boolean;
        TaxChartOfAccounts: any;
        AccountClass: any;
        AccountAreaGroup: null;
        StatementLevel: null;
        AccountingArea: any;
        HelpId: string;
        ParentLineItem: any;
      }
    | undefined;
  constructor(
    public dialog: DialogRef,
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private globalService: GlobalService,
    private analysisService: AnalysisReviewService,
    private router: Router
  ) {
    this.editLineItemForm = this.fb.group({
      ID: 0,
      AccountingAreaID: 0,
      Description: [''],
      RequireFurtherAnalysis: true,
      CreateTaxAccount: false,
      AccountDerivation: [''],
      AmountDisplayIndicator: true,
      TaxChartOfAccounts: 0,
      AccountClass: 0,
      AccountAreaGroup: [''],
      StatementLevel: 0,
      AccountingArea: 0,
      HelpId: '',
      ParentLineItem: null,
    });
  }

  ngOnInit(): void {
    this.BusinessTypeId = this.nodedata;
    this.editLineItemForm.controls['CreateTaxAccount'].disable();
    this.getLineItemById(this.editLineItemId);
  }
  async getLineItemById(editLineItemId: any) {
    await this.globalService
      .getLineItemById(editLineItemId)
      .then((res) => {
        if (res) {
          this.LIDetailsfromDb = JSON.parse(res);

          this.editLineItemForm.controls['ID'].setValue(
            this.LIDetailsfromDb.LineID
          );
          this.editLineItemForm.controls['ID'].disable();
          this.editLineItemForm.controls['AccountingAreaID'].setValue(
            this.LIDetailsfromDb.LineID
          );
          this.editLineItemForm.controls['AccountingAreaID'].disable();
          this.requireFurtherAnalysis =
            this.LIDetailsfromDb.RequireFurtherAnalysis;
          this.BusinessTypeId = this.LIDetailsfromDb.TaxChartOfAccounts;
          this.editLineItemForm.controls['Description'].setValue(
            this.LIDetailsfromDb.Description
          );
          var lineItem = {
            LineID: this.LIDetailsfromDb.AccountingArea,
          };
          if (this.LIDetailsfromDb.AccountClass == 3)
            this.editLineItemForm.controls['ParentLineItem'].disable();
          else
            this.editLineItemForm.controls['ParentLineItem'].setValue(lineItem);
          this.editLineItemForm.controls['AccountClass'].setValue(
            this.LIDetailsfromDb.AccountClass
          );
        }
      })
      .catch((err) => {});

    this.getRefData();
  }
  getRefData() {
    this.globalService
      .getAllAccountClass()
      .then((res) => {
        this.AccountClassDescriptionList = res;
        this.AccountClassDescriptionList =
          this.AccountClassDescriptionList.slice();
      })
      .catch((err) => {});

    this.globalService
      .getAllLineItemsByBusinessType(this.BusinessTypeId)
      .then((res) => {
        this.AccountingAreaDescriptionListDB = res[0];
        let checkVal = this.LIDetailsfromDb.LineID;
        this.AccountingAreaDescriptionListDB = res.filter(function (el: any) {
          return el.LineID != checkVal;
        });
        this.AccountingAreaDescriptionList =
          this.AccountingAreaDescriptionListDB.slice();
      })
      .catch((err) => {});
  }

  handleAccountClassChange(value: any) {
    if (value.Description == 'Complex') {
      this.editLineItemForm.controls['ParentLineItem'].disable();
      this.editLineItemForm.controls['CreateTaxAccount'].disable();
      this.requireFurtherAnalysis = true;
    } else {
      this.editLineItemForm.controls['ParentLineItem'].enable();
      this.editLineItemForm.controls['CreateTaxAccount'].enable();
      this.requireFurtherAnalysis = false;
    }
  }

  handleFilterParentLineItem(value: any) {
    this.AccountingAreaDescriptionList =
      this.AccountingAreaDescriptionListDB.filter(
        (s: any) =>
          s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
  }
  cancelClick() {
    this.dialog.close();
  }

  saveEditLineItem() {
    var formObject = this.editLineItemForm.getRawValue();
    let AccountingAreaVal, AccountingAreaIdVal;
    if (formObject.AccountClass.ID == 3 || formObject.ParentLineItem == null) {
      this.requireFurtherAnalysis = true;
      AccountingAreaVal = null;
      AccountingAreaIdVal = '';
    } else {
      AccountingAreaVal = formObject.ParentLineItem.LineID;
      AccountingAreaIdVal = formObject.ParentLineItem.AccountingAreaID;
      this.requireFurtherAnalysis = true;
    }
    if (formObject.ParentLineItem == undefined) {
      formObject.ParentLineItem = null;
      this.requireFurtherAnalysis = true;
    }
    var lineId = null;
    var AccountClass = null;
    this.requireFurtherAnalysis = true;
    if (
      formObject.ParentLineItem != null &&
      formObject.ParentLineItem != undefined
    ) {
      lineId = formObject.ParentLineItem.LineID;
      AccountClass = this.LIDetailsfromDb.AccountClass;
      this.requireFurtherAnalysis = false;
    }
    try {
      this.editLineItemObject = {
        ID: this.editLineItemId,
        AccountingAreaID: formObject.AccountingAreaID.toString(),
        Description: formObject.Description,
        RequireFurtherAnalysis: this.requireFurtherAnalysis,
        AccountDerivation: null,
        AmountDisplayIndicator: true,
        TaxChartOfAccounts: this.BusinessTypeId,
        AccountClass: this.LIDetailsfromDb.AccountClass,
        AccountAreaGroup: null,
        StatementLevel: null,
        AccountingArea: lineId,
        HelpId: '',
        ParentLineItem: formObject.ParentLineItem,
      };
    } catch (_) {}
    this.analysisService
      .updateLineItem(this.editLineItemObject)
      .then((res) => {
        if (res == 'Line Item updated successfully') {
          this.toastr.successToastr(
            'Line item updated successfully',
            'Success'
          );
          this.displayGrid();
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.displayGrid();
        }
      })
      .catch((err) => {});
  }
  displayGrid() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }
  onHighlightClick(data: any) {}
}
