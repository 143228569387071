<div class="header">GL accounts import wizard</div>
<mat-card class="userCard" style="padding: 5px 16px">
  <!-- Trail balance navbar here -->
  <div class="GLImport">
    <div class="container2">
      <div class="row">
        <div class="col-12">
          <ap-progress-stepper-set
            [orientation]="orientation"
            [(activeIndex)]="activeIndex"
            [space]="space"
            [readonly]="readonly"
            (activeIndexChange)="onActiveIndexChange($event)"
          >
            <ap-progress-stepper
              *ngFor="let tab of steps; let i = index"
              [label]="tab.label"
            >
              <!-- Set the content of every stepper panel here -->
            </ap-progress-stepper>
            <div *ngIf="isLoading">
              <kendo-chunkprogressbar
                [chunkCount]="10"
                [min]="-10"
                [max]="10"
                [indeterminate]="true"
                [value]="30"
                style="width: 100%"
              ></kendo-chunkprogressbar>
            </div>
          </ap-progress-stepper-set>
        </div>
      </div>
    </div>

    <!-- Import wizard content here -->
    <div
      class="card"
      *ngIf="activeIndex == 0"
      style="height: calc(100vh - 230px)"
    >
      <div class="card-body card_body">
        <div class="" style="font-size: 35px">
          <div class="">
            <div class="ap-container" style="padding: 0px">
              <div class="title1" style="margin-bottom: 0px">
                <h5 style="text-align: left">
                  Welcome to the GL account import wizard
                </h5>
                <!-- <p>With supporting text below as a natural lead-in to additional content.</p> -->
                <p style="margin-bottom: 5px">
                  The GL account import wizard allows you to import GL account
                  information extracted from your accounting system.
                </p>
                <!-- <p style="margin-bottom: 5px">
                  You can import the information from one of the following
                  sources: Microsoft Excel.
                </p> -->
                <p style="margin-bottom: 5px">
                  Click select files to import data.
                </p>

                <div class="card">
                  <div
                    class="card-body card_body"
                    style="padding: 10px 0px; height: calc(100vh - 410px)"
                  >
                    <div class="ap-container">
                      <div class="row">
                        <div class="col-4">
                          <div class="title">
                            <p>Select the file to import the data from</p>
                          </div>
                          <div class="col-8"></div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-1 Appkit4-iconFirst">
                          <span
                            class="Appkit4-icon icon-folder-opened-outline"
                          ></span>
                        </div>
                        <div
                          class="col-9 input-class uploadfile-css"
                          style="width: 91%; padding: 10px"
                        >
                          <kendo-upload
                            [saveUrl]="uploadSaveUrl"
                            [chunkable]="chunkSettings"
                            (upload)="uploadEventHandler($event)"
                            [withCredentials]="withCredential"
                            [restrictions]="myRestrictions"
                            [multiple]="false"
                            (success)="success($event)"
                          >
                          </kendo-upload>
                          <div *ngIf="fileName" class="displayfile">
                            <div>Uploaded file : {{ this.fileName }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Select worksheet content here -->
    <div
      class="card"
      *ngIf="activeIndex == 1"
      style="height: calc(100vh - 240px)"
    >
      <div class="card-body card_body">
        <div class="ap-container app-content" style="padding: 0px">
          <div class="row">
            <div class="col-12">
              <div class="title1">
                <p>
                  Select one or more sheets from the list below to import the
                  data
                </p>
              </div>
            </div>
            <div class="col-12">
              <div
                class="card"
                style="
                  height: calc(100vh - 330px);
                  overflow-y: scroll;
                  overflow-x: hidden;
                "
              >
                <div class="card-body title1">
                  <ul style="padding: 0px">
                    <li
                      *ngFor="let item of sheetslist1"
                      [ngStyle]="{ 'list-style-type': 'none' }"
                    >
                      <ap-checkbox
                        (onChange)="onCheckboxchange($event)"
                        [(ngModel)]="item.selected"
                        [disabled]="checkboxDisabled"
                        [indeterminate]="tempState"
                      >
                        <span *ngIf="showCheckboxLabel">{{
                          item.sheetname
                        }}</span>
                      </ap-checkbox>
                    </li>
                  </ul>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DataValidation content here -->
    <div
      class="card"
      *ngIf="activeIndex == 2"
      style="height: calc(100vh - 240px)"
    >
      <div class="card-body card_body">
        <div class="ap-container" style="font-size: 35px; padding: 0px">
          <div class="row">
            <div class="col">
              <div class="Pre-processing">
                <p>Pre-processing selected file for import data</p>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="!isLoading">
            <div class="col">
              <div class="card" style="text-align: center">
                <div
                  class="card-header Pre-processing"
                  *ngIf="isProcessingSuccess"
                >
                  <span
                    class="Appkit4-icon icon-circle-checkmark-outline"
                    style="color: green; width: auto; font-weight: bold"
                    >&nbsp;Validation Succeed</span
                  >
                </div>
                <div
                  class="card-header Pre-processing"
                  *ngIf="!isProcessingSuccess"
                >
                  <span
                    class="Appkit4-icon icon-circle-warning-outline"
                    style="color: red; width: auto; font-weight: bold"
                    >&nbsp;Validation Failed</span
                  >
                </div>
                <div
                  class="card-body Pre-processing"
                  *ngIf="isProcessingSuccess"
                >
                  <p class="card-text">
                    Validation Success.Click next to continue.
                  </p>
                </div>
                <div
                  class="card-body Pre-processing"
                  *ngIf="!isProcessingSuccess"
                >
                  <p class="card-text">
                    Validation failed: {{ failureMessage }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Last Import data content here -->
    <div
      class="card"
      *ngIf="activeIndex == 3"
      style="height: calc(100vh - 240px)"
    >
      <div class="card-body card_body">
        <div class="ap-container app-content" style="padding: 0px">
          <div class="row">
            <div class="col-12">
              <div class="title1" *ngIf="!isLoading && isImportSuccess">
                <b style="font-size: 14px">Import complete</b>
                <p>Transactions have been imported.</p>
              </div>
            </div>
            <div class="col-12">
              <div class="card">
                <div class="card-body title1" style="padding: 10px 20px">
                  <p class="summaryDetail">{{ importOutputSummaryDetail }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div
                  class="card-body title1"
                  style="padding: 10px 20px; display: flex"
                >
                  <p *ngIf="isImportSuccess">
                    <ap-button
                      type="button"
                      [btnType]="'primary'"
                      [label]="'Download summary'"
                      (onClick)="getImportTextFileReport()"
                      style="margin-bottom: 1px"
                    >
                    </ap-button
                    ><br /><span style="margin-top: 10px">
                      Click the button to download<br />summary report</span
                    >
                  </p>
                  <p *ngIf="isErrorAvailable" style="margin-left: 10px">
                    <ap-button
                      type="button"
                      [btnType]="'primary'"
                      [label]="'Download error'"
                      (onClick)="downloadErrorExcel()"
                      style="margin-bottom: 1px"
                    >
                    </ap-button
                    ><br /><span style="margin-top: 10px">
                      Click the button to download<br />error report</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fixed-bottom">
      <div class="row">
        <div class="card" style="border: 0px">
          <div class="card-body">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <ap-button
                type="button"
                [btnType]="'secondary'"
                [label]="'Back'"
                [disabled]="isBackDisabled"
                (onClick)="handleBackButton()"
                class="okButton"
              >
              </ap-button>
              <ap-button
                type="button"
                [btnType]="'primary'"
                [label]="'Next'"
                [disabled]="isNextDisabled"
                (onClick)="handleNextButton()"
                class="okButton"
              >
              </ap-button>
              <ap-button
                *ngIf="activeIndex == 3 && !this.isLoading"
                type="button"
                [btnType]="'primary'"
                [label]="'Finish'"
                (click)="finish()"
                class="okButton"
              >
              </ap-button>
              <ap-button
                *ngIf="activeIndex != 3"
                type="button"
                [btnType]="'secondary'"
                [disabled]="isCancelDisabled"
                [label]="'Cancel'"
                (click)="handleCancelButton()"
                class="okButton"
              >
              </ap-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog
    title="Cancel wizard"
    *ngIf="isConfirmOpen"
    (close)="close('no')"
    [minWidth]="250"
    [width]="500"
  >
    <div style="display: flex">
      <p>
        <span
          class="Appkit4-icon icon-circle-warning-outline"
          style="font-size: 24px; color: #d04a02"
        ></span>
      </p>
      <p style="margin-left: 10px; font-size: medium">
        Are you sure you wish to cancel the import wizard?
      </p>
    </div>

    <div class="clickToActions" style="padding: 10px 0px 0px 0px">
      <ap-button
        [btnType]="'secondary'"
        [label]="'No'"
        style="width: 100px"
        (click)="close('no')"
      ></ap-button>
      <ap-button
        [btnType]="'primary'"
        [label]="'Yes'"
        themeColor="primary"
        (click)="close('yes')"
        class="okButton"
        style="margin-right: 6px"
      ></ap-button>
    </div>
  </kendo-dialog>
</mat-card>
