<div class="contain">
  <div class="bg-color">
    <h1>Transaction Data Assist</h1>
  </div>
  <div class="nb"></div>
</div>
<div class="container-fluid scrollHeight" *ngIf="dashBoardSummary">
  <span style="font-weight: bold">Summary</span>

  <div
    class="row"
    style="
      margin: 3px;
      padding: 12px 0 12px 12px;
      border: 1px solid rgba(0, 0, 0, 0.2);
    "
  >
    <div
      class="col-md-2 col-xs-12"
      style="
        background-color: #eb8c00;
        width: 19%;
        margin-right: 13px;
        height: 90px;
        padding-top: 6px;
      "
    >
      <!-- <p class="property-color">Property</p> -->
      <span class="property-color">Total transactions imported</span>
      <br />
      <span class="Appkit4-icon icon-bar-chart-outline icon-changes"></span
      ><span class="total-count">{{
        dashBoardSummary.TotalTransactions | number
      }}</span
      ><br />
    </div>
    <div
      class="col-md-2 col-xs-12"
      style="
        background-color: #eb8c00;
        width: 19%;
        margin-right: 13px;
        height: 90px;
        padding-top: 6px;
      "
    >
      <!-- <p class="property-color">Property</p> -->
      <span class="property-color">Total trial balance lines imported</span
      ><br />
      <span class="Appkit4-icon icon-bar-chart-outline icon-changes"></span
      ><span class="total-count">{{
        dashBoardSummary.TBTransactions | number
      }}</span
      ><br />
    </div>
    <div
      class="col-md-2 col-xs-12"
      style="
        background-color: #d93954;
        width: 19%;
        margin-right: 13px;
        height: 90px;
        padding-top: 6px;
      "
      (click)="offlineReferrals('Total')"
    >
      <!-- <p class="property-color">Property</p> -->
      <span class="property-color1">Total offline allocations</span><br />

      <span class="Appkit4-icon icon-bar-chart-outline icon-changes"></span
      ><span class="total-count-number">{{
        dashBoardSummary.TotalOfflineReferals | number
      }}</span
      ><br />
    </div>
    <div
      class="col-md-2 col-xs-12"
      style="
        background-color: #d93954;
        width: 19%;
        margin-right: 13px;
        height: 90px;
        padding-top: 6px;
      "
      (click)="offlineReferrals('Confirmed')"
    >
      <span class="property-color1">Offline allocations completed</span><br />
      <!-- <p class="property-color">Property</p> -->
      <span class="Appkit4-icon icon-bar-chart-outline icon-changes"></span
      ><span class="total-count-number">{{
        dashBoardSummary.OfflineReferalsCompleted | number
      }}</span
      ><br />
    </div>
    <div
      class="col-md-2 col-xs-12"
      style="
        background-color: #d93954;
        width: 19%;
        margin-right: 13px;
        height: 90px;
        padding-top: 6px;
      "
      (click)="offlineReferrals('Exported')"
    >
      <!-- <p class="property-color">Property</p> -->
      <span class="property-color1">Offline allocations exported</span><br />
      <span class="Appkit4-icon icon-bar-chart-outline icon-changes"></span
      ><span class="total-count-number">{{
        dashBoardSummary.OfflineReferalsExported | number
      }}</span
      ><br />
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-6 col-xs-12">
      <span style="font-weight: bold">Top GL accounts with transactions</span>
      <kendo-chart
        style="width: 100%; height: 350px; border: 1px solid rgba(0, 0, 0, 0.2)"
      >
        <!-- (seriesClick)="barChartData($event)"
        (dblclick)="dblclicksff()" -->
        <kendo-chart-tooltip>
          <ng-template
            kendoChartSeriesTooltipTemplate
            let-value="value"
            let-dataItem="dataItem"
            let-series="series"
            let-category="category"
          >
            Transactions: {{ dataItem.Transactions | number }}
          </ng-template>
        </kendo-chart-tooltip>
        <kendo-chart-value-axis>
          <kendo-chart-value-axis-item [title]="{ text: staticData }">
            <kendo-chart-value-axis-item-labels
              [format]="'#,#'"
              [margin]="{ right: 10 }"
            >
            </kendo-chart-value-axis-item-labels>
          </kendo-chart-value-axis-item>
        </kendo-chart-value-axis>
        <kendo-chart-legend
          position="bottom"
          orientation="horizontal"
        ></kendo-chart-legend>
        <kendo-chart-series>
          <kendo-chart-series-item
            name="GL Account"
            type="column"
            [data]="dashBoardChartData"
            field="AmountTotal"
            categoryField="GLAccount"
            [colorField]="'colorcodeaccount'"
          >
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
    </div>
    <!-- <div class="col-md-4 col-xs-12" >
      <span style="font-weight:bold">Application settings</span>
      <kendo-grid
        [data]="applicationSettingData"
        scrollable="virtual"
        [selectable]="true"
        [height]="350"
        width="50"
      >
        <kendo-grid-column field="label" title="Property" [width]="50" [headerStyle] ="{'font-weight' : 'bold', 'text-align': 'center', 'color': '#d04a02'}"></kendo-grid-column>
        <kendo-grid-column field="value" title="Value" [width]="20" [headerStyle] ="{'font-weight' : 'bold', 'text-align': 'center', 'color': '#d04a02'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [style]="AddColorFormat(dataItem.value)">
              {{ dataItem.value }}
            </span>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
   
  </div> -->
    <div class="col-md-6 col-xs-12 pie-chart-width" *ngIf="showBarChatValue">
      <!-- <span>value : {{this.pieChartData}}</span> -->
      <span style="font-weight: bold">Vendor Description</span>
      <div class="border-piechart">
        <kendo-grid
          [data]="barChartDataValue"
          scrollable="virtual"
          [selectable]="true"
          [sortable]="true"
          [sort]="sort"
          (sortChange)="sortChange($event)"
          [height]="350"
          width="50"
        >
          <kendo-grid-column
            field="AccountNumber"
            title="GL account"
            [width]="20"
            [headerStyle]="{
              'font-weight': 'bold',
              'text-align': 'center',
              color: '#d04a02'
            }"
          ></kendo-grid-column>
          <kendo-grid-column
            field="VendorDescription"
            title="Description"
            [width]="40"
            [headerStyle]="{
              'font-weight': 'bold',
              'text-align': 'center',
              color: '#d04a02'
            }"
          ></kendo-grid-column>
          <kendo-grid-column
            field="TotalAmount"
            title="Amount"
            [width]="30"
            [style]="{ 'text-align': 'right' }"
            [headerStyle]="{
              'font-weight': 'bold',
              'text-align': 'right !important',
              color: '#d04a02'
            }"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [style]="ColorFormat(dataItem.TotalAmount)">
                {{
                  (dataItem.TotalAmount == null
                    ? dataItem.TotalAmount
                    : dataItem.TotalAmount
                  ) | tonumber
                }}
              </span>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
    <div
      class="col-md-6 col-xs-12 pie-chart-width"
      *ngIf="showGLAccountPieChart"
    >
      <span style="font-weight: bold">
        {{ pieChartText }}
        <!-- <span
          class="Appkit4-icon icon-view-outline"
          (click)="accountSummery()"
          style="cursor: pointer"></span> -->
      </span>
      <div class="border-piechart">
        <kendo-chart>
          <kendo-chart-tooltip>
            <ng-template kendoChartSeriesTooltipTemplate let-value="value">
              Amount : {{ value.toLocaleString() }}
            </ng-template>
          </kendo-chart-tooltip>
          <kendo-chart-legend position="left"></kendo-chart-legend>
          <kendo-chart-series>
            <kendo-chart-series-item
              type="pie"
              [data]="dashBoardData"
              field="TotalAmount"
              categoryField="AccountNumber"
              colorField="colorcode"
            >
            </kendo-chart-series-item>
          </kendo-chart-series>
        </kendo-chart>
      </div>
    </div>

    <div class="col-md-6 col-xs-12 pie-chart-width" *ngIf="showGLAccountGrid">
      <span style="font-weight: bold">
        {{ pieChartText }}
        <!-- <span
          class="Appkit4-icon icon-view-outline"
          (click)="pieChartAccountSummery()"
          style="cursor: pointer"></span> -->
      </span>
      <div class="border-piechart">
        <kendo-grid
          [data]="dashBoardGridData"
          scrollable="virtual"
          [selectable]="true"
          [sortable]="true"
          [sort]="sort"
          (sortChange)="sortChange($event)"
          [height]="350"
          width="50"
        >
          <kendo-grid-column
            field="AccountNumber"
            title="GL account"
            [width]="20"
            [headerStyle]="{
              'font-weight': 'bold',
              'text-align': 'center',
              color: '#d04a02'
            }"
          ></kendo-grid-column>
          <kendo-grid-column
            field="LongDescription"
            title="Description"
            [width]="40"
            [headerStyle]="{
              'font-weight': 'bold',
              'text-align': 'center',
              color: '#d04a02'
            }"
          ></kendo-grid-column>
          <kendo-grid-column
            field="TotalAmount"
            title="Amount"
            [width]="30"
            [style]="{ 'text-align': 'right' }"
            [headerStyle]="{
              'font-weight': 'bold',
              'text-align': 'right !important',
              color: '#d04a02'
            }"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [style]="ColorFormat(dataItem.TotalAmount)">
                {{
                  (dataItem.TotalAmount == null
                    ? dataItem.TotalAmount
                    : dataItem.TotalAmount
                  ) | tonumber
                }}
              </span>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>

  <div class="footer d-flex flex-row justify-content-between footer-container">
    <div class="align-items-start">
      <p style="margin-bottom: 0px">
        <sup>©</sup> {{ currentYear }} PwC. All rights reserved. PwC refers to
        the PwC network and/or one or more of its member firms, each of which is
        a separate legal entity.
      </p>
      &nbsp;&nbsp;
      <p class="d-inline">
        Please see
        <a
          class="footerlink"
          href="https://www.pwc.com/structure"
          target="_blank"
          rel="noopener noreferrer"
          >www.pwc.com/structure</a
        >
        for further details.
      </p>
    </div>
    <div class="align-items-end pr-0 pl-2">
      <div>
        <!-- <p class="d-inline-flex"></p> -->
        <ul class="list-inline">
          <li class="list-inline-item">
            <a
              href="/termsofuse"
              target="_blank"
              rel="noopener noreferrer"
              data-testid="footer-btn-0"
              class="footer-list-itemlink pl-0 btn font-weight-normal btn btn-link"
              >Terms of Use
            </a>
          </li>
          <li class="list-inline-item">
            <a
              href="/privacystatement"
              target="_blank"
              data-testid="footer-btn-1"
              class="footer-list-itemlink pl-0 btn font-weight-normal btn btn-link"
              >Privacy statement</a
            >
          </li>
          <!-- href="https://docs.google.com/document/d/1b67PfdI7T7akI47uyr7TNyBkZ8xzH-ZOSPNuL9lzAFo/edit#heading=h.5c4xgzpgc2gd"
          target="_blank" -->
          <!-- <li class="list-inline-item">
            <a
              rel="noopener noreferrer"
              data-testid="footer-btn-2"
              class="footer-list-itemlink pl-0 btn font-weight-normal btn
              btn-link">Data Controller</a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</div>
