<!-- Header -->
<div class="header">Manage line item</div>
<!-- New line item form -->
<div class="container-fluid" style="padding: 0px 20px 0px 20px">
  <div class="row">
    <div class="col-md-8 col-xs-12">
      <h6>Line item details - Business type: {{ BusinessDesc }}</h6>
      <form class="k-form" [formGroup]="newLineItemForm">
        <kendo-formfield>
          <kendo-label [for]="Description" text="Description:*"></kendo-label>
          <input
            formControlName="Description"
            kendoTextBox
            #Description
            [maxlength]="100"
            required
          />
          <kendo-formerror>{{ descriptionErrorMessage }}</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield>
          <kendo-label
            [for]="AccountClass"
            text="Account class:*"
          ></kendo-label>
          <kendo-combobox
            #AccountClass
            required
            formControlName="AccountClass"
            [data]="AccountClassDescriptionList"
            [filterable]="true"
            textField="Description"
            valueField="ID"
            (valueChange)="handleAccountClassChange($event)"
            (filterChange)="handleFilterAccountClass($event)"
          >
          </kendo-combobox>
          <div *ngIf="Complex == 2">
            <kendo-formerror>{{ complexMessage }}</kendo-formerror>
          </div>
          <div *ngIf="Complex == 1">
            <kendo-formerror>{{ accountclassErrorMessage }}</kendo-formerror>
          </div>
        </kendo-formfield>

        <kendo-formfield>
          <kendo-label
            [for]="ParentLineItem"
            text="Parent line item:"
          ></kendo-label>
          <kendo-combobox
            #ParentLineItem
            formControlName="ParentLineItem"
            [data]="AccountingAreaDescriptionList"
            [filterable]="true"
            textField="Description"
            valueField="LineID"
            (valueChange)="handleParentLineItemChange($event)"
            (filterChange)="handleFilterParentLineItem($event)"
          >
          </kendo-combobox>
        </kendo-formfield>
        <!-- <label class="k-label">
                    Analysis further:
                    <input type="checkbox" kendoCheckBox formControlName="RequireFurtherAnalysis" />
                  </label> -->
        <br />
        <kendo-formfield>
          <kendo-label [for]="CreateTaxAccount" text="Create tax account:"
            >&nbsp;&nbsp;<input
              type="checkbox"
              #CreateTaxAccount
              kendoCheckBox
              formControlName="CreateTaxAccount"
          /></kendo-label>
        </kendo-formfield>
      </form>
      <!-- Click to Actions -->
      <div class="clickToActions">
        <ap-button
          [btnType]="'secondary'"
          [label]="'Cancel'"
          (click)="cancelClick()"
          style="margin-left: 5px; width: 100px"
        ></ap-button>
        <ap-button
          [btnType]="'primary'"
          [label]="'Save'"
          [disabled]="!newLineItemForm.valid"
          themeColor="primary"
          (click)="saveLineItem()"
          style="margin-right: 6px; width: 100px"
        ></ap-button>
      </div>
    </div>
    <div class="col-md-4 col-xs-12"></div>
  </div>
</div>
