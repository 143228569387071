export interface offlineReferralOperator {
  Id: number;
  Operator: string;
}

export const offlineReferralOperatorDataList: offlineReferralOperator[] = [
  {
    Id: 1,
    Operator: 'is =',
  },
  {
    Id: 2,
    Operator: 'is not =',
  },
  {
    Id: 3,
    Operator: 'is <=',
  },
  {
    Id: 4,
    Operator: 'is <',
  },
  {
    Id: 5,
    Operator: 'is >=',
  },
  {
    Id: 6,
    Operator: 'is >',
  },
];
