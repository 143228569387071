  <kendo-dialog-titlebar (close)="cancelClick()">
    <div style="font-size: 20px; line-height: 1.3em">Column properties</div>
  </kendo-dialog-titlebar>
 
  <mat-card class="userCard" style="padding: 0px">
    <mat-card-title><b> Column properties </b></mat-card-title>
    <mat-card-content>
      <div>
        <form class="k-form" [formGroup]="newColumnForm">          

          <kendo-formfield>
            <kendo-label [for]="ColumnName" text="Header*"></kendo-label>
            <input style="width:100%" formControlName="ColumnName" kendoTextBox #ColumnName required /> 
            <kendo-formerror>{{ColumnNameErrorMessage}}</kendo-formerror>        
        </kendo-formfield>    
        </form>
      </div>
    </mat-card-content>
  </mat-card>
    <div class="clickToActions" style="padding: 14px 0px 0px 0px">
      <ap-button
        [btnType]="'secondary'"
        [label]="'Cancel'"
        class="cancelButton"
        (click)="cancelClick()"
      ></ap-button>
  
      <ap-button
        [btnType]="'primary'"
        [label]="'Save'"
        class="okButton"
        style="margin-right: 6px"
        themeColor="primary"
    [disabled]="!(newColumnForm.valid)"
          themeColor="primary"
          (click)="saveColumn()"
      ></ap-button>
    </div>
  
