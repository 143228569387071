<kendo-dialog
  title="Upload GL account for mapping"
  (close)="cancelClick()"
  [minWidth]="250"
  [width]="550"
  [minheight]="207"
  [maxheight]="300"
>
  <div style="display: flex;justify-content: center;">    
    <div class="gridHeader" style="padding-top: 11px;">Please select file to be uploaded</div>
    <div  
    style="width: 59%;
    margin-left: 3%;">                        
        <kendo-upload
        [saveUrl]="uploadSaveUrl"
        [removeUrl]="uploadRemoveUrl"
        (upload)="uploadEventHandler($event)"
        [withCredentials]= "withCredential"
        [multiple]="false"
        (success)="success($event)"
        [restrictions]="GLFileRestrictions"
        width="306px"
        (error)="error($event)"
      >
      <kendo-fileselect-messages
      dropFilesHere="Drop your file here"
      select="Select file"
      [fileStatusUploaded]="FileStatus"
                      [fileStatusFailed]="ErrorFileStatus">
  </kendo-fileselect-messages>
      </kendo-upload>                      
      </div> 
  </div>
  <br/>

  <div class="clickToActions" style="padding: 10px 0px 0px 0px">
    <ap-button
      [btnType]="'secondary'"
      [label]="'Cancel'"
      (click)="cancelClick()"
      class="cancelButton"
        style="width: 100px;"
    ></ap-button>
  </div>  
</kendo-dialog>