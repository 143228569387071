import { Component, OnInit } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {
  public isLoading:boolean=false;
  constructor(   public dialog: DialogRef,) { }

  ngOnInit(): void {
  }
  closeButtonClick()
  {
    this.dialog.close();   
  }


}
