import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { NewglaccountsComponent } from '../newglaccounts/newglaccounts.component';
import { EditglaccountsComponent } from '../editglaccounts/editglaccounts.component';
import { DeleteglaccountsComponent } from '../deleteglaccounts/deleteglaccounts.component';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
//import { TableModule } from '@appkit4/angular-components/table';
//import { SearchModule} from '@appkit4/angular-components/search';

@Component({
  selector: 'app-glaccountsgridpopup',
  templateUrl: './glaccountsgridpopup.component.html',
  styleUrls: ['./glaccountsgridpopup.component.scss'],
})
export class GLaccountsgridpopupComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective | any;
  public GLAccounts: any = [];
  public actionsLayout: ActionsLayout = 'normal';
  public StatutoryClearSearchID: any;
  public glAccountsGridForm: FormGroup = new FormGroup({
    businessTypeId: new FormControl({
      BusinessTypeId: 1,
      Description: 'SAP',
      Library: false,
      CountryName: 'UNITED KINGDOM',
      IndustryName: null,
      HelpId: '',
      CountryId: 231,
      IndustryId: null,
    }),
  });

  businesstypeList: any = [];
  businesstypeListDB: any;
  @Input() selectItemOnClose: any;
  public selectedItem: any;
  BusinessTypeID: any = 1;
  defaultBT: any = {
    BusinessTypeId: 1,
    Description: 'SAP',
    Library: false,
    CountryName: 'UNITED KINGDOM',
    IndustryName: null,
    HelpId: '',
    CountryId: 231,
    IndustryId: null,
  };
  data: any;

  public gridData: any = [];
  selectedClient: string | any;

  StatutoryCoAId: any = 'SAP';
  getDistinctStatutoryAccountCodes: any;
  isLoadId: any;

  constructor(
    //public dialog: DialogRef,
    private router: Router,
    private cookieService: CookieService,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {
    //super(dialog);
    //this.loadgridData();
  }

  ngOnInit(): void {
    this.selectItemOnClose = history.state.selectItemOnClose;
    this.getRefData();
    this.selectedClient = localStorage.getItem('SELECTEDCLIENT');
  }

  getRefData() {
    this.globalService
      .getDistinctStatutoryAccountCodes()
      .then((res: any) => {
        this.businesstypeListDB = res;
        if (this.selectItemOnClose) {
          this.selectedItem = this.selectItemOnClose;
        } else {
          this.selectedItem =
            this.glAccountsGridForm.controls['businessTypeId'].value || res[0];
        }

        this.getAllStatutoryAccountCodeByCode(this.selectedItem);
        this.businesstypeList = this.businesstypeListDB.slice();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  getAllStatutoryAccountCodeByCode(StatutoryCoAId: any) {
    this.StatutoryClearSearchID = StatutoryCoAId;
    if (!StatutoryCoAId) {
      StatutoryCoAId = this.StatutoryCoAId;
    }
    this.globalService
      .getAllStatutoryAccountCodeByCode(StatutoryCoAId)
      .then((res: any) => {
        this.GLAccounts = res;
        this.gridData = res;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  handleBTchange(selBT: any) {
    this.getAllStatutoryAccountCodeByCode(selBT);
  }

  handleFilterBusinessTypeId(value: any) {
    this.businesstypeList = this.businesstypeListDB.filter(
      (s: any) =>
        s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public onFilter(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.GLAccounts = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'AccountNumber',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'ShortDescription',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'BalanceSheetPandLIndicator',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'AccountType',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'TranCount',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Statement_Analysis',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Amount',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      this.loadgridData();
    }
    //this.dataBinding.skip = 0;
  }
  public onClearSearch(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    if (inputValue == '') {
      this.loadgridData();
    }
  }
  //sorting feature
  public sort: SortDescriptor[] = [
    {
      field: 'AccountNumber',
      dir: 'asc',
    },
  ];
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.GLAccounts = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }
  public loadgridData(): void {
    this.getAllStatutoryAccountCodeByCode(this.StatutoryClearSearchID);
  }

  closeButtonClick() {
    // this.dialog.close();
  }

  openNewGLAccountsPopup() {
    var formObject = this.glAccountsGridForm.getRawValue();

    this.router.navigate(['/newGLAccounts'], {
      state: {
        BusinessType: this.selectedItem,
      },
    });
    //this.dialog.close();
    // const dialogRef = this.dialogService.open({
    //   content: NewglaccountsComponent,
    //   width: 900,
    //   height:600
    // });
    // const newGLAccounts = dialogRef.content.instance as NewglaccountsComponent;
    // dialogRef.result.subscribe((r: any) => {
    //   console.log(r);
    //   //this.getAllStatutoryAccountCodeByCode(this.StatutoryCoAId);
    // });
    // console.log(this.selectedItem)
    // newGLAccounts.BusinessType = this.selectedItem;
  }

  openEditGLAccountsPopup(data: any) {
    var formObject = this.glAccountsGridForm.getRawValue();

    this.router.navigate(['/editGLAccount'], {
      state: {
        editGLAccountId: data.StatutoryAccountCodeId,
        BusinessType: this.selectedItem,
      },
    });
    //this.dialog.close();
    // const dialogRef = this.dialogService.open({
    //   content: EditglaccountsComponent,
    //   width: 1000,
    //   height:500
    // });
    // const editGLAccounts = dialogRef.content.instance as EditglaccountsComponent;
    // dialogRef.result.subscribe((r: any) => {
    //   console.log(r);
    //   this.getAllStatutoryAccountCodeByCode(this.selectedItem);
    // });
    // editGLAccounts.editGLAccountId = data.StatutoryAccountCodeId;
    //  editGLAccounts.BusinessType = this.selectedItem;
  }

  openCopyGLAccountsPopup() {}

  openDeleteGLAccountsPopup(data: any) {
    const dialogRef = this.dialogService.open({
      content: DeleteglaccountsComponent,
      width: 500,
      height: 220,
    });
    const delGLDetails = dialogRef.content
      .instance as DeleteglaccountsComponent;
    dialogRef.result.subscribe((r: any) => {
      this.getAllStatutoryAccountCodeByCode(this.selectedItem);
    });
    delGLDetails.delGLdata = data;
    delGLDetails.BusinessType = this.selectedItem;
  }
}
