import { Component, OnInit, Input } from '@angular/core';
import {
  DialogContentBase,
  ActionsLayout,
  DialogAction,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from '@progress/kendo-angular-dialog';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';

@Component({
  selector: 'app-deleteglaccounts',
  templateUrl: './deleteglaccounts.component.html',
  styleUrls: ['./deleteglaccounts.component.scss'],
})
export class DeleteglaccountsComponent
  extends DialogContentBase
  implements OnInit
{
  warnondelete: boolean = false;
  public actionsLayout: ActionsLayout = 'normal';
  delGLdata: any;
  @Input() BusinessType: any;

  constructor(
    public dialog: DialogRef,
    public toastr: ToastrManager,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {
    super(dialog);
  }

  ngOnInit(): void {}

  cancelClick() {
    this.dialog.close();
  }

  deleteGLAccount() {
    this.globalService
      .deleteStatutoryAccountCode(this.delGLdata.StatutoryAccountCodeId)
      .then((res: any) => {
        if (res == 'GL Account deleted successfully') {
          this.toastr.successToastr('GL account deleted successfully', 'Success');
          this.dialog.close();
        } else if (res != 'GL Account deleted successfully') {
          this.warnondelete = true;
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}
