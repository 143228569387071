
<div *ngIf="isLoading">
  <kendo-chunkprogressbar 
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%;"
  ></kendo-chunkprogressbar>
</div>
<div class="header">Manage keyword import</div>
<div class="container-fluid" >
  <div class="row" style="padding: 15px;">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <div class="row" style="padding: 20px; border: 1px solid rgba(0,0,0,.125);">
        <div class="col-md-1 col-xs-1">
          <span class="Appkit4-icon icon-folder-opened-outline" style="font-size: 37px; margin-top: 15px;"></span>
        </div>
        <div class="col-md-11 col-xs-11 uploadfile-css">
          <kendo-upload
            [saveUrl]="uploadSaveUrl"
            [removeUrl]="uploadRemoveUrl"
            (upload)="uploadEventHandler($event)"
            [withCredentials]= "withCredential"
            [multiple]="false"
            (success)="success($event)"
            [restrictions]="keywordFileRestrictions"
            [chunkable]="chunkSettings"
          >
          </kendo-upload>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
<div class="container-fluid" style="margin-top: 40px;">
  <div class="row">
    <div class="col-md-1 col-xs-1"></div>
    <div class="col-md-10 col-xs-10">
      <div *ngIf="isNextVisible">
        <kendo-label text="Select one or more sheets from the list below to import data from">
          &nbsp;
          <kendo-multiselect
            [checkboxes]="{ checkOnClick: false }"
            [autoClose]="false"
            [data]="worksheets"
            [(ngModel)]="value"
            style="width: 70%;"
          ></kendo-multiselect>
        </kendo-label>
      </div>
      <div>&nbsp;</div>
      <div class="clickToActions">
        <ap-button 
            class="cancelButton"
            [btnType]="'secondary'"
            [label]="'Cancel'"
            (click)="closeButtonClick()"
        ></ap-button>
        &nbsp;&nbsp;&nbsp;
        <ap-button style="float: right;" *ngIf="isNextVisible" [btnType]="'primary'" [label]="'Import'" class='okButton' (click)="finalImport()"></ap-button>
      </div>
    </div>
    <div class="col-md-1 col-xs-1"></div>
  </div>
</div>

<mat-card class="userCard">
  <!-- <div >
    <kendo-upload
    [saveUrl]="uploadSaveUrl"
    [removeUrl]="uploadRemoveUrl"
    (upload)="uploadEventHandler($event)"
    [withCredentials]= "withCredential"
    [multiple]="false"
    (success)="success($event)"
    [restrictions]="keywordFileRestrictions"
    [chunkable]="chunkSettings"
    style="width: 70%;"
    class="uploadfile-css"
  >
  </kendo-upload>
  </div> -->

  
</mat-card>



