import { Injectable } from '@angular/core';

@Injectable()
export class MyGlobalObject {
  public labelConfigs: any;
  showSpinner: boolean;

  public allPermission: any;
  loggedinUserDetail: any;
  userDetailsEnlightenPortal: any;
  mappedClientsForUser: any;
  selectedClient: any;
  authorizationErrorMessage: any;
  BackUpAdmin: any;
  selectedTaxAccountBT: any;
  clientPreferences: any;
  selectedFiscalYear: any;
  createExpressionShortcut: any = [];
  expressionRowsIndex: any = {};
  newExpressionfromNewRule: boolean = false;
  public constructor() {
    this.showSpinner = false;

    this.authorizationErrorMessage =
      "You don't have required permission to access the Application.Please contact Customer Support.";
  }
}
