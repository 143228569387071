import { Component, OnInit } from '@angular/core';
import { MenubarService } from 'src/app/common/menubarService/menubar.service';
@Component({
  selector: 'app-termsanduse',
  templateUrl: './termsanduse.component.html',
  styleUrls: ['./termsanduse.component.scss'],
})
export class TermsanduseComponent implements OnInit {
  constructor(public menubarService: MenubarService) {}

  ngOnInit(): void {
    this.menubarService.hide();
    this.menubarService.entityhide();
  }
}
