import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { GLaccountsgridpopupComponent } from '../glaccountsgridpopup/glaccountsgridpopup.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newglaccounts',
  templateUrl: './newglaccounts.component.html',
  styleUrls: ['./newglaccounts.component.scss'],
})
export class NewglaccountsComponent implements OnInit {
  newGLAccountForm: FormGroup | any;
  public actionsLayout: ActionsLayout = 'normal';
  //StatutoryCoAId: any = "SAP";
  textareaDemo = '';
  @Input() public BusinessType: any;
  selectedItem = {};
  StatutoryAccountCodeList: any;

  AccountTypeIdList: any;
  //dropdownlist
  AccountTypeIDList: any;
  selectedItem1 = {};
  item: any;
  //AccountTypeIdListDB: any;
  //AccountTypeIdList: any;
  GLDetailsfromDb: any;

  AccountTypeIDListDB: any;
  BusinessTypeId: any;
  BalanceSheetPandLIndicatorErrorMessage: any =
    'Balance or profit/loss is required';
  BusinessDesc: any;
  selectedClient: string | any;
  AccountTypeIdListDB: any;
  onSelectDropdown(event: any) {}
  onSelectItem(event: any) {}

  //radio
  // radioDisabled = false;
  //       showRadioLabel = true;
  //       groupRadioSelect: string="Balance";

  //       list2 = [
  //         {name: 'Balance', key: 'B'},
  //         {name: 'Profit/loss', key: 'P'},

  //       ];

  //       onSelected(event:any): void {
  //         console.log(event);
  //       }

  constructor(
    //public dialog: DialogRef,
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {
    //super(dialog);
    this.newGLAccountForm = this.fb.group({
      StatutoryCoAId: ['', [Validators.minLength(1), Validators.maxLength(20)]],
      BalanceSheetPandLIndicator: [''],
      AccountNumber: ['', [Validators.minLength(1), Validators.maxLength(20)]],
      ShortDescription: [
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(25),
          Validators.required,
        ],
      ],
      LongDescription: [
        '',
        [Validators.minLength(1), Validators.maxLength(255)],
      ],
      StatutoryAccountCodeId: 0,
      AccountTypeId: 0,
    });
  }

  ngOnInit(): void {
    this.BusinessType = history.state.BusinessType;
    //this.BusinessTypeId=this.BusinessType.BusinessTypeId;
    //this.BusinessDesc=this.BusinessType.Description;
    this.getRefData();
    this.selectedClient = localStorage.getItem('SELECTEDCLIENT');
  }

  getRefData() {
    this.globalService
      .getAllAccountType()
      .then((res) => {
        this.AccountTypeIdListDB = res;
        this.AccountTypeIdList = this.AccountTypeIdListDB.slice();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleFilterStatutorytreatment(value: any) {
    this.AccountTypeIdList = this.AccountTypeIdListDB.filter(
      (s: any) =>
        s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  cancelClick() {
    this.router.navigate(['./glAccounts'], {
      state: {
        selectItemOnClose: this.BusinessType,
      },
    });
    //this.dialog.close();
    // const dialogRef = this.dialogService.open({
    //   content: GLaccountsgridpopupComponent,
    //   width: 1200,
    //   height:600
    // });
    // const editGLAccounts = dialogRef.content.instance as GLaccountsgridpopupComponent;

    // editGLAccounts.selectItemOnClose=this.BusinessType
  }

  getStatutoryAccountCodesById(StatutoryAccountCodeId: any) {
    this.globalService
      .getStatutoryAccountCodesById(StatutoryAccountCodeId)
      .then((res) => {
        if (res) {
          this.GLDetailsfromDb = res;
          this.newGLAccountForm.controls['StatutoryCoAId'].setValue(
            this.GLDetailsfromDb.StatutoryCoAId
          );
          this.newGLAccountForm.controls['BalanceSheetPandLIndicator'].setValue(
            this.GLDetailsfromDb.BalanceSheetPandLIndicator
          );
          this.newGLAccountForm.controls['AccountNumber'].setValue(
            this.GLDetailsfromDb.AccountNumber
          );
          this.newGLAccountForm.controls['ShortDescription'].setValue(
            this.GLDetailsfromDb.ShortDescription
          );
          this.newGLAccountForm.controls['LongDescription'].setValue(
            this.GLDetailsfromDb.LongDescription
          );
          this.newGLAccountForm.controls['StatutoryAccountCodeId'].setValue(
            this.GLDetailsfromDb.StatutoryAccountCodeId
          );
          this.newGLAccountForm.controls['AccountTypeId'].setValue(
            this.GLDetailsfromDb.AccountTypeId
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  saveNewGL() {
    var formObject = this.newGLAccountForm.getRawValue();

    let StatutoryGLObject = {
      StatutoryCoAId: formObject.StatutoryCoAId,
      BalanceSheetPandLIndicator: formObject.BalanceSheetPandLIndicator,
      AccountNumber: formObject.AccountNumber,
      ShortDescription: formObject.ShortDescription,
      LongDescription: formObject.LongDescription,
      StatutoryAccountCodeId: 0,
      AccountTypeId: formObject.AccountTypeId.Id,
    };
    this.globalService
      .insertStatutoryAccountCode(StatutoryGLObject)
      .then((res) => {
        if (res == 'GL Account added successfully') {
          this.toastr.successToastr('GL account added successfully', 'Success');
          this.displayGrid();
        } else if (res == 'Maximum 20 characters allowed for GL Account id') {
          this.toastr.errorToastr(
            'Maximum 20 characters allowed for GL account id',
            'Failed'
          );
          return;
        } else if (
          res ==
          'Combination of Account Number and Account Id already exists for other Record'
        ) {
          this.toastr.errorToastr(
            'Combination of account number and account Id already exists for other Record',
            'Failed'
          );
          //this.displayGrid();
          return;
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.displayGrid();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  displayGrid() {
    this.router.navigate(['./glAccounts'], {
      state: {
        selectItemOnClose: this.BusinessType,
      },
    });
    //this.dialog.close();
    // const dialogRef = this.dialogService.open({
    //   content: GLaccountsgridpopupComponent,
    //   width: 1200,
    //   height:600
    // });
    // const editGLAccounts = dialogRef.content.instance as GLaccountsgridpopupComponent;
    // var defaultBT ={"BusinessTypeId":this.BusinessTypeId,"Description":this.BusinessDesc,"Library":false,"CountryName":"UNITED KINGDOM","IndustryName":null,"HelpId":"","CountryId":231,"IndustryId":null};
    // dialogRef.result.subscribe((r: any) => {

    // });
    // editGLAccounts.isLoadId = true;
    // editGLAccounts.glAccountsGridForm = new FormGroup({
    //   businessTypeId: new FormControl(defaultBT)
    // });
    // console.log(this.BusinessType)

    // editGLAccounts.selectItemOnClose=this.BusinessType
  }
}
