<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>
<div class="header">Offline allocation - define parameters</div>

<mat-card class="userCard scroll" style="padding: 0px 15px">
  <!-- <mat-card-title style="font-size: 18px; line-height: 1.5em;background-color: #D04A02;color: white;">
        &nbsp;Offline Referral - Define Parameters
    </mat-card-title> -->
  <mat-card-content>
    <div>
      <form class="k-form" [formGroup]="offlineReferralBreakdownForm">
        <kendo-formfield style="margin-top: 10px">
          <div class="row">
            <div class="col-6" style="border: 0px solid; padding-top: 0%">
              <kendo-label
                style="font-weight: bold"
                [for]="fiscalYears"
                text="Fiscal year:"
                >&nbsp;&nbsp;&nbsp;<kendo-label
                  >{{ this.selectedFiscalYear }}
                </kendo-label></kendo-label
              >
            </div>
            <div class="col-6" style="border: 0px solid">
              <kendo-label [for]="legalEntity" text="Legal entity:">
                <kendo-combobox
                  #legalEntity
                  [kendoDropDownFilter]="filterSettings"
                  formControlName="legalEntity"
                  [data]="legalEntities"
                  [(ngModel)]="selectedLegalEntity"
                  style="width: 88%; margin-left: 4px"
                  textField="Description"
                  valueField="LegalEntityID"
                  placeholder="Select legal entity"
                  (valueChange)="handleLegalEntityChange($event)"
                  [disabled]="
                    callingType == 'AnalyseReview' ||
                    isViewTransaction ||
                    isExportClicked ||
                    isGridOperatorVisible ||
                    isGridTextOperatorVisible
                  "
                >
                </kendo-combobox>
              </kendo-label>
            </div>
          </div>
        </kendo-formfield>
        <kendo-formfield style="margin-top: 10px">
          <div class="row">
            <div class="col-6" style="border: 0px solid">
              <kendo-label [for]="periodFrom" text="Period from:">
                <kendo-combobox
                  #periodFrom
                  [kendoDropDownFilter]="filterSettings"
                  formControlName="periodFrom"
                  [data]="periods"
                  [(ngModel)]="selectedPeriodFrom"
                  style="width: 88%; margin-left: 4px"
                  placeholder="Select period"
                  [disabled]="
                    isViewTransaction ||
                    isExportClicked ||
                    isGridOperatorVisible ||
                    isGridTextOperatorVisible
                  "
                >
                </kendo-combobox>
              </kendo-label>
            </div>
            <div class="col-6" style="border: 0px solid">
              <kendo-label [for]="periodFrom" text="Period to:">
                <kendo-combobox
                  #periodTo
                  [kendoDropDownFilter]="filterSettings"
                  formControlName="periodTo"
                  [data]="periods"
                  [(ngModel)]="selectedPeriodTo"
                  style="width: 88%; margin-left: 18px"
                  placeholder="Select period"
                  [disabled]="
                    isViewTransaction ||
                    isExportClicked ||
                    isGridOperatorVisible ||
                    isGridTextOperatorVisible
                  "
                >
                </kendo-combobox>
              </kendo-label>
            </div>
          </div>
        </kendo-formfield>
        <div *ngIf="isGridDataItemTaxAccountVisible">
          <kendo-formfield style="margin-top: 10px">
            <div class="row">
              <div class="col-12" style="border: 0px solid">
                <mat-card-title
                  ><b>Additional data items for selection</b></mat-card-title
                >
                <kendo-grid
                  [kendoGridBinding]="dataFilters"
                  [pageable]="false"
                  class="height"
                  [sortable]="false"
                  [reorderable]="true"
                  style="height: 200px; width: 100%"
                >
                  <kendo-grid-column
                    field="Id"
                    title="Id"
                    [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"
                    [width]="50"
                  ></kendo-grid-column>
                  <kendo-grid-column
                    field="Name"
                    title="Data item"
                    [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"
                    [width]="200"
                  ></kendo-grid-column>
                  <kendo-grid-column
                    field="Values"
                    title="Values"
                    [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"
                  ></kendo-grid-column>
                  <kendo-grid-column
                    [headerStyle]="{
                      'font-weight': 'bold',
                      color: '#d04a02',
                      'text-align': 'left'
                    }"
                    [width]="60"
                  >
                    <ng-template kendoGridCellTemplate let-data>
                      <button
                        kendoButton
                        (click)="editFilter(data)"
                        kendoTooltip
                        title="More"
                      >
                        ...
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column
                    [headerStyle]="{
                      'font-weight': 'bold',
                      color: '#d04a02',
                      'text-align': 'left'
                    }"
                    [width]="60"
                  >
                    <ng-template kendoGridCellTemplate let-data>
                      <button
                        kendoButton
                        (click)="deleteFilter(data)"
                        kendoTooltip
                        title="Delete"
                      >
                        <span class="Appkit4-icon icon-delete-outline"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                </kendo-grid>
              </div>
            </div>
          </kendo-formfield>

          <mat-card-title style="margin-top: 10px"
            ><b>Tax accounts</b></mat-card-title
          >

          <kendo-formfield style="margin-top: 2px">
            <kendo-label>
              <kendo-grid
                [kendoGridBinding]="taxAccounts"
                [pageable]="false"
                [sortable]="false"
                class="height"
                style="height: 200px; width: 50%; margin-right: 40px"
                [kendoGridSelectBy]="selectedCallback"
                [(selectedKeys)]="mySelectionMasterData"
                [reorderable]="true"
              >
                <kendo-grid-column
                  [width]="50"
                  field="TaxAccountCodeId"
                  title="Id"
                  [headerStyle]="{
                    'font-weight': 'bold',
                    'text-align': 'left',
                    color: '#d04a02'
                  }"
                ></kendo-grid-column>
                <kendo-grid-column
                  field="LongDescription"
                  title="Tax account"
                  [headerStyle]="{
                    'font-weight': 'bold',
                    'text-align': 'left',
                    color: '#d04a02'
                  }"
                ></kendo-grid-column>
                <kendo-grid-column
                  field="AccountingAreaDescription"
                  title="Line item"
                  [headerStyle]="{
                    'font-weight': 'bold',
                    'text-align': 'left',
                    color: '#d04a02'
                  }"
                ></kendo-grid-column>
              </kendo-grid>
              <div class="btn" style="padding-top: 30px; padding-bottom: 0px">
                <ap-button
                  kendoButton
                  (click)="forwardMasterData()"
                  [btnType]="'secondary'"
                  style="width: 50px; height: 37px; margin-bottom: 5px"
                  ><span class="material-icons"
                    >keyboard_arrow_right</span
                  ></ap-button
                >
                <ap-button
                  kendoButton
                  (click)="backwardMasterData()"
                  [btnType]="'secondary'"
                  style="width: 50px; height: 37px; margin-bottom: 5px"
                  ><span class="material-icons"
                    >keyboard_arrow_left</span
                  ></ap-button
                >
                <ap-button
                  kendoButton
                  (click)="forwardMasterDataAll()"
                  [btnType]="'secondary'"
                  style="width: 50px; height: 37px; margin-bottom: 5px"
                  ><span class="material-icons"
                    >keyboard_double_arrow_right</span
                  ></ap-button
                >
                <ap-button
                  kendoButton
                  (click)="backwardMasterDataAll()"
                  [btnType]="'secondary'"
                  style="width: 50px; height: 37px; margin-bottom: 5px"
                  ><span class="material-icons"
                    >keyboard_double_arrow_left</span
                  ></ap-button
                >
              </div>
              <kendo-grid
                [kendoGridBinding]="taxAccountsSelected"
                [pageable]="false"
                [sortable]="false"
                class="height"
                style="height: 200px; width: 50%; margin-left: 40px"
                [kendoGridSelectBy]="selectedCallback"
                [(selectedKeys)]="mySelectionMasterDataSelected"
              >
                <kendo-grid-column
                  [width]="50"
                  field="TaxAccountCodeId"
                  title="Id"
                  [headerStyle]="{
                    'font-weight': 'bold',
                    'text-align': 'left',
                    color: '#d04a02'
                  }"
                ></kendo-grid-column>
                <kendo-grid-column
                  field="LongDescription"
                  title="Tax account"
                  [headerStyle]="{
                    'font-weight': 'bold',
                    'text-align': 'left',
                    color: '#d04a02'
                  }"
                ></kendo-grid-column>
                <kendo-grid-column
                  field="AccountingAreaDescription"
                  title="Line item"
                  [headerStyle]="{
                    'font-weight': 'bold',
                    'text-align': 'left',
                    color: '#d04a02'
                  }"
                ></kendo-grid-column>
              </kendo-grid>
            </kendo-label>
          </kendo-formfield>
        </div>
      </form>
      <div *ngIf="isGridOperatorVisible">
        <div class="row" style="margin-top: 10px">
          <div style="border: 0px solid; padding: 0px 15px">
            <mat-card-title style="margin-bottom: 0px"
              ><b style="font-size: 14px"
                >Define absolute amount criteria</b
              ></mat-card-title
            >
          </div>
        </div>
        <div class="row" style="display: flex">
          <div class="col-6" style="padding-right: 0px">
            <kendo-label
              style="margin-top: 5px"
              text="Define the condition on the absolute amount column that will be used to filter the transactions."
            ></kendo-label>
          </div>
          <div class="col-6" style="padding-left: 0px">
            <ap-button
              [icon]="'plus-outline'"
              kendoButton
              [compact]="true"
              [add]="true"
              kendoTooltip
              title="Add"
              (onClick)="addFilter()"
            ></ap-button>
          </div>
        </div>

        <div class="row" style="margin-top: 10px">
          <div class="col-12" style="border: 0px solid">
            <kendo-grid
              #gridOperator
              style="width: 100%; margin-top: 6px"
              style="height: calc(100vh - 360px); width: 100%"
              [data]="filterOperatorDatas"
              [selectable]="true"
              [navigable]="true"
            >
              <kendo-grid-column
                field="Sno"
                title="Sno"
                [width]="50"
                [headerStyle]="{
                  'font-weight': 'bold',
                  'text-align': 'left',
                  color: '#d04a02'
                }"
              ></kendo-grid-column>
              <kendo-grid-column
                field="DataItemId"
                title="Data item id"
                [width]="100"
                [headerStyle]="{
                  'font-weight': 'bold',
                  'text-align': 'left',
                  color: '#d04a02'
                }"
              ></kendo-grid-column>
              <kendo-grid-column
                field="DataItemText"
                title="Data item text"
                [width]="200"
                [headerStyle]="{
                  'font-weight': 'bold',
                  'text-align': 'left',
                  color: '#d04a02'
                }"
              ></kendo-grid-column>
              <kendo-grid-column
                field="OperatorId"
                title="New destination account"
                [width]="250"
                [headerStyle]="{
                  'font-weight': 'bold',
                  'text-align': 'left',
                  color: '#d04a02'
                }"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-combobox
                    (valueChange)="operatorChange(dataItem, $event)"
                    [value]="dataItem.OperatorId"
                    textField="Operator"
                    valueField="Id"
                    [data]="operators"
                  >
                  </kendo-combobox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column
                field="Criteria"
                title="Criteria"
                [headerStyle]="{
                  'font-weight': 'bold',
                  'text-align': 'left',
                  color: '#d04a02'
                }"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox
                    [value]="dataItem.Criteria"
                    (valueChange)="criteriaChange(dataItem, $event)"
                  >
                  </kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>
          </div>
        </div>
        <div style="margin-top: 10px">
          <p style="margin-bottom: 0px">
            The system will filter the offline referral to include transactions
            where all of the above conditions are true.
          </p>
        </div>
        <div class="row d-grid gap-2 d-md-flex justify-content-md-start">
          <!-- <div class="col-1" style="border: 0px solid; margin-top: 6px">
          <ap-button
            kendoButton
            [btnType]="'secondary'"
            [label]="'Add'"
            (onClick)="addFilter()"
          ></ap-button>
        </div> -->
          <div
            class="clickToActions fixed-bottom"
            style="padding: 0px 15px 0px 15px; bottom: 2%; right: 1%"
          >
            <ap-button
              kendoButton
              [btnType]="'secondary'"
              [label]="'Close'"
              (onClick)="saveFilter()"
            ></ap-button>
          </div>
        </div>
      </div>
      <div *ngIf="isGridTextOperatorVisible">
        <div class="row">
          <div class="col-12" style="border: 0px solid; margin-top: 10px">
            <b
              ><kendo-label style="border: 0px solid; font-size: 14px"
                >Data item: {{ selectedTextOperatorText }}</kendo-label
              ></b
            >
          </div>
        </div>
        <div class="row" style="margin-top: 5px">
          <div class="col-12" style="border: 0px solid">
            <kendo-label text="Select values"></kendo-label>
          </div>
        </div>
        <div class="row">
          <div class="col-12" style="border: 0px solid">
            <kendo-multiselect
              [checkboxes]="{ checkOnClick: false }"
              [autoClose]="false"
              [data]="filters"
              [(ngModel)]="textFiltervalue"
              textField="Value"
              valueField="Value"
              style="width: 100%; margin-top: 6px"
            ></kendo-multiselect>
          </div>
        </div>
        <div class="row d-grid gap-2 d-md-flex justify-content-md-start">
          <div class="clickToActions" style="padding: 20px 15px">
            <ap-button
              style="border: 0px solid"
              kendoButton
              [btnType]="'secondary'"
              [label]="'Close'"
              (onClick)="saveTextFilter()"
            ></ap-button>
          </div>
        </div>
      </div>
      <div *ngIf="isGridDataItemTaxAccountVisible">
        <div class="row d-grid gap-2 d-md-flex justify-content-md-start">
          <div
            class="clickToActions btns fixed-bottom"
            style="padding: 5px 15px; bottom: 2%"
          >
            <ap-button
              kendoButton
              [btnType]="'secondary'"
              [label]="'Close'"
              (onClick)="navigate()"
            ></ap-button>
            <ap-button
              kendoButton
              [btnType]="'secondary'"
              style="margin-right: 6px"
              [label]="'Export all - Detail'"
              (click)="exportAll()"
            ></ap-button>
            <ap-button
              kendoButton
              [btnType]="'primary'"
              style="margin-right: 6px"
              [label]="'View transactions'"
              (click)="ViewTransaction()"
            ></ap-button>
          </div>
        </div>
      </div>
      <div *ngIf="isViewTransaction">
        <div class="row" style="margin-left: 1px">
          <div
            class="col-3"
            style="
              border: 1px solid #d9d9d9;
              margin-top: 10px;
              height: calc(100vh - 290px);
            "
          >
            <kendo-treeview
              [nodes]="offlineReferralTransactions"
              textField="description"
              [loadOnDemand]="false"
              kendoTreeViewExpandable
              kendoTreeViewHierarchyBinding
              childrenField="items"
              kendoTreeViewCheckable
              [(checkedKeys)]="checkedKeys"
              (checkedChange)="checkEvent($event)"
              style="height: 300px; margin-top: 10px"
            >
            </kendo-treeview>
          </div>

          <div class="col-9" style="margin-top: 10px">
            <button
              kendoButton
              class="actionButton chooseColumnsButton"
              (click)="openWindow()"
              [disabled]="isLoading"
              style="color: #ffff; padding: 2px 8px; background-color: #d04a02"
            >
              Choose columns
            </button>

            <kendo-tabstrip
              style="margin-top: 10px"
              (tabClose)="onClose($event)"
              (tabSelect)="onSelect($event)"
            >
              <kendo-tabstrip-tab
                title="Transactions pending allocation"
                [selected]="true"
              >
                <ng-template kendoTabContent>
                  <div>
                    <kendo-grid
                      [kendoGridBinding]="pendingTransactionResponse"
                      style="
                        width: 100%;
                        margin-top: 10px;
                        height: calc(100vh - 455px);
                      "
                    >
                      <kendo-grid-column
                        *ngFor="let col of gridColumns"
                        field="{{ col.field }}"
                        title="{{ col.title }}"
                        format="{{ col.format }}"
                        [filter]="col.type"
                        [width]="200"
                      ></kendo-grid-column>
                    </kendo-grid>
                  </div>
                  <div class="row">
                    <div
                      class="col-5"
                      style="border: 0px solid; margin-top: 10px; display: flex"
                    >
                      <ap-button
                        kendoButton
                        (click)="backwardPendingDataToFirst()"
                        [btnType]="'secondary'"
                        style="width: 50px; height: 37px; margin-right: 10px"
                        ><span class="material-icons"
                          >keyboard_double_arrow_left</span
                        ></ap-button
                      >

                      <ap-button
                        kendoButton
                        (click)="backwardPendingData()"
                        [btnType]="'secondary'"
                        style="width: 50px; height: 37px; margin-right: 10px"
                        ><span class="material-icons"
                          >keyboard_arrow_left</span
                        ></ap-button
                      >

                      <ap-button
                        kendoButton
                        (click)="forwardPendingData()"
                        [btnType]="'secondary'"
                        style="width: 50px; height: 37px; margin-right: 10px"
                        ><span class="material-icons"
                          >keyboard_arrow_right</span
                        ></ap-button
                      >

                      <ap-button
                        kendoButton
                        (click)="forwardPendingDataToLast()"
                        [btnType]="'secondary'"
                        style="width: 50px; height: 37px"
                        ><span class="material-icons"
                          >keyboard_double_arrow_right</span
                        ></ap-button
                      >
                      <p style="margin-left: 10px; margin-top: 10px">
                        Page: {{ currentTransactionPendingPageNo }}
                      </p>
                    </div>

                    <div
                      class="col-7"
                      style="
                        border: 0px solid;
                        margin-top: 20px;
                        text-align: right;
                      "
                    >
                      Total Pages: {{ totalPagePending }} &nbsp;&nbsp;Total
                      Rows: {{ totalRowPending }}
                    </div>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab title="Transactions exported">
                <ng-template kendoTabContent>
                  <div>
                    <kendo-grid
                      [kendoGridBinding]="exportedTransactionResponse"
                      style="
                        width: 100%;
                        margin-top: 10px;
                        height: calc(100vh - 461px);
                      "
                    >
                      <kendo-grid-column
                        *ngFor="let col of gridColumns"
                        field="{{ col.field }}"
                        title="{{ col.title }}"
                        format="{{ col.format }}"
                        [filter]="col.type"
                        [width]="250"
                      ></kendo-grid-column>
                    </kendo-grid>
                  </div>
                  <div class="row">
                    <div
                      class="col-5"
                      style="border: 0px solid; margin-top: 10px; display: flex"
                    >
                      <ap-button
                        kendoButton
                        (click)="backwardExportedDataToFirst()"
                        [btnType]="'secondary'"
                        style="width: 50px; height: 37px; margin-right: 10px"
                        ><span class="material-icons"
                          >keyboard_double_arrow_left</span
                        ></ap-button
                      >

                      <ap-button
                        kendoButton
                        (click)="backwardExportedData()"
                        [btnType]="'secondary'"
                        style="width: 50px; height: 37px; margin-right: 10px"
                        ><span class="material-icons"
                          >keyboard_arrow_left</span
                        ></ap-button
                      >

                      <ap-button
                        kendoButton
                        (click)="forwardExportedData()"
                        [btnType]="'secondary'"
                        style="width: 50px; height: 37px; margin-right: 10px"
                        ><span class="material-icons"
                          >keyboard_arrow_right</span
                        ></ap-button
                      >

                      <ap-button
                        kendoButton
                        (click)="forwardExportedDataToLast()"
                        [btnType]="'secondary'"
                        style="width: 50px; height: 37px"
                        ><span class="material-icons"
                          >keyboard_double_arrow_right</span
                        ></ap-button
                      >
                      <p style="margin-left: 10px; margin-top: 10px">
                        Page: {{ currentTransactionExportedPageNo }}
                      </p>
                    </div>

                    <div
                      class="col-7"
                      style="
                        border: 0px solid;
                        margin-top: 20px;
                        text-align: right;
                      "
                    >
                      Total Pages: {{ totalPageExported }} &nbsp;&nbsp;Total
                      Rows: {{ totalRowExported }}
                    </div>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab title="Transactions allocated">
                <ng-template kendoTabContent>
                  <div>
                    <kendo-grid
                      [kendoGridBinding]="allocatedTransactionResponse"
                      style="
                        width: 100%;
                        margin-top: 10px;
                        height: calc(100vh - 461px);
                      "
                    >
                      <kendo-grid-column
                        *ngFor="let col of gridColumns"
                        field="{{ col.field }}"
                        title="{{ col.title }}"
                        format="{{ col.format }}"
                        [filter]="col.type"
                        [width]="250"
                      ></kendo-grid-column>
                    </kendo-grid>
                  </div>
                  <div class="row">
                    <div
                      class="col-5"
                      style="border: 0px solid; margin-top: 10px; display: flex"
                    >
                      <ap-button
                        kendoButton
                        (click)="backwardAllocatedDataToFirst()"
                        [btnType]="'secondary'"
                        style="width: 50px; height: 37px; margin-right: 10px"
                        ><span class="material-icons"
                          >keyboard_double_arrow_left</span
                        ></ap-button
                      >

                      <ap-button
                        kendoButton
                        (click)="backwardAllocatedData()"
                        [btnType]="'secondary'"
                        style="width: 50px; height: 37px; margin-right: 10px"
                        ><span class="material-icons"
                          >keyboard_arrow_left</span
                        ></ap-button
                      >

                      <ap-button
                        kendoButton
                        (click)="forwardAllocatedData()"
                        [btnType]="'secondary'"
                        style="width: 50px; height: 37px; margin-right: 10px"
                        ><span class="material-icons"
                          >keyboard_arrow_right</span
                        ></ap-button
                      >

                      <ap-button
                        kendoButton
                        (click)="forwardAllocatedDataToLast()"
                        [btnType]="'secondary'"
                        style="width: 50px; height: 37px; margin-right: 10px"
                        ><span class="material-icons"
                          >keyboard_double_arrow_right</span
                        ></ap-button
                      >
                      <p style="margin-left: 10px; margin-top: 10px">
                        Page: {{ currentTransactionAllocatedPageNo }}
                      </p>
                    </div>

                    <div
                      class="col-7"
                      style="
                        border: 0px solid;
                        margin-top: 20px;
                        text-align: right;
                      "
                    >
                      Total Pages: {{ totalPageAllocated }} &nbsp;&nbsp;Total
                      Rows: {{ totalRowAllocated }}
                    </div>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
            </kendo-tabstrip>
          </div>
        </div>
        <div
          class="clickToActions fixed-bottom"
          style="padding: 15px 0px 15px 0px; right: 1%"
        >
          <ap-button
            kendoButton
            [btnType]="'secondary'"
            [label]="'Close'"
            (click)="CloseViewTransaction()"
          ></ap-button>
          <ap-button
            style="margin-right: 6px"
            kendoButton
            [btnType]="'primary'"
            [label]="'Export'"
            (click)="exportAll()"
          ></ap-button>
        </div>
      </div>

      <div class="row" *ngIf="isExportClicked">
        <div
          class="col-6 newCol"
          style="border: 0px solid; margin-top: 10px; padding-right: 0px"
        >
          <kendo-grid
            [kendoGridBinding]="exportedTransactionNotSelected"
            [pageable]="false"
            [sortable]="false"
            [kendoGridSelectBy]="selectedCallback"
            [(selectedKeys)]="mySelectionExportData"
            style="height: calc(100vh - 290px); width: 90%"
          >
            <kendo-grid-column
              [width]="50"
              field="Id"
              title="Id"
              [headerStyle]="{
                'font-weight': 'bold',
                'text-align': 'left',
                color: '#d04a02'
              }"
            ></kendo-grid-column>
            <kendo-grid-column
              field="DisplayName"
              title="Display name"
              [headerStyle]="{
                'font-weight': 'bold',
                'text-align': 'left',
                color: '#d04a02'
              }"
            ></kendo-grid-column>
          </kendo-grid>
        </div>
        <div
          class="col-1"
          style="
            margin-top: 10px;
            margin-top: 8%;
            position: absolute;
            left: 45.5%;
          "
        >
          <ap-button
            kendoButton
            (click)="forwardExportData()"
            [btnType]="'secondary'"
            style="width: 50px; height: 37px; margin-bottom: 5px"
            ><span class="material-icons">keyboard_arrow_right</span></ap-button
          >
          <ap-button
            kendoButton
            (click)="backwardExportData()"
            [btnType]="'secondary'"
            style="width: 50px; height: 37px; margin-bottom: 5px"
            ><span class="material-icons">keyboard_arrow_left</span></ap-button
          >
          <ap-button
            kendoButton
            (click)="forwardExportDataAll()"
            [btnType]="'secondary'"
            style="width: 50px; height: 37px; margin-bottom: 5px"
            ><span class="material-icons"
              >keyboard_double_arrow_right</span
            ></ap-button
          >
          <ap-button
            kendoButton
            (click)="backwardExportDataAll()"
            [btnType]="'secondary'"
            style="width: 50px; height: 37px; margin-bottom: 5px"
            ><span class="material-icons"
              >keyboard_double_arrow_left</span
            ></ap-button
          >
        </div>
        <div
          class="col-6"
          style="
            border: 0px solid;
            margin-top: 10px;
            padding-left: 30px;
            padding-right: 10px;
          "
        >
          <kendo-grid
            [kendoGridBinding]="exportedTransactionSelected"
            [pageable]="false"
            [sortable]="false"
            style="height: calc(100vh - 290px); width: 90%"
            [kendoGridSelectBy]="selectedCallback"
            [(selectedKeys)]="mySelectionExportDataSelected"
            [rowClass]="rowClass"
            [reorderable]="true"
          >
            <kendo-grid-column
              [width]="50"
              field="Sno"
              title="Sno"
              [headerStyle]="{
                'font-weight': 'bold',
                'text-align': 'left',
                color: '#d04a02'
              }"
            ></kendo-grid-column>
            <kendo-grid-column
              [width]="50"
              field="Id"
              title="Id"
              [headerStyle]="{
                'font-weight': 'bold',
                'text-align': 'left',
                color: '#d04a02'
              }"
            ></kendo-grid-column>
            <kendo-grid-column
              field="DisplayName"
              title="Display name"
              [headerStyle]="{
                'font-weight': 'bold',
                'text-align': 'left',
                color: '#d04a02'
              }"
            ></kendo-grid-column>
          </kendo-grid>
        </div>
        <div
          class="col-1 btn1"
          style="
            border: 0px solid;
            margin-top: 60px;
            position: absolute;
            right: 0;
            margin-top: 10%;
            padding-left: 50px;
          "
        >
          <ap-button
            kendoButton
            [btnType]="'secondary'"
            style="width: 50px; height: 37px; margin-bottom: 5px"
            (click)="onMoveUpButtonClick()"
          >
            <span class="material-icons">keyboard_arrow_up</span>
          </ap-button>
          <ap-button
            kendoButton
            (click)="onMoveDownButtonClick()"
            [btnType]="'secondary'"
            style="width: 50px; height: 37px; margin-bottom: 5px"
          >
            <span class="material-icons">keyboard_arrow_down</span>
          </ap-button>
        </div>
      </div>

      <div class="row" *ngIf="isExportClicked">
        <div
          class="clickToActions fixed-bottom"
          style="padding: 20px 15px 0px 15px; bottom: 2%"
        >
          <ap-button
            kendoButton
            [btnType]="'secondary'"
            [label]="'Cancel'"
            (click)="closeExport()"
          ></ap-button>
          <ap-button
            kendoButton
            [btnType]="'primary'"
            [label]="'Export'"
            style="margin-right: 6px"
            (click)="export()"
          ></ap-button>
        </div>
      </div>
    </div>
    <kendo-window
      *ngIf="opened"
      [draggable]="isDraggable"
      (close)="openClose(false)"
      [width]="300"
      [height]="300"
      [(state)]="windowState"
      title="Choose Columns"
      style="
        position: fixed !important;
        margin-left: 2% !important;
        margin-top: 4% !important;
      "
    >
      <li *ngFor="let column of columns" class="">
        <input
          type="checkbox"
          id="{{ column }}"
          kendoCheckBox
          [checked]="!isHidden(column)"
          (change)="hideColumn(column)"
        />
        <label class="k-checkbox-label" for="{{ column">{{ column }}</label>
      </li>
      &nbsp;
      <ap-button
        [btnType]="'primary'"
        [label]="'Save'"
        themeColor="primary"
        (click)="saveColumn()"
        style="margin-right: 6px"
      ></ap-button>
    </kendo-window>
  </mat-card-content>
</mat-card>

<style>
  .k-grid {
    width: 500px;
  }

  .discontinued {
    font-weight: bold;
    color: #f00;
  }

  .k-grid .no-padding {
    padding: 0;
  }
  .whole-cell {
    display: block;
    padding: 8px 12px; /* depends on theme */
  }
</style>
