import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from '@appkit4/angular-components/checkbox';
import {
  ActionsLayout,
  DialogRef,
  DialogService,
  WindowState,
} from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { LegalEntity } from 'src/app/_models/legalEntity';
import { SelectEvent, TabCloseEvent } from '@progress/kendo-angular-layout';
import { JournalService } from 'src/app/common/services/journal/journal-service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { BusinessType } from 'src/app/_models/businessType';
import { JournalCategory } from 'src/app/_models/journalCategory';
import { FiscalYear } from 'src/app/_models/fiscalYear';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import {
  offlineReferralGridColumnSetting,
  // offlineReferralGridColumnSetting2,
  offlineReferralGridColumnSettingData,
} from 'src/app/_models/offlineReferralGridColumnSetting';
// import { Observable, of } from 'rxjs';
import { OfflineReferralService } from 'src/app/common/services/offlineReferral/offlineReferral-service';
import {
  SupplementayTaxAccount,
  TaxAccount,
  TaxAccountAmount,
} from 'src/app/_models/taxAccount';
import { GlTaxAccount } from 'src/app/_models/glTaxAccount';
import {
  AddEvent,
  GridDataResult,
  CellClickEvent,
  CellCloseEvent,
  SaveEvent,
  CancelEvent,
  GridComponent,
  RemoveEvent,
  RowArgs,
} from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { anyChanged, Keys } from '@progress/kendo-angular-common';
import { map, timeout } from 'rxjs/operators';
import {
  SaveAdjustmentModel,
  SupplementaryCalculationFileMappingDTO,
  TaxAccountAmountDTOList,
} from 'src/app/_models/saveAdjustmentModel';
import { ToastrManager } from 'ng6-toastr-notifications';
import { EditService } from 'src/app/common/services/journal/edit.service';
import {
  ChunkSettings,
  FileRestrictions,
  SuccessEvent,
  UploadEvent,
} from '@progress/kendo-angular-upload';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AdjustmentDetailResponse } from 'src/app/_models/adjustmentDetailResponse';
import { EditJournalResponse } from 'src/app/_models/editJournalResponse';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AutomatchtransactionsService } from 'src/app/common/services/automatchtransactions/automatchtransactions.service';
import { ManagetransactionService } from 'src/app/common/services/ManageTransaction/managetransaction.service';
import { SharedserviceMoveService } from 'src/app/common/services/moveTransactions/sharedservice-move.service';
import { MoveapiService } from 'src/app/common/services/moveTransactions/moveapi.service';
@Component({
  selector: 'app-allocate-transaction',
  templateUrl: './allocate-transaction.component.html',
  styleUrls: ['./allocate-transaction.component.scss'],
})
export class AllocateTransactionComponent implements OnInit {
  public gridData!: any[];

  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;

  public pageSize = 20;
  public skip = 0;
  public totalCount: any = 0;
  addJournalForm!: FormGroup;
  businessTypes: BusinessType[] = [];
  businessType!: BusinessType;
  legalEntities: LegalEntity[] = [];
  journalCategories: JournalCategory[] = [];
  periods: string[] = [];
  public actionsLayout: ActionsLayout = 'normal';
  isLoading: boolean = false;
  fiscalYears: FiscalYear[] = [];
  fiscalYear!: FiscalYear;
  fiscalYearValue: number = 0;
  value: Date = new Date();
  taxAccounts: TaxAccount[] = [];
  taxAccountsTo: TaxAccount[] = [];
  filteredTaxAccounts: TaxAccount[] = [];
  glTaxAccounts: GlTaxAccount[] = [];
  businessTypeId: number = 0;
  legalEntityId: number = 0;
  isDraggable: boolean = true;
  opened: boolean = false;
  taxAccountAmount!: TaxAccountAmountDTOList;
  taxAccountAmounts: TaxAccountAmountDTOList[] = [];
  gridSno: number = 0;
  Complex: number = 0;
  selectedcolumnSort: any;
  gridSettings: any;
  // hiddenColumns: any;
  public hiddenColumns: string[] = [];
  columns: string[] = [];
  public windowState: WindowState = 'default';
  defaultSelectedList!: any[];
  public openClose(isOpened: boolean): void {
    this.opened = isOpened;
  }
  public isHidden(columnName: string): boolean {
    return this.hiddenColumns.indexOf(columnName) > -1;
  }

  public view!: Observable<GridDataResult>;
  public formGroup!: FormGroup;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 100,
  };

  public changes = {};
  taxAccountAmt!: TaxAccountAmount;
  isTaxAmtData: boolean = false;
  saveAdjustment!: SaveAdjustmentModel;
  saveAdj: any;
  saveAdjTotal: any;
  totalAmt: number = 0;
  isGridEdited: boolean = false;
  uploadSaveUrl: string = '';
  uploadRemoveUrl: string = '';
  fileName: string = '';
  fileUid: string = '';
  private baseUrl: string = '';
  private apifolder: string = '';
  private selectedClient: any;
  private rowsSelected: number[] = [];
  supplementayTaxAccounts: SupplementayTaxAccount[] = [];
  supplementayFilteredTaxAccounts: SupplementayTaxAccount[] = [];
  supplementayTaxAccount!: SupplementayTaxAccount;
  supplementaryCalculationFileMappingDTO!: SupplementaryCalculationFileMappingDTO;
  isFileUploaded: boolean = false;
  editJournallResponses: EditJournalResponse[] = [];
  editJournallResponse!: EditJournalResponse;
  adjustmentDetailResponse!: AdjustmentDetailResponse;
  adjustmentDetailResponses: AdjustmentDetailResponse[] = [];
  mode: string = '';
  taxAccountsId: any;
  getTransactionView: any;

  matchCheckboxState: boolean = false;
  excelCheckboxState: boolean = true;
  exportdata: any;
  result: any;
  public gridColumns: offlineReferralGridColumnSetting[] = [];
  gridColumn!: offlineReferralGridColumnSetting;

  isMatchTransaction: boolean = true;
  isMovedTransaction: boolean = false;
  isTaxChildTransaction: boolean = false;
  isviewAutomatch: boolean = false;

  LegalEntityId: any;
  allRowsSelected!: boolean;
  isSelectAll: boolean = false;
  isSelect: boolean = false;
  isTransactionNew: any = false;
  transationData: any;
  isItemSelected: boolean = false;

  matchtransation!: TaxAccount[];
  formdata: any = [];
  startPayload: {} = {};
  orchestrationId: string = '';
  items: any;
  gridView!: { data: any; total: any };
  AssignTransactionData: any;
  addTransctionData: any;
  selecteddata: any = [];
  getTransactionCount!: any[];
  currentTransactionExportedPageNo: number = 1;
  currentTransactionPendingPageNo: number = 1;
  currentTransactionAllocatedPageNo: number = 1;
  startingRowPending = 0;
  totalRowPending: any;
  // startingRowPending: number = 0;
  endingRowPending: number = 100;

  totalRowExported: number = 0;
  startingRowExported: number = 0;
  endingRowExported: number = 0;

  totalRowAllocated: number = 0;
  startingRowAllocated: number = 0;
  endingRowAllocated: number = 0;
  totalPagePending: any;

  constructor(
    private fb: FormBuilder,
    private journalService: JournalService,
    private automatchService: AutomatchtransactionsService,
    private sharedService: SharedserviceMoveService,
    private moveapiservice: MoveapiService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public editService: EditService,
    public toastr: ToastrManager,
    private cookieService: CookieService,
    private dialogService: DialogService,
    private managetransation: ManagetransactionService,
    private OfflineReferralService: OfflineReferralService
  ) {
    this.automatchMappingForm = this.fb.group({
      excelCheck: true,
      matchCheck: true,
      destinationTaxEntity: ['', Validators.required],
      automatchMappingGrid: [
        '',
        this.automatchMappingList.length == 0 ? Validators.required : '',
      ],
    });
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    this.getAutoMatchColumns();
  }
  selectedBusinessType: BusinessType | undefined;
  sortorderAsc: boolean = false;
  sortorder: string = 'desc';
  selectedLegalEntity!: LegalEntity | null;
  selectedTaxAccount: any;
  toTaxAccount: any;
  selectedGlAccount: GlTaxAccount | undefined;
  debitCreditType: string = 'debit';
  withCredential: boolean = false;
  isJourneyPeriodNew: boolean = false;
  onlineAllocationId: number = 0;

  public automatchMappingForm!: FormGroup;

  public automatchcolumnsList: any[] = [];
  public automatchMappingList: any[] = [];
  public selectedautomatchColumn = (args: any) => args.dataItem;
  public mySelectedautomatchColumn: string[] = [];
  public mySelectedautomatchColumnMapping: string[] = [];
  public checkedRowData: any[] = [];

  ngOnInit(): void {
    this.setupForm();
    this.getLatestFiscalDetail();
    this.GetTransactionType();
    this.getBusinessTypeList();
    this.getGridSetting();

    this.view = this.editService.pipe(
      map((data) => process(data, this.gridState))
    );
    this.editService.read();
    this.gridSno = 1;
    this.taxAccountAmounts = [];
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.uploadSaveUrl =
      this.baseUrl +
      this.apifolder +
      '/Journal/UploadSupplementaryToContainer?ClientdbName=' +
      this.selectedClient.DBName;
    this.uploadRemoveUrl = 'removeUrl';
  }

  setupForm(): void {
    this.addJournalForm = this.fb.group({
      businessType: ['', Validators.required],
      legalEntity: ['', Validators.required],
      legalTaxEntity: ['', Validators.required],
      description: ['', Validators.required],
      taxAccount: [''],
      excelCheck: [],
      matchedTrans: [],
      sortBy: [''],
    });
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  getBusinessTypeList(): void {
    this.isLoading = true;
    this.journalService.getAllBusinessTypeList().subscribe(
      (response) => {
        this.businessTypes = response;

        if (this.businessTypes.length == 1) {
          this.selectedBusinessType = this.businessTypes[0];
          this.handleBusinessTypeChange(this.selectedBusinessType);
        }
        this.isLoading = false;
      },
      (error) => {
        //console.log(error);
        this.isLoading = false;
      }
    );
  }

  getLegalEntityList(businessTypeId: number): void {
    this.isLoading = true;
    this.journalService
      .getAllLegalEntityByTaxChartOfAccount(businessTypeId)
      .subscribe(
        (response) => {
          this.legalEntities = response;
          if (this.legalEntities.length == 1) {
            this.selectedLegalEntity = this.legalEntities[0];
            this.handleLegalEntityChange(this.selectedLegalEntity);
          }
          this.isLoading = false;
        },
        (error) => {
          //console.log(error);
          this.isLoading = false;
        }
      );
  }
  getTaxAccounts(businessTypeId: number, legalEntityID: number): void {
    this.isLoading = true;
    this.journalService.GetTaxAccounts(businessTypeId, legalEntityID).subscribe(
      (response) => {
        this.taxAccounts = response;
        this.taxAccountsTo = response;
        this.taxAccountsId = response;
        this.isLoading = false;
      },
      (error) => {
        //console.log(error);
        this.isLoading = false;
      }
    );
  }

  getLatestFiscalDetail(): void {
    this.isLoading = true;
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.fiscalYear = JSON.parse(year);
    this.fiscalYearValue = this.fiscalYear.FiscalYearId;
  }
  hasTransactionValue = true;
  getTransactionViewData(startIndex: number, maxRows: number): void {
    maxRows = 100;
    this.isLoading = true;
    this.managetransation
      .GetAllocateTransactionViewData(
        this.selectedTaxAccount!.TaxAccountCodeId,
        this.selectedLegalEntity!.ID,
        this.fiscalYearValue,
        startIndex,
        maxRows,
        this.isMatchTransaction,
        this.isMovedTransaction,
        this.isTaxChildTransaction,
        this.selectedcolumnSort.field,
        this.sortorderAsc
      )
      .subscribe(
        (response) => {
          this.getTransactionView = response;
          this.isLoading = false;

          // let count = 0;
          // for (let i = 0; i < this.getTransactionView.length; i++) {
          //   let changed = false;
          //   for (let j = 0; j < this.selecteddata.length; j++) {
          //     if (this.getTransactionView[i]._id === this.selecteddata[j]._id) {
          //       this.getTransactionView[i].IsChecked = true;
          //       count++;
          //       changed = true;
          //     }
          //   }
          //   if (changed == false) {
          //     this.getTransactionView[i].IsChecked = false;
          //   }
          // }
          // if (count == this.getTransactionView.length) {
          //   this.isSelectAll = true;
          // } else {
          //   this.isSelectAll = false;
          // }

          // var mapListArray = this.getTransactionView;
          // this.getTransactionView = [];
          // var nonMatchList: Array<any> = [];

          // mapListArray.forEach((element: any) => {
          //   if (
          //     this.isMatchTransaction &&
          //     element.MatchID != null &&
          //     element.MatchID != ''
          //   ) {
          //     this.getTransactionView.push(element);
          //   } else if (
          //     !this.isMatchTransaction &&
          //     this.isMovedTransaction &&
          //     element.Moved == true
          //   ) {
          //     this.getTransactionView.push(element);
          //   } else if (
          //     !this.isMatchTransaction &&
          //     this.isTaxChildTransaction &&
          //     element.IsChild == true
          //   ) {
          //     this.getTransactionView.push(element);
          //   } else {
          //     nonMatchList.push(element);
          //   }
          // });
          // this.getTransactionView.push(...nonMatchList);

          // if (this.isMovedTransaction) {
          //   var mapListArray = this.getTransactionView;
          //   this.getTransactionView = [];

          //   var nonMatchList: Array<any> = [];
          //   mapListArray.forEach((element: any) => {
          //     if (
          //       this.isMovedTransaction &&
          //       element.TransactionType != null &&
          //       element.TransactionType != ''
          //     ) {
          //       this.getTransactionView.push(element);
          //     } else if (
          //       !this.isMovedTransaction &&
          //       this.isMatchTransaction &&
          //       element.MatchID != null &&
          //       element.MatchID != ''
          //     ) {
          //       this.getTransactionView.push(element);
          //     } else if (
          //       !this.isMovedTransaction &&
          //       this.isTaxChildTransaction &&
          //       element.IsChild == true
          //     ) {
          //       this.getTransactionView.push(element);
          //     } else {
          //       nonMatchList.push(element);
          //     }
          //   });
          //   this.getTransactionView.push(...nonMatchList);
          // }

          this.hasTransactionValue = this.getTransactionView.length == 0;
          this.getTotalCountExpressions();
        },
        (error) => {
          //console.log(error);
          this.isLoading = false;
        }
      );
    //this.isLoading = false;
  }

  getTotalCountExpressions() {
    this.isLoading = true;
    this.managetransation
      .GetAllocateViewDataCount(
        this.selectedTaxAccount!.TaxAccountCodeId,
        this.selectedLegalEntity!.ID,
        this.fiscalYearValue,
        this.isMatchTransaction,
        this.isMovedTransaction,
        this.isTaxChildTransaction
      )
      .subscribe(
        (response) => {
          this.totalRowPending = response;
          this.hasTransactionValue = this.getTransactionView.length == 0;
          let rows: number = 0;
          this.totalPagePending = 0;
          while (rows < this.totalRowPending) {
            rows = rows + 100;
          }
          this.totalPagePending = Math.floor(this.totalRowPending / 100) + 1;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  forwardPendingData(): void {
    if (this.totalRowPending == 0) {
      this.currentTransactionPendingPageNo = 1;
      return;
    }
    this.currentTransactionPendingPageNo =
      this.currentTransactionPendingPageNo + 1;
    this.startingRowPending = this.startingRowPending + 100;
    this.endingRowPending = this.endingRowPending + 100;
    if (this.startingRowPending >= this.totalRowPending) {
      this.startingRowPending = this.totalRowPending - 100;
      this.currentTransactionPendingPageNo =
        this.currentTransactionPendingPageNo - 1;
    }
    if (this.endingRowPending > this.totalRowPending) {
      this.endingRowPending = this.totalRowPending;
    }

    this.getTransactionViewData(this.startingRowPending, this.endingRowPending);
  }

  forwardPendingDataToLast(): void {
    if (this.totalRowPending == 0) {
      this.currentTransactionPendingPageNo = 1;
      return;
    }
    let rows: number = 0;
    this.currentTransactionPendingPageNo = 0;
    while (rows < this.totalRowPending) {
      this.currentTransactionPendingPageNo =
        this.currentTransactionPendingPageNo + 1;
      rows = rows + 500;
    }
    if (this.totalRowPending % 100 === 0)
      this.startingRowPending = this.totalRowPending - 100;
    else
      this.startingRowPending =
        this.totalRowPending - (this.totalRowPending % 100);
    this.endingRowPending = this.totalRowPending;
    if (this.startingRowPending < 100) this.currentTransactionPendingPageNo = 1;
    else
      this.currentTransactionPendingPageNo =
        Math.floor(this.startingRowPending / 100) + 1;
    this.getTransactionViewData(this.startingRowPending, this.endingRowPending);
  }

  backwardPendingData(): void {
    if (this.totalRowPending == 0) {
      this.currentTransactionPendingPageNo = 1;

      return;
    }
    this.currentTransactionPendingPageNo =
      this.currentTransactionPendingPageNo - 1;
    this.startingRowPending = this.startingRowPending - 100;
    if (this.startingRowPending < 0) {
      this.startingRowPending = 0;
      this.currentTransactionPendingPageNo = 1;
    }
    this.endingRowPending = this.endingRowPending - 100;
    if (this.endingRowPending < 500) {
      this.endingRowPending = 100;
      this.currentTransactionPendingPageNo = 1;
    }
    if (this.startingRowPending < 100) this.currentTransactionPendingPageNo = 1;
    else
      this.currentTransactionPendingPageNo = Math.floor(
        this.startingRowPending / 100 + 1
      );
    this.getTransactionViewData(this.startingRowPending, this.endingRowPending);
  }

  backwardPendingDataToFirst(): void {
    if (this.totalRowPending == 0) {
      this.currentTransactionPendingPageNo = 1;
      return;
    }
    this.currentTransactionPendingPageNo = 1;
    this.startingRowPending = 0;
    this.endingRowPending = 100;
    if (this.startingRowPending < 100) this.currentTransactionPendingPageNo = 1;
    else
      this.currentTransactionPendingPageNo = this.startingRowPending / 100 + 1;
    this.getTransactionViewData(this.startingRowPending, this.endingRowPending);
  }
  handleSortColumnChange(column: any) {
    this.selectedcolumnSort = column;
    if (column == undefined)
      this.selectedcolumnSort = {
        field: 'MatchID',
        title: 'Match id',
        type: 'text',
      };

    this.getTransactionViewData(this.startingRowPending, this.endingRowPending);
  }
  // public sortorderchange(event: any) {
  //   this.sortorderAsc = event.checkboxState;
  //   this.getTransactionViewData(this.startingRowPending, this.endingRowPending);
  // }
  downbuttonClick() {
    this.sortorderAsc = false;
    this.sortorder = 'desc';
    this.getTransactionViewData(this.startingRowPending, this.endingRowPending);
  }
  upbuttonClick() {
    this.sortorderAsc = true;
    this.sortorder = 'asc';
    this.getTransactionViewData(this.startingRowPending, this.endingRowPending);
  }
  handleBusinessTypeChange(businessType: BusinessType): void {
    this.selectedBusinessType = businessType;
    this.legalEntities = [];
    this.selectedLegalEntity = null;
    this.selectedTaxAccount = undefined;
    this.selectedGlAccount = undefined;
    this.taxAccounts = [];
    this.glTaxAccounts = [];
    if (businessType != undefined) {
      this.businessTypeId = businessType.BusinessTypeId;
      this.getLegalEntityList(businessType.BusinessTypeId);
    }
    if (((this.taxAccounts = []), (this.legalEntities = []))) {
      this.getTransactionView = [];
      this.totalRowPending = 0;
      this.totalPagePending = 0;
      this.currentTransactionPendingPageNo = 0;
    }
  }

  handleLegalEntityChange(legalEntitity: LegalEntity): void {
    this.selectedLegalEntity = legalEntitity;
    this.selectedTaxAccount = undefined;
    this.selectedGlAccount = undefined;
    this.taxAccounts = [];
    this.glTaxAccounts = [];
    if (legalEntitity != undefined) {
      this.getTaxAccounts(this.businessTypeId, this.selectedLegalEntity.ID);
    }
  }

  handleTaxEntityChange(legalEntitity: any): void {
    this.selectedTaxAccount = legalEntitity;
    if (this.selectedTaxAccount != undefined) {
      this.currentTransactionPendingPageNo = 1;
      this.getTransactionViewData(
        this.startingRowPending,
        this.endingRowPending
      );
      this.selecteddata = [];
    } else {
      this.selecteddata = [];
      this.getTransactionView = [];
      this.totalRowPending = 0;
      this.totalPagePending = 0;
      this.currentTransactionPendingPageNo = 0;
    }
  }

  handleDestinationTaxAccount(data: any) {}

  checkMatchTransaction(event: any) {
    this.isMatchTransaction = event.checkboxState;
    var mapListArray = this.getTransactionView;

    this.getTransactionView = [];
    //this.getTransactionViewData(this.startingRowPending, this.endingRowPending);
    this.backwardPendingDataToFirst();
  }

  checkMovedTransaction(event: any) {
    this.isMovedTransaction = event.checkboxState;
    var mapListArray = this.getTransactionView;
    this.getTransactionView = [];
    //this.getTransactionViewData(this.startingRowPending, this.endingRowPending);
    this.backwardPendingDataToFirst();
  }

  checkTaxChildTransaction(event: any) {
    this.isTaxChildTransaction = event.checkboxState;
    var mapListArray = this.getTransactionView;
    this.getTransactionView = [];
    //this.getTransactionViewData(this.startingRowPending, this.endingRowPending);
    this.backwardPendingDataToFirst();
  }

  complexCheckBox(event: any) {
    this.taxAccounts = [];
    this.selectedTaxAccount = null;
    if (event.checkboxState) {
      this.taxAccountsId.forEach((element: any) => {
        if (element.AccountClassId == 3) {
          this.taxAccounts.push(element);
        }
      });
    } else {
      this.taxAccounts = this.taxAccountsId;
    }
  }

  handleTaxAccountChange(taxAccount: TaxAccount): void {
    this.selectedGlAccount = undefined;
  }

  changeSelectAll() {
    this.isSelectAll = !this.isSelectAll;

    if (this.isSelectAll) {
      this.getTransactionView.map((adj: any, i: any) => {
        if (!this.selectAllUpdatePage(adj._id, this.selecteddata)) {
          this.getTransactionView[i].IsChecked = true;
          this.selecteddata.push(this.getTransactionView[i]);
        }
      });
    } else {
      for (let i = 0; i < this.getTransactionView.length; i++) {
        for (let j = 0; j < this.selecteddata.length; j++) {
          if (this.getTransactionView[i]._id === this.selecteddata[j]._id) {
            this.getTransactionView[i].IsChecked = false;
            this.selecteddata.splice(j, 1);
          }
        }
      }
      this.isSelectAll = false;
    }
  }
  selectAllUpdatePage(id: any, arr: any) {
    let filteredData = arr.filter((obj: any) => {
      return obj._id == id;
    });
    return filteredData.length > 0;
  }
  toggle(args: any) {
    var i = this.getTransactionView.findIndex(function (el: any) {
      return args._id == el._id;
    });
    if (this.getTransactionView[i].IsChecked) {
      this.selecteddata = this.selecteddata.filter(
        (obj: any) => obj._id != this.getTransactionView[i]._id
      );
      this.getTransactionView[i].IsChecked = false;
    } else {
      this.selecteddata.push(this.getTransactionView[i]);
      this.getTransactionView[i].IsChecked = true;
    }

    var isFalse = false;
    this.getTransactionView.map((adj: any, i: any) => {
      if (
        this.getTransactionView[i].IsChecked == false ||
        this.getTransactionView[i].IsChecked == null ||
        this.getTransactionView[i].IsChecked == undefined
      ) {
        isFalse = true;
      }
    });

    this.isSelectAll = !isFalse;
  }

  selectedTaxAccountTr: any;

  handleJournalPeriodChange(period: any) {
    this.isSelect = true;
    this.selectedTaxAccountTr = period;

    if (period.Description == '(None)') {
      this.isTransactionNew = false;
      this.selectedTaxAccountTr._id = 0;
    } else if (period.Description == '(New...)') {
      this.isTransactionNew = true;
    }
  }

  GetTransactionType() {
    this.managetransation.GetTransactionType().subscribe((res) => {
      this.transationData = JSON.parse(res);

      if (this.isSelect) {
        this.selectedTaxAccountTr =
          this.transationData[this.transationData.length - 1];
        this.saveAddTransaction();
      }
    });
  }

  GetAutoMatchColumnsData() {
    this.managetransation.GetAutoMatchColumns().subscribe((res) => {});
  }

  saveAddTransaction() {
    this.isLoading = true;
    var transactionIds: any[] = [];
    var isItemSelected = false;

    if (this.selectedTaxAccountTr == null) {
      this.isLoading = false;
      this.toastr.errorToastr(
        'You must select at least one editable transaction in order to assign a type',
        'Error'
      );
      return;
    }
    if (this.selecteddata != null) {
      this.selecteddata.map((adj: any, i: any) => {
        isItemSelected = true;

        transactionIds.push(this.selecteddata[i]);
      });
    } else return;

    if (!isItemSelected) {
      this.isLoading = false;
      this.toastr.errorToastr(
        'You must select at least one editable transaction in order to assign a type',
        'Error'
      );
      return;
    }

    var map = {
      taxAccountID: this.selectedTaxAccount!.TaxAccountCodeId,
      fiscalYear: this.fiscalYearValue,
      legalEntity: this.selectedLegalEntity!.ID,
      transactionTypeId: this.selectedTaxAccountTr._id,
    };

    this.managetransation
      .AssignTransactionType(map, transactionIds)
      .subscribe((res: any) => {
        this.AssignTransactionData = res;

        if (res.Status == true) {
          this.selecteddata = [];

          this.toastr.successToastr('Transaction type assigned successfully');
          this.getTransactionViewData(
            this.startingRowPending,
            this.endingRowPending
          );
          this.isTransactionNew = false;
          this.isLoading = false;
        } else if (res.Status == false) {
          this.toastr.errorToastr(res.Message);
        }
      });
  }

  AddTransaction() {
    var transactionIds: any[] = [];
    this.isLoading = true;
    this.isItemSelected = false;
    this.getTransactionView.map((adj: any, i: any) => {
      if (this.getTransactionView[i].IsChecked) {
        this.isItemSelected = true;
        transactionIds.push(this.getTransactionView[i]);
      }
    });

    if (this.isItemSelected == false) {
      this.toastr.errorToastr('Minimum one transaction is required', 'Error');
      return;
    }
    var map = {
      taxAccountID: this.selectedTaxAccount!.TaxAccountCodeId,
      description: this.addJournalForm.getRawValue().description,
    };

    this.managetransation
      .AddTransactionType(map, transactionIds)
      .subscribe((res: any) => {
        this.addTransctionData = res;
        if (this.addTransctionData.Status === true) {
          this.isSelect = true;
          this.GetTransactionType();
          this.isLoading = false;
          this.addJournalForm.getRawValue().description.value = '';
          this.addJournalForm.reset();
        } else if (res.Status == false) {
          this.toastr.errorToastr(res.Message);
        }

        this.getTransactionViewData(
          this.startingRowPending,
          this.endingRowPending
        );
      });
  }

  Match() {
    this.isLoading = true;
    var transactionIds: any[] = [];
    var isItemSelected = false;
    this.getTransactionView.map((adj: any, i: any) => {
      if (this.getTransactionView[i].IsChecked) {
        isItemSelected = true;
        transactionIds.push(this.getTransactionView[i]);
      }
    });

    var map = {
      taxAccountID: this.selectedTaxAccount!.TaxAccountCodeId,
      fiscalYear: this.fiscalYearValue,
    };

    this.managetransation
      .MatchTransactions(map, this.selecteddata)
      .subscribe((response: any) => {
        this.matchtransation = response;
        // console.log(response.Message,"Mes")
        if (response.Status == true) {
          this.selecteddata = [];
          this.toastr.successToastr(
            ' The selected transactions matched successfully'
          );
          this.getTransactionViewData(
            this.startingRowPending,
            this.endingRowPending
          );
          this.isLoading = false;
        } else if (response.Status == false) {
          this.isLoading = false;
          this.toastr.errorToastr(response.Message);
        }
      });
  }

  UnMatch() {
    this.isLoading = true;
    var transactionIds: any[] = [];
    var isItemSelected = false;
    this.getTransactionView.map((adj: any, i: any) => {
      if (this.getTransactionView[i].IsChecked) {
        isItemSelected = true;

        transactionIds.push(this.getTransactionView[i]);
      }
    });

    this.managetransation
      .UnMatchTransactions(transactionIds)
      .subscribe((response: any) => {
        this.matchtransation = response;
        if (response.Status == true) {
          this.selecteddata = [];
          this.toastr.successToastr(
            ' The selected transactions Unmatch successfully'
          );
          this.getTransactionViewData(
            this.startingRowPending,
            this.endingRowPending
          );
          this.isLoading = false;
        } else if (response.Status == false) {
          this.isLoading = false;
          this.toastr.errorToastr(response.Message);
        }
        this.hasTransactionValue = this.getTransactionView.length == 0;
      });
  }

  unMatchAll() {
    this.isLoading = true;
    var transactionIds: any[] = [];
    var isItemSelected = false;
    this.getTransactionView.map((adj: any, i: any) => {
      if (this.getTransactionView[i].IsChecked) {
        isItemSelected = true;
      }
    });

    var map = {
      taxAccountID: this.selectedTaxAccount!.TaxAccountCodeId,
      fiscalYear: this.fiscalYearValue,
      legalEntity: this.selectedLegalEntity!.ID,
    };

    this.managetransation
      .UnMatchAllTransactionsData(map, transactionIds)
      .subscribe((response: any) => {
        this.matchtransation = response;
        if (response == true) {
          this.selecteddata = [];
          this.toastr.successToastr(
            'The selected transactions have been unmatched successfully'
          );
          this.getTransactionViewData(
            this.startingRowPending,
            this.endingRowPending
          );
          this.isLoading = false;
        } else if (response == false) {
          this.isLoading = false;
          this.toastr.errorToastr(
            'Sorry, something went wrong with the Api',
            'Error'
          );
        }
      });
  }

  getGridSetting(): void {
    this.isLoading = true;
    this.gridColumns = [];
    this.gridSettings = [];
    this.OfflineReferralService.getGridSetting(
      'TransactionGridColumns'
    ).subscribe(
      (response) => {
        this.gridSettings = response;

        this.gridSettings.map((gr: any, i: any) => {
          if (gr.Selected == true) {
            this.gridColumn = {
              field: gr.DbColumnName,
              title: gr.DisplayName,
              type: 'text',
            };
            this.gridColumns.push(this.gridColumn);
          } else {
            this.hiddenColumns.push(gr.DisplayName);
          }
          this.columns.push(gr.DisplayName);
        });
        this.selectedcolumnSort = {
          field: 'MatchID',
          title: 'Match id',
          type: 'text',
        };

        // this.handleSortColumnChange(this.selectedcolumnSort);
        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }

  openWindow() {
    this.opened = true;
  }

  saveColumn() {
    this.saveChooseColumnSettings();
    this.openClose(false);
  }

  saveChooseColumnSettings() {
    this.isLoading = true;
    this.gridColumns = [];
    this.gridSettings.map((gr: any, i: any) => {
      if (this.hiddenColumns.includes(gr.DisplayName)) {
        gr.Selected = false;
      } else {
        gr.Selected = true;
        this.gridColumn = {
          field: gr.DbColumnName,
          title: gr.DisplayName,
          type: 'text',
        };
        this.gridColumns.push(this.gridColumn);
        this.selectedcolumnSort = {
          field: 'MatchID',
          title: 'Match id',
          type: 'text',
        };
      }
    });

    this.OfflineReferralService.PostGridColumnList(
      this.gridSettings,
      'TransactionGridColumns'
    ).subscribe(
      (response) => {
        if (response == 'true') {
          this.toastr.successToastr(
            'Selected grid columns saved successfully.',
            'Success'
          );
        } else {
          this.toastr.errorToastr(
            'Selected grid columns saved failed.',
            'Error'
          );
        }
        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }

  public hideColumn(columnName: string): void {
    const hiddenColumns = this.hiddenColumns;

    if (!this.isHidden(columnName)) {
      hiddenColumns.push(columnName);
    } else {
      hiddenColumns.splice(hiddenColumns.indexOf(columnName), 1);
    }
  }

  /** Code for automatch begins */

  public openPopup = false;

  public open(): void {
    this.openPopup = true;
  }
  gotoAutomatchDataLayout() {
    this.isviewAutomatch = true;
  }
  cancelClick() {
    this.openPopup = false;
  }
  close() {
    this.isviewAutomatch = false;
  }

  moveTransaction(
    taxAccountdata: any,
    businessTypedata: any,
    legaEntitydata: any,
    destinationTaxAccountdata: any
  ) {
    this.isLoading = true;
    var transactionIds: any[] = [];
    var isItemSelected = false;
    if (this.getTransactionView != null)
      this.getTransactionView.map((adj: any, i: any) => {
        if (this.getTransactionView[i].IsChecked) {
          isItemSelected = true;

          transactionIds.push(this.getTransactionView[i]);
        }
      });
    else return;

    this.formdata.push(
      businessTypedata,
      legaEntitydata,
      taxAccountdata,
      destinationTaxAccountdata,
      this.selecteddata
    );

    this.sharedService.setmoveFormData(this.formdata);

    if (transactionIds.length != 0) {
      this.isLoading = true;
      this.moveapiservice.validateMoveTransaction(this.selecteddata).subscribe(
        (response: any) => {
          this.result = response;

          if (
            response ==
            'You can only create movement journals for one GL account at a time.  Please select transactions from only one GL account.'
          ) {
            this.toastr.warningToastr(response, 'Warning!');
            this.isLoading = false;
          } else if (response == 'Success') {
            this.router.navigate(['/manageMovementJournal/manageTransaction']);
            this.isLoading = false;
          } else {
            this.toastr.warningToastr(response, 'Warning!');
          }
        },
        (error) => {
          // console.log(error);
          this.toastr.errorToastr(
            'Sorry something went wrong with the Api',
            'Error'
          );
          this.isLoading = false;
        }
      );
    } else if (this.selecteddata.length === 0) {
      this.toastr.warningToastr(
        'Please select atleast one trasaction from grid to move',
        'Warning!'
      );
    }
  }

  getAutoMatchColumns(): void {
    this.isLoading = true;
    this.automatchService.getautomatchcolumns().subscribe(
      (response) => {
        this.automatchcolumnsList = response.filter(
          (obj) => obj.MandatoryItem == false
        );
        this.automatchMappingList = response.filter((obj) => obj.Selected);
        this.defaultSelectedList = response.filter((obj) => obj.MandatoryItem);

        this.isLoading = false;
      },
      (error) => {
        //console.log(error);
        this.isLoading = false;
      }
    );
  }

  automatchforwardScope() {
    this.movetaxoutcomeData(
      'forward',
      this.mySelectedautomatchColumn,
      this.mySelectedautomatchColumnMapping,
      this.automatchcolumnsList,
      this.automatchMappingList,
      'ltrscope'
    );
  }
  automatchbackwardScope() {
    this.movetaxoutcomeData(
      'backward',
      this.mySelectedautomatchColumnMapping,
      this.mySelectedautomatchColumn,
      this.automatchMappingList,
      this.automatchcolumnsList,
      'rtlscope'
    );
  }
  automatchforwardScopeAll() {
    this.movetaxoutcomeDataAll(this.automatchcolumnsList);
  }
  automatchbackwardScopeAll() {
    this.movetaxoutcomeDatabackAll(this.automatchMappingList);
  }
  movetaxoutcomeData(
    action: any,
    selection: any,
    selectionSelected: any,
    grid: any[],
    gridSelected: any[],
    dir: any
  ) {
    let filterMandatoryItem = selection.filter(
      (obj: any) => obj.MandatoryItem == false
    );
    selection = filterMandatoryItem;

    for (let j = 0; j < selection.length; j++) {
      if (dir == 'ltrscope') {
        this.automatchMappingList = [
          ...this.automatchMappingList,
          selection[j],
        ];

        var index = grid
          .map((x) => x.PropertyName)
          .indexOf(selection[j].PropertyName);
        grid.splice(index, 1).slice(0);

        this.mySelectedautomatchColumn = [];
      } else if (dir == 'rtlscope') {
        this.automatchcolumnsList = [
          selection[j],
          ...this.automatchcolumnsList,
        ];
        var index = grid
          .map((x) => x.PropertyName)
          .indexOf(selection[j].PropertyName);
        grid.splice(index, 1).slice(0);
        this.mySelectedautomatchColumnMapping = [];
      }
    }
  }

  movetaxoutcomeDataAll(fromData: any[]) {
    let filterMandatoryItem = fromData.filter(
      (obj: any) => obj.MandatoryItem == false
    );
    this.automatchcolumnsList = fromData.filter(
      (obj: any) => obj.MandatoryItem == true
    );
    fromData = filterMandatoryItem;
    for (let j = 0; j < fromData.length; j++) {
      this.automatchMappingList = [...this.automatchMappingList, fromData[j]];
    }
  }

  movetaxoutcomeDatabackAll(fromData: any[]) {
    let filterMandatoryItem = fromData.filter(
      (obj: any) => obj.MandatoryItem == false
    );
    this.automatchMappingList = fromData.filter(
      (obj: any) => obj.MandatoryItem == true
    );
    fromData = filterMandatoryItem;

    for (let j = 0; j < fromData.length; j++) {
      this.automatchcolumnsList = [...this.automatchcolumnsList, fromData[j]];
    }
  }
  moveup() {
    if (
      this.mySelectedautomatchColumnMapping != null &&
      this.mySelectedautomatchColumnMapping != undefined
    ) {
      this.move(
        this.mySelectedautomatchColumnMapping,
        this.automatchMappingList,
        'up'
      );
    }
  }
  movedown() {
    if (
      this.mySelectedautomatchColumnMapping != null &&
      this.mySelectedautomatchColumnMapping != undefined
    ) {
      this.move(
        this.mySelectedautomatchColumnMapping,
        this.automatchMappingList,
        'down'
      );
    }
  }

  move(selection: any, grid: any[], dir: any) {
    let filterMandatoryItem = grid.filter(
      (obj: any) => obj.MandatoryItem == false
    );
    let stable = grid.filter((obj: any) => obj.MandatoryItem == true);
    grid = filterMandatoryItem;
    if (selection[0].MandatoryItem == false) {
      if (dir == 'down') {
        var currentIndex = grid.indexOf(selection[0]);
        var newIndex = currentIndex + 1;
        if (newIndex >= grid.length) {
          return;
        } else {
          var temp = grid[currentIndex];
          grid[currentIndex] = grid[newIndex];
          grid[newIndex] = temp;
        }
      } else if (dir == 'up') {
        var currentIndex = grid.indexOf(selection[0]);
        var newIndex = currentIndex - 1;

        if (newIndex < 0) {
          return;
        } else {
          var temp = grid[currentIndex];
          grid[currentIndex] = grid[newIndex];
          grid[newIndex] = temp;
        }
      }
    }

    this.automatchMappingList = [...stable, ...grid.slice()];
  }

  matchCheckBox(event: any) {
    this.matchCheckboxState = event.checkboxState;
  }

  excelCheckBox(event: any) {
    this.excelCheckboxState = event.checkboxState;
  }
  validateAutomatch() {
    this.isLoading = true;

    this.automatchService
      .validateAutomatch(
        this.selectedTaxAccount!.TaxAccountCodeId,
        this.fiscalYearValue
      )
      .subscribe(
        (response: any) => {
          if (response) {
            if (response.Status == true) {
              this.isLoading = false;
              this.open();
            } else if (
              response.Message ==
              'The transactions that you have selected to auto match have already been analysed.\r\n\r\nAuto matching can only be performed on transactions that have not yet been analysed.\r\n'
            ) {
              this.isLoading = false;
              this.toastr.warningToastr(response.Message, 'Warning!', {
                timeOut: 5000,
              });
            }
          }
        },
        (error: any) => {
          // console.log(error);
          this.isLoading = false;
        }
      );
  }
  processingfile() {
    this.isLoading = true;

    this.startPayload = {
      TaxAccountID: this.selectedTaxAccount!.TaxAccountCodeId,
      FiscalYear: this.fiscalYearValue,
      LegalEntity: this.selectedLegalEntity!.ID,
      AutoMatchRemainingTrans: this.matchCheckboxState,
      IsAutoMatchByPurchaseOrder: false,
      GenerateExcel: this.excelCheckboxState,
      AutoMatchColumns: this.automatchMappingList,
      TriggerBy: '',
      ClientDbName: this.selectedClient.DBName,
      OrchestrationId: '',
    };
    this.automatchService
      .autoMatchAllTransactionsProcessingStart(this.startPayload)
      .subscribe(
        (response: any) => {
          if (response) {
            this.orchestrationId = response.orchestrationId;
            this.getstatus();
          }
        },
        (error: any) => {
          // console.log(error);
          this.isLoading = false;
        }
      );
  }

  afterProcessingDetails: any;
  isProcessingSuccess: boolean = false;
  getstatus() {
    this.isLoading = true;
    this.automatchService
      .getAutoMatchAllTransactionsProcessingStatus(this.orchestrationId)
      .subscribe(
        (response: any) => {
          if (
            response.orchestrationRuntimeStatus == 'Completed' ||
            response.orchestrationRuntimeStatus == 'Failed'
          ) {
            this.isLoading = false;

            if (
              response.orchestrationRuntimeStatus == 'Completed' &&
              response.isExportProcessingCompletedWithSuccess &&
              response.autoMatchCount > 0 &&
              this.excelCheckboxState === true
            ) {
              this.isLoading = false;

              this.afterProcessingDetails = response;

              this.isProcessingSuccess = true;
              this.getExportTextFile();
            } else if (
              response.orchestrationRuntimeStatus == 'Completed' &&
              response.isExportProcessingCompletedWithSuccess &&
              response.autoMatchCount > 0 &&
              this.excelCheckboxState != true
            ) {
              this.isLoading = false;
              this.toastr.successToastr(
                'Data Auto matched successfully.',
                'Success'
              );
              this.isviewAutomatch = false;
              this.getTransactionViewData(
                this.startingRowPending,
                this.endingRowPending
              );
            } else if (
              response.orchestrationRuntimeStatus == 'Completed' &&
              response.isExportProcessingCompletedWithSuccess &&
              response.autoMatchCount === 0
            ) {
              this.isLoading = false;
              this.toastr.warningToastr(
                'No Transactions have been identified as having contras for the selected tax account.',
                'Warning'
              );
            } else if (
              response.orchestrationRuntimeStatus == 'Failed' ||
              !response.isExportProcessingCompletedWithSuccess
            ) {
              this.isLoading = false;

              this.toastr.errorToastr(
                response.exportProcessingErrorMessage,
                'Error'
              );
              this.isProcessingSuccess = false;
            }
          } else {
            setTimeout(() => {
              this.getstatus();
            }, 500);
          }
        },
        (error: any) => {
          // console.log(error);
          this.isLoading = false;
          this.toastr.errorToastr(
            'Sorry, something went wrong with the Api',
            'Error'
          );
          return error;
        }
      );
  }
  getExportTextFile() {
    this.isLoading = true;
    this.automatchService
      .downloadAutoMatchAllTransactionsExcelFile(
        this.afterProcessingDetails.generatedExportFileName
      )
      .subscribe(
        (response: any) => {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          const fileName = this.afterProcessingDetails.generatedExportFileName;
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
          this.isLoading = false;
          this.toastr.successToastr('Data exported successfully.', 'Success');
          this.isviewAutomatch = false;
          this.getTransactionViewData(
            this.startingRowPending,
            this.endingRowPending
          );
        },
        (error: any) => {
          this.isLoading = false;
          this.toastr.errorToastr(
            'Sorry, something went wrong with the Api',
            'Error'
          );
          // console.log(error);
        }
      );
  }

  public rowClass = (context: RowClassArgs) => {
    if (context.dataItem.MandatoryItem) {
      return { gold: true };
    } else {
      return { green: true };
    }
  };

  roundedPercentage(myValue: number) {
    var result = myValue;

    return result.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  validateMatchbyPO() {
    this.isLoading = true;

    this.automatchService
      .validateAutomatch(
        this.selectedTaxAccount!.TaxAccountCodeId,
        this.fiscalYearValue
      )
      .subscribe(
        (response: any) => {
          if (response) {
            if (response.Status == true) {
              this.isLoading = false;
              this.MatchbyPO();
            } else if (response.Status == false) {
              this.isLoading = false;
              this.toastr.warningToastr(response.Message, 'Warning!', {
                timeOut: 5000,
              });
            }
          }
        },
        (error: any) => {
          // console.log(error);
          this.isLoading = false;
        }
      );
  }
  MatchbyPO() {
    this.isLoading = true;

    this.startPayload = {
      TaxAccountID: this.selectedTaxAccount!.TaxAccountCodeId,
      FiscalYear: this.fiscalYearValue,
      LegalEntity: this.selectedLegalEntity!.ID,
      IsAutoMatchByPurchaseOrder: true,
      GenerateExcel: this.excelCheckboxState,
      TriggerBy: '',
      ClientDbName: this.selectedClient.DBName,
      OrchestrationId: '',
    };
    this.automatchService
      .autoMatchAllTransactionsProcessingStart(this.startPayload)
      .subscribe(
        (response: any) => {
          if (response) {
            this.orchestrationId = response.orchestrationId;
            this.getstatusPO();
          }
        },
        (error: any) => {
          // console.log(error);
          this.isLoading = false;
        }
      );
  }
  getstatusPO() {
    this.isLoading = true;
    this.automatchService
      .getAutoMatchAllTransactionsProcessingStatus(this.orchestrationId)
      .subscribe(
        (response: any) => {
          if (
            response.orchestrationRuntimeStatus == 'Completed' ||
            response.orchestrationRuntimeStatus == 'Failed'
          ) {
            this.isLoading = false;

            if (
              response.orchestrationRuntimeStatus == 'Completed' &&
              response.isExportProcessingCompletedWithSuccess &&
              response.autoMatchCount > 0
            ) {
              this.isLoading = false;

              this.afterProcessingDetails = response;

              this.isProcessingSuccess = true;
              this.getExportTextFile();
            } else if (
              response.orchestrationRuntimeStatus == 'Completed' &&
              response.isExportProcessingCompletedWithSuccess &&
              response.autoMatchCount === 0
            ) {
              this.isLoading = false;
              this.toastr.warningToastr(
                'No Transactions have been identified as having contras for the selected tax account.',
                'Warning'
              );
            } else if (
              response.orchestrationRuntimeStatus == 'Failed' ||
              !response.isExportProcessingCompletedWithSuccess
            ) {
              this.isLoading = false;

              this.toastr.errorToastr(
                response.exportProcessingErrorMessage,
                'Error'
              );
              this.isProcessingSuccess = false;
            }
          } else {
            setTimeout(() => {
              this.getstatusPO();
            }, 500);
          }
        },
        (error: any) => {
          // console.log(error);
          this.isLoading = false;
          this.toastr.errorToastr(
            'Sorry, something went wrong with the Api',
            'Error'
          );
          return error;
        }
      );
  }
}
