<div *ngIf="isLoading">
  <kendo-chunkprogressbar [chunkCount]="10" [min]="-10" [max]="10" [indeterminate]="true" [value]="30"
    style="width: 100%"></kendo-chunkprogressbar>
</div>
<!-- Header -->
<div class="header">Manage Attachments</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <div style="margin-bottom: -5px">
        <form class="k-form autoHeight" [formGroup]="manageAttachementForm">
          <kendo-tabstrip (tabClose)="onClose($event)" (tabSelect)="onSelect($event)">
            <kendo-tabstrip-tab title="Attachments" [selected]="true">
              <ng-template kendoTabContent>
                <div class="form-group" style="margin-bottom: 10px">
                  <kendo-combobox formControlName="businessType" [(ngModel)]="selectedBusinessType"
                    [data]="businessTypes" style="width: 100%" textField="Description" valueField="BusinessTypeId"
                    placeholder="Select business type" (valueChange)="handleBusinessTypeChange($event)">
                  </kendo-combobox>
                </div>
                <div class="form-group" style="margin-bottom: 15px">
                  <kendo-combobox formControlName="legalEntity" [data]="legalEntities" [(ngModel)]="selectedLegalEntity"
                    style="width: 100%" textField="Description" valueField="ID" placeholder="Select legal entity"
                    (valueChange)="handleLegalEntityChange($event)">
                  </kendo-combobox>
                </div>

                <div class="searchContainer">
                  <ap-search [placeholder]="'Search here'" [searchType]="'secondary'" style="
                      width: 100%;
                      margin-right: 24px;
                      padding: 0px !important;
                    " [disabled]="false" (onSearch)="onFilter($event)"></ap-search>
                  <ap-button [icon]="'delete-outline'" [compact]="true" [add]="true"
                    [disabled]="this.isAttachmentDelete" (onClick)="getUserRole()" kendoTooltip
                    title="Delete"></ap-button>
                  <ap-button style="margin-left: 5px" [icon]="'download-outline'" [compact]="true" [add]="true"
                    class="actionButton" [disabled]="this.isAttachmentDelete" (onClick)="ExportAttachementData()"
                    kendoTooltip title="Download Multiple Files"></ap-button>
                </div>
                <kendo-grid [data]="attachementDetails" scrollable="virtual" [sortable]="true" [sort]="sort"
                  (sortChange)="sortChangeAttachment($event)" class="autoHeightattach" [selectable]="true"
                  (selectionChange)="onGridSelectionChange($event)" [reorderable]="true" >
                  <kendo-grid-checkbox-column [showSelectAll]="true" [width]="45"></kendo-grid-checkbox-column>
                  <kendo-grid-column [headerStyle]="{
                      'font-weight': 'bold',
                      color: '#d04a02',
                      'text-align': 'left'
                    }" field="IsActive" title="Action" [width]="70">
                    <ng-template kendoGridCellTemplate let-data>
                      <button kendoButton kendoTooltip title="Download" class="actionButton" kendoTooltip
                        title="Export to excel" (click)="exportSingleFile(data)">
                        <span class="Appkit4-icon icon-download-outline"></span>
                      </button>
                      <!-- &nbsp;
                          <button kendoButton kendoTooltip title="Delete" (click)="deleteButtonClick(data)"><span class="Appkit4-icon icon-delete-outline"></span></button> -->
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="StatusDescription" title="Status" [width]="150" [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"></kendo-grid-column>
                  <kendo-grid-column field="FileExtension" title="File Extensions" [width]="130" [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"></kendo-grid-column>
                  <kendo-grid-column field="Statement" title="Statement" [width]="300" [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"></kendo-grid-column>
                  <kendo-grid-column field="TaxAccount" title="Tax Account" [width]="250" [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"></kendo-grid-column>
                  <!-- <kendo-grid-column
                            field="FiscalYear"
                            title="Fiscal Year"
                            [width]="100"
                            [style]="{'text-align': 'right'}"
                            [headerStyle]="{
                              'font-weight': 'bold',
                              'text-align': 'right',
                              color: '#d04a02'
                            }"
                          >
                          </kendo-grid-column> -->
                  <kendo-grid-column field="Attachement" title="Attachment" [width]="270" [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"></kendo-grid-column>
                  <kendo-grid-column title="Supplementary" [width]="150" [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <input type="checkbox" kendoCheckBox [checked]="dataItem.Supplementator" />
                    </ng-template></kendo-grid-column>
                  <kendo-grid-column field="ActionTakenBy" title="Last Updated By" [width]="200" [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"></kendo-grid-column>
                </kendo-grid>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="Additional Files">
              <ng-template kendoTabContent>
                <div class="searchContainer">
                  <ap-search [placeholder]="'Search here'" [searchType]="'secondary'" style="
                      width: 100%;
                      margin-right: 2px;
                      padding: 0px !important;
                    " [disabled]="false" (onSearch)="onFilter($event)"></ap-search>
                  <ap-button [icon]="'delete-outline'" [compact]="true" [add]="true"
                    [disabled]="this.isAdditionalDelete" (onClick)="getUserRole()" kendoTooltip
                    title="Delete"></ap-button>
                  <ap-button style="margin-left: 5px" [icon]="'download-outline'" [compact]="true" [add]="true"
                    [disabled]="this.isAdditionalDelete" (onClick)="ExportAdditonalFile()" kendoTooltip
                    title="Download Multiple Files"></ap-button>
                </div>
                <kendo-grid [data]="additionalFileDetails" scrollable="virtual" [sortable]="true" [sort]="sort"
                  (sortChange)="sortChangeAdditional($event)" class="autoHeightadditional" [selectable]="true"
                  (selectionChange)="onAdditonalGridSelectionChange($event)" [reorderable]="true" >
                  <kendo-grid-checkbox-column [showSelectAll]="true" [width]="45"></kendo-grid-checkbox-column>
                  <kendo-grid-column [headerStyle]="{
                      'font-weight': 'bold',
                      color: '#d04a02',
                      'text-align': 'left'
                    }" field="IsActive" title="Action" [width]="70">
                    <ng-template kendoGridCellTemplate let-data>
                      <button kendoButton kendoTooltip title="Download" class="actionButton" kendoTooltip
                        title="Export to excel" (click)="exportAdditionalSingleFile(data)">
                        <span class="Appkit4-icon icon-download-outline"></span>
                      </button>
                      <!-- &nbsp;
                        <button kendoButton kendoTooltip title="Delete" (click)="deleteButtonClick(data)"><span class="Appkit4-icon icon-delete-outline"></span></button> -->
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="StatusDescription" title="Status" [width]="150" [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"></kendo-grid-column>
                  <kendo-grid-column field="Name" title="Name" [width]="230" [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"></kendo-grid-column>
                  <kendo-grid-column field="FileExtension" title="File Extension" [width]="150" [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"></kendo-grid-column>
                  <kendo-grid-column field="Description" title="Description" [width]="250" [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"></kendo-grid-column>
                  <kendo-grid-column field="UploadDate" title="Upload Time" [width]="150"
                    [style]="{ 'text-align': 'right' }" [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'right',
                      color: '#d04a02'
                    }">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      {{ dataItem.UploadDate | date : "MM/dd/yyyy" }}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="Version" title="Version" [width]="100" [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"></kendo-grid-column>
                  <kendo-grid-column field="ActionTakenBy" title="Last Updated By" [width]="200" [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"></kendo-grid-column>
                </kendo-grid>
                <!-- <div class="clickToActions" style="padding-top: 15px">

                  <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl"
                    (upload)="uploadEventHandler($event)" [withCredentials]="withCredential" [multiple]="true"
                    (success)="success($event)" [restrictions]="AdditionalFileRestrictions" (error)="error($event)">
                    <kendo-fileselect-messages dropFilesHere="Drop your file here" select="Select file"
                      [fileStatusUploaded]="FileStatus" [fileStatusFailed]="ErrorFileStatus">
                    </kendo-fileselect-messages>
                  </kendo-upload>
                </div> -->
                <div style="display: flex;justify-content: right;padding-top: 15px" id="attachementFileUpload">
                  <!-- <div class="gridHeader" style="padding-top: 11px;">Please select file to be uploaded</div> -->
                  <div style="
                  margin-left: 3%;">
                    <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl"
                      (upload)="uploadEventHandler($event)" [withCredentials]="withCredential" [multiple]="true"
                      (success)="success($event)" [restrictions]="AdditionalFileRestrictions" (error)="error($event)">
                      <kendo-fileselect-messages dropFilesHere="Drop your file here" select="Select file"
                        [fileStatusUploaded]="FileStatus" [fileStatusFailed]="ErrorFileStatus">
                      </kendo-fileselect-messages>
                    </kendo-upload>
                  </div>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </form>
      </div>
    </div>
  </div>
</div>
<kendo-dialog title="Delete {{ this.DeleteTab }}" *ngIf="warnondelete" (close)="cancelClick()" [minWidth]="250"
  [width]="500" [height]="211">
  <div style="display: flex">
    <div class="gridHeader">
      Please select from one of the following options
    </div>
  </div>
  <br />
  <ul class="k-radio-list k-list-horizontal">
    <li class="k-radio-item">
      <input type="radio" name="deleteradio" value="Soft Delete" #softDelete [(ngModel)]="deleteType" kendoRadioButton
        (ngModelChange)="selectDeleteType($event)" checked />
      <kendo-label [for]="softDelete" text="Archive"></kendo-label>
    </li>
    <li class="k-radio-item">
      <input type="radio" name="deleteradio" value="Pemanent Delete" #confirmDelete [(ngModel)]="deleteType"
        kendoRadioButton (ngModelChange)="selectDeleteType($event)" />
      <kendo-label [for]="confirmDelete" text="Delete"></kendo-label>
    </li>
  </ul>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 col-xs-12">
        <div class="clickToActions" style="padding: 10px 0px">
          <ap-button [btnType]="'secondary'" [label]="'Cancel'" (click)="cancelClick()"></ap-button>
          <ap-button [btnType]="'primary'" [label]="'Save'" themeColor="primary" (click)="deleteData()"
            [disabled]="!manageAttachementForm.valid" style="margin-right: 6px"></ap-button>
        </div>
      </div>
    </div>
  </div>
</kendo-dialog>