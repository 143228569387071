import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { TaxaccountserviceService } from 'src/app/common/services/taxaccounts/taxaccountservice.service';
import { LineitemsComponent } from 'src/app/lineitems/lineitems.component';
import { TaxaccountsgridpopupComponent } from '../taxaccountsgridpopup/taxaccountsgridpopup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-newtaxaccount',
  templateUrl: './newtaxaccount.component.html',
  styleUrls: ['./newtaxaccount.component.scss'],
})
export class NewtaxaccountComponent implements OnInit {
  @Input() public gridBusinessType: any;
  @Input() public editTaxAccountbyCode: any;
  @Input() public isCreateFromLI: any;
  @Input() public LICreateTaxData: any;
  public selectedParentData: any | undefined;
  public actionsLayout: ActionsLayout = 'normal';
  taxAccountForm: FormGroup;
  isEdit: boolean = false;
  parentTaxAccLabel: string = 'Parent tax account';
  editDetails: any;
  parentTaxAccountList: any[] = [];
  AccountClassDescriptionList: any;
  lineItemList: any;
  STList: any;
  STListDB: any;
  TaxOutcomeList: any;
  TaxOutcomeListDB: any;
  dropdownIndicator: any[] = [
    { label: 'B', value: 'B' },
    { label: 'P', value: 'P' },
  ];
  AccountClassDescriptionListDB: any;
  lineItemListDB: any;
  parentTaxAccountListDB: any;
  pathId: any;
  changeDescription: any;
  taxAccountCodeId: any;
  constructor(
    // public dialog: DialogRef,
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private dialogService: DialogService,
    private globalService: GlobalService,
    private taxAccountService: TaxaccountserviceService,
    private route: ActivatedRoute
  ) {
    // super(dialog);
    this.taxAccountForm = this.fb.group({
      taxAccount: [''],
      shortDesc: ['', Validators.required],
      longDesc: [''],
      indicator: [{ label: 'P', value: 'P' }],
      parentTaxAccount: [''],
      accountClass: [''],
      lineItem: [''],
      showAll: [false],
      statutoryTreatment: [''],
      taxOutcome: [''],
      highlighted: [false],
      taxSpecific: [false],
    });
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.pathId = params.id;
      this.taxAccountCodeId = params.value;
    });
    if (history.state.gridBusinessType) {
      this.gridBusinessType = history.state.gridBusinessType;
      this.pathId = history.state.gridBusinessType.BusinessTypeId;
    }
    this.globalService.getBusinessTypeById(this.pathId).then((res) => {
      this.gridBusinessType = res;
      this.changeDescription = this.gridBusinessType.Description;
      if (this.taxAccountCodeId) {
        this.getDetailsByCode();
      } else if (this.isCreateFromLI) {
        this.createTaxPopulateData();
        this.getRefData();
        this.getLineItemlist();
      } else {
        this.getRefData();
        this.getLineItemlist();
      }
    });
    // this.gridBusinessType = history.state.gridBusinessType;
    this.isCreateFromLI = history.state.isCreateFromLI;
    this.LICreateTaxData = history.state.LICreateTaxData;
    // this.editTaxAccountbyCode = history.state.editTaxAccountbyCode;
  }
  createTaxPopulateData() {
    this.taxAccountForm.controls['taxAccount'].setValue(
      this.LICreateTaxData.ID
    );
    this.taxAccountForm.controls['shortDesc'].setValue(
      this.LICreateTaxData.Description
    );
    this.taxAccountForm.controls['longDesc'].setValue(
      this.LICreateTaxData.Description
    );
    this.taxAccountForm.controls['lineItem'].setValue(this.LICreateTaxData.ID);
    this.taxAccountForm.controls['accountClass'].setValue(
      this.LICreateTaxData.AccountClass
    );
    this.taxAccountForm.controls['accountClass'].disable();
    if (this.LICreateTaxData.AccountClass == 3)
      this.taxAccountForm.controls['parentTaxAccount'].disable();
    if (this.LICreateTaxData.AccountingArea) {
      this.taxAccountForm.controls['parentTaxAccount'].setValidators([
        Validators.required,
      ]);
      this.taxAccountForm.controls['parentTaxAccount'].markAsTouched();
      this.taxAccountForm.controls['parentTaxAccount'].setErrors({
        incorrect: true,
      });
      this.parentTaxAccLabel = 'Parent tax account*';
    }
  }
  getDetailsByCode() {
    this.isEdit = true;
    this.taxAccountService
      .getTaxAccountDetailsByCode(this.taxAccountCodeId)
      .then((res) => {
        this.editDetails = res;
        this.getRefData();
        this.getLineItemlist();
        this.taxAccountForm.controls['taxAccount'].setValue(
          this.editDetails.TaxAccountCode
        );
        this.taxAccountForm.controls['shortDesc'].setValue(
          this.editDetails.ShortDescription
        );
        this.taxAccountForm.controls['longDesc'].setValue(
          this.editDetails.LongDescription
        );
        this.taxAccountForm.controls['indicator'].setValue(
          this.editDetails.BalanceSheetPLIndicator
        );
        this.taxAccountForm.controls['parentTaxAccount'].setValue(
          this.editDetails.fk_ParentTaxAccountCode
        );
        this.taxAccountForm.controls['accountClass'].setValue(
          this.editDetails.AccountClassId
        );
        this.taxAccountForm.controls['lineItem'].setValue(
          this.editDetails.AccountingAreaId
        );
        this.taxAccountForm.controls['showAll'].setValue(
          this.editDetails.ShowAll
        );
        this.taxAccountForm.controls['statutoryTreatment'].setValue(
          this.editDetails.AccountTypeId
        );
        this.taxAccountForm.controls['taxOutcome'].setValue(
          this.editDetails.TaxOutcomeId
        );
        this.taxAccountForm.controls['highlighted'].setValue(
          this.editDetails.Highlighted
        );
        this.taxAccountForm.controls['taxSpecific'].setValue(
          this.editDetails.TaxSpecific
        );
        if (this.editDetails.fk_ParentTaxAccountCode != null) {
          this.taxAccountForm.controls['accountClass'].disable();
        }
      })
      .catch((err) => {});
  }
  getRefData() {
    this.globalService
      .getAllAccountClass()
      .then((res) => {
        this.AccountClassDescriptionListDB = res.slice();
        this.AccountClassDescriptionList = this.AccountClassDescriptionListDB;
      })
      .catch((err) => {});

    let ParentDatapayload = {
      AccountingAreaId: 0,
      AccountClassId: 0,
      TaxChartOfAccountId: this.gridBusinessType.BusinessTypeId,
      TaxAccountCodeId: 0,
      ShowAll: false,
      AccountClass: 'string',
      ParentAccountCodeId: 0,
    };
    if (this.isEdit) {
      ParentDatapayload.TaxAccountCodeId = this.editDetails.TaxAccountCodeId;
      ParentDatapayload.AccountingAreaId = this.editDetails.AccountingAreaId;
    }
    this.globalService
      .getTaxAccountParentData(ParentDatapayload)
      .then((res: any) => {
        this.parentTaxAccountListDB = JSON.parse(res).slice();
        this.parentTaxAccountList = this.parentTaxAccountListDB;
        if (this.parentTaxAccountList.length == 1)
          this.selectedParentData = res[0];
        //this.taxAccountForm.controls['parentTaxAccount'].setValue(res[0].TaxAccountCodeId);
        if (this.isEdit)
          this.taxAccountForm.controls['parentTaxAccount'].setValue(
            this.editDetails.fk_ParentTaxAccountCode
          );
      })
      .catch((err) => {});
    if (this.isCreateFromLI) this.handleLineItemChange(this.LICreateTaxData);
    this.globalService
      .getAllTaxOutcomes()
      .then((res: any) => {
        this.TaxOutcomeListDB = res.slice();
        this.TaxOutcomeList = this.TaxOutcomeListDB;
      })
      .catch((err) => {});
    this.globalService
      .getAllAccountTypes()
      .then((res: any) => {
        this.STListDB = res.slice();
        this.STList = this.STListDB;
      })
      .catch((err) => {});
  }
  getLineItemlist() {
    let TAlineItempayload = {
      AccountingAreaId: 0,
      AccountClassId: 0,
      TaxChartOfAccountId: this.gridBusinessType.BusinessTypeId,
      TaxAccountCodeId: 0,
      ShowAll: this.taxAccountForm.getRawValue().showAll,
      AccountClass: 'string',
      ParentAccountCodeId: 0,
    };
    if (this.isEdit) {
      TAlineItempayload.TaxAccountCodeId = this.editDetails.TaxAccountCodeId;
      TAlineItempayload.AccountClassId = this.editDetails.AccountClassId;
      TAlineItempayload.AccountingAreaId = this.editDetails.AccountingAreaId;
    }
    if (this.isCreateFromLI) {
      TAlineItempayload.AccountClassId = this.LICreateTaxData.AccountClass.ID;
    }
    if (this.taxAccountForm.getRawValue().accountClass !== '') {
      if (this.taxAccountForm.getRawValue().accountClass.ID) {
        TAlineItempayload.AccountClassId =
          this.taxAccountForm.getRawValue().accountClass.ID;
      } else
        TAlineItempayload.AccountClassId =
          this.taxAccountForm.getRawValue().accountClass;
    }
    this.globalService
      .getTaxaccountLineitem(TAlineItempayload)
      .then((res: any) => {
        this.lineItemListDB = JSON.parse(res).slice();
        this.lineItemList = this.lineItemListDB;
      })
      .catch((err) => {});
  }
  handleFilterParentTaxaccount(value: any) {
    this.parentTaxAccountList = this.parentTaxAccountListDB.filter(
      (s: any) =>
        s.shortdescription.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  handleFilterAccountClass(value: any) {
    this.AccountClassDescriptionList =
      this.AccountClassDescriptionListDB.filter(
        (s: any) =>
          s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
  }
  handleFilterLineItem(value: any) {
    this.lineItemList = this.lineItemListDB.filter(
      (s: any) =>
        s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  handleFilterSatuatory(value: any) {
    this.STList = this.STListDB.filter(
      (s: any) =>
        s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  handleFilterTaxoutcome(value: any) {
    this.TaxOutcomeList = this.TaxOutcomeListDB.filter(
      (s: any) =>
        s.ShortDescription.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  handleAccountClassChange(value: any) {
    let formValue = this.taxAccountForm.getRawValue();

    let selectedAccountClassValue = value;
    if (selectedAccountClassValue !== undefined) {
      this.taxAccountForm.controls['lineItem'].setValue('');
      let ACpayload = {
        AccountingAreaId: 0,
        AccountClassId: selectedAccountClassValue.ID,
        TaxChartOfAccountId: this.gridBusinessType.BusinessTypeId,
        TaxAccountCodeId: 0,
        ShowAll: this.taxAccountForm.getRawValue().showAll,
        AccountClass: 'string',
        ParentAccountCodeId: 0,
      };
      this.taxAccountService
        .AccountClassChange(ACpayload)
        .then((res: any) => {
          this.lineItemListDB = res.slice();
          this.lineItemList = this.lineItemListDB;
        })
        .catch((err) => {});

      this.taxAccountService
        .LineItemChange(ACpayload)
        .then((res: any) => {
          this.parentTaxAccountListDB = res.slice();
          this.parentTaxAccountList = this.parentTaxAccountListDB;
          this.selectedParentData = undefined;
          if (res.length == 1) this.selectedParentData = res[0];
          //this.taxAccountForm.controls['parentTaxAccount'].setValue(res[0].TaxAccountCodeId);
        })
        .catch((err) => {});
    }
  }

  handleParentTaxAccountChange(value: any) {
    this.selectedParentData = value;
    let selectedParentTax = value;
    if (selectedParentTax !== '' && selectedParentTax !== null) {
      this.taxAccountForm.controls['accountClass'].setValue(1);
      this.taxAccountForm.controls['accountClass'].disable();
      let parentCodeId;
      if (selectedParentTax == undefined) {
        parentCodeId = 0;
        this.taxAccountForm.controls['accountClass'].enable();
      } else parentCodeId = selectedParentTax.TaxAccountCodeId;
      let ParentDatapayload = {
        AccountingAreaId: 0,
        AccountClassId: 0,
        TaxChartOfAccountId: this.gridBusinessType.BusinessTypeId,
        TaxAccountCodeId: 0,
        ShowAll: this.taxAccountForm.getRawValue().showAll,
        AccountClass: 'string',
        ParentAccountCodeId: parentCodeId,
      };
      this.taxAccountService
        .TaxAccountParentChange(ParentDatapayload)
        .then((res: any) => {
          this.lineItemListDB = res.Item2.slice();
          this.lineItemList = this.lineItemListDB;
        })
        .catch((err) => {});
    } else {
      this.taxAccountForm.controls['accountClass'].enable();
    }
  }
  handleLineItemChange(value: any) {
    let selectedLI = value;
    let formValue = this.taxAccountForm.getRawValue();

    if (selectedLI !== '' && selectedLI !== null && selectedLI !== undefined) {
      this.taxAccountForm.controls['accountClass'].setValue(
        selectedLI.AccountClass
      );
      let accValue, accArea;
      if (selectedLI.AccountingArea) {
        this.taxAccountForm.controls['parentTaxAccount'].setValidators([
          Validators.required,
        ]);
        this.taxAccountForm.controls['parentTaxAccount'].markAsTouched();
        this.taxAccountForm.controls['parentTaxAccount'].setErrors({
          incorrect: true,
        });
        this.parentTaxAccLabel = 'Parent tax account*';
        accArea = selectedLI.AccountingAreaID;
      } else {
        this.taxAccountForm.controls['parentTaxAccount'].setValidators(null);
        this.taxAccountForm.controls['parentTaxAccount'].setErrors(null);
        this.parentTaxAccLabel = 'Parent tax account';
        accArea = selectedLI.AccountingAreaID;
      }
      let LIpayload = {
        AccountingAreaId: accArea,
        AccountClassId: selectedLI.AccountClass,
        TaxChartOfAccountId: this.gridBusinessType.BusinessTypeId,
        TaxAccountCodeId: 0,
        ShowAll: this.taxAccountForm.getRawValue().showAll,
        AccountClass: 'string',
        ParentAccountCodeId: 0,
      };
      // if(formValue.accountClass)
      // LIpayload.AccountClassId=1;

      this.taxAccountService
        .LineItemChange(LIpayload)
        .then((res: any) => {
          this.parentTaxAccountListDB = res.slice();
          this.parentTaxAccountList = this.parentTaxAccountListDB;
          this.selectedParentData = undefined;
          if (res.length == 1) this.selectedParentData = res[0];
          //this.taxAccountForm.controls['parentTaxAccount'].setValue(res[0].TaxAccountCodeId);
        })
        .catch((err) => {});
    }
  }
  handleSTChange(value: any) {}
  handleTOChange(value: any) {}
  onShowAllChange(value: any) {
    this.getLineItemlist();
  }
  cancelClick() {
    if (this.isCreateFromLI) {
      this.displayLIgrid();
    } else {
      this.displayGrid();
    }
  }

  saveNewTaxAccount() {
    var formObject = this.taxAccountForm.getRawValue();

    let accountClassId;
    if (formObject.accountClass.ID) {
      accountClassId = formObject.accountClass.ID;
    } else {
      accountClassId = formObject.accountClass;
    }
    let parentTaxAccountCodeId = null;

    if (this.isEdit) {
      let indicatorVal, lineItemVal, taxOutcomeVal, statutoryTreatmentVal;
      if (formObject.parentTaxAccount) {
        if (formObject.parentTaxAccount.TaxAccountCodeId)
          parentTaxAccountCodeId = formObject.parentTaxAccount.TaxAccountCodeId;
        else {
          parentTaxAccountCodeId = formObject.parentTaxAccount;
        }
      }
      if (formObject.indicator.value) {
        indicatorVal = formObject.indicator.value;
      } else {
        indicatorVal = formObject.indicator;
      }
      if (formObject.lineItem.ID) {
        lineItemVal = formObject.lineItem.ID;
      } else {
        lineItemVal = formObject.lineItem;
      }
      if (formObject.taxOutcome) {
        if (formObject.taxOutcome._id)
          taxOutcomeVal = formObject.taxOutcome._id;
        else taxOutcomeVal = formObject.taxOutcome;
      }
      if (formObject.statutoryTreatment) {
        if (formObject.statutoryTreatment.Id)
          statutoryTreatmentVal = formObject.statutoryTreatment.Id;
        else statutoryTreatmentVal = formObject.statutoryTreatment;
      }
      let TAobject = {
        TaxAccountCodeId: this.taxAccountCodeId,
        TaxChartOfAccountId: this.gridBusinessType.BusinessTypeId,
        TaxAccountCode: formObject.taxAccount,
        ShortDescription: formObject.shortDesc,
        LongDescription: formObject.longDesc,
        EntryTypeAuto: false,
        BalanceSheetPLIndicator: indicatorVal,
        Highlighted: formObject.highlighted,
        TaxSpecific: formObject.taxSpecific,
        AllocationManual: false,
        AllocationRule: false,
        AllocationSuggestion: false,
        AllocationTemp: false,
        SourceBased: false,
        SummaryLevel: false,
        SummaryComparative: false,
        SummaryVariance: false,
        SummaryRelative: false,
        fk_AnalysisTypeTargetTaxAccountCode: null,
        GroupLevel: false,
        AccountingAreaId: lineItemVal,
        TaxOutcomeId: taxOutcomeVal,
        AccountTypeId: statutoryTreatmentVal,
        AccountClassId: accountClassId,
        DataSourceId: 1,
        fk_ParentTaxAccountCode: parentTaxAccountCodeId,
        HelpId: null,
        EntryTypeAutoExplanation: null,
        fk_BaselineTaxAccount: null,
      };
      this.taxAccountService
        .updateTaxAccountCode(TAobject)
        .then((res) => {
          if (res) {
            this.toastr.successToastr(this.capitalize(res), 'Success');
            this.displayGrid();
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
            this.displayGrid();
          }
        })
        .catch((err) => {});
    } else {
      if (formObject.parentTaxAccount) {
        parentTaxAccountCodeId = formObject.parentTaxAccount.TaxAccountCodeId;
      }
      let LineItemValue;
      if (formObject.lineItem.ID) {
        LineItemValue = formObject.lineItem.ID;
      } else {
        LineItemValue = formObject.lineItem;
      }
      let TAobject = {
        TaxAccountCodeId: 0,
        TaxChartOfAccountId: this.gridBusinessType.BusinessTypeId,
        TaxAccountCode: formObject.taxAccount,
        ShortDescription: formObject.shortDesc,
        LongDescription: formObject.longDesc,
        EntryTypeAuto: false,
        BalanceSheetPLIndicator: formObject.indicator.value,
        Highlighted: formObject.highlighted,
        TaxSpecific: formObject.taxSpecific,
        AllocationManual: false,
        AllocationRule: false,
        AllocationSuggestion: false,
        AllocationTemp: false,
        SourceBased: false,
        SummaryLevel: false,
        SummaryComparative: false,
        SummaryVariance: false,
        SummaryRelative: false,
        fk_AnalysisTypeTargetTaxAccountCode: null,
        GroupLevel: false,
        AccountingAreaId: LineItemValue,
        TaxOutcomeId: formObject.taxOutcome._id,
        AccountTypeId: formObject.statutoryTreatment.Id,
        AccountClassId: accountClassId,
        DataSourceId: 1,
        fk_ParentTaxAccountCode: parentTaxAccountCodeId,
        HelpId: null,
        EntryTypeAutoExplanation: null,
        fk_BaselineTaxAccount: null,
      };
      if (this.isCreateFromLI) {
        TAobject.TaxAccountCode = formObject.taxAccount.toString();
      }

      this.globalService
        .insertTaxAccountCode(TAobject)
        .then((res) => {
          if (res) {
            this.toastr.successToastr(this.capitalize(res), 'Success');
            if (this.isCreateFromLI) {
              this.displayLIgrid();
            } else {
              this.displayGrid();
            }
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
            this.displayGrid();
          }
        })
        .catch((err) => {});
    }
  }

  onHighlightClick(data: any) {}
  onTaxSpecificClick(data: any) {}
  displayGrid() {
    this.router.navigate(['/taxAccounts'], {
      state: {
        isSeletedBT: true,
        businessTypeValue: this.gridBusinessType,
      },
    });
  }
  displayLIgrid() {
    this.router.navigate(['/lineItems'], {
      state: {
        BusinessType: this.gridBusinessType,
      },
    });
  }
  public capitalize(text: any) {
    return text[0].toUpperCase() + text.substr(1).toLowerCase();
  }
}
