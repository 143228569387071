<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Delete Expression</div>
</kendo-dialog-titlebar>
<div style="display: flex">
  <p>
    <span
      class="Appkit4-icon icon-circle-warning-outline"
      style="font-size: 24px; color: #d04a02"
    ></span>
  </p>
  <p style="margin-left: 10px; font-size: medium">
    Are you sure want to delete
    <b> {{ delExpressiondata.RuleDescription }} </b> Expression?
  </p>
</div>
<!-- Click to Actions -->
<div class="clickToActions" style="padding: 10px 0px 0px 0px">
  <ap-button
    [btnType]="'secondary'"
    [label]="'No'"
    class="cancelButton"
    style="width: 100px;"
    (click)="cancelClick()"
  ></ap-button>
  <ap-button
    [btnType]="'primary'"
    [label]="'Yes'"
    themeColor="primary"
    class="okButton"
    (click)="deleteExpression()"
    style="margin-right: 6px"
  ></ap-button>
</div>
