<!-- Header -->
<div class="header">Manage habitats</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <kendo-textbox
        placeholder="Habitat:"
        [style.width]="'100%'"
        [(ngModel)]="habitat"
      ></kendo-textbox>
      <div
        class="containerPadding containerMarginTop bgcolorwhite borderlightgray"
      >
        <ap-tabset [type]="'filled'" [(activeIndex)]="activeIndex">
          <ap-tab
            *ngFor="let tab of tabs; let i = index"
            [label]="tab.label"
            [disabled]="tab.disabled"
          >
            <div *ngIf="i == 0">
              <span style="font-size: 14px"
                >Choose the account you would like to include in this habitat.
                If you add a complex account, all the related child accounts
                will be automatically added.</span
              >
              <div class="containerPadding dropdownContainer">
                <span>Display available tax accounts for:</span>
                <div class="flexcontainer containerMarginTop">
                  <kendo-combobox
                    [style.width]="'70%'"
                    [data]="businesstypeList"
                    [filterable]="true"
                    textField="Description"
                    [placeholder]="'Business type:'"
                    valueField="BusinessTypeId"
                    [(ngModel)]="selectedItem"
                    (valueChange)="handleBTchange($event)"
                    (filterChange)="handleFilterBusinessTypeId($event)"
                  ></kendo-combobox>

                  <ap-checkbox
                    [(ngModel)]="checkboxSelected"
                    [disabled]="checkboxDisabled"
                    [indeterminate]="tempState"
                    (onChange)="!checkboxDisabled && onClickCheckBox($event)"
                    style="margin-left: 40px"
                  >
                    <span>Complex only</span>
                  </ap-checkbox>
                </div>
              </div>
              <div class="flexcontainer containerMarginTop flexspacebetween">
                <div class="textheadingbox">
                  <span>Available tax accounts</span>
                </div>
                <div class="textheadingbox">
                  <span>Selected tax accounts</span>
                </div>
              </div>
              <div class="flexcontainer containerMarginTop">
                <div class="gridcontainter">
                  <kendo-grid
                    [data]="gridViewScope"
                    [kendoGridSelectBy]="selectedCallback"
                    [(selectedKeys)]="mySelectionScope"
                    [resizable]="true"
                    [selectable]="true"
                    [hideHeader]="true"
                    style="
                      height: calc(100vh - 490px) !important;
                      margin-right: 65px;
                    "
                  >
                    <kendo-grid-column
                      [headerStyle]="{
                        'font-weight': 'bold',
                        'text-align': 'center',
                        color: '#d04a02'
                      }"
                      field="TaxAccountDescription"
                    ></kendo-grid-column>
                  </kendo-grid>
                </div>
                <div
                  class="buttonList flexcontainer flexdirectioncolumn centeralign"
                  style="
                    width: fit-content;
                    position: fixed;
                    top: 60%;
                    left: 48%;
                  "
                >
                  <ap-button
                    kendoButton
                    [btnType]="'secondary'"
                    (click)="forwardScope()"
                    style="
                      width: 50px;
                      height: 37px;
                      margin-bottom: 5px;
                      padding-top: 0px;
                      padding-bottom: 0px;
                    "
                  >
                    <span class="material-icons">keyboard_arrow_right</span>
                  </ap-button>
                  <ap-button
                    kendoButton
                    (click)="backwardScope()"
                    [btnType]="'secondary'"
                    style="
                      width: 50px;
                      height: 37px;
                      margin-bottom: 5px;
                      padding-top: 0px;
                      padding-bottom: 0px;
                    "
                  >
                    <span class="material-icons">keyboard_arrow_left</span>
                  </ap-button>

                  <ap-button
                    kendoButton
                    (click)="forwardScopeAll()"
                    [btnType]="'secondary'"
                    style="
                      width: 50px;
                      height: 37px;
                      margin-bottom: 5px;
                      padding-top: 0px;
                      padding-bottom: 0px;
                    "
                  >
                    <span class="material-icons"
                      >keyboard_double_arrow_right</span
                    >
                  </ap-button>
                  <ap-button
                    kendoButton
                    (click)="backwardScopeAll()"
                    [btnType]="'secondary'"
                    style="
                      width: 50px;
                      height: 37px;
                      margin-bottom: 5px;
                      padding-top: 0px;
                      padding-bottom: 0px;
                    "
                  >
                    <span class="material-icons"
                      >keyboard_double_arrow_left</span
                    >
                  </ap-button>
                </div>
                <div class="gridcontainter">
                  <kendo-grid
                    [data]="gridViewScopeSelected"
                    [kendoGridSelectBy]="selectedCallback"
                    [(selectedKeys)]="mySelectionScopeSelected"
                    [resizable]="true"
                    [selectable]="true"
                    [hideHeader]="true"
                    style="
                      height: calc(100vh - 490px) !important;
                      margin-left: 65px;
                    "
                  >
                    <kendo-grid-column
                      [headerStyle]="{
                        'font-weight': 'bold',
                        'text-align': 'center',
                        color: '#d04a02'
                      }"
                      field="TaxAccountDescription"
                    ></kendo-grid-column>
                  </kendo-grid>
                </div>
              </div>
            </div>

            <div *ngIf="i == 1">
              <span
                >Choose the master data columns that you would only expect to
                find within this habitat.</span
              >

              <div class="flexcontainer containerMarginTop flexspacebetween">
                <div class="textheadingbox">
                  <span>Available master data</span>
                </div>
                <div class="textheadingbox">
                  <span>Selected master data</span>
                </div>
              </div>
              <div class="flexcontainer containerMarginTop">
                <div class="gridcontainter">
                  <kendo-grid
                    [data]="gridViewMasterData"
                    [kendoGridSelectBy]="selectedCallback"
                    [(selectedKeys)]="mySelectionMasterData"
                    [resizable]="true"
                    [selectable]="true"
                    [hideHeader]="true"
                    style="
                      height: calc(100vh - 400px) !important;
                      margin-right: 65px;
                    "
                  >
                    <kendo-grid-column
                      [headerStyle]="{
                        'font-weight': 'bold',
                        'text-align': 'center',
                        color: '#d04a02'
                      }"
                      field="PropertyName"
                    ></kendo-grid-column>
                  </kendo-grid>
                </div>
                <div
                  class="buttonList flexcontainer flexdirectioncolumn centeralign"
                  style="position: fixed; top: 50%; left: 40%"
                >
                  <ap-button
                    kendoButton
                    (click)="forwardMasterData()"
                    [btnType]="'secondary'"
                    style="
                      width: 50px;
                      height: 37px;
                      margin-bottom: 5px;
                      padding-top: 0px;
                      padding-bottom: 0px;
                    "
                  >
                    <span class="material-icons">keyboard_arrow_right</span>
                  </ap-button>
                  <ap-button
                    kendoButton
                    (click)="backwardMasterData()"
                    [btnType]="'secondary'"
                    style="
                      width: 50px;
                      height: 37px;
                      margin-bottom: 5px;
                      padding-top: 0px;
                      padding-bottom: 0px;
                    "
                  >
                    <span class="material-icons">keyboard_arrow_left</span>
                  </ap-button>

                  <ap-button
                    kendoButton
                    (click)="forwardMasterDataAll()"
                    [btnType]="'secondary'"
                    style="
                      width: 50px;
                      height: 37px;
                      margin-bottom: 5px;
                      padding-top: 0px;
                      padding-bottom: 0px;
                    "
                  >
                    <span class="material-icons"
                      >keyboard_double_arrow_right</span
                    >
                  </ap-button>
                  <ap-button
                    kendoButton
                    (click)="backwardMasterDataAll()"
                    [btnType]="'secondary'"
                    style="
                      width: 50px;
                      height: 37px;
                      margin-bottom: 5px;
                      padding-top: 0px;
                      padding-bottom: 0px;
                    "
                  >
                    <span class="material-icons"
                      >keyboard_double_arrow_left</span
                    >
                  </ap-button>
                </div>
                <div class="gridcontainter">
                  <kendo-grid
                    [data]="gridViewMasterDataSelected"
                    [kendoGridSelectBy]="selectedCallback"
                    [(selectedKeys)]="mySelectionMasterDataSelected"
                    [resizable]="true"
                    [selectable]="true"
                    [hideHeader]="true"
                    style="
                      height: calc(100vh - 400px) !important;
                      margin-left: 65px;
                    "
                  >
                    <kendo-grid-column
                      [headerStyle]="{
                        'font-weight': 'bold',
                        'text-align': 'center',
                        color: '#d04a02'
                      }"
                      field="PropertyName"
                    ></kendo-grid-column>
                  </kendo-grid>
                </div>
              </div>
            </div>

            <div *ngIf="i == 2">
              <span
                >Choose the keywords groups that contains the keywords you would
                only expect to find within this habitat.</span
              >

              <div class="flexcontainer containerMarginTop flexspacebetween">
                <div class="textheadingbox">
                  <span>Available keyword groups</span>
                </div>
                <div class="textheadingbox">
                  <span>Selected keyword groups</span>
                </div>
              </div>
              <div
                class="flexcontainer containerMarginTop flexspacebetween"
                style="width: 90%"
              >
                <div class="gridcontainter">
                  <kendo-combobox
                    [style.width]="'100%'"
                    [data]="folderList"
                    [filterable]="true"
                    textField="Name"
                    [placeholder]="'Folder:'"
                    [(ngModel)]="selectedItemFolder"
                    valueField="Name"
                    (valueChange)="handleFDchange($event)"
                    (filterChange)="handleFilterfolderList($event)"
                  ></kendo-combobox>
                </div>
                <div class="gridcontainter"></div>
              </div>
              <div class="flexcontainer containerMarginTop">
                <div class="gridcontainter">
                  <kendo-grid
                    [data]="gridViewKeyword"
                    [kendoGridSelectBy]="selectedCallback"
                    [(selectedKeys)]="mySelectionKeyword"
                    [resizable]="true"
                    [selectable]="true"
                    [hideHeader]="true"
                    style="
                      height: calc(100vh - 450px) !important;
                      margin-right: 65px;
                    "
                  >
                    <kendo-grid-column
                      [headerStyle]="{
                        'font-weight': 'bold',
                        'text-align': 'center',
                        color: '#d04a02'
                      }"
                      field="VocabularyGroupName"
                    ></kendo-grid-column>
                  </kendo-grid>
                </div>
                <div
                  class="buttonList flexcontainer flexdirectioncolumn centeralign"
                  style="position: fixed; top: 56%; left: 40%"
                >
                  <ap-button
                    kendoButton
                    (click)="forwardKeyword()"
                    [btnType]="'secondary'"
                    style="
                      width: 50px;
                      height: 37px;
                      margin-bottom: 5px;
                      padding-top: 0px;
                      padding-bottom: 0px;
                    "
                  >
                    <span class="material-icons">keyboard_arrow_right</span>
                  </ap-button>
                  <ap-button
                    kendoButton
                    (click)="backwardKeyword()"
                    [btnType]="'secondary'"
                    style="
                      width: 50px;
                      height: 37px;
                      margin-bottom: 5px;
                      padding-top: 0px;
                      padding-bottom: 0px;
                    "
                  >
                    <span class="material-icons">keyboard_arrow_left</span>
                  </ap-button>

                  <ap-button
                    kendoButton
                    (click)="forwardKeywordAll()"
                    [btnType]="'secondary'"
                    style="
                      width: 50px;
                      height: 37px;
                      margin-bottom: 5px;
                      padding-top: 0px;
                      padding-bottom: 0px;
                    "
                  >
                    <span class="material-icons"
                      >keyboard_double_arrow_right</span
                    >
                  </ap-button>
                  <ap-button
                    kendoButton
                    (click)="backwardKeywordAll()"
                    [btnType]="'secondary'"
                    style="
                      width: 50px;
                      height: 37px;
                      margin-bottom: 5px;
                      padding-top: 0px;
                      padding-bottom: 0px;
                    "
                  >
                    <span class="material-icons"
                      >keyboard_double_arrow_left</span
                    >
                  </ap-button>
                </div>
                <div class="gridcontainter">
                  <kendo-grid
                    [data]="gridViewKeywordSelected"
                    [kendoGridSelectBy]="selectedCallback"
                    [(selectedKeys)]="mySelectionKeywordSelected"
                    [resizable]="true"
                    [selectable]="true"
                    [hideHeader]="true"
                    style="
                      height: calc(100vh - 450px) !important;
                      margin-left: 65px;
                    "
                  >
                    <kendo-grid-column
                      [headerStyle]="{
                        'font-weight': 'bold',
                        'text-align': 'center',
                        color: '#d04a02'
                      }"
                      field="VocabularyGroupName"
                    ></kendo-grid-column>
                  </kendo-grid>
                </div>
              </div>
            </div>
          </ap-tab>
        </ap-tabset>
      </div>
      <div class="clickToActions" style="padding: 20px 0px">
        <ap-button
          [btnType]="'secondary'"
          [label]="'Cancel'"
          (click)="closeButtonClick()"
          class="cancelButton"
        ></ap-button>
        <ap-button
          [btnType]="'primary'"
          [label]="'Save'"
          [disabled]="!habitat || habitat.length == 0"
          themeColor="primary"
          (click)="saveChanges()"
          class="okButton"
          style="margin-right: 6px"
        ></ap-button>
      </div>
    </div>
    <div class="col-md-1 col-xs-12"></div>
  </div>
</div>
