<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Manage line item</div>
</kendo-dialog-titlebar>

<mat-card class="userCard" style="padding: 0px">
  <mat-card-title>
    <h6>
      Line item details - Business type:
      {{ LIDetailsfromDb?.TaxChartDescription }}
    </h6>
  </mat-card-title>
  <mat-card-content>
    <div>
      <form class="k-form" [formGroup]="editLineItemForm">
        <kendo-formfield>
          <kendo-label
            [for]="AccountingAreaID"
            text="Line item id:"
          ></kendo-label>
          <input
            formControlName="AccountingAreaID"
            kendoTextBox
            #AccountingAreaID
            [disabled]="true"
          />
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="Description" text="Description:*"></kendo-label>
          <input
            formControlName="Description"
            [maxlength]="100"
            kendoTextBox
            #Description
          />
          <kendo-formerror>{{ descriptionErrorMessage }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            [for]="AccountClass"
            text="Account class:*"
          ></kendo-label>
          <kendo-combobox
            #AccountClass
            required
            [disabled]="true"
            formControlName="AccountClass"
            [data]="AccountClassDescriptionList"
            [filterable]="true"
            textField="Description"
            valueField="ID"
            (valueChange)="handleAccountClassChange($event)"
          >
            <kendo-formerror>{{ accountclassErrorMessage }}</kendo-formerror>
          </kendo-combobox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            [for]="ParentLineItem"
            text="Parent line item:"
          ></kendo-label>
          <kendo-combobox
            #ParentLineItem
            formControlName="ParentLineItem"
            [data]="AccountingAreaDescriptionList"
            [filterable]="true"
            textField="Description"
            valueField="LineID"
            (filterChange)="handleFilterParentLineItem($event)"
          >
          </kendo-combobox>
        </kendo-formfield>
        <br />
        <kendo-formfield>
          <kendo-label [for]="CreateTaxAccount" text="Create tax account:"
            >&nbsp;&nbsp;
            <ap-checkbox
              formControlName="CreateTaxAccount"
              #CreateTaxAccount
              (onChange)="onHighlightClick($event)"
            >
            </ap-checkbox>
          </kendo-label>
        </kendo-formfield>
      </form>
    </div>
  </mat-card-content>
</mat-card>

<div class="clickToActions" style="padding-top: 10px">
  <ap-button
    [btnType]="'secondary'"
    class="cancelButton"
    [label]="'Cancel'"
    (onClick)="cancelClick()"
  ></ap-button>
  <ap-button
    [btnType]="'primary'"
    [disabled]="!editLineItemForm.valid"
    [label]="'Save'"
    class="okButton"
    style="margin-right: 6px"
    (onClick)="saveEditLineItem()"
  ></ap-button>
</div>
