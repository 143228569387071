<!-- Header -->
<div class="header" style="font-size: 20px">Manage GL accounts</div>
<div class="mainContent" class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <!-- Sub Header -->
      <div class="subHeader" style="font-size: 18px">GL account id</div>
      <!-- Select business type -->
      <div style="margin-bottom: -5px">
        <form class="k-form" [formGroup]="glAccountsGridForm">
          <div class="form-group">
            <kendo-combobox
              #businessTypeId
              formControlName="businessTypeId"
              [data]="businesstypeList"
              [filterable]="true"
              textField="Description"
              valueField="BusinessTypeId"
              (valueChange)="handleBTchange($event)"
              (filterChange)="handleFilterBusinessTypeId($event)"
              [(ngModel)]="selectedItem"
            >
            </kendo-combobox>
          </div>
        </form>
      </div>
      <!-- Search bar and action -->
      <div style="display: flex; padding-bottom: 10px; margin-top: 10px">
        <ap-search
          [placeholder]="'Search here'"
          [searchType]="'secondary'"
          style="width: 100%; margin-right: 24px; padding: 0px !important"
          [disabled]="false"
          (onSearch)="onFilter($event)"
          (onClear)="onClearSearch($event)"
        ></ap-search>
        <ap-button
          [icon]="'plus-outline'"
          [compact]="true"
          [add]="true"
          (onClick)="openNewGLAccountsPopup()"
          kendoTooltip
          title="New"
        ></ap-button>
      </div>
      <!-- subHeader -->
      <div class="subHeader" style="font-size: 18px">GL accounts</div>
      <kendo-grid
        [data]="GLAccounts"
        scrollable="virtual"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        [resizable]="true"
        class="gridHeight"
        [reorderable]="true"
      >
        <kendo-grid-command-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'center'
          }"
          field="IsActive"
          title="Actions"
          [width]="120"
        >
          <ng-template kendoGridCellTemplate let-data>
            <button
              kendoButton
              name="edit"
              kendoTooltip
              title="Edit"
              (click)="openEditGLAccountsPopup(data)"
              style="margin-right: 5px"
            >
              <span class="Appkit4-icon icon-edit-outline"></span>
            </button>
            <button
              kendoButton
              name="delete"
              kendoTooltip
              title="Delete"
              (click)="openDeleteGLAccountsPopup(data)"
            >
              <span class="Appkit4-icon icon-delete-outline"></span>
            </button>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column
          field="AccountNumber"
          title="Account number"
          [width]="180"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="ShortDescription"
          title="Short description"
          [width]="180"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="BalanceSheetPandLIndicator"
          title="Balance"
          [width]="100"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="AccountType"
          title="Statutory treatment"
          [width]="150"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="TranCount"
          title="Trancount"
          [width]="100"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Statement_Analysis"
          title="Statement analysis"
          [width]="150"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Amount"
          title="Amount"
          [width]="120"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
