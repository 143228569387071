<div *ngIf="isLoading">
  <kendo-chunkprogressbar 
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%;"
  ></kendo-chunkprogressbar>
</div>
<div class="header">Manage export keywords</div>
<div class="container-fluid" style="padding-top: 10px;">
  <div class="row">
    <div class="col-md-8 col-xs-12">
      <div class="example-wrapper">
        <kendo-label text="Select one or more folders from the list below to export data from">
          <kendo-multiselect
            placeholder="Select options ..."
            [checkboxes]="{ checkOnClick: false }"
            [autoClose]="false"
            [data]="keywordGroupFolders"
            [(ngModel)]="value"
            textField="Name"
            valueField="VocabularyGroupFolderId"
          ></kendo-multiselect>
        </kendo-label>
      </div>
      <div>&nbsp;</div>
      <div class="clickToActions">
        <ap-button 
          class="cancelButton"
          [btnType]="'secondary'"
          [label]="'Cancel'"
          (click)="closeButtonClick()"
        ></ap-button>
        <ap-button
          class="okButton"
          [btnType]="'primary'"
          [label]="'Export'"
          (click)="export()"
          style="margin-right: 6px"
        ></ap-button>
      </div>
    </div>
    <div class="col-md-4 col-xs-12"></div>
  </div>
</div>
<mat-card class="userCard">
  <!-- <mat-card-title style="font-size: 18px; line-height: 1.5em;background-color: #D04A02;color: white;">
    &nbsp;Manage Export Keywords
  </mat-card-title> -->
  
</mat-card>