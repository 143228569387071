<div class="header">Unlock Segment</div>
<div id="mainContent" class="container-fluid" style="margin-top: 15px">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <kendo-grid
        [data]="segmentList"
        scrollable="virtual"
        [sortable]="true"
        [resizable]="true"
        [reorderable]="true"
      >
        <kendo-grid-column
          field="SegmentName"
          title="Segment name"
          [width]="100"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="LockOwners"
          title="Lock owners"
          [width]="250"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'left'
          }"
          field="IsActive"
          title="Actions"
          [width]="50"
        >
          <ng-template kendoGridCellTemplate let-data>
            <button
              kendoButton
              name="unlock"
              kendoTooltip
              title="Unlock"
              (click)="unlockButtonClick(data)"
              style="margin-right: 5px"
              *ngIf="data.IsSegmentCanBeUnlocked"
            >
              <span class="Appkit4-icon icon-lockopen-unlock-outline"></span>
            </button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
