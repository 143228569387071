import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { TaxAccount } from 'src/app/_models/taxAccount';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ManagetransactionService {
  public apiUrl: any;
  apiHost: string;
  baseUrl: any;
  apifolder: any;
  selectedClient: any;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      GetAllocateTransactionViewData:
        '/ManageTransaction/GetAllocateTransactionViewData',
      GetAllocateTransactionViewDataCount:
        '/ManageTransaction/GetAllocateTransactionViewDataCount',
      GetTransactionType: '/ManageTransaction/GetTransactionType',
      AddTransactionType: '/ManageTransaction/AddTransactionType',
      AssignTransactionType: '/ManageTransaction/AssignTransactionType',
      ValidateMoveTransaction: '/ManageTransaction/ValidateMoveTransaction',
      UnMatchTransactions: '/ManageTransaction/UnMatchTransactions',
      MatchTransactions: '/ManageTransaction/MatchTransactions',
      UnMatchAllTransactions: '/ManageTransaction/UnMatchAllTransactions',
      GetAutoMatchColumns: '/ManageTransaction/GetAutoMatchColumns',
      AutoMatchTransactions: '/ManageTransaction/AutoMatchTransactions',
    };
  }

  GetAllocateTransactionViewData(
    taxAccountID: number,
    legalEntityID: number,
    fiscalYear: number,
    startIndex: number,
    maxRows: number,
    matched: boolean,
    moved: boolean,
    childTaxAccountTrans: boolean,
    sortColumn: string,
    orderBy: boolean
  ): Observable<TaxAccount[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('taxAccountID', taxAccountID)
      .set('legalEntity', legalEntityID)
      .set('fiscalYear', fiscalYear)
      .set('startIndex', startIndex)
      .set('maxRows', maxRows)
      .set('matched', matched)
      .set('moved', moved)
      .set('childTaxAccountTrans', childTaxAccountTrans)
      .set('orderByParam', sortColumn)
      .set('orderByAsc', orderBy);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.GetAllocateTransactionViewData;
    return this.http.get<TaxAccount[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  GetAllocateViewDataCount(
    taxAccountID: number,
    legalEntityID: number,
    fiscalYear: number,
    includeMatched: boolean,
    includeMoved: boolean,
    includeChildTaxAccountTrans: boolean
  ): Observable<TaxAccount[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('taxAccountID', taxAccountID)
      .set('legalEntity', legalEntityID)
      .set('fiscalYear', fiscalYear)
      .set('includeMatched', includeMatched)
      .set('includeMoved', includeMoved)
      .set('includeChildTaxAccountTrans', includeChildTaxAccountTrans);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.GetAllocateTransactionViewDataCount;

    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  AutoMatchTransactionsData(
    taxAccountID: number,
    legalEntityID: number,
    fiscalYear: number
  ): Observable<TaxAccount[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('taxAccountID', taxAccountID)
      .set('legalEntity', legalEntityID)
      .set('fiscalYear', fiscalYear);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.AutoMatchTransactions;
    return this.http.post<TaxAccount[]>(this.apiHost, headers, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  UnMatchAllTransactionsData(
    parms: any,
    transactionIds: any
  ): Observable<TaxAccount[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      accept: '*/*',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    parms['ClientdbName'] = this.selectedClient.DBName;

    let httpParams = new HttpParams().appendAll(parms);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.UnMatchAllTransactions;
    return this.http.post<TaxAccount[]>(this.apiHost, transactionIds, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  MatchTransactions(parms: any, transactionIds: any): Observable<TaxAccount[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      accept: '*/*',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    parms['ClientdbName'] = this.selectedClient.DBName;

    let httpParams = new HttpParams().appendAll(parms);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.MatchTransactions;
    return this.http.post<TaxAccount[]>(this.apiHost, transactionIds, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  AddTransactionType(
    parms: any,
    transactionIds: any
  ): Observable<TaxAccount[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      accept: '*/*',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    parms['ClientdbName'] = this.selectedClient.DBName;

    let httpParams = new HttpParams().appendAll(parms);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.AddTransactionType;
    return this.http.post<TaxAccount[]>(this.apiHost, transactionIds, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  AssignTransactionType(
    parms: any,
    transcationId: any
  ): Observable<TaxAccount[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      accept: '*/*',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    parms['ClientdbName'] = this.selectedClient.DBName;

    let httpParams = new HttpParams().appendAll(parms);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.AssignTransactionType;
    return this.http.post<TaxAccount[]>(this.apiHost, transcationId, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  ValidateMoveTransaction(
    taxAccountID: number,
    legalEntityID: number,
    fiscalYear: number
  ): Observable<TaxAccount[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('taxAccountID', taxAccountID)
      .set('legalEntity', legalEntityID)
      .set('fiscalYear', fiscalYear);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.ValidateMoveTransaction;
    return this.http.post<TaxAccount[]>(this.apiHost, headers, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  UnMatchTransactions(transactionIds: any): Observable<TaxAccount[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      accept: '*/*',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.UnMatchTransactions;
    return this.http.post<TaxAccount[]>(this.apiHost, transactionIds, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  GetTransactionType(): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.GetTransactionType;
    return this.http.post<string>(this.apiHost, headers, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  GetAutoMatchColumns(): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.GetAutoMatchColumns;
    return this.http.get<string>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }
}
