import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './common/modules/material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ng6-toastr-notifications';
import { MyGlobalObject } from './common/myglobalobject';
import { GlobalService } from './common/globalservice/globalservice.service';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { CheckboxModule } from '@appkit4/angular-components/checkbox';
import { ClientselectpopupComponent } from './clientselectpopup/clientselectpopup.component';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AddbusinesstypeComponent } from './businessType/addbusinesstype/addbusinesstype.component';
import { BusinesstypegridpopupComponent } from './businessType/businesstypegridpopup/businesstypegridpopup.component';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { MenuComponent } from './menu/menu.component';
import { MenusModule, MenuModule } from '@progress/kendo-angular-menu';
import { IconsModule } from '@progress/kendo-angular-icons';
import { LabelModule } from '@progress/kendo-angular-label';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FieldModule } from '@appkit4/angular-components/field';
import { DropdownModule } from '@appkit4/angular-components/dropdown';
import { RadioModule } from '@appkit4/angular-components/radio';
import { TooltipModule } from '@appkit4/angular-components/tooltip';
//import { TooltipModule as KendoTooltipModule } from "@progress/kendo-angular-tooltip";
import { DeletebusinesstypeComponent } from './businessType/deletebusinesstype/deletebusinesstype.component';
import { AddlegalentityComponent } from './legalEntity/addlegalentity/addlegalentity.component';
import { LegalentitygridpopupComponent } from './legalEntity/legalentitygridpopup/legalentitygridpopup.component';
import { DeletelegalentityComponent } from './legalEntity/deletelegalentity/deletelegalentity.component';
import { AnalysisstatementgridpopupComponent } from './analysisStatement/analysisstatementgridpopup/analysisstatementgridpopup.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { AddanalysisstatementlayoutComponent } from './analysisStatement/addanalysisstatementlayout/addanalysisstatementlayout.component';
import { NewsubanalysisComponent } from './analysisStatement/newsubanalysis/newsubanalysis.component';
import { DeletestatementlayoutComponent } from './analysisStatement/deletestatementlayout/deletestatementlayout.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { LineitemsComponent } from './lineitems/lineitems.component';
import { NewlineitemComponent } from './lineitems/newlineitem/newlineitem.component';
import { DeletelineitemComponent } from './lineitems/deletelineitem/deletelineitem.component';
import { CopylineitemComponent } from './lineitems/copylineitem/copylineitem.component';
import { EditlineitemComponent } from './lineitems/editlineitem/editlineitem.component';
import { TaxaccountsgridpopupComponent } from './taxaccounts/taxaccountsgridpopup/taxaccountsgridpopup.component';
import { NewtaxaccountComponent } from './taxaccounts/newtaxaccount/newtaxaccount.component';
import { DeletetaxaccountComponent } from './taxaccounts/deletetaxaccount/deletetaxaccount.component';
import { GLaccountsgridpopupComponent } from './glaccounts/glaccountsgridpopup/glaccountsgridpopup.component';
import { NewglaccountsComponent } from './glaccounts/newglaccounts/newglaccounts.component';
import { EditglaccountsComponent } from './glaccounts/editglaccounts/editglaccounts.component';
import { DeleteglaccountsComponent } from './glaccounts/deleteglaccounts/deleteglaccounts.component';
import { HabitatsComponent } from './habitats/habitats.component';
import { DeletehabitatsComponent } from './habitats/deletehabitats/deletehabitats.component';
import { ManagehabitatsComponent } from './habitats/managehabitats/managehabitats.component';
import { ReassignHabitatsComponent } from './habitats/reassign-habitats/reassign-habitats.component';
import { TabsModule } from '@appkit4/angular-components/tabs';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { KeywordGroupFormListComponent } from './keyword/keyword-group-form-list/keyword-group-form-list.component';
import { ButtonModule } from '@appkit4/angular-components/button';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { LoadingModule } from '@appkit4/angular-components/loading';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { QueryBuilderModule } from 'angular2-query-builder';
import { KeywordImportComponent } from './keyword/keyword-import/keyword-import.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MapGlAccountComponent } from './map-gl-account/map-gl-account.component';
import { KeywordExportComponent } from './keyword/keyword-export/keyword-export.component';
import { SummaryStatementComponent } from './analysisReview/summary-statement/summary-statement.component';
import { AnalysisReviewGridPopupComponent } from './analysisReview/analysis-review-grid-popup/analysis-review-grid-popup.component';
import { AnalysisReviewLayoutComponent } from './analysisReview/analysis-review-layout/analysis-review-layout.component';
import { JournalService } from './common/services/journal/journal-service';
import { ManageJournalsComponent } from './journal/manage-journals/manage-journals.component';
import { ManageAdjustmentJournalComponent } from './journal/manage-adjustment-journal/manage-adjustment-journal.component';
import { ManageSelectJournalComponent } from './journal/manage-select-journal/manage-select-journal.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ProgressStepperModule } from '@appkit4/angular-components/progress-stepper';
import { TransactionsComponent } from './transactionsImport/transactions/transactions.component';
import { TrialbalanceComponent } from './trialbalanceImport/trialbalance/trialbalance.component';
import { AnalysisPackPopupComponent } from './analysisReview/analysis-pack-popup/analysis-pack-popup.component';
import { HomepageComponent } from './homepage/homepage.component';
import { MoveSubMenuPopupComponent } from './analysisReview/move-sub-menu-popup/move-sub-menu-popup.component';
import { AttachmentSubMenuPopupComponent } from './analysisReview/attachment-sub-menu-popup/attachment-sub-menu-popup.component';
import { EditAnalysisPackComponent } from './analysisReview/edit-analysis-pack/edit-analysis-pack.component';
import { DeleteStatementComponent } from './analysisReview/delete-statement/delete-statement.component';
import { LinkLineItemPopupComponent } from './analysisReview/link-line-item-popup/link-line-item-popup.component';
import { UnlinkLineItemPopupComponent } from './analysisReview/unlink-line-item-popup/unlink-line-item-popup.component';
import { DeleteAnalysisPackPopupComponent } from './analysisReview/delete-analysis-pack-popup/delete-analysis-pack-popup.component';
import { AnalysisNewLineComponent } from './analysisReview/analysis-line-item/analysis-new-line/analysis-new-line.component';
import { AnalysisEditLineComponent } from './analysisReview/analysis-line-item/analysis-edit-line/analysis-edit-line.component';
import { AnalysisDeleteLineComponent } from './analysisReview/analysis-line-item/analysis-delete-line/analysis-delete-line.component';
import { SearchModule } from '@appkit4/angular-components/search';
import { AnalysisTaxaccountComponent } from './analysisReview/analysis-line-item/analysis-taxaccount/analysis-taxaccount.component';
import { AnalysisEditTaxaccountComponent } from './analysisReview/analysis-line-item/analysis-edit-taxaccount/analysis-edit-taxaccount.component';
import { LinkAnalysisStatementPopupComponent } from './analysisReview/link-analysis-statement-popup/link-analysis-statement-popup.component';
import { UnlinkAnalysisStatementPopupComponent } from './analysisReview/unlink-analysis-statement-popup/unlink-analysis-statement-popup.component';
import { ManageAllocationRulesComponent } from './allocation-rules/manage-allocation-rules/manage-allocation-rules.component';
import { EditAllocationRulesComponent } from './allocation-rules/edit-allocation-rules/edit-allocation-rules.component';
import { AnalysisStatementComponent } from './showDetails/analysis-statement/analysis-statement.component';
import { DisplayAccountPopupComponent } from './analysisReview/display-account-popup/display-account-popup.component';
import { NoLineItemComponent } from './analysisReview/no-line-item/no-line-item.component';
import { DeleteAllocationRuleComponent } from './allocation-rules/delete-allocation-rule/delete-allocation-rule.component';
import { TaxOutcomeComponent } from './analysisReview/tax-outcome/tax-outcome.component';
import { DefineExpressionsComponent } from './allocation-rules/define-expressions/define-expressions.component';
import { LockstatusComponent } from './lockstatus/lockstatus.component';
import { EditExpressionComponent } from './allocation-rules/edit-expression/edit-expression.component';
import { DeleteExpressionComponent } from './allocation-rules/delete-expression/delete-expression.component';
import { ShowTransactionsComponent } from './showDetails/show-transactions/show-transactions.component';
import { ShowGlAccountsComponent } from './showDetails/show-gl-accounts/show-gl-accounts.component';
import { ManageMovementJournalComponent } from './journal/manage-movement-journal/manage-movement-journal.component';
import { AllocateTransactionComponent } from './Allocate/allocate-transaction/allocate-transaction.component';
import { NewExpressionComponent } from './allocation-rules/new-expression/new-expression.component';
import { TransformexpressionPipe } from './allocation-rules/new-expression/transformexpression.pipe';
import { PrettyjsonPipe } from './allocation-rules/new-expression/prettyjson.pipe';
import { AddKeywordComponent } from './allocation-rules/add-keyword/add-keyword.component';
import { DeleteJournalComponent } from './journal/delete-journal/delete-journal.component';
import { LegendComponent } from './analysisReview/legend/legend.component';
import { GlAccountComponent } from './Accounts/gl-account/gl-account.component';
import { ImportGlAccountComponent } from './Accounts/import-gl-account/import-gl-account.component';
import { OfflineReferralService } from './common/services/offlineReferral/offlineReferral-service';
import { OfflineReferralBreakdownLevelComponent } from './offlineReferral/offline-referral-breakdown-level/offline-referral-breakdown-level.component';
import { PreferenceComponent } from './Admin/preference/preference.component';
import { HeaderComponent } from './header/header.component';
import { ManageTransactionLinesComponent } from './Admin/manage-transaction-lines/manage-transaction-lines.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ManageOfflineReferralsComponent } from './manage-offline-referrals/manage-offline-referrals.component';
import { ManageCustomColumnComponent } from './Admin/TransactionCustomColumn/manage-custom-column/manage-custom-column.component';
import { StatementDetailsExportComponent } from './statement-details-export/statement-details-export.component';
import { DeleteKeywordComponent } from './allocation-rules/delete-keyword/delete-keyword.component';
import { CollectOfflineReferralComponent } from './offlineReferral/collect-offline-referral/collect-offline-referral.component';
import { NumberPipe } from './common/Pipe/NumberPipe';
import { MasterDataComponent } from './master-data/master-data.component';
import { MasterDataService } from './common/services/masterData/masterData-service';
import { ExportAnalysisStatementsComponent } from './Export/export-analysis-statements/export-analysis-statements.component';
import { ImportStatementDetailsComponent } from './import-statement-details/import-statement-details.component';
import { EditstatementcolumnComponent } from './analysisStatement/editstatementcolumn/editstatementcolumn.component';
import { SparklineModule } from '@progress/kendo-angular-charts';
import { ChartModule } from '@progress/kendo-angular-charts';
import { ManageAttachementComponent } from './Allocate/manage-attachement/manage-attachement.component';
import { DeleteKeywordGroupComponent } from './keyword/delete-keyword/delete-keyword.component';
import { UnlocksegmentComponent } from './Admin/unlocksegment/unlocksegment.component';

import { ComparativeAnalysisComponent } from './analysisReview/comparative-analysis/comparative-analysis.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { AccountSummeryComponent } from './homepage/account-summery/account-summery.component';
import { OffineReferallsComponent } from './homepage/offine-referalls/offine-referalls.component';
import { DeleteManageAttachementComponent } from './analysisReview/delete-manage-attachement/delete-manage-attachement.component';
import { TaxAccountReportComponent } from './analysisReview/tax-account-report/tax-account-report.component';
import { StatutorytreatmentComponent } from './statutorytreatment/statutorytreatment.component';
import { CreateEditStatutorytreatmentComponent } from './statutorytreatment/create-edit-statutorytreatment/create-edit-statutorytreatment.component';
import { DeleteStatutorytreatmentComponent } from './statutorytreatment/delete-statutorytreatment/delete-statutorytreatment.component';
import { TaxoutcomesComponent } from './taxoutcomes/taxoutcomes.component';
import { NewOrEditTaxoutcomesComponent } from './taxoutcomes/new-or-edit-taxoutcomes/new-or-edit-taxoutcomes.component';
import { MergeTaxOutcomesComponent } from './taxoutcomes/merge-tax-outcomes/merge-tax-outcomes.component';
import { DeleteTaxoutcomesComponent } from './taxoutcomes/delete-taxoutcomes/delete-taxoutcomes.component';
import { AddManageAttachmentComponent } from './analysisReview/add-manage-attachment/add-manage-attachment.component';
import { MoveTransactionsPopupComponent } from './analysisReview/move-transactions-popup/move-transactions-popup.component';
import { GlAccountRunComponent } from './gl-account-run/gl-account-run.component';
import { PiechartPopupComponent } from './homepage/piechart-popup/piechart-popup.component';
import { LoaderInterceptor } from './common/helpers/loader.interceptor';
import { CustomColumnsDetailsComponent } from './allocation-rules/manage-allocation-rules/custom-columns-details/custom-columns-details.component';
import { HelpComponent } from './help/help.component';
import { TermsanduseComponent } from './termsanduse/termsanduse.component';
import { PrivacystatementComponent } from './privacystatement/privacystatement.component';
import { UploadglaccountComponent } from './map-gl-account/uploadglaccount/uploadglaccount.component';
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ClientselectpopupComponent,
    AddbusinesstypeComponent,
    BusinesstypegridpopupComponent,
    DeletebusinesstypeComponent,
    AddlegalentityComponent,
    LegalentitygridpopupComponent,
    DeletelegalentityComponent,
    AnalysisstatementgridpopupComponent,
    AddanalysisstatementlayoutComponent,
    NewsubanalysisComponent,
    DeletestatementlayoutComponent,
    LineitemsComponent,
    NewlineitemComponent,
    DeletelineitemComponent,
    CopylineitemComponent,
    EditlineitemComponent,
    TaxaccountsgridpopupComponent,
    DeletetaxaccountComponent,
    NewtaxaccountComponent,
    GLaccountsgridpopupComponent,
    NewglaccountsComponent,
    EditglaccountsComponent,
    DeleteglaccountsComponent,
    HabitatsComponent,
    DeletehabitatsComponent,
    ManagehabitatsComponent,
    ReassignHabitatsComponent,
    KeywordGroupFormListComponent,
    KeywordImportComponent,
    MapGlAccountComponent,
    KeywordExportComponent,
    AnalysisReviewGridPopupComponent,
    AnalysisReviewLayoutComponent,
    TransactionsComponent,
    TrialbalanceComponent,
    ManageJournalsComponent,
    ManageAdjustmentJournalComponent,
    ManageMovementJournalComponent,
    ManageSelectJournalComponent,
    HomepageComponent,
    MoveSubMenuPopupComponent,
    AttachmentSubMenuPopupComponent,
    AnalysisPackPopupComponent,
    SummaryStatementComponent,
    DeleteStatementComponent,
    EditAnalysisPackComponent,
    LinkLineItemPopupComponent,
    UnlinkLineItemPopupComponent,
    DeleteAnalysisPackPopupComponent,
    AnalysisNewLineComponent,
    AnalysisEditLineComponent,
    AnalysisDeleteLineComponent,
    AnalysisTaxaccountComponent,
    AnalysisEditTaxaccountComponent,
    LinkAnalysisStatementPopupComponent,
    UnlinkAnalysisStatementPopupComponent,
    ManageAllocationRulesComponent,
    EditAllocationRulesComponent,
    AnalysisStatementComponent,
    DisplayAccountPopupComponent,
    NoLineItemComponent,
    AnalysisStatementComponent,
    DeleteAllocationRuleComponent,
    TaxOutcomeComponent,
    DefineExpressionsComponent,
    DeleteExpressionComponent,
    LockstatusComponent,
    ShowTransactionsComponent,
    ShowGlAccountsComponent,
    EditExpressionComponent,
    ShowTransactionsComponent,
    AllocateTransactionComponent,
    NewExpressionComponent,
    TransformexpressionPipe,
    PrettyjsonPipe,
    AddKeywordComponent,
    DeleteJournalComponent,
    LegendComponent,
    GlAccountComponent,
    ImportGlAccountComponent,
    OfflineReferralBreakdownLevelComponent,
    PreferenceComponent,
    HeaderComponent,
    ManageTransactionLinesComponent,
    ManageOfflineReferralsComponent,
    ManageCustomColumnComponent,
    StatementDetailsExportComponent,
    DeleteKeywordComponent,
    CollectOfflineReferralComponent,
    NumberPipe,
    MasterDataComponent,
    ExportAnalysisStatementsComponent,
    ImportStatementDetailsComponent,
    EditstatementcolumnComponent,
    DeleteKeywordGroupComponent,
    ManageAttachementComponent,
    UnlocksegmentComponent,

    ComparativeAnalysisComponent,
    AccountSummeryComponent,
    OffineReferallsComponent,
    DeleteManageAttachementComponent,
    TaxAccountReportComponent,
    StatutorytreatmentComponent,
    CreateEditStatutorytreatmentComponent,
    DeleteStatutorytreatmentComponent,
    TaxoutcomesComponent,
    NewOrEditTaxoutcomesComponent,
    MergeTaxOutcomesComponent,
    DeleteTaxoutcomesComponent,
    AddManageAttachmentComponent,
    MoveTransactionsPopupComponent,
    GlAccountRunComponent,
    PiechartPopupComponent,
    CustomColumnsDetailsComponent,
    HelpComponent,
    TermsanduseComponent,
    PrivacystatementComponent,
    UploadglaccountComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    InputsModule,
    MaterialModule,
    HttpClientModule,
    HttpClientJsonpModule,
    DialogsModule,
    WindowModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownsModule,
    NavigationModule,
    MenusModule,
    MenuModule,
    IconsModule,
    ToastrModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    LabelModule,
    GridModule,
    ExcelModule,
    ButtonsModule,
    TreeViewModule,
    LayoutModule,
    FieldModule,
    DropdownModule,
    RadioModule,
    CheckboxModule,
    TabsModule,
    TreeListModule,
    ButtonModule,
    ProgressBarModule,
    UploadsModule,
    ProgressStepperModule,
    DateInputsModule,
    LoadingModule,
    SearchModule,
    TooltipModule,
    QueryBuilderModule,
    ChartModule,
    SparklineModule,
    ChartsModule,
  ],
  providers: [
    MyGlobalObject,
    GlobalService,
    JournalService,
    OfflineReferralService,
    MasterDataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
