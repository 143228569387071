<!-- Header -->
<div class="header">Manage tax outcomes</div>
<div id="mainContent" class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <!-- search bar and action -->
      <div class="searchContainer">
        <ap-search
          [placeholder]="'Search here'"
          [searchType]="'secondary'"
          style="width: 100%; margin-right: 24px; padding: 0px !important"
          [disabled]="false"
          (onSearch)="onFilter($event)"
          [(ngModel)]="searchText"
        ></ap-search>
      </div>
      <!-- Sub Header  -->
      <div class="subHeader">Tax outcomes</div>
      <!-- Data grid -->

      <kendo-grid
        [data]="gridView"       
      [pageable]="false"
      [sortable]="true"        
      (edit)="editHandler($event)"
      (cancel)="cancelHandler($event)"
      (save)="saveHandler($event)"
      (remove)="removeHandler($event)"
      (add)="addHandler($event)"
      [resizable]="true"
        scrollable="virtual"
        [sort]="sort"
        (sortChange)="sortChange($event)"
      >
      <ng-template kendoGridToolbarTemplate>
        <ap-button
        kendoGridAddCommand
        [btnType]="'primary'"
        [label]="'Add new'"
      ></ap-button>
      </ng-template>
      <kendo-grid-command-column title="Action" [width]="70"
      [headerStyle]="{
        'font-weight': 'bold',
        'text-align': 'center',
        color: '#d04a02'
      }">
        <ng-template kendoGridCellTemplate let-isNew="isNew">
          <button kendoGridEditCommand title="Edit">
            <span class="Appkit4-icon icon-edit-outline"></span>
          </button>
          <button kendoGridRemoveCommand title="Delete">
            <span class="Appkit4-icon icon-delete-outline"></span>
          </button>
          <button kendoGridSaveCommand [disabled]="taxOutcomeForm?.invalid"
          
          title="{{ isNew ? 'Add' : 'Update' }}"
        >
        <span class="Appkit4-icon icon-save-outline"></span>
          </button>
          <button kendoGridCancelCommand icon="cancel"
          title="{{ isNew ? 'Discard changes' : 'Cancel' }}">
          </button>
        </ng-template>
      </kendo-grid-command-column>
        <kendo-grid-column
          [width]="320"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
          field="ShortDescription"
          title="Short description"
        >
        </kendo-grid-column>

        <kendo-grid-column
          [width]="320"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
          field="LongDescription"
          title="Long description"
        >
        </kendo-grid-column>
      </kendo-grid>

      <div style="margin-top: 5px">
        <label  style="font-size: 14px"
          >Default tax outcome</label
        ><br />
        <kendo-combobox
          [style.width]="'100%'"
          
          [data]="defaultTaxOutcomeList"
          [(ngModel)]="selectedItem"
          [filterable]="true"
          textField="ShortDescription"
          valueField="_id"
          (valueChange)="handledefaultTaxOutcomeChange($event)"
        >
        </kendo-combobox>
      </div>
    </div>
  </div>
</div>
