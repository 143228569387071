import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AllocationRulesService } from 'src/app/common/services/allocationrule/allocation-rules.service';
import { BusinessType } from 'src/app/_models/businessType';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { SharedserviceMoveService } from 'src/app/common/services/moveTransactions/sharedservice-move.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
@Component({
  selector: 'app-edit-allocation-rules',
  templateUrl: './edit-allocation-rules.component.html',
  styleUrls: ['./edit-allocation-rules.component.scss'],
})
export class EditAllocationRulesComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private allocationRulesService: AllocationRulesService,
    public toastr: ToastrManager,
    private sharedService: SharedserviceMoveService,
    public myGlobalObject: MyGlobalObject
  ) {}
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  routedata: any = [];
  addAllocationRuleForm!: FormGroup;
  businessTypesList: BusinessType[] = [];
  habitatList!: any[];
  expressionNameList: any[] = [];
  accountType: any = 'Simple';
  ComplexTaxAccountList: any[] = [];
  SimpleTaxAccountList: any[] = [];
  isSimpleEnable: boolean = false;
  selectedBusinessType: any;
  selectedhabitat: any | undefined;
  selectedexpressionName: any | undefined;
  selectedComplexTaxAccount: any;
  selectedSimpleTaxAccount: any | undefined;
  mode: string = '';
  allocationId: number = 0;
  isLoading: boolean = false;
  allocationDetail: any;
  taxAccount: any;
  issimpleTax: boolean = true;
  descriptionErrorMessage: string = 'Name is required';
  complexDescription: string = 'Complex tax account';
  copyOrEdit = false;
  ngOnInit(): void {
    this.setupForm();
    this.getBusinessTypeList();
    this.gethabitatList();
    this.getexpressionList();
    this.getcomplexList(0);

    if (this.route.snapshot.paramMap.get('id')?.includes('S')) {
      this.copyOrEdit = true;
    } else this.copyOrEdit = false;

    if (
      this.route.snapshot.paramMap.get('id') != '' &&
      this.route.snapshot.paramMap.get('id') != null &&
      !this.route.snapshot.routeConfig?.path?.includes('newAllocationRule')
    ) {
      if (this.route.snapshot.routeConfig?.path?.includes('copy') == true)
        this.mode = 'Copy';
      else if (this.route.snapshot.routeConfig?.path?.includes('edit') == true)
        this.mode = 'Edit';

      this.allocationId = Number(
        this.route.snapshot.paramMap.get('id')?.split('S')[0]
      );
      this.getAllocationRuleDetail(this.allocationId);
    } else {
      this.mode = 'Add';
      this.getSimpleTaxAccounts(0, 0);
      this.allocationId = 0;
      this.selectedComplexTaxAccount = 0;
      let a = this.route.snapshot.paramMap.get('id')?.split('S')[0];
      if (
        this.route.snapshot.routeConfig?.path?.includes('newAllocationRule')
      ) {
        this.selectedexpressionName = Number(a);
        this.getExpressionByRuleId(this.selectedexpressionName);
      }
    }
    this.routedata.push(this.mode.toLowerCase());

    this.sharedService.setRoutedata(this.routedata);
  }
  public editButtonClick(): void {
    if (this.route.snapshot.routeConfig?.path?.includes('newAllocationRule')) {
      this.router.navigate([
        '/editExpression/edit/',
        this.selectedexpressionName + 'S' + this.selectedexpressionName,
      ]);
    } else
      this.router.navigate([
        '/editExpression/edit/',
        this.selectedexpressionName +
          'S' +
          Number(this.route.snapshot.paramMap.get('id')?.split('S')[0]),
      ]);
  }
  public newButtonClick(): void {
    //this.router.navigate(['/newExpression/'+'S'+0]);
    if (this.route.snapshot.routeConfig?.path?.includes('newAllocationRule')) {
      // this.router.navigate(['/newExpression/'+'S'+0]);
      this.router.navigate([
        '/newExpression/',
        this.selectedexpressionName + 'S' + this.selectedexpressionName,
      ]);
    } else
      this.router.navigate([
        '/newExpression/',
        this.selectedexpressionName +
          'S' +
          Number(this.route.snapshot.paramMap.get('id')?.split('S')[0]),
      ]);
  }
  public copyButtonClick(): void {
    if (this.route.snapshot.routeConfig?.path?.includes('newAllocationRule')) {
      this.router.navigate([
        '/copyExpression/copy/',
        this.selectedexpressionName + 'S' + this.selectedexpressionName,
      ]);
    }
    this.router.navigate([
      '/copyExpression/copy/',
      this.selectedexpressionName +
        'S' +
        Number(this.route.snapshot.paramMap.get('id')?.split('S')[0]),
    ]);
  }
  getDisable() {
    if (
      this.addAllocationRuleForm.controls['expressionName'].value != null &&
      this.addAllocationRuleForm.controls['expressionName'].value !=
        undefined &&
      this.addAllocationRuleForm.controls['expressionName'].value != '' &&
      !Number.isNaN(this.addAllocationRuleForm.controls['expressionName'].value)
    ) {
      return false;
    } else return true;
  }
  setupForm(): void {
    this.addAllocationRuleForm = this.fb.group({
      txtName: ['', Validators.required],
      businessType: ['', Validators.required],
      habitat: ['', Validators.required],
      expressionName: ['', Validators.required],
      txtExpression: [''],
      complex: [''],
      ComplexTaxAccount: ['', Validators.required],
      SimpleTaxAccount: [
        '',
        this.accountType == 'Simple' ? Validators.required : '',
      ],
      account: 1,
    });
    this.addAllocationRuleForm.controls['txtExpression'].disable();
  }

  handleBusinessTypeChange(businessType: any): void {
    this.selectedBusinessType = businessType.BusinessTypeId;
    this.getcomplexList(businessType.BusinessTypeId);
    this.getSimpleTaxAccounts(0, this.selectedBusinessType);
  }
  handleHabitatChange(habitat: any): void {
    this.selectedhabitat = habitat.HabitatId;
  }
  handleExpressionNameChange(expression: any): void {
    this.selectedexpressionName = expression.RuleId;
    this.getExpressionByRuleId(this.selectedexpressionName);
  }
  handleComplexTaxAccountChange(compexTax: any): void {
    this.selectedComplexTaxAccount = compexTax.TaxAccountCodeId;
    this.selectedSimpleTaxAccount =
      this.accountType == 'Complex' ? 0 : undefined;
    this.getSimpleTaxAccounts(
      compexTax.TaxAccountCodeId,
      this.selectedBusinessType
    );
    this.complexDescription = 'Complex Tax Account';
    this.addAllocationRuleForm
      .get('ComplexTaxAccount')
      ?.valueChanges.subscribe(() => {
        const val = this.addAllocationRuleForm.getRawValue();
        if (val.account === 'Complex' && this.selectedComplexTaxAccount == 0) {
          this.addAllocationRuleForm.controls[
            'ComplexTaxAccount'
          ].markAsTouched();
          this.addAllocationRuleForm.controls['ComplexTaxAccount'].setErrors({
            incorrect: true,
          });
          this.complexDescription =
            'All Complex accounts is not a valid Tax Account for this Tax Account Type.';
        }
      });
  }
  handleSimpleTaxAccountChange(simpleTax: any): void {
    this.selectedSimpleTaxAccount = simpleTax.TaxAccountCodeId;
    if (this.accountType === 'Simple' && this.selectedComplexTaxAccount == 0) {
      this.complexDescription = 'Complex Tax Account';
      this.addAllocationRuleForm.controls['ComplexTaxAccount'].setErrors(null);
    }
  }
  closeButtonClick() {
    this.router.navigate(['/manageAllocationRule']);
  }

  getBusinessTypeList(): void {
    this.isLoading = true;
    this.allocationRulesService.getAllBusinessTypeList().subscribe(
      (response) => {
        this.businessTypesList = response;
        if (this.businessTypesList.length == 1) {
          this.selectedBusinessType = this.businessTypesList[0];
          this.handleBusinessTypeChange(this.selectedBusinessType);
        }
        var index = this.businessTypesList
          .map((x) => x.BusinessTypeId)
          .indexOf(0);
        this.businessTypesList.splice(index, 1).slice(0);
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  gethabitatList(): void {
    this.isLoading = true;
    this.allocationRulesService.getAllhabitatList().subscribe(
      (response) => {
        this.habitatList = response;
        if (this.habitatList.length == 1) {
          this.selectedhabitat = this.habitatList[0];
          this.handleHabitatChange(this.selectedhabitat);
        }
        var index = this.habitatList.map((x) => x.HabitatId).indexOf(0);
        this.habitatList.splice(index, 1).slice(0);
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getexpressionList(): void {
    this.isLoading = true;
    this.allocationRulesService.getAllexpressionList().subscribe(
      (response) => {
        this.expressionNameList = response;
        if (this.expressionNameList.length == 1) {
          this.selectedexpressionName = this.expressionNameList[0];
          this.handleExpressionNameChange(this.expressionNameList[0]);
        }
        if (this.myGlobalObject.newExpressionfromNewRule) {
          this.myGlobalObject.newExpressionfromNewRule = false;
          let index = this.expressionNameList.length - 1;
          this.selectedexpressionName = this.expressionNameList[index];
          this.handleExpressionNameChange(this.expressionNameList[index]);
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getcomplexList(businessType: number): void {
    this.isLoading = true;
    let selectionID: string = businessType.toString();
    this.allocationRulesService.getAllComplexList(selectionID).subscribe(
      (response) => {
        this.ComplexTaxAccountList = response;
        if (this.ComplexTaxAccountList.length == 1) {
          this.selectedComplexTaxAccount = this.ComplexTaxAccountList[0];
          this.handleComplexTaxAccountChange(this.selectedComplexTaxAccount);
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getAllocationRuleDetail(allocationID: number): void {
    this.isLoading = true;
    this.allocationRulesService.getAllocationRuleDetail(allocationID).subscribe(
      (response) => {
        this.allocationDetail = response;
        if (
          this.allocationDetail != undefined &&
          this.allocationDetail != null
        ) {
          if (this.mode != 'Copy')
            this.addAllocationRuleForm.controls['txtName'].setValue(
              this.allocationDetail.Item1.Name
            );
          if (this.allocationDetail.Item1.fk_TaxChartOfAccountsId != 0) {
            this.selectedBusinessType =
              this.allocationDetail.Item1.fk_TaxChartOfAccountsId;
          }
          if (this.allocationDetail.Item1.fk_LinkedHabitat != 0) {
            this.selectedhabitat = this.allocationDetail.Item1.fk_LinkedHabitat;
          }
          this.selectedexpressionName = this.allocationDetail.Item1.fk_RuleId;
          if (this.copyOrEdit)
            this.selectedexpressionName = Number(
              this.route.snapshot.paramMap.get('id')?.split('S')[1]
            );
          this.addAllocationRuleForm.controls['account'].setValue(
            this.allocationDetail.Item2.Description
          );
          let businessID, taxAccountcode;
          businessID = this.allocationDetail.Item1.fk_TaxChartOfAccountsId;
          taxAccountcode = this.allocationDetail.Item1.fk_TaxAccountId;
          if (
            businessID == undefined ||
            businessID == null ||
            businessID == 0
          ) {
            businessID = 0;
          }
          if (this.allocationDetail.Item2.Description == 'Simple') {
            this.isSimpleEnable = false;
            this.selectedSimpleTaxAccount = taxAccountcode;
            this.getDetailsByCode(taxAccountcode, businessID);
          } else {
            this.selectedComplexTaxAccount = taxAccountcode;
          }
          this.getcomplexList(businessID);
          this.getExpressionByRuleId(this.allocationDetail.Item1.fk_RuleId);
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
    if (this.mode == 'Copy') {
      this.allocationId = 0;
    }
  }

  getExpressionByRuleId(ruleID: number): void {
    this.isLoading = true;
    //console.log(ruleID)
    this.allocationRulesService.getExpressionByRuleId(ruleID).subscribe(
      (response) => {
        this.addAllocationRuleForm.controls['txtExpression'].setValue(response);
      },
      (error) => {
        console.log(error);
        this.addAllocationRuleForm.controls['txtExpression'].setValue(
          error.error.text
        );
        this.isLoading = false;
      }
    );
  }

  getSimpleTaxAccounts(
    ParentTaxAccountCode: number,
    BusinessTypeId: number
  ): void {
    this.isLoading = true;
    this.allocationRulesService
      .getSimpleTaxAccounts(ParentTaxAccountCode, BusinessTypeId)
      .subscribe(
        (response) => {
          this.SimpleTaxAccountList = response;
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  getDetailsByCode(code: number, businessID: number) {
    this.allocationRulesService
      .getTaxAccountDetailsByCode(code)
      .then((res) => {
        this.taxAccount = res;
        this.selectedComplexTaxAccount =
          this.taxAccount.fk_ParentTaxAccountCode == null
            ? 0
            : this.taxAccount.fk_ParentTaxAccountCode;
        this.getSimpleTaxAccounts(
          this.taxAccount.fk_ParentTaxAccountCode,
          businessID
        );
      })
      .catch((err) => {});
  }

  selectAccount(event: any) {
    this.accountType = event;
    if (event == 'Complex') {
      this.isSimpleEnable = true;
      this.selectedComplexTaxAccount = undefined;
      this.selectedSimpleTaxAccount = 0;
    } else {
      this.selectedComplexTaxAccount = undefined;
      this.selectedSimpleTaxAccount = undefined;
      this.isSimpleEnable = false;
    }
  }

  saveAllocationRule() {
    var formObject = this.addAllocationRuleForm.getRawValue();
    let taxAccountCode =
      this.accountType == 'Complex'
        ? this.selectedComplexTaxAccount
        : this.selectedSimpleTaxAccount;
    let allocationDetail = {
      _id: this.allocationId,
      Name: formObject.txtName,
      fk_TaxChartOfAccountsId:
        this.selectedBusinessType == undefined || this.selectedBusinessType == 0
          ? null
          : this.selectedBusinessType,
      fk_RuleId: this.selectedexpressionName,
      fk_TaxAccountId: taxAccountCode,
      Priority: 0,
      fk_LinkedHabitat:
        this.selectedhabitat == undefined || this.selectedhabitat == 0
          ? null
          : this.selectedhabitat,
    };
    if (this.allocationId > 0) this.updateAllocationRule(allocationDetail);
    else if (this.allocationId == 0)
      this.insertAllocationRule(allocationDetail);
  }

  insertAllocationRule(allocationobj: any) {
    this.allocationRulesService.SaveNewAllocationRule(allocationobj).subscribe(
      (res) => {
        if (res == 'Allocation Rule added successfully') {
          this.toastr.successToastr(res, 'Success');
          this.router.navigate(['/manageAllocationRule']);
        } else if (res == 'Allocation Rule Name  already exists') {
          this.addAllocationRuleForm.controls['txtName'].markAsTouched();
          this.descriptionErrorMessage =
            'Please modify name as it is not unique';
          this.addAllocationRuleForm.controls['txtName'].setErrors({
            incorrect: true,
          });
          return;
        } else {
          this.toastr.errorToastr(res, 'Failed');
        }
      },
      (error) => {
        this.isLoading = false;
        this.toastr.errorToastr('Failed to Save Allocation Rule', 'Error');
        console.log(error);
      }
    );
  }

  updateAllocationRule(allocationobj: any) {
    this.allocationRulesService.SaveEditAllocationRule(allocationobj).subscribe(
      (res) => {
        if (res == 'Allocation Rule updated successfully') {
          this.toastr.successToastr(res, 'Success');
          this.router.navigate(['/manageAllocationRule']);
        } else if (res == 'Allocation Rule Name  already exists') {
          this.addAllocationRuleForm.controls['txtName'].markAsTouched();
          this.descriptionErrorMessage =
            'Please modify name as it is not unique';
          this.addAllocationRuleForm.controls['txtName'].setErrors({
            incorrect: true,
          });
          return;
        } else {
          this.toastr.errorToastr(res, 'Failed');
        }
      },
      (error) => {
        this.isLoading = false;
        this.toastr.errorToastr('Failed to Save allocation rule', 'Error');
        console.log(error);
      }
    );
  }
}
