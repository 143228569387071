import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { AnalysispackService } from 'src/app/common/services/analysispackservice/analysispack.service';

@Component({
  selector: 'app-unlink-analysis-statement-popup',
  templateUrl: './unlink-analysis-statement-popup.component.html',
  styleUrls: ['./unlink-analysis-statement-popup.component.scss'],
})
export class UnlinkAnalysisStatementPopupComponent implements OnInit {
  @Input() public node: any;
  constructor(
    private globalService: GlobalService,
    private dialogService: DialogService,
    public dialog: DialogRef,
    private router: Router,
    public toastr: ToastrManager,
    private analysisPackService: AnalysispackService
  ) {}

  ngOnInit(): void {}

  unLinkAnalysisStatement() {
    this.analysisPackService
      .unLinkAnalysisStatement(this.node.parentStatementID, this.node.id)
      .then((res) => {
        if (res == 'Analysis Statement is deleted successfully') {
          this.toastr.successToastr(
            'Analysis Statement is unlinked successfully',
            'Success'
          );
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
        this.displaytreePack();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  displaytreePack() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }

  cancelClick() {
    this.dialog.close();
  }
}
