import { Component, Input, OnInit } from '@angular/core';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { Router } from '@angular/router';
import { AnalysispackService } from 'src/app/common/services/analysispackservice/analysispack.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BusinessType } from 'src/app/_models/businessType';
import { LegalEntity } from 'src/app/_models/legalEntity';
import { CookieService } from 'ngx-cookie-service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { LegalentitygridpopupComponent } from 'src/app/legalEntity/legalentitygridpopup/legalentitygridpopup.component';
import { AnalysisReviewGridPopupComponent } from '../analysis-review-grid-popup/analysis-review-grid-popup.component';

@Component({
  selector: 'app-analysis-pack-popup',
  templateUrl: './analysis-pack-popup.component.html',
  styleUrls: ['./analysis-pack-popup.component.scss'],
})
export class AnalysisPackPopupComponent implements OnInit {
  @Input() public nodeData: any;
  isLoading: boolean = false;
  businessTypes: BusinessType[] = [];
  legalEntities: LegalEntity[] = [];
  parentLEdetails: any;
  selectedBusinessType: BusinessType | undefined;
  selectedLegalEntity: LegalEntity | undefined;
  countryName: string = '';
  industryName: string = '';
  addPack: FormGroup | any;
  public actionsLayout: ActionsLayout = 'normal';
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dialogService: DialogService,
    public dialog: DialogRef,
    private cookieService: CookieService,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private globalService: GlobalService,
    private analysisPackService: AnalysispackService
  ) {
    this.addPack = this.fb.group({
      businessType: ['', Validators.required],
      LegalEntity: ['', Validators.required],
      packDescription: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getRefData();
  }
  getRefData() {
    this.globalService
      .getAllBusinessTypes()
      .then(async (res) => {
        this.businessTypes = res.slice();

        await this.getLEDetailsById(this.nodeData.id);
      })
      .catch((err) => {});
  }
  getLEDetailsById(LEId: number) {
    this.globalService
      .getLegalEntityById(LEId)
      .then(async (res) => {
        if (res) {
          this.parentLEdetails = res;

          await this.getLegalEntityList(res.BusinessTypeID, true);
          this.selectedBusinessType = this.businessTypes.find(
            (item) => item.BusinessTypeId == this.parentLEdetails.BusinessTypeID
          );
          if (this.parentLEdetails.CountryName)
            this.countryName = this.parentLEdetails.CountryName;
          if (this.parentLEdetails.IndustryName)
            this.industryName = this.parentLEdetails.IndustryName;

          //this.addPack.controls['businessType'].setValue(this.parentLEdetails.BusinessTypeID);
          //this.addPack.controls['LegalEntity'].setValue(this.parentLEdetails.ID);
        }
      })
      .catch((err) => {});
  }
  handleFilterBusinessTypeId(value: any) {
    this.businessTypes = this.businessTypes.filter(
      (s: any) =>
        s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  handleFilterEntity(value: any) {
    this.legalEntities = this.legalEntities.filter(
      (s: any) =>
        s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  handleBusinessTypeChange(businessType: BusinessType): void {
    this.selectedBusinessType = businessType;
    this.legalEntities = [];
    this.selectedLegalEntity = undefined;

    if (businessType != undefined) {
      this.getLegalEntityList(businessType.BusinessTypeId, false);
    }
  }
  handleLegalEntityChange(LE: any): void {
    if (LE == undefined) {
      this.countryName = '';
      this.industryName = '';
    } else {
      if (LE.CountryName) this.countryName = LE.CountryName;
      else this.countryName = '';
      if (LE.IndustryName) this.industryName = LE.IndustryName;
      else this.industryName = '';
    }
  }
  getLegalEntityList(businessTypeId: number, ispopulate: boolean): void {
    this.isLoading = true;
    this.analysisPackService
      .getAllLegalEntityByBusinessType(businessTypeId)
      .subscribe(
        (response) => {
          if (response != null) this.legalEntities = response.slice();
          if (ispopulate)
            this.selectedLegalEntity = this.legalEntities.find(
              (item) => item.ID == this.parentLEdetails.ID
            );
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  cancelClick() {
    this.dialog.close();
  }
  savePack() {
    var formObject = this.addPack.getRawValue();

    let pack = {
      StatementGroupId: 0,
      LegalEntityId: formObject.LegalEntity.ID,
      StatementGroupTypeId: 1,
      Description: formObject.packDescription,
      BusinessTypeId: formObject.businessType.BusinessTypeId,
      HelpId: '',
    };

    this.analysisPackService.saveAnalysisPack(pack).subscribe(
      (response: any) => {
        //this.isNextVisible = true;
        if (response == 'Analysis Pack added successfully') {
          this.toastr.successToastr('Analysis pack added successfully', 'Success');
          this.displaytreePack();
        } else if (response == 'Analysis Pack Name already exists') {
          this.toastr.errorToastr('Analysis pack name already exists', 'Failed');
          this.dialog.close();
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.dialog.close();
        }
      },
      (error: any) => {
        console.log(error);
      },
      () => console.log('Success')
    );
  }
  displaytreePack() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }
}
