<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Movement journal</div>
</kendo-dialog-titlebar>
<div>
  You are about to create {{ this.transactionsList.length }} journal(s) from<b>
    {{ this.transactionsList[0].TaxAccount }}
  </b>
  line item to <b> {{ this.targetLineItemDescription.trim() }}</b
  >, are you sure you wish to continue?
</div>
<div class="clickToActions" style="padding: 10px 0px 0px 0px">
  <ap-button
    [btnType]="'secondary'"
    [label]="'Cancel'"
    (click)="cancelClick()"
    class="cancelButton"
    style="width: 100px"
  ></ap-button>
  <ap-button
    [btnType]="'primary'"
    [label]="'OK'"
    themeColor="primary"
    (click)="moveProceed()"
    class="okButton"
    style="margin-right: 6px"
  ></ap-button>
</div>
