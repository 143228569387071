import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { GlobalService } from '../globalservice/globalservice.service';
import { MyGlobalObject } from '../myglobalobject';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  userDetails: any = null;
  constructor(
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private router: Router,
    public myGlobalObject: MyGlobalObject,
    private cookieService: CookieService
  ) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const url = state.url;
    const str = url.split('/');
    const page = str[1];
    return this.checkAuthorization(page);
  }
  async checkAuthorization(page: any): Promise<boolean> {
    let result = false;
    let cookie: any = localStorage.getItem('OPENAMSESSION');
    if (
      cookie == 'undefined' ||
      cookie == null ||
      cookie === '' ||
      cookie === 'null'
    ) {
      // alert('auth');
      // alert(localStorage.getItem('isVisited'));
      if (
        !localStorage.getItem('isVisited') ||
        localStorage.getItem('isVisited') == null
      ) {
        const response = await this.authenticationService
          .checkAuthentication()
          .toPromise();
        localStorage.setItem('OPENAMSESSION', JSON.stringify(response));
        localStorage.setItem('tokenCreated', Date.now().toString());
      }
    }
    if (typeof this.myGlobalObject.userDetailsEnlightenPortal === 'undefined') {
      await this.globalService
        .getLoggedUserInfo()
        .then((response) => {
          if (response) {
            if (
              response == 'Super Admin' ||
              response == 'Admin' ||
              response == 'User'
            ) {
              this.myGlobalObject.userDetailsEnlightenPortal = response;
              result = true;
            }
          }
        })
        .catch((error) => {
          //  User not present in M&R database
          this.router.navigate(['/unauthorized']);
          result = false;
        });
    } else {
      result = true;
    }
    return result;
  }
}
