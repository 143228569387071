import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AddEvent,
  CancelEvent,
  EditEvent,
  RemoveEvent,
  SaveEvent,
  TreeListComponent,
} from '@progress/kendo-angular-treelist';
import {
  DialogRef,
  DialogContentBase,
  ActionsLayout,
  DialogService,
  DialogAction,
  DialogCloseResult,
  DialogResult,
} from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';

import { KeywordData, keywordTotalData } from 'src/app/_models/keywordData';
import { KeywordGroup } from 'src/app/_models/keywordGroup';
import { KeywordGroupFolder } from 'src/app/_models/keywordGroupFolder';
import { Keyword } from 'src/app/_models/keyword';
import { ToastrManager } from 'ng6-toastr-notifications';
import { KeywordDeleteData } from 'src/app/_models/keywordDeleteData';
import { KeywordImportComponent } from '../keyword-import/keyword-import.component';
import { KeywordExportComponent } from '../keyword-export/keyword-export.component';
import { DeleteKeywordGroupComponent } from '../delete-keyword/delete-keyword.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-keyword-group-form-list',
  templateUrl: './keyword-group-form-list.component.html',
  styleUrls: ['./keyword-group-form-list.component.scss'],
})
export class KeywordGroupFormListComponent implements OnInit {
  public rootData!: Observable<KeywordData[]>;
  formGroup!: FormGroup;
  public editedItem!: KeywordData;

  constructor(
    private dialogService: DialogService,
    private globalService: GlobalService,
    public toastr: ToastrManager,
    private router: Router
  ) {}
  keywordData: KeywordData[] = [];
  keywordDataChildren: KeywordData[] = [];
  keywordGroupFolders: KeywordGroupFolder[] = [];
  keywordGroups: KeywordGroup[] = [];
  keywordGroupFolder!: KeywordGroupFolder;
  keyword!: Keyword;
  public keywordDeleteData!: KeywordDeleteData;
  public expandedNames!: any[];
  selectedId: number = 0;

  isLoading: boolean = false;

  public ngOnInit(): void {
    this.getAllKeywordsData();
  }

  resetAllKeyWordData(): void {
    this.keywordData = [];
  }

  resetKeywordData(): void {
    this.keyword = {
      vocabularyGroupId: 0,
      vocabularyId: 0,
      description: '',
    };
  }

  resetKeywordGroupFolderData(): void {
    this.keywordGroupFolder = {
      Name: '',
      VocabularyGroupFolderId: 0,
    };
  }

  getAllKeywordsData(): void {
    this.resetAllKeyWordData();
    this.isLoading = true;
    this.globalService.getAllKeywordsData().subscribe(
      (response) => {
        this.keywordData = response;
        if (this.selectedId != 0) {
          this.expandedNames = [this.selectedId];
        }

        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  public addHandler({ sender, parent = '' }: AddEvent): void {
    if (parent.level == 'Keyword') {
      this.toastr.errorToastr(
        'Adding new entry not allowed in keyword level.',
        'Error',
        { timeOut: 2000 }
      );
      return;
    }
    // Close the current edited row, if any.
    this.closeEditor(sender);

    // Expand the parent.
    if (parent) {
      sender.expand(parent);
    }

    this.formGroup = new FormGroup({
      description: new FormControl('', Validators.required),
    });

    // Show the new row editor, with the `FormGroup` build above
    sender.addRow(this.formGroup, parent);
  }

  public editHandler({ sender, dataItem }: EditEvent): void {
    // Close the current edited row, if any.
    this.closeEditor(sender, dataItem);

    // Define all editable fields validators and default values
    this.formGroup = new FormGroup({
      description: new FormControl(dataItem.description, Validators.required),
    });

    this.editedItem = dataItem;

    // Put the row in edit mode, with the `FormGroup` build above
    sender.editRow(dataItem, this.formGroup);
  }

  public cancelHandler({ sender, dataItem, isNew }: CancelEvent): void {
    // Close the editor for the given row
    this.closeEditor(sender, dataItem, isNew);
  }

  public saveHandler({
    sender,
    dataItem,
    parent = '',
    formGroup,
    isNew = false,
  }: SaveEvent): void {
    if (isNew && parent == '') {
      this.resetKeywordGroupFolderData();
      this.keywordGroupFolder.Name = formGroup.value.description;
      this.globalService.createKeywordFolder(this.keywordGroupFolder).subscribe(
        (response) => {
          if (response == 'The Keyword group folder is created successfully') {
            this.toastr.successToastr('The keyword group folder is created successfully', 'Success');
            sender.closeRow(dataItem, isNew);
            this.getAllKeywordsData();
          } else {
            this.toastr.errorToastr(response, 'Error', { timeOut: 2000 });
            return;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else if (isNew && parent != '') {
      this.resetKeywordGroupFolderData();
      if (parent.level === 'Folder') {
        this.keywordGroupFolder.Name = formGroup.value.description;
        this.keywordGroupFolder.VocabularyGroupFolderId = parent.id;
        this.globalService
          .CreateKeywordGroup(this.keywordGroupFolder)
          .subscribe(
            (response) => {
              if (response == 'The keyword group is created successfully.') {
                this.toastr.successToastr('The keyword group is created successfully.', 'Success');
                this.getAllKeywordsData();
              } else {
                this.toastr.errorToastr(response, 'Error', { timeOut: 20000 });
                return;
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } else if (parent.level === 'Group') {
        this.keywordGroupFolder.Name = formGroup.value.description;
        this.keywordGroupFolder.VocabularyGroupFolderId = parent.id;
        this.globalService.CreateKeyword(this.keywordGroupFolder).subscribe(
          (response) => {
            if (response == 'The keyword is created successfully') {
              this.toastr.successToastr(response, 'Success');
              this.getAllKeywordsData();
            } else {
              this.toastr.errorToastr(response, 'Error', { timeOut: 2000 });
              return;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }

    if (!isNew) {
      if (dataItem.level === 'Folder') {
        this.resetKeywordGroupFolderData();
        this.keywordGroupFolder.Name = formGroup.value.description;
        this.keywordGroupFolder.VocabularyGroupFolderId = dataItem.id;
        this.globalService.editKeyWordFolder(this.keywordGroupFolder).subscribe(
          (response) => {
            if (response == 'The keyword group folder updated successfully.') {
              this.toastr.successToastr(response, 'Success');
              this.getAllKeywordsData();
            } else {
              this.toastr.errorToastr(response, 'Error', { timeOut: 2000 });
              return;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } else if (dataItem.level === 'Group') {
        this.resetKeywordData();
        this.keyword.vocabularyGroupId = dataItem.parentId;
        this.keyword.vocabularyId = dataItem.id;
        this.keyword.description = formGroup.value.description;

        this.globalService.editKeywordGroup(this.keyword).subscribe(
          (response) => {
            if (response == 'The keyword group updated successfully.') {
              this.toastr.successToastr(response, 'Success');
              this.getAllKeywordsData();
            } else {
              this.toastr.errorToastr(response, 'Error', { timeOut: 2000 });
              return;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } else if (dataItem.level === 'Keyword') {
        this.resetKeywordData();
        this.keyword.vocabularyGroupId = dataItem.parentId;
        this.keyword.vocabularyId = dataItem.id;
        this.keyword.description = formGroup.value.description;

        this.globalService.editKeyword(this.keyword).subscribe(
          (response) => {
            if (response == 'The keyword updated successfully.') {
              this.toastr.successToastr(response, 'Success');
              this.getAllKeywordsData();
            } else {
              this.toastr.errorToastr(response, 'Error', { timeOut: 2000 });
              return;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }

  public onExpandedNamesChange(names: any[]): void {
    if (names.length > 0) {
      this.selectedId = names[0];
    }
  }

  public removeHandler({ sender, dataItem, parent }: RemoveEvent): void {
    this.resetKeywordDeleteData();
    const dialogRef = this.dialogService.open({
      content: DeleteKeywordGroupComponent,
      width: 500,
      height:'auto'
    });
    const deleteKeywordGroupDetails = dialogRef.content.instance as DeleteKeywordGroupComponent;
    dialogRef.result.subscribe((r: any) => {
      this.getAllKeywordsData();
    });
    deleteKeywordGroupDetails.dataItem = dataItem;

    // if (dataItem.level === 'Folder') {
    //   this.keywordDeleteData.level = dataItem.level;
    //   this.keywordDeleteData.id = dataItem.id;
    //   this.keywordDeleteData.title = 'Delete ' + (this.keywordDeleteData.level).toLowerCase();
    //   this.keywordDeleteData.message =
    //     "You have selected to permanently delete the selected folder. Any keyword groups and keywords within the folder will also be deleted. Are you sure you wish to continue?";
    // } else if (dataItem.level === 'Group') {
    //   this.keywordDeleteData.level = dataItem.level;
    //   this.keywordDeleteData.id = dataItem.id;
    //   this.keywordDeleteData.title = 'Delete ' + (this.keywordDeleteData.level).toLowerCase();
    //   this.keywordDeleteData.message =
    //     'You have selected to permanently delete the selected keyword group(s). Any keywords associated with the group will also be deleted. Are you sure you wish to continue?';
    // } else if (dataItem.level === 'Keyword') {
    //   this.keywordDeleteData.level = dataItem.level;
    //   this.keywordDeleteData.id = dataItem.id;
    //   this.keywordDeleteData.title = 'Delete ' + (this.keywordDeleteData.level).toLowerCase();
    //   this.keywordDeleteData.message =
    //     'You have selected to permanently delete the selected keywords.  Are you sure you wish to continue?';
    // }

    // var dialog = this.dialogService.open({
    //   title: this.keywordDeleteData.title,
    //   content: this.keywordDeleteData.message,
    //   width: 350,
    //   height: 230,
    //   actions: [{ text: 'No' }, { text: 'Yes', themeColor: 'light' }],
      
    // });

    // dialog.result.subscribe((result) => {
    //   if (result instanceof DialogCloseResult) {
    //   } else {
    //     if (result.text == 'Yes') {
    //       if (this.keywordDeleteData.level === 'Folder') {
    //         this.globalService
    //           .DeleteKeywordGroupFolder(this.keywordDeleteData)
    //           .subscribe(
    //             (response) => {
    //               if (
    //                 response == 'keyword Group Folder is deleted successfully'
    //               ) {
    //                 this.getAllKeywordsData();
    //                 this.toastr.successToastr('keyword group folder is deleted successfully', 'Success');
    //               } else {
    //                 this.toastr.errorToastr(response, 'Error');
    //                 return;
    //               }
    //             },
    //             (error) => {
    //               console.log(error);
    //             }
    //           );
    //       } else if (this.keywordDeleteData.level === 'Group') {
    //         this.globalService
    //           .DeleteKeywordGroup(this.keywordDeleteData)
    //           .subscribe(
    //             (response) => {
    //               if (response == 'keyword Group is deleted successfully.') {
    //                 this.getAllKeywordsData();
    //                 this.toastr.successToastr('keyword group is deleted successfully.', 'Success');
    //               } else {
    //                 this.toastr.errorToastr(response, 'Error');
    //                 return;
    //               }
    //             },
    //             (error) => {
    //               console.log(error);
    //             }
    //           );
    //       } else if (this.keywordDeleteData.level === 'Keyword') {
    //         this.globalService.DeleteKeyword(this.keywordDeleteData).subscribe(
    //           (response) => {
    //             if (response == 'keyword is deleted successfully.') {
    //               this.getAllKeywordsData();
    //               this.toastr.successToastr(response, 'Success');
    //             } else {
    //               this.toastr.errorToastr(response, 'Error');
    //               return;
    //             }
    //           },
    //           (error) => {
    //             console.log(error);
    //           }
    //         );
    //       }
    //     }
    //   }
    // });
  }

  resetKeywordDeleteData(): void {
    this.keywordDeleteData = {
      id: 0,
      message: '',
      level: '',
      title: '',
    };
  }

  private closeEditor(
    treelist: TreeListComponent,
    dataItem: any = this.editedItem,
    isNew = false
  ): void {
    treelist.closeRow(dataItem, isNew);
  }

  public importHandler(event: any) {
    this.router.navigate(['/manageKeywordImport']);
  }

  public exportHandler(event: any) {
    this.router.navigate(['/manageKeywordExport']);
  }
}
