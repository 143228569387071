import { Component, OnInit,Input } from '@angular/core';
import { DialogContentBase, ActionsLayout, DialogAction} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DialogService, DialogRef,DialogCloseResult} from '@progress/kendo-angular-dialog';
import { HabitatService } from '../../common/services/habitatservice/habitat.service';
import { HabitatsComponent } from '../habitats.component';
@Component({
  selector: 'app-reassign-habitats',
  templateUrl: './reassign-habitats.component.html',
  styleUrls: ['./reassign-habitats.component.scss']
})
export class ReassignHabitatsComponent implements OnInit {

  @Input() public reassignData: any;

  dialogTitle:any="Delete Habitat's";
  confirmText:any;
  warnondelete:boolean=false;
  public actionsLayout: ActionsLayout = 'normal';

  delHabitatDetails: any;
  constructor(  public dialog: DialogRef,   public toastr: ToastrManager,
    private dialogService: DialogService,
    private habitatService: HabitatService) {  }

  ngOnInit(): void {
  }

  cancelClick(){
    this.dialog.close();
  }

  reassignHabitat(){
  
    this.habitatService.reassignHabitat(this.reassignData).then((res: any) => {    
 
      if(res == "Successfully reassigned child accounts"){
        this.toastr.successToastr(
          res,
          'Success'
        );
        this.dialog.close();
      
      }
      
      else{
        this.toastr.errorToastr('Something went wrong try after sometime', 'Oops!');
      }
      this.dialog.close();
    }).catch((err: any) => {
      console.log(err)
      
    })
    
  }

}
