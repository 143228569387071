import { Component, OnInit } from '@angular/core';
import { StatementDetailService } from '../common/services/statementDetail/statementDetail-service';
import { StatementDetailExportType } from '../_models/statementDetailExportType';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OfflineReferralService } from '../common/services/offlineReferral/offlineReferral-service';
import { GlobalService } from '../common/globalservice/globalservice.service';
@Component({
  selector: 'app-gl-account-run',
  templateUrl: './gl-account-run.component.html',
  styleUrls: ['./gl-account-run.component.scss'],
})
export class GlAccountRunComponent implements OnInit {
  statementDetailExportForm!: FormGroup;
  public mySelectionMasterDataSelected: any[] = [];
  taxAccountsSelected = [];
  businessTypes: StatementDetailExportType[] = [];
  selectedbusinessType: StatementDetailExportType | undefined;
  selectedbusinessTypeId: number = 0;
  legalEntities: StatementDetailExportType[] = [];
  selectedLegalEntity: StatementDetailExportType | undefined;
  selectedLegalEntityId: number = 0;
  analysisPacks: StatementDetailExportType[] = [];
  selectedAnalysisPack: StatementDetailExportType | undefined;
  selectedAnalysisPackId: number = 0;
  statements: StatementDetailExportType[] = [];
  isLoading: boolean = false;
  public value: any;
  public isChecked = false;
  public exportLineItemValue: boolean = false;
  private selectedClient: any;
  processingOrchestrationId: string = '';
  isExportSuccess: boolean = false;
  exportProcessingErrorMessage: string = '';
  selectedStatementIds: number[] = [];
  periods: number[] = [];
  // subTotalValue: any;
  selectedFiscalYear: number | undefined;
  fiscalYears: number[] = [];
  columnData: any[] = [];
  rightColumn: any[] = [];
  periodArrayResponse: any;
  fiscalYear: any;
  subtotaldata: boolean = true;
  periodDisabled = false;
  activeState: any;
  subTotalDisabled = true;
  constructor(
    private fb: FormBuilder,
    private statementDetailService: StatementDetailService,
    private OfflineReferralService: OfflineReferralService,
    private globalService: GlobalService
  ) {}

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  ngOnInit(): void {
    this.setupForm();
    this.getBusinessTypes();
    this.getFiscalYears();
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    this.globalService.GetPeriodsForGLAccountReport().then((response: any) => {
      this.periodArrayResponse = response;
    });
  }

  setupForm(): void {
    this.statementDetailExportForm = this.fb.group({
      businessType: '',
      legalEntity: '',
      analysisPack: '',
      statementOptions: '',
    });
  }

  public isItemSelected(item: StatementDetailExportType) {
    return this.value.some(
      (x: StatementDetailExportType) => x.LookUpId === item.LookUpId
    );
  }

  public onClick() {
    this.isChecked = !this.isChecked;
    this.value = this.isChecked ? this.statements : [];
  }

  public onValueChange(event: any) {
    this.value = event;
  }

  handleBusinessTypeChange(businessType: StatementDetailExportType): void {
    this.selectedbusinessType = businessType;
    this.selectedLegalEntity = undefined;
    this.legalEntities = [];
    this.selectedbusinessTypeId = 0;
    this.selectedLegalEntityId = 0;
    this.selectedAnalysisPackId = 0;
    this.selectedAnalysisPack = undefined;
    this.analysisPacks = [];

    this.statements = [];
    if (this.selectedbusinessType != undefined) {
      this.selectedbusinessTypeId = this.selectedbusinessType.LookUpId;
      this.getLegalEntities(businessType.LookUpId);
    }
  }

  handleLegalEntityChange(legalEntity: StatementDetailExportType): void {
    this.selectedLegalEntity = legalEntity;
    this.selectedAnalysisPack = undefined;
    this.selectedAnalysisPackId = 0;
    this.analysisPacks = [];
    this.statements = [];
    this.value = '';
    if (this.selectedLegalEntity != undefined) {
      this.selectedLegalEntityId = legalEntity.LookUpId;
      this.getAnalysisPacks(this.selectedbusinessTypeId, legalEntity.LookUpId);
    }
  }

  handleAnalysisPackChange(analysispack: StatementDetailExportType): void {
    this.selectedAnalysisPack = analysispack;
    this.statements = [];
    this.value = '';
    if (this.selectedAnalysisPack != undefined) {
      this.selectedAnalysisPackId = analysispack.LookUpId;
      this.getStatements(analysispack.LookUpId);
    }
  }

  getBusinessTypes(): void {
    this.isLoading = true;
    this.businessTypes = [];
    this.statementDetailService.getAllBusinessTypeDetail().subscribe(
      (response) => {
        this.businessTypes = response;
        if (this.businessTypes.length == 1) {
          this.selectedbusinessType = this.businessTypes[0];
          this.handleBusinessTypeChange(this.selectedbusinessType);
        }
        if (this.businessTypes.length == 1) {
          this.selectedbusinessType = this.businessTypes[0];
          this.handleBusinessTypeChange(this.selectedbusinessType);
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getLegalEntities(businessTypeId: number): void {
    this.isLoading = true;
    this.legalEntities = [];
    this.statementDetailService
      .GetLegalEntitiesByBusinessTypeId(businessTypeId)
      .subscribe(
        (response) => {
          this.legalEntities = response;
          if (this.legalEntities.length == 1) {
            this.selectedLegalEntity = this.legalEntities[0];
            this.handleLegalEntityChange(this.selectedLegalEntity);
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  getAnalysisPacks(businessTypeId: number, legalEntityId: number): void {
    this.isLoading = true;
    this.analysisPacks = [];
    this.statementDetailService
      .GetStatementGroupsByBusTypeAndLegalEntityId(
        businessTypeId,
        legalEntityId
      )
      .subscribe(
        (response) => {
          this.analysisPacks = response;
          if (this.analysisPacks.length == 1) {
            this.selectedAnalysisPack = this.analysisPacks[0];
            this.handleAnalysisPackChange(this.selectedAnalysisPack);
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  getStatements(statementGroupId: number): void {
    this.isLoading = true;
    this.statements = [];
    this.statementDetailService
      .GetAnalysisStatementsForStatementGroupId(statementGroupId)
      .subscribe(
        (response) => {
          this.statements = response;
          if (this.statements.length == 1) {
            this.value = this.statements[0];
            this.onValueChange(this.value);
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  exportExcelRun() {
    this.isLoading = true;
    let busTypeId = this.selectedbusinessType?.LookUpId
      ? this.selectedbusinessType.LookUpId
      : 0;
    let legalEntityId = this.selectedLegalEntity?.LookUpId
      ? this.selectedLegalEntity.LookUpId
      : 0;
    let statementID = this.value?.LookUpId ? this.value.LookUpId : 0;
    let periodArrayData = JSON.parse(JSON.stringify(this.periodArrayResponse));
    let requestData = periodArrayData.map((i: any) => {
      if (i && i.id && i.position) {
        delete i['id'];
        delete i['position'];
        return i;
      } else {
        return i;
      }
    });
    this.globalService
      .generateGLAccountReportRun(
        busTypeId,
        legalEntityId,
        statementID,
        requestData
      )
      .subscribe((response) => {
        const fileName = 'GL account report.xlsx';
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.isLoading = false;
      });
  }
  subTotal(): void {
    let subTotalValue = {
      Name: 'SubTotal',
      DefaultItem: false,
      MandatoryItem: false,
      TableField: 'SubTotal',
      PropertyName: 'SubTotal',
      position: 'up',
      id: new Date().getTime(),
    };
    this.subTotalDisabled = true;
    let index = this.periodArrayResponse.findIndex(
      (i: any) => i.Name == this.activeState.Name
    );
    if (index !== -1) {
      this.periodArrayResponse.splice(index, 0, subTotalValue);
    }
  }
  handleFiscalYearChange(fiscalYear: number): void {
    this.selectedFiscalYear = fiscalYear;
    this.legalEntities = [];
    this.periods = [];
    this.selectedLegalEntity = undefined;
    if (fiscalYear != undefined) {
      this.getPeriodsByFiscalYear(fiscalYear);
    } else {
      this.legalEntities = [];
      this.periods = [];
    }
  }
  getFiscalYears(): void {
    this.isLoading = true;
    this.fiscalYears = [];
    this.OfflineReferralService.getFiscalYearForOfflineReferral().subscribe(
      (response) => {
        this.fiscalYears = response;
        if (this.fiscalYears.length > 0) {
          let year: any = localStorage.getItem('selectedFiscalYear');
          this.selectedFiscalYear = JSON.parse(year).FiscalYearId;
          if (this.fiscalYears.indexOf(Number(this.selectedFiscalYear)) == -1) {
            this.selectedFiscalYear = undefined;
          } else {
            this.handleFiscalYearChange(Number(this.selectedFiscalYear));
          }
        }
      }
    );
  }
  getPeriodsByFiscalYear(fiscalYear: number): void {
    this.isLoading = true;
    this.periods = [];
    this.OfflineReferralService.getPeriodsByFiscalYear(fiscalYear).subscribe(
      (response) => {
        this.periods = response;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
  onMoveDownButtonClick(): void {
    if (
      this.activeState &&
      this.activeState.Name == 'SubTotal' &&
      this.activeState.position == 'up'
    ) {
      let periodIndex = this.periodArrayResponse.findIndex((i: any) => {
        if (i && i.id && i.id == this.activeState.id) {
          return true;
        } else {
          return false;
        }
      });
      let temp = this.periodArrayResponse[periodIndex + 1];
      this.periodArrayResponse[periodIndex + 1] =
        this.periodArrayResponse[periodIndex];
      this.periodArrayResponse[periodIndex] = temp;
      this.periodArrayResponse[periodIndex + 1].position = 'down';
    }
  }
  onMoveUpButtonClick(): void {
    if (
      this.activeState &&
      this.activeState.Name == 'SubTotal' &&
      this.activeState.position == 'down'
    ) {
      let periodIndex = this.periodArrayResponse.findIndex((i: any) => {
        if (i && i.id && i.id == this.activeState.id) {
          return true;
        } else {
          return false;
        }
      });
      let temp = this.periodArrayResponse[periodIndex - 1];
      this.periodArrayResponse[periodIndex - 1] =
        this.periodArrayResponse[periodIndex];
      this.periodArrayResponse[periodIndex] = temp;
      this.periodArrayResponse[periodIndex - 1].position = 'up';
    }
  }

  getPeriodValue(period: any) {
    this.periodDisabled = true;

    this.activeState = period;
    if (period.Name == 'SubTotal') {
      this.periodDisabled = false;
      // this.subTotalDisabled = true;
    }
    if (period.Name !== 'SubTotal') {
      // this.periodDisabled = false;
      this.subTotalDisabled = false;
    }
    // if
  }
}
