import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MyGlobalObject } from '../../myglobalobject';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { promise } from 'protractor';
import { TaxAccount } from 'src/app/_models/taxAccount';

@Injectable({
  providedIn: 'root',
})
export class AutomatchtransactionsService {
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private apiHost: string;
  private automatchAPIUrl: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.automatchAPIUrl = environment.importapiUrl;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getautomatchcolumns: '/ManageTransaction/GetAutoMatchColumns',
      validateAutomatch: '/ManageTransaction/ValidateAutoMatchAllTransaction',
      autoMatchAllTransactionsProcessingStart:
        '/AutoMatchAllTransactionsProcessingStart',
      getAutoMatchAllTransactionsProcessingStatus:
        '/GetAutoMatchAllTransactionsProcessingStatus/',
      downloadAutoMatchAllTransactionsExcelFile:
        '/DownloadAutoMatchAllTransactionsExcelFile',
    };
  }
  getautomatchcolumns(): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getautomatchcolumns;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  validateAutomatch(taxAccountID: number, fiscalYear: number): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('taxAccountID', taxAccountID)
      .set('fiscalYear', fiscalYear);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.validateAutomatch;

    return this.http.post(this.apiHost, headers, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'json' as 'text',
    });
  }

  autoMatchAllTransactionsProcessingStart(startPayload: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    //this.apiHost="https://wezehahzadap004.azurewebsites.net/api/AutoMatchAllTransactionsProcessingStart";
    this.apiHost =
      this.automatchAPIUrl +
      this.apifolder +
      this.apiUrl.autoMatchAllTransactionsProcessingStart;
    return this.http.post(this.apiHost, startPayload, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  getAutoMatchAllTransactionsProcessingStatus(instanceId: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);
    //this.apiHost="https://wezehahzadap004.azurewebsites.net/api/GetAutoMatchAllTransactionsProcessingStatus/"+instanceId;
    this.apiHost =
      this.automatchAPIUrl +
      this.apifolder +
      this.apiUrl.getAutoMatchAllTransactionsProcessingStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
    });
  }

  downloadAutoMatchAllTransactionsExcelFile(
    generatedExportFileName: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('filename', generatedExportFileName);
    this.apiHost =
      this.automatchAPIUrl +
      this.apifolder +
      this.apiUrl.downloadAutoMatchAllTransactionsExcelFile;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'text',
    });
  }
}
