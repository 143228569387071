<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>

<!-- Header -->
<div class="header">Export analysis statements</div>
<div id="mainContent" class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <div class="subHeader">Analysis pack and tax engine selection</div>
      <p style="font-size: 14px">
        Select an analysis pack to export and the tax engine to export to:
      </p>
      <!-- <div class="col-12"> -->

      <form class="k-form" style="margin-top: 10px" [formGroup]="exportForm">
        <b style="font-size: 14px; margin: 10px 0px">Analysis pack</b>
        <div class="row">
          <div class="col-6">
            <kendo-formfield>
              <kendo-label [for]="fiscalYear" text="Fiscal year*">
              </kendo-label>
              <kendo-combobox
                #fiscalYear
                [kendoDropDownFilter]="filterSettings"
                formControlName="fiscalYear"
                [(ngModel)]="selectedFiscalYear"
                [data]="fiscalYears"
                textField="Display"
                valueField="FiscalYearId"
                placeholder="Select fiscal year"
                (valueChange)="handleFiscalYearChange($event)"
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>
          <div class="col-6">
            <kendo-formfield>
              <kendo-label text="Business type*"> </kendo-label>
              <kendo-combobox
                #businessType
                formControlName="businessType"
                [(ngModel)]="selectedBusinessType"
                [data]="businessTypes"
                textField="Description"
                valueField="BusinessTypeId"
                [kendoDropDownFilter]="filterSettings"
                placeholder="Select business type"
                (valueChange)="handleBusinessTypeChange($event)"
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <kendo-formfield>
              <kendo-label text="Legal entity*"> </kendo-label>
              <kendo-combobox
                #legalEntity
                formControlName="legalEntity"
                [data]="legalEntities"
                [(ngModel)]="selectedLegalEntity"
                textField="Description"
                [kendoDropDownFilter]="filterSettings"
                valueField="ID"
                placeholder="Select legal entity"
                (valueChange)="handleLegalEntityChange($event)"
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>
          <div class="col-6">
            <kendo-formfield>
              <kendo-label [for]="analysisPack" text="Analysis pack*">
              </kendo-label>
              <kendo-combobox
                #analysisPack
                [kendoDropDownFilter]="filterSettings"
                formControlName="analysisPack"
                [(ngModel)]="selectedAnalysisPack"
                [data]="analysispacks"
                valueField="LookUpId"
                textField="LookUpDescription"
                placeholder="Select analysis pack"
                (valueChange)="handleAnalysisPackChange($event)"
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>
        </div>
        <div class="row" style="margin-bottom: 10px">
          <kendo-formfield>
            <ap-checkbox
              #taxchecked
              formControlName="taxchecked"
              (onChange)="taxCheckBox($event)"
            >
              <span>Include tax outcomes</span>
            </ap-checkbox>

            <ap-checkbox (onChange)="exportCheckBox($event)">
              <span>Export transactions for all tax accounts</span>
            </ap-checkbox>
          </kendo-formfield>
        </div>
        <div class="row" style="margin-top: -15px !important">
          <div class="col-6">
            <kendo-formfield>
              <kendo-label [for]="taxEngine" text="Output format*">
              </kendo-label>
              <kendo-combobox
                #taxEngine
                [kendoDropDownFilter]="filterSettings"
                formControlName="taxEngine"
                [(ngModel)]="selectedTaxEngine"
                [data]="taxengines"
                valueField="ID"
                textField="Description"
                placeholder="Select output format"
                (valueChange)="handleTaxEngineChange($event)"
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>
        </div>
      </form>
      <!-- </div> -->

      <div
        class="clickToActions fixed-bottom"
        style="padding: 10px 0px 0px 0px; right: 1.5%; bottom: 1%"
      >
        <ap-button
          kendoButton
          [btnType]="'secondary'"
          [label]="'Close'"
          (click)="close()"
          class="cancelButton"
          style="margin-left: 7px; width: 100px"
        ></ap-button>

        <ap-button
          kendoButton
          [btnType]="'primary'"
          [label]="'Export'"
          style="margin-left: 7px; width: 100px"
          (onClick)="export()"
          [disabled]="
            taxEngine.value == undefined ||
            analysisPack.value == undefined ||
            legalEntity.value == undefined ||
            businessType.value == undefined ||
            fiscalYear.value == undefined
          "
        ></ap-button>
      </div>
    </div>
  </div>
</div>
