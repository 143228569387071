import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CancelEvent,
  CreateFormGroupArgs,
  RowArgs,
  SaveEvent,
  SelectionEvent,
} from '@progress/kendo-angular-grid';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { AdministrationService } from 'src/app/common/services/administration/administration.service';
import { TransactionCustomColumn } from 'src/app/_models/transactionCustomColumnModel';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-manage-custom-column',
  templateUrl: './manage-custom-column.component.html',
  styleUrls: ['./manage-custom-column.component.scss'],
})
export class ManageCustomColumnComponent implements OnInit {
  constructor(
    public toastr: ToastrManager,
    private adminService: AdministrationService,
    private formBuilder: FormBuilder
  ) {
    this.createFormGroup = this.createFormGroup.bind(this);
  }

  item: TransactionCustomColumn | undefined;
  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    const itemdata = args.isNew ? this.item : args.dataItem;

    this.formGroup = this.formBuilder.group({
      Id: itemdata?.Id,
      ColumnNumber: itemdata?.ColumnNumber,
      ColumnName: [itemdata?.ColumnName, Validators.required],
      Show: itemdata?.Show,
    });

    return this.formGroup;
  }

  ngOnInit(): void {
    this.getAllCustomColumn();
  }
  public customColumnId: any[] = [];
  public selectedcustomColumn: boolean = false;
  public customColumnList: any = [];
  public gridData: any = [];
  public isLoading: boolean = false;
  public formGroup: FormGroup | undefined;

  public onGridSelectionChange(event: SelectionEvent) {
    var count = event.selectedRows != undefined ? event.selectedRows.length : 0;
    for (let i = 0; i < count; i++) {
      var item =
        event.selectedRows != undefined
          ? event.selectedRows[i].dataItem._ID
          : '';

      this.customColumnId.push(item);
    }
    count = event.deselectedRows != undefined ? event.deselectedRows.length : 0;
    for (let i = 0; i < count; i++) {
      var item =
        event.deselectedRows != undefined
          ? event.deselectedRows[i].dataItem._ID
          : '';
      var index = this.customColumnId.map((x) => x._ID).indexOf(item);
      this.customColumnId.splice(index, 1).slice(0);
    }
  }

  public onCustomColumnchange(event: any) {
    this.selectedcustomColumn = event.checkboxState;
  }

  public onFilter(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.customColumnList = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'Column',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Name',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'ColumnType',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      let response = this.getAllCustomColumn();
    }
  }

  public getAllCustomColumn() {
    this.isLoading = true;
    this.adminService
      .getCustomColumns()
      .then((response: any[]) => {
        this.customColumnList = response;

        this.gridData = response;
        this.isLoading = false;
      })
      .catch((err: any) => {
        this.isLoading = false;
      });
  }

  public sort: SortDescriptor[] = [
    {
      field: 'Id',
      dir: 'asc',
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.customColumnList = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }

  public saveChanges(args: SaveEvent) {}

  public cancelHandler(args: CancelEvent): void {
    args.sender.closeRow(args.rowIndex);
  }

  public saveHandler(args: SaveEvent): void {
    if (args.formGroup.valid) {
      let customColumn = {
        ColumnNumber: args.formGroup.value.ColumnNumber,
        ColumnName: args.formGroup.value.ColumnName,
        Show: args.dataItem.Show,
        StaticColumn: args.dataItem.StaticColumn,
        ColumnType: args.dataItem.ColumnType,
        Id: args.formGroup.value.Id,
      };
      this.adminService.UpdateTransactionCustom(customColumn).subscribe(
        (res) => {
          if (res == 'Custom Column Update Successfully') {
            this.toastr.successToastr(res, 'Success');
            args.sender.closeRow(args.rowIndex);
            this.getAllCustomColumn();
          }
        },
        (error) => {
          this.toastr.errorToastr('Failed to Save Custom Columns', 'Error');
          console.log(error);
          args.sender.closeRow(args.rowIndex);
        }
      );
    }
  }

  public onHighlightClick(event: any) {
    if (event.Show) event.Show = false;
    else event.Show = true;
  }

  public UpdateEnableColumn() {
    let myCustomColumn: string = '';
    for (let i = 0; i < this.customColumnList.length; i++) {
      if (this.customColumnList[i].Show === true) {
        myCustomColumn += this.customColumnList[i].Id + ',';
      }
    }
    this.adminService.EnableTransactionCustom(myCustomColumn).subscribe(
      (res) => {
        if (res == 'Columns update successfully') {
          this.toastr.successToastr(res, 'Success');
          this.getAllCustomColumn();
        }
      },
      (error) => {
        this.toastr.errorToastr('Failed to Save Custom Columns', 'Error');
        console.log(error);
      }
    );
  }
}
