<kendo-dialog-titlebar (close)="closeButtonClick()">
  <div
    style="
      font-size: 18px;
      line-height: 1.1em;

      font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) 24px/24px
        var(--unnamed-font-family-helvetica-neue-lt-pro);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--default-color);
      text-align: left;
      font: normal normal medium 24px/24px Helvetica Neue LT Pro;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    "
  >
    Total offline allocations
  </div>
</kendo-dialog-titlebar>
<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>

<div class="row">
  <div class="col-md-12 col-xs-12">
    <kendo-grid
      [data]="offlineAccountSummary"
      scrollable="virtual"
      [sortable]="true"
      kendoGridSelectBy
      [(selectedKeys)]="mySelection"
      [resizable]="true"
      class="gridHeight"
      (selectionChange)="onGridSelectionChange($event)"
    >
      <kendo-grid-column
        [headerStyle]="{
          'font-weight': 'bold',
          color: '#d04a02',
          'text-align': 'center'
        }"
        field="Ref"
        title="Ref"
        [width]="50"
      >
      </kendo-grid-column>
      <kendo-grid-column
        field="FiscalYear"
        title="Fiscal year"
        [width]="50"
        [headerStyle]="{
          'font-weight': 'bold',
          'text-align': 'center',
          color: '#d04a02'
        }"
      ></kendo-grid-column>
      <kendo-grid-column
        field="ExportFileName"
        title="Export filename"
        [width]="180"
        [headerStyle]="{
          'font-weight': 'bold',
          'text-align': 'center',
          color: '#d04a02'
        }"
      ></kendo-grid-column>
      <kendo-grid-column
        field="ExportDate"
        title="Export date"
        [width]="50"
        [headerStyle]="{
          'font-weight': 'bold',
          'text-align': 'center',
          color: '#d04a02'
        }"
      ></kendo-grid-column>
      <kendo-grid-column
        field="Status"
        title="Status"
        [width]="50"
        [headerStyle]="{
          'font-weight': 'bold',
          'text-align': 'center',
          color: '#d04a02'
        }"
      ></kendo-grid-column>
      <kendo-grid-column
        field="IsSupplementary"
        title="Supplementary"
        [width]="50"
        [headerStyle]="{
          'font-weight': 'bold',
          'text-align': 'center',
          color: '#d04a02'
        }"
        [style]="{ 'text-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <input
            type="checkbox"
            kendoCheckBox
            [checked]="dataItem.IsSupplementary"
            (change)="IsSupplementaryChange($event, dataItem)"
          />
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
