import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import {
  AddEvent,
  CancelEvent,
  DataBindingDirective,
  EditEvent,
  GridComponent,
  GridDataResult,
  RemoveEvent,
  SaveEvent,
} from '@progress/kendo-angular-grid';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from '../common/globalservice/globalservice.service';
import { MyGlobalObject } from '../common/myglobalobject';

import { ClientselectpopupComponent } from '../clientselectpopup/clientselectpopup.component';
import { DeleteTaxoutcomesComponent } from './delete-taxoutcomes/delete-taxoutcomes.component';
import { NewOrEditTaxoutcomesComponent } from './new-or-edit-taxoutcomes/new-or-edit-taxoutcomes.component';
import { MergeTaxOutcomesComponent } from './merge-tax-outcomes/merge-tax-outcomes.component';
import { TaxBusinessOutcomeService } from 'src/app/common/services/taxbusinessoutcomeservice/tax-business-outcome.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  State,
  process,
  SortDescriptor,
  orderBy,
} from '@progress/kendo-data-query';

@Component({
  selector: 'app-taxoutcomes',
  templateUrl: './taxoutcomes.component.html',
  styleUrls: ['./taxoutcomes.component.scss'],
})
export class TaxoutcomesComponent implements OnInit {
  public view: Observable<GridDataResult> | undefined;
  public gridState: State = {
    sort: [],
  };
  public taxOutcomeForm: any;
  data: any;
  private editedRowIndex: number | undefined;
  action: any;
  public gridView: any;
  public actionsLayout: ActionsLayout = 'normal';
  public gridData: any = [];
  selectedClient: string | any;
  public mySelection: string[] = [];
  public defaultTaxOutcomeList: any[] = [];
  public selectedItem: any;
  searchText: string = '';
  constructor(
    private taxbusinessOutcome: TaxBusinessOutcomeService,
    private router: Router,
    public toastr: ToastrManager,
    private dialogService: DialogService
  ) {}

  public ngOnInit(): void {
    this.getAllTaxOutcomes();
    this.getDefaultTaxOutcome();
    this.selectedClient = localStorage.getItem('SELECTEDCLIENT');
  }

  public sort: SortDescriptor[] = [
    {
      field: 'ShortDescription',
      dir: 'asc',
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.gridView = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }

  public onStateChange(state: State): void {
    this.gridState = state;

    this.getAllTaxOutcomes();
  }

  public addHandler(args: AddEvent): void {
    this.closeEditor(args.sender);
    this.taxOutcomeForm = new FormGroup({
      ShortDescription: new FormControl(
        '',
        Validators.compose([
          Validators.minLength(1),
          Validators.maxLength(25),
          Validators.required,
          Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$'),
        ])
      ),
    });
    args.sender.addRow(this.taxOutcomeForm);
  }

  public editHandler(args: EditEvent): void {
    const { dataItem } = args;
    this.closeEditor(args.sender);

    this.taxOutcomeForm = new FormGroup({
      ShortDescription: new FormControl(
        dataItem.ShortDescription,
        Validators.compose([
          Validators.minLength(1),
          Validators.maxLength(25),
          Validators.required,
          Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$'),
        ])
      ),
    });
    this.data = dataItem;
    this.editedRowIndex = args.rowIndex;
    // put the row in edit mode, with the `FormGroup` build above
    args.sender.editRow(args.rowIndex, this.taxOutcomeForm);
  }

  public cancelHandler(args: CancelEvent): void {
    // close the editor for the given row
    this.closeEditor(args.sender, args.rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    if (isNew) {
      this.action = 'new';
    } else {
      this.action = 'edit';
    }
    this.saveBusinessTaxOutcome();
    sender.closeRow(rowIndex);
  }

  saveBusinessTaxOutcome() {
    if (this.action == 'edit') {
      let data = {
        _id: this.data._id,
        ShortDescription: this.taxOutcomeForm.controls.ShortDescription.value,
        LongDescription: this.taxOutcomeForm.controls.ShortDescription.value,
        RateAllowableCY: this.data.RateAllowableCY,
        RateAllowableEventually: this.data.RateAllowableEventually,
        PermanentTimingDiff: this.data.PermanentTimingDiff,
        HelpId: this.data.HelpId,
        DefaultOption: this.data.DefaultOption,
      };
      this.taxbusinessOutcome.editBusinessOutcome(data).subscribe(
        (res: any) => {
          this.searchText = '';
          if (res == 'Tax Outcome updated successfully') {
            this.toastr.successToastr(
              'Tax outcome updated successfully',
              'Success'
            );
            this.getAllTaxOutcomes();
            this.getDefaultTaxOutcome();
          } else if ((res = 'Tax Outcome Description already exists')) {
            this.toastr.warningToastr(
              'Tax Outcome Description already exists',
              'Warning!'
            );
          } else if ((res = 'Tax Outcome is not available')) {
            this.toastr.warningToastr(
              'Tax Outcome is not available',
              'Warning!'
            );
          } else {
            this.toastr.warningToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
          }
        },
        (error) => {
          console.log(error);
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      );
    } else if (this.action == 'new') {
      let data = {
        _id: 0,
        ShortDescription: this.taxOutcomeForm.controls.ShortDescription.value,
        LongDescription: this.taxOutcomeForm.controls.ShortDescription.value,
        RateAllowableCY: 0,
        RateAllowableEventually: 0,
        PermanentTimingDiff: true,
        HelpId: '',
        DefaultOption: true,
      };
      this.taxbusinessOutcome.inseetBusinessOutcome(data).subscribe(
        (res: any) => {
          this.searchText = '';
          if (res == 'Tax Outcome added successfully') {
            this.toastr.successToastr(
              'Tax outcome added successfully',
              'Success'
            );
            this.getAllTaxOutcomes();
            this.getDefaultTaxOutcome();
          } else if ((res = 'Tax Outcome Description already exists')) {
            this.toastr.warningToastr(
              'Tax Outcome Description already exists',
              'Warning!'
            );
          } else if ((res = 'Tax Outcome is not available')) {
            this.toastr.warningToastr(
              'Tax Outcome is not available',
              'Warning!'
            );
          }
        },
        (error) => {
          console.log(error);
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      );
    }
  }

  public removeHandler(args: RemoveEvent): void {
    this.openDeletePopup(args.dataItem);
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    // close the editor
    grid.closeRow(rowIndex);
    // reset the helpers
    this.editedRowIndex = undefined;
    this.taxOutcomeForm = undefined;
  }

  public onFilter(inputValue: any): void {
    inputValue = inputValue.queryStr;
    this.gridView = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'ShortDescription',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'LongDescription',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      this.getAllTaxOutcomes();
    }
  }

  public selectedCallback = (args: any) => args.dataItem;

  getAllTaxOutcomes() {
    this.taxbusinessOutcome
      .getAllTaxOutcomes()
      .then((res: any) => {
        this.gridData = res;
        this.gridView = this.gridData;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  getDefaultTaxOutcome() {
    this.taxbusinessOutcome
      .getDefaultTaxOutcome()
      .then((res: any) => {
        if (res != null && res != undefined) {
          this.defaultTaxOutcomeList = res;
          var index = this.defaultTaxOutcomeList
            .map((x: { DefaultOption: any }) => x.DefaultOption)
            .indexOf(true);
          this.selectedItem = this.defaultTaxOutcomeList[index];
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  closeButtonClick() {
    this.router.navigate(['/home']);
  }

  openDeletePopup(data: any) {
    const dialogRef = this.dialogService.open({
      content: DeleteTaxoutcomesComponent,
      width: 500,
      height: 'auto',
    });
    const deltaxOutcomeDetails = dialogRef.content
      .instance as DeleteTaxoutcomesComponent;
    dialogRef.result.subscribe((r: any) => {
      this.searchText = '';
      this.getAllTaxOutcomes();
      this.getDefaultTaxOutcome();
    });
    deltaxOutcomeDetails.delTaxoutcome = data;
  }

  handledefaultTaxOutcomeChange(taxOutcome: any): void {
    this.selectedItem = taxOutcome;
    this.changeTaxoutcome();
  }

  changeTaxoutcome() {
    this.taxbusinessOutcome.changeTaxOutcome(this.selectedItem._id).subscribe(
      (res: any) => {},
      (error: any) => {
        console.log(error);
      }
    );
  }
}
