import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';

@Component({
  selector: 'app-delete-statement',
  templateUrl: './delete-statement.component.html',
  styleUrls: ['./delete-statement.component.scss']
})
export class DeleteStatementComponent implements OnInit {

  constructor(private globalService: GlobalService,public toastr: ToastrManager,
    public dialog: DialogRef,private router: Router) { }
public statement:string="";
public statementid:number=0;
public warnonstatementdelete:boolean=false;
  ngOnInit(): void {
  }
  deleteAnalysisSatement()
  {
    this.globalService.deleteAnalysisStatement(this.statementid).then((res) => {  
      if(res=="Analysis Statement is deleted successfully")
      {
        this.toastr.successToastr(
          'Analysis statement is deleted successfully',
          'Success'
        );
      }
      else if(res == "Analysis Statement is not available"){
        this.toastr.errorToastr(
          'Analysis statement is not available',
          'Failed'
        );
      }
      else{
        this.toastr.errorToastr('Something went wrong try after sometime', 'Oops!');
      }
      this.displaytreePack();
    }).catch((err) => {
    });
  }

  displaytreePack()
  {    
    this.dialog.close();   
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/analysisreview']));
  }

  cancelClick() {
    this.dialog.close();
  }
}
