<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 18px; line-height: 1.3em">Delete GL account</div>
</kendo-dialog-titlebar>
<div style="display: flex">
  <p>
    <span
      class="Appkit4-icon icon-circle-warning-outline"
      style="font-size: 24px; color: #d04a02"
    ></span>
  </p>
  <p style="margin-left: 8px; font-size: medium">
    You have selected to delete the selected GL account<br /><br />
    Are you sure you wish to continue?
  </p>
</div>
<!-- Click to Actions     -->
<div class="clickToActions" style="padding: 10px 0px 0px 0px">
  <ap-button
    [btnType]="'secondary'"
    [label]="'No'"
    (click)="cancelClick()"
    style="margin-left: 5px; width: 100px"
  ></ap-button>
  <ap-button
    [btnType]="'primary'"
    [label]="'Yes'"
    themeColor="primary"
    class="okButton"
    (click)="deleteGLAccount()"
    style="margin-right: 6px"
  ></ap-button>
</div>
<!-- <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton class='actionButton btn-pwc-secondary' style="width:70px;" (click)="cancelClick()">No</button>
    <button kendoButton class='actionButton btn-pwc-primary' style="width:70px;" (click)="deleteGLAccount()">
        Yes
    </button>
</kendo-dialog-actions> -->

<kendo-dialog
  title="Delete GL account"
  *ngIf="warnondelete"
  (close)="this.dialog.close()"
  [minWidth]="250"
  [width]="500"
  [height]="280"
>
  <div style="position: relative; width: 100%">
    <div style="float: left; width: 7%">
      <p>
        <span
          class="Appkit4-icon icon-circle-warning-outline"
          style="color: #d04a02; font-size: 24px"
        ></span>
      </p>
    </div>
    <div style="float: right; width: 93%">
      <p>
        The following GL account could not be deleted as there is data
        associated with them:
      </p>
      <p>-{{ delGLdata.ShortDescription }}</p>
      <p>
        To delete you must first delete all the data associated with the listed
        GL account.
      </p>
    </div>
  </div>

  <div style="padding: 10px 0px 0px 0px; float: right">
    <ap-button
      [btnType]="'primary'"
      [label]="'OK'"
      themeColor="primary"
      (click)="this.dialog.close()"
    ></ap-button>
  </div>
</kendo-dialog>
