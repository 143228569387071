<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Manage analysis pack</div>
</kendo-dialog-titlebar>
<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>
<div>
  <form class="k-form" [formGroup]="addPack">
    <kendo-formfield>
      <kendo-label
        text="New analysis pack"
        style="font-weight: bold"
      ></kendo-label>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="description" text="Description*"></kendo-label>
      <input
        formControlName="packDescription"
        [maxlength]="50"
        #description
        kendoTextBox
      />
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="businessType" text="Business type*"></kendo-label>
      <kendo-combobox
        #businessType
        formControlName="businessType"
        [(ngModel)]="selectedBusinessType"
        [data]="businessTypes"
        textField="Description"
        valueField="BusinessTypeId"
        placeholder="Select Business type"
        (filterChange)="handleFilterBusinessTypeId($event)"
        (valueChange)="handleBusinessTypeChange($event)"
      >
      </kendo-combobox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="legalEntityID" text="Legal entity*"></kendo-label>
      <kendo-combobox
        #legalEntityID
        formControlName="LegalEntity"
        [(ngModel)]="selectedLegalEntity"
        placeholder="Select Legal entity"
        [data]="legalEntities"
        [filterable]="true"
        textField="Description"
        valueField="ID"
        (valueChange)="handleLegalEntityChange($event)"
        (filterChange)="handleFilterEntity($event)"
      >
      </kendo-combobox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label text="Analysis pack type: "
        >&nbsp;&nbsp;<b> Company Specific</b></kendo-label
      >
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label text="Country: "
        >&nbsp;&nbsp;{{ this.countryName }}</kendo-label
      >
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label text="Industry: "
        >&nbsp;&nbsp;{{ this.industryName }}</kendo-label
      >
    </kendo-formfield>
  </form>
</div>
<div class="clickToActions" style="padding-top: 10px">
  <ap-button
    [btnType]="'secondary'"
    [label]="'Cancel'"
    class="cancelButton okButton"
    (click)="cancelClick()"
  ></ap-button>

  <ap-button
    [btnType]="'primary'"
    [label]="'Save'"
    style="margin-right: 6px"
    [disabled]="!addPack.valid"
    themeColor="primary"
    class="okButton"
    (click)="savePack()"
  ></ap-button>
</div>
