import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service'

@Component({
  selector: 'app-delete-keyword',
  templateUrl: './delete-keyword.component.html',
  styleUrls: ['./delete-keyword.component.scss']
})
export class DeleteKeywordComponent implements OnInit {
  @Input() public keywordDeleteData: any; 

  constructor(
    public dialog: DialogRef,
    private route: ActivatedRoute,
    private router: Router,
    public toastr: ToastrManager,
    private globalService: GlobalService,
  ) { }

  ngOnInit(): void {}

  cancelClick() {
    this.dialog.close();
  }

  deleteKeyword() {
    if (this.keywordDeleteData.level === 'Folder') {
      this.globalService
      .DeleteKeywordGroupFolder(this.keywordDeleteData)
      .subscribe(
        (response) => {
          if (
            response == 'The keyword Group Folder is deleted successfully'
          ) {
            this.toastr.successToastr(response, 'Success');
            this.dialog.close('deleted');
          } else {
            this.toastr.errorToastr(response, 'Error');
            this.cancelClick();
            return;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else if (this.keywordDeleteData.level === 'Group') {
      this.globalService
        .DeleteKeywordGroup(this.keywordDeleteData)
        .subscribe(
          (response) => {
            if (response == 'The keyword Group is deleted successfully.') {
              this.toastr.successToastr(response, 'Success');
              this.dialog.close('deleted');
            } else {
              this.toastr.errorToastr(response, 'Error');
              this.cancelClick();
              return;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else if (this.keywordDeleteData.level === 'Keyword') {
      this.globalService.DeleteKeyword(this.keywordDeleteData).subscribe(
        (response) => {
          if (response == 'The keyword is deleted successfully.') {
            this.toastr.successToastr(response, 'Success');
            this.dialog.close('deleted');
          } else {
            this.toastr.errorToastr(response, 'Error');
            this.cancelClick();
            return;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
}
