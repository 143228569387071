<kendo-dialog
  title="Delete attachment"
  (close)="cancelClick()"
  [minWidth]="250"
  [width]="500"
  [height]="211"
>
  <div style="display: flex">    
    <div class="gridHeader" >Please select from one of the following options</div>
  </div>
  <br/>
      <ul class="k-radio-list k-list-horizontal">        
        
        <li class="k-radio-item"> 
          
          <input
            type="radio" 
            name="deleteradio"
            value="Soft Delete"
            #softDelete
            [(ngModel)]="deleteType"
            kendoRadioButton            
            (ngModelChange)="selectDeleteType($event)"
            checked
          />
          <kendo-label [for]="softDelete"  text="Archive"></kendo-label>
        </li>
        <li class="k-radio-item">          
          <input
            type="radio"
            name="deleteradio"
            value="Pemanent Delete"
            #confirmDelete
            [(ngModel)]="deleteType"
            kendoRadioButton            
            (ngModelChange)="selectDeleteType($event)"
          />
          <kendo-label [for]="confirmDelete"  text="Delete"></kendo-label>
        </li>        
    </ul>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 col-xs-12">
          <div class="clickToActions" style="padding: 10px 0px">
            <ap-button [btnType]="'secondary'" [label]="'Cancel'" (click)="cancelClick()"></ap-button>
            <ap-button [btnType]="'primary'" [label]="'Save'" 
            themeColor="primary"
            (click)="deleteData()"
            style="margin-right: 6px;"></ap-button>
          </div>
        </div>
      </div>
    </div>
</kendo-dialog>