import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-select-journal',
  templateUrl: './manage-select-journal.component.html',
  styleUrls: ['./manage-select-journal.component.scss'],
})
export class ManageSelectJournalComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  public model = {
    journalType: 'AdjustmentJournal',
  };

  openAddJournal() {
    // console.log(this.model.journalType);
    if (this.model.journalType == 'AdjustmentJournal') {
      this.router.navigate(['/manageAdjustmentJournal']);
    } else {
      this.router.navigate(['/manageMovementJournal']);
    }
  }

  closeButtonClick() {
    this.router.navigate(['/manageJournal']);
  }
}
