import { Component, OnInit, Input } from '@angular/core';
import {
  DialogContentBase,
  ActionsLayout,
  DialogAction,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from '@progress/kendo-angular-dialog';
import { JournalService } from 'src/app/common/services/journal/journal-service';

@Component({
  selector: 'app-delete-journal',
  templateUrl: './delete-journal.component.html',
  styleUrls: ['./delete-journal.component.scss'],
})
export class DeleteJournalComponent
  extends DialogContentBase
  implements OnInit
{
  @Input() public deleteJournals: number[] = [];
  isLoading: boolean = false;
  constructor(
    public dialog: DialogRef,
    public toastr: ToastrManager,
    private dialogService: DialogService,
    private journalService: JournalService
  ) {
    super(dialog);
  }
  cancelClick() {
    this.dialog.close();
  }
  ngOnInit(): void {}
  delJournal() {
    this.isLoading = true;
    this.journalService.DeleteOnlineallocation(this.deleteJournals).subscribe(
      (response) => {
        if (response == 'Journal is deleted successfully') {
          this.isLoading = false;
          this.dialog.close('deleted');
          this.toastr.successToastr(response, 'Success');
        } else {
          this.isLoading = false;
          this.toastr.errorToastr(response, 'Error');
          return;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
