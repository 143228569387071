<!-- Header -->
<div class="header">Preferences</div>
<div class="container-fluid">
  <form class="k-form" [formGroup]="preferenceForm">
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="flexbox">
          <kendo-formfield style="margin-top: 0px">
            <kendo-label class="flexbox-header"
              >Display format - Negative values</kendo-label
            >
            <kendo-label
              for
              text="Select the format of how negative values should be
              displayed"
              style="font-size: 14px"
            ></kendo-label>
            <kendo-combobox
              #displayFormat
              formControlName="displayFormat"
              [(ngModel)]="selecteddisplayFormat"
              [data]="displayFormatList"
              textField="label"
              valueField="value"
              placeholder="Select Display Format"
              (valueChange)="handleDisplayFormatChange($event)"
              style="margin-top: 10px; width: 100%; color: red"
            >
            </kendo-combobox>
          </kendo-formfield>
        </div>
        <div class="flexbox" style="margin-top: 10px">
          <kendo-formfield orientation="horizontal" style="margin-top: 0px">
            <kendo-label
              class="flexbox-header"
              text="Transaction value import format - Debit (DR+) / Credit
              (CR+)"
            ></kendo-label>
            <kendo-label
              text="Select the format of how transaction data values will be
              imported"
            ></kendo-label>
            <ul class="k-radio-list k-list-horizontal">
              <li class="k-radio-item">
                <input
                  type="radio"
                  name="account"
                  value="Debit"
                  #debit
                  [(ngModel)]="accountType"
                  kendoRadioButton
                  formControlName="debit"
                  (change)="selectAccount($event)"
                />
                <kendo-label text="DR+"></kendo-label>
              </li>
              <li class="k-radio-item">
                <input
                  type="radio"
                  name="account"
                  value="Credit"
                  #credit
                  [(ngModel)]="accountType"
                  kendoRadioButton
                  formControlName="credit"
                  (change)="selectAccount($event)"
                />
                <kendo-label text="CR+"></kendo-label>
              </li>
            </ul>
          </kendo-formfield>
        </div>
        <div class="flexbox" style="margin-top: 10px">
          <kendo-formfield style="margin-top: 0px">
            <kendo-label
              class="flexbox-header"
              text="Transactions display"
            ></kendo-label>
            <kendo-label
              text="Choose whether you would like matched and moved transactions
              displayed in the system"
            ></kendo-label>
            <span style="display: flex; margin-top: 5px">
              <ap-checkbox
                (onChange)="onDisplayMatchedchange($event)"
                [(ngModel)]="selecteddisplayMatched"
                formControlName="matchedDisplay"
                #matchedDisplay
                style="font-size: 14px; margin-right: 10px"
              >
                <span>Display matched transactions</span>
              </ap-checkbox>
              <ap-checkbox
                (onChange)="onDisplayMovedchange($event)"
                [(ngModel)]="selecteddisplayMoved"
                formControlName="movedDisplay"
                #movedDisplay
              >
                <span>Display moved transactions</span>
              </ap-checkbox>
            </span>
          </kendo-formfield>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="flexbox">
          <kendo-formfield style="margin-top: 0px">
            <kendo-label class="flexbox-header" text="Currency"></kendo-label>
            <kendo-label
              [For]="currencyFormat"
              text="Select the currency you would like to display in the
              system"
            ></kendo-label>
            <kendo-combobox
              #currencyFormat
              formControlName="currencyFormat"
              [(ngModel)]="selectedCurrency"
              [data]="currencyFormatList"
              textField="Code"
              valueField="CurrencyId"
              placeholder="Select Currency"
              [kendoDropDownFilter]="filterSettings"
              (valueChange)="handlecurrencyFormatChange($event)"
              style="margin-top: 10px; width: 100%"
            >
            </kendo-combobox>
          </kendo-formfield>
        </div>
        <div class="flexbox" style="margin-top: 10px">
          <kendo-formfield style="margin-top: 0px">
            <kendo-label
              class="flexbox-header"
              text="Zero transactions balance"
              style="margin-bottom: 1.1rem"
            ></kendo-label>
            <ap-checkbox
              (onChange)="onZeropTransactionchange($event)"
              [(ngModel)]="selectedTransactionBalance"
              formControlName="transactionBalance"
              #transactionBalance
              style="font-size: 14px !important; margin-bottom: 0.5rem"
            >
              <span style="font-size: 14px"
                >Show transactions where TB and REVERSE transactions balance to
                zero</span
              >
            </ap-checkbox>
          </kendo-formfield>
        </div>
        <div class="flexbox" style="margin-top: 10px">
          <kendo-formfield style="margin-top: 0px">
            <kendo-label
              class="flexbox-header"
              style="margin-bottom: 0.65rem"
              text="Auto allocation"
            ></kendo-label>
            <ap-checkbox
              (onChange)="onAutoAllocationchange($event)"
              [(ngModel)]="selectedAutoAllocation"
              formControlName="autoAllocation"
              #autoAllocation
              style="margin-bottom: 1.2rem"
            >
              <span
                >Include tax accounts that have exported offline
                allocations</span
              >
            </ap-checkbox>
          </kendo-formfield>
        </div>
      </div>
      <div class="col-md-6 col-xs-12" style="margin-top: 10px">
        <div class="flexbox">
          <kendo-formfield orientation="horizontal" style="margin-top: 0px">
            <kendo-label class="flexbox-header" text="Dashboard"></kendo-label>

            <ul class="k-radio-list k-list-horizontal">
              <li class="k-radio-item">
                <input
                  type="radio"
                  name="accountGrid"
                  value="false"
                  #gridAccount
                  [(ngModel)]="dashBoardChangeType"
                  kendoRadioButton
                  formControlName="gridAccount"
                  (change)="selectDashBoardAccount($event)"
                />
                <kendo-label text="GL account summary "></kendo-label>
              </li>
              <li class="k-radio-item">
                <input
                  type="radio"
                  name="accountGrid"
                  value="true"
                  #pieChartAccount
                  [(ngModel)]="dashBoardChangeType"
                  kendoRadioButton
                  formControlName="pieChartAccount"
                  (change)="selectDashBoardAccount($event)"
                />
                <kendo-label text="Pie chart"></kendo-label>
              </li>
            </ul>
          </kendo-formfield>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-xs-12">
        <div class="clickToActions" style="padding: 20px 0px">
          <ap-button
            kendoButton
            class="okButton"
            [btnType]="'secondary'"
            [label]="'Close'"
            (onClick)="closeButtonClick()"
          ></ap-button>
          &nbsp;&nbsp;&nbsp;
          <ap-button
            class="okButton"
            kendoButton
            [btnType]="'primary'"
            [label]="'Save'"
            [disabled]="preferenceFormDisabled"
            (onClick)="savePreferences()"
          ></ap-button>
        </div>
      </div>
    </div>
  </form>
</div>
