<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>
<div class="header" style="font-size: 20px">
  Generate statement details template
</div>
<mat-card class="userCard">
  <!-- <mat-card-title style="font-size: 18px; line-height: 1.5em;background-color: #D04A02;color: white;">
        &nbsp;Generate Statement Details Template
    </mat-card-title> -->
  <mat-card-content>
    <div>
      <form class="k-form" [formGroup]="statementDetailExportForm">
        <kendo-formfield>
          <div class="row">
            <div class="col-6" style="border: 0px solid; padding-top: 0%">
              <kendo-label [for]="businessType" text="Business type:">
                <kendo-combobox
                  #businessType
                  [data]="businessTypes"
                  formControlName="businessType"
                  textField="LookUpDescription"
                  valueField="LookUpId"
                  placeholder="Select business type"
                  (valueChange)="handleBusinessTypeChange($event)"
                  [(ngModel)]="selectedbusinessType"
                  style="width: 85%; margin-left: 4px"
                  [kendoDropDownFilter]="filterSettings"
                >
                </kendo-combobox>
              </kendo-label>
            </div>
            <div class="col-6" style="border: 0px solid">
              <kendo-label [for]="legalEntity" text="Legal entity:">
                <kendo-combobox
                  #legalEntity
                  formControlName="legalEntity"
                  [data]="legalEntities"
                  [(ngModel)]="selectedLegalEntity"
                  style="width: 88%; margin-left: 4px"
                  textField="LookUpDescription"
                  valueField="LookUpId"
                  placeholder="Select legal entity"
                  (valueChange)="handleLegalEntityChange($event)"
                  [kendoDropDownFilter]="filterSettings"
                >
                </kendo-combobox>
              </kendo-label>
            </div>
          </div>
        </kendo-formfield>
        <kendo-formfield>
          <div class="row">
            <div class="col-6" style="border: 0px solid">
              <kendo-label [for]="analysisPack" text="Analysis pack:">
                <kendo-combobox
                  #analysisPack
                  formControlName="analysisPack"
                  [data]="analysisPacks"
                  [(ngModel)]="selectedAnalysisPack"
                  style="width: 85%; margin-left: 6px"
                  textField="LookUpDescription"
                  valueField="LookUpId"
                  placeholder="Select analysis pack"
                  (valueChange)="handleAnalysisPackChange($event)"
                  [kendoDropDownFilter]="filterSettings"
                >
                </kendo-combobox>
              </kendo-label>
            </div>
          </div>
        </kendo-formfield>
      </form>

      <div class="row">
        <div class="col-12" style="margin-top: 16px">
          <!-- <kendo-label text="Select the statement that you would like to add the statement details template:"></kendo-label> -->
          <kendo-label
            text="Statements:"
            style="margin-top: 16px; font-weight: bold; font-size: 14px"
          ></kendo-label>
        </div>
      </div>
      <div style="width: 100%; display: flex">
        <div style="width: 130%">
          <kendo-multiselect
            placeholder="Select options"
            (valueChange)="onValueChange($event)"
            [autoClose]="false"
            [data]="statements"
            [(ngModel)]="value"
            textField="LookUpDescription"
            valueField="LookUpId"
            style="margin-top: 6px"
          >
            <ng-template
              kendoMultiSelectHeaderTemplate
              *ngIf="statements.length > 0"
            >
              <span style="margin-left: 8px">
                <input
                  type="checkbox"
                  id="chk"
                  kendoCheckBox
                  [checked]="isChecked"
                  [indeterminate]="isIndet"
                  (click)="onClick()"
                />
                <kendo-label for="chk" style="margin-left: 5px">{{
                  toggleAllText
                }}</kendo-label>
              </span>
            </ng-template>

            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <span>
                <kendo-label for="chk-{{ dataItem.LookUpId }}">
                  <input
                    type="checkbox"
                    id="chk-{{ dataItem.LookUpId }}"
                    kendoCheckBox
                    [checked]="isItemSelected(dataItem)"
                  />
                </kendo-label>
              </span>
              <span>{{ dataItem.LookUpDescription }}</span>
            </ng-template>
          </kendo-multiselect>
        </div>
        <div style="margin-top: 5px; width: 30%; margin-left: 25px">
          <input
            type="checkbox"
            [(ngModel)]="exportLineItemValue"
            kendoCheckBox
            #exportLineItem
          />&nbsp;
          <kendo-label
            [for]="exportLineItem"
            text="Export existing line items to template"
            style="margin-top: 10px; font-size: 14px"
          >
          </kendo-label>
        </div>
      </div>
      <!-- <div class="row">
                <div class="col-6" style="margin-top: 10px;">
                <input type="checkbox" [(ngModel)]="exportLineItemValue" kendoCheckBox    #exportLineItem />&nbsp;
                <kendo-label [for]="exportLineItem" text="Export existing line items to template" style="margin-top: 10px; font-size: 14px;">
                </kendo-label>
                </div>
            </div>
            <div  class="row">
                <div class="col-12 clickToActions" style="margin-top: 6px;">
                    <ap-button kendoButton  [btnType]="'primary'" [label]="'Generate'"  (onClick)="generate()" ></ap-button>
                </div>
                
            </div> -->
    </div>
  </mat-card-content>
</mat-card>
<div class="clickToActions" style="margin-top: 25px">
  <ap-button
    kendoButton
    [btnType]="'primary'"
    [label]="'Generate'"
    (onClick)="generate()"
    style="width: 100px; position: absolute; right: 1%"
  ></ap-button>
</div>
