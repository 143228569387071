import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { StatementDetailService } from '../common/services/statementDetail/statementDetail-service';
import { StatementDetailExportType } from '../_models/statementDetailExportType';
import {
  statementDetailTemplateProcessingStartBody,
  statementDetailTemplateProcessingStartResponse,
} from '../_models/statementDetailTemplateProcessingStart.ts';
import { CookieService } from 'ngx-cookie-service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MasterDataService } from 'src/app/common/services/masterData/masterData-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-master-data',
  templateUrl: './master-data.component.html',
  styleUrls: ['./master-data.component.scss'],
})
export class MasterDataComponent implements OnInit {
  statementDetailExportForm!: FormGroup;
  public mySelectionMasterDataSelected: any[] = [];
  taxAccountsSelected = [];

  businessTypes: StatementDetailExportType[] = [];
  selectedbusinessType: StatementDetailExportType | undefined;
  selectedbusinessTypeId: number = 0;
  legalEntities: StatementDetailExportType[] = [];
  selectedLegalEntity: StatementDetailExportType | undefined;
  selectedLegalEntityId: number = 0;
  analysisPacks: StatementDetailExportType[] = [];
  selectedAnalysisPack: StatementDetailExportType | undefined;
  selectedAnalysisPackId: number = 0;
  statements: StatementDetailExportType[] = [];
  isLoading: boolean = false;
  public value: any;
  public isChecked = false;
  public exportLineItemValue: boolean = false;
  statementDetailTemplateProcessingBody!: statementDetailTemplateProcessingStartBody;
  private selectedClient: any;
  processingOrchestrationId: string = '';
  afterExportDetails!: statementDetailTemplateProcessingStartResponse;
  isExportSuccess: boolean = false;
  exportProcessingErrorMessage: string = '';
  selectedStatementIds: number[] = [];
  sortDataDetails: any;
  fiscalYear: any;
  fiscalYearValue: any;
  greport: any;
  xyreport: any;
  raw: any;
  public selectedCallback = (args: any) => args.dataItem;
  public mySelectionMasterData: any[] = [];
  public selectedCallback1 = (args: any) => args.dataItem;
  public mySelectionMasterData1: any[] = [];
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private statementDetailService: StatementDetailService,
    private cookieService: CookieService,
    public toastr: ToastrManager,
    private masterDataService: MasterDataService
  ) { }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  columnData: any[] = [];
  rightColumn: any[] = [];
  onMoveDownButtonClick(): void {
    let indexItem = 0;
    let selected = '';
    this.mySelectionMasterData1.forEach((ele: any) => {
      if (ele.Selected && selected == '') {
        selected = ele.DisplayName;
      }
    });
    if (selected != this.rightColumn[this.rightColumn.length - 1].DisplayName) {
      this.rightColumn.forEach((ele: any, index: any) => {
        if (ele.DisplayName == selected) {
          indexItem = index;
        }
      });
      let temp = this.rightColumn[indexItem];
      this.rightColumn[indexItem] = this.rightColumn[indexItem + 1];
      this.rightColumn[indexItem + 1] = temp;
      let request: any[] = [];
      // this.columnData.forEach((ele:any)=>{
      //   request.push(ele)
      // })
      this.rightColumn.forEach((ele: any) => {
        request.push(ele);
      });
      this.masterDataService
        .saveMasterData(request)
        .subscribe((response) => { });
    }
  }
  public onGreportChange(event: any) {
    this.greport = event.checkboxState;
  }
  public onXyreportChange(event: any) {
    this.xyreport = event.checkboxState;
  }
  public rawChange(event: any) {
    this.raw = event.checkboxState;
  }
  run() {
    this.isLoading = true;
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.fiscalYear = JSON.parse(year);
    this.fiscalYearValue = this.fiscalYear.FiscalYearId;
    let master: any[] = [];
    // this.columnData.forEach((ele:any)=>{
    //   master.push(ele)
    // })
    this.rightColumn.forEach((ele: any) => {
      master.push(ele);
    });
    let businessType, legalEntity, statementgrp, statement;
    if (this.value != undefined && this.value.LookUpId != 0 && this.value) {
      statement = this.value.LookUpId;
      statementgrp = 0;
      legalEntity = 0;
      businessType = 0;
    } else if (this.selectedAnalysisPackId != 0) {
      statement = 0;
      statementgrp = this.selectedAnalysisPackId;
      legalEntity = 0;
      businessType = this.selectedbusinessTypeId;
    } else if (this.selectedLegalEntityId != 0) {
      statement = 0;
      statementgrp = 0;
      legalEntity = this.selectedLegalEntityId;
      businessType = this.selectedbusinessTypeId;
    }
    let request = {
      fiscalYear: this.fiscalYearValue,
      businessTypeId: businessType,
      legalEntityId: legalEntity,
      statementGroupId: statementgrp,
      statementId: statement,
      masterDataReportColumns: master,
      isGenerateGroupingAnalysisReport: this.greport,
      isGenerateXYAnalysisReport: this.xyreport,
      isOnlyRawDataSheet: this.raw,
      clientDbName: this.selectedClient.DBName,
    };
    this.isLoading = true;

    this.masterDataService.generateReport(request).subscribe(
      (response: any) => {
        this.isLoading = true;

        if (response) {
          this.processingOrchestrationId = response.orchestrationId;
          this.getProcessingStatus();
        }
        //this.isNextVisible = true;
      },
      (error: any) => {
        this.isLoading = false;
        this.toastr.errorToastr('Export statement failed', 'Error');
      }
    );
  }

  onMoveUpButtonClick(): void {
    let indexItem = 0;
    let selected = '';
    this.mySelectionMasterData1.forEach((ele: any) => {
      if (ele.Selected && selected == '') {
        selected = ele.DisplayName;
      }
    });
    if (selected != this.rightColumn[0].DisplayName) {
      this.rightColumn.forEach((ele: any, index: any) => {
        if (ele.DisplayName == selected) {
          indexItem = index;
        }
      });
      let temp = this.rightColumn[indexItem];
      this.rightColumn[indexItem] = this.rightColumn[indexItem - 1];
      this.rightColumn[indexItem - 1] = temp;
      let request: any[] = [];
      // this.columnData.forEach((ele:any)=>{
      //   request.push(ele)
      // })
      this.rightColumn.forEach((ele: any) => {
        request.push(ele);
      });
      this.masterDataService.saveMasterData(request).subscribe(
        (response) => { },
        (error: any) => {
          this.isLoading = false;
          this.toastr.errorToastr('Error occured', 'Error');
        }
      );
    }
  }
  forwardMasterData() {
    this.mySelectionMasterData.forEach((ele: any) => {
      if (!this.rightColumn.includes(ele)) this.rightColumn.push(ele);
      this.columnData.forEach((ele1: any, item: any) => {
        if (ele.DisplayName == ele1.DisplayName) {
          ele1.Selected = true;
          this.columnData.splice(item, 1);
        }
      });
    });
    let request: any[] = [];
    // this.columnData.forEach((ele: any) => {
    //   request.push(ele)
    // })
    this.rightColumn.forEach((ele: any) => {
      request.push(ele);
    });
    this.masterDataService.saveMasterData(request).subscribe((response) => { });
  }
  backwardMasterData() {
    this.getDisableStatus();
    // if (
    //   this.mySelectionMasterData1.length != this.rightColumn.length &&
    //   this.rightColumn.length > 1
    // ) {
    this.mySelectionMasterData1.forEach((ele: any) => {
      if (!this.columnData.includes(ele)) this.columnData.push(ele);
      this.rightColumn.forEach((ele1: any, item: any) => {
        if (ele.DisplayName == ele1.DisplayName) {
          ele1.Selected = false;
          this.rightColumn.splice(item, 1);
        }
      });
    });
    // }
    let request: any[] = [];
    // this.columnData.forEach((ele: any) => {
    //   request.push(ele);
    // });
    this.rightColumn.forEach((ele: any) => {
      request.push(ele);
    });
    this.masterDataService.saveMasterData(request).subscribe((response) => { });
    let arr = this.columnData;
    let found = this.sortDataDetails.find((element: any) => element.DbColumnName.toLowerCase().includes('customer'));
    let customValArr = [...arr].filter((obj) => {
      if (obj.DbColumnName.toLowerCase().includes('custom') && obj.DbColumnName != "fk_CustomerId") {
        return true
      }
      else {
        return false
      }
    })
    arr = arr.filter((obj) => {
      if (!obj.DbColumnName.toLowerCase().includes('custom')) {
        return true
      }
      else {
        return false
      }
    })
    if (found && found["Selected"] == false) {
      arr.push(found);
    }
    // arr.push(found)
    arr.sort((a, b) => (a.DisplayName.toLowerCase() < b.DisplayName.toLowerCase()) ? -1 : ((b.DisplayName.toLowerCase() > a.DisplayName.toLowerCase()) ? 1 : 0));
    this.columnData = [...arr, ...customValArr]
  }
  forwardMasterDataAll() {
    this.columnData.forEach((ele: any) => {
      this.rightColumn.push(ele);
      ele.Selected = true;
    });
    this.columnData = [];
    let request: any[] = [];

    this.rightColumn.forEach((ele: any) => {
      request.push(ele);
    });
    this.masterDataService.saveMasterData(request).subscribe((response) => { });
  }
  close() {
    this.router.navigate(['/home']);
  }
  backwardMasterDataAll() {
    this.getDisableStatus();

    this.xyreport = false;
    // let firstItem = this.rightColumn[0];
    // this.rightColumn.forEach((ele: any) => {
    //   if (ele != firstItem) {
    //     this.columnData.push(ele);
    //     ele.Selected = false;
    //   }
    // });
    this.rightColumn.forEach((ele: any) => {
      this.columnData.push(ele);
      ele.Selected = true;
    });
    this.rightColumn = [];
    // this.rightColumn.push(firstItem);
    let request: any[] = [];
    // this.columnData.forEach((ele: any) => {
    //   request.push(ele)
    // })
    this.rightColumn.forEach((ele: any) => {
      request.push(ele);
    });
    this.masterDataService.saveMasterData(request).subscribe((response) => { });
    let arr = this.columnData;
    let found = this.sortDataDetails.find((element: any) => element.DbColumnName.toLowerCase().includes('customer'));
    let customValArr = [...arr].filter((obj) => {
      if (obj.DbColumnName.toLowerCase().includes('custom') && obj.DbColumnName != "fk_CustomerId") {
        return true
      }
      else {
        return false
      }
    })
    arr = arr.filter((obj) => {
      if (!obj.DbColumnName.toLowerCase().includes('custom')) {
        return true
      }
      else {
        return false
      }
    })
    arr.push(found)
    arr.sort((a, b) => (a.DisplayName.toLowerCase() < b.DisplayName.toLowerCase()) ? -1 : ((b.DisplayName.toLowerCase() > a.DisplayName.toLowerCase()) ? 1 : 0));
    this.columnData = [...arr, ...customValArr]
  }
  getDisableStatus() {
    if (this.raw) {
      this.xyreport = false;
      return true;
    } else if (this.rightColumn.length > 1) return false;
    else {
      this.xyreport = false;
      return true;
    }
  }
  getDisableStatus1() {
    if (this.raw) {
      this.greport = false;
      return true;
    } else return false;
  }
  getDisableStatusRaw() {
    if (this.xyreport || this.greport) {
      this.raw = false;
      return true;
    } else return false;
  }
  ngOnInit(): void {
    this.setupForm();
    this.getBusinessTypes();
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    this.masterDataService.getMasterData().subscribe(
      (response) => {
        this.sortDataDetails = response;
        response.forEach((ele: any) => {
          if (ele.Selected) {
            this.rightColumn.push(ele);
          } else {
            this.columnData.push(ele);

          }
        });
        let found: any = response.find((element: any) => element.DbColumnName.toLowerCase().includes('customer'));
        let arr = this.columnData;
        let customValArr = [...arr].filter((obj) => {
          if (obj.DbColumnName.toLowerCase().includes('custom') && obj.DbColumnName != "fk_CustomerId") {
            return true
          }
          else {
            return false
          }
        })
        arr = arr.filter((obj) => {
          if (!obj.DbColumnName.toLowerCase().includes('custom')) {
            return true
          }
          else {
            return false
          }
        });
        if (found && found["Selected"] == false) {
          arr.push(found);
        }
        arr.sort((a, b) => (a.DisplayName.toLowerCase() < b.DisplayName.toLowerCase()) ? -1 : ((b.DisplayName.toLowerCase() > a.DisplayName.toLowerCase()) ? 1 : 0));
        this.columnData = [...arr, ...customValArr];
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  setupForm(): void {
    this.statementDetailExportForm = this.fb.group({
      businessType: '',
      legalEntity: '',
      analysisPack: '',
      statementOptions: '',
    });
  }

  public get toggleAllText() {
    return this.isChecked ? 'Deselect All' : 'Select All';
  }

  public isItemSelected(item: StatementDetailExportType) {
    return this.value.some(
      (x: StatementDetailExportType) => x.LookUpId === item.LookUpId
    );
  }

  public onClick() {
    this.isChecked = !this.isChecked;
    this.value = this.isChecked ? this.statements : [];
  }

  public onValueChange(event: any) {
    this.value = event;
  }

  handleBusinessTypeChange(businessType: StatementDetailExportType): void {
    this.selectedbusinessType = businessType;
    this.selectedLegalEntity = undefined;
    this.legalEntities = [];
    this.selectedbusinessTypeId = 0;
    this.selectedLegalEntityId = 0;
    this.selectedAnalysisPackId = 0;
    this.selectedAnalysisPack = undefined;
    this.analysisPacks = [];

    this.statements = [];
    if (this.selectedbusinessType != undefined) {
      this.selectedbusinessTypeId = this.selectedbusinessType.LookUpId;
      this.getLegalEntities(businessType.LookUpId);
    }
  }

  handleLegalEntityChange(legalEntity: StatementDetailExportType): void {
    this.selectedLegalEntity = legalEntity;
    this.selectedAnalysisPack = undefined;
    this.selectedAnalysisPackId = 0;
    this.analysisPacks = [];
    this.statements = [];
    this.value = '';
    if (this.selectedLegalEntity != undefined) {
      this.selectedLegalEntityId = legalEntity.LookUpId;
      this.getAnalysisPacks(this.selectedbusinessTypeId, legalEntity.LookUpId);
    }
  }

  handleAnalysisPackChange(analysispack: StatementDetailExportType): void {
    this.selectedAnalysisPack = analysispack;
    this.statements = [];
    this.value = '';
    if (this.selectedAnalysisPack != undefined) {
      this.selectedAnalysisPackId = analysispack.LookUpId;
      this.getStatements(analysispack.LookUpId);
    }
  }

  getBusinessTypes(): void {
    this.isLoading = true;
    this.businessTypes = [];
    this.statementDetailService.getAllBusinessTypeDetail().subscribe(
      (response) => {
        this.businessTypes = response;
        if (this.businessTypes.length == 1) {
          this.selectedbusinessType = this.businessTypes[0];
          this.handleBusinessTypeChange(this.selectedbusinessType);
        }
        if (this.businessTypes.length == 1) {
          this.selectedbusinessType = this.businessTypes[0];
          this.handleBusinessTypeChange(this.selectedbusinessType);
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  getLegalEntities(businessTypeId: number): void {
    this.isLoading = true;
    this.legalEntities = [];
    this.statementDetailService
      .GetLegalEntitiesByBusinessTypeId(businessTypeId)
      .subscribe(
        (response) => {
          this.legalEntities = response;
          if (this.legalEntities.length == 1) {
            this.selectedLegalEntity = this.legalEntities[0];
            this.handleLegalEntityChange(this.selectedLegalEntity);
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  getAnalysisPacks(businessTypeId: number, legalEntityId: number): void {
    this.isLoading = true;
    this.analysisPacks = [];
    this.statementDetailService
      .GetStatementGroupsByBusTypeAndLegalEntityId(
        businessTypeId,
        legalEntityId
      )
      .subscribe(
        (response) => {
          this.analysisPacks = response;
          if (this.analysisPacks.length == 1) {
            this.selectedAnalysisPack = this.analysisPacks[0];
            this.handleAnalysisPackChange(this.selectedAnalysisPack);
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  getStatements(statementGroupId: number): void {
    this.isLoading = true;
    // this.analysisPacks = [];
    this.statements = [];
    this.statementDetailService
      .GetAnalysisStatementsForStatementGroupId(statementGroupId)
      .subscribe(
        (response) => {
          this.statements = response;
          if (this.statements.length == 1) {
            this.value = this.statements[0];
            this.onValueChange(this.value);
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  getProcessingStatus() {
    this.isLoading = true;

    this.masterDataService
      .GetExportStatementDetailTemplateProcessingStatus(
        this.processingOrchestrationId
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;

          this.afterExportDetails = response.body;
          let responseData = response.body;
          if (
            this.afterExportDetails.orchestrationRuntimeStatus == 'Completed' ||
            this.afterExportDetails.orchestrationRuntimeStatus == 'Failed'
          ) {
            if (
              this.afterExportDetails.orchestrationRuntimeStatus == 'Completed'
            ) {
              if (
                this.afterExportDetails
                  .isExportProcessingCompletedWithSuccess == true
              ) {
                this.downloadStatementDetailTemplateExcelFile(
                  this.afterExportDetails.generatedExportFileName
                );
              } else {
                this.exportProcessingErrorMessage =
                  this.afterExportDetails.exportProcessingErrorMessage;
                this.toastr.errorToastr(
                  'Export statement failed as ' +
                  responseData.exportProcessingValidationMessage,
                  'Error'
                );
              }
            } else if (
              this.afterExportDetails.orchestrationRuntimeStatus == 'Failed'
            ) {
              this.exportProcessingErrorMessage =
                this.afterExportDetails.exportProcessingErrorMessage;

              this.toastr.errorToastr('Export statement failed', 'Error');
            }
            this.isLoading = false;
            this.isExportSuccess = true;
          } else this.getProcessingStatus();
        },
        (error: any) => {
          this.isLoading = false;
          this.toastr.errorToastr('Export statement failed', 'Error');
          return error;
        }
      );
  }

  downloadStatementDetailTemplateExcelFile(generatedExportFileName: any) {
    this.isLoading = true;

    this.masterDataService
      .downloadStatementDetailTemplateExcelFile(generatedExportFileName)
      .subscribe(
        (response: any) => {
          this.isLoading = false;

          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          const fileName = generatedExportFileName;
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
          this.isLoading = false;
        },
        (error: any) => {
          this.isLoading = false;
          this.toastr.errorToastr('Export statement failed', 'Error');
        }
      );
  }
}
