<div class="header">Terms of use: Transaction Data Assist</div>
<div class="container-fluid termsofuseContent">
  <div class="row">
    <!-- <div class="col-2"></div> -->
    <div class="col-12">
      <div>
        <p>
          You have been given access by a PwC firm (also referred to as “us”, or
          “we”) to Transaction Data Assist (the “technology tool”). These terms
          form an agreement between you and that PwC firm; please read them
          carefully as they govern your access and use of the technology tool.
        </p>
        <p>
          By using this technology tool, you confirm that you accept these terms
          and agree to comply with these terms. If you do not accept these terms
          or cannot comply with these terms, then you must cease using this
          technology tool immediately.
        </p>
        <p>
          If you are using this technology tool on behalf of a corporation or
          other organisation, you represent and warrant that you have the
          ability to agree to these terms on behalf of such organisations and
          all references to “you” throughout these terms will include such
          organisation, jointly and severally with you personally.
        </p>
        <p>
          We may amend these terms from time to time. The amended terms will
          either be notified to you, or be available for you to view on (or when
          accessing) the technology tool.
        </p>
        <div>
          <ol class="makeBold">
            <li>
              <b>Use</b>
              <ol type="a">
                <li>
                  Permitted use. You may only use this technology tool, the
                  content on it, and the outputs from it, for their intended
                  purpose and subject to these terms save as otherwise agreed in
                  writing. You will use the technology tool for lawful purposes.
                </li>
                <li>
                  Restrictions on use. You may not:
                  <ol type="i">
                    <li>
                      act or omit to act in any way that puts you or any entity
                      you work for in breach of (i) any agreement with any PwC
                      firm, and/or (ii) any applicable law or regulation;
                    </li>
                    <li>
                      make this technology tool, the contents or the outputs
                      available to any person, other than as permitted by us;
                    </li>
                    <li>
                      copy, modify, disassemble, work around, by-pass, reverse
                      engineer or otherwise change this technology tool or its
                      underlying data, systems or software, or attempt to do so;
                    </li>
                    <li>
                      copy or use the contents or outputs, other than as
                      expressly permitted by us;
                    </li>
                    <li>
                      engage in or encourage unlawful, invasive, infringing,
                      defamatory or fraudulent activity (including to violate,
                      or encourage the violation of, the legal rights of
                      others); impersonate another person or otherwise
                      misrepresent your identity or other information about you;
                    </li>
                    <li>
                      use to the technology tool to transmit or upload any
                      virus, malware, spyware, worm, Trojan horse or other
                      harmful or malicious code or content;
                    </li>
                    <li>
                      post, transmit or otherwise make available any materials
                      that are or may be: (a) threatening, harassing, degrading,
                      hateful, or otherwise fail to respect the rights of
                      others; (b) obscene, indecent, pornographic or otherwise
                      objectionable;
                    </li>
                    <li>
                      remove or alter any identification, proprietary, copyright
                      or other notices on the technology tool or the contents or
                      outputs;
                    </li>
                    <li>
                      interfere with or disrupt the operation of the technology
                      tool or any network, system or server connected to or used
                      in connection with the technology tool;
                    </li>
                    <li>
                      access or use the technology tool on any publicly
                      available equipment; or
                    </li>
                    <li>
                      bypass or attempt to bypass any of the access or security
                      controls or otherwise weaken or threaten the security of
                      the technology tool.
                    </li>
                  </ol>
                </li>
                <li>
                  Access. You agree to:
                  <ol type="i">
                    <li>
                      maintain your access credentials in strict confidence and
                      not share them with anyone else;
                    </li>
                    <li>
                      immediately notify us by sending an email to your PwC
                      contact or to
                      <span
                        ><a
                          href="mailto:gbl_transaction_data_assist_support@pwc.com"
                          target="_blank"
                          >gbl_transaction_data_assist_support@pwc.com</a
                        ></span
                      >
                      if anyone else obtains your access credentials so that we
                      can disable them and provide you with new credentials, or
                      you become aware of a violation, by any person, of any
                      part of these terms; and
                    </li>
                    <li>
                      tell your PwC contact immediately if you lose your access
                      credentials or know or suspect someone else may know or
                      have access to them.
                    </li>
                  </ol>
                </li>
                <li>
                  Incorrect access. You agree to notify us immediately, and then
                  exit the technology tool, if you access content or outputs on
                  it that are not intended for you.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <b>No liability to you</b>
              <ol type="a">
                <li>
                  Use is at your own risk. As between you and any PwC firm:
                  <ol type="i">
                    <li>
                      this technology tool, the content and outputs are provided
                      on an ‘as-is’ and “as available” basis;
                    </li>
                    <li>
                      to the extent permitted by law, no warranties or other
                      provisions are implied into these terms. We do not warrant
                      that the operation of the technology tool will be
                      uninterrupted or error-free, or that the technology tool,
                      the content and outputs will be fit for any particular
                      purpose or be free of errors or defects. We do not
                      guarantee that the technology tool will be secure or free
                      from bugs or viruses and you accept that you will use your
                      own virus protection software;
                    </li>
                    <li>
                      where the technology tool contains links to other sites
                      and resources provided by third parties, these links are
                      provided for your information only. Such links should not
                      be interpreted as approval by us of those linked websites
                      or information you may obtain from them. You accept that
                      we have no control over the contents of those sites or
                      resources;
                    </li>
                    <li>
                      to the extent permitted by law, no PwC firm accepts any
                      liability, responsibility or duty of care to you for the
                      technology tool, the content or outputs, or for any
                      consequences of you or anyone acting or refraining to act
                      in reliance on them, or for any decision based on them;
                      and
                    </li>
                    <li>
                      you acknowledge and accept that use of the technology tool
                      is subject to the risks inherent in any connection and
                      transmission on the internet, in particular, in relation
                      to security risks and vulnerabilities, technical
                      performance and risk of interruption. Accordingly, PwC is
                      not liable to you in any circumstances for any losses or
                      damages caused by disruption or failure of internet
                      networks or for any interruptions to or restrictions on
                      the accessibility of the technology tool.
                    </li>
                  </ol>
                </li>
                <li>
                  Liability: To the maximum extent permitted by law, we exclude
                  all liability for any loss or damage of whatever kind and
                  however arising in connection with your use of, or inability
                  to use, the technology tool and use of or reliance on any
                  contents or any materials you obtain via the technology tool.
                  We disclaim all liability and shall not be liable for any
                  indirect, incidental, special, consequential or punitive
                  damages, including without limitation damages for lost profits
                  or revenues, goodwill, work stoppage, security breaches,
                  viruses, compute failure or malfunction, use, data or other
                  intangible losses or commercial damages, even if we were
                  advised of the possibility of such losses, arising under or in
                  connection with these terms, the technology tool, the use of
                  or inability to use the same, or any other subject matter
                  hereof. Nothing in these terms excludes our liability for
                  fraud, or for anything else that cannot by law be limited.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <b>Suspension or termination</b>
              <ol type="a">
                <li>
                  Ending your access. Without any liability we may immediately
                  suspend or terminate access to the technology tool if you use
                  it other than as permitted by us, or if we consider that your
                  use of the technology tool could cause a detriment to us or
                  others, or for any other reason we require (e.g. maintenance,
                  or to make any changes or improvements).
                </li>
              </ol>
            </li>
            <br />
            <li>
              <b>Your inputs</b>
              <ol type="a">
                <li>
                  Your inputs. To the extent that you make or upload inputs of
                  any sort to the technology tool (which may include comments,
                  information, materials, data, or anything else), you grant: i)
                  us and our suppliers the right to host your inputs on the
                  technology tool or any other system we deem appropriate, and
                  use it to produce the outputs or for any other lawful business
                  purpose (which may include technical support and maintenance);
                  and ii) us and anyone we lawfully permit to access the
                  technology tool the ability to view, access and download those
                  inputs. You agree that you have all necessary rights in your
                  inputs to grant the rights set out in this section. No
                  payment, royalty or other consideration whatsoever will be
                  payable by us in connection with our use of such inputs. You
                  agree to reimburse us in full to the extent we incur any
                  liability as a result of your non-compliance with this
                  section. You agree that we will not be liable in any way for
                  any inputs entered by you into the technology tool.
                </li>
                <li>
                  Moderation - We may, in our discretion, moderate your inputs
                  and other content (including making content unavailable), for
                  example where the technology tool contains a discussion board
                  or other open forum. However, we are under no obligation to
                  oversee, monitor or moderate any content. The views expressed
                  by users of the technology tool do not necessarily represent
                  our views or values, nor are they supported or endorsed by us
                  or any PwC firm.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <b>Ownership</b>
              <ol type="a">
                <li>
                  Intellectual property rights. We and our licensors own this
                  technology tool, the content, and the outputs. You have a
                  limited, non- exclusive, non-transferable, non-sublicensable
                  licence to use them in accordance with these terms only. (Our
                  licensors may include an entity you work for).
                </li>
              </ol>
            </li>
            <br />
            <li>
              <b>Open Source</b>
              <ol type="a">
                <li>
                  Open source software. Portions of the technology tool may use
                  open source components, a list of which may be available on
                  the technology tool or is otherwise available from us on
                  request. The source code and open source licensing terms
                  applicable to each open source component can be obtained
                  either by clicking on the related link, or from us on request.
                </li>
                <li>
                  Open source licence terms prevail. In the event of a conflict
                  between the terms of any open source licence and these terms,
                  the open source licence terms shall prevail.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <b>General</b>
              <ol type="a">
                <li>
                  Disputes.
                  <ol type="i">
                    <li>
                      If a dispute arises, you and we will attempt to resolve it
                      by discussion, negotiation and mediation before commencing
                      legal proceedings.
                    </li>
                    <li>
                      These terms and any dispute arising from them, whether
                      contractual or non-contractual, will be governed by the
                      law of the territory in which we operate, and be subject
                      to the exclusive jurisdiction of the courts of that
                      territory.
                    </li>
                    <li>
                      Any claims must be brought no later than 2 years after the
                      date the claimant should have been aware of the potential
                      claim and, in any event, no later than 4 years after any
                      alleged breach.
                    </li>
                  </ol>
                </li>
                <li>
                  Matters beyond reasonable control. We will not be liable to
                  you if we fail to meet our obligations due to matters beyond
                  our reasonable control.
                </li>
                <li>
                  Rights of third parties. A person who is not a party to these
                  terms has no rights to enforce them.
                </li>
                <li>
                  Entire agreement. These terms form the entire agreement
                  between you and us in relation to the technology tool, the
                  content and the outputs. These terms replace any earlier
                  agreements, representations or discussions.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <b>Other</b>
              <ol type="a">
                <li>
                  Your personal data. All information, rights and obligations
                  concerning personal data in relation to the technology tool is
                  contained in its privacy statement.
                </li>
                <li>
                  System of record – The technology tool is not, and is not
                  designed to operate as, a system of record. You are solely
                  responsible for keeping any inputs added to the technology
                  tool, anything you wish to download from the technology tool,
                  and any copies required and you must do so in accordance with
                  these terms or as otherwise agreed in writing with us.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <b>Definitions</b>
              <ol type="a">
                <li>
                  The following words and expressions in these terms have the
                  following meanings:
                </li>
                <ol type="i">
                  <li>
                    <b>access credentials</b> - the username and password or
                    other access details that you may use to access the
                    technology tool
                  </li>
                  <li>
                    <b>content</b> - the information, data, material and any
                    other content on the technology tool, other than the outputs
                  </li>
                  <li>
                    <b>outputs</b> - any visualisation, graphic, calculation,
                    result or other output produced by the functionality of the
                    technology tool
                  </li>
                  <li>
                    <b>PwC firm</b> - a member firm in the PwC network, each of
                    which is a separate legal entity. For further details,
                    please see
                    <span
                      ><a
                        href="https://www.pwc.com/gx/en/about/corporate-governance/network-structure.html"
                        target="_blank"
                        >www.pwc.com/structure.</a
                      ></span
                    >
                  </li>
                  <li>
                    <b>these terms</b> - means the agreement formed by this
                    “terms of use” document
                  </li>
                </ol>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <!-- <div class="col-2"></div> -->
  </div>
</div>
