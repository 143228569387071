import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { JournalService } from 'src/app/common/services/journal/journal-service';
import { TaxoutcomereportserviceService } from 'src/app/common/services/taxoutcomereportservice/taxoutcomereportservice.service';

@Component({
  selector: 'app-tax-account-report',
  templateUrl: './tax-account-report.component.html',  
  styleUrls: ['./tax-account-report.component.scss']
})
export class TaxAccountReportComponent implements OnInit {
  TaxAccountReportForm!: FormGroup;
  taxAccountsSelected = [];
  businessTypes: any[]=[];
  selectedbusinessType: any;
  legalEntities: any[]=[];
  selectedLegalEntity: any | undefined;
  isLoading: boolean = false;
  public isChecked = false;
  public fiscalYear: any;
  public fiscalYearData:any[]=[];
  public selectedfiscalYear:any;
  public fiscalYears:any;
  public fiscalYearID : number=0;
  isExcludeData:boolean=false;
  constructor(private router: Router,
    private fb: FormBuilder,
    private journalService: JournalService,
    private globalService: GlobalService,
    private cookieService: CookieService,
    public toastr: ToastrManager,
    public taxoutcomereportserviceService:TaxoutcomereportserviceService) { 
      this.TaxAccountReportForm = this.fb.group({
        fiscalYears: [''],
        businessType: [''],
        legalEntity: ['']
      });
    }

    public filterSettings: DropDownFilterSettings = {
      caseSensitive: false,
      operator: 'contains',
    };

    
  ngOnInit(): void {
    let year: any = localStorage.getItem('selectedFiscalYear');
    if(year!=null && year!=undefined)
    {
      this.fiscalYear = JSON.parse(year);
      this.fiscalYearID=this.fiscalYear.FiscalYearId
    }
    this.getBusinessTypes();
  }

  handleBusinessTypeChange(businessType: any): void {
    this.selectedbusinessType = businessType;
    
    if (this.selectedbusinessType != undefined) {
      this.TaxAccountReportForm.controls['legalEntity'].reset();
      this.getLegalEntities(businessType.BusinessTypeId);
    }
  }

  handleLegalEntityChange(legalEntity: any): void {
    this.selectedLegalEntity = legalEntity;
  }

  // handlefiscalYearChange(event:any): void {
  //  this.selectedfiscalYear=event;
  // }

  getBusinessTypes(): void {
    this.isLoading = true;
    this.businessTypes = [];
    this.journalService.getAllBusinessTypeList().subscribe(
      (response) => {
        this.businessTypes = response;  
        if (this.businessTypes.length == 1) {
          this.selectedbusinessType = this.businessTypes[0];
          this.handleBusinessTypeChange(this.selectedbusinessType);
        }      
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getLegalEntities(businessTypeId: number): void {
    this.isLoading = true;
    this.selectedLegalEntity=undefined;
    this.legalEntities=[];
    let allOption:any={ID: 0,Description: 'All'}    
    this.journalService
    .getAllLegalEntityByTaxChartOfAccount(businessTypeId)
      .subscribe(
        (response) => {
          this.legalEntities = response;
          if (this.legalEntities.length == 1) {
            this.selectedLegalEntity = this.legalEntities[0];
            this.handleLegalEntityChange(this.selectedLegalEntity);
          }  
         else if (this.legalEntities!=undefined && this.legalEntities?.length > 0) {
         this.legalEntities.push(allOption);
         this.legalEntities=this.legalEntities.sort((a, b) => a.ID - b.ID);
          this.handleLegalEntityChange(this.legalEntities[0]);
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  // getFiscalYear(): void {
  //   this.isLoading = true;
  //   this.globalService
  //     .getAllFiscalYear(
  //     )
  //     .then(
  //       (response) => {
  //         this.fiscalYearData = response;
  //         if (this.fiscalYearData!=undefined && this.fiscalYearData?.length > 0) {
  //           let year: any = localStorage.getItem('selectedFiscalYear');
  //            this.fiscalYear = JSON.parse(year);
  //            var index = this.fiscalYearData
  //         .map((x: { FiscalYearId: any; }) => x.FiscalYearId)
  //         .indexOf(this.fiscalYear.FiscalYearId);
  //         this.handlefiscalYearChange(this.fiscalYearData[index]);
  //         }
  //         this.isLoading = false;
  //       },
  //       (error) => {
  //         console.log(error);
  //         this.isLoading = false;
  //       }
  //     );
  // }

  close() {
    this.router.navigate(['/home']);
  }

  run() {
    this.isLoading = true;
    
      let taxAccountReport:any={
        businesstypeID:this.selectedbusinessType.BusinessTypeId,
      legalEntity:this.selectedLegalEntity.ID,
      fiscalYear:this.fiscalYear.FiscalYearId,
      isExcludeData:this.isExcludeData
      }

      this.taxoutcomereportserviceService.getTaxAccountAnalysisReport(taxAccountReport).subscribe(
      (response:any) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const fileName = 'TaxAccountReport.xlsx';
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  complexCheckBox(event:any)
  {
    if(event!=null && event!=undefined)
     this.isExcludeData=event.checkboxState;
  }
}
