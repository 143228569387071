<!-- Header -->
<div class="header" style="font-size: 20px">Manage tax account</div>
<!-- new tax account form -->
<div class="container-fluid" style="padding: 0px 24px 10px 24px">
  <div class="row">
    <div class="col-md-8 col-xs-12">
      <h6>Business type - {{ changeDescription }}</h6>
      <form class="k-form" [formGroup]="taxAccountForm">
        <kendo-formfield>
          <kendo-label text="Tax account"></kendo-label>
          <input formControlName="taxAccount" kendoTextBox [maxlength]="50" />
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label text="Short description *"></kendo-label>
          <input
            kendoTextBox
            #shortDesc
            formControlName="shortDesc"
            [maxlength]="50"
          />
          <kendo-formerror>Please enter short Description</kendo-formerror>
        </kendo-formfield>
        <!-- <ap-field [title]="'Tax Account'">
          <input appkit-field formControlName="taxAccount" />
        </ap-field> -->
        <!-- <br /> -->
        <!-- <ap-field [title]="'Short Description'" [required]="true">
          <input appkit-field formControlName="shortDesc" />
          <div
            *ngIf="
              taxAccountForm.controls['shortDesc'].invalid &&
              (taxAccountForm.controls['shortDesc'].dirty ||
                taxAccountForm.controls['shortDesc'].touched)
            "
            id="errormessage"
            aria-live="polite"
            class="ap-field-email-validation-error"
          >
            Please enter short Description
          </div> </ap-field
        ><br /> -->
        <!-- <ap-field [title]="'Long Description'">
          <textarea
            appkit-field
            maxlength="420"
            formControlName="longDesc"
          ></textarea></ap-field
        > -->
        <kendo-formfield>
          <kendo-label text="Long description"></kendo-label>
          <kendo-textarea
            #longDesc
            [style.width.%]="100"
            [maxlength]="420"
            formControlName="longDesc"
          >
          </kendo-textarea>
          <!-- <input kendoTextArea formControlName="longDesc" /> -->
          <!-- <kendo-textarea
            [style.width.%]="100"
            resizable="auto"
            [maxlength]="420"
            [value]="longDesc"
          >
          </kendo-textarea> -->
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            [for]="indicator"
            text="P&L,B/S indicator*"
          ></kendo-label>
          <kendo-combobox
            #indicator
            required
            formControlName="indicator"
            [data]="dropdownIndicator"
            textField="label"
            valueField="value"
            [kendoDropDownFilter]="filterSettings"
          >
          </kendo-combobox>
          <kendo-formerror>P&L,B/S Indicator is required</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            [for]="parentTaxAccount"
            [text]="parentTaxAccLabel"
          ></kendo-label>
          <kendo-combobox
            #parentTaxAccount
            formControlName="parentTaxAccount"
            [data]="parentTaxAccountList"
            [(ngModel)]="selectedParentData"
            [filterable]="true"
            textField="shortdescription"
            valueField="TaxAccountCodeId"
            placeholder="Select parent tax account"
            (valueChange)="handleParentTaxAccountChange($event)"
            (filterChange)="handleFilterParentTaxaccount($event)"
          >
          </kendo-combobox>
          <kendo-formerror>Parent tax account is required</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="AccountClass" text="Account class*"></kendo-label>
          <kendo-combobox
            #AccountClass
            required
            formControlName="accountClass"
            [data]="AccountClassDescriptionList"
            textField="Description"
            valueField="ID"
            [filterable]="true"
            (valueChange)="handleAccountClassChange($event)"
            (filterChange)="handleFilterAccountClass($event)"
          >
          </kendo-combobox>
          <kendo-formerror>Account class is required</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="lineitem" text="Line item*"></kendo-label>
          <kendo-combobox
            #lineitem
            required
            formControlName="lineItem"
            [data]="lineItemList"
            textField="Description"
            valueField="ID"
            [filterable]="true"
            (valueChange)="handleLineItemChange($event)"
            (filterChange)="handleFilterLineItem($event)"
          >
          </kendo-combobox>
          <kendo-formerror>Line Item is required</kendo-formerror>
          <span class="showAllCheck">
            <ap-checkbox
              formControlName="showAll"
              (onChange)="onShowAllChange($event)"
            >
              <span>Show all</span>
            </ap-checkbox>
          </span>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            [for]="statutoryTreatment"
            text="Statutory treatment"
          ></kendo-label>
          <kendo-combobox
            #statutoryTreatment
            formControlName="statutoryTreatment"
            [data]="STList"
            textField="Description"
            valueField="Id"
            [filterable]="true"
            (valueChange)="handleSTChange($event)"
            (filterChange)="handleFilterSatuatory($event)"
          >
          </kendo-combobox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="taxOutcome" text="Tax outcome"></kendo-label>
          <kendo-combobox
            #taxOutcome
            formControlName="taxOutcome"
            [data]="TaxOutcomeList"
            textField="ShortDescription"
            valueField="_id"
            [filterable]="true"
            (valueChange)="handleTOChange($event)"
            (filterChange)="handleFilterTaxoutcome($event)"
          >
          </kendo-combobox>
        </kendo-formfield>
        <kendo-formfield>
          <span style="display: flex">
            <ap-checkbox
              formControlName="highlighted"
              (onChange)="onHighlightClick($event)"
            >
              <span>Highlighted</span>
            </ap-checkbox>
            <ap-checkbox
              formControlName="taxSpecific"
              (onChange)="onTaxSpecificClick($event)"
              style="margin-left: 16px"
            >
              <span>Tax specific</span>
            </ap-checkbox>
          </span>
        </kendo-formfield>
      </form>
      <!-- Click to Actions -->
      <div class="clickToActions">
        <ap-button
          class="cancelButton okButton"
          [btnType]="'secondary'"
          [label]="'Cancel'"
          (click)="cancelClick()"
        ></ap-button>
        <ap-button
          class="okButton cancelButton"
          [btnType]="'primary'"
          [label]="'Save'"
          [disabled]="taxAccountForm.invalid"
          themeColor="primary"
          (click)="saveNewTaxAccount()"
          style="margin-right: 6px"
        ></ap-button>
      </div>
    </div>
    <div class="col-md-4 col-xs-12"></div>
  </div>
</div>
