import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Worksheet } from 'src/app/_models/worksheet';

@Injectable({
  providedIn: 'root',
})
export class GlaccountimportService {
  private selectedClient: any;
  private baseUrl: string = '';
  private importAPIUrl: string = '';
  private apifolder: string = '';
  public apiUrl: any;
  private apiHost: string = '';
  tokenValue: any;
  constructor(
    public http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.importAPIUrl = environment.importapiUrl;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    this.apiUrl = {
      GetSheetNamesGLImport: '/GlAccountImport/TransactionGetSheetNames',
      ImportGlAccountPreprocessingStart: '/ImportGlAccountPreprocessingStart',
      GetImportGlAccountPreprocessingStatus:
        '/GetImportGlAccountPreprocessingStatus/',
      ImportGlAccountProcessingStart: '/ImportGlAccountProcessingStart',
      ImportGlAccountProcessingStatus: '/GetImportGlAccountProcessingStatus/',
      ExportGLaccountImportReportTextFile:
        '/ExportGlAccountImportReportTextFile',
      ImportGlAccountErrorRowExcelDownloadStart:
        '/ImportGlAccountErrorRowExcelDownloadStart',
      GetImportGlAccountErrorRowExcelDownloadStatus:
        '/GetImportGlAccountErrorRowExcelDownloadStatus/',
      ExportGlAccountErrorRowExcelFile: '/ExportGlAccountErrorRowExcelFile',
      ImportGLaccountTemplate:
        '/GlAccountImport/DownloadGlAccountImportTemplate',
    };
  }
  getSheetNamesGLaccountImport(fileName: string): Observable<Worksheet[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('fileName', fileName)
      .set('ClientdbName', this.selectedClient.DBName);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.GetSheetNamesGLImport;
    return this.http.get<Worksheet[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  importGLaccountPreProcessingStart(glprocessStartPayload: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.ImportGlAccountPreprocessingStart;
    return this.http.post(this.apiHost, glprocessStartPayload, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  importGlaccountPreProcessingStatus(instanceId: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.GetImportGlAccountPreprocessingStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  importGlAccountProcessingStart(tbprocessStartPayload: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.ImportGlAccountProcessingStart;
    return this.http.post(this.apiHost, tbprocessStartPayload, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  importGlAccountProcessingStatus(instanceId: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.ImportGlAccountProcessingStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  ExportGlImportReportTextFile(
    importReportTextFileName: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('glAccountImportReportTextFileName', importReportTextFileName);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.ExportGLaccountImportReportTextFile;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'text',
    });
  }
  ImportGLErrorRowExcelDownloadStart(downloadXLStartPayload: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.ImportGlAccountErrorRowExcelDownloadStart;
    return this.http.post(this.apiHost, downloadXLStartPayload, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  GetImportGLErrorRowExcelDownloadStatus(instanceId: string): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.GetImportGlAccountErrorRowExcelDownloadStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      observe: 'response',
      //responseType: 'blob' as 'json',
    });
  }
  downloadGlImportErrorExcelFile(
    importErrorRowExcelFileName: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('glAccountImportErrorRowExcelFileName', importErrorRowExcelFileName);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.ExportGlAccountErrorRowExcelFile;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
  downloadGlImportTemplateFile(): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.ImportGLaccountTemplate;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
