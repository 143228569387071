import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnauthorizedComponent } from './common/exception/unauthorized.component';
import { HomepageComponent } from './homepage/homepage.component';
import { AuthorizationService } from './common/authorization/authorization.service';
import { AnalysisReviewGridPopupComponent } from './analysisReview/analysis-review-grid-popup/analysis-review-grid-popup.component';
import { ManageJournalsComponent } from './journal/manage-journals/manage-journals.component';
import { ManageAdjustmentJournalComponent } from './journal/manage-adjustment-journal/manage-adjustment-journal.component';
import { ManageSelectJournalComponent } from './journal/manage-select-journal/manage-select-journal.component';
import { KeywordGroupFormListComponent } from './keyword/keyword-group-form-list/keyword-group-form-list.component';
import { KeywordImportComponent } from './keyword/keyword-import/keyword-import.component';
import { KeywordExportComponent } from './keyword/keyword-export/keyword-export.component';
import { TrialbalanceComponent } from './trialbalanceImport/trialbalance/trialbalance.component';
import { TransactionsComponent } from './transactionsImport/transactions/transactions.component';
import { BusinesstypegridpopupComponent } from './businessType/businesstypegridpopup/businesstypegridpopup.component';
import { AddbusinesstypeComponent } from './businessType/addbusinesstype/addbusinesstype.component';
import { LegalentitygridpopupComponent } from './legalEntity/legalentitygridpopup/legalentitygridpopup.component';
import { AddlegalentityComponent } from './legalEntity/addlegalentity/addlegalentity.component';
import { LineitemsComponent } from './lineitems/lineitems.component';
import { NewlineitemComponent } from './lineitems/newlineitem/newlineitem.component';
import { EditlineitemComponent } from './lineitems/editlineitem/editlineitem.component';
import { CopylineitemComponent } from './lineitems/copylineitem/copylineitem.component';
import { TaxaccountsgridpopupComponent } from './taxaccounts/taxaccountsgridpopup/taxaccountsgridpopup.component';
import { NewtaxaccountComponent } from './taxaccounts/newtaxaccount/newtaxaccount.component';
import { GLaccountsgridpopupComponent } from './glaccounts/glaccountsgridpopup/glaccountsgridpopup.component';
import { EditglaccountsComponent } from './glaccounts/editglaccounts/editglaccounts.component';
import { NewglaccountsComponent } from './glaccounts/newglaccounts/newglaccounts.component';
import { ManageAllocationRulesComponent } from './allocation-rules/manage-allocation-rules/manage-allocation-rules.component';
import { EditAllocationRulesComponent } from './allocation-rules/edit-allocation-rules/edit-allocation-rules.component';
import { MapGlAccountComponent } from './map-gl-account/map-gl-account.component';
import { HabitatsComponent } from './habitats/habitats.component';
import { ManagehabitatsComponent } from './habitats/managehabitats/managehabitats.component';
import { DefineExpressionsComponent } from './allocation-rules/define-expressions/define-expressions.component';
import { EditExpressionComponent } from './allocation-rules/edit-expression/edit-expression.component';
import { ManageMovementJournalComponent } from './journal/manage-movement-journal/manage-movement-journal.component';
import { AllocateTransactionComponent } from './Allocate/allocate-transaction/allocate-transaction.component';
import { NewExpressionComponent } from './allocation-rules/new-expression/new-expression.component';
import { AddKeywordComponent } from './allocation-rules/add-keyword/add-keyword.component';
import { ImportGlAccountComponent } from './Accounts/import-gl-account/import-gl-account.component';
import { OfflineReferralBreakdownLevelComponent } from './offlineReferral/offline-referral-breakdown-level/offline-referral-breakdown-level.component';
import { ManageOfflineReferralsComponent } from './manage-offline-referrals/manage-offline-referrals.component';
import { PreferenceComponent } from './Admin/preference/preference.component';
import { ManageTransactionLinesComponent } from './Admin/manage-transaction-lines/manage-transaction-lines.component';
import { AnalysisstatementgridpopupComponent } from './analysisStatement/analysisstatementgridpopup/analysisstatementgridpopup.component';
import { ManageCustomColumnComponent } from './Admin/TransactionCustomColumn/manage-custom-column/manage-custom-column.component';
import { StatementDetailsExportComponent } from './statement-details-export/statement-details-export.component';
import { CollectOfflineReferralComponent } from './offlineReferral/collect-offline-referral/collect-offline-referral.component';
import { TaxOutcomeComponent } from './analysisReview/tax-outcome/tax-outcome.component';
import { MasterDataComponent } from './master-data/master-data.component';
import { ExportAnalysisStatementsComponent } from './Export/export-analysis-statements/export-analysis-statements.component';
import { ImportStatementDetailsComponent } from './import-statement-details/import-statement-details.component';
import { ManageAttachementComponent } from './Allocate/manage-attachement/manage-attachement.component';
import { UnlocksegmentComponent } from './Admin/unlocksegment/unlocksegment.component';
import { ComparativeAnalysisComponent } from './analysisReview/comparative-analysis/comparative-analysis.component';
import { TaxAccountReportComponent } from './analysisReview/tax-account-report/tax-account-report.component';
import { StatutorytreatmentComponent } from './statutorytreatment/statutorytreatment.component';
import { TaxoutcomesComponent } from './taxoutcomes/taxoutcomes.component';
import { GlAccountRunComponent } from './gl-account-run/gl-account-run.component';
import { TermsanduseComponent } from './termsanduse/termsanduse.component';
import { PrivacystatementComponent } from './privacystatement/privacystatement.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomepageComponent,
  },
  {
    path: 'analysisreview',
    component: AnalysisReviewGridPopupComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageJournal',
    component: ManageJournalsComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageAdjustmentJournal/edit/:id',
    component: ManageAdjustmentJournalComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageAdjustmentJournal',
    component: ManageAdjustmentJournalComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageMovementJournal',
    component: ManageMovementJournalComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageMovementJournal/edit/:id',
    component: ManageMovementJournalComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageMovementJournal/:CallingType',
    component: ManageMovementJournalComponent,
    canActivate: [AuthorizationService],
  },

  {
    path: 'transactionsAllocate',
    component: AllocateTransactionComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageSelectJournal',
    component: ManageSelectJournalComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageKeyword',
    component: KeywordGroupFormListComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageKeywordImport',
    component: KeywordImportComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageKeywordExport',
    component: KeywordExportComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'trialBalanceImport',
    component: TrialbalanceComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'transactionsImport',
    component: TransactionsComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'ImportglAccounts',
    component: ImportGlAccountComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageBusinessType',
    component: BusinesstypegridpopupComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'addBusinessType',
    component: AddbusinesstypeComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'legalEntities',
    component: LegalentitygridpopupComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'addLegalEntities',
    component: AddlegalentityComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageanalysisstatementlayouts',
    component: AnalysisstatementgridpopupComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'lineItems',
    component: LineitemsComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'addNewLineItem',
    component: NewlineitemComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'editLineItem',
    component: EditlineitemComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'copyLineItem',
    component: CopylineitemComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'newTaxAccount',
    component: NewtaxaccountComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'taxAccounts',
    component: TaxaccountsgridpopupComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'glAccounts',
    component: GLaccountsgridpopupComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'editGLAccount',
    component: EditglaccountsComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'newGLAccounts',
    component: NewglaccountsComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageAllocationRule',
    component: ManageAllocationRulesComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'editAllocationRule/edit/:id',
    component: EditAllocationRulesComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'newAllocationRule/:id',
    component: EditAllocationRulesComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'mapGlAccounts',
    component: MapGlAccountComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'habitats',
    component: HabitatsComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageHabitats',
    component: ManagehabitatsComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'editAllocationRule/copy/:id',
    component: EditAllocationRulesComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'defineExpressions',
    component: DefineExpressionsComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'editExpression',
    component: EditExpressionComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'editExpression/edit/:id',
    component: EditExpressionComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'copyExpression/copy/:id',
    component: EditExpressionComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'newExpression/:id',
    component: NewExpressionComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'addKeyword',
    component: AddKeywordComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'analysisreview/treeViewReport/TaxOutComeReport',
    component: AnalysisReviewGridPopupComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'analysisreview/treeViewReport/ComparativeAnalysisReport',
    component: AnalysisReviewGridPopupComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'Preference',
    component: PreferenceComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'offlineReferralBreakdownLevel',
    component: OfflineReferralBreakdownLevelComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'offlineReferralBreakdownLevel/:CallingType/:FiscalYear/:LegalEntityId/:TaxAccount',
    component: OfflineReferralBreakdownLevelComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageofflineReferrals',
    component: ManageOfflineReferralsComponent,
    canActivate: [AuthorizationService],
  },

  {
    path: 'manageTransactionLines',
    component: ManageTransactionLinesComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageCustomColumn',
    component: ManageCustomColumnComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'masterData',
    component: MasterDataComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'glAccountRun',
    component: GlAccountRunComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageStatementDetailsExport',
    component: StatementDetailsExportComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'collectofflineReferrals',
    component: CollectOfflineReferralComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'taxOutComeOptions',
    component: TaxOutcomeComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'exportAnalysisStatements',
    component: ExportAnalysisStatementsComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'importAnalysisStatements',
    component: ImportStatementDetailsComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'manageAttachment',
    component: ManageAttachementComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'unlocksegment',
    component: UnlocksegmentComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'ComparativeAnalysisReport',
    component: ComparativeAnalysisComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'TaxAccountReport',
    component: TaxAccountReportComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'statutoryTreatment',
    component: StatutorytreatmentComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'taxOutcomes',
    component: TaxoutcomesComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'termsofuse',
    component: TermsanduseComponent,
    canActivate: [AuthorizationService],
  },
  {
    path: 'privacystatement',
    component: PrivacystatementComponent,
    canActivate: [AuthorizationService],
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', redirectTo: '/home', canActivate: [AuthorizationService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
