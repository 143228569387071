import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AnalysisReviewService {
  private params: any;
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private mockApiUrl: any;
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    //  this.apiPort = environment.apiPort;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      addLineItem: '/AnalysisTreeView/AddLinkLineItem',
      updateLineItem: '/LineItem/UpdateLineItem',

      deleteLineItem: '/AnalysisTreeView/DeleteLineItemWithTaxAccount',
      insertLineItem: '/LineItem/InsertLineItem',
      getTaxAccountByLineItem: '/AnalysisTreeView/GetTaxAccountByLineItem',
      deleteAttachment: '/AnalysisTreeView/DeleteSupplimentaryExcel',
      uploadSupplementaryFile: '/AnalysisTreeView/UploadSupplementaryFile'
    };
  }

  insertAnalysisLineItem(request: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );

    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.addLineItem;
    return this.http
      .post(this.apiHost, request, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        if (err.error == 'Reference Constraint Violated : 500') {
          return err.error;
        }
      });
  }

  insertLineItem(_LIobject: {
    AccountingAreaID: any;
    Description: any;
    RequireFurtherAnalysis: boolean;
    AccountDerivation: null;
    AmountDisplayIndicator: boolean;
    TaxChartOfAccounts: number;
    AccountClass: number;
    AccountAreaGroup: null;
    StatementLevel: null;
    AccountingArea: number;
    HelpId: string;
  }): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.insertLineItem;
    return this.http
      .post(this.apiHost, JSON.stringify(_LIobject), {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  updateLineItem(LIDetails: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.updateLineItem;
    return this.http
      .post(this.apiHost, LIDetails, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  deleteLineItem(LineItemId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('lineItemID', LineItemId);

    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.deleteLineItem;

    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }

  getTaxAccountByLineItem(LineItemId: any): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('lineItemID', LineItemId);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getTaxAccountByLineItem;

    return this.http.get<any>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  deleteAttachment(ids: string,deleteoption:boolean,fiscalYear:number): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' +
        JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('supplimentaryId', ids)
      .set('fiscalYear', fiscalYear)
      .set('isSoftDelete', deleteoption);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteAttachment;
    return this.http.delete<string>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }
}
