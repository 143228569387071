import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class DashboardserviceService {
  private params: any;
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private mockApiUrl: any;
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    //  this.apiPort = environment.apiPort;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getDashBoardGLAccount: '/DashBoardEnlighten/GetDashBoardGLAccount',
      getDashBoardChartData: '/DashBoardEnlighten/GetDashBoardChartData',
      getDashBoardSummary: '/DashBoardEnlighten/GetDashBoardSummary',
      GetManageOfflineReferralsByFiscalYear: '/OfflineReferral/GetManageOfflineReferralsByFiscalYear',
      getTransactrionByGLAccount: '/DashBoardEnlighten/GetTransactrionByGLAccount'
    };
  }

  fetchDashBoardGLAccount(fiscalYear: number): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getDashBoardGLAccount;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }

  fetchDashBoardChartData(fiscalYear: number): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getDashBoardChartData;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }
  getDashBoardSummary(fiscalYear: number): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = { 'Content-Type': 'application/json', 'Authorization-Token': 'Bearer ' + JSON.parse(this.tokenValue).access_token, };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName).set('fiscalYear', fiscalYear);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getDashBoardSummary;
    return this.http.get(this.apiHost, {
      headers: headersObj, withCredentials: false, params: httpParams,
    })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }

  getManageOfflineReferralsByFiscalYear(fiscalYear: number): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = { 'Content-Type': 'application/json', 'Authorization-Token': 'Bearer ' + JSON.parse(this.tokenValue).access_token, };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName).set('fiscalYear', fiscalYear);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.GetManageOfflineReferralsByFiscalYear;
    return this.http.get(this.apiHost, {
      headers: headersObj, withCredentials: false, params: httpParams,
    })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }
  getTransactrionByGLAccount(fiscalYear: number, glAccount: number): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear)
      .set('glAccount', glAccount);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getTransactrionByGLAccount;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }

}
