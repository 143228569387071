import { Component, OnInit, Input } from '@angular/core';
import {
  DialogContentBase,
  ActionsLayout,
  DialogAction,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from '@progress/kendo-angular-dialog';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { LineitemsComponent } from '../lineitems.component';
import { FormControl, FormGroup } from '@angular/forms';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-deletelineitem',
  templateUrl: './deletelineitem.component.html',
  styleUrls: ['./deletelineitem.component.scss'],
})
export class DeletelineitemComponent
  extends DialogContentBase
  implements OnInit
{
  @Input() public BusinessType: any;
  @Input() public delLIdata: any;

  dialogTitle: any = 'Delete Line items(s)';
  confirmText: any;
  warnondelete: boolean = false;
  public actionsLayout: ActionsLayout = 'normal';

  delLIDetails: any;
  deleteLineItemId: any;
  responceData: any;
  BusinessTypeId: any;
  BusinessDesc: any;
  isInfo: boolean = false;

  constructor(
    public dialog: DialogRef,
    public toastr: ToastrManager,
    private router: Router,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    this.BusinessTypeId = this.BusinessType.BusinessTypeId;
    this.BusinessDesc = this.BusinessType.Description;
  }

  cancelClick() {
    this.dialog.close();
    // const dialogRef =this.dialogService.open({
    //   content: LineitemsComponent,
    //   width: 1200,
    //   height:600
    // });
    // const editLineItem = dialogRef.content.instance as LineitemsComponent;
    // var defaultBT ={"BusinessTypeId":this.BusinessTypeId,"Description":this.BusinessDesc,"Library":false,"CountryName":"UNITED KINGDOM","IndustryName":null,"HelpId":"","CountryId":231,"IndustryId":null};
    // dialogRef.result.subscribe((r: any) => {

    // });
    // editLineItem.isLoadId = true;
    // editLineItem.lineItemsGridForm = new FormGroup({
    //   businessTypeId: new FormControl(defaultBT)
    // });
  }

  deleteLineItem() {
    this.globalService
      .deleteLineItem(this.delLIdata)
      .then((res: any) => {
        this.responceData = res;
        if (
          res.includes(
            'Are you sure you wish to continue and delete the specified mappings'
          )
        )
          this.isInfo = false;
        else this.isInfo = true;
        if (res == 'Line Item is deleted successfully') {
          this.toastr.successToastr(
            'Line item is deleted successfully',
            'Success'
          );
          this.dialog.close();
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() =>
              this.router.navigate(['/lineItems'], {
                state: {
                  BusinessType: this.BusinessType,
                },
              })
            );
        } else if (res != 'LineItem is deleted successfully') {
          this.warnondelete = true;
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      })
      .catch((err: any) => {});
  }
  deleteLinkLineItem() {
    this.globalService
      .deleteLinkLineItem(this.delLIdata)
      .then((res: any) => {
        if (res == 'Line Item is deleted successfully') {
          this.toastr.successToastr(
            'Line item is deleted successfully',
            'Success'
          );
          this.dialog.close();
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() =>
              this.router.navigate(['/lineItems'], {
                state: {
                  BusinessType: this.BusinessType,
                },
              })
            );
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      })
      .catch((err: any) => {});
  }
}
