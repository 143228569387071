<kendo-dialog-titlebar (close)="closeButtonClick()">
  <div
    style="
      font-size: 18px;
      line-height: 1.1em;

      font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) 24px/24px
        var(--unnamed-font-family-helvetica-neue-lt-pro);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--default-color);
      text-align: left;
      font: normal normal medium 24px/24px Helvetica Neue LT Pro;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    "
  >
    Custom column details
  </div>
</kendo-dialog-titlebar>
<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>

<div class="row">
  <div class="col">
    <kendo-grid
      [data]="gridData"
      scrollable="virtual"
      [height]="350"
      width="50"
    >
      <kendo-grid-column
        field="StaticColumn"
        title="Column name"
        [width]="220"
        [headerStyle]="{
          'font-weight': 'bold',
          'text-align': 'left',
          color: '#d04a02'
        }"
      ></kendo-grid-column>
      <kendo-grid-column
        field="ColumnName"
        title="Updated name"
        [width]="320"
        [headerStyle]="{
          'font-weight': 'bold',
          'text-align': 'left',
          color: '#d04a02'
        }"
      ></kendo-grid-column>
    </kendo-grid>
  </div>
</div>
