<kendo-dialog-titlebar (close)="cancelClick()">
    <div style="font-size: 18px; line-height: 1.1em;">
         Merge tax outcomes
    </div>
</kendo-dialog-titlebar>

<p>
       Please select the main tax outcome you wish to merge the selected taxoutcomes info
</p>
 <label for="defaultTaxOutcomeList" style="font-size:14px">Default tax outcome</label><br/>
<kendo-combobox
[style.width]="'100%'"
  [data]="defaultTaxOutcomeList"
  [(ngModel)]="selectedItem"
  [filterable]="true"
  textField="ShortDescription"
  valueField="_id"
>
</kendo-combobox>

<div class="clickToActions" style="padding: 10px 0px 0px 0px">
    <ap-button
      [btnType]="'secondary'"
      [label]="'Cancel'"
      (click)="cancelClick()"
      class="cancelButton"
      style="width: 100px;"
  
    ></ap-button>
    <ap-button
      [btnType]="'primary'"
      [label]="'Save'"
      themeColor="primary"      
      (click)="save()"
      class="okButton"
      style="margin-right: 6px"
    ></ap-button>
  </div>