import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AnalysisstatementmoveService } from 'src/app/common/services/analysisstatementmove/analysisstatementmove.service';
import { LineitemmoveService } from 'src/app/common/services/lineitemmoveservice/lineitemmove.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-move-sub-menu-popup',
  templateUrl: './move-sub-menu-popup.component.html',
  styleUrls: ['./move-sub-menu-popup.component.scss'],
})
export class MoveSubMenuPopupComponent implements OnInit {
  @Input() public nodeData: any;
  @Input() public lineItemID: any;
  public openedMove: boolean = false;
  public form: FormGroup;
  public analysisStatements: any = '';

  public statementStatus = {
    Top: false,
    Bottom: false,
    Up: false,
    Down: false,
  };

  // public isDisabled:boolean=false;
  statementDetails: any;

  public dataform: { [Key: string]: string } = {
    confirmation: '',
    gender: '',
  };

  constructor(
    private router: Router,
    public dialog: DialogRef,
    private globalService: GlobalService,
    private analysisstatementmoveService: AnalysisstatementmoveService,
    private lineitemmoveService: LineitemmoveService,
    public toastr: ToastrManager
  ) {
    this.form = new FormGroup({
      confirmation: new FormControl(this.dataform.confirmation, [
        Validators.required,
      ]),
    });
  }
  ngOnInit(): void {
    if (this.nodeData.type == 'AS' || this.nodeData.type == 'SS') {
      this.getStatementOrderDetails();
    }
    if (this.nodeData.type == 'LI' || this.nodeData.type == 'CLI') {
      this.getLineItemOrderDetails();
    }
  }
  cancelClick() {
    this.dialog.close();
  }
  closeButtonClick() {
    this.dialog.close();
  }
  public OpenOnClickOfMove(): void {
    this.openedMove = true;
  }
  getStatementOrderDetails() {
    this.analysisstatementmoveService
      .getStatementOrder(this.nodeData.id)
      .subscribe(
        (response) => {
          this.statementStatus = response;

          if (response) {
            this.statementStatus = response;
          } else
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
        },
        (error: any) => {
          console.log(error);
        },
        () => console.log('Success')
      );
  }
  moveStatUp(): void {
    this.analysisstatementmoveService
      .moveAnalysisStatementUp(this.nodeData.id)
      .subscribe(
        (response: any) => {
          if (response) {
            this.toastr.successToastr(response, 'Moved to up successfully');
            this.cancelClick();
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
          }
        },
        (error: any) => {
          console.log(error);
        },
        () => console.log('Success')
      );
  }
  moveStatDown(): void {
    this.analysisstatementmoveService
      .moveAnalysisStatementDown(this.nodeData.id)
      .subscribe(
        (response: any) => {
          if (response) {
            this.toastr.successToastr(response, 'Moved to down successfully');
            this.cancelClick();
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
          }
        },
        (error: any) => {
          console.log(error);
        },
        () => console.log('Success')
      );
  }
  moveStatTop(): void {
    this.analysisstatementmoveService
      .moveAnalysisStatementTop(this.nodeData.id)
      .subscribe(
        (response: any) => {
          if (response) {
            this.toastr.successToastr(response, 'Moved to top successfully');
            this.cancelClick();
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
          }
        },
        (error: any) => {
          console.log(error);
        },
        () => console.log('Success')
      );
  }
  moveStatBottom(): void {
    this.analysisstatementmoveService
      .moveAnalysisStatementBottom(this.nodeData.id)
      .subscribe(
        (response: any) => {
          if (response) {
            this.toastr.successToastr(response, 'Moved to bottom successfully');
            this.cancelClick();
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
          }
        },
        (error: any) => {
          console.log(error);
        },
        () => console.log('Success')
      );
  }
  getLineItemOrderDetails() {
    this.lineitemmoveService
      .getLineItemOrderDetails(this.nodeData.Satementid, this.nodeData.id)
      .subscribe(
        (response) => {
          if (response) {
            this.statementStatus = response;
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
          }
        },
        (error: any) => {
          console.log(error);
        },
        () => console.log('Success')
      );
  }
  moveLineItemTop(): void {
    this.lineitemmoveService
      .moveLineItemTop(this.nodeData.Satementid, this.nodeData.id)
      .subscribe(
        (response: any) => {
          if (response) {
            this.toastr.successToastr(response, 'Moved to top successfully');
            this.cancelClick();
          } else
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
        },
        (error: any) => {
          console.log(error);
        },
        () => console.log('Success')
      );
  }
  moveLineItemUp(): void {
    this.lineitemmoveService
      .moveLineItemUp(this.nodeData.Satementid, this.nodeData.id)
      .subscribe(
        (response: any) => {
          if (response) {
            this.toastr.successToastr(response, 'Moved to up successfully');
            this.cancelClick();
          } else
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
        },
        (error: any) => {
          console.log(error);
        },
        () => console.log('Success')
      );
  }

  moveLineItemBottom(): void {
    this.lineitemmoveService
      .moveLineItemBottom(this.nodeData.Satementid, this.nodeData.id)
      .subscribe(
        (response: any) => {
          if (response) {
            this.toastr.successToastr(response, 'Moved to bottom successfully');
            this.cancelClick();
          } else
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
        },
        (error: any) => {
          console.log(error);
        },
        () => console.log('Success')
      );
  }
  moveLineItemDown(): void {
    this.lineitemmoveService
      .moveLineItemDown(this.nodeData.Satementid, this.nodeData.id)
      .subscribe(
        (response: any) => {
          if (response) {
            this.toastr.successToastr(response, 'Moved to down successfully');
            this.cancelClick();
          } else
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
        },
        (error: any) => {
          console.log(error);
        },
        () => console.log('Success')
      );
  }
  displaytreePack() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }
  onChange(e: any) {
    this.analysisStatements = e.target.value;
  }
  moveProceed() {
    let selectedValue = this.analysisStatements.toString().toLowerCase();
    if (selectedValue == 'up' && this.nodeData.type == 'AS') {
      this.moveStatUp();
    }
    if (selectedValue == 'down' && this.nodeData.type == 'AS') {
      this.moveStatDown();
    }
    if (selectedValue == 'top' && this.nodeData.type == 'AS') {
      this.moveStatTop();
    }
    if (selectedValue == 'bottom' && this.nodeData.type == 'AS') {
      this.moveStatBottom();
    }
    if (
      selectedValue == 'up' &&
      (this.nodeData.type == 'LI' || this.nodeData.type == 'CLI')
    ) {
      this.moveLineItemUp();
    }
    if (
      selectedValue == 'down' &&
      (this.nodeData.type == 'LI' || this.nodeData.type == 'CLI')
    ) {
      this.moveLineItemDown();
    }
    if (
      selectedValue == 'top' &&
      (this.nodeData.type == 'LI' || this.nodeData.type == 'CLI')
    ) {
      this.moveLineItemTop();
    }
    if (
      selectedValue == 'bottom' &&
      (this.nodeData.type == 'LI' || this.nodeData.type == 'CLI')
    ) {
      this.moveLineItemBottom();
    }
    this.displaytreePack();
  }
}
