import { Component, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from './common/authentication/authentication.service';
import { GlobalService } from './common/globalservice/globalservice.service';
import { GlaccountimportService } from './common/services/importService/glaccountimport.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MyGlobalObject } from './common/myglobalobject';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { filter, mergeMap, switchMap } from 'rxjs/operators';
import { interval, observable, Observable } from 'rxjs';
import Swal from 'sweetalert2';
import getPkce from 'oauth-pkce';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  DialogCloseResult,
  DialogRef,
  DialogService,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { AuthorizationService } from './common/authorization/authorization.service';
import { ClientselectpopupComponent } from './clientselectpopup/clientselectpopup.component';
import { MenuComponent } from './menu/menu.component';
import { MapGlAccountComponent } from './map-gl-account/map-gl-account.component';
import { BusinesstypegridpopupComponent } from './businessType/businesstypegridpopup/businesstypegridpopup.component';
import { MenuSelectEvent } from '@progress/kendo-angular-menu';
import { AnalysisstatementgridpopupComponent } from './analysisStatement/analysisstatementgridpopup/analysisstatementgridpopup.component';
import { GLaccountsgridpopupComponent } from './glaccounts/glaccountsgridpopup/glaccountsgridpopup.component';
import { HabitatsComponent } from './habitats/habitats.component';
import { KeywordGroupFormListComponent } from './keyword/keyword-group-form-list/keyword-group-form-list.component';
import { LegalentitygridpopupComponent } from './legalEntity/legalentitygridpopup/legalentitygridpopup.component';
import { LineitemsComponent } from './lineitems/lineitems.component';
import { TaxaccountsgridpopupComponent } from './taxaccounts/taxaccountsgridpopup/taxaccountsgridpopup.component';
import { MenubarService } from './common/menubarService/menubar.service';
import { LockstatusComponent } from './lockstatus/lockstatus.component';
import { HelpComponent } from './help/help.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title = 'Enlighten-Portal-UI';
  user: any;
  initials: any;
  isVisited: any = 'false';
  displaySelectedClient: any;
  ismenuVisible: boolean = true;
  idleState = 'Not started.';
  timedOut = false;
  lastPing: any;
  isAdmin: boolean = false;
  selectYearList: any = [];
  selectYearListDB: any = [];
  selectedFiscalYear: any;
  public item = [{ text: 'Options' }, { text: 'Toggle' }];
  menu = [
    {
      text: 'Logout',
    },
  ];
  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    public myGlobalObject: MyGlobalObject,
    private globalService: GlobalService,
    private glaccountimportService: GlaccountimportService,
    private authenticationService: AuthenticationService,
    private auth: AuthorizationService,
    private dialogService: DialogService,
    private windowService: WindowService,
    private router: Router,
    private cookieService: CookieService,
    public toastr: ToastrManager,
    public menubarService: MenubarService
  ) {
    // sets an idle timeout of 1080 seconds.
    idle.setIdle(1080);
    // sets a timeout period of 60 seconds. after 1080 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      // this.cookieService.set('OPENAMSESSION', '');
      // this.cookieService.delete('OPENAMSESSION');
      localStorage.removeItem('OPENAMSESSION');
      localStorage.removeItem('tokenCreated');
      // this.cookieService.set('SELECTEDCLIENT', '');
      // this.cookieService.delete('SELECTEDCLIENT');
      localStorage.removeItem('SELECTEDCLIENT');
      localStorage.removeItem('selectedFiscalYear');
      localStorage.removeItem('isVisited');
      localStorage.removeItem('verifier');
      localStorage.removeItem('selectedTreenode');
      window.location.href = environment.openam.logoutUrl;
    });
    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
    });
    idle.onTimeoutWarning.subscribe((countdown: any) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      if (countdown >= 60) {
        let timerInterval;
        Swal.fire({
          title: 'Alert!',
          html: 'You will time out in <b></b> seconds!',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#736b53',
          confirmButtonText: 'Resume',
          allowOutsideClick: false,
          timer: 60000,
          didOpen: () => {
            const b = (Swal.getHtmlContainer() as any).querySelector('b');
            timerInterval = setInterval(() => {
              b.textContent =
                ((Swal.getTimerLeft() as any) / 1000).toFixed(0) + '';
            }, 100);
          },
        }).then((result: any) => {
          if (result.isConfirmed) {
            this.reset();
          }
        });
      }
    });
    // sets the ping interval to 15 seconds
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
    this.reset();
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
  ngOnInit() {
    this.reset();
    // interval(20 * 60 * 1000)
    //   .pipe(
    //     switchMap(() => {
    //       let tokenValue: any = localStorage.getItem('OPENAMSESSION');
    //       return this.globalService.getTokenDetailsFromRefreshToken(
    //         JSON.parse(tokenValue).refresh_token
    //       );
    //     })
    //   )
    //   .subscribe((data) => {
    //     // this.cookieService.set('OPENAMSESSION', JSON.stringify(data));
    //     localStorage.setItem('OPENAMSESSION', JSON.stringify(data));
    //     localStorage.setItem('tokenCreated', Date.now().toString());
    //   });
    //alert(localStorage.getItem('isVisited'));

    var createdTime: any;
    createdTime = localStorage.getItem('tokenCreated')
      ? localStorage.getItem('tokenCreated')
      : ' ';

    var now = Date.now().toString();
    var a: any = new Date(parseInt(createdTime));
    var b: any = new Date();
    var check: any = a - b;
    var diff;
    var token: any = localStorage.getItem('OPENAMSESSION');
    if (token != 'undefined') {
      diff = Math.abs(check) / (60 * 60 * 1000) > 0.96;
    }
    var isVisitedFlag = localStorage.getItem('isVisited');
    if (isVisitedFlag) {
      this.isVisited = 'true';
      if (token != 'undefined' && diff) {
        if (Math.abs(check) / (60 * 60 * 1000) > 24) {
          localStorage.removeItem('OPENAMSESSION');
          localStorage.removeItem('tokenCreated');
          this.isVisited = 'false';
        } else {
          var tokenValue: any = localStorage.getItem('OPENAMSESSION');
          this.globalService
            .getTokenDetailsFromRefreshToken(
              JSON.parse(tokenValue).refresh_token
            )
            .subscribe(
              (data) => {
                localStorage.setItem('OPENAMSESSION', JSON.stringify(data));
                localStorage.setItem('tokenCreated', Date.now().toString());
              },
              (error) => {
                this.isVisited = false;
              }
            );
        }
      }
    } else {
      this.isVisited = 'false';
    }
    // this.isVisited = localStorage.getItem('isVisited')
    //   ? localStorage.getItem('isVisited')
    //   : 'false';
    if (this.isVisited == 'true') {
      this.authenticationService.checkAuthentication().subscribe((result) => {
        //this.cookieService.set('OPENAMSESSION', JSON.stringify(result));
        localStorage.setItem('OPENAMSESSION', JSON.stringify(result));
        localStorage.setItem('tokenCreated', Date.now().toString());
        //console.log(localStorage.getItem('OPENAMSESSION'));
        this.authenticationService.getUserInfo(result).then((response) => {
          this.user = response;
          this.initials =
            this.user.given_name.charAt(0).toUpperCase() +
            this.user.family_name.charAt(0).toUpperCase();
          this.myGlobalObject.loggedinUserDetail = response;
          if (localStorage.getItem('SELECTEDCLIENT')) {
            let selected: any = localStorage.getItem('SELECTEDCLIENT');
            this.displaySelectedClient = JSON.parse(selected);
            this.fetchClientPreferences();
            this.getAllFiscalYear();
            let year: any = localStorage.getItem('selectedFiscalYear');
            if (year == null || year == undefined) {
              this.getLatestFiscalYear();
            } else {
              this.selectedFiscalYear = JSON.parse(year).FiscalYearId;
            }

            // if (this.myGlobalObject.selectedFiscalYear == undefined)
          }
          this.globalService
            .getLoggedUserInfo()
            .then((response) => {
              this.myGlobalObject.userDetailsEnlightenPortal = response;
              if (response != undefined) {
                if (response == 'Super Admin' || response == 'Admin') {
                  this.isAdmin = true;
                } else {
                  this.isAdmin = false;
                }
                this.fetchClientList();
              }
            })
            .catch((error) => {
              this.router.navigate(['/unauthorized']);
            });
          if (this.myGlobalObject.userDetailsEnlightenPortal !== undefined) {
            if (localStorage.getItem('SELECTEDCLIENT')) {
              let selected: any = localStorage.getItem('SELECTEDCLIENT');
              this.displaySelectedClient = JSON.parse(selected);
            }
            this.checkLockStatus('Import transactions');
            this.checkLockStatus('Import trial balance');
          }
        });
      });
    }
  }
  goto() {
    localStorage.setItem('isVisited', 'true');
    this.isVisited = 'true';
    var challengeVerifier = { verifier: '', challenge: '' };
    getPkce(50, (error, { verifier, challenge }) => {
      if (!error) {
        //console.log({ verifier, challenge });
        challengeVerifier = { verifier, challenge };
        //console.log(challengeVerifier);
        localStorage.setItem('verifier', challengeVerifier.verifier);
        this.authenticationService.performLogin(challengeVerifier.challenge);
      }
    });
  }

  fetchClientList() {
    this.globalService
      .fetchClientsList()
      .then((res) => {
        this.myGlobalObject.mappedClientsForUser = res;
        if (
          localStorage.getItem('SELECTEDCLIENT') == '' ||
          !localStorage.getItem('SELECTEDCLIENT')
        ) {
          if (res.length < 1) {
            this.router.navigate(['/unauthorized']);
          } else {
            this.openDialogForm();
          }
        }
      })
      .catch((err) => {});
  }

  fetchClientPreferences() {
    this.globalService
      .fetchClientPreferences()
      .then((res) => {
        this.myGlobalObject.clientPreferences = res;
      })
      .catch((err) => {});
  }
  getAllFiscalYear() {
    this.globalService
      .getAllFiscalYear()
      .then((res) => {
        let selectObj = {
          id: 1,
          FiscalYearId: 1,
          Display: 'Select year',
          RolledForward: false,
        };
        this.selectYearListDB = res;
        this.selectYearList = res;
        this.selectYearList.forEach((object: any) => {
          object.parentId = 1;
        });
        this.selectYearList.unshift(selectObj);
      })
      .catch((err) => {});
  }
  getLatestFiscalYear() {
    this.globalService
      .getLatestFiscalYear()
      .then((res) => {
        localStorage.setItem('selectedFiscalYear', JSON.stringify(res));
        this.selectedFiscalYear = res.FiscalYearId;
      })
      .catch((err) => {});
  }

  UnlockSegment(segmentName: string) {
    this.globalService.UnLockSegment(segmentName).subscribe(
      (response) => {},
      (error) => {
        console.log(error);
      }
    );
  }
  checkLockStatus(segmentName: string) {
    var details: any;
    this.globalService.segmentLockDetail(segmentName).subscribe(
      (response) => {
        details = response;
        if (
          details.IsSegmentLocked &&
          details.LockOwners[0] == this.myGlobalObject.loggedinUserDetail.mail
        )
          this.UnlockSegment(segmentName);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  public openDialogForm(): void {
    const dialogRef = this.dialogService.open({
      content: ClientselectpopupComponent,
      width: 500,
      height: 'auto',
    });

    const clientInfo = dialogRef.content.instance as ClientselectpopupComponent;
    dialogRef.result.subscribe((r: any) => {
      if (r.text === 'Submit') {
        localStorage.removeItem('SELECTEDCLIENT');
        localStorage.removeItem('selectedFiscalYear');
        let selectedClient = this.myGlobalObject.mappedClientsForUser.find(
          (obj: any) => {
            return obj.ClientId === clientInfo.formGroup.value.client.value;
          }
        );
        this.myGlobalObject.selectedClient = selectedClient;
        // this.cookieService.set(
        //   'SELECTEDCLIENT',
        //   JSON.stringify(selectedClient)
        // );
        localStorage.setItem('SELECTEDCLIENT', JSON.stringify(selectedClient));
        let selectedVal: any = localStorage.getItem('SELECTEDCLIENT');
        this.displaySelectedClient = JSON.parse(selectedVal);
        this.checkLockStatus('Import trial balance');
        this.checkLockStatus('Import transactions');
        this.getLatestFiscalYear();
        this.getAllFiscalYear();
        this.fetchClientPreferences();
      }
    });
  }

  public handleSelectionYearChange(event: MenuSelectEvent): void {
    if (event.item.text == 'Select year') return;
    let selectedOption: any = event.item.data;
    this.selectedFiscalYear = selectedOption.FiscalYearId;
    localStorage.setItem('selectedFiscalYear', JSON.stringify(selectedOption));
    let currentURL = this.router.url;
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([currentURL]));
    if (currentURL.indexOf('home') > 0) {
      window.location.reload();
    }
  }
  public handleSelectionChange(event: MenuSelectEvent): void {
    // process the event only if it's a leaf item
    if (event.item.text == 'Business types')
      this.router.navigate(['/manageBusinessType']);
    else if (event.item.text == 'Legal entities')
      this.router.navigate(['/legalEntities']);
    else if (event.item.text == 'Analysis statement')
      this.router.navigate(['/manageanalysisstatementlayouts']);
    else if (event.item.text == 'Line items')
      this.router.navigate(['/lineItems']);
    else if (event.item.text == 'Tax accounts')
      this.router.navigate(['/taxAccounts']);
    else if (event.item.text == 'Habitats') this.router.navigate(['/habitats']);
    else if (event.item.text == 'Manage GL accounts')
      this.router.navigate(['/glAccounts']);
    else if (event.item.text == 'Keyword groups')
      this.router.navigate(['/manageKeyword']);
    else if (event.item.text == 'Manage journals')
      this.router.navigate(['/manageJournal']);
    else if (event.item.text == 'New Journals')
      this.router.navigate(['/manageSelectJournal']);
    else if (event.item.text == 'Mapping')
      this.router.navigate(['/mapGlAccounts']);
    else if (event.item.text == 'Trial balance') this.trialBalanceClick();
    else if (event.item.text == 'Transactions') this.transactionsClick();
    else if (event.item.text == 'Import GL accounts') this.GlAccount();
    else if (event.item.text == 'Download Template') this.downloadGLTemplate();
    else if (event.item.text == 'Manage transactions')
      this.router.navigate(['/transactionsAllocate']);
    else if (event.item.text == 'Analyse and review')
      this.router.navigate(['/analysisreview']);
    else if (event.item.text == 'Allocation rules')
      this.router.navigate(['/manageAllocationRule']);
    else if (event.item.text == 'New offline allocation')
      this.router.navigate(['/offlineReferralBreakdownLevel']);
    else if (event.item.text == 'Manage offline allocation')
      this.router.navigate(['/manageofflineReferrals']);
    else if (event.item.text == 'Preferences')
      this.router.navigate(['/Preference']);
    else if (event.item.text == 'Master data')
      this.router.navigate(['/masterData']);
    else if (event.item.text == 'GL account')
      this.router.navigate(['/glAccountRun']);
    else if (event.item.text == 'Transaction data')
      this.router.navigate(['/manageTransactionLines']);
    else if (event.item.text == 'Manage custom columns')
      this.router.navigate(['/manageCustomColumn']);
    else if (event.item.text == 'Export template')
      this.router.navigate(['/manageStatementDetailsExport']);
    else if (event.item.text == 'Collect offline allocation')
      this.router.navigate(['/collectofflineReferrals']);
    else if (event.item.text == 'New adjustment journal')
      this.router.navigate(['/manageAdjustmentJournal']);
    else if (event.item.text == 'New movement journal')
      this.router.navigate(['/manageMovementJournal']);
    else if (event.item.text == 'Toggle') this.toggleTax();
    else if (event.item.text == 'Options')
      this.router.navigate(['/taxOutComeOptions']);
    else if (event.item.text == 'Analysis statements')
      this.router.navigate(['/exportAnalysisStatements']);
    else if (event.item.text == 'Import template')
      this.router.navigate(['/importAnalysisStatements']);
    else if (event.item.text == 'Unlock import')
      this.router.navigate(['/unlocksegment']);
    // else if (event.item.text == 'Comparative-Toggle')
    //   this.toggleComparativeAnalysis();
    // else if (event.item.text == 'Comparative-Options')
    //   this.router.navigate(['/ComparativeAnalysisReport']);
    else if (event.item.text == 'Manage attachments')
      this.router.navigate(['/manageAttachment']);
    else if (event.item.text == 'Tax account')
      this.router.navigate(['/TaxAccountReport']);
    else if (event.item.text == 'Statutory treatment')
      this.router.navigate(['/statutoryTreatment']);
    else if (event.item.text == 'Tax outcomes')
      this.router.navigate(['/taxOutcomes']);
  }
  public toggleTax(): void {
    if (this.router.url === '/analysisreview') {
      this.router.navigate([
        '/analysisreview/treeViewReport/',
        'TaxOutComeReport',
      ]);
    } else if (
      this.router.url === '/analysisreview/treeViewReport/TaxOutComeReport'
    ) {
      this.router.navigate(['/analysisreview']);
    } else {
      this.router.navigate(['/analysisreview/treeViewReport/TaxOutComeReport']);
    }
  }

  public toggleComparativeAnalysis(): void {
    if (this.router.url === '/analysisreview') {
      this.router.navigate([
        '/analysisreview/treeViewReport/',
        'ComparativeAnalysisReport',
      ]);
    } else if (
      this.router.url ===
      '/analysisreview/treeViewReport/ComparativeAnalysisReport'
    ) {
      this.router.navigate(['/analysisreview']);
    } else {
      this.router.navigate([
        '/analysisreview/treeViewReport/ComparativeAnalysisReport',
      ]);
    }
  }

  ComparativeOptions: any[] = [
    {
      text: 'Options',
    },
    {
      text: 'Toggle',
    },
  ];

  onSelect(item: any) {
    if (item.text == 'Toggle') this.toggleComparativeAnalysis();
    else if (item.text == 'Options')
      this.router.navigate(['/ComparativeAnalysisReport']);
  }

  public openAnalysisStatementDialog(): void {
    const dialogRef1 = this.dialogService.open({
      content: AnalysisstatementgridpopupComponent,
      width: '100%',
      height: '100%',
    });
    const clientInfo1 = dialogRef1.content
      .instance as AnalysisstatementgridpopupComponent;
    dialogRef1.result.subscribe((r1: any) => {});
  }
  public openTaxAccountsDialog(): void {
    const dialogRef1 = this.dialogService.open({
      content: TaxaccountsgridpopupComponent,
      width: 1350,
      height: 600,
    });
    const clientInfo1 = dialogRef1.content
      .instance as TaxaccountsgridpopupComponent;
    dialogRef1.result.subscribe((r1: any) => {});
  }
  public openGLAccountsDialog(): void {
    const dialogRef1 = this.dialogService.open({
      content: GLaccountsgridpopupComponent,
      width: 1300,
      height: 600,
    });
    const clientInfo1 = dialogRef1.content
      .instance as GLaccountsgridpopupComponent;
    dialogRef1.result.subscribe((r1: any) => {});
  }
  public trialBalanceClick() {
    this.globalService.LockSegment('Import trial balance').subscribe(
      (response) => {
        if (response.IsLockSuccessful == false) {
          const dialogRef1 = this.dialogService.open({
            content: LockstatusComponent,
            width: 500,
            height: 200,
          });
          const clientInfo1 = dialogRef1.content
            .instance as LockstatusComponent;
          dialogRef1.result.subscribe((r1: any) => {});
          const lockststatusdetails = dialogRef1.content
            .instance as LockstatusComponent;
          lockststatusdetails.statusDetails = response;
          lockststatusdetails.wizardName = 'Import Trial Balance';
        } else if (response.IsLockSuccessful == true) {
          this.router.navigate(['/trialBalanceImport']);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  public transactionsClick() {
    this.globalService.LockSegment('Import transactions').subscribe(
      (response) => {
        if (response.IsLockSuccessful == false) {
          const dialogRef1 = this.dialogService.open({
            content: LockstatusComponent,
            width: 500,
            height: 200,
          });
          const clientInfo1 = dialogRef1.content
            .instance as LockstatusComponent;
          dialogRef1.result.subscribe((r1: any) => {});
          const lockststatusdetails = dialogRef1.content
            .instance as LockstatusComponent;
          lockststatusdetails.statusDetails = response;
          lockststatusdetails.wizardName = 'Import Transactions';
        } else if (response.IsLockSuccessful == true) {
          this.router.navigate(['/transactionsImport']);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public GlAccount() {
    this.router.navigate(['/ImportglAccounts']);
  }
  downloadGLTemplate() {
    this.glaccountimportService.downloadGlImportTemplateFile().subscribe(
      (response: any) => {
        if (response.status == 200) {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          const fileName = 'GL Account Template';
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
        } else
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        //this.isLoading = false;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  homeClick() {
    this.checkLockStatus('Import transactions');
    this.checkLockStatus('Import trial balance');
    this.router.navigate(['/home']);
  }
  logout(): void {
    // Logout  OpenAM session
    // this.cookieService.set('OPENAMSESSION', '');
    // this.cookieService.delete('OPENAMSESSION');
    localStorage.removeItem('OPENAMSESSION');
    localStorage.removeItem('tokenCreated');
    // this.cookieService.set('SELECTEDCLIENT', '');
    // this.cookieService.delete('SELECTEDCLIENT');
    localStorage.removeItem('SELECTEDCLIENT');
    localStorage.removeItem('selectedFiscalYear');
    localStorage.removeItem('isVisited');
    localStorage.removeItem('verifier');
    localStorage.removeItem('selectedTreenode');
    window.location.href = environment.openam.logoutUrl;
  }
  helpClick() {
    const dialogRef = this.dialogService.open({
      content: HelpComponent,
      width: 500,
      height: 'auto',
    });
  }
}
