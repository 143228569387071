import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TaxoutcomeService } from 'src/app/common/services/taxoutcome/taxoutcome.service';
import { AnalysisReviewGridPopupComponent } from '../analysis-review-grid-popup/analysis-review-grid-popup.component';

@Component({
  selector: 'app-tax-outcome',
  templateUrl: './tax-outcome.component.html',
  styleUrls: ['./tax-outcome.component.scss'],
})
export class TaxOutcomeComponent implements OnInit {
  constructor(
    private taxoutcomeService: TaxoutcomeService,
    private router: Router,
    // private dialogService: DialogService,
    public toastr: ToastrManager,
    // public dialog: DialogRef,
    private fb: FormBuilder
  ) {
    this.taxoutcomeMappingForm = this.fb.group({
      report: [''],
      period: [''],
      ppcombo: ['', this.periodType == 'Year' ? Validators.required : ''],
      pycombo: [''],
      periodReport: [''],
      taxOutComeMappingGrid: [
        '',
        this.taxoutcomeMappingList.length == 0 ? Validators.required : '',
      ],
    });

    this.getAllTaxOutcomesList();
  }

  ngOnInit(): void {}
  public selectedtaxOutcome = (args: any) => args.dataItem;
  public mySelectedtaxOutcome: string[] = [];
  public mySelectedtaxOutcomeMapping: string[] = [];

  public selectedPeriod = (args: any) => args.dataItem;
  public mySelectedavailablePeriod: string[] = [];
  public mySelectedperiodtoReport: string[] = [];
  public periodtoReportList: any = [];
  public availablePeriodList: any[] = [];
  public taxoutcomeMappingForm!: FormGroup;
  public gridViewScopeSelected: any;
  public taxoutcomeList: any[] = [];
  public taxoutcomeMappingList: any[] = [];
  public isLoading: boolean = false;
  public reportType: any = 'Value';
  public periodType: any = 'Year';
  public pyList: any[] = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
  ];
  public selectedpy: any;
  public ppList: any[] = [
    { label: '3', value: '3' },
    { label: '12', value: '12' },
    { label: 'Custom', value: 'Custom' },
  ];
  public selectedpp: any;
  public selectedperiodYear: any | undefined;
  public selectedperiodPercentage: any | undefined;

  public isperiodvalueEnable: boolean = false;
  public isperiodpercentageEnable: boolean = true;
  public getperiodtoReportList: any = [];
  getAllTaxOutcomesList(): void {
    this.isLoading = true;
    this.taxoutcomeService.getAllTaxOutcomesList().subscribe(
      (response) => {
        if (response != null && response != undefined) {
          this.taxoutcomeList = response.sort((a, b) =>
            a.ShortDescription > b.ShortDescription ? 1 : -1
          );
        }
        this.getTaxOutComeMappingData();
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getTaxOutComeMappingData(): void {
    this.isLoading = true;
    this.taxoutcomeService.getTaxOutComeMappingData().then(
      (response) => {
        if (
          response != null &&
          response != undefined &&
          response.Item1 != null &&
          response.Item2 != null &&
          response.Item1 != undefined &&
          response.Item2 != undefined
        ) {
          this.taxoutcomeMappingForm.controls[
            'taxOutComeMappingGrid'
          ].setErrors(null);
          this.taxoutcomeMappingForm.controls['ppcombo'].setErrors(null);
          this.taxoutcomeMappingList = response.Item1;
          if (this.taxoutcomeMappingList.length > 0) {
            for (let x = 0; x < this.taxoutcomeMappingList.length; x++) {
              var index = this.taxoutcomeList
                .map((x) => x._id)
                .indexOf(this.taxoutcomeMappingList[x]._id);
              this.taxoutcomeList.splice(index, 1).slice(0);
            }
            this.taxoutcomeMappingList.sort((a, b) =>
              a.ShortDescription > b.ShortDescription ? 1 : -1
            );
          } else {
            this.taxoutcomeMappingForm.controls[
              'taxOutComeMappingGrid'
            ].setErrors({
              incorrect: true,
            });
          }
          if (response.Item2.TaxOutComeId != null) {
            this.reportType = response.Item2.ReportType;
            this.periodType = response.Item2.PriorType;
            this.selectedpy = response.Item2.PriorYearAmount;
            if (this.periodType == 'Year') {
              this.isperiodpercentageEnable = true;
              this.isperiodvalueEnable = false;
              this.selectedperiodYear = response.Item2.PriorYearAmount;
            } else if (this.periodType == 'Period') {
              this.isperiodvalueEnable = true;
              this.isperiodpercentageEnable = false;
              this.selectedperiodPercentage = response.Item2.PriorYearAmount;
              if (response.Item2.PriorYearAmount == 'Custom') {
                // for(let i=0;i<response.Item2.CustomPeriod.length;i++)
                // {
                //   this.periodtoReportList.push(response.Item2.CustomPeriod[i].fiscalYearModels);
                // }
                this.getCustomPeriodData(response.Item2.CustomPeriod);
              }
            }
          }
        } else {
          this.taxoutcomeMappingForm.controls[
            'taxOutComeMappingGrid'
          ].setErrors({
            incorrect: true,
          });
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  handlepycomboChange(pyear: any): void {
    if (pyear != undefined) {
      this.selectedperiodPercentage = '';
      this.selectedpy = this.selectedperiodYear.value;
      this.taxoutcomeMappingForm.controls['ppcombo'].setErrors(null);
    } else {
      this.selectedperiodYear = '';
    }
  }

  handleppcomboChange(pyear: any): void {
    if (pyear != undefined) {
      this.selectedperiodYear = '';
      this.taxoutcomeMappingForm.controls['pycombo'].setValue('');
      this.selectedpy = this.selectedperiodPercentage.value;
      if (this.selectedpy == 'Custom') {
        if (this.periodtoReportList.length == 0) {
          this.taxoutcomeMappingForm.controls['periodReport'].markAsTouched();
          this.taxoutcomeMappingForm.controls['periodReport'].setErrors({
            incorrect: true,
          });
        }
        this.getCustomPeriodData(null);
      } else {
        this.periodtoReportList = [];
        this.availablePeriodList = [];
        this.taxoutcomeMappingForm.controls['periodReport'].setErrors(null);
      }
    } else {
      this.selectedperiodPercentage = '';
    }
  }

  getCustomPeriodData(data: any): void {
    this.isLoading = true;
    this.taxoutcomeService.getCustomePeriodData().subscribe(
      (response) => {
        if (response != null && response != undefined) {
          this.availablePeriodList = response;
          if (data != null && data != undefined) {
            for (let i = 0; i < data.length; i++) {
              this.periodtoReportList.push(data[i].fiscalYearModels);
              var index = this.availablePeriodList
                .map((x) => x.Display)
                .indexOf(this.periodtoReportList[i].Display);
              this.availablePeriodList.splice(index, 1).slice(0);
            }
          }
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  saveChanges() {
    if (this.taxoutcomeMappingForm.valid) {
      let taxids: string = '';
      let period: string = '';
      let fiscalyear: number = 0;
      for (let j = 0; j < this.taxoutcomeMappingList.length; j++) {
        taxids += this.taxoutcomeMappingList[j]._id.toString() + ',';
      }
      if (
        this.selectedperiodPercentage != undefined &&
        (this.selectedperiodPercentage.value == 'Custom' ||
          this.selectedperiodPercentage == 'Custom')
      ) {
        for (let j = 0; j < this.periodtoReportList.length; j++) {
          period += this.periodtoReportList[j].Display + ',';
        }
      }
      let periodType = this.periodType;
      let reportType = this.reportType;
      let periodYearcombo = this.selectedpy;
      let year: any = localStorage.getItem('selectedFiscalYear');
      let taxMappingDetails: any = {
        _id: 0,
        ShortDescription: '',
        LongDescription: '',
        IsMapped: false,
        PriorType: periodType,
        ReportType: reportType,
        PriorYearAmount: periodYearcombo,
        fk_TaxOutComeId: 0,
        Period: 0,
        FiscalYear: JSON.parse(year).FiscalYearId,
        DisplayYear: JSON.parse(year).FiscalYearId,
        TaxOutcomeIds: taxids,
        CustomPeriods: period,
        CustomPeriodCount: 0,
      };
      this.taxoutcomeService.SaveTaxOutComeMapping(taxMappingDetails).subscribe(
        (res) => {
          if (res == 'Tax Outcome options saved') {
            this.toastr.successToastr(res, 'Success');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() =>
                this.router.navigate([
                  '/analysisreview/treeViewReport/',
                  'TaxOutComeReport',
                ])
              );
          }
        },
        (error) => {
          this.isLoading = false;
          this.toastr.errorToastr('Failed to Save TaxoutCome Mapping', 'Error');
          console.log(error);
        }
      );
    }
  }

  closeButtonClick() {
    this.router.navigate(['/analysisreview']);
  }

  taxoutcomeforwardScope() {
    this.movetaxoutcomeData(
      'forward',
      this.mySelectedtaxOutcome,
      this.mySelectedtaxOutcomeMapping,
      this.taxoutcomeList,
      this.taxoutcomeMappingList,
      'ltrscope'
    );
  }
  taxoutcomebackwardScope() {
    this.movetaxoutcomeData(
      'backward',
      this.mySelectedtaxOutcomeMapping,
      this.mySelectedtaxOutcome,
      this.taxoutcomeMappingList,
      this.taxoutcomeList,
      'rtlscope'
    );
  }
  taxoutcomeforwardScopeAll() {
    this.movetaxoutcomeDataAll(this.taxoutcomeList);
  }
  taxoutcomebackwardScopeAll() {
    this.movetaxoutcomeDatabackAll(this.taxoutcomeMappingList);
  }

  movetaxoutcomeData(
    action: any,
    selection: any,
    selectionSelected: any,
    grid: any[],
    gridSelected: any[],
    dir: any
  ) {
    for (let j = 0; j < selection.length; j++) {
      if (dir == 'ltrscope') {
        this.taxoutcomeMappingList = [
          selection[j],
          ...this.taxoutcomeMappingList,
        ];
        this.taxoutcomeMappingList.sort((a, b) =>
          a.ShortDescription > b.ShortDescription ? 1 : -1
        );
        var index = grid.map((x) => x._id).indexOf(selection[j]._id);
        grid.splice(index, 1).slice(0);
        this.mySelectedtaxOutcome = [];
      } else if (dir == 'rtlscope') {
        this.taxoutcomeList = [selection[j], ...this.taxoutcomeList];
        this.taxoutcomeList.sort((a, b) =>
          a.ShortDescription > b.ShortDescription ? 1 : -1
        );
        var index = grid.map((x) => x._id).indexOf(selection[j]._id);
        grid.splice(index, 1).slice(0);
        this.mySelectedtaxOutcomeMapping = [];
      }
    }
    if (this.taxoutcomeMappingList.length > 0) {
      this.taxoutcomeMappingForm.controls['taxOutComeMappingGrid'].setErrors(
        null
      );
    } else {
      this.taxoutcomeMappingForm.controls['taxOutComeMappingGrid'].setErrors({
        incorrect: true,
      });
    }
  }

  movetaxoutcomeDataAll(fromData: any[]) {
    for (let j = 0; j < fromData.length; j++) {
      this.taxoutcomeMappingList = [fromData[j], ...this.taxoutcomeMappingList];
    }
    this.taxoutcomeMappingList.sort((a, b) =>
      a.ShortDescription > b.ShortDescription ? 1 : -1
    );
    this.taxoutcomeList = [];
    if (this.taxoutcomeMappingList.length > 0) {
      this.taxoutcomeMappingForm.controls['taxOutComeMappingGrid'].setErrors(
        null
      );
    }
  }

  movetaxoutcomeDatabackAll(fromData: any[]) {
    for (let j = 0; j < fromData.length; j++) {
      this.taxoutcomeList = [fromData[j], ...this.taxoutcomeList];
    }
    this.taxoutcomeList.sort((a, b) =>
      a.ShortDescription > b.ShortDescription ? 1 : -1
    );
    this.taxoutcomeMappingList = [];
    if (this.taxoutcomeMappingList.length == 0) {
      this.taxoutcomeMappingForm.controls['taxOutComeMappingGrid'].setErrors({
        incorrect: true,
      });
    }
  }

  selectReportType(node: any) {}

  selectperiodType(node: any) {
    if (this.periodType != null && this.periodType != undefined) {
      if (node == 'Year') {
        this.selectedperiodPercentage = '';
        this.isperiodpercentageEnable = true;
        this.isperiodvalueEnable = false;
        this.taxoutcomeMappingForm.controls['pycombo'].markAsTouched();
        this.taxoutcomeMappingForm.controls['pycombo'].setErrors({
          incorrect: true,
        });
        this.taxoutcomeMappingForm.controls['ppcombo'].setErrors(null);
        this.taxoutcomeMappingForm.controls['periodReport'].setErrors(null);
      } else if (node == 'Period') {
        this.selectedperiodYear = '';
        this.isperiodvalueEnable = true;
        this.isperiodpercentageEnable = false;
        this.taxoutcomeMappingForm.controls['ppcombo'].markAsTouched();
        this.taxoutcomeMappingForm.controls['ppcombo'].setErrors({
          incorrect: true,
        });
        this.taxoutcomeMappingForm.controls['pycombo'].setErrors(null);
      }

      this.periodtoReportList = [];
      this.availablePeriodList = [];
    }
  }

  periodforwardScope() {
    this.moveperiodData(
      'forward',
      this.mySelectedavailablePeriod,
      this.mySelectedperiodtoReport,
      this.availablePeriodList,
      this.periodtoReportList,
      'ltrscope'
    );
  }
  periodbackwardScope() {
    this.moveperiodData(
      'backward',
      this.mySelectedperiodtoReport,
      this.mySelectedavailablePeriod,
      this.periodtoReportList,
      this.availablePeriodList,
      'rtlscope'
    );
  }
  periodforwardScopeAll() {
    this.moveperiodDataAll(this.availablePeriodList);
  }
  periodbackwardScopeAll() {
    this.moveperiodDatabackAll(this.periodtoReportList);
  }

  moveperiodData(
    action: any,
    selection: any,
    selectionSelected: any,
    grid: any[],
    gridSelected: any[],
    dir: any
  ) {
    for (let j = 0; j < selection.length; j++) {
      if (dir == 'ltrscope') {
        this.periodtoReportList = [selection[j], ...this.periodtoReportList];
        var index = grid.map((x) => x.Display).indexOf(selection[j].Display);
        grid.splice(index, 1).slice(0);
        this.mySelectedavailablePeriod = [];
      } else if (dir == 'rtlscope') {
        this.availablePeriodList = [selection[j], ...this.availablePeriodList];
        var index = grid.map((x) => x.Display).indexOf(selection[j].Display);
        grid.splice(index, 1).slice(0);
        this.mySelectedperiodtoReport = [];
      }
    }
    if (
      this.periodtoReportList.length > 0 ||
      this.selectedperiodPercentage.value != 'Custom'
    ) {
      this.taxoutcomeMappingForm.controls['periodReport'].setErrors(null);
    } else if (
      this.periodtoReportList.length == 0 &&
      this.selectedperiodPercentage.value == 'Custom'
    ) {
      this.taxoutcomeMappingForm.controls['periodReport'].setErrors({
        incorrect: true,
      });
    }
  }

  moveperiodDataAll(fromData: any[]) {
    for (let j = 0; j < fromData.length; j++) {
      this.periodtoReportList = [fromData[j], ...this.periodtoReportList];
    }
    this.availablePeriodList = [];
    if (this.periodtoReportList.length > 0) {
      this.taxoutcomeMappingForm.controls['periodReport'].setErrors(null);
    }
  }

  moveperiodDatabackAll(fromData: any[]) {
    for (let j = 0; j < fromData.length; j++) {
      this.availablePeriodList = [fromData[j], ...this.availablePeriodList];
    }
    this.periodtoReportList = [];
    if (this.periodtoReportList.length == 0) {
      this.taxoutcomeMappingForm.controls['periodReport'].setErrors({
        incorrect: true,
      });
    }
  }
}
