<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 18px; line-height: 1.3em">
    {{ wizardName }}
  </div>
</kendo-dialog-titlebar>
<div style="display: flex">
  {{ statusDetails.LockNotAcquireMessage }}
</div>

<div class="clickToActions" style="padding: 10px 0px 0px 0px">
  <ap-button
    [btnType]="'primary'"
    [label]="'OK'"
    themeColor="primary"
    (click)="cancelClick()"
  ></ap-button>
</div>
