import { Component, Input, OnInit } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { GlobalService } from '../common/globalservice/globalservice.service';
@Component({
  selector: 'app-lockstatus',
  templateUrl: './lockstatus.component.html',
  styleUrls: ['./lockstatus.component.scss'],
})
export class LockstatusComponent extends DialogContentBase implements OnInit {
  @Input() statusDetails: any;
  @Input() wizardName: any;
  constructor(private globalService: GlobalService, public dialog: DialogRef) {
    super(dialog);
  }

  ngOnInit(): void {}
  cancelClick() {
    this.dialog.close();
  }
}
