<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 18px; line-height: 1.1em">
    {{ action[0].toUpperCase() + action.slice(1).toLowerCase() }} statutory
    treatment
  </div>
</kendo-dialog-titlebar>

<mat-card class="">
  <!-- <mat-card-title>
       Statutory treatment
    </mat-card-title> -->
  <mat-card-content>
    <div>
      <form class="k-form" [formGroup]="statutoryTreatmentForm">
        <kendo-formfield>
          <kendo-label
            [for]="Description"
            text="Description"
            style="font-weight: bold; margin-bottom: 5px"
          ></kendo-label>
          <input
            formControlName="Description"
            maxlength="50"
            (keypress)="omit_special_char($event)"
            kendoTextBox
            #Description
            required
          />
          <kendo-formerror>{{ descriptionErrorMessage }}</kendo-formerror>
        </kendo-formfield>
      </form>
    </div>
  </mat-card-content>
</mat-card>
<div class="clickToActions" style="padding: 10px 0px 0px 0px">
  <ap-button
    [btnType]="'secondary'"
    [label]="'Cancel'"
    (click)="cancelClick()"
    class="cancelButton"
    style="width: 100px"
  ></ap-button>
  <ap-button
    [btnType]="'primary'"
    [label]="'Save'"
    themeColor="primary"
    [disabled]="!statutoryTreatmentForm.valid"
    (click)="savestatutorytreatment()"
    class="okButton"
    style="margin-right: 6px"
  ></ap-button>
</div>
