<!-- Header -->
<div class="header" style="margin-bottom: 0px">Manage line items</div>
<div id="mainContent" class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <!-- Sub Header -->
      <div class="subHeader sub">Business type</div>
      <!-- Select business type -->
      <div>
        <form class="k-form" [formGroup]="lineItemsGridForm">
          <div class="form-group">
            <kendo-combobox
              #businessTypeId
              formControlName="businessTypeId"
              [data]="businesstypeList"
              [filterable]="true"
              textField="Description"
              valueField="BusinessTypeId"
              (valueChange)="handleBTchange($event)"
              (filterChange)="handleFilterBusinessTypeId($event)"
            >
            </kendo-combobox>
          </div>
        </form>
      </div>
      <!-- search bar and action -->
      <div style="display: flex">
        <ap-search
          [placeholder]="'Search here'"
          [searchType]="'secondary'"
          style="width: 100%; margin-right: 24px; padding: 0px !important"
          [disabled]="false"
          (onSearch)="onFilter($event)"
        ></ap-search>
        <ap-button
          [icon]="'plus-outline'"
          [compact]="true"
          kendoTooltip
          title="New"
          [add]="true"
          (onClick)="openNewLineItemPopup()"
        ></ap-button>
      </div>
      <!-- Sub Header -->
      <div class="subHeader">Line items</div>
      <kendo-grid
        [data]="lineItems"
        scrollable="virtual"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        [resizable]="true"
        [reorderable]="true"
      >
        <kendo-grid-command-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'center'
          }"
          field="IsActive"
          title="Actions"
          [width]="100"
        >
          <ng-template kendoGridCellTemplate let-data>
            <button
              kendoButton
              name="edit"
              kendoTooltip
              title="Edit"
              (click)="openEditLineItemPopup(data)"
              style="margin-right: 5px"
            >
              <span class="Appkit4-icon icon-edit-outline"></span>
            </button>
            <!-- <button kendoButton name="copy" (click)="openCopyLineItemPopup(data)" style="margin-right: 5px;"><span class="Appkit4-icon icon-copy-outline"></span></button> -->
            <button
              kendoButton
              name="delete"
              kendoTooltip
              title="Delete"
              (click)="openDeleteLineItemPopup(data)"
            >
              <span class="Appkit4-icon icon-delete-outline"></span>
            </button>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column
          field="LineID"
          title="Line item id"
          [width]="220"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Description"
          title="Description"
          [width]="280"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="AccountClassDescription"
          title="Account class"
          [width]="150"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="AccountingAreaDescription"
          title="Parent line item"
          [width]="250"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <!-- <kendo-grid-column field="HelpId" title="Tax help file" [width]="220" [headerStyle] ="{'font-weight' : 'bold', 'text-align': 'center', 'color': '#d04a02'}"></kendo-grid-column>                                     -->
      </kendo-grid>
    </div>
    <!-- <div class="col-md-1 col-xs-12"></div> -->
  </div>
</div>
