import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DialogRef,
  DialogContentBase,
  ActionsLayout,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { ToastrManager } from 'ng6-toastr-notifications';
import { LegalentitygridpopupComponent } from '../legalentitygridpopup/legalentitygridpopup.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addlegalentity',
  templateUrl: './addlegalentity.component.html',
  styleUrls: ['./addlegalentity.component.scss'],
})
export class AddlegalentityComponent implements OnInit {
  @Input() public LEId: any;
  @Input() public copyLEId: any;
  selectedClient: any;
  countryList: any;
  countryListDB: any;
  industryList: any;
  industryListDB: any;
  businesstypeList: any;
  businesstypeListDB: any;
  currencyList: any;
  currencyListDB: any;
  addLEForm: FormGroup | any;
  isEdit: boolean = false;
  LEDetailsfromDb: any;
  copiedDescription: any;
  isDuplicateEntry: boolean = false;
  isCopy: boolean = false;
  descriptionErrorMessage: string = 'Description is required';
  legalentityIdErrorMessage: string = 'Legal entity id is required';
  public actionsLayout: ActionsLayout = 'normal';
  constructor(
    // public dialog: DialogRef,
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public router: Router,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {
    //super(dialog);
    this.addLEForm = this.fb.group({
      LegalEntityID: [''],
      country: [''],
      industry: [''],
      businessTypeId: [''],
      description: [''],
      currency: [''],
    });
  }

  ngOnInit(): void {
    this.LEId = history.state.leId;
    this.copyLEId = history.state.copyLEId;
    this.getRefData();
    this.selectedClient = localStorage.getItem('SELECTEDCLIENT');
    if (this.LEId) {
      this.isEdit = true;
      this.getLEDetailsById(this.LEId);
    }
    if (this.copyLEId) {
      this.isCopy = true;
      this.getLEDetailsById(this.copyLEId);
    }
  }
  getRefData() {
    this.globalService
      .getAllCountry()
      .then((res: any) => {
        this.countryListDB = res;
        this.countryList = this.countryListDB.slice();
      })
      .catch((err) => {});

    this.globalService
      .getAllIndustry()
      .then((res) => {
        this.industryListDB = res;
        this.industryList = this.industryListDB.slice();
      })
      .catch((err) => {});

    this.globalService
      .getAllBusinessTypes()
      .then((res) => {
        this.businesstypeListDB = res;
        this.businesstypeList = this.businesstypeListDB.slice();
      })
      .catch((err) => {});

    this.globalService
      .getAllCurrency()
      .then((res) => {
        let currencyListDB_temp = res;
        let tempCurrencylist = [];
        tempCurrencylist = currencyListDB_temp.map((obj: any) => {
          let Currencytemp = {
            Code: '',
            CurrencyId: 0,
            Description: '',
            codeDesc: '',
          };
          Currencytemp['Code'] = obj.Code;
          Currencytemp['CurrencyId'] = obj.CurrencyId;
          Currencytemp['Description'] = obj.Description;
          Currencytemp['codeDesc'] = obj.Code + '(' + obj.Description + ')';
          return Currencytemp;
        });
        this.currencyListDB = tempCurrencylist;
        this.currencyListDB.codeDesc = res.Code + '(' + res.Description + ')';
        this.currencyList = this.currencyListDB.slice();
      })
      .catch((err) => {});
  }

  getLEDetailsById(LEId: any) {
    this.globalService
      .getLegalEntityById(LEId)
      .then((res) => {
        if (res) {
          this.LEDetailsfromDb = res;
          if (this.isEdit) {
            this.addLEForm.controls['businessTypeId'].setValue(
              this.LEDetailsfromDb.BusinessTypeID
            );
          }
          if (this.isCopy) {
            this.copiedDescription = this.LEDetailsfromDb.Description;
            this.isDuplicateEntry = true;
          }
          if (this.isEdit) {
            this.addLEForm.controls['LegalEntityID'].setValue(
              this.LEDetailsfromDb.LegalEntityID
            )
          };
          this.addLEForm.controls['businessTypeId'].setValue(
            this.LEDetailsfromDb.BusinessTypeID
          );
          this.addLEForm.controls['description'].setValue(
            this.LEDetailsfromDb.Description
          );
          this.addLEForm.controls['country'].setValue(
            this.LEDetailsfromDb.CountryId
          );
          this.addLEForm.controls['industry'].setValue(
            this.LEDetailsfromDb.IndustryId
          );
          this.addLEForm.controls['currency'].setValue(
            this.LEDetailsfromDb.CurrencyID
          );
        }
      })
      .catch((err) => {});
  }
  handleBTchange(selBT: any) {
    if (selBT.CountryId == null)
      this.addLEForm.controls['country'].setValue('');
    else this.addLEForm.controls['country'].setValue(selBT.CountryId);
    if (selBT.IndustryId == null)
      this.addLEForm.controls['industry'].setValue('');
    else this.addLEForm.controls['industry'].setValue(selBT.IndustryId);
  }

  handleFilterCountry(value: any) {
    this.countryList = this.countryListDB.filter(
      (s: any) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  handleFilterIndustry(value: any) {
    this.industryList = this.industryListDB.filter(
      (s: any) =>
        s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  handleFilterBusinessTypeId(value: any) {
    this.businesstypeList = this.businesstypeListDB.filter(
      (s: any) =>
        s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  handleFilterCurrency(value: any) {
    this.currencyList = this.currencyListDB.filter(
      (s: any) => s.Code.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  saveLE() {
    var formObject = this.addLEForm.getRawValue();
    let cID, IId, LEId, BTId, CurrencyId;
    if (this.isCopy && formObject.description == this.copiedDescription) {
      this.addLEForm.controls['description'].markAsTouched();
      this.descriptionErrorMessage =
        'Please modify Description as it is not unique';
      this.addLEForm.controls['description'].setErrors({ incorrect: true });
      return;
    }
    if (formObject.country) {
      if (typeof formObject.country === 'object')
        cID = formObject.country.CountryId;
      else cID = formObject.country;
    } else {
      cID = null;
    }

    if (formObject.industry) {
      if (typeof formObject.industry === 'object')
        IId = formObject.industry.IndustryId;
      else IId = formObject.industry;
    } else {
      IId = null;
    }

    if (formObject.currency) {
      if (typeof formObject.currency === 'object')
        CurrencyId = formObject.currency.CurrencyId;
      else CurrencyId = formObject.currency;
    } else {
      CurrencyId = null;
    }

    if (formObject.businessTypeId) {
      if (typeof formObject.businessTypeId === 'object')
        BTId = formObject.businessTypeId.BusinessTypeId;
      else BTId = formObject.businessTypeId;
    } else {
      BTId = null;
    }

    if (this.isEdit) LEId = formObject.LegalEntityID;

    if (this.isEdit) {
      let LEobject = {
        ID: this.LEDetailsfromDb.ID,
        LegalEntityID: LEId,
        Description: formObject.description,
        Template: false,
        CompanyId: null,
        CountryId: cID,
        IndustryId: IId,
        TaxChartOfAccounts: BTId,
        CurrencyID: CurrencyId,
        HelpId: '',
      };

      this.globalService
        .updateLegalEntity(LEobject)
        .then((res) => {
          if (res == 'Legal Entity updated successfully') {
            this.toastr.successToastr(
              'Legal entity updated successfully',
              'Success'
            );
            this.router.navigate(['/legalEntities']);
          } else if (res == 'Legal Entity Description already exists') {
            this.addLEForm.controls['description'].markAsTouched();
            this.descriptionErrorMessage =
              'Please modify Description as it is not unique';
            this.addLEForm.controls['description'].setErrors({
              incorrect: true,
            });
            return;
          } else if (res == 'Legal Entity Id already exists') {
            this.addLEForm.controls['LegalEntityID'].markAsTouched();
            this.legalentityIdErrorMessage =
              'Please modify Legal entity id as it is not unique';
            this.addLEForm.controls['LegalEntityID'].setErrors({
              incorrect: true,
            });
            return;
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
            this.router.navigate(['/legalEntities']);
          }
        })
        .catch((err) => {});
    } else {
      let LEobject = {
        LegalEntityID: formObject.LegalEntityID,
        Description: formObject.description,
        Template: false,
        CompanyId: null,
        CountryId: cID,
        IndustryId: IId,
        TaxChartOfAccounts: BTId,
        CurrencyID: CurrencyId,
        HelpId: '',
      };
      this.globalService
        .saveLegalEntity(LEobject)
        .then((res) => {
          if (res == 'Legal Entity added successfully') {
            this.toastr.successToastr(
              'Legal entity added successfully',
              'Success'
            );
            this.router.navigate(['/legalEntities']);
          } else if (res == 'Legal Entity Description already exists') {
            this.addLEForm.controls['description'].markAsTouched();
            this.descriptionErrorMessage =
              'Please modify Description as it is not unique';
            this.addLEForm.controls['description'].setErrors({
              incorrect: true,
            });
            return;
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
            this.router.navigate(['/legalEntities']);
          }
        })
        .catch((err) => {});
    }
  }

  cancelClick() {
    this.router.navigate(['/legalEntities']);
  }
}
