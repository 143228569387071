import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionsLayout } from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { OfflineReferralService } from 'src/app/common/services/offlineReferral/offlineReferral-service';
import { LegalEntity } from 'src/app/_models/legalEntity';
import { OfflineReferralDataFilters } from 'src/app/_models/offlineReferralDataFilters';
import { OfflineReferralLegalEntity } from 'src/app/_models/offlineReferralLegalEntity';
import {
  offlineReferralOperator,
  offlineReferralOperatorDataList,
} from 'src/app/_models/offlineReferralOperator';
import { offlineReferralOperatorData } from 'src/app/_models/offlineReferralOperatorData';
import {
  offlineReferralOperatorFilter,
  offlineReferralOperatorFilterWithOperatorId,
} from 'src/app/_models/offlineReferralOperatorFilter';
import { offlineReferralOperatorTextFilter } from 'src/app/_models/offlineReferralOperatorTextFilter';
import { OfflineReferralTaxAccount } from 'src/app/_models/offlineReferralTaxAccount';
import {
  offlineReferralItemL2,
  offlineReferralItemL3,
  offlineReferralTransaction,
} from 'src/app/_models/offlineReferralTransaction';

import { CheckableSettings } from '@progress/kendo-angular-treeview';
import {
  offlineReferralTransactionFilterItem,
  offlineReferralTransactionSelected,
} from 'src/app/_models/offlineReferralTransactionSelected';
// import { OfflineReferralTransactionResponse } from 'src/app/_models/offlineReferralTransactionResponse';
import { SelectEvent, TabCloseEvent } from '@progress/kendo-angular-layout';
import { offlineReferralExportResponse } from 'src/app/_models/offlineReferralExportResponse';
import { offlineReferralExportTransactionBody } from 'src/app/_models/offlineReferralExportTransactionBody';
import { offlineReferralGridColumnSetting } from 'src/app/_models/offlineReferralGridColumnSetting';
import { map } from '@progress/kendo-data-query/dist/npm/transducers';
import { RowClassArgs } from '@progress/kendo-angular-treelist';
import { offlineReferralExportProcessingStartResponse } from 'src/app/_models/offlineReferralExportProcessingStart';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { GridSetting } from 'src/app/_models/gridSetting';
import { WindowState } from '@progress/kendo-angular-dialog';
@Component({
  selector: 'app-offline-referral-breakdown-level',
  templateUrl: './offline-referral-breakdown-level.component.html',
  styleUrls: ['./offline-referral-breakdown-level.component.scss'],
  styles: [
    `
      tr.gold,
      tr.k-alt.gold {
        background-color: #ffba80;
      }
      tr.green,
      tr.k-alt.greem {
        background-color: #b2f699;
      }
    `,
  ],
})
export class OfflineReferralBreakdownLevelComponent implements OnInit {
  offlineReferralBreakdownForm!: FormGroup;
  taxAccounts: OfflineReferralTaxAccount[] = [];
  taxAccountsSelected: OfflineReferralTaxAccount[] = [];
  taxAccount!: OfflineReferralTaxAccount;
  legalEntities: OfflineReferralLegalEntity[] = [];
  dataFilters: OfflineReferralDataFilters[] = [];
  dataFilter!: OfflineReferralDataFilters;
  filters: offlineReferralOperatorFilter[] = [];
  totalfilters: offlineReferralOperatorFilterWithOperatorId[] = [];
  filterTotalfilters: offlineReferralOperatorFilterWithOperatorId[] = [];
  filter!: offlineReferralOperatorFilterWithOperatorId;

  numberDataFilters: offlineReferralOperatorFilter[] = [];
  numberDataFilter!: offlineReferralOperatorFilter;

  textDataFilters: offlineReferralOperatorFilter[] = [];
  textDataFilter!: offlineReferralOperatorFilter;

  periods: number[] = [];
  periodsFilter: number[] = [];
  selectedPeriodFrom: number | undefined;
  selectedPeriodTo: number | undefined;
  public actionsLayout: ActionsLayout = 'normal';
  isLoading: boolean = false;
  isLoadingExport: boolean = false;
  fiscalYears: number[] = [];
  fiscalYear!: number;
  fiscalYearValue: number = 0;
  selectedFiscalYear: number | undefined;
  selectedLegalEntity: OfflineReferralLegalEntity | undefined;
  selectedLegalEntityId: number = 0;
  operatorDatas: offlineReferralOperatorData[] = [];
  operatorData!: offlineReferralOperatorData;

  filterOperatorDatas: offlineReferralOperatorData[] = [];
  filterOperatorData!: offlineReferralOperatorData;

  operators: offlineReferralOperator[] = [];
  operator!: offlineReferralOperator;
  selectedOperator: number = 1;
  isGridOperatorVisible: boolean = false;
  selectedNumericOperatorId: number = 0;
  selectedNumericOperatorText: string = '';
  selectedTextOperatorId: number = 0;
  selectedTextOperatorText: string = '';
  public textFiltervalue: offlineReferralOperatorFilter[] = [];
  textFilter!: offlineReferralOperatorFilter;
  isGridTextOperatorVisible: boolean = false;
  isGridDataItemTaxAccountVisible: boolean = false;

  public selectedCallback = (args: any) => args.dataItem;
  public mySelectionMasterData: any[] = [];
  public mySelectionMasterDataSelected: any[] = [];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  textFilterTotalvalue: offlineReferralOperatorTextFilter[] = [];
  textFilterTotalval!: offlineReferralOperatorTextFilter;
  textFilterFilterTotalvalue: offlineReferralOperatorTextFilter[] = [];

  offlineReferralTransactions: offlineReferralTransaction[] = [];
  offlineReferralTransaction!: offlineReferralTransaction;

  offlineReferralItemL2s: offlineReferralItemL2[] = [];
  offlineReferralItemL2!: offlineReferralItemL2;

  offlineReferralItemL3s: offlineReferralItemL3[] = [];
  offlineReferralItemL3!: offlineReferralItemL3;

  treeviewSelections: offlineReferralTransactionSelected[] = [];
  treeviewSelection!: offlineReferralTransactionSelected;

  treeviewSelectionsPass: offlineReferralTransactionSelected[] = [];

  treeviewSelectionsFinal: offlineReferralTransactionSelected[] = [];
  treeviewSelectionFinal!: offlineReferralTransactionSelected;
  treeviewSelectionsFinalBck: offlineReferralTransactionSelected[] = [];

  treeviewSelectionsFinalFltr: offlineReferralTransactionSelected[] = [];
  treeviewSelectionFinalFltr!: offlineReferralTransactionSelected;

  treeviewSelectionsFinalFilter: offlineReferralTransactionSelected[] = [];
  treeviewSelectionFinalFilter!: offlineReferralTransactionSelected;

  treeviewSelectionsFilter: offlineReferralTransactionSelected[] = [];
  treeviewSelectionsBck: offlineReferralTransactionSelected[] = [];
  treeviewSelectionsBckFinal: offlineReferralTransactionSelected[] = [];

  treeviewSelectedTransFilters: offlineReferralTransactionFilterItem[] = [];
  treeviewSelectedTransFilter!: offlineReferralTransactionFilterItem;

  treeviewSelectedPeriods: number[] = [];
  treeviewSelectedFilters: string[] = [];

  finalPeriods: number[] = [];
  finalPeriodsFilter: number[] = [];
  selectedPeriods: number[] = [];

  selectedFilters: offlineReferralTransactionFilterItem[] = [];
  selectedFinalFilters: offlineReferralTransactionFilterItem[] = [];
  finalFiltersFilter: offlineReferralTransactionFilterItem[] = [];
  finalFilters: offlineReferralTransactionFilterItem[] = [];
  finalFilterItems: string[] = [];
  finalFilterFilterItems: string[] = [];

  finalRowSno: number = 0;
  exportRowIndex: number = 0;

  public expandedNames!: any[];
  selectedId: number = 0;
  isViewTransaction: boolean = false;

  public children = (dataItem: any): Observable<any[]> => of(dataItem.items);
  public hasChildren = (dataItem: any): boolean => !!dataItem.items;

  public checkedKeys: string[] = [];
  checkedFilterKeys: string[] = [];

  pendingTransactionResponse: any[] = [];
  exportedTransactionResponse: any[] = [];
  allocatedTransactionResponse: any[] = [];

  exportedTransaction: offlineReferralExportResponse[] = [];
  exportedTransactionNotSelected: offlineReferralExportResponse[] = [];
  exportedTransactionSelected: offlineReferralExportResponse[] = [];
  exportedTransactionSelectedDefault: offlineReferralExportResponse[] = [];

  public mySelectionExportData: any[] = [];
  public mySelectionExportDataSelected: any[] = [];

  exportFilters: number[] = [];
  isExportClicked: boolean = false;

  exportTransactionsBody: offlineReferralExportTransactionBody[] = [];
  exportTransactionBody!: offlineReferralExportTransactionBody;

  selectedExportDataColumns: number[] = [];

  postValidateExportResponse: any;
  postExportFilteredTransactionResponse: any;

  public enableCheck = true;
  public checkChildren = true;
  public checkDisabledChildren = false;
  public checkParents = true;
  public checkOnClick = false;
  public checkMode: any = 'multiple';
  public selectionMode: any = 'single';

  totalRowPending: number = 0;
  startingRowPending: number = 0;
  endingRowPending: number = 0;

  totalRowExported: number = 0;
  startingRowExported: number = 0;
  endingRowExported: number = 0;

  totalRowAllocated: number = 0;
  startingRowAllocated: number = 0;
  endingRowAllocated: number = 0;
  private selectedClient: any;
  processingOrchestrationId: string = '';

  afterExportDetails!: offlineReferralExportProcessingStartResponse;
  exportProcessingErrorMessage: string = '';
  isExportSuccess: boolean = false;

  // public rowClass = (args: any) => ({
  //   // 'k-disabled': args.dataItem.MandatoryItem,

  // });

  public rowClass = (context: RowClassArgs) => {
    if (context.dataItem.MandatoryItem) {
      return { gold: true };
    } else {
      return { green: true };
    }
  };

  // public columns: offlineReferralGridColumnSetting[] =
  //   offlineReferralGridColumnSettingData;

  public gridColumns: offlineReferralGridColumnSetting[] = [];
  gridColumn!: offlineReferralGridColumnSetting;

  columns: string[] = [];

  gridSettings: GridSetting[] = [];
  countryList: any;
  countryListDB: any;

  currentTransactionExportedPageNo: number = 1;
  currentTransactionPendingPageNo: number = 1;
  currentTransactionAllocatedPageNo: number = 1;

  totalPageExported: number = 0;
  totalPagePending: number = 0;
  totalPageAllocated: number = 0;

  callingType: any = '';
  callingFiscalYear: number = 0;
  callingLegalEntityId: number = 0;
  callingTaxAccount: number = 0;

  opened: boolean = false;
  isDraggable: boolean = true;
  public windowState: WindowState = 'default';

  public hiddenColumns: string[] = [];

  public openClose(isOpened: boolean): void {
    this.opened = isOpened;
  }

  public isHidden(columnName: string): boolean {
    return this.hiddenColumns.indexOf(columnName) > -1;
  }

  public pounds = Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  constructor(
    private fb: FormBuilder,
    private OfflineReferralService: OfflineReferralService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.setupForm();
    if (
      this.route.snapshot.paramMap.get('CallingType') != '' &&
      this.route.snapshot.paramMap.get('CallingType') != null
    ) {
      this.callingType = this.route.snapshot.paramMap.get('CallingType');
      // AnalyseReview

      this.callingFiscalYear = Number(
        this.route.snapshot.paramMap.get('FiscalYear')
      );

      this.callingLegalEntityId = Number(
        this.route.snapshot.paramMap.get('LegalEntityId')
      );

      this.callingTaxAccount = Number(
        this.route.snapshot.paramMap.get('TaxAccount')
      );
    }

    this.getFiscalYears();
    this.operators = offlineReferralOperatorDataList;
    this.isGridDataItemTaxAccountVisible = true;
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
  }

  public hideColumn(columnName: string): void {
    const hiddenColumns = this.hiddenColumns;

    if (!this.isHidden(columnName)) {
      hiddenColumns.push(columnName);
    } else {
      hiddenColumns.splice(hiddenColumns.indexOf(columnName), 1);
    }
  }

  setupForm(): void {
    this.offlineReferralBreakdownForm = this.fb.group({
      fiscalYear: '',
      legalEntity: '',
      periodFrom: '',
      periodTo: '',
    });
  }

  resetOperatorDatas() {
    this.operatorDatas = [];
    this.operatorData = {
      Sno: 1,
      DataItemId: this.selectedNumericOperatorId,
      DataItemText: this.selectedNumericOperatorText,
      OperatorId: 0,
      Operator: '',
      Criteria: 0,
    };
    this.operatorDatas.push(this.operatorData);
  }

  getFiscalYears(): void {
    this.isLoading = true;
    this.fiscalYears = [];
    this.OfflineReferralService.getFiscalYearForOfflineReferral().subscribe(
      (response) => {
        // console.log(response);
        this.fiscalYears = response;
        if (this.fiscalYears.length > 0) {
          let year: any = localStorage.getItem('selectedFiscalYear');
          this.selectedFiscalYear = JSON.parse(year).FiscalYearId;
          if (this.fiscalYears.indexOf(Number(this.selectedFiscalYear)) == -1) {
            this.selectedFiscalYear = undefined;
          } else {
            this.handleFiscalYearChange(Number(this.selectedFiscalYear));
          }
        }

        if (this.callingType == 'AnalyseReview') {
          this.selectedFiscalYear = this.callingFiscalYear;
          this.handleFiscalYearChange(this.callingFiscalYear);
        }
        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }

  getLegalEntitesByYear(fiscalYear: number): void {
    this.isLoading = true;
    this.legalEntities = [];
    this.OfflineReferralService.getLegalEntitesByYear(fiscalYear).subscribe(
      (response) => {
        // console.log(response);
        this.legalEntities = response;

        if (this.legalEntities.length > 0) {
          this.selectedLegalEntity = this.legalEntities[0];
          this.handleLegalEntityChange(this.selectedLegalEntity);
        }
        if (this.callingType == 'AnalyseReview') {
          if (this.callingLegalEntityId > 0) {
            let filterLegalEntity: OfflineReferralLegalEntity[];
            filterLegalEntity = this.legalEntities.filter(
              (f) => f.ID == this.callingLegalEntityId
            );
            if (filterLegalEntity.length > 0) {
              this.selectedLegalEntity = filterLegalEntity[0];
              this.handleLegalEntityChange(this.selectedLegalEntity);
            }
          }
        }
        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }

  getPeriodsByFiscalYear(fiscalYear: number): void {
    this.isLoading = true;
    this.periods = [];
    this.OfflineReferralService.getPeriodsByFiscalYear(fiscalYear).subscribe(
      (response) => {
        // console.log(response);
        this.periods = response;
        if (this.periods.length > 0) {
          this.selectedPeriodFrom = this.periods[0];
          this.selectedPeriodTo = this.periods[this.periods.length - 1];
        }
        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }

  getDataFiltersForOfflineReferral(): void {
    this.isLoading = true;
    this.dataFilters = [];
    this.OfflineReferralService.getDataFiltersForOfflineReferral().subscribe(
      (response) => {
        this.dataFilters = response;

        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }

  getFilterData(filterId: number, fiscalYear: any, legalEntityID: any): void {
    this.isLoading = true;
    this.filters = [];
    this.OfflineReferralService.getFilterData(
      filterId,
      fiscalYear,
      legalEntityID
    ).subscribe(
      (response) => {
        this.filters = response;
        // console.log(this.filters);
        this.filters.map((f, i) => {
          this.filter = {
            OperatorId: filterId,
            Id: Number(f.Id),
            Value: f.Value,
          };
          this.totalfilters.push(this.filter);
        });
        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }

  getTaxAccountsForOfflineReferral(taxChartOfAccountId: number): void {
    this.isLoading = true;
    this.taxAccounts = [];
    this.OfflineReferralService.getTaxAccountsForOfflineReferral(
      taxChartOfAccountId
    ).subscribe(
      (response) => {
        this.taxAccounts = response;
        // console.log(this.taxAccounts);

        if (this.callingType == 'AnalyseReview') {
          this.taxAccountsSelected = [];
          this.taxAccounts.map((adj) => {
            if (adj.TaxAccountCodeId == this.callingTaxAccount) {
              this.taxAccountsSelected.push(adj);
            }
          });
          let bckTaxAccounts: OfflineReferralTaxAccount[];
          bckTaxAccounts = this.taxAccounts;
          this.taxAccounts = [];
          bckTaxAccounts.map((adj) => {
            if (adj.TaxAccountCodeId != this.callingTaxAccount) {
              this.taxAccounts.push(adj);
            }
          });
        }
        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }

  handleFiscalYearChange(fiscalYear: number): void {
    // console.log(fiscalYear);
    this.selectedFiscalYear = fiscalYear;
    this.legalEntities = [];
    this.periods = [];
    this.resetAllCollection();
    this.selectedLegalEntity = undefined;
    this.selectedPeriodFrom = undefined;
    this.selectedPeriodTo = undefined;
    if (fiscalYear != undefined) {
      this.getLegalEntitesByYear(fiscalYear);
      this.getPeriodsByFiscalYear(fiscalYear);
    } else {
      this.legalEntities = [];
      this.periods = [];
    }
  }

  handleLegalEntityChange(legalEntity: OfflineReferralLegalEntity): void {
    // console.log(legalEntity);

    this.resetAllCollection();
    if (legalEntity != undefined) {
      this.selectedLegalEntity = legalEntity;
      this.selectedLegalEntityId = legalEntity.ID;
      this.dataFilters = [];
      this.getDataFiltersForOfflineReferral();

      this.getTaxAccountsForOfflineReferral(legalEntity.TaxChartOfAccounts);
    }
  }

  editFilter(filter: any): void {
    // console.log(filter);
    if (filter.Type == 'Number') {
      this.isGridOperatorVisible = true;
      this.isGridDataItemTaxAccountVisible = false;
      this.isGridTextOperatorVisible = false;
      this.selectedNumericOperatorId = filter.Id;
      this.selectedNumericOperatorText = filter.Name;

      if (this.operatorDatas.length == 0) {
        this.resetOperatorDatas();
      }

      this.filterOperatorDatas = this.operatorDatas.filter(
        (f) => f.DataItemId == this.selectedNumericOperatorId
      );
      if (this.filterOperatorDatas.length == 0) {
        let maxSno: number = 1;
        if (this.operatorDatas.length > 0) {
          maxSno = this.operatorDatas[this.operatorDatas.length - 1].Sno;
        }
        this.operatorData = {
          Sno: maxSno + 1,
          DataItemId: this.selectedNumericOperatorId,
          DataItemText: this.selectedNumericOperatorText,
          OperatorId: 0,
          Operator: '',
          Criteria: 0,
        };
        this.operatorDatas.push(this.operatorData);
        this.filterOperatorDatas = this.operatorDatas.filter(
          (f) => f.DataItemId == this.selectedNumericOperatorId
        );
      }
    } else {
      this.isGridOperatorVisible = false;
      this.isGridDataItemTaxAccountVisible = false;
      this.isGridTextOperatorVisible = true;
      this.selectedTextOperatorId = filter.Id;
      this.selectedTextOperatorText = filter.Name;
      this.getFilterData(
        filter.Id,
        this.selectedFiscalYear,
        this.selectedLegalEntityId
      );

      this.textFilterFilterTotalvalue = this.textFilterTotalvalue.filter(
        (f) => f.DataItemId == this.selectedTextOperatorId
      );
      this.textFiltervalue = [];
      this.textFilterFilterTotalvalue.map((adj, i) => {
        this.textFilter = {
          Id: adj.Id,
          Value: adj.Value,
        };
        this.textFiltervalue.push(this.textFilter);
      });
    }
  }

  deleteFilter(filter: any): void {
    this.dataFilters.map((adj, i) => {
      if (adj.Id == filter.Id) {
        adj.Values = '';
      }
    });

    if (filter.Type == 'Number') {
      // let filterOperator: offlineReferralOperatorData[];
      let bckfilterOperatorDatas: offlineReferralOperatorData[];
      bckfilterOperatorDatas = this.filterOperatorDatas;
      this.filterOperatorDatas = [];

      bckfilterOperatorDatas.map((f, i) => {
        if (f.DataItemId != filter.Id) {
          this.filterOperatorDatas.push(f);
        }
      });

      // let filteroperatorDatas: offlineReferralOperatorData[];
      let bckoperatorDatas: offlineReferralOperatorData[];
      bckoperatorDatas = this.operatorDatas;
      this.operatorDatas = [];

      bckoperatorDatas.map((f, i) => {
        if (f.DataItemId != filter.Id) {
          this.operatorDatas.push(f);
        }
      });
    }
    if (filter.Type == 'Text') {
      let filtertextFilterTotalvalue: offlineReferralOperatorTextFilter[];
      let bckTextFilterTotalvalue: offlineReferralOperatorTextFilter[];
      bckTextFilterTotalvalue = this.textFilterTotalvalue;
      this.textFilterTotalvalue = [];

      bckTextFilterTotalvalue.map((f, i) => {
        if (f.DataItemId != filter.Id) {
          this.textFilterTotalvalue.push(f);
        }
      });
    }
  }

  operatorChange(dataItem: any, e: any) {
    // console.log(e);
    dataItem.OperatorId = e.Id;
    dataItem.Operator = e.Operator;

    if (dataItem.Criteria == undefined || dataItem.Criteria == '') {
      dataItem.Criteria = 0;
    }
    // console.log(dataItem);

    // let row = dataItem.Sno - 1;
    this.operatorDatas.map((od, i) => {
      if (od.DataItemId == dataItem.DataItemId && od.Sno == dataItem.Sno) {
        od.Sno = dataItem.Sno;
        od.DataItemId = dataItem.DataItemId;
        od.OperatorId = dataItem.OperatorId;
        od.Operator = dataItem.Operator;
        od.Criteria = dataItem.Criteria;
      }
    });

    this.filterOperatorDatas = this.operatorDatas.filter(
      (f) => f.DataItemId == this.selectedNumericOperatorId
    );
  }

  criteriaChange(dataItem: any, e: any) {
    // console.log(e);
    // console.log(dataItem);
    // let row = dataItem.Sno - 1;
    // this.operatorDatas[row].Sno = dataItem.Sno;
    // this.operatorDatas[row].DataItemId = dataItem.DataItemId;
    // this.operatorDatas[row].OperatorId = dataItem.OperatorId;
    // this.operatorDatas[row].Operator = dataItem.Operator;
    dataItem.Criteria = e;
    this.operatorDatas.map((od, i) => {
      if (od.DataItemId == dataItem.DataItemId && od.Sno == dataItem.Sno) {
        od.Sno = dataItem.Sno;
        od.DataItemId = dataItem.DataItemId;
        od.OperatorId = dataItem.OperatorId;
        od.Operator = dataItem.Operator;
        od.Criteria = dataItem.Criteria;
      }
    });

    // this.operatorDatas[row].Criteria = dataItem.Criteria;

    this.filterOperatorDatas = this.operatorDatas.filter(
      (f) => f.DataItemId == this.selectedNumericOperatorId
    );
  }

  saveFilter() {
    // console.log(this.operatorDatas);
    let finalValue: string;

    this.filterOperatorDatas.map((adj, i) => {
      if (this.filterOperatorDatas[i].Operator != '') {
        if (i == 0) {
          finalValue =
            this.filterOperatorDatas[i].Operator +
            this.filterOperatorDatas[i].Criteria;
        } else {
          finalValue =
            finalValue +
            ';' +
            this.filterOperatorDatas[i].Operator +
            this.filterOperatorDatas[i].Criteria;
        }
      }
    });

    this.dataFilters.map((adj, i) => {
      if (adj.Id == this.selectedNumericOperatorId) {
        adj.Values = finalValue;
      }
    });

    this.filterOperatorDatas = this.operatorDatas.filter(
      (f) => f.DataItemId == this.selectedNumericOperatorId
    );

    this.isGridOperatorVisible = false;
    this.isGridTextOperatorVisible = false;
    this.isGridDataItemTaxAccountVisible = true;
  }

  addFilter() {
    let maxSno: number = 1;
    if (this.operatorDatas.length > 0) {
      maxSno = this.operatorDatas[this.operatorDatas.length - 1].Sno;
    }
    this.operatorData = {
      Sno: maxSno + 1,
      DataItemId: this.selectedNumericOperatorId,
      DataItemText: this.selectedNumericOperatorText,
      OperatorId: 0,
      Operator: '',
      Criteria: 0,
    };
    this.operatorDatas.push(this.operatorData);

    this.filterOperatorDatas = this.operatorDatas.filter(
      (f) => f.DataItemId == this.selectedNumericOperatorId
    );
  }

  saveTextFilter() {
    // console.log(this.textFiltervalue);
    let finalValue: string;

    this.textFilterFilterTotalvalue = this.textFilterTotalvalue.filter(
      (f) => f.DataItemId != this.selectedTextOperatorId
    );
    this.textFilterTotalvalue = [];

    this.textFilterFilterTotalvalue.map((adj, i) => {
      this.textFilterTotalvalue.push(adj);
    });

    this.textFiltervalue.map((adj, i) => {
      this.textFilterTotalval = {
        DataItemId: this.selectedTextOperatorId,
        DataItemText: this.selectedTextOperatorText,
        Id: adj.Id,
        Value: adj.Value,
      };
      this.textFilterTotalvalue.push(this.textFilterTotalval);
    });

    this.textFiltervalue.map((adj, i) => {
      if (i == 0) {
        finalValue = adj.Value;
      } else {
        finalValue = finalValue + ';' + adj.Value;
      }
    });

    this.dataFilters.map((adj, i) => {
      if (adj.Id == this.selectedTextOperatorId) {
        adj.Values = finalValue;
      }
    });

    this.isGridOperatorVisible = false;
    this.isGridTextOperatorVisible = false;
    this.isGridDataItemTaxAccountVisible = true;
  }

  forwardMasterData() {
    this.moveDataMaster(
      this.mySelectionMasterData,
      this.mySelectionMasterDataSelected,
      this.taxAccounts,
      this.taxAccountsSelected,
      'ltrmasterdata'
    );
  }

  backwardMasterData() {
    this.moveDataMaster(
      this.mySelectionMasterDataSelected,
      this.mySelectionMasterData,
      this.taxAccountsSelected,
      this.taxAccounts,
      'rtlmasterdata'
    );
  }

  forwardMasterDataAll() {
    this.moveDataAllMaster(
      this.taxAccounts,
      this.taxAccountsSelected,
      'ltrmasterdata'
    );
  }

  backwardMasterDataAll() {
    this.moveDataAllMaster(
      this.taxAccountsSelected,
      this.taxAccounts,
      'rtlmasterdata'
    );
  }

  forwardExportData() {
    this.moveDataExport(
      this.mySelectionExportData,
      this.mySelectionExportDataSelected,
      this.exportedTransactionNotSelected,
      this.exportedTransactionSelected,
      'ltrmasterdata'
    );
    // this.resetSnoExportedTransactionSelected();
    this.exportedTransactionSelected = this.exportedTransactionSelected.sort(
      (a: any, b: any) => (a.Sno > b.Sno ? 1 : -1)
    );
  }

  backwardExportData() {
    this.moveDataExport(
      this.mySelectionExportDataSelected,
      this.mySelectionExportData,
      this.exportedTransactionSelected,
      this.exportedTransactionNotSelected,
      'rtlmasterdata'
    );
    // this.resetSnoExportedTransactionSelected();
    this.exportedTransactionSelected = this.exportedTransactionSelected.sort(
      (a: any, b: any) => (a.Sno > b.Sno ? 1 : -1)
    );
  }

  forwardExportDataAll() {
    this.moveDataAllExport(
      this.exportedTransactionNotSelected,
      this.exportedTransactionSelected,
      'ltrmasterdata'
    );
    this.exportedTransactionSelected = this.exportedTransactionSelected.sort(
      (a: any, b: any) => (a.Sno > b.Sno ? 1 : -1)
    );
    // this.resetSnoExportedTransactionSelected();
  }

  backwardExportDataAll() {
    this.moveDataAllExport(
      this.exportedTransactionSelected,
      this.exportedTransactionNotSelected,
      'rtlmasterdata'
    );
    this.exportedTransactionSelected = this.exportedTransactionSelected.sort(
      (a: any, b: any) => (a.Sno > b.Sno ? 1 : -1)
    );
    // this.resetSnoExportedTransactionSelected();
  }

  resetSnoExportedTransactionSelected() {
    let sno: number = 1;
    this.exportedTransactionSelected.map((exp, i) => {
      exp.Sno = sno;
      sno++;
    });
    this.exportedTransactionSelected = this.exportedTransactionSelected.sort(
      (a: any, b: any) => (a.Sno > b.Sno ? 1 : -1)
    );
  }

  moveDataAllMaster(fromData: any, toData: any, dir: any) {
    for (let i = 0; i < fromData.length; i++) {
      toData.push(fromData[i]);
    }
    toData = toData.sort((a: any, b: any) =>
      a.PropertyName > b.PropertyName ? 1 : -1
    );

    if (dir == 'ltrmasterdata') {
      this.taxAccounts = [];
    }
    if (dir == 'rtlmasterdata') {
      this.taxAccountsSelected = [];
    }
    this.mySelectionMasterData = [];
    this.mySelectionMasterDataSelected = [];
  }

  moveDataMaster(
    selection: any,
    selectionSelected: any,
    grid: any,
    gridSelected: any,
    dir: any
  ) {
    let maxSno: number = 1;
    if (this.exportedTransactionSelected.length > 0) {
      maxSno =
        this.exportedTransactionSelected[
          this.exportedTransactionSelected.length - 1
        ].Sno;
    }
    for (let i = 0; i < selection.length; i++) {
      selection[i].Sno = maxSno++;
      gridSelected.push(selection[i]);
      grid = grid.filter(
        (item: any) => item.TaxAccountCodeId !== selection[i].TaxAccountCodeId
      );

      // console.log(grid);
      if (dir == 'ltrmasterdata') {
        this.taxAccounts = grid;
      }
      if (dir == 'rtlmasterdata') {
        this.taxAccountsSelected = grid;
      }
    }
    if (selection.length > 0) {
      gridSelected = gridSelected.sort((a: any, b: any) =>
        a.PropertyName > b.PropertyName ? 1 : -1
      );
      // console.log(gridSelected);
    }
    this.mySelectionMasterData = [];
    this.mySelectionMasterDataSelected = [];
  }

  moveDataAllExport(fromData: any, toData: any, dir: any) {
    let maxSno: number = 1;
    let maxSno1: number = 1;
    if (this.exportedTransactionSelected.length > 0) {
      maxSno =
        this.exportedTransactionSelected[
          this.exportedTransactionSelected.length - 1
        ].Sno;
    }
    for (let i = 0; i < fromData.length; i++) {
      if (fromData[i].MandatoryItem == false) {
        fromData[i].Sno = maxSno++;
        toData.push(fromData[i]);
      }
    }
    toData = toData.sort((a: any, b: any) =>
      a.PropertyName > b.PropertyName ? 1 : -1
    );

    if (dir == 'ltrmasterdata') {
      this.exportedTransactionNotSelected = [];
    }
    if (dir == 'rtlmasterdata') {
      this.exportedTransactionSelected = [];
      this.exportedTransactionSelectedDefault.map((exp, i) => {
        exp.Sno = maxSno1++;
        this.exportedTransactionSelected.push(exp);
      });
      // this.exportedTransactionSelected =
      //   this.exportedTransactionSelectedDefault;
    }
    this.mySelectionExportData = [];
    this.mySelectionExportDataSelected = [];
    // this.mySelectionExportDataSelected =
    //   this.exportedTransactionSelectedDefault;

    this.exportedTransactionSelectedDefault.map((exp, i) => {
      this.mySelectionExportDataSelected.push(exp);
    });
    this.exportedTransactionSelected = this.exportedTransactionSelected.sort(
      (a: any, b: any) => (a.PropertyName > b.PropertyName ? 1 : -1)
    );
    this.mySelectionExportDataSelected =
      this.mySelectionExportDataSelected.sort((a: any, b: any) =>
        a.PropertyName > b.PropertyName ? 1 : -1
      );
  }

  moveDataExport(
    selection: any,
    selectionSelected: any,
    grid: any,
    gridSelected: any,
    dir: any
  ) {
    let maxSno: number = 0;
    if (this.exportedTransactionSelected.length > 0) {
      maxSno =
        this.exportedTransactionSelected[
          this.exportedTransactionSelected.length - 1
        ].Sno;
    }
    for (let i = 0; i < selection.length; i++) {
      if (selection[i].MandatoryItem == false) {
        maxSno = maxSno + 1;
        selection[i].Sno = maxSno;
        gridSelected.push(selection[i]);
        grid = grid.filter((item: any) => item.Id !== selection[i].Id);
      }

      // console.log(grid);
      if (dir == 'ltrmasterdata') {
        this.exportedTransactionNotSelected = grid;
      }
      if (dir == 'rtlmasterdata') {
        this.exportedTransactionSelected = grid;
      }
    }
    if (selection.length > 0) {
      gridSelected = gridSelected.sort((a: any, b: any) =>
        a.PropertyName > b.PropertyName ? 1 : -1
      );
      // console.log(gridSelected);
    }
    this.mySelectionExportData = [];
    this.mySelectionExportDataSelected = [];
  }

  onMoveDownButtonClick(): void {
    let selectedSno: number = this.mySelectionExportDataSelected[0].Sno;
    let selectedId: number = this.mySelectionExportDataSelected[0].Id;
    let oldSnoId: number = 0;

    let filter: offlineReferralExportResponse[] = [];

    filter = this.exportedTransactionSelected.filter(
      (f) => f.Sno == selectedSno + 1
    );

    if (filter.length > 0) {
      oldSnoId = filter[0].Id;
    }

    this.exportedTransactionSelected.map((exp) => {
      if (exp.Id == selectedId) {
        exp.Sno = selectedSno + 1;
      }
      if (exp.Id == oldSnoId) {
        exp.Sno = selectedSno;
      }
    });

    this.exportedTransactionSelected = this.exportedTransactionSelected.sort(
      (a: any, b: any) => (a.Sno > b.Sno ? 1 : -1)
    );
  }

  onMoveUpButtonClick(): void {
    let selectedSno: number = this.mySelectionExportDataSelected[0].Sno;
    let selectedId: number = this.mySelectionExportDataSelected[0].Id;
    let oldSnoId: number = 0;

    let filter: offlineReferralExportResponse[] = [];

    filter = this.exportedTransactionSelected.filter(
      (f) => f.Sno == selectedSno - 1
    );

    if (filter.length > 0) {
      oldSnoId = filter[0].Id;
    }

    this.exportedTransactionSelected.map((exp) => {
      if (exp.Id == selectedId) {
        exp.Sno = selectedSno - 1;
      }
      if (exp.Id == oldSnoId) {
        exp.Sno = selectedSno;
      }
    });

    this.exportedTransactionSelected = this.exportedTransactionSelected.sort(
      (a: any, b: any) => (a.Sno > b.Sno ? 1 : -1)
    );
  }

  resetAllCollection(): void {
    this.dataFilters = [];
    this.taxAccounts = [];
    this.selectedNumericOperatorId = 0;
    this.selectedNumericOperatorText = '';
    this.operatorDatas = [];
    this.filterOperatorDatas = [];
    this.taxAccountsSelected = [];
    this.filterOperatorDatas = [];
    this.filters = [];
    this.textFilterFilterTotalvalue = [];
    this.textFilterTotalvalue = [];
  }

  public onExpandedNamesChange(names: any[]): void {
    // console.log(names);
    if (names.length > 0) {
      this.selectedId = names[0];
    }
  }

  getAllOfflineReferralTransactions(): void {
    this.isLoading = true;
    this.offlineReferralTransactions = [];

    // console.log(this.selectedPeriodFrom);
    // console.log(this.selectedPeriodTo);

    if (this.selectedPeriodFrom == undefined) {
      this.selectedPeriodFrom = 0;
    }

    if (this.selectedPeriodTo == undefined) {
      this.selectedPeriodTo = 0;
    }

    let maxNo: number = 0;

    this.taxAccountsSelected.map((taxAccount, i) => {
      let pf: number = Number(this.selectedPeriodFrom);
      let pt: number = Number(this.selectedPeriodTo);
      this.offlineReferralItemL2s = [];
      this.offlineReferralItemL3s = [];
      maxNo = maxNo + 1;
      if (pf == 0 && pt == 0) {
        this.periods.map((p) => {
          this.offlineReferralItemL3 = {
            taxId: taxAccount.TaxAccountCodeId,
            filterType: 'Period',
            filterId: 0,
            type: 'Period',
            description: p.toString(),
            id: p,
            // parentId: maxNo,
            level: 'DataItem',
          };
          this.offlineReferralItemL3s.push(this.offlineReferralItemL3);
        });
      } else {
        while (pf <= pt) {
          this.offlineReferralItemL3 = {
            taxId: taxAccount.TaxAccountCodeId,
            filterType: 'Period',
            filterId: 0,
            type: 'Period',
            description: pf.toString(),
            id: pf,
            // parentId: maxNo,
            level: 'DataItem',
          };
          this.periodsFilter = this.periods.filter((f) => f == pf);
          if (this.periodsFilter.length > 0) {
            this.offlineReferralItemL3s.push(this.offlineReferralItemL3);
          }
          pf++;
        }
      }
      this.offlineReferralItemL2 = {
        taxId: taxAccount.TaxAccountCodeId,
        type: 'Period',
        id: maxNo,
        description: 'Period',
        level: 'Group',
        // parentId: taxAccount.TaxAccountCodeId,
        items: this.offlineReferralItemL3s,
      };

      this.offlineReferralItemL2s.push(this.offlineReferralItemL2);

      this.dataFilters.map((filter, i) => {
        if (filter.Values != undefined && filter.Values != '') {
          let val = filter.Values;
          if (val.length > 0) {
            let filterValues: string[] = val.split(';');
            this.offlineReferralItemL3s = [];

            filterValues.map((fv, i) => {
              let filterId: any;
              this.filterTotalfilters = this.totalfilters.filter(
                (f) => f.OperatorId == filter.Id && f.Value == fv
              );
              if (this.filterTotalfilters.length > 0) {
                filterId = this.filterTotalfilters[0].Id;
              }
              maxNo = maxNo + 1;
              this.offlineReferralItemL3 = {
                taxId: taxAccount.TaxAccountCodeId,
                filterType: filter.Type.toString(),
                filterId: filter.Id,
                type: filter.Name,
                description: fv,
                // id: maxNo,
                id: filterId,
                // parentId: filter.Id,
                level: 'DataItem',
              };
              this.offlineReferralItemL3s.push(this.offlineReferralItemL3);
            });
          }

          // console.log('offlineReferralItemL3s');
          // console.log(this.offlineReferralItemL3s);

          this.offlineReferralItemL2 = {
            taxId: taxAccount.TaxAccountCodeId,
            type: filter.Type.toString(),
            id: filter.Id,
            description: filter.Name,
            level: 'Group',
            // parentId: taxAccount.TaxAccountCodeId,
            items: this.offlineReferralItemL3s,
          };

          this.offlineReferralItemL2s.push(this.offlineReferralItemL2);
        }
        // console.log('offlineReferralItemL2s');
        // console.log(this.offlineReferralItemL2s);
      });

      this.offlineReferralTransaction = {
        taxId: taxAccount.TaxAccountCodeId,
        type: 'Tax Account',
        description: taxAccount.AccountingAreaDescription,
        id: taxAccount.TaxAccountCodeId,
        // parentId: 0,
        level: 'Folder',
        items: this.offlineReferralItemL2s,
      };
      this.offlineReferralTransactions.push(this.offlineReferralTransaction);
    });

    // this.offlineReferralTransactions = [];
    // this.offlineReferralTransactions = offlineReferralTransactionData;

    if (this.selectedId != 0) {
      this.expandedNames = [this.selectedId];
    }
    this.isLoading = false;
  }

  ViewTransaction(): void {
    if (this.selectedPeriodFrom == undefined) {
      this.selectedPeriodFrom = 0;
    }

    if (this.selectedPeriodTo == undefined) {
      this.selectedPeriodTo = 0;
    }

    if (Number(this.selectedPeriodFrom) > Number(this.selectedPeriodTo)) {
      this.toastr.errorToastr(
        'Period from should not be greater than Period To',
        'Error'
      );
      return;
    }
    if (this.taxAccountsSelected.length == 0) {
      this.toastr.errorToastr('Please select tax account.', 'Error');
      this.isLoading = false;
      return;
    }
    this.getGridSetting();
    this.getAllOfflineReferralTransactions();
    this.isViewTransaction = true;
    this.isGridDataItemTaxAccountVisible = false;
    this.checkedKeys = [];
    this.treeviewSelections = [];
    this.treeviewSelectionsFinal = [];
    this.treeviewSelections = [];
    this.finalRowSno = 0;
    this.finalFilters = [];
    this.pendingTransactionResponse = [];
    this.exportedTransactionResponse = [];
    this.allocatedTransactionResponse = [];

    this.currentTransactionExportedPageNo = 1;
    this.currentTransactionPendingPageNo = 1;
    this.currentTransactionAllocatedPageNo = 1;

    this.totalPageExported = 0;
    this.totalRowExported = 0;

    this.totalPagePending = 0;
    this.totalRowPending = 0;

    this.totalPageAllocated = 0;
    this.totalRowAllocated = 0;
  }

  CloseViewTransaction(): void {
    this.isViewTransaction = false;
    this.isGridDataItemTaxAccountVisible = true;
  }

  checkEvent(event: any) {
    this.treeviewSelectedPeriods = [];
    this.treeviewSelectedFilters = [];
    this.treeviewSelectedTransFilters = [];
    this.offlineReferralItemL3s = [];

    if (
      event.item.dataItem.type == 'Tax Account' &&
      event.item.dataItem.level == 'Folder'
    ) {
      event.item.dataItem.items.map((adjFolder: any) => {
        this.treeviewSelectedFilters = [];
        if (adjFolder.type == 'Period' && adjFolder.level == 'Group') {
          this.offlineReferralItemL3s = adjFolder.items;
          this.offlineReferralItemL3s.map((adj, i) => {
            this.treeviewSelectedPeriods.push(adj.id);
          });
        }
        if (adjFolder.type != 'Period' && adjFolder.level == 'Group') {
          this.offlineReferralItemL3s = adjFolder.items;
          this.offlineReferralItemL3s.map((adj, i) => {
            if (adjFolder.type == 'Number') {
              this.treeviewSelectedFilters.push(adj.description);
            } else {
              this.treeviewSelectedFilters.push(adj.id.toString());
            }
          });

          this.treeviewSelectedTransFilter = {
            id: adjFolder.id,
            description: adjFolder.description,
            filterItems: this.treeviewSelectedFilters,
          };
          this.treeviewSelectedTransFilters.push(
            this.treeviewSelectedTransFilter
          );
        }
      });
    }

    if (
      event.item.dataItem.type == 'Period' &&
      event.item.dataItem.level == 'Group'
    ) {
      this.offlineReferralItemL3s = event.item.dataItem.items;
      this.offlineReferralItemL3s.map((adj, i) => {
        this.treeviewSelectedPeriods.push(adj.id);
      });
    }

    if (
      event.item.dataItem.type == 'Period' &&
      event.item.dataItem.level == 'DataItem'
    ) {
      this.treeviewSelectedPeriods.push(event.item.dataItem.id);
    }

    if (
      event.item.dataItem.type != 'Period' &&
      event.item.dataItem.level == 'Group'
    ) {
      this.offlineReferralItemL3s = event.item.dataItem.items;
      this.offlineReferralItemL3s.map((adj, i) => {
        if (event.item.dataItem.type == 'Number') {
          this.treeviewSelectedFilters.push(adj.description);
        } else {
          this.treeviewSelectedFilters.push(adj.id.toString());
        }
      });

      this.treeviewSelectedTransFilter = {
        id: event.item.dataItem.id,
        description: event.item.dataItem.description,
        filterItems: this.treeviewSelectedFilters,
      };
      this.treeviewSelectedTransFilters.push(this.treeviewSelectedTransFilter);
    }

    if (
      event.item.dataItem.type != 'Period' &&
      event.item.dataItem.level == 'DataItem'
    ) {
      this.offlineReferralItemL3 = {
        taxId: event.item.dataItem.taxId,
        type: event.item.dataItem.type,
        filterType: event.item.dataItem.filterType,
        filterId: event.item.dataItem.filterId,
        id: event.item.dataItem.id,
        description: event.item.dataItem.description,
        level: event.item.dataItem.level,
        // parentId: event.item.dataItem.parentId,
      };
      if (event.item.dataItem.filterType == 'Number') {
        this.treeviewSelectedFilters.push(
          this.offlineReferralItemL3.description
        );
      } else {
        this.treeviewSelectedFilters.push(
          this.offlineReferralItemL3.id.toString()
        );
      }

      this.treeviewSelectedTransFilter = {
        id: event.item.dataItem.filterId,
        description: event.item.dataItem.type,
        filterItems: this.treeviewSelectedFilters,
      };
      this.treeviewSelectedTransFilters.push(this.treeviewSelectedTransFilter);
    }

    this.checkedFilterKeys = this.checkedKeys.filter(
      (f) => f == event.item.index
    );

    let isChecked: boolean = false;
    if (this.checkedFilterKeys.length > 0) {
      isChecked = true;
    } else {
      isChecked = false;
    }

    this.treeviewSelection = {
      index: event.item.index,
      checked: isChecked,
      taxId: event.item.dataItem.taxId,
      periods: this.treeviewSelectedPeriods,
      filters: this.treeviewSelectedTransFilters,
    };

    // this.treeviewSelectionsFilter = this.treeviewSelections.filter(
    //   (f) => f.index == event.item.index
    // );
    // if (this.treeviewSelectionsFilter.length == 0) {
    this.treeviewSelections.push(this.treeviewSelection);
    // }

    // if (this.treeviewSelectionsFilter.length > 0) {
    //   this.treeviewSelectionsBck = this.treeviewSelections.filter(
    //     (f) => f.index != event.item.index
    //   );
    //   this.treeviewSelections = [];

    //   this.treeviewSelectionsBck.map((tree, i) => {
    //     this.treeviewSelections.push(tree);
    //   });
    // }

    this.pendingTransactionResponse = [];
    this.exportedTransactionResponse = [];
    this.allocatedTransactionResponse = [];

    this.postPendingFilteredTransactionsCount();

    this.startingRowPending = 0;
    this.endingRowPending = 300;
    this.PostPendingFilteredTransactions(
      this.startingRowPending,
      this.endingRowPending
    );

    this.postExportedFilteredTransactionsCount();
    this.startingRowExported = 0;
    this.endingRowExported = 300;
    this.PostExportedFilteredTransactions(
      this.startingRowExported,
      this.endingRowExported
    );

    this.postAllocatedFilteredTransactionsCount();
    this.startingRowAllocated = 0;
    this.endingRowAllocated = 300;
    this.PostAllocatedFilteredTransactions(
      this.startingRowAllocated,
      this.endingRowAllocated
    );

    this.currentTransactionExportedPageNo = 1;
    this.currentTransactionPendingPageNo = 1;
    this.currentTransactionAllocatedPageNo = 1;

    this.totalPageExported = 0;
    this.totalRowExported = 0;

    this.totalPagePending = 0;
    this.totalRowPending = 0;

    this.totalPageAllocated = 0;
    this.totalRowAllocated = 0;
  }

  PostPendingFilteredTransactions(startIndex: number, maxRows: number): void {
    this.isLoading = true;
    let fiscalYear: any = this.selectedFiscalYear;
    let legalEntityId: any = this.selectedLegalEntityId;
    let transactionType: string = 'pending allocation';
    this.pendingTransactionResponse = [];
    this.OfflineReferralService.PostFilteredTransactions(
      fiscalYear,
      legalEntityId,
      transactionType,
      true,
      startIndex,
      maxRows,
      this.treeviewSelections
    ).subscribe(
      (response) => {
        // console.log('pendingTransactionResponse');
        // console.log(response);
        this.pendingTransactionResponse = JSON.parse(response);
        this.pendingTransactionResponse.map((pt) => {
          pt.Amount = this.pounds.format(pt.Amount).replace('£', '');
        });
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  postPendingFilteredTransactionsCount(): void {
    this.isLoading = true;
    let fiscalYear: any = this.selectedFiscalYear;
    let legalEntityId: any = this.selectedLegalEntityId;
    let transactionType: string = 'pending allocation';
    this.pendingTransactionResponse = [];
    this.OfflineReferralService.PostFilteredTransactionsCount(
      fiscalYear,
      legalEntityId,
      transactionType,
      true,
      this.treeviewSelections
    ).subscribe(
      (response) => {
        // console.log('pendingTransactionResponse Count');
        // console.log(response);
        this.totalRowPending = JSON.parse(response);

        let rows: number = 0;
        this.totalPagePending = 0;
        while (rows < this.totalRowPending) {
          this.totalPagePending = this.totalPagePending + 1;
          rows = rows + 300;
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  PostExportedFilteredTransactions(startIndex: number, maxRows: number): void {
    this.isLoading = true;
    let fiscalYear: any = this.selectedFiscalYear;
    let legalEntityId: any = this.selectedLegalEntityId;
    let transactionType: string = 'exported';
    this.OfflineReferralService.PostFilteredTransactions(
      fiscalYear,
      legalEntityId,
      transactionType,
      true,
      startIndex,
      maxRows,
      this.treeviewSelections
    ).subscribe(
      (response) => {
        // console.log('this.exportedTransactionResponse');
        // console.log(response);
        this.exportedTransactionResponse = JSON.parse(response);

        this.exportedTransactionResponse.map((pt) => {
          pt.Amount = this.pounds.format(pt.Amount).replace('£', '');
        });
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  postExportedFilteredTransactionsCount(): void {
    this.isLoading = true;
    let fiscalYear: any = this.selectedFiscalYear;
    let legalEntityId: any = this.selectedLegalEntityId;
    let transactionType: string = 'exported';
    this.pendingTransactionResponse = [];
    this.OfflineReferralService.PostFilteredTransactionsCount(
      fiscalYear,
      legalEntityId,
      transactionType,
      true,
      this.treeviewSelections
    ).subscribe(
      (response) => {
        // console.log('pendingTransactionResponse Count');
        // console.log(response);
        this.totalRowExported = JSON.parse(response);

        let rows: number = 0;
        this.totalPageExported = 0;
        while (rows < this.totalRowExported) {
          this.totalPageExported = this.totalPageExported + 1;
          rows = rows + 300;
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  PostAllocatedFilteredTransactions(startIndex: number, maxRows: number): void {
    this.isLoading = true;
    let fiscalYear: any = this.selectedFiscalYear;
    let legalEntityId: any = this.selectedLegalEntityId;
    let transactionType: string = 'allocated';
    this.OfflineReferralService.PostFilteredTransactions(
      fiscalYear,
      legalEntityId,
      transactionType,
      true,
      startIndex,
      maxRows,
      this.treeviewSelections
    ).subscribe(
      (response) => {
        // console.log(response);
        this.allocatedTransactionResponse = JSON.parse(response);

        this.allocatedTransactionResponse.map((pt) => {
          pt.Amount = this.pounds.format(pt.Amount).replace('£', '');
        });

        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  postAllocatedFilteredTransactionsCount(): void {
    this.isLoading = true;
    let fiscalYear: any = this.selectedFiscalYear;
    let legalEntityId: any = this.selectedLegalEntityId;
    let transactionType: string = 'allocated';
    this.pendingTransactionResponse = [];
    this.OfflineReferralService.PostFilteredTransactionsCount(
      fiscalYear,
      legalEntityId,
      transactionType,
      true,
      this.treeviewSelections
    ).subscribe(
      (response) => {
        // console.log('pendingTransactionResponse Count');
        // console.log(response);
        this.totalRowAllocated = JSON.parse(response);

        let rows: number = 0;
        this.totalPageAllocated = 0;
        while (rows < this.totalRowAllocated) {
          this.totalPageAllocated = this.totalPageAllocated + 1;
          rows = rows + 300;
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  public onClose(e: TabCloseEvent): void {
    // console.log(e);
    // console.log(`tabClose: ${e.index} ${e.tab.title}`);
  }

  public onSelect(e: SelectEvent): void {
    // console.log(`tabSelect: ${JSON.stringify(e)}`);
  }

  exportAll(): void {
    this.isLoading = true;
    if (this.selectedPeriodFrom == undefined) {
      this.selectedPeriodFrom = 0;
    }

    if (this.selectedPeriodTo == undefined) {
      this.selectedPeriodTo = 0;
    }

    if (Number(this.selectedPeriodFrom) > Number(this.selectedPeriodTo)) {
      this.toastr.errorToastr(
        'Period from should not be greater than Period To',
        'Error'
      );
      return;
    }

    if (this.taxAccountsSelected.length == 0) {
      this.toastr.errorToastr('Please select tax account.', 'Error');
      this.isLoading = false;
      return;
    }

    this.exportedTransactionNotSelected = [];
    this.exportedTransactionSelected = [];
    this.mySelectionExportDataSelected = [];
    this.mySelectionExportData = [];

    this.getAllOfflineReferralTransactions();
    this.isExportClicked = true;
    this.isGridDataItemTaxAccountVisible = false;

    this.exportedTransactionSelected = [];
    this.exportedTransactionNotSelected = [];
    this.exportedTransactionSelectedDefault = [];

    this.exportFilters = [];

    this.CreateExportAllBody();

    this.dataFilters.map((fltr, i) => {
      if (fltr.Values != '' && fltr.Values != undefined) {
        this.exportFilters.push(fltr.Id);
      }
    });

    this.OfflineReferralService.PostExport(this.exportFilters).subscribe(
      (response) => {
        this.exportedTransaction = JSON.parse(response);
        this.exportedTransactionSelected = this.exportedTransaction.filter(
          (f) => f.Selected == true
        );

        //  console.log(this.exportedTransactionSelected)
        this.exportedTransactionNotSelected = this.exportedTransaction.filter(
          (f) => f.Selected == false
        );
        this.exportedTransactionSelectedDefault =
          this.exportedTransaction.filter((f) => f.MandatoryItem == true);

        this.resetSnoExportedTransactionSelected();

        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );

    // this.resetSnoExportedTransactionSelected();

    this.CloseViewTransaction();

    this.isExportClicked = true;
    this.isGridDataItemTaxAccountVisible = false;
  }

  closeExport(): void {
    this.isExportClicked = false;
    this.isGridDataItemTaxAccountVisible = true;
  }

  CreateExportAllBody(): void {
    this.treeviewSelectedPeriods = [];
    this.treeviewSelectedFilters = [];
    this.treeviewSelectedTransFilters = [];
    this.offlineReferralItemL3s = [];

    this.treeviewSelectedPeriods = [];
    this.treeviewSelectedTransFilters = [];

    if (this.isViewTransaction == false) {
      this.treeviewSelections = [];
      this.offlineReferralTransactions.map((tr: offlineReferralTransaction) => {
        if (tr.type == 'Tax Account') {
          this.offlineReferralItemL2s = tr.items;
          this.offlineReferralItemL2s.map((tr2, i) => {
            if (tr2.type == 'Period') {
              this.offlineReferralItemL3s = tr2.items;
              this.offlineReferralItemL3s.map((tr3, i) => {
                this.treeviewSelectedPeriods.push(tr3.id);
              });
            }
            if (tr2.type != 'Period') {
              this.offlineReferralItemL3s = tr2.items;
              this.offlineReferralItemL3s.map((tr3, i) => {
                if (tr3.filterType == 'Number') {
                  this.treeviewSelectedFilters.push(tr3.description);
                } else {
                  this.treeviewSelectedFilters.push(tr3.id.toString());
                }
              });
              this.treeviewSelectedTransFilter = {
                id: tr2.id,
                description: tr2.description,
                filterItems: this.treeviewSelectedFilters,
              };
              this.treeviewSelectedTransFilters.push(
                this.treeviewSelectedTransFilter
              );
              this.treeviewSelectedFilters = [];
            }
          });

          this.treeviewSelection = {
            index: this.exportRowIndex.toString(),
            checked: false,
            taxId: tr.taxId,
            periods: this.treeviewSelectedPeriods,
            filters: this.treeviewSelectedTransFilters,
          };
          this.treeviewSelections.push(this.treeviewSelection);
          this.treeviewSelectedPeriods = [];
          this.treeviewSelectedTransFilters = [];
        }
        this.exportRowIndex = this.exportRowIndex + 1;
      });
    }
  }

  createExportTransactionBody(): void {
    this.exportedTransactionSelected.map((adj, i) => {
      this.selectedExportDataColumns.push(adj.Id);
    });
    if (this.treeviewSelections.length == 1) {
      this.treeviewSelections[0].checked = true;
    }

    this.exportTransactionBody = {
      mergeFilterData: true,
      clientdbName: this.selectedClient.DBName,
      fiscalYear: this.selectedFiscalYear,
      legalEntityId: this.selectedLegalEntityId,
      exportDataColumns: this.selectedExportDataColumns,
      offlineReferralFilters: this.treeviewSelections,
      TriggerBy: '',
      OrchestrationId: '',
    };
  }

  export(): void {
    this.isLoading = true;
    this.createExportTransactionBody();
    this.postValidateExportResponse = [];
    let CanCallExport = false;
    this.OfflineReferralService.PostValidateExport(
      this.exportTransactionBody
    ).subscribe(
      (response) => {
        this.postValidateExportResponse = JSON.parse(response);
        CanCallExport = this.postValidateExportResponse.CanCallExport;

        if (this.postValidateExportResponse.Message.length > 0) {
          const strValue = this.postValidateExportResponse.Message.join(' ');
          this.toastr.errorToastr(strValue, 'Error', { enableHtml: true });
        }
        // this.getDownloadExcel();
        if (CanCallExport) {
          this.offlineReferralExportprocessing();
        }
        this.isExportClicked = true;
        this.closeExport();
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.toastr.errorToastr('Data export failed.', 'Error');
        this.isLoading = false;
      }
    );
  }

  offlineReferralExportprocessing() {
    this.isLoading = true;
    this.OfflineReferralService.PostExportOfflineReferralProcessingStart(
      this.exportTransactionBody
    ).subscribe(
      (response: offlineReferralExportProcessingStartResponse) => {
        if (response) {
          this.processingOrchestrationId = response.orchestrationId;
          this.getProcessingStatus();
          this.isLoading = false;
        }
      },
      (error: any) => {
        this.isLoading = false;
        this.toastr.errorToastr('Data export failed.', 'Error');
        console.log(error);
      }
    );
  }

  getProcessingStatus() {
    this.isLoading = true;
    this.OfflineReferralService.GetExportOfflineReferralProcessingStatus(
      this.processingOrchestrationId
    ).subscribe(
      (response: any) => {
        this.afterExportDetails = response.body;
        if (
          this.afterExportDetails.orchestrationRuntimeStatus == 'Completed' ||
          this.afterExportDetails.orchestrationRuntimeStatus == 'Failed'
        ) {
          if (
            this.afterExportDetails.orchestrationRuntimeStatus == 'Completed'
          ) {
            if (
              this.afterExportDetails.isExportProcessingCompletedWithSuccess ==
              true
            ) {
              this.downloadOfflineReferralExportExcelFile(
                this.afterExportDetails.generatedExportFileName
              );
            } else {
              this.exportProcessingErrorMessage =
                this.afterExportDetails.exportProcessingErrorMessage;

              this.toastr.errorToastr('Data export failed.', 'Error');
            }
          } else if (
            this.afterExportDetails.orchestrationRuntimeStatus == 'Failed'
          ) {
            this.exportProcessingErrorMessage =
              this.afterExportDetails.exportProcessingErrorMessage;

            this.toastr.errorToastr('Data export failed.', 'Error');
            this.isLoading = false;
          }
          this.isLoading = false;
          this.isExportSuccess = true;
        } else this.getProcessingStatus();
      },
      (error: any) => {
        this.isLoading = false;
        this.toastr.errorToastr('Data export failed.', 'Error');
        console.log(error);
        return error;
      }
    );
  }

  downloadOfflineReferralExportExcelFile(generatedExportFileName: any) {
    this.isLoading = true;
    this.OfflineReferralService.downloadOfflineReferralExcelFile(
      generatedExportFileName
    ).subscribe(
      (response: any) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const fileName = generatedExportFileName;
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.isLoading = false;
      },
      (error: any) => {
        this.isLoading = false;
        this.toastr.errorToastr('Data export failed.', 'Error');
        console.log(error);
      }
    );
  }

  getGridSetting(): void {
    this.isLoading = true;
    this.gridColumns = [];
    this.gridSettings = [];
    this.OfflineReferralService.getGridSetting(
      'OfflineReferralGridColumns'
    ).subscribe(
      (response) => {
        this.gridSettings = response;

        this.gridSettings.map((gr, i) => {
          if (gr.Selected == true) {
            this.gridColumn = {
              field: gr.DbColumnName,
              title: gr.DisplayName,
              type: 'text',
            };
            this.gridColumns.push(this.gridColumn);
          } else {
            this.hiddenColumns.push(gr.DisplayName);
          }
          this.columns.push(gr.DisplayName);
        });

        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }
  navigate() {
    this.router.navigate(['/analysisreview']);
  }

  forwardPendingData(): void {
    if (this.totalRowPending == 0) {
      this.currentTransactionPendingPageNo = 1;
      return;
    }
    this.currentTransactionPendingPageNo =
      this.currentTransactionPendingPageNo + 1;
    this.startingRowPending = this.startingRowPending + 300;
    this.endingRowPending = this.endingRowPending + 300;
    if (this.startingRowPending >= this.totalRowPending) {
      this.startingRowPending = this.totalRowPending - 300;
      this.currentTransactionPendingPageNo =
        this.currentTransactionPendingPageNo - 1;
    }
    if (this.endingRowPending > this.totalRowPending) {
      this.endingRowPending = this.totalRowPending;
    }
    this.PostPendingFilteredTransactions(
      this.startingRowPending,
      this.endingRowPending
    );
  }

  forwardPendingDataToLast(): void {
    if (this.totalRowPending == 0) {
      this.currentTransactionPendingPageNo = 1;
      return;
    }
    let rows: number = 0;
    this.currentTransactionPendingPageNo = 0;
    while (rows < this.totalRowPending) {
      this.currentTransactionPendingPageNo =
        this.currentTransactionPendingPageNo + 1;
      rows = rows + 300;
    }

    this.startingRowPending = this.totalRowPending - 300;
    this.endingRowPending = this.totalRowPending;

    this.PostPendingFilteredTransactions(
      this.startingRowPending,
      this.endingRowPending
    );
  }

  backwardPendingData(): void {
    if (this.totalRowPending == 0) {
      this.currentTransactionPendingPageNo = 1;
      return;
    }
    this.currentTransactionPendingPageNo =
      this.currentTransactionPendingPageNo - 1;
    this.startingRowPending = this.startingRowPending - 300;
    if (this.startingRowPending < 0) {
      this.startingRowPending = 0;
      this.currentTransactionPendingPageNo = 1;
    }
    this.endingRowPending = this.endingRowPending - 300;
    if (this.endingRowPending < 300) {
      this.endingRowPending = 300;
      this.currentTransactionPendingPageNo = 1;
    }
    this.PostPendingFilteredTransactions(
      this.startingRowPending,
      this.endingRowPending
    );
  }

  backwardPendingDataToFirst(): void {
    if (this.totalRowPending == 0) {
      this.currentTransactionPendingPageNo = 1;
      return;
    }
    this.currentTransactionPendingPageNo = 1;
    this.startingRowPending = 0;
    this.endingRowPending = 300;

    this.PostPendingFilteredTransactions(
      this.startingRowPending,
      this.endingRowPending
    );
  }

  forwardExportedData(): void {
    if (this.totalRowExported == 0) {
      this.currentTransactionExportedPageNo = 1;
      return;
    }
    this.currentTransactionExportedPageNo =
      this.currentTransactionExportedPageNo + 1;
    this.startingRowExported = this.startingRowExported + 300;
    this.endingRowExported = this.endingRowExported + 300;
    if (this.startingRowExported >= this.totalRowExported) {
      this.startingRowExported = this.totalRowExported - 300;
      this.currentTransactionExportedPageNo =
        this.currentTransactionExportedPageNo - 1;
    }
    if (this.endingRowExported > this.totalRowExported) {
      this.endingRowExported = this.totalRowExported;
    }
    this.PostExportedFilteredTransactions(
      this.startingRowExported,
      this.endingRowExported
    );
  }

  forwardExportedDataToLast(): void {
    if (this.totalRowExported == 0) {
      this.currentTransactionExportedPageNo = 1;
      return;
    }
    let rows: number = 0;
    this.currentTransactionExportedPageNo = 0;
    while (rows < this.totalRowExported) {
      this.currentTransactionExportedPageNo =
        this.currentTransactionExportedPageNo + 1;
      rows = rows + 300;
    }

    this.startingRowExported = this.totalRowExported - 300;
    this.endingRowExported = this.totalRowExported;

    this.PostExportedFilteredTransactions(
      this.startingRowExported,
      this.endingRowExported
    );
  }

  backwardExportedData(): void {
    if (this.totalRowExported == 0) {
      this.currentTransactionExportedPageNo = 1;
      return;
    }
    this.currentTransactionExportedPageNo =
      this.currentTransactionExportedPageNo - 1;
    this.startingRowExported = this.startingRowExported - 300;
    if (this.startingRowExported < 0) {
      this.startingRowExported = 0;
      this.currentTransactionExportedPageNo = 1;
    }
    this.endingRowExported = this.endingRowExported - 300;
    if (this.endingRowExported < 300) {
      this.endingRowExported = 300;
      this.currentTransactionExportedPageNo = 1;
    }
    this.PostExportedFilteredTransactions(
      this.startingRowExported,
      this.endingRowExported
    );
  }

  backwardExportedDataToFirst(): void {
    if (this.totalRowExported == 0) {
      this.currentTransactionExportedPageNo = 1;
      return;
    }
    this.currentTransactionExportedPageNo = 1;
    this.startingRowExported = 0;
    this.endingRowExported = 300;

    this.PostExportedFilteredTransactions(
      this.startingRowExported,
      this.endingRowExported
    );
  }

  forwardAllocatedData(): void {
    if (this.totalRowAllocated == 0) {
      this.currentTransactionAllocatedPageNo = 1;
      return;
    }
    this.currentTransactionAllocatedPageNo =
      this.currentTransactionPendingPageNo + 1;
    this.startingRowAllocated = this.startingRowAllocated + 300;
    this.endingRowAllocated = this.endingRowAllocated + 300;
    if (this.startingRowAllocated >= this.totalRowAllocated) {
      this.startingRowAllocated = this.totalRowAllocated - 300;
      this.currentTransactionAllocatedPageNo =
        this.currentTransactionAllocatedPageNo - 1;
    }
    if (this.endingRowAllocated > this.totalRowAllocated) {
      this.endingRowAllocated = this.totalRowAllocated;
    }
    this.PostAllocatedFilteredTransactions(
      this.startingRowAllocated,
      this.endingRowAllocated
    );
  }

  forwardAllocatedDataToLast(): void {
    if (this.totalRowAllocated == 0) {
      this.currentTransactionAllocatedPageNo = 1;
      return;
    }
    let rows: number = 0;
    this.currentTransactionAllocatedPageNo = 0;
    while (rows < this.totalRowAllocated) {
      this.currentTransactionAllocatedPageNo =
        this.currentTransactionAllocatedPageNo + 1;
      rows = rows + 300;
    }

    this.startingRowAllocated = this.totalRowAllocated - 300;
    this.endingRowAllocated = this.totalRowAllocated;

    this.PostAllocatedFilteredTransactions(
      this.startingRowAllocated,
      this.endingRowAllocated
    );
  }

  backwardAllocatedData(): void {
    if (this.totalRowAllocated == 0) {
      this.currentTransactionAllocatedPageNo = 1;
      return;
    }
    this.currentTransactionAllocatedPageNo =
      this.currentTransactionAllocatedPageNo - 1;
    this.startingRowAllocated = this.startingRowAllocated - 300;
    if (this.startingRowAllocated < 0) {
      this.startingRowAllocated = 0;
      this.currentTransactionAllocatedPageNo = 1;
    }
    this.endingRowAllocated = this.endingRowAllocated - 300;
    if (this.endingRowAllocated < 300) {
      this.endingRowAllocated = 300;
      this.currentTransactionAllocatedPageNo = 1;
    }
    this.PostAllocatedFilteredTransactions(
      this.startingRowAllocated,
      this.endingRowAllocated
    );
  }

  backwardAllocatedDataToFirst(): void {
    if (this.totalRowAllocated == 0) {
      this.currentTransactionAllocatedPageNo = 1;
      return;
    }
    this.currentTransactionAllocatedPageNo = 1;
    this.startingRowAllocated = 0;
    this.endingRowAllocated = 300;

    this.PostAllocatedFilteredTransactions(
      this.startingRowAllocated,
      this.endingRowAllocated
    );
  }

  openWindow() {
    this.opened = true;
  }

  saveColumn() {
    this.saveChooseColumnSettings();
    this.openClose(false);
  }

  saveChooseColumnSettings() {
    this.isLoading = true;
    this.gridColumns = [];
    this.gridSettings.map((gr, i) => {
      if (this.hiddenColumns.includes(gr.DisplayName)) {
        gr.Selected = false;
      } else {
        gr.Selected = true;
        this.gridColumn = {
          field: gr.DbColumnName,
          title: gr.DisplayName,
          type: 'text',
        };
        this.gridColumns.push(this.gridColumn);
      }
    });

    this.OfflineReferralService.PostGridColumnList(
      this.gridSettings,
      'OfflineReferralGridColumns'
    ).subscribe(
      (response) => {
        if (response == 'true') {
          this.toastr.successToastr(
            'Selected Grid columns saved successfully.',
            'Success'
          );
        } else {
          this.toastr.errorToastr(
            'Selected Grid columns saved failed.',
            'Error'
          );
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }
}
