import { Component, Input, OnInit } from '@angular/core';
import {
  ActionsLayout,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-merge-tax-outcomes',
  templateUrl: './merge-tax-outcomes.component.html',
  styleUrls: ['./merge-tax-outcomes.component.scss'],
})
export class MergeTaxOutcomesComponent implements OnInit {
  @Input() data: any;
  selectedItem: any;
  public actionsLayout: ActionsLayout = 'normal';

  defaultTaxOutcomeList: any = [
    {
      DefaultOption: true,
      HelpId: '',
      LongDescription: 'Allow',
      PermanentTimingDiff: false,
      RateAllowableCY: 0,
      RateAllowableEventually: 0,
      ShortDescription: 'Allow',
      _id: 1,
    },
    {
      DefaultOption: true,
      HelpId: '',
      LongDescription: 'Allow',
      PermanentTimingDiff: false,
      RateAllowableCY: 0,
      RateAllowableEventually: 0,
      ShortDescription: 'Happiee',
      _id: 2,
    },
  ];
  constructor(public dialog: DialogRef, private dialogService: DialogService) {}

  ngOnInit(): void {}

  cancelClick() {
    this.dialog.close();
  }

  save() {}
}
