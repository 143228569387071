<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">
    {{ popupCaption }}
  </div>
</kendo-dialog-titlebar>
<div>
  <form class="k-form" [formGroup]="statementForm">
    <kendo-formfield>
      <kendo-label text="" style="font-weight: bold"
        >Statement path - {{ statementPath }}</kendo-label
      >
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label
        [for]="pSubAnalysis"
        text="Parent sub-analysis"
      ></kendo-label>
      <kendo-combobox
        #pSubAnalysis
        required
        formControlName="pSubAnalysis"
        [data]="drpPSubAnalysisList"
        textField="BlockName"
        valueField="StatementLayoutBlockId"
        [kendoDropDownFilter]="filterSettings"
      >
      </kendo-combobox>
      <kendo-formerror>Parent sub-analysis</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label text="Description*"></kendo-label>
      <input
        kendoTextBox
        [maxlength]="50"
        required
        #txtDescription
        formControlName="txtDescription"
      />
      <kendo-formerror>Please enter description</kendo-formerror>
    </kendo-formfield>
    <!-- <br />
    <ap-field [title]="'Description:'" [required]="true">
      <input appkit-field formControlName="txtDescription" />
      <div
        *ngIf="
          statementForm.controls['txtDescription'].invalid &&
          (statementForm.controls['txtDescription'].dirty ||
            statementForm.controls['txtDescription'].touched)
        "
        id="errormessage"
        aria-live="polite"
        class="ap-field-email-validation-error"
      >
        Please enter Description
      </div>
    </ap-field> -->

    <kendo-formfield>
      <kendo-label
        [for]="drpLayout"
        text="Layout*"
        [required]="true"
      ></kendo-label>
      <kendo-combobox
        #drpLayout
        required
        formControlName="drpLayout"
        [data]="drpLayoutList"
        textField="Description"
        valueField="StatementLayoutId"
        placeholder="Select Layout"
        (valueChange)="onItemClickDrp($event)"
        [kendoDropDownFilter]="filterSettings"
      >
      </kendo-combobox>
      <kendo-formerror>Layout is required</kendo-formerror>
    </kendo-formfield>
    <br />
    Type : {{ statementGroupType }}
  </form>
</div>
<!-- <mat-card class="userCard" style="padding: 0px">
  <mat-card-title style="font-style: italic">
    <b> Statement Path - {{ statementPath }}</b>
  </mat-card-title>
  <mat-card-content>
    <div>
      
    </div>
  </mat-card-content>
</mat-card> -->

<kendo-dialog
  title="Change Layout"
  *ngIf="warnondelete"
  (close)="changeLayout(true)"
  [minWidth]="250"
  [width]="700"
>
  <div style="display: flex">
    <p>
      <span
        class="Appkit4-icon icon-circle-warning-outline"
        style="font-size: 24px; color: #d04a02"
      ></span>
    </p>
    <p style="margin-left: 10px; font-size: medium">
      Changing the layout, will result in the loss of any linked accounting
      areas and statements.<br /><br />Do you wish to continue?
    </p>
  </div>
  <!-- Click to Actions -->
  <div class="clickToActions" style="padding: 10px 0px 0px 0px">
    <ap-button
      [btnType]="'secondary'"
      [label]="'No'"
      (click)="changeLayout(false)"
      class="cancelButton"
      style="width: 100px"
    ></ap-button>
    <ap-button
      [btnType]="'primary'"
      [label]="'Yes'"
      themeColor="primary"
      (click)="changeLayout(true)"
      class="okButton"
      style="margin-right: 6px"
    ></ap-button>
  </div>
  <!-- <kendo-dialog-actions [layout]="actionsLayout">
    <button
      kendoButton
      class="actionButton btn-pwc-primary"
      (click)="changeLayout(true)"
    >
      Yes
    </button>
    <button kendoButton class="secondary" (click)="changeLayout(false)">
      No
    </button>
  </kendo-dialog-actions> -->
</kendo-dialog>

<div class="clickToActions" style="padding-top: 10px">
  <ap-button
    [btnType]="'secondary'"
    class="cancelButton"
    [label]="'Cancel'"
    (onClick)="cancelClick()"
  ></ap-button
  >&nbsp;

  <ap-button
    [btnType]="'primary'"
    class="okButton"
    [label]="'Save'"
    (onClick)="saveNewAnalysisSatement()"
    style="margin-right: 6px"
    [disabled]="statementForm.invalid"
  ></ap-button
  >&nbsp;
</div>
