<!-- Header -->
<div class="header">Manage line item</div>
<!-- Edit line item form -->
<div class="container-fluid">
  <div class="col-md-12 col-xs-12" style="padding: 0px !important">
    <div class="subHeader">
      Line item details - Business type: {{ BusinessDesc }}
    </div>
  </div>

  <div class="row">
    <div class="col-md-8 col-xs-12">
      <form class="k-form" [formGroup]="editLineItemForm">
        <kendo-formfield class="form-field">
          <kendo-label
            [for]="AccountingAreaID"
            text="Line item id:"
          ></kendo-label>
          <input
            formControlName="AccountingAreaID"
            kendoTextBox
            #AccountingAreaID
            [disabled]="true"
          />
        </kendo-formfield>
        <kendo-formfield class="form-field">
          <kendo-label [for]="Description" text="Description:*"></kendo-label>
          <input
            formControlName="Description"
            [maxlength]="100"
            kendoTextBox
            #Description
          />
          <kendo-formerror>{{ descriptionErrorMessage }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield class="form-field">
          <kendo-label
            [for]="AccountClass"
            text="Account class:*"
          ></kendo-label>
          <kendo-combobox
            #AccountClass
            required
            [disabled]="true"
            formControlName="AccountClass"
            [data]="AccountClassDescriptionList"
            [filterable]="true"
            textField="Description"
            valueField="ID"
            (valueChange)="handleAccountClassChange($event)"
          >
            <kendo-formerror>{{ accountclassErrorMessage }}</kendo-formerror>
          </kendo-combobox>
        </kendo-formfield>
        <kendo-formfield class="form-field">
          <kendo-label
            [for]="ParentLineItem"
            text="Parent line item:"
          ></kendo-label>
          <kendo-combobox
            #ParentLineItem
            formControlName="ParentLineItem"
            [data]="AccountingAreaDescriptionList"
            [filterable]="true"
            textField="Description"
            valueField="LineID"
            (filterChange)="handleFilterParentLineItem($event)"
          >
          </kendo-combobox>
        </kendo-formfield>
        <br />
        <kendo-formfield style="margin-top: 0px">
          <div style="display: flex">
            <input
              type="checkbox"
              #CreateTaxAccount
              kendoCheckBox
              formControlName="CreateTaxAccount"
            />&nbsp;&nbsp;
            <kendo-label [for]="CreateTaxAccount" text="Create tax account">
            </kendo-label>
          </div>
          <div style="position: absolute; right: 1%; display: flex">
            <ap-button
              [btnType]="'primary'"
              [label]="'Save'"
              [disabled]="!editLineItemForm.valid"
              style="margin-right: 6px"
              themeColor="primary"
              class="okButton"
              (click)="saveEditLineItem()"
            ></ap-button>
            <ap-button
              [btnType]="'secondary'"
              [label]="'Cancel'"
              class="cancelButton"
              (click)="cancelClick()"
            ></ap-button>
          </div>
        </kendo-formfield>
      </form>
      <!-- Click to Actions -->
      <!-- <div class="clickToActions">
        <ap-button
          [btnType]="'secondary'"
          [label]="'Cancel'"
          class="cancelButton"
          (click)="cancelClick()"
        ></ap-button>

        <ap-button
          [btnType]="'primary'"
          [label]="'Save'"
          [disabled]="!editLineItemForm.valid"
          style="margin-right: 6px;"
          themeColor="primary"
          class="okButton"
          (click)="saveEditLineItem()"
        ></ap-button>
      </div> -->
    </div>
    <div class="col-md-4 col-xs-12"></div>
  </div>
</div>
