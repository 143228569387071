import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { AddTransaction } from 'src/app/_models/addTransaction';

const CREATE_ACTION = 'create';
const UPDATE_ACTION = 'update';
const REMOVE_ACTION = 'destroy';

const itemIndex = (item: AddTransaction, data: AddTransaction[]): number => {
  for (let idx = 0; idx < data.length; idx++) {
    if (data[idx].DataRow === item.DataRow) {
      return idx;
    }
  }

  return -1;
};

const cloneData = (data: AddTransaction[]) =>
  data.map((item) => Object.assign({}, item));

@Injectable({
  providedIn: 'root',
})
export class EditServiceTransaction extends BehaviorSubject<unknown[]> {
  private data: AddTransaction[] = [];
  private originalData: AddTransaction[] = [];
  private createdItems: AddTransaction[] = [];
  private updatedItems: AddTransaction[] = [];
  private deletedItems: AddTransaction[] = [];

  constructor(private http: HttpClient) {
    super([]);
  }

  public read(): void {
    if (this.data.length) {
      return super.next(this.data);
    }
    this.originalData = cloneData(this.data);
    super.next(this.data);
  }

  public clean(): void {
    this.data = [];
  }

  public update(item: AddTransaction): void {
    if (!this.isNew(item)) {
      const index = itemIndex(item, this.updatedItems);
      if (index !== -1) {
        this.updatedItems.splice(index, 1, item);
      } else {
        this.updatedItems.push(item);
      }
    } else {
      const index = this.createdItems.indexOf(item);
      this.createdItems.splice(index, 1, item);
    }
  }

  public remove(item: AddTransaction): void {
    let index = itemIndex(item, this.data);
    this.data.splice(index, 1);

    index = itemIndex(item, this.createdItems);
    if (index >= 0) {
      this.createdItems.splice(index, 1);
    } else {
      this.deletedItems.push(item);
    }

    index = itemIndex(item, this.updatedItems);
    if (index >= 0) {
      this.updatedItems.splice(index, 1);
    }

    super.next(this.data);
  }

  public create(item: AddTransaction): void {
    this.createdItems.push(item);
    this.data.unshift(item);

    super.next(this.data);
  }

  public isNew(item: AddTransaction): boolean {
    return !item.DataRow;
  }

  public assignValues(target: any, source: unknown): void {
    Object.assign(target, source);
  }
}
