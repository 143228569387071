import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectEvent, TabCloseEvent } from '@progress/kendo-angular-layout';
import {
  DialogContentBase,
  ActionsLayout,
  DialogAction,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from '@progress/kendo-angular-dialog';
import { JournalService } from 'src/app/common/services/journal/journal-service';
import { Manageattachementservice } from 'src/app/common/services/manageattachement/manageattachement.service';
import { BusinessType } from 'src/app/_models/businessType';
import { LegalEntity } from 'src/app/_models/legalEntity';
import { orderBy, process, SortDescriptor } from '@progress/kendo-data-query';
import { SelectionEvent } from '@progress/kendo-angular-grid';
import { ChunkSettings, FileRestrictions, SuccessEvent, UploadEvent, ErrorEvent } from '@progress/kendo-angular-upload';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { environment } from 'src/environments/environment';
import { Worksheet } from 'src/app/_models/worksheet';



@Component({
  selector: 'app-manage-attachement',
  templateUrl: './manage-attachement.component.html',
  styleUrls: ['./manage-attachement.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageAttachementComponent
  implements OnInit {

  @ViewChild('fileInput') fileInput: any;

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private journalService: JournalService,
    public toastr: ToastrManager,
    private manageattachementservice: Manageattachementservice,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.selectedYear = JSON.parse(year).FiscalYearId;
    this.getBusinessTypeList();
    this.getAdditionalFileData();
    this.loadUploadURL();
    this.setupForm();

  }

  loadUploadURL() {
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    let tokenValue: any = localStorage.getItem('OPENAMSESSION');
    this.headers = {
      'Authorization-Token':
        'Bearer ' +
        JSON.parse(tokenValue).access_token,
    };
    let selected: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selected);
    this.uploadSaveUrl =
      this.baseUrl +
      this.apifolder +
      '/ManageStandaloneAttachment/UploadFile?ClientdbName=' +
      this.selectedClient.DBName;

    this.uploadRemoveUrl = 'removeUrl';
  }
  headers: any;
  headersObj: any;
  private apifolder: string = '';
  private baseUrl: string = '';
  private selectedClient: any;
  uploadSaveUrl: string = '';
  uploadRemoveUrl: string = '';
  selectedBusinessType: BusinessType | undefined;
  selectedLegalEntity: LegalEntity | undefined;
  businessTypes: BusinessType[] = [];
  businessType!: BusinessType;
  legalEntities: LegalEntity[] = [];
  businessTypeId: number = 0;
  legalEntityId: number = 0;
  isLoading: boolean = false;
  selectedYear: number = 0;
  attachementDetails: any = [];
  worksheets: Worksheet[] = [];
  worksheet!: Worksheet;
  selectedTab: string = 'Attachments';
  DeleteTab: string = 'Attachment'

  public warnondelete: boolean = false;
  public ondeleteTran: boolean = true;
  isAdmin: boolean = false;
  public deleteType: any = 'Soft Delete';
  public FileStatus: any;
  public ErrorFileStatus: any;


  manageAttachementForm!: FormGroup;
  suppIds: any[] = [];
  AddfileIds: any[] = [];
  additionalFileDetails: any = [];
  public gridData: any = [];
  public gridAdditionalData: any = [];
  public value: string[] = [];
  worksheetParams: string[] = [];
  withCredential: boolean = false;
  Additionalfilename: string = '';
  isNextVisible: boolean = false;
  isAttachmentDelete: boolean = true;
  isAdditionalDelete: boolean = true;
  public sort: SortDescriptor[] = [
    {
      field: '_ID',
      dir: 'asc',
    },
  ];
  AdditionalFileRestrictions: FileRestrictions = {
    allowedExtensions: ['.xls', '.xlsx', '.doc', '.docx', '.pdf', '.ppt', '.pptx'],
    maxFileSize: 10485760,
  };

  public chunkSettings: ChunkSettings = {
    size: 1048576,
  };



  uploadEventHandler(e: UploadEvent) {
    let tokenValue: any;
    tokenValue = localStorage.getItem('OPENAMSESSION');
    e.headers = e.headers.append(
      'Authorization-Token',
      'Bearer ' +
      JSON.parse(tokenValue).access_token
    );
  }

  setupForm(): void {
    this.manageAttachementForm = this.fb.group({
      businessType: [''],
      legalEntity: ['']
    });
    console.log(this.manageAttachementForm);
  }

  getBusinessTypeList(): void {
    this.isLoading = true;
    this.journalService.getAllBusinessTypeList().subscribe(
      (response) => {
        this.businessTypes = response;
        if (this.businessTypes.length == 1) {
          this.selectedBusinessType = this.businessTypes[0];
          this.handleBusinessTypeChange(this.selectedBusinessType);
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  handleBusinessTypeChange(businessType: BusinessType): void {
    this.selectedBusinessType = businessType;
    this.selectedLegalEntity = undefined;

    if (businessType != undefined) {
      this.businessTypeId = businessType.BusinessTypeId;
      this.getLegalEntityList(businessType.BusinessTypeId);
    }
  }

  handleLegalEntityChange(legalEntitity: LegalEntity): void {
    if (legalEntitity != undefined) {
      this.selectedLegalEntity = legalEntitity;
      this.legalEntityId = this.selectedLegalEntity.ID;
      this.getManageAttachement();
    }
  }

  getLegalEntityList(businessTypeId: number): void {
    this.isLoading = true;
    this.legalEntities = [];
    this.journalService
      .getAllLegalEntityByTaxChartOfAccount(businessTypeId)
      .subscribe(
        (response) => {
          this.legalEntities = response;
          if (this.legalEntities.length == 1) {
            this.selectedLegalEntity = this.legalEntities[0];
            this.handleLegalEntityChange(this.selectedLegalEntity);
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  getManageAttachement() {
    this.isLoading = true;
    this.manageattachementservice
      .getManageAttachementDetails(this.businessTypeId, this.legalEntityId, this.selectedYear)
      .subscribe(
        (response) => {
          this.attachementDetails = response;
          this.gridData = response;
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  getAdditionalFileData() {
    this.isLoading = true;
    this.manageattachementservice
      .getAdditionalFileDetails()
      .subscribe(
        (response) => {
          this.additionalFileDetails = response;
          this.gridAdditionalData = response;
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  public onClose(e: TabCloseEvent): void { }

  public onSelect(e: SelectEvent): void {
    this.selectedTab = e.title;
    if (this.selectedTab == 'Attachments') {
      this.DeleteTab = 'attachment';
    }
    else if (this.selectedTab == 'Additional Files') {
      this.DeleteTab = 'additional file';
    }
  }
  public onFilter(event: { originEvent: any; queryStr: string }): void {
    if (this.selectedTab == 'Attachments') {
      this.filterAttachment(event);
    }
    else if (this.selectedTab == 'Additional Files') {
      this.filterAdditional(event);
    }
  }

  public filterAttachment(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.attachementDetails = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'FileExtension',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Statement',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'TaxAccount',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'OfflineStatement',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Attachement',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'FiscalYear',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      let response = this.getManageAttachement();
    }
  }

  public filterAdditional(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.additionalFileDetails = process(this.gridAdditionalData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'StatusDescription',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Name',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'FileExtension',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Description',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      let response = this.getAdditionalFileData();
    }

  }

  public sortChangeAttachment(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.attachementDetails = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }

  public sortChangeAdditional(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.additionalFileDetails = {
      data: orderBy(this.gridAdditionalData, this.sort),
      total: this.gridAdditionalData.length,
    };
  }

  public onGridSelectionChange(event: SelectionEvent) {
    var count = event.selectedRows != undefined ? event.selectedRows.length : 0;
    for (let i = 0; i < count; i++) {
      var item =
        event.selectedRows != undefined
          ? event.selectedRows[i].dataItem.SuppID
          : '';

      this.suppIds.push(item);
    }
    count = event.deselectedRows != undefined ? event.deselectedRows.length : 0;
    for (let i = 0; i < count; i++) {
      var item =
        event.deselectedRows != undefined
          ? event.deselectedRows[i].dataItem.SuppID
          : '';
      var index = this.suppIds.map((x) => x).indexOf(item);
      this.suppIds.splice(index, 1).slice(0);
    }
    if (this.suppIds.length > 0) {
      this.isAttachmentDelete = false;
    }
    else {
      this.isAttachmentDelete = true;
    }
  }

  public onAdditonalGridSelectionChange(event: SelectionEvent) {
    var count = event.selectedRows != undefined ? event.selectedRows.length : 0;
    for (let i = 0; i < count; i++) {
      var item =
        event.selectedRows != undefined
          ? event.selectedRows[i].dataItem.FileID
          : '';

      this.AddfileIds.push(item);
    }
    count = event.deselectedRows != undefined ? event.deselectedRows.length : 0;
    for (let i = 0; i < count; i++) {
      var item =
        event.deselectedRows != undefined
          ? event.deselectedRows[i].dataItem.FileID
          : '';
      var index = this.AddfileIds.map((x) => x).indexOf(item);
      this.AddfileIds.splice(index, 1).slice(0);
    }
    if (this.AddfileIds.length > 0) {
      this.isAdditionalDelete = false;
    }
    else {
      this.isAdditionalDelete = true;
    }
  }

  DeleteAttachement() {
    let isSoftDelete = true;
    this.isLoading = true;
    if (this.deleteType == 'Pemanent Delete') {
      isSoftDelete = false;
    }
    this.manageattachementservice
      .deleteManageAttachment(this.suppIds.toString(), isSoftDelete)
      .subscribe((response) => {
        if (response == 'Attachment deleted successfully.'
          || response == 'Attachment archived successfully.') {
          this.suppIds = [];
          this.isLoading = false;
          this.warnondelete = false;
          this.toastr.successToastr(response, 'Success');
          this.getManageAttachement();
          this.isAttachmentDelete = true;
        } else {
          this.isLoading = false;
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      });
  }

  ExportAttachementData() {
    this.isLoading = true;
    this.manageattachementservice.ExportManageAttachment(this.suppIds.toString()).subscribe(
      (response: any) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const fileName = 'ManageAttachmentExport_' + timestamp;
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.isLoading = false;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }



  DeleteAdditonalFile() {
    let isSoftDelete = true;
    this.isLoading = true;
    if (this.deleteType == 'Pemanent Delete') {
      isSoftDelete = false;
    }
    this.manageattachementservice
      .deleteAdditionalFile(isSoftDelete, this.AddfileIds.toString())
      .subscribe((response) => {
        if (response == 'File deleted successfully.'
          || response == 'File archived successfully.') {
          this.AddfileIds = [];
          this.isLoading = false;
          this.warnondelete = false;
          this.toastr.successToastr(response, 'Success');
          this.getAdditionalFileData();
          this.isAdditionalDelete = true;
        } else {
          this.isLoading = false;
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      });
  }

  ExportAdditonalFile() {
    this.isLoading = true;
    this.manageattachementservice.ExportAdditionalFile(this.AddfileIds.toString()).subscribe(
      (response: any) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const fileName = 'AdditonalFileExport_' + timestamp;
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.isLoading = false;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }




  getSupplimentaryFileName(e: any) {
    this.isLoading = true;
    this.manageattachementservice.GetManageAttachmentExcel(e)
      .subscribe(
        (response: any) => {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = this.Additionalfilename;
          a.href = window.URL.createObjectURL(blob);
          a.click();
          this.isLoading = false;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  exportSingleFile(suplimentaryID: any) {
    this.globalService
      .GetSupplimentaryFileName(suplimentaryID.SuppID)
      .then((res) => {
        if (res != null && res != undefined && res != '' && res.ExportFileName != null) {
          this.Additionalfilename = res.ExportFileName;
        }
        else {
          this.Additionalfilename = "Attachment.xlsx"
        }
        this.getSupplimentaryFileName(suplimentaryID.SuppID);
      })
      .catch((err) => { console.log(err); });

  }

  public success(e: SuccessEvent): void {
    this.FileStatus = e.response.body.FileUid;
    if (e.files.length > 0) {
      if (e.response.status == 200) {
        this.getAdditionalFileData();
      }
    }
  }

  public error(e: ErrorEvent): void {
    if (e.response.status == 400)
      this.ErrorFileStatus = 'File already exists';
    else if (e.response.status == 800)
      this.ErrorFileStatus = 'The file name should not be longer than 50 characters.';
  }



  exportAdditionalSingleFile(additionalFile: any) {
    this.Additionalfilename = additionalFile.Name;
    this.getAdditionalFileExcel(additionalFile.FileID);
  }

  getAdditionalFileExcel(e: any) {
    this.isLoading = true;
    this.manageattachementservice.GetAdditionalFileExcel(e)
      .subscribe(
        (response: any) => {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = this.Additionalfilename;
          a.href = window.URL.createObjectURL(blob);
          a.click();
          this.isLoading = false;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  getUserRole() {
    this.globalService
      .getLoggedUserInfo()
      .then((response) => {
        if (response != undefined) {
          if (response == 'Super Admin' || response == 'Admin') {
            this.isAdmin = true;
            this.warnondelete = true;
          } else {
            this.isAdmin = false;
            this.warnondelete = false;
            this.deleteData();
          }
        }
      })
      .catch((error) => { });
  }

  selectDeleteType(node: any) { }

  deleteData() {
    if (this.selectedTab == 'Attachments') {
      this.DeleteAttachement();
    }
    else if (this.selectedTab == 'Additional Files') {
      this.DeleteAdditonalFile();
    }
  }

  cancelClick() {
    this.warnondelete = false;
  }


}
