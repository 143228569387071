import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActionsLayout,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
@Component({
  selector: 'app-link-line-item-popup',
  templateUrl: './link-line-item-popup.component.html',
  styleUrls: ['./link-line-item-popup.component.scss'],
})
export class LinkLineItemPopupComponent implements OnInit {
  constructor(
    private globalService: GlobalService,
    private dialogService: DialogService,
    public dialog: DialogRef,
    private router: Router,
    public toastr: ToastrManager
  ) {}
  public statementDesc: string = '';
  public linkItemData: any = [];
  public filterlinkItemData: any = [];
  public node: any;
  public selectedCallback = (args: any) => args.dataItem;
  public mySelectionScope: string[] = [];
  public actionsLayout: ActionsLayout = 'normal';
  ngOnInit(): void {
    this.getAllunusedLineItemData(this.node.Satementid);
  }

  public onFilter(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.filterlinkItemData = process(this.linkItemData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'Description',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      this.getAllunusedLineItemData(this.node.Satementid);
    }
  }

  getAllunusedLineItemData(statementID: number) {
    this.globalService
      .getUnusedLineItem(statementID)
      .then((res: any) => {
        this.linkItemData = res.Item1;
        this.filterlinkItemData = res.Item1;
        this.statementDesc = res.Item2;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  cancelClick() {
    this.dialog.close();
  }

  linlLineitem() {
    let selectedlineItem = '';
    let linklineitemList: any[] = [];
    if (this.mySelectionScope != null && this.mySelectionScope != undefined) {
      let selectedData: any = this.mySelectionScope;
      for (let i = 0; i < selectedData.length; i++) {
        let item = {
          ID: 0,
          Statement: this.node.Satementid,
          AccountingArea: selectedData[i].ID,
          BlockId: this.node.id,
          DisplayOrder: 0,
        };
        linklineitemList.push(item);
      }
      this.globalService
        .addLinkItem(linklineitemList)
        .then((res) => {
          if (res == 'Line item(s) linked successfully') {
            this.toastr.successToastr(res, 'Success');
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
          }
          this.displaytreePack();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  displaytreePack() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }
}
