<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Manage Tax Account</div>
</kendo-dialog-titlebar>
<mat-card class="userCard" style="padding: 0px">
  <mat-card-title>
    <h6>
      Line item details - Business type:
      {{ AccountingAreaDescriptionListDB?.TaxChartDescription }}
    </h6>
  </mat-card-title>
  <mat-card-content>
    <div>
      <form class="k-form" [formGroup]="taxAccountForm">
        <ap-field [title]="'Tax Account'">
          <input appkit-field [maxlength]="50" formControlName="taxAccount" />
        </ap-field>
        <br />
        <ap-field [title]="'Short Description'" [required]="true">
          <input appkit-field [maxlength]="50" formControlName="shortDesc" />
          <div
            *ngIf="
              taxAccountForm.controls['shortDesc'].invalid &&
              (taxAccountForm.controls['shortDesc'].dirty ||
                taxAccountForm.controls['shortDesc'].touched)
            "
            id="errormessage"
            aria-live="polite"
            class="ap-field-email-validation-error"
          >
            Please enter short Description
          </div> </ap-field
        ><br /><br />
        <ap-field [title]="'Long Description'">
          <textarea
            appkit-field
            maxlength="420"
            formControlName="longDesc"
          ></textarea></ap-field
        ><br />
        <kendo-formfield>
          <kendo-label
            [for]="indicator"
            text="P&L,B/S Indicator*"
          ></kendo-label>
          <kendo-combobox
            #indicator
            required
            formControlName="indicator"
            [data]="dropdownIndicator"
            textField="label"
            valueField="value"
          >
          </kendo-combobox>
          <kendo-formerror>P&L,B/S Indicator is required</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            [for]="parentTaxAccount"
            [text]="parentTaxAccLabel"
          ></kendo-label>
          <kendo-combobox
            #parentTaxAccount
            formControlName="parentTaxAccount"
            [data]="parentTaxAccountList"
            [filterable]="true"
            [(ngModel)]="selectedParentData"
            textField="shortdescription"
            valueField="TaxAccountCodeId"
            placeholder="Select Parent tax Account"
            (valueChange)="handleParentTaxAccountChange($event)"
            (filterChange)="handleFilterParentTaxaccount($event)"
          >
          </kendo-combobox>
          <kendo-formerror>Parent tax account is required</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="AccountClass" text="Account class*"></kendo-label>
          <kendo-combobox
            #AccountClass
            required
            formControlName="accountClass"
            [data]="AccountClassDescriptionList"
            textField="Description"
            valueField="ID"
            (valueChange)="handleAccountClassChange($event)"
          >
          </kendo-combobox>
          <kendo-formerror>Account class is required</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="lineitem" text="Line item*"></kendo-label>
          <kendo-combobox
            #lineitem
            required
            formControlName="lineItem"
            [data]="lineItemList"
            textField="Description"
            valueField="ID"
            (valueChange)="handleLineItemChange($event)"
            [filterable]="true"
            (filterChange)="handleFilterLineItem($event)"
          >
          </kendo-combobox>
          <kendo-formerror>Line Item is required</kendo-formerror>
          <span class="showAllCheck">
            <ap-checkbox
              formControlName="showAll"
              (onChange)="onShowAllChange($event)"
            >
              <span>Show All</span>
            </ap-checkbox>
          </span>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            [for]="statutoryTreatment"
            text="Statutory treatment"
          ></kendo-label>
          <kendo-combobox
            #statutoryTreatment
            formControlName="statutoryTreatment"
            [data]="STList"
            textField="Description"
            valueField="Id"
            (valueChange)="handleSTChange($event)"
          >
          </kendo-combobox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="taxOutcome" text="Tax outcome"></kendo-label>
          <kendo-combobox
            #taxOutcome
            formControlName="taxOutcome"
            [data]="TaxOutcomeList"
            textField="ShortDescription"
            valueField="_id"
            (valueChange)="handleTOChange($event)"
          >
          </kendo-combobox>
        </kendo-formfield>
        <kendo-formfield>
          <ap-checkbox
            formControlName="highlighted"
            (onChange)="onHighlightClick($event)"
          >
            <span>Highlighted</span>
          </ap-checkbox>
        </kendo-formfield>
        <kendo-formfield>
          <ap-checkbox
            formControlName="taxSpecific"
            (onChange)="onTaxSpecificClick($event)"
          >
            <span>Tax Specific</span>
          </ap-checkbox>
        </kendo-formfield>
      </form>
    </div>
  </mat-card-content>
</mat-card>

<!-- 
<kendo-dialog-actions [layout]="actionsLayout" class="fontcolorlabel" style="margin-left: 77%">
  <button kendoButton class='btn-pwc-secondary' (click)="cancelClick()">Cancel</button>
  <button kendoButton 
  class='actionButton btn-pwc-primary'
  [disabled]="taxAccountForm.invalid"
    themeColor="primary"
    
    (click)="saveNewTaxAccount()"
  >
      Save
  </button>
</kendo-dialog-actions> -->
<kendo-dialog-actions>
  <div class="clickToActions" style="padding: 12px">
    <ap-button
      [btnType]="'secondary'"
      [label]="'Cancel'"
      class="cancelButton"
      (onClick)="cancelClick()"
    ></ap-button>
    <ap-button
      [btnType]="'primary'"
      [disabled]="taxAccountForm.invalid"
      [label]="'Save'"
      (onClick)="saveNewTaxAccount()"
      class="okButton"
      style="margin-right: 6px"
    ></ap-button>
  </div>
</kendo-dialog-actions>
