import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ShowDetailsService } from 'src/app/common/services/showDetails/show-details.service';
import { LegalEntity } from 'src/app/_models/legalEntity';
import { ShowGlAccounts } from 'src/app/_models/showGlaccounts';
import { TransactionsGroupFolder } from 'src/app/_models/transactionsGroupFolder';

@Component({
  selector: 'app-show-gl-accounts',
  templateUrl: './show-gl-accounts.component.html',
  styleUrls: ['./show-gl-accounts.component.scss'],
})
export class ShowGlAccountsComponent implements OnInit {
  @Input() selectedRow: any;
  public value: ShowGlAccounts[] = [];
  public statementId: any;
  public gridData: ShowGlAccounts[] = [];
  glAccountGroupFolders: ShowGlAccounts[] = [];
  glAccountGroupFolder!: ShowGlAccounts;
  glParams: ShowGlAccounts[] = [];
  isLoading: boolean = false;
  public rowData: any;
  public levelType: string = '';
  legalEntityId: any;
  public lineItemID: any;
  public blockID: any;
  public selectedFiscalYear: any;
  public aggregateOptionsAmount = [
    { id: 'Average', label: 'Average', checked: false },
    { id: 'Count', label: 'Count', checked: false },
    { id: 'Maximum', label: 'Maximum', checked: false },
    { id: 'Minimum', label: 'Minimum', checked: false },
    { id: 'Sum', label: 'Sum', checked: false },
  ];
  avgAmount: number = 0;
  sumAmt: number = 0;
  public cntAmount: number = 0;
  public maxAmount: number = 0;
  public minAmount: number = 0;
  selectedAggregations: any = [];
  aggregationValsArr: any = [];
  constructor(
    private showDetailsService: ShowDetailsService,
    private router: Router,
    public toastr: ToastrManager
  ) {}

  ngOnInit(): void {
    this.rowData = this.selectedRow;
    this.levelType = this.selectedRow.type;
    this.selectedFiscalYear = localStorage.getItem('selectedFiscalYear');
    if (this.selectedRow.type == 'AS' || this.selectedRow.type == 'SS') {
      this.statementId = this.selectedRow.id;
      // this.legalEntityId = this.selectedRow.legalEntityID;

      this.legalEntityId = this.selectedRow.LegalEntityID;
      this.getGLAccountDetailsforStatement();
    } else if (
      this.selectedRow.type == 'LI' ||
      this.selectedRow.type == 'CLI'
    ) {
      this.lineItemID = this.selectedRow.id;
      this.legalEntityId = this.selectedRow.LegalEntityID;
      this.getGLAccountDetailsforLineItem();
    } else if (this.selectedRow.type == 'SA') {
      this.blockID = this.selectedRow.id;
      this.legalEntityId = this.selectedRow.LegalEntityID;
      this.statementId = this.selectedRow.Satementid;
      this.getGLAccountDetailsforSubAnalysis();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRow.previousValue != undefined) {
      this.rowData = this.selectedRow;
      this.levelType = this.selectedRow.type;
      if (this.selectedRow.type == 'AS' || this.selectedRow.type == 'SS') {
        this.statementId = this.selectedRow.id;
        this.legalEntityId = this.selectedRow.LegalEntityID;
        this.getGLAccountDetailsforStatement();
      } else if (
        this.selectedRow.type == 'LI' ||
        this.selectedRow.type == 'CLI'
      ) {
        this.lineItemID = this.selectedRow.id;
        this.legalEntityId = this.selectedRow.LegalEntityID;
        this.getGLAccountDetailsforLineItem();
      } else if (this.selectedRow.type == 'SA') {
        this.blockID = this.selectedRow.id;
        this.legalEntityId = this.selectedRow.LegalEntityID;
        this.statementId = this.selectedRow.Satementid;
        this.getGLAccountDetailsforSubAnalysis();
      }
    }
  }

  // showGlAccounts(): void {
  //   this.isLoading= true;
  //   this.showDetailsService.getGlAccountDetails(this.statementId).subscribe(
  //     (response) => {
  //       console.log(response);
  //       this.gridData = response;
  //       this.glAccountGroupFolders = response;
  //       this.isLoading = false;
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }

  getGLAccountDetailsforStatement(): void {
    this.isLoading = true;
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.showDetailsService
      .getGLAccountDetailsforStatement(
        year == 'null' || year == null ? 0 : JSON.parse(year).FiscalYearId,
        this.statementId,
        this.legalEntityId
      )
      .subscribe(
        (response) => {
          this.gridData = response;
          this.getCalculations();
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
        }
      );
  }
  getGLAccountDetailsforLineItem(): void {
    this.isLoading = true;
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.showDetailsService
      .getGLAccountDetailsforLineItem(
        year == 'null' || year == null ? 0 : JSON.parse(year).FiscalYearId,
        this.lineItemID,
        this.legalEntityId
      )
      .subscribe(
        (response) => {
          this.gridData = response;
          this.getCalculations();
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
        }
      );
  }
  getGLAccountDetailsforSubAnalysis(): void {
    this.isLoading = true;
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.showDetailsService
      .getGLAccountDetailsforSubAnalysis(
        year == 'null' || year == null ? 0 : JSON.parse(year).FiscalYearId,
        this.blockID,
        this.statementId,
        this.legalEntityId
      )
      .subscribe(
        (response) => {
          this.gridData = response;
          this.getCalculations();
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  public AddColorFormat(textData: any, amount: any) {
    if (amount.indexOf(')') > 0 || amount.indexOf('-') != -1) {
      return 'color:red;';
      ('font-style:');
      textData.GLAccountDescription === 'Adjustments' ||
      textData.GLAccountDescription === 'Journals in' ||
      textData.GLAccountDescription === 'Journals out'
        ? 'italic'
        : 'none';
    } else {
      return 'color:black;';
      ('font-style:');
      textData.GLAccountDescription === 'Adjustments' ||
      textData.GLAccountDescription === 'Journals in' ||
      textData.GLAccountDescription === 'Journals out'
        ? 'italic'
        : 'none';
    }
  }
  roundedPercentage(myValue: number) {
    var result = myValue;

    return result.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  public aggregateMethod(event: any, type: string, column: string): void {
    let aggregateOptions: any = [];

    this.aggregateOptionsAmount.map((obj: any) => {
      if (obj.id == type) {
        obj.checked = event.target.checked;
      }
    });
    var selectedValue = this.aggregateOptionsAmount.filter(function (el: any) {
      return el.checked == true;
    });
    this.selectedAggregations = selectedValue;
  }
  getCalculations() {
    this.avgAmount =
      this.gridData
        .filter((item) => !isNaN(item.Amount))
        .reduce((sum, current) => sum + current.Amount, 0) /
        this.gridData.length || 0;
    this.sumAmt = this.gridData
      .filter((item) => !isNaN(item.Amount))
      .reduce((sum, current) => sum + current.Amount, 0);
    this.cntAmount =
      this.gridData.length > 1
        ? this.gridData.length - 1
        : this.gridData.length;
    this.maxAmount = Math.max(
      ...this.gridData
        .filter((item) => !isNaN(item.Amount))
        .map((item) => item.Amount)
    );
    this.minAmount = Math.min(
      ...this.gridData
        .filter((item) => !isNaN(item.Amount))
        .map((item) => item.Amount)
    );
  }
  openAggregate(column: string) {
    let avgVal, countVal, maxVal, minVal, sumVal;
    this.aggregationValsArr = [];
    for (let i = 0; i < this.selectedAggregations.length; i++) {
      if (this.selectedAggregations[i].label == 'Average') {
        avgVal = this.avgAmount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        this.aggregationValsArr.push('Average = ' + avgVal);
      } else if (this.selectedAggregations[i].label == 'Maximum') {
        maxVal = this.maxAmount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        this.aggregationValsArr.push('Maximum = ' + maxVal);
      } else if (this.selectedAggregations[i].label == 'Sum') {
        sumVal = this.sumAmt.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        this.aggregationValsArr.push('Sum = ' + sumVal);
      } else if (this.selectedAggregations[i].label == 'Minimum') {
        minVal = this.minAmount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        this.aggregationValsArr.push('Minimum = ' + minVal);
      } else if (this.selectedAggregations[i].label == 'Count') {
        countVal = this.cntAmount;
        this.aggregationValsArr.push('Count = ' + countVal);
      }
    }
  }
  amountAggregate() {
    return this.aggregationValsArr;
  }
}
