import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MenubarService } from '../common/menubarService/menubar.service';
import { Router } from '@angular/router';
import { DashboardserviceService } from '../common/services/dashboard/dashboardservice.service';
import { GlobalService } from '../common/globalservice/globalservice.service';
import { AdministrationService } from '../common/services/administration/administration.service';
import { AnyARecord, AnySoaRecord } from 'dns';
import { CookieService } from 'ngx-cookie-service';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
  aggregateBy,
} from '@progress/kendo-data-query';
import { Console } from 'console';
import { ValueAxisComponent } from '@progress/kendo-angular-charts';
import { SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';
import { IntlService } from '@progress/kendo-angular-intl';
import {
  DialogRef,
  DialogCloseResult,
  DialogService,
  WindowCloseResult,
  WindowRef,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { AccountSummeryComponent } from './account-summery/account-summery.component';
// import { CollectOfflineReferralComponent } from '../offlineReferral/collect-offline-referral/collect-offline-referral.component';
import { OffineReferallsComponent } from './offine-referalls/offine-referalls.component';
import { LockstatusComponent } from '../lockstatus/lockstatus.component';
import { PiechartPopupComponent } from './piechart-popup/piechart-popup.component';
interface Model {
  AmountTotal: string;
  GLAccount: number;
}

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  public aggregates: any[] = [{ field: 'TotalAmount', aggregate: 'sum' }];
  selectedFiscalYear: number = 0;
  public dashBoardData: any;
  public accountSummaryData: any;
  public dashBoardChartData!: Model[];
  public preferenceSetting: any = [];
  public applicationSettingData!: any[];
  public gridData: any = [];
  dashBoardSummary: any;
  dashboardOfflineRefferal: any;
  offilneReferalData: any;
  total: any;
  TotalAmount: any;
  selectedClient: string = '';
  glAccountLength: any;
  showDataResponse: any;
  showGLAccountPieChart: boolean = true;
  showGLAccountGrid: boolean = false;
  pieChartData: any;
  showBarChatValue: boolean = false;
  public staticData: any;
  pieChartText: any;
  barChartDataValue: any;
  public colorString = [
    '#D04A02',
    '#EB8C00',
    '#FFB600',
    '#7D7D7D',
    '#DEDEDE',
    '#295477',
    '#299D8F',
    '#4577C9',
  ];
  public colorCodeAccount = [
    '#D04A02',
    '#EB8C00',
    '#FFB600',
    '#7D7D7D',
    '#DEDEDE',
    '#295477',
    '#299D8F',
    '#4577C9',
  ];
  constructor(
    public menubarService: MenubarService,
    private router: Router,
    private dashboardserviceService: DashboardserviceService,
    private globalService: GlobalService,
    private adminService: AdministrationService,
    private cookieService: CookieService,
    private intl: IntlService,
    private dialogService: DialogService
  ) {}
  totalDashBoardData: any;
  public state: State = {};
  public currentYear: any = new Date().getFullYear();
  ngOnInit(): void {
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.loadData(year);
    this.menubarService.show();
    this.getDataPieChart();
  }

  loadData(year: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    var client = localStorage.getItem('SELECTEDCLIENT');
    if (
      (year == null || year == undefined) &&
      client != null &&
      client != undefined &&
      client != ''
    ) {
      this.selectedClient = JSON.parse(selectedClientVal);
      this.getLatestFiscalYear();
    } else if (client != '') {
      this.selectedClient = JSON.parse(selectedClientVal);
      this.selectedFiscalYear = JSON.parse(year).FiscalYearId;
      this.getDashBoardGLAccount(this.selectedFiscalYear);
      this.getDashBoardChartData(this.selectedFiscalYear);
      this.getDashBoardAccountSummary(this.selectedFiscalYear);
      this.getDashBoardManageOffline(this.selectedFiscalYear);
      this.getPreferencesSettings();
      // this.barChartData(this.selectedFiscalYear)
    }
  }
  totalDataValue: any;
  dashBoardGridData: any;
  getDashBoardGLAccount(fiscalYear: number) {
    this.dashboardserviceService
      .fetchDashBoardGLAccount(fiscalYear)
      .then((res: any) => {
        this.accountSummaryData = res;
        this.dashBoardGridData = res;
        // this.dashBoardData = res.sort((a: any, b: any) => parseFloat(b.TotalAmount) - parseFloat(a.TotalAmount)).slice(0, 5);;
        this.dashBoardData = res.slice(0, 8).map((obj: any, i: any) => {
          obj['colorcode'] = this.colorString[i];
          return obj;
        });
        this.totalDataValue = this.dashBoardData.map((item: any) => {
          return item.TotalAmount;
        });
        this.gridData = res;
      })
      .catch((err: any) => {});
  }
  getDashBoardChartData(fiscalYear: number) {
    var result: any[];
    this.dashboardserviceService
      .fetchDashBoardChartData(fiscalYear)
      .then((res: any) => {
        result = res;
        if (res.length == 0) {
          this.glAccountLength = 'Top GL accounts with transactions';
        }
        if (res != null && res != undefined && res.length > 0) {
          if (res.length == 8) {
            this.glAccountLength = 'Top 8 GL accounts with transactions';
          } else {
            this.glAccountLength = 'Top GL accounts with transactions';
          }
          this.dashBoardChartData = res.map((obj: any, i: any) => {
            obj['colorcodeaccount'] = this.colorCodeAccount[i];
            return obj;
          });
        }
      })

      .catch((err: any) => {});
  }
  public labelContent = (e: any) => {
    return e.value.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  getLatestFiscalYear() {
    this.globalService
      .getLatestFiscalYear()
      .then((res) => {
        localStorage.setItem('selectedFiscalYear', JSON.stringify(res));
        this.selectedFiscalYear = res.FiscalYearId;
        this.getDashBoardGLAccount(this.selectedFiscalYear);
        this.getDashBoardChartData(this.selectedFiscalYear);
        this.getDashBoardAccountSummary(this.selectedFiscalYear);
        this.getDashBoardManageOffline(this.selectedFiscalYear);
        this.getPreferencesSettings();
      })
      .catch((err) => {});
  }

  getPreferencesSettings(): void {
    this.adminService.getPreferencesSettings().subscribe(
      (response) => {
        if (response != undefined && response != null) {
          this.preferenceSetting = response;
          this.applicationSettingData = [
            { label: 'Currency', value: this.preferenceSetting.DisplayFormat },
            {
              label: 'Currency Symbol',
              value: this.preferenceSetting.CurrencySymbol,
            },
            {
              label: 'Debit (DR+) / Credit (CR+)',
              value: this.preferenceSetting.CreditPositive,
            },
            {
              label: 'Display Matched Transactions',
              value: this.checkboolValue(
                this.preferenceSetting.DisplayMatchedTransactions
              ),
            },
            {
              label: 'Display Moved Transactions',
              value: this.checkboolValue(
                this.preferenceSetting.DisplayMovedTransactions
              ),
            },
          ];
        }
      },
      (error) => {}
    );
  }

  public checkboolValue(value: boolean) {
    if (value === true) {
      return 'Yes';
    } else {
      return 'No';
    }
  }
  //sorting feature
  public sort: SortDescriptor[] = [
    {
      field: 'AccountNumber',
      dir: 'asc',
    },
  ];
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.dashBoardGridData = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }
  //color format for negative values
  public ColorFormat(amount: any) {
    return amount >= 0 ? 'color: black' : 'color: red';
  }
  //color format for negative values
  public AddColorFormat(amount: any) {
    let preferenceValue = ['1,000.00', '-1,000.00', '(1,000.00)'];
    return preferenceValue.includes(amount) ? 'color: red' : 'color: black';
  }
  accountSummery() {
    const dialogRef = this.dialogService.open({
      content: AccountSummeryComponent,
      width: 800,
      height: 'auto',
    });
    const accountSummaryData = dialogRef.content
      .instance as AccountSummeryComponent;
    accountSummaryData.GLaccountSummary = this.accountSummaryData;
  }
  getDashBoardAccountSummary(fiscalYear: number) {
    this.dashboardserviceService
      .getDashBoardSummary(fiscalYear)
      .then((response: any) => {
        this.dashBoardSummary = response;
      })
      .catch((err: any) => {});
  }
  offlineReferrals(dataChange: any) {
    const dialogRef = this.dialogService.open({
      content: OffineReferallsComponent,
      width: 1200,
      height: 'auto',
    });
    let submissionIdList: any = [];
    if (dataChange != 'Total') {
      submissionIdList = this.offilneReferalData.filter((obj: any) => {
        return obj.Status == dataChange;
      });
    }
    if (dataChange == 'Total') {
      this.dashboardOfflineRefferal = this.offilneReferalData;
    } else {
      this.dashboardOfflineRefferal = submissionIdList;
    }
    const dashboardOfflineRefferal = dialogRef.content
      .instance as OffineReferallsComponent;
    dashboardOfflineRefferal.offlineAccountSummary =
      this.dashboardOfflineRefferal;
  }

  getDashBoardManageOffline(fiscalYear: number) {
    this.dashboardserviceService
      .getManageOfflineReferralsByFiscalYear(fiscalYear)
      .then((response: any) => {
        this.offilneReferalData = response;
      })
      .catch((err: any) => {});
  }
  pieChartAccountSummery() {
    const dialogRef = this.dialogService.open({
      content: PiechartPopupComponent,
      width: 800,
      height: 'auto',
    });
    const accountSummaryData = dialogRef.content
      .instance as PiechartPopupComponent;
    accountSummaryData.changeValue = this.accountSummaryData;
  }
  getDataPieChart() {
    this.adminService.getPreferencesSettings().subscribe((response: any) => {
      this.showDataResponse = response;
      this.staticData =
        'Total Amount' + ' ' + '(' + response.CurrencySymbol + ')';
      if (this.showDataResponse.IsDisplayPieChart == false) {
        this.showGLAccountPieChart = false;
        this.showGLAccountGrid = true;
        this.pieChartText = 'GL account summary';
      } else {
        this.showGLAccountPieChart = true;
        this.showGLAccountGrid = false;
        this.pieChartText = 'Top GL account summary';
      }
    });
  }
  dblclicksff() {
    this.showBarChatValue = false;
    if (this.showDataResponse.IsDisplayPieChart == false) {
      this.showGLAccountPieChart = false;
      this.showGLAccountGrid = true;
      this.pieChartText = 'Pie Chart';
    } else {
      this.showGLAccountPieChart = true;
      this.showGLAccountGrid = false;
      this.pieChartText = 'tab';
    }
  }
  barChartData(e: any) {
    this.showBarChatValue = true;
    this.showGLAccountPieChart = false;
    this.showGLAccountGrid = false;
    this.dashboardserviceService
      .getTransactrionByGLAccount(this.selectedFiscalYear, e.category)
      .then((response: any) => {
        this.barChartDataValue = response;
      });
  }
}
