import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MenubarService {
  visible: boolean;
  entityVisible: boolean;
  constructor() {
    this.visible = true;
    this.entityVisible = true;
  }

  hide() {
    this.visible = false;
  }

  show() {
    this.visible = true;
  }
  entityhide() {
    this.entityVisible = false;
  }

  entityshow() {
    this.entityVisible = true;
  }
}
