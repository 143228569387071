<!-- <kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">
    Manage analysis statement layouts
  </div>
</kendo-dialog-titlebar>
<div><h3>Layout Properties</h3></div>
<mat-card class="userCard">
  <mat-card-title> </mat-card-title>
  <mat-card-content>
    <div>
      <form class="k-form" [formGroup]="addASLayoutForm">
        <kendo-formfield>
          <kendo-label [for]="description" text="Description*"></kendo-label>
          <input
            formControlName="description"
            kendoTextBox
            #description
            required
          />
          <kendo-formerror>{{ descriptionErrorMessage }}</kendo-formerror>
        </kendo-formfield>
        <br />
        <label for="display">Display*:</label>

        <input
          type="radio"
          name="display"
          value="CR+"
          #CR
          formControlName="display"
          kendoRadioButton
        />
        <label class="k-label" [for]="CR">CR+</label>
        &nbsp;&nbsp;

        <input
          type="radio"
          name="display"
          value="DR+"
          #DR
          formControlName="display"
          kendoRadioButton
        />
        <label class="k-label" [for]="DR">DR+</label>
        <kendo-formfield>
          <kendo-label [for]="footer" text="Footer"></kendo-label>
          <input formControlName="footer" kendoTextBox #footer />
          <kendo-formerror
            >Footer is required</kendo-formerror
          > </kendo-formfield
        ><br />
        <label class="k-label">
          <input
            type="checkbox"
            kendoCheckBox
            formControlName="displaytotalinfooter"
          />
          Display total in Footer
        </label>
      </form>
    </div>
  </mat-card-content>
</mat-card>

<kendo-dialog-actions [layout]="actionsLayout">
  <button kendoButton class="btn-pwc-secondary" (click)="cancelClick()">
    Cancel
  </button>
  <button
    kendoButton
    class="actionButton btn-pwc-primary"
    [disabled]="!addASLayoutForm.valid"
    (click)="saveASLayout()"
  >
    Save
  </button>
</kendo-dialog-actions> -->

<!-- <kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Manage analysis statement layouts</div>
</kendo-dialog-titlebar>

<div class="col-md-12 col-xs-12" style="padding: 0px !important">

<div class="subHeader">Layout Properties</div>
</div>

<mat-card class="userCard" style="padding: 0px">
  <mat-card-content>


    <div>
      <form class="k-form" [formGroup]="addASLayoutForm">
        <kendo-formfield>
            <kendo-label [for]="description" text="Description*"></kendo-label>
            <input
              formControlName="description"
              kendoTextBox
              #description
              required
            />
            <kendo-formerror>{{ descriptionErrorMessage }}</kendo-formerror>
          </kendo-formfield>
          <label for="display">Display*:</label>

          <input
            type="radio"
            name="display"
            value="CR+"
            #CR
            formControlName="display"
            kendoRadioButton
          />
          <label class="k-label" [for]="CR">CR+</label>
          &nbsp;&nbsp;
  
          <input
            type="radio"
            name="display"
            value="DR+"
            #DR
            formControlName="display"
            kendoRadioButton
          />
          <label class="k-label" [for]="DR">DR+</label>
        <kendo-formfield>
          <kendo-label [for]="footer" text="Footer"></kendo-label>
          <input formControlName="footer" kendoTextBox #footer />
          <kendo-formerror
            >Footer is required</kendo-formerror
          > </kendo-formfield
        ><br />

        <div style="display: flex">
          <input
            type="checkbox"
            kendoCheckBox
            formControlName="displaytotalinfooter"
            style="margin-right: 0px;"
          />
          <label class="k-label">

          Display total in Footer
        </label>
      </div>

        <div style="position: absolute; right: 0%; display: flex">
          <ap-button
          [btnType]="'primary'"
          class="okButton"
          [label]="'Save'"
          [disabled]="!addASLayoutForm.valid"
          style="margin-right: 6px"
          themeColor="primary"
          (click)="saveASLayout()"
        ></ap-button>
          <ap-button
          [btnType]="'secondary'"
          class="cancelButton"
          [label]="'Cancel'"
          (click)="cancelClick()"
        ></ap-button>
       
        </div>
       
        
    
      </form>
    </div>
  </mat-card-content>
</mat-card> -->

<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">
    Manage analysis statement layouts
  </div>
</kendo-dialog-titlebar>
<!-- <div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div> -->
<mat-card class="userCard" style="padding: 0px">
  <mat-card-title><b> Layout properties </b></mat-card-title>
  <mat-card-content>
    <div>
      <form class="k-form" [formGroup]="addASLayoutForm">
        <kendo-formfield>
          <kendo-label [for]="description" text="Description*"></kendo-label>
          <input
            formControlName="description"
            kendoTextBox
            #description
            [maxlength]="50"
            required
          />
          <kendo-formerror>{{ descriptionErrorMessage }}</kendo-formerror>
        </kendo-formfield>

        <!-- <label for="display" style="margin-top: 10px;">Display*:</label>

        <input
          type="radio"
          name="display"
          value="CR+"
          #CR
          formControlName="display"
          kendoRadioButton
        />
        <label class="k-label" [for]="CR">CR+</label>
        &nbsp;&nbsp;

        <input
          type="radio"
          name="display"
          value="DR+"
          #DR
          formControlName="display"
          kendoRadioButton
        />
        <label class="k-label" [for]="DR">DR+</label> -->

        <kendo-formfield>
          <kendo-label [for]="footer" text="Footer"></kendo-label>
          <input
            formControlName="footer"
            [maxlength]="50"
            kendoTextBox
            #footer
          />
          <kendo-formerror>Footer is required</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield>
          <kendo-label [for]="HideSubAnalysisInTree" text=""
            ><span
              ><input
                type="checkbox"
                formControlName="displaytotalinfooter"
                #HideSubAnalysisInTree
                kendoCheckBox
              />Display total in footer</span
            ></kendo-label
          >
        </kendo-formfield>

        <!-- <div style="display: flex;margin-top: 10px;">
          <input
            type="checkbox"
            kendoCheckBox
            formControlName="displaytotalinfooter"
            style="margin-right: 0px;"
          />
          <label class="k-label">

          Display total in Footer
        </label>
      </div> -->
      </form>
    </div>
  </mat-card-content>
</mat-card>
<kendo-dialog-actions style="padding-bottom: 20px">
  <div class="clickToActions">
    <ap-button
      [btnType]="'secondary'"
      [label]="'Cancel'"
      class="cancelButton"
      (click)="cancelClick()"
      style="margin-right: 6px"
    ></ap-button>

    <ap-button
      [btnType]="'primary'"
      [label]="'Save'"
      class="okButton"
      style="margin-right: 6px"
      [disabled]="!addASLayoutForm.valid"
      themeColor="primary"
      (click)="saveASLayout()"
    ></ap-button>
  </div>
</kendo-dialog-actions>
