<!-- Header -->
<div class="header">Account mapping</div>

<!-- <div class="container-fluid header">
  <div class="row">
    <div class="col-md-10 col-xs-12">
    </div>
    <div class="col-md-2 col-xs-12">
      <ap-button
        [btnType]="'secondary'"
        (onClick)="exportData()"
        style="float: right; background-color: white; border-radius: 5px"
        >Export</ap-button
      >
    </div>
  </div>
</div> -->
<!-- loading data -->
<div class="container">
  <div *ngIf="isLoading">
    <kendo-chunkprogressbar
      [chunkCount]="10"
      [min]="-10"
      [max]="10"
      [indeterminate]="true"
      [value]="30"
      style="width: 100%"
    >
    </kendo-chunkprogressbar>
  </div>
</div>
<!-- Map dialog boxes -->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 col-xs-12">
      <section class="sectionCss">
        <div>
          <small class="smallCs">
            <span *ngIf="filter">Mapped -</span
            ><span *ngIf="filter == null">All -</span>
            <span *ngIf="filter != null && !filter">Unmapped -</span> {{ sort }}
          </small>
          &nbsp;
          <!-- <ap-search
            class="marginL"
            #customInput
            [(ngModel)]="filter1"
            (click)="handleFilter($event)"
            [clearButton]="true"
            placeholder="GL account search"
          >
            <ng-template kendoTextBoxSuffixTemplate>
              <button
                style="float: right; cursor: pointer"
                (click)="handleFilter($event)"
                class="k-input-icon k-icon k-i-search"
              ></button>
            </ng-template>
          </ap-search> -->
          <kendo-textbox
            class="marginL"
            style="width: 100%"
            #customInput
            [(ngModel)]="filter1"
            (click)="handleFilter($event)"
            [clearButton]="true"
            placeholder="GL account search"
          >
            <ng-template kendoTextBoxSuffixTemplate>
              <button
                style="float: right; cursor: pointer"
                (click)="handleFilter($event)"
                class="k-input-icon k-icon k-i-search"
              ></button>
            </ng-template>
          </kendo-textbox>
        </div>
        <div>
          <div class="dropList">
            <kendo-dropdownbutton
              [data]="sortList"
              (itemClick)="onItemClick($event)"
              style="margin-right: 5px"
              kendoTooltip
              title="Sort"
              [compact]="true"
            >
              <span class="Appkit4-icon icon-filter-outline"></span>
            </kendo-dropdownbutton>
            <kendo-dropdownbutton
              [data]="view"
              (itemClick)="onItemClickFilter($event)"
              kendoTooltip
              title="Show"
            >
              <span class="Appkit4-icon icon-view-outline"></span>
            </kendo-dropdownbutton>
          </div>
        </div>
        <br />
        <kendo-treeview
          class="treeView1"
          #listA
          [filter]="filterTerm"
          [dropZoneTreeViews]="[listB]"
          kendoTreeViewHierarchyBinding
          childrenField="items"
          kendoTreeViewDragAndDrop
          [allowCopy]="allowCopy"
          kendoTreeViewDragAndDropEditing
          emptyItemPlaceholder
          kendoTreeViewExpandable
          expandBy="id"
          textField="text"
          [nodes]="parsedData"
          [(expandedKeys)]="expand[0]"
          (nodeDrop)="handleDrop($event)"
          kendoTreeViewDisable="text"
          [disabledKeys]="disabledKeys"
        >
          <hr />
          <ng-template kendoTreeViewNodeTemplate let-dataItem let-index="index">
            <input
              kendoCheckBox
              *ngIf="show(index) && dataItem.items == undefined"
              type="checkbox"
              (change)="onChange($event, index)"
              [checked]="isChecked(index)"
            />
            {{ dataItem.text }}
          </ng-template>
        </kendo-treeview>
      </section>
    </div>
    <div class="col-md-6 col-xs-12">
      <section class="sectionCss">
        <kendo-dropdownlist
          style="width: 100%; height: 27px"
          [(ngModel)]="azData"
          [data]="azDrp"
          textField="text"
          (valueChange)="onItemClickDrp($event)"
        >
        </kendo-dropdownlist>
        <div>
          <div style="min-height: 10px"></div>
          <!-- <ap-search
            class="marginL"
            style="width: 100%"
            #customInput
            [(ngModel)]="filter2"
            [clearButton]="true"
            placeholder="Tax account search"
          >
            <ng-template kendoTextBoxSuffixTemplate>
              <button
                style="float: right; cursor: pointer"
                (click)="handleFilter1($event)"
                class="k-input-icon k-icon k-i-search"
              ></button>
            </ng-template>
          </ap-search> -->
          <kendo-textbox
            class="marginL"
            style="width: 100%"
            #customInput
            [(ngModel)]="filter2"
            [clearButton]="true"
            (valueChange)="clearData($event)"
            placeholder="Tax account search"
          >
            <ng-template kendoTextBoxSuffixTemplate>
              <button
                style="float: right; cursor: pointer"
                (click)="handleFilter1($event)"
                class="k-input-icon k-icon k-i-search"
              ></button>
            </ng-template>
          </kendo-textbox>
          <div class="row" style="margin-top: 10px">
            <div class="col-md-8 col-xs-8">
              <ap-checkbox
                [(ngModel)]="checked"
                [disabled]="checkboxDisabled"
                [indeterminate]="tempState"
                (onChange)="!checkboxDisabled && complex()"
              >
                <span>Complex accounts only</span>
              </ap-checkbox>
            </div>
            <div class="col-md-4 col-xs-4">
              <button
                (click)="open()"
                style="border-radius: 5px; padding: 4px 8px; float: right"
                kendoTooltip
                class="k-button k-button-solid-base k-button-solid"
                title="Delete"
              >
                <span class="Appkit4-icon icon-delete-outline"></span>
              </button>
              <button
                (click)="expandAll()"
                style="
                  border-radius: 5px;
                  padding: 4px 8px;
                  float: right;
                  margin-right: 5px;
                "
                kendoTooltip
                class="k-button k-button-solid-base k-button-solid"
                title="Expand all"
              >
                <span class="Appkit4-icon icon-circle-plus-outline"></span>
              </button>
              <button
                (click)="collapseAll()"
                style="
                  border-radius: 5px;
                  padding: 4px 8px;
                  float: right;
                  margin-right: 5px;
                "
                kendoTooltip
                class="k-button k-button-solid-base k-button-solid"
                title="Collapse all"
              >
                <span class="Appkit4-icon icon-circle-minus-outline"></span>
              </button>
            </div>
          </div>
        </div>
        <kendo-treeview
          class="treeView2"
          #listB
          kendoTreeViewHierarchyBinding
          childrenField="items"
          kendoTreeViewDragAndDrop
          [allowCopy]="allowCopy"
          kendoTreeViewDragAndDropEditing
          emptyItemPlaceholder
          kendoTreeViewExpandable
          expandBy="id"
          (expand)="onExpand($event)"
          (collapse)="onCollapse($event)"
          textField="text"
          [nodes]="parsedData1"
          [(expandedKeys)]="singleExpand[0]"
          (nodeDrop)="handleDrop($event)"
          (selectionChange)="handleSelection($event)"
        >
          <ng-template kendoTreeViewNodeTemplate let-dataItem let-index="index">
            <input
              kendoCheckBox
              *ngIf="dataItem.IsMapped != undefined"
              type="checkbox"
              (change)="onChange1($event, index)"
              [checked]="isChecked1(index)"
            />
            {{ dataItem.text }}
          </ng-template>
        </kendo-treeview>
      </section>
    </div>
  </div>
</div>

<kendo-dialog
  title="Please confirm"
  *ngIf="opened1"
  (close)="close('cancel')"
  [minWidth]="250"
  [width]="450"
>
  <div style="display: flex">
    <p>
      <span
        class="Appkit4-icon icon-circle-warning-outline"
        style="font-size: 24px; color: #d04a02"
      ></span>
    </p>
    <p style="margin-left: 8px; font-size: 14px">
      Are you sure you want to delete?
    </p>
  </div>
  <div class="clickToActions" style="padding: 10px 0px 0px 0px">
    <ap-button
      [btnType]="'secondary'"
      [label]="'No'"
      (click)="close('no')"
      style="width: 100px"
    ></ap-button>
    <ap-button
      [btnType]="'primary'"
      [label]="'Yes'"
      themeColor="primary"
      (click)="close('yes')"
      style="margin-right: 6px; width: 100px"
    ></ap-button>
  </div>
</kendo-dialog>

<div class="clickToActions" style="padding: 20px 0px">
  
  <ap-button
    [btnType]="'secondary'"
    [label]="'Export mapping report'"
    style="margin-right: 6px"
    (onClick)="exportData()"
    themeColor="primary"
  ></ap-button>
  <ap-button
    [btnType]="'secondary'"
    [label]="'Download for mapping'"
    style="margin-right: 10px"
    [disabled]="!isNodeSelected"
    (onClick)="exportUnmappedData()"
    themeColor="primary"
  ></ap-button>
  <ap-button
  [btnType]="'secondary'"
  [label]="'Upload for mapping'"
  style="margin-right: 12px"
  (onClick)="uploadData()"
  themeColor="primary"
></ap-button>
</div>
