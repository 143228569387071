import { Component, OnInit } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AdministrationService } from 'src/app/common/services/administration/administration.service';
@Component({
  selector: 'app-custom-columns-details',
  templateUrl: './custom-columns-details.component.html',
  styleUrls: ['./custom-columns-details.component.scss'],
})
export class CustomColumnsDetailsComponent implements OnInit {
  public isLoading: boolean = false;
  public gridData: any = [];

  constructor(
    public dialog: DialogRef,
    private adminService: AdministrationService
  ) {}

  ngOnInit(): void {
    this.getAllCustomColumn();
  }
  public getAllCustomColumn() {
    this.isLoading = true;
    this.adminService
      .getCustomColumns()
      .then((response: any[]) => {
        this.gridData = response;
        this.isLoading = false;
      })
      .catch((err: any) => {
        this.isLoading = false;
      });
  }
  closeButtonClick() {
    this.dialog.close();
  }
}
