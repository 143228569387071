import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef } from '@progress/kendo-angular-dialog';
import {
  ChunkSettings,
  FileRestrictions,
  SuccessEvent,
  UploadEvent,
  ErrorEvent,
} from '@progress/kendo-angular-upload';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { Manageattachementservice } from 'src/app/common/services/manageattachement/manageattachement.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-uploadglaccount',
  templateUrl: './uploadglaccount.component.html',
  styleUrls: ['./uploadglaccount.component.scss'],
})
export class UploadglaccountComponent implements OnInit {
  constructor(
    private manageattachementservice: Manageattachementservice,
    public toastr: ToastrManager,
    private globalService: GlobalService,
    private router: Router,
    public dialog: DialogRef
  ) {}
  public warnondelete: boolean = false;
  public deleteType: any = 'Soft Delete';
  isAdmin: boolean = false;
  message: string = 'Please Upload File';
  ngOnInit(): void {
    this.loadUploadURL();
  }

  headers: any;
  headersObj: any;
  private apifolder: string = '';
  private baseUrl: string = '';
  private selectedClient: any;
  uploadSaveUrl: string = '';
  uploadRemoveUrl: string = '';
  withCredential: boolean = false;
  public FileStatus: any;
  public ErrorFileStatus: any;
  GLFileRestrictions: FileRestrictions = {
    allowedExtensions: ['.xls', '.xlsx'],
    maxFileSize: 10485760,
  };

  public chunkSettings: ChunkSettings = {
    size: 1048576,
  };

  loadUploadURL() {
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    let tokenValue: any = localStorage.getItem('OPENAMSESSION');
    this.headers = {
      'Authorization-Token': 'Bearer ' + JSON.parse(tokenValue).access_token,
    };
    let selected: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selected);
    this.uploadSaveUrl =
      this.baseUrl +
      this.apifolder +
      '/MapGLAccount/UploadUnMappedGLAccounts?ClientdbName=' +
      this.selectedClient.DBName;

    this.uploadRemoveUrl = 'removeUrl';
  }

  cancelClick() {
    this.warnondelete = false;
    localStorage.removeItem('expendedforUploadMap');
    this.dialog.close();
  }
  uploadEventHandler(e: UploadEvent) {
    let tokenValue: any;
    tokenValue = localStorage.getItem('OPENAMSESSION');
    e.headers = e.headers.append(
      'Authorization-Token',
      'Bearer ' + JSON.parse(tokenValue).access_token
    );
  }

  public success(e: SuccessEvent): void {
    if (e.files.length > 0) {
      if (e.response.status == 200) {
        this.DownloadResponseFile(e.response.body.FileUid);
        this.toastr.successToastr('File upload successfully.', 'Success');
        this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/mapGlAccounts']));
      this.warnondelete = false;
      this.dialog.close();
      }
    }
  }

  public error(e: any): void {
    this.ErrorFileStatus =e.response.error;
  }
  DownloadResponseFile(responsecontent: any) {
    this.globalService.DownloadResponseFile(responsecontent).subscribe(
      (response) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const fileName = 'GLAccountSummary' + timestamp + '.txt';
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
