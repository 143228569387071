import { Component, OnInit, Input } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
@Component({
  selector: 'app-piechart-popup',
  templateUrl: './piechart-popup.component.html',
  styleUrls: ['./piechart-popup.component.scss']
})
export class PiechartPopupComponent implements OnInit {
  public isLoading: boolean = false;
  @Input() changeValue: any;
  constructor(public dialog: DialogRef) { }

  ngOnInit(): void {
    console.log(this.changeValue)
  }
  closeButtonClick() {
    this.dialog.close();
  }
}
