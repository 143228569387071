import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MyGlobalObject } from '../../myglobalobject';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root',
})
export class TaxoutcomeService {
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getAllTaxOutcomes: '/TaxOutcomes/GetAllTaxOutcomes',
      getTaxOutComeMappingData: '/TaxOutcomeMapping/GetTaxOutComeMappingData',
      InsertUpdate: '/TaxOutcomeMapping/InsertUpdate',
      getCustomePeriodData: '/TaxOutcomeMapping/GetCustomPeriod',
    };
  }

  getAllTaxOutcomesList(): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllTaxOutcomes;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  getTaxOutComeMappingData(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getTaxOutComeMappingData;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }

  SaveTaxOutComeMapping(taxOutComeMapping: any): Observable<string> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.InsertUpdate;
    return this.http.post<string>(this.apiHost, taxOutComeMapping, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  getCustomePeriodData(): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getCustomePeriodData;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
}
