import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import {
  DialogRef,
  DialogContentBase,
  ActionsLayout,
  DialogService,
  DialogAction,
} from '@progress/kendo-angular-dialog';
import {
  NodeClickEvent,
  TreeViewModule,
} from '@progress/kendo-angular-treeview';
import { forkJoin, Observable } from 'rxjs';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { ContextMenuComponent } from '@progress/kendo-angular-menu';
import { AddanalysisstatementlayoutComponent } from '../addanalysisstatementlayout/addanalysisstatementlayout.component';
import { NewsubanalysisComponent } from '../newsubanalysis/newsubanalysis.component';
import { DeletestatementlayoutComponent } from '../deletestatementlayout/deletestatementlayout.component';
import { map } from 'rxjs/internal/operators/map';
import { Router } from '@angular/router';
import { EditstatementcolumnComponent } from '../editstatementcolumn/editstatementcolumn.component';
const is = (fileName: string, ext: string) =>
  new RegExp(`.${ext}\$`).test(fileName);

@Component({
  selector: 'app-analysisstatementgridpopup',
  templateUrl: './analysisstatementgridpopup.component.html',
  styleUrls: ['./analysisstatementgridpopup.component.scss'],
})
export class AnalysisstatementgridpopupComponent implements OnInit {
  @ViewChild('treeview', { static: false }) public treeview: any;
  usedByData: any;
  previewSubAnalysisBlocks: any;
  childData: any[] = [];
  previewData: any[] = [];
  usedBydisable: boolean = true;
  SAType: any;
  isEdit: boolean = true;
  isCopy: boolean = true;
  ismoveDown: boolean = true;
  ismoveUp: boolean = true;
  isStatementLayout: boolean = false;
  issubanalysis: boolean = false;
  isstatementColumn: boolean = false;
  isChild: boolean = true;
  isdelEnable: boolean = true;
  nodeData: any;
  public expandedKeys: any[] = ['0'];
  public isItemExpanded = (_: any, index: string) =>
    this.expandedKeys.indexOf(index) > -1;
  public treeContextMenu: ContextMenuComponent | any;
  //dialog: any;
  public actionsLayout: ActionsLayout = 'normal';
  public analysisStatement: Observable<any[]> | any;
  public hasChildren = (item: any) => {
    return 'Description' in item;
  };
  public selectedKeys: any[] = [];
  public isItemSelected = (_: any, index: string) =>
    this.selectedKeys.indexOf(index) > -1;

  public handleSelection({ index }: any): void {
    this.selectedKeys = [index];
  }
  public lastNode: any;
  private contextItem: any;
  public onNodeClick(e: NodeClickEvent): void {
    this.previewData = [];
    let id;

    this.nodeData = e.item?.dataItem;
    if (e.item?.dataItem.Type == 'B') {
      this.isEdit = true;
      this.isdelEnable = true;
      this.isChild = true;
      this.isCopy = true;
    } else {
      if (this.nodeData.StatementLayoutId) {
        id = this.nodeData.StatementLayoutId;
      } else {
        id = this.nodeData.ParentStatementLayoutId;
      }
      if (this.nodeData.LayoutTypeId == 1) this.SAType = 'StatementAnalysis';
      else this.SAType = 'BFStatement';

      this.setPreviewData(id, this.SAType);
      this.globalService
        .getAllStatementUsedBy(id)
        .then((res) => {
          this.usedByData = res;
          if (res.length == 0) {
            this.usedBydisable = true;
            this.isdelEnable = false;
          } else {
            this.isdelEnable = true;
            this.usedBydisable = false;
          }
          if (e.item?.dataItem.Type == 'C') {
            this.isEdit = false;
            this.isdelEnable = true;
          }
        })
        .catch((err) => {});
      if (e.item?.dataItem.StatementLayoutId) {
        this.isStatementLayout = true;
        this.issubanalysis = false;
        this.isChild = false;
        this.isCopy = false;
        this.ismoveDown = true;
        this.ismoveUp = true;
      } else if (e.item?.dataItem.ChildNodeLayoutId) {
        this.issubanalysis = true;
        this.isStatementLayout = false;
        this.isChild = true;
        this.isCopy = true;
        /*if(e.item?.dataItem.DisplayOrder!=1){
        this.ismoveUp=false;
        this.ismoveDown=true;
        }*/
        let childNodesBlocks = this.globalService
          .getAllChildNode(id)
          .subscribe((data: any) => {
            let subAnalysis = data.filter((data: any) => data.Type === 'A');
            if (
              e.item?.dataItem.DisplayOrder == 1 &&
              e.item?.dataItem.Type == 'A'
            ) {
              this.ismoveUp = true;
              this.ismoveDown = false;
            } else if (
              e.item?.dataItem.DisplayOrder == subAnalysis.length &&
              e.item?.dataItem.Type == 'A'
            ) {
              this.ismoveUp = false;
              this.ismoveDown = true;
            } else if (
              e.item?.dataItem.DisplayOrder > 1 &&
              e.item?.dataItem.DisplayOrder < subAnalysis.length &&
              e.item?.dataItem.Type == 'A'
            ) {
              this.ismoveDown = false;
              this.ismoveUp = false;
            } else {
              this.ismoveDown = true;
              this.ismoveUp = true;
            }
          });
      }
      this.isEdit = false;
      this.isstatementColumn = false;
      if (e.item?.dataItem.Type == 'C') {
        this.isEdit = false;
        this.isdelEnable = true;
        this.isstatementColumn = true;
      }
    }
  }
  public onSelect(_e: any): void {}
  closeButtonClick() {
    // this.dialog.close();
    this.router.navigate(['/home']);
  }

  constructor(
    // public dialog: DialogRef,
    private router: Router,

    private cookieService: CookieService,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {}

  ngOnInit(): void {
    this.getAllStatementLayout();
  }
  openAddASLayoutPopup() {
    // this.dialog.close();
    const dialogRef = this.dialogService.open({
      content: AddanalysisstatementlayoutComponent,
      width: '700px',
      height: 'auto',
    });
    const addASLayout = dialogRef.content
      .instance as AddanalysisstatementlayoutComponent;
    dialogRef.result.subscribe((r: any) => {});
    addASLayout.LayoutString = 'Simple';
  }
  openAddBFASLayoutPopup() {
    // this.dialog.close();
    const dialogRef = this.dialogService.open({
      content: AddanalysisstatementlayoutComponent,
      width: '700px',
      height: 'auto',
    });
    const addBFASLayout = dialogRef.content
      .instance as AddanalysisstatementlayoutComponent;
    dialogRef.result.subscribe((r: any) => {});
    addBFASLayout.LayoutString = 'Balance Sheet';
  }
  public onMoveUpButtonClick(e: {
    item: { dataItem: { StatementLayoutId: any; ChildNodeLayoutId: any } };
  }): void {
    if (this.issubanalysis) {
      this.globalService
        .statementBlockMoveUp(this.nodeData.ChildNodeLayoutId)
        .then((res) => {
          if (res == 0) {
            this.ismoveUp = false;
          } else {
            this.ismoveUp = true;
          }
          this.getAllStatementLayout();
        })
        .catch((err) => {});
    }
  }

  public onMoveDownButtonClick(e: {
    item: { dataItem: { StatementLayoutId: any; ChildNodeLayoutId: any } };
  }): void {
    if (this.issubanalysis) {
      this.globalService
        .statementBlockMoveDown(this.nodeData.ChildNodeLayoutId)
        .then((res) => {
          if (res == 0) {
            this.ismoveDown = false;
          } else {
            this.ismoveDown = true;
          }
          this.getAllStatementLayout();
        })
        .catch((err) => {});
    }
  }
  onTabSelect(e: any) {}

  getAllStatementLayout() {
    this.globalService
      .getAllStatementLayout()
      .then((res) => {
        this.analysisStatement = res;
        this.selectedKeys = [];
      })
      .catch((err) => {});
  }
  setPreviewData(id: any, statmentType: any) {
    /*preview changes*/
    this.childData = this.globalService
      .getAllChildNode(id)
      .subscribe((data: any) => {
        let subAnalysis = data.filter((data: any) => data.Type === 'A');
        this.previewSubAnalysisBlocks = subAnalysis;
        if (statmentType == 'StatementAnalysis') {
          for (let i = 1; i <= this.previewSubAnalysisBlocks.length; i++) {
            for (let j = 1; j <= 4; j++) {
              let obj1 = { Description: '', Amount: '', DisplayOrder: 0 };
              let val1 = i;
              if (j == 1) {
                obj1.Description =
                  this.previewSubAnalysisBlocks[i - 1].BlockHeaderText;
                obj1.Amount = '';
              } else if (j == 2) {
                val1 = i * 2 - 1;
                obj1.Description = 'Example line item ' + val1;
                obj1.Amount = val1.toString();
              } else if (j == 3) {
                let val2 = i * 2;
                obj1.Description = 'Example line item ' + val2;
                obj1.Amount = val2.toString();
              } else if (j == 4) {
                obj1.Description =
                  this.previewSubAnalysisBlocks[i - 1].BlockFooterText;
                obj1.Amount = '';
              }

              obj1.DisplayOrder =
                this.previewSubAnalysisBlocks[i - 1].DisplayOrder;
              this.previewData.push(obj1);
            }
          }
        } else if (statmentType == 'BFStatement') {
          for (let i = 1; i <= this.previewSubAnalysisBlocks.length; i++) {
            for (let j = 1; j <= 4; j++) {
              let obj1 = {
                Description: '',
                Balancebfwd: '',
                Movements: '',
                Balancecfwd: '',
                DisplayOrder: 0,
              };
              let val1 = i;
              if (j == 1) {
                obj1.Description =
                  this.previewSubAnalysisBlocks[i - 1].BlockHeaderText;
                obj1.Balancebfwd = '';
                obj1.Movements = '';
                obj1.Balancecfwd = '';
              } else if (j == 2) {
                val1 = i * 2 - 1;
                obj1.Description = 'Example line item ' + val1;
                obj1.Balancebfwd = val1.toString();
                obj1.Movements = val1.toString();
                obj1.Balancecfwd = (val1 * 2).toString();
              } else if (j == 3) {
                let val2 = i * 2;
                obj1.Description = 'Example line item ' + val2;
                obj1.Balancebfwd = val2.toString();
                obj1.Movements = val2.toString();
                obj1.Balancecfwd = (val2 * 2).toString();
              } else if (j == 4) {
                obj1.Description =
                  this.previewSubAnalysisBlocks[i - 1].BlockFooterText;
                obj1.Balancebfwd = '';
                obj1.Movements = '';
                obj1.Balancecfwd = '';
              }

              obj1.DisplayOrder =
                this.previewSubAnalysisBlocks[i - 1].DisplayOrder;
              this.previewData.push(obj1);
            }
          }
        }
        let previewDatacreated = this.previewData;
        let finalpreviewData = [];

        this.previewData = previewDatacreated;
      });

    //ends
  }
  public fetchChildren = (id: any) => {
    return this.globalService.getAllChildNode(id.StatementLayoutId);
  };

  public newSubanalysisClick(nodes: { StatementLayoutId: any }): void {
    //this.dialog.close();
    const dialogRef = this.dialogService.open({
      content: NewsubanalysisComponent,
      width: '700px',
      height: 'auto',
    });

    const analysisStatementDetails = dialogRef.content
      .instance as NewsubanalysisComponent;
    dialogRef.result.subscribe((r: any) => {
      this.getAllStatementLayout();
    });
    analysisStatementDetails.ASId = nodes.StatementLayoutId;
    analysisStatementDetails.parentDetails = this.nodeData;
  }

  public editButtonClick(nodes: { StatementLayoutId: any }): void {
    if (this.isStatementLayout) {
      // this.dialog.close();
      const dialogRef = this.dialogService.open({
        content: AddanalysisstatementlayoutComponent,
        width: '700px',
        height: 'auto',
      });
      const addASLayout = dialogRef.content
        .instance as AddanalysisstatementlayoutComponent;
      dialogRef.result.subscribe((r: any) => {});
      if (this.nodeData.LayoutTypeId == 2) {
        addASLayout.LayoutString = 'Balance Sheet';
      } else {
        addASLayout.LayoutString = 'Simple';
      }

      addASLayout.editNodeData = this.nodeData;
    } else if (this.issubanalysis && !this.isstatementColumn) {
      const dialogRef = this.dialogService.open({
        content: NewsubanalysisComponent,
        width: '700px',
        height: 'auto',
      });

      const analysisStatementDetails = dialogRef.content
        .instance as NewsubanalysisComponent;
      dialogRef.result.subscribe((r: any) => {
        this.getAllStatementLayout();
      });
      analysisStatementDetails.editSubAnalysisData = this.nodeData;
    } else if (this.isstatementColumn) {
      const dialogRef = this.dialogService.open({
        content: EditstatementcolumnComponent,
        width: '530px',
        height: 'auto',
      });

      const editstatementcolumnComponent = dialogRef.content
        .instance as EditstatementcolumnComponent;
      editstatementcolumnComponent.editStatementColumnData = this.nodeData;
    }
  }

  public copyButtonClick(nodes: { StatementLayoutId: any }): void {
    if (this.isStatementLayout) {
      // this.dialog.close();
      const dialogRef = this.dialogService.open({
        content: AddanalysisstatementlayoutComponent,
        width: '700px',
        height: 'auto',
      });
      const addASLayout = dialogRef.content
        .instance as AddanalysisstatementlayoutComponent;
      dialogRef.result.subscribe((r: any) => {});
      if (this.nodeData.LayoutTypeId == 2) {
        addASLayout.LayoutString = 'Balance Sheet';
      } else {
        addASLayout.LayoutString = 'Simple';
      }

      addASLayout.copyNodeData = this.nodeData;
    }
  }

  public deleteButtonClick(nodes: { StatementLayoutId: any }): void {
    const dialogRef = this.dialogService.open({
      content: DeletestatementlayoutComponent,
      width: 500,
      height: 'auto',
    });
    const delASDetails = dialogRef.content
      .instance as DeletestatementlayoutComponent;
    dialogRef.result.subscribe((r: any) => {
      this.getAllStatementLayout();
      this.isEdit = true;
      this.isCopy = true;
      this.isdelEnable = true;
      this.isChild = true;
    });
    delASDetails.delSLDetails = this.nodeData;
  }
}
