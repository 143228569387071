<!-- <div class="row" *ngIf="isVisited == 'false'">
  <div
    class="col-10"
    style="height: 100vh; background-color: #d2d7e2"
    *ngIf="isVisited == 'false'"
  >
    <h2 style="margin: 10%">Transaction Data Assist</h2>
    <div style="margin-left: 20%">
      <ap-button
        [btnType]="'primary'"
        class="okButton"
        [label]="'Login'"
        style="margin-right: 6px"
        themeColor="primary"
        (click)="goto()"
      ></ap-button>
    </div>
  </div>
  <div class="col-2 landing" style="height: 100vh"></div>
</div> -->
<div class="contain1" *ngIf="isVisited == 'false'">
  <div style="background-color: #f2f2f2; width: 50%; padding: 7% 0 7% 22%">
    <span>
      <img
        style="width: 150px; height: 150px"
        src="./assets/images/pwcicon.png"
    /></span>
    <h4 style="padding-left: 16%">Transaction Data Assist</h4>
    <div class="content-text">
      <ul style="padding-left: 16%">
        <li class="textdata">It's nice to have you back<br /></li>
      </ul>
    </div>
    <div style="margin-left: 60%">
      <ap-button
        [btnType]="'primary'"
        class="okButton"
        [label]="'Login'"
        style="margin-right: 6px; background-color: #000"
        themeColor="primary"
        (click)="goto()"
      ></ap-button>
    </div>
  </div>
  <div class="image-mainclass">
    <span>
      <img class="image-size" src="./assets/images/loginimage.png"
    /></span>
    <!-- <div class="landingright"></div> -->
  </div>
</div>
<div class="" *ngIf="isVisited == 'true'">
  <div class="responsive">
    <kendo-appbar position="top" *ngIf="menubarService.visible">
      <kendo-appbar-section class="logo-text">
        <img
          class="sepspace"
          (click)="homeClick()"
          width="42"
          height="16"
          src="./assets/images/Logo.png"
          title="PricewaterhouseCoopers"
        />
      </kendo-appbar-section>

      <kendo-appbar-section class="data-assist">
        <p class="customAppkitStyleMenuItem" style="margin-bottom: 5px">
          Transaction Data Assist
        </p>
      </kendo-appbar-section>

      <kendo-appbar-spacer></kendo-appbar-spacer>

      <kendo-menu
        [kendoMenuFlatBinding]="selectYearList"
        idField="FiscalYearId"
        parentIdField="parentId"
        textField="Display"
        (select)="handleSelectionYearChange($event)"
        class="select-year"
      >
      </kendo-menu>

      <kendo-appbar-section>
        <kendo-menu (select)="handleSelectionChange($event)">
          <kendo-menu-item text="Setup">
            <kendo-menu-item text="Business types"></kendo-menu-item>
            <kendo-menu-item text="Legal entities"></kendo-menu-item>
            <kendo-menu-item text="Analysis statement"></kendo-menu-item>
            <kendo-menu-item text="Statement details">
              <kendo-menu-item text="Line items"></kendo-menu-item>
              <kendo-menu-item text="Tax accounts"></kendo-menu-item>
              <kendo-menu-item text="Export template"></kendo-menu-item>
              <kendo-menu-item text="Import template"></kendo-menu-item>
            </kendo-menu-item>
            <kendo-menu-item text="GL accounts">
              <kendo-menu-item text="Download Template"></kendo-menu-item>
              <kendo-menu-item text="Import GL accounts"></kendo-menu-item>
              <kendo-menu-item text="Manage GL accounts"> </kendo-menu-item>
            </kendo-menu-item>
            <kendo-menu-item text="Mapping"> </kendo-menu-item>

            <kendo-menu-item text="Configure ">
              <kendo-menu-item text="Tax outcomes"></kendo-menu-item>
              <kendo-menu-item text="Keyword groups"></kendo-menu-item>
              <kendo-menu-item text="Habitats"></kendo-menu-item>
              <kendo-menu-item text="Statutory treatment"></kendo-menu-item>
            </kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
      </kendo-appbar-section>

      <kendo-appbar-section>
        <kendo-menu (select)="handleSelectionChange($event)">
          <kendo-menu-item text="Import">
            <kendo-menu-item text="Trial balance"></kendo-menu-item>
            <kendo-menu-item text="Transactions"></kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
      </kendo-appbar-section>
      <kendo-appbar-section>
        <kendo-menu
          (select)="handleSelectionChange($event)"
          class="analyse-text"
        >
          <kendo-menu-item text="Analyse">
            <kendo-menu-item text="Analyse and review"></kendo-menu-item>
            <kendo-menu-item text="Allocation rules"></kendo-menu-item>
            <kendo-menu-item text="Manage transactions"></kendo-menu-item>
            <kendo-menu-item text="Offline allocation">
              <kendo-menu-item text="New offline allocation"></kendo-menu-item>
              <kendo-menu-item
                text="Collect offline allocation"
              ></kendo-menu-item>
              <kendo-menu-item
                text="Manage offline allocation"
              ></kendo-menu-item>
            </kendo-menu-item>
            <!-- <kendo-menu-item text="Offline statement">
                   <kendo-menu-item text="With control total"></kendo-menu-item>
               <kendo-menu-item text="Without control total"></kendo-menu-item>
               <kendo-menu-item text="Check in"></kendo-menu-item>
               <kendo-menu-item text="Check out"></kendo-menu-item>
               <kendo-menu-item text="Manage offline statement"></kendo-menu-item>
                 </kendo-menu-item> -->
            <kendo-menu-item text="Journals">
              <kendo-menu-item text="New adjustment journal"></kendo-menu-item>
              <kendo-menu-item text="New movement journal"></kendo-menu-item>
              <kendo-menu-item text="Manage journals"></kendo-menu-item>
            </kendo-menu-item>
            <kendo-menu-item text="Manage attachments"></kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
      </kendo-appbar-section>
      <kendo-appbar-section>
        <kendo-menu (select)="handleSelectionChange($event)">
          <kendo-menu-item text="Export">
            <kendo-menu-item
              text="Analysis statements"
              cssClass="submenu"
            ></kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
      </kendo-appbar-section>
      <kendo-appbar-section>
        <kendo-menu (select)="handleSelectionChange($event)">
          <kendo-menu-item text="Reporting">
            <kendo-menu-item text="Tax account"></kendo-menu-item>
            <kendo-menu-item text="Comparative analysis">
              <ng-container *ngFor="let item of ComparativeOptions">
                <kendo-menu-item>
                  <ng-template kendoMenuItemTemplate>
                    <span (click)="onSelect(item)">{{ item.text }}</span>
                  </ng-template>
                </kendo-menu-item>
              </ng-container>
            </kendo-menu-item>
            <kendo-menu-item text="Tax outcome">
              <kendo-menu-item text="Options"></kendo-menu-item>
              <kendo-menu-item text="Toggle"></kendo-menu-item>
            </kendo-menu-item>
            <kendo-menu-item text="Master data"></kendo-menu-item>
            <kendo-menu-item text="GL account"></kendo-menu-item>
            <!-- <kendo-menu-item text="Habitat exceptions"></kendo-menu-item> -->
          </kendo-menu-item>
        </kendo-menu>
      </kendo-appbar-section>
      <kendo-appbar-section>
        <kendo-menu (select)="handleSelectionChange($event)">
          <kendo-menu-item text="Administration">
            <kendo-menu-item text="Preferences"></kendo-menu-item>
            <kendo-menu-item
              *ngIf="this.isAdmin"
              text="Manage custom columns"
            ></kendo-menu-item>
            <kendo-menu-item
              *ngIf="this.isAdmin"
              text="Transaction data"
            ></kendo-menu-item>
            <kendo-menu-item text="Unlock import"></kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
      </kendo-appbar-section>
      <kendo-appbar-spacer></kendo-appbar-spacer>
      <kendo-appbar-section>
        <span
          class="customAppkitStyleMenuItem"
          *ngIf="this.displaySelectedClient"
        >
          {{ this.displaySelectedClient.ClientEntityName }} ({{
            this.selectedFiscalYear
          }})
        </span>
      </kendo-appbar-section>
      <kendo-appbar-section class="actions">
        <button
          kendoTooltip
          style="background-color: #ebebeb"
          title="Switch Entity"
          (click)="openDialogForm()"
        >
          <span class="Appkit4-icon icon-change-outline"></span>
        </button>
        <button
          kendoTooltip
          style="background-color: #ebebeb"
          title="Home"
          (click)="homeClick()"
        >
          <span class="Appkit4-icon icon-home-outline"></span>
        </button>
        <button
          kendoTooltip
          style="background-color: #ebebeb"
          (click)="helpClick()"
          title="Help"
        >
          <span class="Appkit4-icon icon-help-question-outline"></span>
        </button>
      </kendo-appbar-section>
      <kendo-appbar-section class="actions">
        <button
          style="background-color: #ebebeb"
          mat-button
          [matMenuTriggerFor]="menu"
        >
          <kendo-avatar
            [initials]="initials"
            shape="circle"
            width="26px"
            height="26px"
          ></kendo-avatar>
        </button>
        <mat-menu
          #menu="matMenu"
          style="position: absolute; top: 58px; right: 0px"
        >
          <button mat-menu-item (click)="logout()">Logout</button>
        </mat-menu>
      </kendo-appbar-section>
    </kendo-appbar>
  </div>
  <div class="responsive">
    <kendo-appbar position="top" *ngIf="!menubarService.visible">
      <kendo-appbar-section>
        <img
          class="sepspace"
          (click)="homeClick()"
          width="42"
          height="16"
          src="./assets/images/Logo.png"
          title="PricewaterhouseCoopers"
        />
      </kendo-appbar-section>

      <kendo-appbar-section>
        <p class="customAppkitStyleMenuItem" style="margin-bottom: 5px">
          Transaction Data Assist
        </p>
      </kendo-appbar-section>

      <kendo-appbar-spacer></kendo-appbar-spacer>

      <kendo-appbar-spacer></kendo-appbar-spacer>
      <kendo-appbar-section *ngIf="menubarService.entityVisible">
        <span
          class="customAppkitStyleMenuItem"
          *ngIf="this.displaySelectedClient"
        >
          {{ this.displaySelectedClient.ClientEntityName }} ({{
            this.selectedFiscalYear
          }})</span
        >
      </kendo-appbar-section>

      <kendo-appbar-section class="actions">
        <button
          style="background-color: #ebebeb"
          mat-button
          [matMenuTriggerFor]="menu"
        >
          <kendo-avatar
            [initials]="initials"
            shape="circle"
            width="26px"
            height="26px"
          ></kendo-avatar>
        </button>
        <mat-menu
          #menu="matMenu"
          style="position: absolute; top: 58px; right: 0px"
        >
          <button mat-menu-item (click)="logout()">Logout</button>
        </mat-menu>
      </kendo-appbar-section>
    </kendo-appbar>
  </div>
  <!-- <app-menu></app-menu> -->
  <div class="enlightenDialog">
    <div kendoDialogContainer></div>
  </div>
  <div kendoWindowContainer></div>
  <router-outlet></router-outlet>
</div>
