<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>
<!-- <div class="headerRule">Manage Transaction
</div> -->
<div class="header">Manage transactions</div>
<mat-card class="" style="padding: 0px">
  <mat-card-content>
    <!-- <div class="col-md-12 col-xs-12"></div> -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 col-xs-12 scroll">
          <!-- search bar and action -->

          <div>
            <form class="k-form" [formGroup]="addJournalForm">
              <kendo-formfield>
                <div class="container-fluid" style="padding: 0px">
                  <div class="row">
                    <div
                      class="col-7"
                      style="
                        border: 0px solid;
                        padding-top: 0%;
                        margin-bottom: 10px;
                      "
                    >
                      <b>Scope</b>
                    </div>
                    <div class="col" style="border: 0px solid; padding-top: 0%">
                      <b>Display settings</b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-7">
                      <div
                        style="border: 1px solid #d9d9d9; padding-bottom: 10px"
                      >
                        <kendo-label
                          text="Business type:"
                          style="
                            margin-top: 10px;
                            margin-left: 10px;
                            font-size: 14px;
                          "
                        >
                          <kendo-combobox
                            #businessType
                            formControlName="businessType"
                            [(ngModel)]="selectedBusinessType"
                            [data]="businessTypes"
                            textField="Description"
                            valueField="BusinessTypeId"
                            [kendoDropDownFilter]="filterSettings"
                            placeholder="Select business type"
                            (valueChange)="handleBusinessTypeChange($event)"
                            [disabled]="isTaxAmtData || mode == 'Edit'"
                            style="width: 70%; margin-left: 9px"
                            placeholder="Select business type"
                            required
                            [kendoDropDownFilter]="filterSettings"
                          >
                          </kendo-combobox>
                        </kendo-label>

                        <kendo-label
                          text="Legal entity:"
                          style="
                            margin-top: 10px;
                            margin-left: 10px;
                            font-size: 14px;
                          "
                        >
                          <kendo-combobox
                            #legalEntity
                            formControlName="legalEntity"
                            [data]="legalEntities"
                            [(ngModel)]="selectedLegalEntity"
                            style="width: 70%; margin-left: 24px"
                            textField="Description"
                            [kendoDropDownFilter]="filterSettings"
                            valueField="ID"
                            placeholder="Select legal entity"
                            (valueChange)="handleLegalEntityChange($event)"
                            [disabled]="isTaxAmtData || mode == 'Edit'"
                            required
                            [kendoDropDownFilter]="filterSettings"
                          >
                          </kendo-combobox>
                        </kendo-label>

                        <kendo-label
                          text="Tax account:"
                          style="
                            margin-top: 10px;
                            margin-left: 10px;
                            font-size: 14px;
                          "
                        >
                          <kendo-combobox
                            #taxAccount
                            formControlName="taxAccount"
                            [data]="taxAccounts"
                            [(ngModel)]="selectedTaxAccount"
                            style="width: 70%; margin-left: 18px"
                            [kendoDropDownFilter]="filterSettings"
                            textField="ShortDescription"
                            valueField="TaxAccountCodeId"
                            placeholder="Select tax account"
                            (valueChange)="handleTaxEntityChange($event)"
                            required
                            [kendoDropDownFilter]="filterSettings"
                          >
                          </kendo-combobox>
                          <ap-checkbox
                            (onChange)="complexCheckBox($event)"
                            style="margin-left: 5px"
                          >
                          </ap-checkbox>
                          <kendo-label
                            style="font-size: 14px"
                            text="Complex only"
                          ></kendo-label>
                        </kendo-label>
                      </div>
                    </div>
                    <div class="col-5">
                      <div
                        style="
                          border: 1px solid #d9d9d9;
                          padding: 10px;
                          height: 100%;
                        "
                      >
                        <ap-checkbox
                          (onChange)="checkMatchTransaction($event)"
                          formControlName="matchedTrans"
                          [(ngModel)]="isMatchTransaction"
                        >
                          <kendo-label
                            style="font-size: 14px"
                            text="Display matched transactions"
                          ></kendo-label>
                        </ap-checkbox>

                        <ap-checkbox (onChange)="checkMovedTransaction($event)">
                          <kendo-label
                            style="font-size: 14px"
                            text="Display moved transactions"
                          ></kendo-label>
                        </ap-checkbox>

                        <ap-checkbox
                          (onChange)="checkTaxChildTransaction($event)"
                        >
                          <kendo-label
                            style="font-size: 14px"
                            text="Display child tax account transactions"
                          ></kendo-label>
                        </ap-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </kendo-label> --> </kendo-formfield
              ><br />
              <div *ngIf="!isviewAutomatch">
                <!-- <samp>Select All</samp><br> -->
                <div class="row">
                  <div
                    class="col-3"
                    style="width: fit-content; margin-top: -14px; display: flex"
                  >
                    <div style="margin-top: 5px; height: fit-content">
                      <input
                        type="checkbox"
                        kendoCheckBox
                        (click)="changeSelectAll()"
                        [checked]="isSelectAll"
                      />
                      <span style="font-size: 14px; margin-right: 10px">
                        Select all</span
                      >
                      <button
                        kendoButton
                        class="actionButton chooseColumnsButton"
                        (click)="openWindow()"
                        [disabled]="isLoading"
                        style="
                          color: #ffff;
                          padding: 2px 8px;
                          background-color: #d04a02;
                        "
                      >
                        Choose columns
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-8"
                    style="margin-left: -10px; margin-top: -10px"
                  >
                    <kendo-label text="Sort by:" style="font-size: 14px">
                      <kendo-combobox
                        [data]="gridColumns"
                        style="width: 40%; margin-left: 18px"
                        [(ngModel)]="selectedcolumnSort"
                        [kendoDropDownFilter]="filterSettings"
                        textField="title"
                        valueField="field"
                        formControlName="sortBy"
                        (valueChange)="handleSortColumnChange($event)"
                      >
                      </kendo-combobox>
                      &nbsp;&nbsp;
                      <button
                        kendoButton
                        name="up"
                        (click)="upbuttonClick()"
                        [class.activated]="sortorder == 'asc'"
                        style="margin-right: 5px; height: 30px"
                      >
                        <span
                          class="Appkit4-icon icon-arrow-up-small-outline"
                        ></span>
                      </button>
                      &nbsp;
                      <button
                        kendoButton
                        name="down"
                        (click)="downbuttonClick()"
                        style="margin-right: 5px; height: 30px"
                        [class.activated]="sortorder == 'desc'"
                      >
                        <span
                          class="Appkit4-icon icon-arrow-down-small-outline"
                        ></span>
                      </button>
                    </kendo-label>
                  </div>
                </div>

                <kendo-grid
                  [kendoGridBinding]="getTransactionView"
                  scrollable="virtual"
                  style="margin-top: 7px; height: calc(100vh - 550px)"
                  [resizable]="true"
                  [reorderable]="true"
                >
                  <kendo-grid-column
                    field="IsChecked"
                    title="Select"
                    [width]="80"
                    [headerStyle]="{
                      'font-weight': 'bold',
                      'text-align': 'left',
                      color: '#d04a02'
                    }"
                  >
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <input
                        type="checkbox"
                        kendoCheckBox
                        (click)="toggle(dataItem)"
                        [checked]="dataItem.IsChecked"
                      />
                    </ng-template>
                  </kendo-grid-column>
                  <!-- <kendo-grid-checkbox-column
                  [checked]="isSelectAll"
                
                  (click)="changeSelectAll()"
                ></kendo-grid-checkbox-column> -->
                  <ng-container *ngFor="let col of gridColumns">
                    <kendo-grid-column
                      *ngIf="
                        !(
                          col.field == 'Amount' || col.field == 'AbsoluteAmount'
                        )
                      "
                      field="{{ col.field }}"
                      title="{{ col.title }}"
                      format="{{ col.format }}"
                      [filter]="col.type"
                      [width]="
                        col.field == 'GLAccount'
                          ? 100
                          : 'GLAccountDescription'
                          ? 150
                          : 'Description'
                          ? 250
                          : 'Taxaccount'
                          ? 160
                          : 'TaxOutcome'
                          ? 115
                          : 'LegalEntity'
                          ? 80
                          : 'FiscalYear'
                          ? 30
                          : 'DocumentType'
                          ? 30
                          : 'DocumentNo'
                          ? 160
                          : 'DocumentDate'
                          ? 115
                          : 'PostingDate'
                          ? 115
                          : 'TransactionDate'
                          ? 115
                          : 'Period'
                          ? 30
                          : 'Currency'
                          ? 50
                          : 'CostCentre'
                          ? 115
                          : 'Vendor'
                          ? 160
                          : 'Customer'
                          ? 80
                          : 'Division'
                          ? 80
                          : 'Product'
                          ? 80
                          : 'Project'
                          ? 80
                          : 'AssetCode'
                          ? 30
                          : 'VATCode'
                          ? 30
                          : 'PurchaseOrder'
                          ? 80
                          : 'Order'
                          ? 80
                          : 'WBSElement'
                          ? 80
                          : 'ProfitCentre'
                          ? 80
                          : 'MatchID'
                          ? 80
                          : 'AllocationType'
                          ? 80
                          : 'JournalDescription'
                          ? 80
                          : 'JournalRef'
                          ? 80
                          : 'JournalNarrative'
                          ? 115
                          : 'JournalCategory'
                          ? 80
                          : 'JournalDate'
                          ? 115
                          : 'TransactionType'
                          ? 10
                          : 'FiscalYear'
                          ? 70
                          : 'MatchID'
                          ? 90
                          : 'Row'
                          ? 90
                          : 30
                      "
                      [headerStyle]="{ 'font-weight': 'bold' }"
                    ></kendo-grid-column>

                    <kendo-grid-column
                      *ngIf="col.field == 'Amount'"
                      field="{{ col.field }}"
                      title="{{ col.title }}"
                      format="{{ col.format }}"
                      [filter]="col.type"
                      [width]="200"
                      [headerStyle]="{ 'font-weight': 'bold' }"
                      [style]="{ 'text-align': 'right' }"
                    >
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span>{{
                          (dataItem.Amount == null
                            ? dataItem.Amount
                            : dataItem.Amount
                          ) | tonumber
                        }}</span></ng-template
                      >
                    </kendo-grid-column>
                    <kendo-grid-column
                      *ngIf="col.field == 'AbsoluteAmount'"
                      field="{{ col.field }}"
                      title="{{ col.title }}"
                      format="{{ col.format }}"
                      [filter]="col.type"
                      [width]="200"
                      [headerStyle]="{ 'font-weight': 'bold' }"
                      [style]="{ 'text-align': 'right' }"
                    >
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span>{{
                          dataItem.AbsoluteAmount == null
                            ? dataItem.AbsoluteAmount
                            : roundedPercentage(dataItem.AbsoluteAmount)
                        }}</span></ng-template
                      >
                    </kendo-grid-column>
                  </ng-container>
                </kendo-grid>

                <div class="row" *ngIf="!hasTransactionValue">
                  <div
                    class="col-5"
                    style="border: 0px solid; margin-top: 10px; display: flex"
                  >
                    <ap-button
                      kendoButton
                      (click)="backwardPendingDataToFirst()"
                      [btnType]="'secondary'"
                      style="width: 50px; height: 37px; margin-right: 10px"
                      ><span class="material-icons"
                        >keyboard_double_arrow_left</span
                      ></ap-button
                    >

                    <ap-button
                      kendoButton
                      (click)="backwardPendingData()"
                      [btnType]="'secondary'"
                      style="width: 50px; height: 37px; margin-right: 10px"
                      ><span class="material-icons"
                        >keyboard_arrow_left</span
                      ></ap-button
                    >

                    <ap-button
                      kendoButton
                      (click)="forwardPendingData()"
                      [btnType]="'secondary'"
                      style="width: 50px; height: 37px; margin-right: 10px"
                      ><span class="material-icons"
                        >keyboard_arrow_right</span
                      ></ap-button
                    >

                    <ap-button
                      kendoButton
                      (click)="forwardPendingDataToLast()"
                      [btnType]="'secondary'"
                      style="width: 50px; height: 37px"
                      ><span class="material-icons"
                        >keyboard_double_arrow_right</span
                      ></ap-button
                    >
                    <p
                      style="margin-left: 10px; margin-top: 10px"
                      *ngIf="!isLoading"
                    >
                      Page: {{ currentTransactionPendingPageNo }}
                    </p>
                  </div>

                  <div
                    class="col-7"
                    style="
                      border: 0px solid;
                      margin-top: 20px;
                      text-align: right;
                    "
                  >
                    Total pages: {{ totalPagePending }} &nbsp;&nbsp;Total rows:
                    {{ totalRowPending }}
                  </div>
                </div>
                <kendo-formfield style="margin-top: 10px">
                  <div>
                    <div class="row" style="margin-top: 0px">
                      <div class="col-6">
                        <b>Move to tax account</b>
                        <div
                          class="d-grid gap-2 d-md-flex justify-content-md-start"
                          style="margin-top: 10px"
                        >
                          <kendo-combobox
                            #destinationTaxAccount
                            [data]="taxAccountsTo"
                            [(ngModel)]="toTaxAccount"
                            textField="ShortDescription"
                            valueField="TaxAccountCodeId"
                            placeholder="Select tax account"
                            [kendoDropDownFilter]="filterSettings"
                            (valueChange)="handleDestinationTaxAccount($event)"
                            required
                          >
                            <!-- [(ngModel)]="selectedTaxAccount" -->
                          </kendo-combobox>
                          <ap-button
                            [btnType]="'primary'"
                            [label]="'Move'"
                            class="okButton"
                            [disabled]="
                              destinationTaxAccount.value == undefined
                            "
                            style="margin-left: 3px"
                            (onClick)="
                              moveTransaction(
                                taxAccount.value,
                                businessType.value,
                                legalEntity.value,
                                destinationTaxAccount.value
                              )
                            "
                          ></ap-button>
                        </div>
                      </div>
                      <div class="col-6">
                        <b *ngIf="!isTransactionNew">
                          Assign transaction type
                          <div
                            class="d-grid gap-2 d-md-flex justify-content-md-start"
                            style="margin-top: 8px"
                          >
                            <kendo-combobox
                              #journalPeriod
                              [data]="transationData"
                              textField="Description"
                              placeholder="Select assign transaction type"
                              [kendoDropDownFilter]="filterSettings"
                              (valueChange)="handleJournalPeriodChange($event)"
                            >
                            </kendo-combobox>
                            <ap-button
                              kendoButton
                              [btnType]="'primary'"
                              [label]="'Save'"
                              [disabled]="journalPeriod.value == undefined"
                              (onClick)="saveAddTransaction()"
                            >
                            </ap-button>
                          </div>
                        </b>

                        <h6 *ngIf="isTransactionNew">
                          Assign transaction type

                          <div
                            class="d-grid gap-2 d-md-flex justify-content-md-start"
                            style="margin-top: 8px"
                          >
                            <input
                              formControlName="description"
                              placeholder="Enter Assign Transaction Type"
                              value="description"
                              kendoTextBox
                              #journalPeriodNew
                            />
                            <ap-button
                              style="margin-left: 3px"
                              [btnType]="'primary'"
                              [label]="'Add'"
                              [disabled]="
                                this.addJournalForm.controls['description']
                                  .value == ''
                              "
                              (onClick)="AddTransaction()"
                            ></ap-button>
                          </div>
                        </h6>
                      </div>
                    </div>

                    <div
                      class="clickToActions fixed-bottom"
                      *ngIf="!addJournalForm.valid"
                      style="
                        margin-top: 10px;
                        padding-bottom: 10px;
                        right: 1.3%;
                      "
                    >
                      <ap-button
                        kendoButton
                        [btnType]="'primary'"
                        [disabled]="
                          taxAccount.value == undefined ||
                          businessType.value == undefined ||
                          legalEntity.value == undefined
                        "
                        [label]="'Auto match'"
                        style="margin-left: 3px"
                        (onClick)="validateAutomatch()"
                      ></ap-button>
                      <ap-button
                        [btnType]="'primary'"
                        [label]="'Match'"
                        class="okButton"
                        (onClick)="Match()"
                        [disabled]="
                          taxAccount.value == undefined ||
                          businessType.value == undefined ||
                          legalEntity.value == undefined
                        "
                        style="margin-left: 3px"
                      ></ap-button>
                      <ap-button
                        [btnType]="'primary'"
                        [label]="'Auto match by PO'"
                        class=""
                        [disabled]="
                          taxAccount.value == undefined ||
                          businessType.value == undefined ||
                          legalEntity.value == undefined
                        "
                        (onClick)="validateMatchbyPO()"
                        style="margin-left: 3px"
                      ></ap-button>
                      <ap-button
                        [btnType]="'secondary'"
                        [label]="'Unmatch'"
                        (onClick)="UnMatch()"
                        [disabled]="
                          taxAccount.value == undefined ||
                          businessType.value == undefined ||
                          legalEntity.value == undefined
                        "
                        style="
                          margin-left: 3px;
                          margin-right: 3px;
                          width: 100px;
                        "
                      ></ap-button>
                      <ap-button
                        [btnType]="'secondary'"
                        [label]="'Unmatch all'"
                        (onClick)="unMatchAll()"
                        [disabled]="
                          taxAccount.value == undefined ||
                          businessType.value == undefined ||
                          legalEntity.value == undefined
                        "
                        style="margin-left: 3px"
                      ></ap-button>

                      <!-- <ap-button [btnType]="'secondary'"  [label]="'Export'"></ap-button> -->
                      <!-- <ap-button [btnType]="'secondary'"  [label]="'Export'"></ap-button> -->
                    </div>
                  </div>
                </kendo-formfield>
              </div>
            </form>
          </div>
          <!-- Popup for Auto match sure or not -->

          <!-- Popup for Auto match sure or not -->

          <kendo-dialog
            title="Auto match"
            *ngIf="openPopup"
            (click)="cancelClick()"
            [height]="'auto'"
            [width]="500"
          >
            <div style="display: flex">
              <p>
                <span
                  class="Appkit4-icon icon-circle-warning-outline"
                  style="font-size: 24px; color: #d04a02"
                ></span>
              </p>
              <p style="margin-left: 10px; font-size: 14px">
                You have selected to automatically match all transactions that
                have not previously been allocated from the selected tax
                account.
                <br />
                <br />
                All transactions that have been identified as contras by auto
                match should be reviewed.
                <br />
                <br />
                Are you sure you wish to continue?
              </p>
            </div>

            <div class="clickToActions" style="padding: 10px 0px 0px 0px">
              <ap-button
                [btnType]="'secondary'"
                [label]="'No'"
                (click)="cancelClick()"
                class="cancelButton"
                style="width: 100px"
              ></ap-button>
              <ap-button
                [btnType]="'primary'"
                [label]="'Yes'"
                themeColor="primary"
                (click)="gotoAutomatchDataLayout()"
                style="margin-right: 6px"
                class="okButton"
              ></ap-button>
            </div>
          </kendo-dialog>
          <!-- Popup end for auto match popup  -->
          <!-- div for auto match mapping data  -->

          <div *ngIf="isviewAutomatch">
            <h5 *ngIf="isLoading">Loading Data....</h5>
            <div *ngIf="isLoading">
              <kendo-chunkprogressbar
                [chunkCount]="10"
                [min]="-10"
                [max]="10"
                [indeterminate]="true"
                [value]="30"
                style="width: 100%"
              ></kendo-chunkprogressbar>
            </div>

            <div class="subHeader">Auto match transactions</div>
            <mat-card class="userCard" style="padding: 0px">
              <mat-card-title
                style="padding: 0px; margin-bottom: 0px; margin-top: 5px"
                ><b> Select auto match data items </b></mat-card-title
              >

              <mat-card-content>
                <div>
                  <form class="k-form" [formGroup]="automatchMappingForm">
                    <div style="display: flex; padding: 0px">
                      <div style="width: 51%">
                        <mat-card-title style="padding: 0px"
                          ><b> Transaction data items </b></mat-card-title
                        >
                      </div>
                      <div>
                        <mat-card-title style="padding: 0px"
                          ><b> Match by </b></mat-card-title
                        >
                      </div>
                    </div>

                    <kendo-formfield style="margin-top: 0px">
                      <kendo-label>
                        <kendo-grid
                          style="width: 45%; height: 350px"
                          [kendoGridBinding]="automatchcolumnsList"
                          [kendoGridSelectBy]="selectedautomatchColumn"
                          [(selectedKeys)]="mySelectedautomatchColumn"
                          [resizable]="true"
                          [selectable]="true"
                          [hideHeader]="true"
                        >
                          <kendo-grid-column
                            [headerStyle]="{
                              'font-weight': 'bold',
                              'text-align': 'center',
                              color: '#d04a02'
                            }"
                            field="Name"
                          ></kendo-grid-column>
                        </kendo-grid>

                        <div style="position: absolute; top: 35%; left: 46.3%">
                          <ap-button
                            kendoButton
                            (click)="automatchforwardScope()"
                            [btnType]="'secondary'"
                            style="
                              width: 50px;
                              height: 37px;
                              margin-bottom: 5px;
                            "
                            ><span class="material-icons"
                              >keyboard_arrow_right</span
                            ></ap-button
                          >
                          <ap-button
                            kendoButton
                            (click)="automatchbackwardScope()"
                            [btnType]="'secondary'"
                            style="
                              width: 50px;
                              height: 37px;
                              margin-bottom: 5px;
                            "
                            ><span class="material-icons"
                              >keyboard_arrow_left</span
                            ></ap-button
                          >
                          <ap-button
                            kendoButton
                            (click)="automatchforwardScopeAll()"
                            [btnType]="'secondary'"
                            style="
                              width: 50px;
                              height: 37px;
                              margin-bottom: 5px;
                            "
                            ><span class="material-icons"
                              >keyboard_double_arrow_right</span
                            ></ap-button
                          >
                          <ap-button
                            kendoButton
                            (click)="automatchbackwardScopeAll()"
                            [btnType]="'secondary'"
                            style="
                              width: 50px;
                              height: 37px;
                              margin-bottom: 5px;
                            "
                            ><span class="material-icons"
                              >keyboard_double_arrow_left</span
                            ></ap-button
                          >
                        </div>

                        <kendo-grid
                          #automatchMappingGrid
                          style="
                            width: 45%;
                            height: 350px;
                            position: absolute;
                            top: 11%;
                            left: 50.5%;
                            margin-left: 5px;
                          "
                          [kendoGridBinding]="automatchMappingList"
                          [kendoGridSelectBy]="selectedautomatchColumn"
                          [(selectedKeys)]="mySelectedautomatchColumnMapping"
                          [resizable]="true"
                          [selectable]="true"
                          [hideHeader]="true"
                          [rowClass]="rowClass"
                        >
                          <kendo-grid-column
                            [headerStyle]="{
                              'font-weight': 'bold',
                              'text-align': 'center',
                              color: '#d04a02'
                            }"
                            field="Name"
                          ></kendo-grid-column>
                        </kendo-grid>

                        <div style="position: absolute; top: 42%; right: 0%">
                          <ap-button
                            kendoButton
                            (click)="moveup()"
                            [btnType]="'secondary'"
                            style="
                              width: 50px;
                              height: 37px;
                              margin-bottom: 5px;
                            "
                            ><span class="material-icons"
                              >keyboard_arrow_up</span
                            ></ap-button
                          >
                          <ap-button
                            kendoButton
                            (click)="movedown()"
                            [btnType]="'secondary'"
                            style="
                              width: 50px;
                              height: 37px;
                              margin-bottom: 5px;
                            "
                            ><span class="material-icons"
                              >keyboard_arrow_down</span
                            ></ap-button
                          >
                        </div>
                      </kendo-label>
                    </kendo-formfield>

                    <div style="margin-top: 1%">
                      <ap-checkbox
                        [checked]="true"
                        (onChange)="matchCheckBox($event)"
                      >
                        <span
                          >Auto match remaining transactions using only GL
                          account and Absolute amount</span
                        >
                      </ap-checkbox>
                      <ap-checkbox
                        (onChange)="excelCheckBox($event)"
                        formControlName="excelCheck"
                      >
                        <span style="margin-bottom: 0px"
                          >Output auto match results to Excel</span
                        >
                      </ap-checkbox>
                    </div>
                  </form>
                </div>
                <div
                  class="clickToActions fixed-bottom"
                  style="padding: 10px 0px 0px 0px; right: 1.5%; bottom: 1%"
                >
                  <ap-button
                    kendoButton
                    [btnType]="'secondary'"
                    [label]="'Close'"
                    (click)="close()"
                    class="cancelButton"
                    style="margin-left: 7px; width: 100px"
                  ></ap-button>

                  <ap-button
                    kendoButton
                    [btnType]="'primary'"
                    [label]="'Auto match'"
                    style="margin-left: 7px"
                    (onClick)="processingfile()"
                  ></ap-button>
                  <!-- (onClick)="automatchalltransactions()"-->
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <kendo-window
            *ngIf="opened"
            [draggable]="isDraggable"
            (close)="openClose(false)"
            [width]="300"
            [height]="300"
            [(state)]="windowState"
            title="Choose columns"
            style="position: fixed !important; margin-top: 4% !important"
          >
            <li *ngFor="let column of columns" class="">
              <input
                type="checkbox"
                id="{{ column }}"
                kendoCheckBox
                [checked]="!isHidden(column)"
                (change)="hideColumn(column)"
              />
              <label class="k-checkbox-label" for="{{ column }}">{{
                column
              }}</label>
            </li>
            &nbsp;
            <ap-button
              [btnType]="'primary'"
              [label]="'Save'"
              themeColor="primary"
              (click)="saveColumn()"
              style="margin-right: 6px"
            ></ap-button>
          </kendo-window>
          <!-- Popup end for auto match popup  -->
          <!-- div for auto match mapping data  -->
        </div>
      </div>
    </div>
  </mat-card-content></mat-card
>
