import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import {
  GroupDescriptor,
  GroupResult,
  groupBy,
} from '@progress/kendo-data-query';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { AdministrationService } from 'src/app/common/services/administration/administration.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.scss'],
})
export class PreferenceComponent implements OnInit {
  public selectedAutoAllocation: boolean = false;
  public selectedTransactionBalance: boolean = false;
  public selectedComplexTax: boolean = false;
  public selectedPeriodAlias: boolean = false;
  public selectedCurrency: any | undefined;
  public selectedLineItem: boolean = false;
  public selecteddisplayFormat: any | undefined;
  public selecteddisplayMatched: boolean = false;
  public selecteddisplayMoved: boolean = false;
  public selectedAccountType: boolean = false;
  public selectedChangeData: boolean = false;
  public preferenceSetting: any = [];
  public preferenceForm!: FormGroup;
  public preferenceFormDisabled = true;
  public currencyFormatList: any;
  public accountType: any = 'Debit';
  public dashBoardChangeType: any = 'true';
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  constructor(
    private adminService: AdministrationService,
    private router: Router,
    private dialogService: DialogService,
    public toastr: ToastrManager,
    private fb: FormBuilder,
    public myGlobalObject: MyGlobalObject,
    private _location: Location
  ) {
    this.preferenceForm = this.fb.group({
      displayFormat: ['', Validators.required],
      debit: [''],
      credit: [''],
      matchedDisplay: [''],
      movedDisplay: [''],
      lineitem: [''],
      balanceLevel: [''],
      periodAlias: [''],
      complexTax: [''],
      transactionBalance: [''],
      autoAllocation: [''],
      gridAccount: [''],
      pieChartAccount: [''],
      dashBoardChangeType: [''],
      currencyFormat: ['', Validators.required],
    });
  }
  public displayFormatList: any = [
    { label: '-1,000.00', value: '-1,000.00' },
    { label: '(1,000.00)', value: '(1,000.00)' },
  ];

  public transactionlist: any[] = [
    { label: 'Display matched transactions', value: '0', selected: false },
    { label: 'Display moved transactions', value: '1', selected: false },
  ];

  ngOnInit(): void {
    this.getAllCurrencyWithCode();
  }

  public handleDisplayFormatChange(event: any) {
    this.selecteddisplayFormat = event.label;
    this.preferenceFormDisabled = false;
  }

  public handlecurrencyFormatChange(event: any) {
    this.selectedCurrency = event.CurrencyId;
    this.preferenceFormDisabled = false;
  }

  public selectAccount(event: any) {
    this.selectedAccountType = event.checkboxState;
    this.preferenceFormDisabled = false;
  }
  public selectDashBoardAccount(event: any) {
    this.selectedChangeData = event.checkboxState;
    this.preferenceFormDisabled = false;
  }

  public onDisplayMatchedchange(event: any) {
    this.selecteddisplayMatched = event.checkboxState;
    this.preferenceFormDisabled = false;
  }

  public onDisplayMovedchange(event: any) {
    this.selecteddisplayMoved = event.checkboxState;
    this.preferenceFormDisabled = false;
  }

  public onLineItemchange(event: any) {
    this.selectedLineItem = event.checkboxState;
  }

  public onPeriodAliaschange(event: any) {
    this.selectedPeriodAlias = event.checkboxState;
  }

  public onComplexTaxchange(event: any) {
    this.selectedComplexTax = event.checkboxState;
  }

  public onZeropTransactionchange(event: any) {
    this.selectedTransactionBalance = event.checkboxState;
    this.preferenceFormDisabled = false;
  }

  public onAutoAllocationchange(event: any) {
    this.selectedAutoAllocation = event.checkboxState;
    this.preferenceFormDisabled = false;
  }

  public closeButtonClick() {
    this.router.navigate(['/analysisreview']);
  }

  getAllCurrencyWithCode(): void {
    this.adminService.getAllCurrencyWithCode().subscribe(
      (response) => {
        this.currencyFormatList = response;
        this.getPreferencesSettings();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  savePreferences() {
    let preference: any = {
      DisplayFormat: this.selecteddisplayFormat,
      CreditPositive: this.accountType,
      DisplayMatchedTransactions: this.selecteddisplayMatched ?? false,
      DisplayMovedTransactions: this.selecteddisplayMoved ?? false,
      CurrencyId: this.selectedCurrency,
      ZeroTransactionBalance: this.selectedTransactionBalance ?? false,
      AutoAllocation: this.selectedAutoAllocation ?? false,
      CurrencySymbol: '',
      IsDisplayPieChart: this.dashBoardChangeType === 'true',
    };
    this.adminService.SavePreferences(preference).subscribe(
      (res) => {
        if (res == 'Preferences Save Successfully') {
          this.toastr.successToastr('Preferences save successfully', 'Success');
          this.getPreferencesSettings();
        }
      },
      (error) => {
        this.toastr.errorToastr('Failed to Save Preferences', 'Error');
        console.log(error);
      });
    window.location.reload();
    this._location.back();

  }
  getPreferencesSettings(): void {
    this.adminService.getPreferencesSettings().subscribe(
      (response) => {
        if (response != undefined && response != null) {
          this.myGlobalObject.clientPreferences = response;
          this.preferenceSetting = response;
          this.selectedAutoAllocation = this.preferenceSetting.AutoAllocation;
          this.selectedTransactionBalance =
            this.preferenceSetting.ZeroTransactionBalance;
          this.selectedCurrency = this.preferenceSetting.CurrencyId;
          this.selecteddisplayFormat = this.preferenceSetting.DisplayFormat;
          this.selecteddisplayMatched =
            this.preferenceSetting.DisplayMatchedTransactions;
          this.selecteddisplayMoved =
            this.preferenceSetting.DisplayMovedTransactions;
          this.selectedAccountType = this.preferenceSetting.CreditPositive;
          this.accountType = this.preferenceSetting.CreditPositive;
          this.selectedChangeData = this.preferenceSetting.IsDisplayPieChart;
          this.dashBoardChangeType =
            this.preferenceSetting.IsDisplayPieChart.toString();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
