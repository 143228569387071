import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  ActionsLayout,
  DialogCloseResult,
  DialogRef,
  DialogService,
  WindowState,
} from '@progress/kendo-angular-dialog';
import { LegalEntity } from 'src/app/_models/legalEntity';
import {
  SelectEvent,
  TabCloseEvent,
  TabStripComponent,
} from '@progress/kendo-angular-layout';
import { JournalService } from 'src/app/common/services/journal/journal-service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { BusinessType } from 'src/app/_models/businessType';
import { JournalCategory } from 'src/app/_models/journalCategory';
import { FiscalYear } from 'src/app/_models/fiscalYear';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { SupplementayTaxAccount, TaxAccount } from 'src/app/_models/taxAccount';
import { GlTaxAccount } from 'src/app/_models/glTaxAccount';
import {
  AddEvent,
  GridDataResult,
  CellClickEvent,
  CellCloseEvent,
  SaveEvent,
  CancelEvent,
  GridComponent,
  RemoveEvent,
  PageChangeEvent,
  SelectionEvent,
} from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { anyChanged, Keys } from '@progress/kendo-angular-common';
import { map } from 'rxjs/operators';
import {
  SaveMovementModel,
  SupplementaryCalculationFileMappingDTO,
  TransactionList,
} from 'src/app/_models/saveMovementModel';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  ChunkSettings,
  FileRestrictions,
  SuccessEvent,
  UploadEvent,
} from '@progress/kendo-angular-upload';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AdjustmentDetailResponse } from 'src/app/_models/adjustmentDetailResponse';
import { EditJournalResponse } from 'src/app/_models/editJournalResponse';
import { EditServiceTransaction } from 'src/app/common/services/journal/edit.serviceTransaction';
import { AddTransaction } from 'src/app/_models/addTransaction';
import { NgClass } from '@angular/common';
import { SharedserviceMoveService } from 'src/app/common/services/moveTransactions/sharedservice-move.service';
import { offlineReferralGridColumnSetting } from 'src/app/_models/offlineReferralGridColumnSetting';
import { GridSetting } from 'src/app/_models/gridSetting';
import { OfflineReferralService } from 'src/app/common/services/offlineReferral/offlineReferral-service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
@Component({
  selector: 'app-manage-movement-journal',
  templateUrl: './manage-movement-journal.component.html',
  styleUrls: ['./manage-movement-journal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageMovementJournalComponent implements OnInit {
  addJournalForm!: FormGroup;
  businessTypes: BusinessType[] = [];
  businessType!: BusinessType;
  legalEntities: LegalEntity[] = [];
  journalCategories: JournalCategory[] = [];
  // periods: string[] = [];
  public actionsLayout: ActionsLayout = 'normal';
  isLoading: boolean = false;
  fiscalYears: FiscalYear[] = [];
  fiscalYear!: FiscalYear;
  fiscalYearValue: number = 0;
  value: Date = new Date();
  taxAccounts: TaxAccount[] = [];
  taxAccountTrs: TaxAccount[] = [];
  filteredTaxAccounts: TaxAccount[] = [];
  filteredTaxAccountTrs: TaxAccount[] = [];
  glTaxAccounts: GlTaxAccount[] = [];
  // glTaxAccountTrs: GlTaxAccount[] = [];
  filteredGlTaxAccounts: GlTaxAccount[] = [];
  filteredGlTaxAccountTrs: GlTaxAccount[] = [];
  businessTypeId: number = 0;
  legalEntityId: number = 0;

  // gridSno: number = 0;

  bckTransactions: AddTransaction[] = [];
  selectedTaxAccountCodeId: any;
  selectedDestinationTaxAccountText: any;

  public view!: Observable<GridDataResult>;
  public viewEdit!: Observable<GridDataResult>;
  public formGroup!: FormGroup;
  public gridState: State = {
    sort: [{ field: 'DataRow', dir: 'asc' }],
    skip: 0,
    take: 100,
  };

  public sortOrder: SortDescriptor[] = [{ field: 'DataRow', dir: 'asc' }];

  public changes = {};
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  addTransactionAmt!: AddTransaction;
  isTaxAmtData: boolean = false;
  saveMovement!: SaveMovementModel;
  // saveAdj: any;
  // saveAdjTotal: any;
  // totalAmt: number = 0;
  isGridEdited: boolean = false;
  uploadSaveUrl: string = '';
  uploadRemoveUrl: string = '';
  fileName: string = '';
  fileUid: string = '';

  private baseUrl: string = '';
  private apifolder: string = '';
  private selectedClient: any;

  supplementayTaxAccounts: SupplementayTaxAccount[] = [];
  supplementayFilteredTaxAccounts: SupplementayTaxAccount[] = [];
  supplementayTaxAccount!: SupplementayTaxAccount;
  supplementaryCalculationFileMappingDTO!: SupplementaryCalculationFileMappingDTO;
  isFileUploaded: boolean = false;
  editJournallResponses: EditJournalResponse[] = [];
  editJournallResponse!: EditJournalResponse;
  adjustmentDetailResponse!: AdjustmentDetailResponse;
  adjustmentDetailResponses: AdjustmentDetailResponse[] = [];
  mode: string = '';

  public gridView!: GridDataResult;
  public mySelection: number[] = [];
  public pageSize = 10;
  public skip = 0;

  public viewAddTransaction!: Observable<GridDataResult>;

  private gridDataResult!: GridDataResult;

  addTransactionResponse: AddTransaction[] = [];
  addTransaction!: AddTransaction;
  editTrans: AddTransaction[] = [];

  addTransactionFilter: AddTransaction[] = [];

  IsSelectAll: boolean = false;

  originalUnallocatedAmount: number = 0;
  originalJournalAmount: number = 0;

  addTransactionFinal!: AddTransaction;
  addTransactionsFinal: AddTransaction[] = [];
  addTransactionsFinalDelete: AddTransaction[] = [];
  addTransactionsFinalFilter: AddTransaction[] = [];

  isTransationAddClick: boolean = false;
  isTransationEditClick: boolean = false;
  isGridVisible: boolean = true;

  gridSelectedRow: any;
  gridRow: any;

  bckAddTransactionsFinal: AddTransaction[] = [];
  isNewButtonClick: boolean = false;

  saveTransactions: TransactionList[] = [];

  saveTransaction!: TransactionList;
  saveMove: any;

  isEditLoaded: boolean = false;

  opened: boolean = false;
  isDraggable: boolean = true;
  public windowState: WindowState = 'default';

  public hiddenColumns: string[] = [];

  public openClose(isOpened: boolean): void {
    this.opened = isOpened;
  }

  callingType: any = '';
  callingBusinessTypeId: number = 0;
  callingLegalEntityId: number = 0;
  callingGlAccount: number = 0;
  callingFromTaxAccount: number = 0;
  callingToTaxAccount: number = 0;
  callingTransactions: string[] = [];
  callingTransactionData: any[] = [];
  addTransactionCallingType: AddTransaction[] = [];
  addTransactionCallingTypeFinal: AddTransaction[] = [];

  isGridDataLoaded: boolean = false;

  @ViewChild('tabstrip')
  public tabstrip!: TabStripComponent;

  public pounds = Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  public gridColumns: offlineReferralGridColumnSetting[] = [];
  gridColumn!: offlineReferralGridColumnSetting;
  gridSettings: GridSetting[] = [];

  public hideColumn(columnName: string): void {
    const hiddenColumns = this.hiddenColumns;

    if (!this.isHidden(columnName)) {
      hiddenColumns.push(columnName);
    } else {
      hiddenColumns.splice(hiddenColumns.indexOf(columnName), 1);
    }
  }

  public isHidden(columnName: string): boolean {
    return this.hiddenColumns.indexOf(columnName) > -1;
  }

  constructor(
    private fb: FormBuilder,
    private journalService: JournalService,
    private sharedService: SharedserviceMoveService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public editService: EditServiceTransaction,
    // public editServiceTr: EditServiceEditTransaction,
    public toastr: ToastrManager,
    private cookieService: CookieService,
    private dialogService: DialogService,
    private OfflineReferralService: OfflineReferralService
  ) {
    // this.loadItems();
  }
  selectedBusinessType: BusinessType | undefined;
  selectedLegalEntity: LegalEntity | undefined;
  selectedTaxAccount: TaxAccount | undefined;
  selectedTaxAccountTr: TaxAccount | undefined;
  selectedGlAccount: GlTaxAccount | undefined;
  // selectedGlAccountTr: GlTaxAccount | undefined;
  debitCreditType: string = 'debit';
  withCredential: boolean = false;
  isJourneyPeriodNew: boolean = false;
  onlineAllocationId: number = 0;
  formdatafromMove: any[] = [];
  columns: string[] = [];

  ngOnInit(): void {
    this.setupForm();
    this.sharedService.getmoveFormData().subscribe((data) => {
      this.formdatafromMove.push(data);
    });
    let year: any = localStorage.getItem('selectedFiscalYear');
    this.fiscalYearValue = JSON.parse(year).FiscalYearId;
    // const selectedGl: any = this.selectedGlAccount?.StatutoryAccountCodeId;
    //this.getLatestFiscalDetail();
    this.getAllJournalCategoryList();

    if (
      this.route.snapshot.paramMap.get('CallingType') != '' &&
      this.route.snapshot.paramMap.get('CallingType') != null
    ) {
      this.callingType = this.route.snapshot.paramMap.get('CallingType');

      this.callingBusinessTypeId = this.formdatafromMove[0][0].BusinessTypeId;
      this.callingLegalEntityId = this.formdatafromMove[0][1].ID;
      this.callingFromTaxAccount = this.formdatafromMove[0][2].TaxAccountCodeId;
      this.callingToTaxAccount = this.formdatafromMove[0][3].TaxAccountCodeId;

      this.callingTransactionData = this.formdatafromMove[0][4];

      this.callingGlAccount =
        this.formdatafromMove[0][4][0].StatutoryAccountCodeId;

      this.resetSaveMovementData();

      this.saveMovement.BusinessTypeId = this.callingBusinessTypeId;
      this.saveMovement.LegalEntityId = this.callingLegalEntityId;

      this.saveMovement.TaxAccountCodeId = this.callingFromTaxAccount;
      this.saveMovement.TaxAccountCodeIdTrs = this.callingToTaxAccount;

      this.callingTransactions = [];

      this.callingTransactionData.map((adj) => {
        this.callingTransactions.push(adj._id);
      });

      this.initCallingForm();

      this.getBusinessTypeList();
      this.mode = 'Add';
    } else {
      this.getBusinessTypeList();
      if (
        this.route.snapshot.paramMap.get('id') != '' &&
        this.route.snapshot.paramMap.get('id') != null
      ) {
        this.mode = 'Edit';

        this.onlineAllocationId = Number(
          this.route.snapshot.paramMap.get('id')
        );
        this.getEditJournal(this.onlineAllocationId);
        this.getTransactionDetail(this.onlineAllocationId);
      } else {
        this.mode = 'Add';
      }
    }

    this.editService.clean();
    this.view = this.editService.pipe(
      map((data) => process(data, this.gridState))
    );
    this.editService.read();

    // this.gridSno = 1;

    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);

    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;

    this.uploadSaveUrl =
      this.baseUrl +
      this.apifolder +
      '/Journal/UploadSupplementaryToContainer?ClientdbName=' +
      this.selectedClient.DBName;

    this.uploadRemoveUrl = 'removeUrl';

    this.getGridSetting();
  }

  setupForm(): void {
    this.addJournalForm = this.fb.group({
      businessType: ['', Validators.required],
      legalEntity: ['', Validators.required],
      journalCategory: ['', Validators.required],
      journalRef: '',
      journalDescription: ['', Validators.required],
      journalNarrative: '',
      journalDate: new Date(),
      taxAccount: ['', Validators.required],
      glAccount: ['', Validators.required],
      taxAccountTr: '',
      // glAccountTr: '',
      supplementayTaxAccount: '',
      includeSupplementay: '',
      selectAll: '',
    });
  }

  initForm(): void {
    this.addJournalForm = this.fb.group({
      businessType: [this.saveMovement.BusinessTypeId, Validators.required],
      legalEntity: [this.saveMovement.LegalEntityId, Validators.required],
      journalCategory: [
        this.saveMovement.JournalCategoryId,
        Validators.required,
      ],
      journalRef: this.saveMovement.JournalRef,
      journalDescription: [
        this.saveMovement.JournalDescription,
        Validators.required,
      ],
      journalNarrative: this.saveMovement.JournalNarrative,
      journalDate: new Date(this.saveMovement.JournalDate),
      taxAccount: this.editJournallResponse.TargetTaxAccountCodeId,
      glAccount: this.editJournallResponse.StatutoryAccountCodeId,
      taxAccountTr: '',
      supplementayTaxAccount: this.editJournallResponse.TaxAccountCodeId,
      includeSupplementay: this.editJournallResponse.IncludeInExport,
      selectAll: '',
    });
  }

  initCallingForm(): void {
    this.addJournalForm = this.fb.group({
      businessType: [this.saveMovement.BusinessTypeId, Validators.required],
      legalEntity: [this.saveMovement.LegalEntityId, Validators.required],
      journalCategory: ['', Validators.required],
      journalRef: '',
      journalDescription: ['', Validators.required],
      journalNarrative: '',
      journalDate: new Date(),
      taxAccount: this.saveMovement.TaxAccountCodeId,
      glAccount: 0,
      taxAccountTr: this.saveMovement.TaxAccountCodeIdTrs,
      supplementayTaxAccount: 0,
      includeSupplementay: false,
      selectAll: '',
    });
  }

  closeButtonClick() {
    this.router.navigate(['/manageJournal']);
  }

  public onClose(e: TabCloseEvent): void {
    // console.log(e);
    // console.log(`tabClose: ${e.index} ${e.tab.title}`);
  }

  public onSelect(e: SelectEvent): void {
    // console.log(`tabSelect: ${JSON.stringify(e)}`);
  }

  getBusinessTypeList(): void {
    this.isLoading = true;
    this.journalService.getAllBusinessTypeList().subscribe(
      (response) => {
        // // console.log(response);
        this.businessTypes = response;
        // this.selectedBusinessType = this.formdatafromMove[0][0];

        if (this.businessTypes.length > 0) {
          this.selectedBusinessType = this.businessTypes[0];
          this.handleBusinessTypeChange(this.selectedBusinessType);
        }

        if (this.callingType == 'manageTransaction') {
          if (this.callingBusinessTypeId > 0) {
            let filterBusinessTypes: BusinessType[];
            filterBusinessTypes = this.businessTypes.filter(
              (f) => f.BusinessTypeId == this.callingBusinessTypeId
            );
            if (filterBusinessTypes.length > 0) {
              this.selectedBusinessType = filterBusinessTypes[0];
              this.getLegalEntityList(this.selectedBusinessType.BusinessTypeId);
            }
          }
        }

        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }

  getLegalEntityList(businessTypeId: number): void {
    this.isLoading = true;
    this.journalService
      .getAllLegalEntityByTaxChartOfAccount(businessTypeId)
      .subscribe(
        (response) => {
          this.legalEntities = response;
          this.selectedLegalEntity = this.formdatafromMove[0][1];
          // console.log(this.formdatafromMove[0][1])
          if (this.legalEntities.length > 0) {
            this.selectedLegalEntity = this.legalEntities[0];
            this.handleLegalEntityChange(this.selectedLegalEntity);
          }

          if (this.callingType == 'manageTransaction') {
            if (this.callingLegalEntityId > 0) {
              let filterLegalEntity: LegalEntity[];
              filterLegalEntity = this.legalEntities.filter(
                (f) => f.ID == this.callingLegalEntityId
              );
              if (filterLegalEntity.length > 0) {
                this.selectedLegalEntity = filterLegalEntity[0];
              }

              this.getGlTaxAccounts(
                this.callingBusinessTypeId,
                this.callingLegalEntityId,
                this.callingGlAccount
              );

              this.getTaxAccountTrs(
                this.callingBusinessTypeId,
                this.callingLegalEntityId
              );

              this.getTransactionDetailAgainstCallingType(
                this.callingLegalEntityId,
                this.fiscalYearValue,
                this.callingGlAccount,
                0,
                this.callingTransactions
              );
            }
          }

          if (this.mode == 'Edit') {
            this.getGlTaxAccounts(
              this.editJournallResponse.BusinessTypeId,
              this.editJournallResponse.LegalEntityID,
              this.editJournallResponse.StatutoryAccountCodeId
            );

            this.getTaxAccounts(
              this.editJournallResponse.BusinessTypeId,
              this.editJournallResponse.LegalEntityID
            );

            this.getTaxAccountTrs(
              this.editJournallResponse.BusinessTypeId,
              this.editJournallResponse.LegalEntityID
            );
          }

          this.isLoading = false;
        },
        (error) => {
          // console.log(error);
          this.isLoading = false;
        }
      );
  }

  getAllJournalCategoryList(): void {
    this.isLoading = true;
    this.journalService.getAllJournalCategoryList().subscribe(
      (response) => {
        // // console.log(response);
        this.journalCategories = response;
        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }

  getLatestFiscalDetail(): void {
    this.isLoading = true;
    this.journalService.getLatestFiscalDetail().subscribe(
      (response) => {
        // // console.log(response);
        this.fiscalYear = response;
        this.fiscalYearValue = this.fiscalYear.FiscalYearId;
        // // console.log(this.fiscalYear);
        // this.getPeriodByFiscalYear(this.fiscalYearValue);
        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }

  getTaxAccounts(businessTypeId: number, legalEntityID: number): void {
    this.isLoading = true;
    this.journalService.GetTaxAccounts(businessTypeId, legalEntityID).subscribe(
      (response) => {
        // // console.log(response);
        this.taxAccounts = response;

        if (this.callingType == 'manageTransaction') {
          if (this.callingFromTaxAccount > 0) {
            let filteredTaxAccounts: TaxAccount[];
            filteredTaxAccounts = this.taxAccounts.filter(
              (f) => f.TaxAccountCodeId == this.callingFromTaxAccount
            );
            if (filteredTaxAccounts.length > 0) {
              this.selectedTaxAccount = filteredTaxAccounts[0];

              //Need to add back
              // let filteredGlTaxAccountsCalling: GlTaxAccount[];
              // filteredGlTaxAccountsCalling = this.glTaxAccounts.filter(
              //   (s: GlTaxAccount) =>
              //     s.TaxAccountCodeId ==
              //     this.selectedTaxAccount?.TaxAccountCodeId
              // );
              // if (filteredGlTaxAccountsCalling.length > 0) {
              //   this.glTaxAccounts = filteredGlTaxAccountsCalling;
              // }
            }
          }
        }
        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }

  getGlTaxAccounts(
    businessTypeId: number,
    legalEntityID: number,
    StatutoryAccountCodeId: number
  ): void {
    this.isLoading = true;
    this.journalService
      .GetGlTaxAccounts(businessTypeId, legalEntityID)
      .subscribe(
        (response) => {
          this.glTaxAccounts = response;
          if (this.callingType == 'manageTransaction') {
            this.getTaxAccounts(
              this.callingBusinessTypeId,
              this.callingLegalEntityId
            );
          }

          if (StatutoryAccountCodeId > 0) {
            this.filteredGlTaxAccounts = this.glTaxAccounts.filter(
              (s: GlTaxAccount) =>
                s.StatutoryAccountCodeId == StatutoryAccountCodeId
            );
            if (this.filteredGlTaxAccounts.length > 0) {
              this.selectedGlAccount = this.filteredGlTaxAccounts[0];

              this.handleGlAccountChange(this.selectedGlAccount, legalEntityID);
            }
          }

          this.isLoading = false;
        },
        (error) => {
          // console.log(error);
          this.isLoading = false;
        }
      );
  }

  getTaxAccountTrs(businessTypeId: number, legalEntityID: number): void {
    this.isLoading = true;
    this.journalService.GetTaxAccounts(businessTypeId, legalEntityID).subscribe(
      (response) => {
        // console.log(response);
        this.taxAccountTrs = response;

        if (this.callingType == 'manageTransaction') {
          if (this.callingToTaxAccount > 0) {
            let filteredTaxAccountTrs: TaxAccount[];
            filteredTaxAccountTrs = this.taxAccountTrs.filter(
              (f) => f.TaxAccountCodeId == this.callingToTaxAccount
            );
            if (filteredTaxAccountTrs.length > 0) {
              this.selectedTaxAccountTr = filteredTaxAccountTrs[0];
            }
          }
        }
        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }

  handleBusinessTypeChange(businessType: BusinessType): void {
    this.selectedBusinessType = businessType;
    this.legalEntities = [];
    this.selectedLegalEntity = undefined;
    this.selectedTaxAccount = undefined;
    this.selectedTaxAccountTr = undefined;
    this.selectedGlAccount = undefined;
    // this.selectedGlAccountTr = undefined;

    this.taxAccounts = [];
    this.glTaxAccounts = [];
    // // console.log(businessType);
    if (businessType != undefined) {
      this.businessTypeId = businessType.BusinessTypeId;
      if (this.callingType != 'manageTransaction') {
        this.getLegalEntityList(businessType.BusinessTypeId);
      }
    }
  }

  handleLegalEntityChange(legalEntitity: LegalEntity): void {
    this.selectedLegalEntity = legalEntitity;
    this.selectedTaxAccount = undefined;
    this.selectedTaxAccountTr = undefined;
    this.selectedGlAccount = undefined;
    // this.selectedGlAccountTr = undefined;
    this.taxAccounts = [];
    this.glTaxAccounts = [];
    this.legalEntityId = legalEntitity.ID;
    if (legalEntitity != undefined) {
      this.getTaxAccounts(this.businessTypeId, legalEntitity.ID);
      this.getGlTaxAccounts(this.businessTypeId, legalEntitity.ID, 0);
      this.getTaxAccountTrs(this.businessTypeId, legalEntitity.ID);
      // this.getGlTaxAccountTrs(this.businessTypeId, legalEntitity.ID, 0);
    }
  }

  handleJournalPeriodChange(period: string) {
    if (period == 'New') {
      this.isJourneyPeriodNew = true;
    } else {
      this.isJourneyPeriodNew = false;
    }
  }

  handleGlAccountChange(glAccount: GlTaxAccount, legalEntityId: number): void {
    if (glAccount != undefined) {
      this.selectedGlAccount = glAccount;
      this.selectedTaxAccount = undefined;
      this.filteredTaxAccounts = [];
      if (glAccount != undefined) {
        this.filteredTaxAccounts = this.taxAccounts.filter(
          (s: TaxAccount) => s.TaxAccountCodeId == glAccount.TaxAccountCodeId
        );
        if (this.filteredTaxAccounts.length > 0) {
          this.selectedTaxAccount = this.filteredTaxAccounts[0];
        } else {
          this.selectedTaxAccount = undefined;
        }

        if (legalEntityId == 0) {
          legalEntityId = Number(this.selectedLegalEntity?.ID);
        }

        if (this.mode != 'Edit') {
          this.getAddTransactionDetail(
            legalEntityId,
            this.fiscalYearValue,
            glAccount.StatutoryAccountCodeId,
            0
          );
        }
      }
    } else {
      this.selectedTaxAccount = undefined;
    }
  }

  // handleGlAccountTrChange(glAccount: GlTaxAccount): void {
  //   if (glAccount != undefined) {
  //     // this.selectedGlAccountTr = glAccount;
  //     this.selectedTaxAccountTr = undefined;
  //     this.filteredTaxAccountTrs = [];
  //     if (glAccount != undefined) {
  //       this.filteredTaxAccountTrs = this.taxAccountTrs.filter(
  //         (s: TaxAccount) => s.TaxAccountCodeId == glAccount.TaxAccountCodeId
  //       );
  //       if (this.filteredTaxAccountTrs.length > 0) {
  //         this.selectedTaxAccountTr = this.filteredTaxAccountTrs[0];
  //       }
  //     }
  //   }
  // }

  handleTaxAccountChange(taxAccount: TaxAccount): void {
    this.selectedGlAccount = undefined;
    this.selectedTaxAccount = taxAccount;

    this.filteredGlTaxAccounts = [];
    if (taxAccount != undefined) {
      this.filteredGlTaxAccounts = this.glTaxAccounts.filter(
        (s: GlTaxAccount) => s.TaxAccountCodeId == taxAccount.TaxAccountCodeId
      );
      if (this.filteredGlTaxAccounts.length > 0) {
        this.glTaxAccounts = this.filteredGlTaxAccounts;
      }
    } else {
      this.getGlTaxAccounts(this.businessTypeId, this.legalEntityId, 0);
    }
  }

  handleTaxAccountTrChange(taxAccount: TaxAccount): void {
    // this.selectedGlAccountTr = undefined;
    this.selectedTaxAccountTr = taxAccount;
    this.filteredGlTaxAccountTrs = [];
  }

  resetSaveMovementData(): void {
    this.saveMovement = {
      OnlineAllocationId: 0,
      FiscalYear: 0,
      BusinessTypeId: 0,
      LegalEntityId: 0,
      JournalRef: '',
      JournalDescription: '0',
      JournalNarrative: '0',
      JournalCategoryId: 0,
      // Period: 0,
      JournalDate: new Date(),
      Type: '',
      StatutoryAccountCodeId: 0,
      TaxAccountCodeId: 0,
      TaxAccountCodeIdTrs: 0,
      transactionList: [],
      supplementaryCalculationFileMappingDTO:
        this.supplementaryCalculationFileMappingDTO,
    };
  }

  backClick(): void {
    this.router.navigate(['/manageSelectJournal']);
  }

  public format: FormatSettings = {
    displayFormat: 'dd/MM/yyyy',
    inputFormat: 'dd/MM/yy',
  };

  saveJournal(): void {
    this.isLoading = true;
    this.resetSaveMovementData();
    const formObject = this.addJournalForm.getRawValue();
    // console.log('formObject');
    // console.log(formObject);

    if (this.mode == 'Add') {
      this.saveMovement.OnlineAllocationId = 0;
    } else {
      this.saveMovement.OnlineAllocationId = this.onlineAllocationId;
    }

    this.saveMovement.FiscalYear = this.fiscalYearValue;

    if (formObject.businessType.BusinessTypeId == undefined) {
      this.saveMovement.BusinessTypeId = formObject.businessType;
    } else {
      this.saveMovement.BusinessTypeId = formObject.businessType.BusinessTypeId;
    }

    if (formObject.legalEntity.ID == undefined) {
      this.saveMovement.LegalEntityId = formObject.legalEntity;
    } else {
      this.saveMovement.LegalEntityId = formObject.legalEntity.ID;
    }

    this.saveMovement.JournalRef = formObject.journalRef;
    this.saveMovement.JournalDescription = formObject.journalDescription;
    this.saveMovement.JournalNarrative = formObject.journalNarrative;
    this.saveMovement.StatutoryAccountCodeId =
      formObject.glAccount.StatutoryAccountCodeId;

    if (this.mode == 'Add') {
      this.saveMovement.TaxAccountCodeId =
        formObject.taxAccount.TaxAccountCodeId;
    } else {
      this.saveMovement.TaxAccountCodeId =
        formObject.taxAccount.TaxAccountCodeId;
    }

    if (formObject.journalCategory.JournalCategoryId == undefined) {
      this.saveMovement.JournalCategoryId = formObject.journalCategory;
    } else {
      this.saveMovement.JournalCategoryId =
        formObject.journalCategory.JournalCategoryId;
    }

    this.saveMovement.JournalDate = formObject.journalDate;
    this.saveMovement.Type = 'M';

    this.saveTransactions = [];

    this.addTransactionsFinal.map((val) => {
      this.saveTransaction = {
        DataRow: 0,
        Mode: '',
        ShortDescription: '',
        TransactionTypeDescription: '',
        JournalAmount: 0,
        AllocatedAmount: 0,
        UnallocatedAmount: 0,
        Type: '',
        _id: 0,
        fk_StatutoryAccountCodeId: 0,
        Amount: 0,
        Description: '',
        TaxAccountCodeId: 0,
        AbsoluteAmount: 0,
        DebitCredit: '',
        fk_ProposalId: 0,
        // fk_TaxAccountCodeId: 0,
        fk_JournalTransactionTypeId: 0,
        OnlineAllocationElementId: 0,
        JournalId: 0,
        TaxAccountDescription: '0',
        TaxAccount: '0',
        SourceTaxAccount: '0',
        Key: '0',
        OnlineAllocation: '0',
        Transaction: '0',
        Remove: '0',
        TransactionType: '0',
        LegalEntity: '0',
        FiscalYear: '0',
        DocumentType: '0',
        DocumentNo: '0',
        TransactionId: '0',
        DocumentDate: '0',
        PostingDate: '0',
        TransactionDate: '0',
        Period: '0',
        GLAccountNo: '0',
        GLAccountDescription: '0',
        Currency: '0',
        CostCentre: '0',
        Vendor: '0',
        Division: '0',
        Product: '0',
        Project: '0',
        AssetCode: '0',
        VATCode: '0',
        PurchaseOrder: '0',
        Order: '0',
        WBSElement: '0',
        ProfitCentre: '0',
        MatchID: '0',
        Custom3: '0',
        Custom4: '0',
        Custom5: '0',
        Custom6: '0',
        Custom7: '0',
      };
      // // console.log(val);
      this.saveMove = val;
      this.saveTransaction.DataRow = this.saveMove.DataRow;
      this.saveTransaction.Mode = this.saveMove.Mode;
      this.saveTransaction.ShortDescription = this.saveMove.ShortDescription;
      this.saveTransaction.TransactionTypeDescription =
        this.saveMove.TransactionTypeDescription;
      this.saveTransaction.JournalAmount = this.saveMove.JournalAmount;
      this.saveTransaction.AllocatedAmount = this.saveMove.AllocatedAmount;
      this.saveTransaction.UnallocatedAmount = this.saveMove.UnallocatedAmount;
      this.saveTransaction._id = this.saveMove._id;
      this.saveTransaction.fk_StatutoryAccountCodeId =
        this.saveMove.fk_StatutoryAccountCodeId;
      this.saveTransaction.Amount = this.saveMove.Amount;
      this.saveTransaction.Description = this.saveMove.Description;
      this.saveTransaction.TaxAccountCodeId = this.saveMove.TaxAccountCodeId;
      this.saveTransaction.AbsoluteAmount = this.saveMove.AbsoluteAmount;
      this.saveTransaction.DebitCredit = this.saveMove.DebitCredit;
      this.saveTransaction.fk_ProposalId = this.saveMove.fk_ProposalId;
      // this.saveTransaction.fk_TaxAccountCodeId =
      //   this.saveMove.fk_TaxAccountCodeId;
      this.saveTransaction.fk_JournalTransactionTypeId = null;
      this.saveTransaction.OnlineAllocationElementId =
        this.saveMove.OnlineAllocationElementId;
      this.saveTransaction.Type = 'm';
      this.saveTransaction.JournalId = this.saveMove.JournalId;

      this.saveTransactions.push(this.saveTransaction);
    });

    // console.log(this.saveTransactions);

    this.saveMovement.transactionList = this.saveTransactions;

    if (this.fileName != '' && this.fileName != 'null.null') {
      if (
        formObject.supplementayTaxAccount == undefined ||
        formObject.supplementayTaxAccount == ''
      ) {
        this.toastr.errorToastr(
          'Please select associated tax account.',
          'Error'
        );
        this.isLoading = false;
        return;
      }

      this.supplementayFilteredTaxAccounts = [];

      if (formObject.supplementayTaxAccount.TaxAccountCodeId == undefined) {
        this.supplementayFilteredTaxAccounts =
          this.supplementayTaxAccounts.filter(
            (f) => f.TaxAccountCodeId == formObject.supplementayTaxAccount
          );
      } else {
        this.supplementayFilteredTaxAccounts =
          this.supplementayTaxAccounts.filter(
            (f) =>
              f.TaxAccountCodeId ==
              formObject.supplementayTaxAccount.TaxAccountCodeId
          );
      }

      if (this.supplementayFilteredTaxAccounts.length == 0) {
        this.toastr.errorToastr(
          'Please select associated tax account.',
          'Error'
        );
        this.isLoading = false;
        return;
      }

      this.supplementaryCalculationFileMappingDTO = {
        SupplementaryCalculationFileMappingId: 0,
        Name: '',
        FileGUID: '',
        IncludeInExport: true,
        TaxAccountCodeId: 0,
        IsStandAloneAttachment: false,
      };
      this.supplementaryCalculationFileMappingDTO.SupplementaryCalculationFileMappingId = 0;
      this.supplementaryCalculationFileMappingDTO.FileGUID = this.fileUid;
      this.supplementaryCalculationFileMappingDTO.Name = this.fileName;
      this.supplementaryCalculationFileMappingDTO.IsStandAloneAttachment =
        false;
      this.supplementaryCalculationFileMappingDTO.IncludeInExport =
        formObject.includeSupplementay == true ? true : false;

      if (formObject.supplementayTaxAccount.TaxAccountCodeId == undefined) {
        this.supplementaryCalculationFileMappingDTO.TaxAccountCodeId =
          formObject.supplementayTaxAccount;
      } else {
        this.supplementaryCalculationFileMappingDTO.TaxAccountCodeId =
          formObject.supplementayTaxAccount.TaxAccountCodeId;
      }

      // if (this.supplementaryCalculationFileMappingDTO.TaxAccountCodeId != 0) {
      //   if (this.supplementaryCalculationFileMappingDTO.Name == '') {
      //     this.toastr.errorToastr('Please upload file.', 'Error');
      //     this.isLoading = false;
      //     return;
      //   }
      // }

      this.saveMovement.supplementaryCalculationFileMappingDTO =
        this.supplementaryCalculationFileMappingDTO;

      this.convertTransactionsDataUkFormat();
    }

    this.journalService.SaveNewEditMovement(this.saveMovement).subscribe(
      (response) => {
        // console.log(response);
        if (
          response == 'Movement journal added successfully' ||
          response == 'Movement journal updated successfully'
        ) {
          this.toastr.successToastr(response, 'Success');
          this.isLoading = true;
          this.router.navigate(['/manageJournal']);
        } else {
          this.toastr.errorToastr(response, 'Error');
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.toastr.errorToastr('Failed to Save Movement Journal', 'Error');
        // console.log(error);
      }
    );
  }

  public createFormGroup(dataItem: AddTransaction): FormGroup {
    return this.formBuilder.group({
      IsChecked: dataItem.IsChecked,
      Description: [dataItem.Description],
      UnallocatedAmount: [
        dataItem.UnallocatedAmount,
        Validators.compose([
          Validators.required,
          // Validators.pattern('^[-+]?[0-9]+$'),
        ]),
      ],
      JournalAmount: [
        dataItem.JournalAmount,
        Validators.compose([
          Validators.required,
          // Validators.pattern('^[-+]?[0-9]+$'),
        ]),
      ],
    });
  }

  private isReadOnly(field: string): boolean {
    const readOnlyColumns = ['Description', 'UnallocatedAmount'];
    return readOnlyColumns.indexOf(field) > -1;
  }

  uploadEventHandler(e: UploadEvent) {
    let tokenValue: any = localStorage.getItem('OPENAMSESSION');
    e.headers = e.headers.append(
      'Authorization-Token',
      'Bearer ' + JSON.parse(tokenValue).access_token
    );
  }

  public success(e: SuccessEvent): void {
    // // console.log(e);
    // // console.log(e.files);
    if (e.files.length > 0) {
      if (e.response.body.FileUploaded == true) {
        this.fileName = e.response.body.FileName;
        this.fileUid = e.response.body.FileUid;
        this.isFileUploaded = true;
      }
    }
  }

  supplementaryFileRestrictions: FileRestrictions = {
    allowedExtensions: ['.xls', '.xlsx'],
  };

  public chunkSettings: ChunkSettings = {
    size: 1048576,
  };

  public viewButtonClick(): void {
    this.getDownloadExcel();
  }

  getDownloadExcel(): void {
    this.isLoading = true;

    this.journalService
      .GetSupplementaryCalculationFromContainer(
        this.onlineAllocationId,
        this.fileName,
        this.fileUid
      )
      .subscribe(
        (response) => {
          const fileName = this.fileName;
          let blob: Blob = response;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
          this.toastr.successToastr(
            'Supplementary Calculation Data downloaded successfully.',
            'Success'
          );
          this.isLoading = false;
        },
        (error) => {
          // console.log(error);
          this.isLoading = false;
        }
      );
  }

  public cellClickHandler(args: CellClickEvent): void {
    if (
      !args.isEdited &&
      !this.isReadOnly(args.column.field) &&
      args.column.field != undefined &&
      args.dataItem.IsChecked
    ) {
      args.sender.editCell(
        args.rowIndex,
        args.columnIndex,
        this.createFormGroup(args.dataItem)
      );
      this.isGridEdited = true;
    }
  }

  public cellCloseHandler(args: CellCloseEvent): void {
    const { formGroup, dataItem } = args;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (args.originalEvent && args.originalEvent.keyCode === Keys.Escape) {
        return;
      }

      let transactionsFinalUnallocatedAmount;
      const val: any = this.addTransactionsFinal.filter(
        // (f: any) => f.DataRow == this.editTrans[0].DataRow
        (f: any) => f._id == dataItem._id
      );

      if (val.length == 0) {
        dataItem.IsUsed = false;
      } else {
        dataItem.IsUsed = true;
        transactionsFinalUnallocatedAmount = val[0].UnallocatedAmount;
      }

      if (dataItem.IsUsed == false) {
        this.addTransactionFilter = [];
        this.addTransactionFilter = this.addTransactionResponse.filter(
          (s: AddTransaction) => s._id == dataItem._id
        );

        if (this.addTransactionFilter.length > 0) {
          this.originalUnallocatedAmount =
            this.addTransactionFilter[0].UnallocatedAmount;
          this.originalJournalAmount =
            this.addTransactionFilter[0].JournalAmount;
        }

        formGroup.value.UnallocatedAmount =
          this.originalUnallocatedAmount - formGroup.value.JournalAmount;
      } else {
        formGroup.value.UnallocatedAmount =
          dataItem.UnallocatedAmount +
          dataItem.JournalAmount -
          formGroup.value.JournalAmount;
      }

      if (dataItem.UnallocatedAmount != formGroup.value.UnallocatedAmount) {
        formGroup.value.IsUsed = true;
      }
      this.editService.assignValues(dataItem, formGroup.value);

      this.addTransaction = {
        ID: dataItem.ID,
        DataRow: dataItem.DataRow,
        IsChecked: dataItem.IsChecked,
        IsUsed: dataItem.IsUsed,
        IsEdit: dataItem.isEdited,
        Mode: 'A',
        ShortDescription: dataItem.ShortDescription,
        TransactionTypeDescription: dataItem.TransactionTypeDescription,
        JournalAmount: dataItem.JournalAmount,
        AllocatedAmount: dataItem.AllocatedAmount,
        UnallocatedAmount: dataItem.UnallocatedAmount,
        Type: dataItem.Type,
        _id: dataItem._id,
        fk_StatutoryAccountCodeId: dataItem.fk_StatutoryAccountCodeId,
        Amount: dataItem.Amount,
        Description: dataItem.Description,
        TaxAccountCodeId: dataItem.TaxAccountCodeId,
        // destinationTaxAccountText: dataItem.destinationTaxAccountText,
        AbsoluteAmount: dataItem.AbsoluteAmount,
        DebitCredit: dataItem.DebitCredit,
        fk_ProposalId: dataItem.fk_ProposalId,
        // fk_TaxAccountCodeId: dataItem.fk_TaxAccountCodeId,
        fk_JournalTransactionTypeId: dataItem.fk_JournalTransactionTypeId,
        OnlineAllocationElementId: dataItem.OnlineAllocationElementId,
        JournalId: dataItem.JournalId,
        JournalAmountText: this.pounds
          .format(dataItem.JournalAmount)
          .replace('£', ''),
        UnallocatedAmountText: this.pounds
          .format(dataItem.UnallocatedAmount)
          .replace('£', ''),
        TaxAccountDescription: dataItem.TaxAccountDescription,
        TaxAccount: dataItem.TaxAccount,
        SourceTaxAccount: dataItem.SourceTaxAccount,
        Key: dataItem.Key,
        OnlineAllocation: dataItem.OnlineAllocation,
        Transaction: dataItem.Transaction,
        Remove: dataItem.Remove,
        TransactionType: dataItem.TransactionType,
        LegalEntity: dataItem.LegalEntity,
        FiscalYear: dataItem.FiscalYear,
        DocumentType: dataItem.DocumentType,
        DocumentNo: dataItem.DocumentNo,
        TransactionId: dataItem.TransactionId,
        DocumentDate: dataItem.DocumentDate,
        PostingDate: dataItem.PostingDate,
        TransactionDate: dataItem.TransactionDate,
        Period: dataItem.Period,
        GLAccountNo: dataItem.GLAccountNo,
        GLAccountDescription: dataItem.GLAccountDescription,
        Currency: dataItem.Currency,
        CostCentre: dataItem.CostCentre,
        Vendor: dataItem.Vendor,
        Division: dataItem.Division,
        Product: dataItem.Product,
        Project: dataItem.Project,
        AssetCode: dataItem.AssetCode,
        VATCode: dataItem.VATCode,
        PurchaseOrder: dataItem.PurchaseOrder,
        Order: dataItem.Order,
        WBSElement: dataItem.WBSElement,
        ProfitCentre: dataItem.ProfitCentre,
        MatchID: dataItem.MatchID,
        Custom3: dataItem.Custom3,
        Custom4: dataItem.Custom4,
        Custom5: dataItem.Custom5,
        Custom6: dataItem.Custom6,
        Custom7: dataItem.Custom7,
      };

      this.editService.update(this.addTransaction);
    }

    this.isGridEdited = false;
    this.convertTransactionsDataUkFormat();
  }

  public cellClickHandlerTr(args: CellClickEvent): void {
    if (
      !args.isEdited &&
      !this.isReadOnly(args.column.field) &&
      args.column.field != undefined
    ) {
      args.sender.editCell(
        args.rowIndex,
        args.columnIndex,
        this.createFormGroup(args.dataItem)
      );
      this.isGridEdited = true;
    }
  }

  public cellCloseHandlerTr(args: CellCloseEvent): void {
    const { formGroup, dataItem } = args;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (args.originalEvent && args.originalEvent.keyCode === Keys.Escape) {
        return;
      }
      this.editTrans[0].IsChecked = true;
      this.editTrans[0].IsUsed = true;
      this.editTrans[0].UnallocatedAmount =
        formGroup.value.UnallocatedAmount +
        dataItem.JournalAmount -
        formGroup.value.JournalAmount;

      this.editTrans[0].JournalAmount = formGroup.value.JournalAmount;
    }

    this.isGridEdited = false;
  }

  public changeSelectAll(): void {
    this.IsSelectAll = !this.IsSelectAll;

    if (this.editService.value.length == 0) {
      return;
    }

    // console.log(this.IsSelectAll);

    this.editService.clean();
    this.bckTransactions.map((adj, i) => {
      this.addTransaction = {
        ID: i + 1,
        DataRow: adj.DataRow,
        IsChecked: this.IsSelectAll,
        IsUsed: adj.IsUsed,
        IsEdit: false,
        Mode: 'A',
        ShortDescription: adj.ShortDescription,
        TransactionTypeDescription: adj.TransactionTypeDescription,
        JournalAmount: this.IsSelectAll
          ? adj.UnallocatedAmount
          : adj.JournalAmount,
        AllocatedAmount: adj.AllocatedAmount,
        UnallocatedAmount: this.IsSelectAll ? 0 : adj.UnallocatedAmount,
        // UnallocatedAmountOld: adj.UnallocatedAmountOld,
        Type: adj.Type,
        _id: adj._id,
        fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
        Amount: adj.Amount,
        Description: adj.Description,
        TaxAccountCodeId: adj.TaxAccountCodeId,
        // destinationTaxAccountText: adj.destinationTaxAccountText,
        AbsoluteAmount: adj.AbsoluteAmount,
        DebitCredit: adj.DebitCredit,
        fk_ProposalId: adj.fk_ProposalId,
        // fk_TaxAccountCodeId: adj.fk_TaxAccountCodeId,
        fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
        OnlineAllocationElementId: adj.OnlineAllocationElementId,
        JournalId: adj.JournalId,
        JournalAmountText: this.pounds
          .format(adj.JournalAmount)
          .replace('£', ''),
        UnallocatedAmountText: this.pounds
          .format(adj.UnallocatedAmount)
          .replace('£', ''),
        TaxAccountDescription: adj.TaxAccountDescription,
        TaxAccount: adj.TaxAccount,
        SourceTaxAccount: adj.SourceTaxAccount,
        Key: adj.Key,
        OnlineAllocation: adj.OnlineAllocation,
        Transaction: adj.Transaction,
        Remove: adj.Remove,
        TransactionType: adj.TransactionType,
        LegalEntity: adj.LegalEntity,
        FiscalYear: adj.FiscalYear,
        DocumentType: adj.DocumentType,
        DocumentNo: adj.DocumentNo,
        TransactionId: adj.TransactionId,
        DocumentDate: adj.DocumentDate,
        PostingDate: adj.PostingDate,
        TransactionDate: adj.TransactionDate,
        Period: adj.Period,
        GLAccountNo: adj.GLAccountNo,
        GLAccountDescription: adj.GLAccountDescription,
        Currency: adj.Currency,
        CostCentre: adj.CostCentre,
        Vendor: adj.Vendor,
        Division: adj.Division,
        Product: adj.Product,
        Project: adj.Project,
        AssetCode: adj.AssetCode,
        VATCode: adj.VATCode,
        PurchaseOrder: adj.PurchaseOrder,
        Order: adj.Order,
        WBSElement: adj.WBSElement,
        ProfitCentre: adj.ProfitCentre,
        MatchID: adj.MatchID,
        Custom3: adj.Custom3,
        Custom4: adj.Custom4,
        Custom5: adj.Custom5,
        Custom6: adj.Custom6,
        Custom7: adj.Custom7,
      };
      this.editService.create(this.addTransaction);
    });
  }

  getAddTransactionDetail(
    legalEntityId: number,
    fiscalYear: number,
    statutoryAccountCodeId: any,
    onlineAllocationId: number
  ): void {
    this.isLoading = true;
    this.resetSaveMovementData();
    this.journalService
      .getAddTransactionDetail(
        legalEntityId,
        fiscalYear,
        statutoryAccountCodeId,
        onlineAllocationId
      )
      .subscribe(
        (response) => {
          this.addTransactionResponse = response;

          if (this.callingType == 'manageTransaction') {
            let bckAddTransactionResponse: AddTransaction[];
            let filterData: string[];
            bckAddTransactionResponse = this.addTransactionResponse;
            this.addTransactionResponse = [];
            bckAddTransactionResponse.map((adj) => {
              filterData = this.callingTransactions.filter(
                (f) => Number(f) == adj._id
              );
              if (filterData.length == 0) {
                this.addTransactionResponse.push(adj);
              }
            });
          }

          let maxId: number = 0;
          if (this.addTransactionsFinal.length > 0) {
            maxId =
              this.addTransactionsFinal[this.addTransactionsFinal.length - 1]
                .ID;
          }

          this.editService.clean();
          this.addTransactionResponse.map((adj, i) => {
            const val: any = this.editService.value.filter(
              (f: any) => f._id == adj._id
            );

            if (val.length == 0) {
              maxId++;
              this.addTransaction = {
                ID: maxId,
                DataRow: adj.DataRow,
                IsChecked: false,
                IsUsed: false,
                IsEdit: false,
                Mode: 'A',
                ShortDescription: adj.ShortDescription,
                TransactionTypeDescription: adj.TransactionTypeDescription,
                JournalAmount: adj.JournalAmount,
                // JournalAmountOld: adj.JournalAmount,
                AllocatedAmount: adj.AllocatedAmount,
                UnallocatedAmount: adj.UnallocatedAmount,
                // UnallocatedAmountOld: adj.UnallocatedAmount,
                Type: adj.Type,
                _id: adj._id,
                fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
                Amount: adj.Amount,
                Description: adj.Description,
                TaxAccountCodeId: adj.TaxAccountCodeId,
                // destinationTaxAccountText: adj.destinationTaxAccountText,
                AbsoluteAmount: adj.AbsoluteAmount,
                DebitCredit: adj.DebitCredit,
                fk_ProposalId: adj.fk_ProposalId,
                // fk_TaxAccountCodeId: adj.fk_TaxAccountCodeId,
                fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
                OnlineAllocationElementId: adj.OnlineAllocationElementId,
                JournalId: adj.JournalId,
                JournalAmountText: this.pounds
                  .format(adj.JournalAmount)
                  .replace('£', ''),
                UnallocatedAmountText: this.pounds
                  .format(adj.UnallocatedAmount)
                  .replace('£', ''),
                TaxAccountDescription: adj.TaxAccountDescription,
                TaxAccount: adj.TaxAccount,
                SourceTaxAccount: adj.SourceTaxAccount,
                Key: adj.Key,
                OnlineAllocation: adj.OnlineAllocation,
                Transaction: adj.Transaction,
                Remove: adj.Remove,
                TransactionType: adj.TransactionType,
                LegalEntity: adj.LegalEntity,
                FiscalYear: adj.FiscalYear,
                DocumentType: adj.DocumentType,
                DocumentNo: adj.DocumentNo,
                TransactionId: adj.TransactionId,
                DocumentDate: adj.DocumentDate,
                PostingDate: adj.PostingDate,
                TransactionDate: adj.TransactionDate,
                Period: adj.Period,
                GLAccountNo: adj.GLAccountNo,
                GLAccountDescription: adj.GLAccountDescription,
                Currency: adj.Currency,
                CostCentre: adj.CostCentre,
                Vendor: adj.Vendor,
                Division: adj.Division,
                Product: adj.Product,
                Project: adj.Project,
                AssetCode: adj.AssetCode,
                VATCode: adj.VATCode,
                PurchaseOrder: adj.PurchaseOrder,
                Order: adj.Order,
                WBSElement: adj.WBSElement,
                ProfitCentre: adj.ProfitCentre,
                MatchID: adj.MatchID,
                Custom3: adj.Custom3,
                Custom4: adj.Custom4,
                Custom5: adj.Custom5,
                Custom6: adj.Custom6,
                Custom7: adj.Custom7,
              };
              this.editService.create(this.addTransaction);
            }
          });

          // console.log('editService.value');
          // console.log(this.editService.value);

          this.editService.value.map((adj: any) => {
            this.addTransaction = {
              ID: adj.ID,
              DataRow: adj.DataRow,
              IsChecked: false,
              IsUsed: adj.IsUsed,
              IsEdit: adj.IsEdit,
              Mode: adj.Mode,
              ShortDescription: adj.ShortDescription,
              TransactionTypeDescription: adj.TransactionTypeDescription,
              JournalAmount: 0,
              AllocatedAmount: adj.AllocatedAmount,
              UnallocatedAmount: adj.UnallocatedAmount,
              Type: adj.Type,
              _id: adj._id,
              fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
              Amount: adj.Amount,
              Description: adj.Description,
              TaxAccountCodeId: adj.TaxAccountCodeId,
              // destinationTaxAccountText: adj.destinationTaxAccountText,
              AbsoluteAmount: adj.AbsoluteAmount,
              DebitCredit: adj.DebitCredit,
              fk_ProposalId: adj.fk_ProposalId,
              // fk_TaxAccountCodeId: adj.fk_TaxAccountCodeId,
              fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
              OnlineAllocationElementId: adj.OnlineAllocationElementId,
              JournalId: adj.JournalId,
              JournalAmountText: this.pounds
                .format(adj.JournalAmount)
                .replace('£', ''),
              UnallocatedAmountText: this.pounds
                .format(adj.UnallocatedAmount)
                .replace('£', ''),
              TaxAccountDescription: adj.TaxAccountDescription,
              TaxAccount: adj.TaxAccount,
              SourceTaxAccount: adj.SourceTaxAccount,
              Key: adj.Key,
              OnlineAllocation: adj.OnlineAllocation,
              Transaction: adj.Transaction,
              Remove: adj.Remove,
              TransactionType: adj.TransactionType,
              LegalEntity: adj.LegalEntity,
              FiscalYear: adj.FiscalYear,
              DocumentType: adj.DocumentType,
              DocumentNo: adj.DocumentNo,
              TransactionId: adj.TransactionId,
              DocumentDate: adj.DocumentDate,
              PostingDate: adj.PostingDate,
              TransactionDate: adj.TransactionDate,
              Period: adj.Period,
              GLAccountNo: adj.GLAccountNo,
              GLAccountDescription: adj.GLAccountDescription,
              Currency: adj.Currency,
              CostCentre: adj.CostCentre,
              Vendor: adj.Vendor,
              Division: adj.Division,
              Product: adj.Product,
              Project: adj.Project,
              AssetCode: adj.AssetCode,
              VATCode: adj.VATCode,
              PurchaseOrder: adj.PurchaseOrder,
              Order: adj.Order,
              WBSElement: adj.WBSElement,
              ProfitCentre: adj.ProfitCentre,
              MatchID: adj.MatchID,
              Custom3: adj.Custom3,
              Custom4: adj.Custom4,
              Custom5: adj.Custom5,
              Custom6: adj.Custom6,
              Custom7: adj.Custom7,
            };
            this.bckTransactions.push(this.addTransaction);
          });

          this.isLoading = false;
          this.IsSelectAll = false;
          this.convertTransactionsDataUkFormat();
        },
        (error) => {
          // console.log(error);
          this.isLoading = false;
        }
      );

    this.convertTransactionsDataUkFormat();
  }

  deleteButtonClick(data: any): void {
    var dialog = this.dialogService.open({
      title: 'Remove selected transaction?',
      content:
        'Are you sure you want to remove the selected transaction from the list?',
      width: 500,
      height: 200,
      actions: [
        { text: 'Yes', cssClass: 'yesclass', themeColor: 'light' },
        { text: 'No', cssClass: 'noclass', fillMode: '#fff, solid' },
      ],
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        // console.log('close');
      } else {
        // console.log('action', result);
        if (result.text == 'Yes') {
          // console.log('Yes');
          this.delete(data);
        } else {
          // console.log('No');
        }
      }
    });
  }

  delete(data: any): void {
    // console.log(this.addTransactionsFinal);
    this.addTransactionsFinalDelete = this.addTransactionsFinal;
    this.addTransactionsFinal = [];
    this.addTransactionsFinalDelete.map((adj: any, i) => {
      // if (adj.ID != data.ID && adj.DataRow == data.DataRow) {
      // if (adj.DataRow != data.DataRow) {
      if (adj._id != data._id) {
        this.addTransaction = {
          ID: adj.ID,
          DataRow: adj.DataRow,
          IsChecked: adj.IsChecked,
          IsUsed: adj.IsUsed,
          IsEdit: adj.isEdited,
          // Mode: 'A',
          Mode: adj.Mode,
          ShortDescription: adj.ShortDescription,
          TransactionTypeDescription: adj.TransactionTypeDescription,
          JournalAmount: adj.JournalAmount,
          AllocatedAmount: adj.AllocatedAmount,
          UnallocatedAmount: adj.UnallocatedAmount,
          Type: adj.Type,
          _id: adj._id,
          fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
          Amount: adj.Amount,
          Description: adj.Description,
          TaxAccountCodeId: adj.TaxAccountCodeId,
          // destinationTaxAccountText: adj.destinationTaxAccountText,
          AbsoluteAmount: adj.AbsoluteAmount,
          DebitCredit: adj.DebitCredit,
          fk_ProposalId: adj.fk_ProposalId,
          // fk_TaxAccountCodeId: adj.fk_TaxAccountCodeId,
          fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
          OnlineAllocationElementId: adj.OnlineAllocationElementId,
          JournalId: adj.JournalId,
          JournalAmountText: this.pounds
            .format(adj.JournalAmount)
            .replace('£', ''),
          UnallocatedAmountText: this.pounds
            .format(adj.UnallocatedAmount)
            .replace('£', ''),
          TaxAccountDescription: adj.TaxAccountDescription,
          TaxAccount: adj.TaxAccount,
          SourceTaxAccount: adj.SourceTaxAccount,
          Key: adj.Key,
          OnlineAllocation: adj.OnlineAllocation,
          Transaction: adj.Transaction,
          Remove: adj.Remove,
          TransactionType: adj.TransactionType,
          LegalEntity: adj.LegalEntity,
          FiscalYear: adj.FiscalYear,
          DocumentType: adj.DocumentType,
          DocumentNo: adj.DocumentNo,
          TransactionId: adj.TransactionId,
          DocumentDate: adj.DocumentDate,
          PostingDate: adj.PostingDate,
          TransactionDate: adj.TransactionDate,
          Period: adj.Period,
          GLAccountNo: adj.GLAccountNo,
          GLAccountDescription: adj.GLAccountDescription,
          Currency: adj.Currency,
          CostCentre: adj.CostCentre,
          Vendor: adj.Vendor,
          Division: adj.Division,
          Product: adj.Product,
          Project: adj.Project,
          AssetCode: adj.AssetCode,
          VATCode: adj.VATCode,
          PurchaseOrder: adj.PurchaseOrder,
          Order: adj.Order,
          WBSElement: adj.WBSElement,
          ProfitCentre: adj.ProfitCentre,
          MatchID: adj.MatchID,
          Custom3: adj.Custom3,
          Custom4: adj.Custom4,
          Custom5: adj.Custom5,
          Custom6: adj.Custom6,
          Custom7: adj.Custom7,
        };
        this.addTransactionsFinal.push(this.addTransaction);
      }
      // if (adj.ID != data.ID && adj.DataRow == data.DataRow) {
      if (adj.ID != data.ID && adj._id == data._id) {
        this.addTransaction = {
          ID: adj.ID,
          DataRow: adj.DataRow,
          IsChecked: adj.IsChecked,
          IsUsed: adj.IsUsed,
          IsEdit: adj.isEdited,
          // Mode: 'A',
          Mode: adj.Mode,
          ShortDescription: adj.ShortDescription,
          TransactionTypeDescription: adj.TransactionTypeDescription,
          JournalAmount: adj.JournalAmount,
          AllocatedAmount: adj.AllocatedAmount,
          UnallocatedAmount: data.UnallocatedAmount + data.JournalAmount,
          Type: adj.Type,
          _id: adj._id,
          fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
          Amount: adj.Amount,
          Description: adj.Description,
          TaxAccountCodeId: adj.TaxAccountCodeId,
          // destinationTaxAccountText: adj.destinationTaxAccountText,
          AbsoluteAmount: adj.AbsoluteAmount,
          DebitCredit: adj.DebitCredit,
          fk_ProposalId: adj.fk_ProposalId,
          // fk_TaxAccountCodeId: adj.fk_TaxAccountCodeId,
          fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
          OnlineAllocationElementId: adj.OnlineAllocationElementId,
          JournalId: adj.JournalId,
          JournalAmountText: this.pounds
            .format(adj.JournalAmount)
            .replace('£', ''),
          UnallocatedAmountText: this.pounds
            .format(adj.UnallocatedAmount)
            .replace('£', ''),
          TaxAccountDescription: adj.TaxAccountDescription,
          TaxAccount: adj.TaxAccount,
          SourceTaxAccount: adj.SourceTaxAccount,
          Key: adj.Key,
          OnlineAllocation: adj.OnlineAllocation,
          Transaction: adj.Transaction,
          Remove: adj.Remove,
          TransactionType: adj.TransactionType,
          LegalEntity: adj.LegalEntity,
          FiscalYear: adj.FiscalYear,
          DocumentType: adj.DocumentType,
          DocumentNo: adj.DocumentNo,
          TransactionId: adj.TransactionId,
          DocumentDate: adj.DocumentDate,
          PostingDate: adj.PostingDate,
          TransactionDate: adj.TransactionDate,
          Period: adj.Period,
          GLAccountNo: adj.GLAccountNo,
          GLAccountDescription: adj.GLAccountDescription,
          Currency: adj.Currency,
          CostCentre: adj.CostCentre,
          Vendor: adj.Vendor,
          Division: adj.Division,
          Product: adj.Product,
          Project: adj.Project,
          AssetCode: adj.AssetCode,
          VATCode: adj.VATCode,
          PurchaseOrder: adj.PurchaseOrder,
          Order: adj.Order,
          WBSElement: adj.WBSElement,
          ProfitCentre: adj.ProfitCentre,
          MatchID: adj.MatchID,
          Custom3: adj.Custom3,
          Custom4: adj.Custom4,
          Custom5: adj.Custom5,
          Custom6: adj.Custom6,
          Custom7: adj.Custom7,
        };
        this.addTransactionsFinal.push(this.addTransaction);
      }
      // else if (adj.ID == data.ID && adj.DataRow == data.DataRow) {
      else if (adj.ID == data.ID && adj._id == data._id) {
        const val: any = this.editService.value.filter(
          (f: any) => f._id == adj._id
        );

        if (val.length == 0) {
          const tr = adj;
          tr.UnallocatedAmount = adj.JournalAmount;
          tr.JournalAmount = 0;
          this.addTransaction = {
            ID: tr.ID,
            DataRow: tr.DataRow,
            IsChecked: tr.IsChecked,
            IsUsed: tr.IsUsed,
            IsEdit: tr.isEdited,
            // Mode: 'A',
            Mode: tr.Mode,
            ShortDescription: tr.ShortDescription,
            TransactionTypeDescription: tr.TransactionTypeDescription,
            JournalAmount: tr.JournalAmount,
            AllocatedAmount: tr.AllocatedAmount,
            UnallocatedAmount: tr.UnallocatedAmount,
            Type: tr.Type,
            _id: tr._id,
            fk_StatutoryAccountCodeId: tr.fk_StatutoryAccountCodeId,
            Amount: tr.Amount,
            Description: tr.Description,
            TaxAccountCodeId: tr.TaxAccountCodeId,
            // destinationTaxAccountText: tr.destinationTaxAccountText,
            AbsoluteAmount: tr.AbsoluteAmount,
            DebitCredit: tr.DebitCredit,
            fk_ProposalId: tr.fk_ProposalId,
            // fk_TaxAccountCodeId: tr.fk_TaxAccountCodeId,
            fk_JournalTransactionTypeId: 0,
            OnlineAllocationElementId: tr.OnlineAllocationElementId,
            JournalId: tr.JournalId,
            JournalAmountText: this.pounds
              .format(adj.JournalAmount)
              .replace('£', ''),
            UnallocatedAmountText: this.pounds
              .format(adj.UnallocatedAmount)
              .replace('£', ''),
            TaxAccountDescription: tr.TaxAccountDescription,
            TaxAccount: tr.TaxAccount,
            SourceTaxAccount: tr.SourceTaxAccount,
            Key: tr.Key,
            OnlineAllocation: tr.OnlineAllocation,
            Transaction: tr.Transaction,
            Remove: tr.Remove,
            TransactionType: tr.TransactionType,
            LegalEntity: tr.LegalEntity,
            FiscalYear: tr.FiscalYear,
            DocumentType: tr.DocumentType,
            DocumentNo: tr.DocumentNo,
            TransactionId: tr.TransactionId,
            DocumentDate: tr.DocumentDate,
            PostingDate: tr.PostingDate,
            TransactionDate: tr.TransactionDate,
            Period: tr.Period,
            GLAccountNo: tr.GLAccountNo,
            GLAccountDescription: tr.GLAccountDescription,
            Currency: tr.Currency,
            CostCentre: tr.CostCentre,
            Vendor: tr.Vendor,
            Division: tr.Division,
            Product: tr.Product,
            Project: tr.Project,
            AssetCode: tr.AssetCode,
            VATCode: tr.VATCode,
            PurchaseOrder: tr.PurchaseOrder,
            Order: tr.Order,
            WBSElement: tr.WBSElement,
            ProfitCentre: tr.ProfitCentre,
            MatchID: tr.MatchID,
            Custom3: tr.Custom3,
            Custom4: tr.Custom4,
            Custom5: tr.Custom5,
            Custom6: tr.Custom6,
            Custom7: tr.Custom7,
          };
          this.editService.create(this.addTransaction);
        } else {
          this.editService.value.map((tr: any) => {
            // if (tr.DataRow == adj.DataRow) {
            if (tr._id == adj._id) {
              tr.UnallocatedAmount = tr.UnallocatedAmount + adj.JournalAmount;
              tr.JournalAmount = 0;
              this.addTransaction = {
                ID: tr.ID,
                DataRow: tr.DataRow,
                IsChecked: tr.IsChecked,
                IsUsed: tr.IsUsed,
                IsEdit: tr.isEdited,
                // Mode: 'A',
                Mode: tr.Mode,
                ShortDescription: tr.ShortDescription,
                TransactionTypeDescription: tr.TransactionTypeDescription,
                JournalAmount: tr.JournalAmount,
                AllocatedAmount: tr.AllocatedAmount,
                UnallocatedAmount: tr.UnallocatedAmount,
                Type: tr.Type,
                _id: tr._id,
                fk_StatutoryAccountCodeId: tr.fk_StatutoryAccountCodeId,
                Amount: tr.Amount,
                Description: tr.Description,
                TaxAccountCodeId: tr.TaxAccountCodeId,
                // destinationTaxAccountText: tr.destinationTaxAccountText,
                AbsoluteAmount: tr.AbsoluteAmount,
                DebitCredit: tr.DebitCredit,
                fk_ProposalId: tr.fk_ProposalId,
                // fk_TaxAccountCodeId: tr.fk_TaxAccountCodeId,
                fk_JournalTransactionTypeId: tr.fk_JournalTransactionTypeId,
                OnlineAllocationElementId: tr.OnlineAllocationElementId,
                JournalId: tr.JournalId,
                JournalAmountText: this.pounds
                  .format(adj.JournalAmount)
                  .replace('£', ''),
                UnallocatedAmountText: this.pounds
                  .format(adj.UnallocatedAmount)
                  .replace('£', ''),
                TaxAccountDescription: tr.TaxAccountDescription,
                TaxAccount: tr.TaxAccount,
                SourceTaxAccount: tr.SourceTaxAccount,
                Key: tr.Key,
                OnlineAllocation: tr.OnlineAllocation,
                Transaction: tr.Transaction,
                Remove: tr.Remove,
                TransactionType: tr.TransactionType,
                LegalEntity: tr.LegalEntity,
                FiscalYear: tr.FiscalYear,
                DocumentType: tr.DocumentType,
                DocumentNo: tr.DocumentNo,
                TransactionId: tr.TransactionId,
                DocumentDate: tr.DocumentDate,
                PostingDate: tr.PostingDate,
                TransactionDate: tr.TransactionDate,
                Period: tr.Period,
                GLAccountNo: tr.GLAccountNo,
                GLAccountDescription: tr.GLAccountDescription,
                Currency: tr.Currency,
                CostCentre: tr.CostCentre,
                Vendor: tr.Vendor,
                Division: tr.Division,
                Product: tr.Product,
                Project: tr.Project,
                AssetCode: tr.AssetCode,
                VATCode: tr.VATCode,
                PurchaseOrder: tr.PurchaseOrder,
                Order: tr.Order,
                WBSElement: tr.WBSElement,
                ProfitCentre: tr.ProfitCentre,
                MatchID: tr.MatchID,
                Custom3: tr.Custom3,
                Custom4: tr.Custom4,
                Custom5: tr.Custom5,
                Custom6: tr.Custom6,
                Custom7: tr.Custom7,
              };
              this.editService.update(this.addTransaction);
            }
          });
        }
      }
    });

    this.fillSupplementayTaxAccounts();
    this.convertTransactionsDataUkFormat();

    // console.log(this.addTransactionsFinal);
  }

  openAddTransaction() {
    this.tabstrip.selectTab(0);
    if (this.mode == 'Edit') {
      this.getTaxAccountTrs(
        this.editJournallResponse.BusinessTypeId,
        this.editJournallResponse.LegalEntityID
      );
    }

    this.bckTransactions = [];

    this.editService.value.map((adj: any) => {
      this.addTransaction = {
        ID: adj.ID,
        DataRow: adj.DataRow,
        IsChecked: false,
        IsUsed: adj.IsUsed,
        IsEdit: adj.IsEdit,
        Mode: adj.Mode,
        ShortDescription: adj.ShortDescription,
        TransactionTypeDescription: adj.TransactionTypeDescription,
        JournalAmount: 0,
        AllocatedAmount: adj.AllocatedAmount,
        UnallocatedAmount: adj.UnallocatedAmount,
        Type: adj.Type,
        _id: adj._id,
        fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
        Amount: adj.Amount,
        Description: adj.Description,
        TaxAccountCodeId: adj.TaxAccountCodeId,
        // destinationTaxAccountText: adj.destinationTaxAccountText,
        AbsoluteAmount: adj.AbsoluteAmount,
        DebitCredit: adj.DebitCredit,
        fk_ProposalId: adj.fk_ProposalId,
        // fk_TaxAccountCodeId: adj.fk_TaxAccountCodeId,
        fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
        OnlineAllocationElementId: adj.OnlineAllocationElementId,
        JournalId: adj.JournalId,
        JournalAmountText: this.pounds
          .format(adj.JournalAmount)
          .replace('£', ''),
        UnallocatedAmountText: this.pounds
          .format(adj.UnallocatedAmount)
          .replace('£', ''),
        TaxAccountDescription: adj.TaxAccountDescription,
        TaxAccount: adj.TaxAccount,
        SourceTaxAccount: adj.SourceTaxAccount,
        Key: adj.Key,
        OnlineAllocation: adj.OnlineAllocation,
        Transaction: adj.Transaction,
        Remove: adj.Remove,
        TransactionType: adj.TransactionType,
        LegalEntity: adj.LegalEntity,
        FiscalYear: adj.FiscalYear,
        DocumentType: adj.DocumentType,
        DocumentNo: adj.DocumentNo,
        TransactionId: adj.TransactionId,
        DocumentDate: adj.DocumentDate,
        PostingDate: adj.PostingDate,
        TransactionDate: adj.TransactionDate,
        Period: adj.Period,
        GLAccountNo: adj.GLAccountNo,
        GLAccountDescription: adj.GLAccountDescription,
        Currency: adj.Currency,
        CostCentre: adj.CostCentre,
        Vendor: adj.Vendor,
        Division: adj.Division,
        Product: adj.Product,
        Project: adj.Project,
        AssetCode: adj.AssetCode,
        VATCode: adj.VATCode,
        PurchaseOrder: adj.PurchaseOrder,
        Order: adj.Order,
        WBSElement: adj.WBSElement,
        ProfitCentre: adj.ProfitCentre,
        MatchID: adj.MatchID,
        Custom3: adj.Custom3,
        Custom4: adj.Custom4,
        Custom5: adj.Custom5,
        Custom6: adj.Custom6,
        Custom7: adj.Custom7,
      };
      this.bckTransactions.push(this.addTransaction);
    });

    // console.log('bckTransactions');
    // console.log(this.bckTransactions);
    // this.selectedGlAccountTr = undefined;
    if (this.callingType != 'manageTransaction') {
      this.selectedTaxAccountTr = undefined;
    }

    this.editService.clean();
    this.bckTransactions.map((adj: any) => {
      this.addTransaction = {
        ID: adj.ID,
        DataRow: adj.DataRow,
        IsChecked: false,
        IsUsed: adj.IsUsed,
        IsEdit: adj.IsEdit,
        Mode: adj.Mode,
        ShortDescription: adj.ShortDescription,
        TransactionTypeDescription: adj.TransactionTypeDescription,
        JournalAmount: 0,
        AllocatedAmount: adj.AllocatedAmount,
        UnallocatedAmount: adj.UnallocatedAmount,
        Type: adj.Type,
        _id: adj._id,
        fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
        Amount: adj.Amount,
        Description: adj.Description,
        TaxAccountCodeId: adj.TaxAccountCodeId,
        // destinationTaxAccountText: adj.destinationTaxAccountText,
        AbsoluteAmount: adj.AbsoluteAmount,
        DebitCredit: adj.DebitCredit,
        fk_ProposalId: adj.fk_ProposalId,
        // fk_TaxAccountCodeId: adj.fk_TaxAccountCodeId,
        fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
        OnlineAllocationElementId: adj.OnlineAllocationElementId,
        JournalId: adj.JournalId,
        JournalAmountText: this.pounds
          .format(adj.JournalAmount)
          .replace('£', ''),
        UnallocatedAmountText: this.pounds
          .format(adj.UnallocatedAmount)
          .replace('£', ''),
        TaxAccountDescription: adj.TaxAccountDescription,
        TaxAccount: adj.TaxAccount,
        SourceTaxAccount: adj.SourceTaxAccount,
        Key: adj.Key,
        OnlineAllocation: adj.OnlineAllocation,
        Transaction: adj.Transaction,
        Remove: adj.Remove,
        TransactionType: adj.TransactionType,
        LegalEntity: adj.LegalEntity,
        FiscalYear: adj.FiscalYear,
        DocumentType: adj.DocumentType,
        DocumentNo: adj.DocumentNo,
        TransactionId: adj.TransactionId,
        DocumentDate: adj.DocumentDate,
        PostingDate: adj.PostingDate,
        TransactionDate: adj.TransactionDate,
        Period: adj.Period,
        GLAccountNo: adj.GLAccountNo,
        GLAccountDescription: adj.GLAccountDescription,
        Currency: adj.Currency,
        CostCentre: adj.CostCentre,
        Vendor: adj.Vendor,
        Division: adj.Division,
        Product: adj.Product,
        Project: adj.Project,
        AssetCode: adj.AssetCode,
        VATCode: adj.VATCode,
        PurchaseOrder: adj.PurchaseOrder,
        Order: adj.Order,
        WBSElement: adj.WBSElement,
        ProfitCentre: adj.ProfitCentre,
        MatchID: adj.MatchID,
        Custom3: adj.Custom3,
        Custom4: adj.Custom4,
        Custom5: adj.Custom5,
        Custom6: adj.Custom6,
        Custom7: adj.Custom7,
      };
      if (adj.UnallocatedAmount == 0) {
        // this.editService.remove(this.addTransaction);
      } else {
        // this.editService.update(this.addTransaction);
        this.editService.create(this.addTransaction);
      }
    });

    // this.bckTransactions = [];
    // this.editService.value.map((adj: any) => {
    //   if (adj.UnallocatedAmount == 0) {
    //     this.editService.remove(adj);
    //   } else {
    //     adj.IsChecked = false;
    //     this.bckTransactions.push(adj);
    //     this.editService.update(adj);
    //   }
    // });

    this.isTransationAddClick = true;
    this.isTransationEditClick = false;
    this.isGridVisible = false;
    // this.IsSelectAll = false;
  }

  editTransaction(row: any) {
    this.bckAddTransactionsFinal = [];

    if (this.mode == 'Edit') {
      // this.getGlTaxAccountTrs(
      //   this.editJournallResponse.BusinessTypeId,
      //   this.editJournallResponse.LegalEntityID,
      //   0
      // );
      this.getTaxAccountTrs(
        this.editJournallResponse.BusinessTypeId,
        this.editJournallResponse.LegalEntityID
      );
    }

    this.addTransactionsFinal.map((adj: any) => {
      this.addTransaction = {
        ID: adj.ID,
        DataRow: adj.DataRow,
        IsChecked: adj.IsChecked,
        IsUsed: adj.IsUsed,
        IsEdit: adj.IsEdit,
        Mode: adj.Mode,
        ShortDescription: adj.ShortDescription,
        TransactionTypeDescription: adj.TransactionTypeDescription,
        JournalAmount: adj.JournalAmount,
        AllocatedAmount: adj.AllocatedAmount,
        UnallocatedAmount: adj.UnallocatedAmount,
        Type: adj.Type,
        _id: adj._id,
        fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
        Amount: adj.Amount,
        Description: adj.Description,
        TaxAccountCodeId: adj.TaxAccountCodeId,
        // destinationTaxAccountText: adj.destinationTaxAccountText,
        AbsoluteAmount: adj.AbsoluteAmount,
        DebitCredit: adj.DebitCredit,
        fk_ProposalId: adj.fk_ProposalId,
        // fk_TaxAccountCodeId: adj.fk_TaxAccountCodeId,
        fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
        OnlineAllocationElementId: adj.OnlineAllocationElementId,
        JournalId: adj.JournalId,
        JournalAmountText: this.pounds
          .format(adj.JournalAmount)
          .replace('£', ''),
        UnallocatedAmountText: this.pounds
          .format(adj.UnallocatedAmount)
          .replace('£', ''),
        TaxAccountDescription: adj.TaxAccountDescription,
        TaxAccount: adj.TaxAccount,
        SourceTaxAccount: adj.SourceTaxAccount,
        Key: adj.Key,
        OnlineAllocation: adj.OnlineAllocation,
        Transaction: adj.Transaction,
        Remove: adj.Remove,
        TransactionType: adj.TransactionType,
        LegalEntity: adj.LegalEntity,
        FiscalYear: adj.FiscalYear,
        DocumentType: adj.DocumentType,
        DocumentNo: adj.DocumentNo,
        TransactionId: adj.TransactionId,
        DocumentDate: adj.DocumentDate,
        PostingDate: adj.PostingDate,
        TransactionDate: adj.TransactionDate,
        Period: adj.Period,
        GLAccountNo: adj.GLAccountNo,
        GLAccountDescription: adj.GLAccountDescription,
        Currency: adj.Currency,
        CostCentre: adj.CostCentre,
        Vendor: adj.Vendor,
        Division: adj.Division,
        Product: adj.Product,
        Project: adj.Project,
        AssetCode: adj.AssetCode,
        VATCode: adj.VATCode,
        PurchaseOrder: adj.PurchaseOrder,
        Order: adj.Order,
        WBSElement: adj.WBSElement,
        ProfitCentre: adj.ProfitCentre,
        MatchID: adj.MatchID,
        Custom3: adj.Custom3,
        Custom4: adj.Custom4,
        Custom5: adj.Custom5,
        Custom6: adj.Custom6,
        Custom7: adj.Custom7,
      };
      this.bckAddTransactionsFinal.push(this.addTransaction);
    });

    // this.selectedGlAccountTr = undefined;
    this.selectedTaxAccountTr = undefined;
    // this.editServiceTr.clean();
    this.addTransactionsFinalFilter = [];
    this.addTransactionsFinalFilter = this.addTransactionsFinal.filter(
      (f) => f.ID == row.ID
    );
    this.editTrans = this.addTransactionsFinalFilter;
    // this.selectedTaxAccountTr =

    // if (glAccount != undefined) {

    this.filteredTaxAccountTrs = this.taxAccountTrs.filter(
      (s: TaxAccount) =>
        s.TaxAccountCodeId == this.editTrans[0].TaxAccountCodeId
      // s.TaxAccountCodeId == 7
    );
    if (this.filteredTaxAccountTrs.length > 0) {
      this.selectedTaxAccountTr = this.filteredTaxAccountTrs[0];
    }

    this.isTransationEditClick = true;
    this.isTransationAddClick = false;
    this.isGridVisible = false;
  }

  saveAddTransaction() {
    if (this.isTransationAddClick == true) {
      let maxId: number = 0;
      if (this.addTransactionsFinal.length > 0) {
        maxId =
          this.addTransactionsFinal[this.addTransactionsFinal.length - 1].ID;
      }

      let IsAnyChecked = false;

      this.editService.value.map((adj: any) => {
        if (adj.IsChecked) {
          IsAnyChecked = true;
        } else {
        }
      });

      if (IsAnyChecked == false) {
        this.toastr.errorToastr(
          'You must select at least one transaction in order to Save',
          'Error'
        );
        // this.isLoading = false;
        return;
      }

      this.editService.value.map((adj: any) => {
        if (adj.IsChecked) {
          maxId++;
          adj.TaxAccountCodeId = this.selectedTaxAccountTr?.TaxAccountCodeId;
          // adj.destinationTaxAccountText =
          //   this.selectedTaxAccountTr?.ShortDescription;
          adj.ShortDescription = this.selectedTaxAccountTr?.ShortDescription;

          this.addTransaction = {
            ID: maxId,
            DataRow: adj.DataRow,
            IsChecked: false,
            IsUsed: false,
            IsEdit: false,
            Mode: 'A',
            ShortDescription: adj.ShortDescription,
            TransactionTypeDescription: adj.TransactionTypeDescription,
            JournalAmount: adj.JournalAmount,
            AllocatedAmount: adj.AllocatedAmount,
            UnallocatedAmount: adj.UnallocatedAmount,
            Type: adj.Type,
            _id: adj._id,
            fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
            Amount: adj.Amount,
            Description: adj.Description,
            TaxAccountCodeId: adj.TaxAccountCodeId,
            // destinationTaxAccountText: adj.destinationTaxAccountText,
            AbsoluteAmount: adj.AbsoluteAmount,
            DebitCredit: adj.DebitCredit,
            fk_ProposalId: adj.fk_ProposalId,
            // fk_TaxAccountCodeId: adj.fk_TaxAccountCodeId,
            fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
            OnlineAllocationElementId: adj.OnlineAllocationElementId,
            JournalId: adj.JournalId,
            JournalAmountText: this.pounds
              .format(adj.JournalAmount)
              .replace('£', ''),
            UnallocatedAmountText: this.pounds
              .format(adj.UnallocatedAmount)
              .replace('£', ''),
            TaxAccountDescription: adj.TaxAccountDescription,
            TaxAccount: adj.TaxAccount,
            SourceTaxAccount: adj.SourceTaxAccount,
            Key: adj.Key,
            OnlineAllocation: adj.OnlineAllocation,
            Transaction: adj.Transaction,
            Remove: adj.Remove,
            TransactionType: adj.TransactionType,
            LegalEntity: adj.LegalEntity,
            FiscalYear: adj.FiscalYear,
            DocumentType: adj.DocumentType,
            DocumentNo: adj.DocumentNo,
            TransactionId: adj.TransactionId,
            DocumentDate: adj.DocumentDate,
            PostingDate: adj.PostingDate,
            TransactionDate: adj.TransactionDate,
            Period: adj.Period,
            GLAccountNo: adj.GLAccountNo,
            GLAccountDescription: adj.GLAccountDescription,
            Currency: adj.Currency,
            CostCentre: adj.CostCentre,
            Vendor: adj.Vendor,
            Division: adj.Division,
            Product: adj.Product,
            Project: adj.Project,
            AssetCode: adj.AssetCode,
            VATCode: adj.VATCode,
            PurchaseOrder: adj.PurchaseOrder,
            Order: adj.Order,
            WBSElement: adj.WBSElement,
            ProfitCentre: adj.ProfitCentre,
            MatchID: adj.MatchID,
            Custom3: adj.Custom3,
            Custom4: adj.Custom4,
            Custom5: adj.Custom5,
            Custom6: adj.Custom6,
            Custom7: adj.Custom7,
          };
          this.addTransactionsFinal.push(this.addTransaction);

          // if (adj.UnallocatedAmount == 0) {
          this.addTransactionsFinal.map((tr: any) => {
            if (adj._id == tr._id) {
              // tr.UnallocatedAmount = 0;
              tr.UnallocatedAmount = this.addTransaction.UnallocatedAmount;
            }
          });
          // }
          adj.IsUsed = true;
        }
      });

      this.editService.value.map((adj: any) => {
        if (adj.UnallocatedAmount == 0) {
          this.editService.remove(adj);
        } else {
        }
      });
      this.isTransationAddClick = false;
    }

    if (this.isTransationEditClick == true) {
      // console.log(this.editTrans);

      this.selectedTaxAccountCodeId =
        this.selectedTaxAccountTr?.TaxAccountCodeId;
      // this.selectedDestinationTaxAccountText =
      //   this.selectedTaxAccountTr?.ShortDescription;

      this.selectedDestinationTaxAccountText =
        this.selectedTaxAccountTr?.ShortDescription;

      this.addTransactionsFinal.map((adj: any) => {
        if (
          // adj.ID == this.editTrans[0].ID
          // &&
          // adj.DataRow == this.editTrans[0].DataRow
          adj._id == this.editTrans[0]._id &&
          adj.ID == this.editTrans[0].ID
        ) {
          adj.UnallocatedAmount = this.editTrans[0].UnallocatedAmount;
          adj.JournalAmount = this.editTrans[0].JournalAmount;
          adj.IsUsed = true;
          adj.TaxAccountCodeId = this.selectedTaxAccountCodeId;
          adj.ShortDescription = this.selectedDestinationTaxAccountText;
        } else if (
          adj._id == this.editTrans[0]._id &&
          adj.ID != this.editTrans[0].ID
        ) {
          // adj.UnallocatedAmount = this.editTrans[0].UnallocatedAmount;
          adj.UnallocatedAmount = this.editTrans[0].UnallocatedAmount;
          // adj.JournalAmount = this.editTrans[0].JournalAmount;
          // adj.IsUsed = true;
          // adj.TaxAccountCodeId = this.selectedTaxAccountCodeId;
          // adj.ShortDescription = this.selectedDestinationTaxAccountText;
          adj.IsUsed = true;
        } else {
          adj.IsUsed = true;
        }
      });

      // console.log(this.addTransactionsFinal);

      const val: any = this.editService.value.filter(
        // (f: any) => f.DataRow == this.editTrans[0].DataRow
        (f: any) => f._id == this.editTrans[0]._id
      );

      if (val.length == 0) {
        let adj = this.editTrans[0];

        // adj.JournalAmount = 0;
        this.addTransaction = {
          ID: adj.ID,
          DataRow: adj.DataRow,
          IsChecked: adj.IsChecked,
          IsUsed: adj.IsUsed,
          IsEdit: adj.IsEdit,
          Mode: 'A',
          ShortDescription: adj.ShortDescription,
          TransactionTypeDescription: adj.TransactionTypeDescription,
          JournalAmount: adj.JournalAmount,
          AllocatedAmount: adj.AllocatedAmount,
          UnallocatedAmount: adj.UnallocatedAmount,
          Type: adj.Type,
          _id: adj._id,
          fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
          Amount: adj.Amount,
          Description: adj.Description,
          TaxAccountCodeId: this.selectedTaxAccountCodeId,
          // destinationTaxAccountText: this.selectedDestinationTaxAccountText,
          AbsoluteAmount: adj.AbsoluteAmount,
          DebitCredit: adj.DebitCredit,
          fk_ProposalId: adj.fk_ProposalId,
          // fk_TaxAccountCodeId: adj.fk_TaxAccountCodeId,
          fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
          OnlineAllocationElementId: adj.OnlineAllocationElementId,
          JournalId: adj.JournalId,
          JournalAmountText: this.pounds
            .format(adj.JournalAmount)
            .replace('£', ''),
          UnallocatedAmountText: this.pounds
            .format(adj.UnallocatedAmount)
            .replace('£', ''),
          TaxAccountDescription: adj.TaxAccountDescription,
          TaxAccount: adj.TaxAccount,
          SourceTaxAccount: adj.SourceTaxAccount,
          Key: adj.Key,
          OnlineAllocation: adj.OnlineAllocation,
          Transaction: adj.Transaction,
          Remove: adj.Remove,
          TransactionType: adj.TransactionType,
          LegalEntity: adj.LegalEntity,
          FiscalYear: adj.FiscalYear,
          DocumentType: adj.DocumentType,
          DocumentNo: adj.DocumentNo,
          TransactionId: adj.TransactionId,
          DocumentDate: adj.DocumentDate,
          PostingDate: adj.PostingDate,
          TransactionDate: adj.TransactionDate,
          Period: adj.Period,
          GLAccountNo: adj.GLAccountNo,
          GLAccountDescription: adj.GLAccountDescription,
          Currency: adj.Currency,
          CostCentre: adj.CostCentre,
          Vendor: adj.Vendor,
          Division: adj.Division,
          Product: adj.Product,
          Project: adj.Project,
          AssetCode: adj.AssetCode,
          VATCode: adj.VATCode,
          PurchaseOrder: adj.PurchaseOrder,
          Order: adj.Order,
          WBSElement: adj.WBSElement,
          ProfitCentre: adj.ProfitCentre,
          MatchID: adj.MatchID,
          Custom3: adj.Custom3,
          Custom4: adj.Custom4,
          Custom5: adj.Custom5,
          Custom6: adj.Custom6,
          Custom7: adj.Custom7,
        };
        this.editService.create(this.addTransaction);
      } else {
        this.editService.value.map((adj: any) => {
          // if (adj.DataRow == this.editTrans[0].DataRow) {
          if (adj._id == this.editTrans[0]._id) {
            // adj.UnallocatedAmount =
            //   adj.UnallocatedAmount - this.editTrans[0].UnallocatedAmount;
            this.addTransaction = {
              ID: adj.ID,
              DataRow: adj.DataRow,
              IsChecked: adj.IsChecked,
              IsUsed: adj.IsUsed,
              IsEdit: adj.isEdited,
              // Mode: 'A',
              Mode: adj.Mode,
              ShortDescription: adj.ShortDescription,
              TransactionTypeDescription: adj.TransactionTypeDescription,
              JournalAmount: adj.JournalAmount,
              AllocatedAmount: adj.AllocatedAmount,
              // UnallocatedAmount:
              //   adj.UnallocatedAmount - this.editTrans[0].UnallocatedAmount,
              UnallocatedAmount: this.editTrans[0].UnallocatedAmount,
              Type: adj.Type,
              _id: adj._id,
              fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
              Amount: adj.Amount,
              Description: adj.Description,
              TaxAccountCodeId: adj.TaxAccountCodeId,
              // destinationTaxAccountText: adj.destinationTaxAccountText,
              AbsoluteAmount: adj.AbsoluteAmount,
              DebitCredit: adj.DebitCredit,
              fk_ProposalId: adj.fk_ProposalId,
              // fk_TaxAccountCodeId: adj.fk_TaxAccountCodeId,
              fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
              OnlineAllocationElementId: adj.OnlineAllocationElementId,
              JournalId: adj.JournalId,
              JournalAmountText: this.pounds
                .format(adj.JournalAmount)
                .replace('£', ''),
              UnallocatedAmountText: this.pounds
                .format(adj.UnallocatedAmount)
                .replace('£', ''),
              TaxAccountDescription: adj.TaxAccountDescription,
              TaxAccount: adj.TaxAccount,
              SourceTaxAccount: adj.SourceTaxAccount,
              Key: adj.Key,
              OnlineAllocation: adj.OnlineAllocation,
              Transaction: adj.Transaction,
              Remove: adj.Remove,
              TransactionType: adj.TransactionType,
              LegalEntity: adj.LegalEntity,
              FiscalYear: adj.FiscalYear,
              DocumentType: adj.DocumentType,
              DocumentNo: adj.DocumentNo,
              TransactionId: adj.TransactionId,
              DocumentDate: adj.DocumentDate,
              PostingDate: adj.PostingDate,
              TransactionDate: adj.TransactionDate,
              Period: adj.Period,
              GLAccountNo: adj.GLAccountNo,
              GLAccountDescription: adj.GLAccountDescription,
              Currency: adj.Currency,
              CostCentre: adj.CostCentre,
              Vendor: adj.Vendor,
              Division: adj.Division,
              Product: adj.Product,
              Project: adj.Project,
              AssetCode: adj.AssetCode,
              VATCode: adj.VATCode,
              PurchaseOrder: adj.PurchaseOrder,
              Order: adj.Order,
              WBSElement: adj.WBSElement,
              ProfitCentre: adj.ProfitCentre,
              MatchID: adj.MatchID,
              Custom3: adj.Custom3,
              Custom4: adj.Custom4,
              Custom5: adj.Custom5,
              Custom6: adj.Custom6,
              Custom7: adj.Custom7,
            };
            // tr.JournalAmount = 0;
            // this.editService.update(this.addTransaction);
            this.editService.remove(adj);
            this.editService.create(this.addTransaction);
          }
        });
      }

      this.editService.value.map((adj: any) => {
        if (adj.UnallocatedAmount == 0) {
          this.editService.remove(adj);
        } else {
        }
      });

      this.isTransationEditClick = false;
    }

    this.fillSupplementayTaxAccounts();

    if (this.addTransactionsFinal.length > 0) {
      this.isNewButtonClick = true;
    }
    this.isGridVisible = true;
    this.convertTransactionsDataUkFormat();
  }

  closeAddTransaction() {
    if (this.isTransationAddClick) {
      this.editService.clean();

      this.bckTransactions.map((adj: any) => {
        this.addTransaction = {
          ID: adj.ID,
          DataRow: adj.DataRow,
          IsChecked: adj.IsChecked,
          IsUsed: adj.IsUsed,
          IsEdit: adj.isEdited,
          Mode: 'A',
          ShortDescription: adj.ShortDescription,
          TransactionTypeDescription: adj.TransactionTypeDescription,
          JournalAmount: adj.JournalAmount,
          AllocatedAmount: adj.AllocatedAmount,
          UnallocatedAmount: adj.UnallocatedAmount,
          Type: adj.Type,
          _id: adj._id,
          fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
          Amount: adj.Amount,
          Description: adj.Description,
          TaxAccountCodeId: adj.TaxAccountCodeId,
          // destinationTaxAccountText: adj.destinationTaxAccountText,
          AbsoluteAmount: adj.AbsoluteAmount,
          DebitCredit: adj.DebitCredit,
          fk_ProposalId: adj.fk_ProposalId,
          // fk_TaxAccountCodeId: adj.fk_TaxAccountCodeId,
          fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
          OnlineAllocationElementId: adj.OnlineAllocationElementId,
          JournalId: adj.JournalId,
          JournalAmountText: this.pounds
            .format(adj.JournalAmount)
            .replace('£', ''),
          UnallocatedAmountText: this.pounds
            .format(adj.UnallocatedAmount)
            .replace('£', ''),
          TaxAccountDescription: adj.TaxAccountDescription,
          TaxAccount: adj.TaxAccount,
          SourceTaxAccount: adj.SourceTaxAccount,
          Key: adj.Key,
          OnlineAllocation: adj.OnlineAllocation,
          Transaction: adj.Transaction,
          Remove: adj.Remove,
          TransactionType: adj.TransactionType,
          LegalEntity: adj.LegalEntity,
          FiscalYear: adj.FiscalYear,
          DocumentType: adj.DocumentType,
          DocumentNo: adj.DocumentNo,
          TransactionId: adj.TransactionId,
          DocumentDate: adj.DocumentDate,
          PostingDate: adj.PostingDate,
          TransactionDate: adj.TransactionDate,
          Period: adj.Period,
          GLAccountNo: adj.GLAccountNo,
          GLAccountDescription: adj.GLAccountDescription,
          Currency: adj.Currency,
          CostCentre: adj.CostCentre,
          Vendor: adj.Vendor,
          Division: adj.Division,
          Product: adj.Product,
          Project: adj.Project,
          AssetCode: adj.AssetCode,
          VATCode: adj.VATCode,
          PurchaseOrder: adj.PurchaseOrder,
          Order: adj.Order,
          WBSElement: adj.WBSElement,
          ProfitCentre: adj.ProfitCentre,
          MatchID: adj.MatchID,
          Custom3: adj.Custom3,
          Custom4: adj.Custom4,
          Custom5: adj.Custom5,
          Custom6: adj.Custom6,
          Custom7: adj.Custom7,
        };
        this.editService.create(this.addTransaction);
      });
    }

    if (this.isTransationEditClick) {
      this.addTransactionsFinal = [];

      this.bckAddTransactionsFinal.map((adj: any) => {
        this.addTransaction = {
          ID: adj.ID,
          DataRow: adj.DataRow,
          IsChecked: adj.IsChecked,
          IsUsed: adj.IsUsed,
          IsEdit: adj.isEdited,
          Mode: 'A',
          ShortDescription: adj.ShortDescription,
          TransactionTypeDescription: adj.TransactionTypeDescription,
          JournalAmount: adj.JournalAmount,
          AllocatedAmount: adj.AllocatedAmount,
          UnallocatedAmount: adj.UnallocatedAmount,
          Type: adj.Type,
          _id: adj._id,
          fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
          Amount: adj.Amount,
          Description: adj.Description,
          TaxAccountCodeId: adj.TaxAccountCodeId,
          // destinationTaxAccountText: adj.destinationTaxAccountText,
          AbsoluteAmount: adj.AbsoluteAmount,
          DebitCredit: adj.DebitCredit,
          fk_ProposalId: adj.fk_ProposalId,
          // fk_TaxAccountCodeId: adj.fk_TaxAccountCodeId,
          fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
          OnlineAllocationElementId: adj.OnlineAllocationElementId,
          JournalId: adj.JournalId,
          JournalAmountText: this.pounds
            .format(adj.JournalAmount)
            .replace('£', ''),
          UnallocatedAmountText: this.pounds
            .format(adj.UnallocatedAmount)
            .replace('£', ''),
          TaxAccountDescription: adj.TaxAccountDescription,
          TaxAccount: adj.TaxAccount,
          SourceTaxAccount: adj.SourceTaxAccount,
          Key: adj.Key,
          OnlineAllocation: adj.OnlineAllocation,
          Transaction: adj.Transaction,
          Remove: adj.Remove,
          TransactionType: adj.TransactionType,
          LegalEntity: adj.LegalEntity,
          FiscalYear: adj.FiscalYear,
          DocumentType: adj.DocumentType,
          DocumentNo: adj.DocumentNo,
          TransactionId: adj.TransactionId,
          DocumentDate: adj.DocumentDate,
          PostingDate: adj.PostingDate,
          TransactionDate: adj.TransactionDate,
          Period: adj.Period,
          GLAccountNo: adj.GLAccountNo,
          GLAccountDescription: adj.GLAccountDescription,
          Currency: adj.Currency,
          CostCentre: adj.CostCentre,
          Vendor: adj.Vendor,
          Division: adj.Division,
          Product: adj.Product,
          Project: adj.Project,
          AssetCode: adj.AssetCode,
          VATCode: adj.VATCode,
          PurchaseOrder: adj.PurchaseOrder,
          Order: adj.Order,
          WBSElement: adj.WBSElement,
          ProfitCentre: adj.ProfitCentre,
          MatchID: adj.MatchID,
          Custom3: adj.Custom3,
          Custom4: adj.Custom4,
          Custom5: adj.Custom5,
          Custom6: adj.Custom6,
          Custom7: adj.Custom7,
        };
        this.addTransactionsFinal.push(this.addTransaction);
      });
    }

    this.isTransationAddClick = false;
    this.isTransationEditClick = false;
    this.isGridVisible = true;
    this.convertTransactionsDataUkFormat();
  }

  public toggle(args: any): void {
    // console.log(args);
    args.IsChecked = !args.IsChecked;
    let transactionsFinalUnallocatedAmount;

    const val: any = this.addTransactionsFinal.filter(
      // (f: any) => f.DataRow == this.editTrans[0].DataRow
      (f: any) => f._id == args._id
    );

    if (val.length == 0) {
      args.IsUsed = false;
    } else {
      args.IsUsed = true;
      transactionsFinalUnallocatedAmount = val[0].UnallocatedAmount;
    }

    if (args.IsUsed == false) {
      this.addTransactionFilter = [];
      this.addTransactionFilter = this.addTransactionResponse.filter(
        // (s: AddTransaction) => s.DataRow == args.DataRow
        (s: AddTransaction) => s._id == args._id
      );

      if (this.addTransactionFilter.length > 0) {
        this.originalUnallocatedAmount =
          this.addTransactionFilter[0].UnallocatedAmount;
        this.originalJournalAmount = this.addTransactionFilter[0].JournalAmount;
      }
      if (args.IsChecked == true) {
        args.UnallocatedAmount = 0;
        args.JournalAmount = this.originalUnallocatedAmount;
      } else {
        args.UnallocatedAmount = this.originalUnallocatedAmount;
        args.JournalAmount = 0;
      }
    } else {
      if (args.IsChecked == true) {
        args.JournalAmount = args.UnallocatedAmount;
        args.UnallocatedAmount = 0;
      } else {
        // args.UnallocatedAmount = args.JournalAmount;
        args.UnallocatedAmount = transactionsFinalUnallocatedAmount;
        args.JournalAmount = 0;
      }
    }
    this.addTransaction = {
      ID: args.ID,
      DataRow: args.DataRow,
      IsChecked: args.IsChecked,
      IsUsed: args.IsUsed,
      IsEdit: args.isEdited,
      Mode: 'A',
      ShortDescription: args.ShortDescription,
      TransactionTypeDescription: args.TransactionTypeDescription,
      JournalAmount: args.JournalAmount,
      AllocatedAmount: args.AllocatedAmount,
      UnallocatedAmount: args.UnallocatedAmount,
      Type: args.Type,
      _id: args._id,
      fk_StatutoryAccountCodeId: args.fk_StatutoryAccountCodeId,
      Amount: args.Amount,
      Description: args.Description,
      TaxAccountCodeId: args.TaxAccountCodeId,
      // destinationTaxAccountText: args.destinationTaxAccountText,
      AbsoluteAmount: args.AbsoluteAmount,
      DebitCredit: args.DebitCredit,
      fk_ProposalId: args.fk_ProposalId,
      // fk_TaxAccountCodeId: args.fk_TaxAccountCodeId,
      fk_JournalTransactionTypeId: args.fk_JournalTransactionTypeId,
      OnlineAllocationElementId: args.OnlineAllocationElementId,
      JournalId: args.JournalId,
      JournalAmountText: this.pounds
        .format(args.JournalAmount)
        .replace('£', ''),
      UnallocatedAmountText: this.pounds
        .format(args.UnallocatedAmount)
        .replace('£', ''),
      TaxAccountDescription: args.TaxAccountDescription,
      TaxAccount: args.TaxAccount,
      SourceTaxAccount: args.SourceTaxAccount,
      Key: args.Key,
      OnlineAllocation: args.OnlineAllocation,
      Transaction: args.Transaction,
      Remove: args.Remove,
      TransactionType: args.TransactionType,
      LegalEntity: args.LegalEntity,
      FiscalYear: args.FiscalYear,
      DocumentType: args.DocumentType,
      DocumentNo: args.DocumentNo,
      TransactionId: args.TransactionId,
      DocumentDate: args.DocumentDate,
      PostingDate: args.PostingDate,
      TransactionDate: args.TransactionDate,
      Period: args.Period,
      GLAccountNo: args.GLAccountNo,
      GLAccountDescription: args.GLAccountDescription,
      Currency: args.Currency,
      CostCentre: args.CostCentre,
      Vendor: args.Vendor,
      Division: args.Division,
      Product: args.Product,
      Project: args.Project,
      AssetCode: args.AssetCode,
      VATCode: args.VATCode,
      PurchaseOrder: args.PurchaseOrder,
      Order: args.Order,
      WBSElement: args.WBSElement,
      ProfitCentre: args.ProfitCentre,
      MatchID: args.MatchID,
      Custom3: args.Custom3,
      Custom4: args.Custom4,
      Custom5: args.Custom5,
      Custom6: args.Custom6,
      Custom7: args.Custom7,
    };
    this.editService.update(this.addTransaction);
    this.convertTransactionsDataUkFormat();
  }

  fillSupplementayTaxAccounts() {
    this.supplementayFilteredTaxAccounts = [];
    this.supplementayTaxAccounts = [];

    this.addTransactionsFinal.map((tr) => {
      this.supplementayTaxAccount = {
        TaxAccountCodeId: 0,
        TaxAccountDescription: '',
      };
      this.supplementayTaxAccount.TaxAccountCodeId = tr.TaxAccountCodeId;
      // this.supplementayTaxAccount.TaxAccountDescription =
      //   tr.destinationTaxAccountText;ShortDescription
      this.supplementayTaxAccount.TaxAccountDescription = tr.ShortDescription;
      this.supplementayFilteredTaxAccounts =
        this.supplementayTaxAccounts.filter(
          (f) => f.TaxAccountCodeId == tr.TaxAccountCodeId
        );
      if (this.supplementayFilteredTaxAccounts.length == 0) {
        this.supplementayTaxAccounts.push(this.supplementayTaxAccount);
      }
    });
    this.convertTransactionsDataUkFormat();
  }

  public removeButtonClick(): void {
    this.fileName = '';
    this.fileUid = '';
    this.toastr.successToastr('File removed successfully.', 'Success');
  }

  getEditJournal(onlineAllocationId: number): void {
    this.isLoading = true;
    this.resetSaveMovementData();
    this.journalService.GetEditJournal(onlineAllocationId).subscribe(
      (response) => {
        this.editJournallResponse = response;

        this.fiscalYearValue = this.editJournallResponse.FiscalYear;

        this.getLegalEntityList(this.editJournallResponse.BusinessTypeId);

        this.saveMovement.FiscalYear = this.editJournallResponse.FiscalYear;
        this.saveMovement.BusinessTypeId =
          this.editJournallResponse.BusinessTypeId;
        this.saveMovement.LegalEntityId =
          this.editJournallResponse.LegalEntityID;
        this.saveMovement.JournalRef = this.editJournallResponse.Ref;
        this.saveMovement.JournalDescription =
          this.editJournallResponse.OnlineAllocationDescription;
        this.saveMovement.JournalNarrative =
          this.editJournallResponse.Narrative;
        this.saveMovement.JournalCategoryId =
          this.editJournallResponse.JournalCategoryId;

        this.saveMovement.JournalDate = this.editJournallResponse.JournalDate;

        this.saveMovement.TaxAccountCodeId =
          this.editJournallResponse.TargetTaxAccountCodeId;

        this.fileName =
          this.editJournallResponse.Name +
          '.' +
          this.editJournallResponse.FileExtension;
        this.fileUid = this.editJournallResponse.FileGUID;

        this.initForm();

        this.isLoading = false;

        this.getAddTransactionDetail(
          this.editJournallResponse.LegalEntityID,
          this.editJournallResponse.FiscalYear,
          this.editJournallResponse.StatutoryAccountCodeId,
          onlineAllocationId
        );
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }

  getTransactionDetail(onlineAllocationId: number): void {
    this.isLoading = true;
    this.resetSaveMovementData();
    this.journalService
      .GetAllTransaction(onlineAllocationId)
      .subscribe((response) => {
        this.saveTransactions = response;

        this.addTransactionsFinal = [];
        this.saveTransactions.map((adj, i) => {
          let destinationTaxAccount: any;
          this.filteredTaxAccounts = this.taxAccounts.filter(
            (s: TaxAccount) => s.TaxAccountCodeId == adj.TaxAccountCodeId
          );
          if (this.filteredTaxAccounts.length > 0) {
            destinationTaxAccount =
              this.filteredTaxAccounts[0].ShortDescription;
          }

          this.addTransaction = {
            ID: i + 1,
            DataRow: adj.DataRow,
            IsChecked: true,
            IsUsed: true,
            IsEdit: true,
            Mode: adj.Mode,
            ShortDescription: adj.ShortDescription,
            TransactionTypeDescription: adj.TransactionTypeDescription,
            JournalAmount: adj.JournalAmount,
            AllocatedAmount: adj.AllocatedAmount,
            UnallocatedAmount: adj.UnallocatedAmount,
            Type: adj.Type,
            _id: adj._id,
            fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
            Amount: adj.Amount,
            Description: adj.Description,
            TaxAccountCodeId: adj.TaxAccountCodeId,
            AbsoluteAmount: adj.AbsoluteAmount,
            DebitCredit: adj.DebitCredit,
            fk_ProposalId: adj.fk_ProposalId,
            fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
            OnlineAllocationElementId: adj.OnlineAllocationElementId,
            JournalId: adj.JournalId,
            JournalAmountText: this.pounds
              .format(adj.JournalAmount)
              .replace('£', ''),
            UnallocatedAmountText: this.pounds
              .format(adj.UnallocatedAmount)
              .replace('£', ''),
            TaxAccountDescription: adj.TaxAccountDescription,
            TaxAccount: adj.TaxAccount,
            SourceTaxAccount: adj.SourceTaxAccount,
            Key: adj.Key,
            OnlineAllocation: adj.OnlineAllocation,
            Transaction: adj.Transaction,
            Remove: adj.Remove,
            TransactionType: adj.TransactionType,
            LegalEntity: adj.LegalEntity,
            FiscalYear: adj.FiscalYear,
            DocumentType: adj.DocumentType,
            DocumentNo: adj.DocumentNo,
            TransactionId: adj.TransactionId,
            DocumentDate: adj.DocumentDate,
            PostingDate: adj.PostingDate,
            TransactionDate: adj.TransactionDate,
            Period: adj.Period,
            GLAccountNo: adj.GLAccountNo,
            GLAccountDescription: adj.GLAccountDescription,
            Currency: adj.Currency,
            CostCentre: adj.CostCentre,
            Vendor: adj.Vendor,
            Division: adj.Division,
            Product: adj.Product,
            Project: adj.Project,
            AssetCode: adj.AssetCode,
            VATCode: adj.VATCode,
            PurchaseOrder: adj.PurchaseOrder,
            Order: adj.Order,
            WBSElement: adj.WBSElement,
            ProfitCentre: adj.ProfitCentre,
            MatchID: adj.MatchID,
            Custom3: adj.Custom3,
            Custom4: adj.Custom4,
            Custom5: adj.Custom5,
            Custom6: adj.Custom6,
            Custom7: adj.Custom7,
          };
          this.addTransactionsFinal.push(this.addTransaction);
        });

        this.fillSupplementayTaxAccounts();

        this.isFileUploaded = true;

        this.isLoading = false;
      });
    this.convertTransactionsDataUkFormat();
  }

  getTransactionDetailAgainstCallingType(
    legalEntityId: number,
    fiscalYear: number,
    statutoryAccountCodeId: any,
    onlineAllocationId: number,
    transactions: string[]
  ): void {
    this.isLoading = true;
    this.addTransactionsFinal = [];
    // this.resetSaveMovementData();
    this.journalService
      .getAddTransactionDetail(
        legalEntityId,
        fiscalYear,
        statutoryAccountCodeId,
        onlineAllocationId
      )
      .subscribe((response) => {
        this.addTransactionCallingType = response;
        let addTransactionCallingTypeFilter: AddTransaction[];
        transactions.map((tr) => {
          addTransactionCallingTypeFilter =
            this.addTransactionCallingType.filter((f) => f._id == Number(tr));
          if (addTransactionCallingTypeFilter.length > 0) {
            this.addTransactionCallingTypeFinal.push(
              addTransactionCallingTypeFilter[0]
            );
          }
        });

        this.addTransactionsFinal = [];

        this.addTransactionCallingTypeFinal.map((adj, i) => {
          let destinationTaxAccount: any;
          this.filteredTaxAccounts = this.taxAccounts.filter(
            (s: TaxAccount) => s.TaxAccountCodeId == this.callingToTaxAccount
          );
          if (this.filteredTaxAccounts.length > 0) {
            destinationTaxAccount =
              this.filteredTaxAccounts[0].ShortDescription;
          }
          this.addTransaction = {
            ID: i + 1,
            DataRow: adj.DataRow,
            IsChecked: true,
            IsUsed: true,
            IsEdit: true,
            Mode: adj.Mode,
            // ShortDescription: adj.ShortDescription,
            ShortDescription: destinationTaxAccount,
            TransactionTypeDescription: adj.TransactionTypeDescription,
            JournalAmount:
              this.callingType == 'manageTransaction'
                ? adj.UnallocatedAmount
                : adj.JournalAmount,
            AllocatedAmount: adj.AllocatedAmount,
            UnallocatedAmount:
              this.callingType == 'manageTransaction'
                ? 0
                : adj.UnallocatedAmount,
            Type: adj.Type,
            _id: adj._id,
            fk_StatutoryAccountCodeId: adj.fk_StatutoryAccountCodeId,
            Amount: adj.Amount,
            Description: adj.Description,
            // TaxAccountCodeId: adj.TaxAccountCodeId,
            TaxAccountCodeId: this.callingToTaxAccount,
            AbsoluteAmount: adj.AbsoluteAmount,
            DebitCredit: adj.DebitCredit,
            fk_ProposalId: adj.fk_ProposalId,
            fk_JournalTransactionTypeId: adj.fk_JournalTransactionTypeId,
            OnlineAllocationElementId: adj.OnlineAllocationElementId,
            JournalId: adj.JournalId,
            JournalAmountText:
              this.callingType == 'manageTransaction'
                ? this.pounds.format(adj.UnallocatedAmount).replace('£', '')
                : this.pounds.format(adj.JournalAmount).replace('£', ''),
            UnallocatedAmountText:
              this.callingType == 'manageTransaction'
                ? this.pounds.format(0).replace('£', '')
                : this.pounds.format(adj.UnallocatedAmount).replace('£', ''),
            TaxAccountDescription: adj.TaxAccountDescription,
            TaxAccount: adj.TaxAccount,
            SourceTaxAccount: adj.SourceTaxAccount,
            Key: adj.Key,
            OnlineAllocation: adj.OnlineAllocation,
            Transaction: adj.Transaction,
            Remove: adj.Remove,
            TransactionType: adj.TransactionType,
            LegalEntity: adj.LegalEntity,
            FiscalYear: adj.FiscalYear,
            DocumentType: adj.DocumentType,
            DocumentNo: adj.DocumentNo,
            TransactionId: adj.TransactionId,
            DocumentDate: adj.DocumentDate,
            PostingDate: adj.PostingDate,
            TransactionDate: adj.TransactionDate,
            Period: adj.Period,
            GLAccountNo: adj.GLAccountNo,
            GLAccountDescription: adj.GLAccountDescription,
            Currency: adj.Currency,
            CostCentre: adj.CostCentre,
            Vendor: adj.Vendor,
            Division: adj.Division,
            Product: adj.Product,
            Project: adj.Project,
            AssetCode: adj.AssetCode,
            VATCode: adj.VATCode,
            PurchaseOrder: adj.PurchaseOrder,
            Order: adj.Order,
            WBSElement: adj.WBSElement,
            ProfitCentre: adj.ProfitCentre,
            MatchID: adj.MatchID,
            Custom3: adj.Custom3,
            Custom4: adj.Custom4,
            Custom5: adj.Custom5,
            Custom6: adj.Custom6,
            Custom7: adj.Custom7,
          };

          this.addTransactionsFinal.push(this.addTransaction);
        });
      });

    this.isGridDataLoaded = true;
    this.convertTransactionsDataUkFormat();
  }

  getGridSetting(): void {
    this.isLoading = true;
    this.gridColumns = [];
    this.gridSettings = [];
    this.OfflineReferralService.getGridSetting(
      'MovJournalAddTransGridColumns'
    ).subscribe(
      (response) => {
        this.gridSettings = response;

        let gridCol: string[] = [];

        this.gridSettings.map((gr, i) => {
          gridCol.push(gr.DbColumnName);
          if (gr.Selected == true) {
            if (
              gr.DbColumnName != 'Description' &&
              gr.DbColumnName != 'UnallocatedAmount' &&
              gr.DbColumnName != 'JournalAmount'
            ) {
              this.gridColumn = {
                field: gr.DbColumnName,
                title: gr.DisplayName,
                type: 'text',
              };
              this.gridColumns.push(this.gridColumn);
            }
          } else {
            this.hiddenColumns.push(gr.DisplayName);
          }
          if (
            gr.DbColumnName != 'Description' &&
            gr.DbColumnName != 'UnallocatedAmount' &&
            gr.DbColumnName != 'JournalAmount'
          ) {
            this.columns.push(gr.DisplayName);
          }
        });

        this.isLoading = false;
      },
      (error) => {
        // console.log(error);
        this.isLoading = false;
      }
    );
  }
  openWindow() {
    this.opened = true;
  }

  saveColumn() {
    this.saveChooseColumnSettings();
    this.openClose(false);
  }

  saveChooseColumnSettings() {
    this.isLoading = true;
    this.gridColumns = [];
    this.gridSettings.map((gr, i) => {
      if (this.hiddenColumns.includes(gr.DisplayName)) {
        gr.Selected = false;
      } else {
        gr.Selected = true;
        if (
          gr.DbColumnName != 'Description' &&
          gr.DbColumnName != 'UnallocatedAmount' &&
          gr.DbColumnName != 'JournalAmount'
        ) {
          this.gridColumn = {
            field: gr.DbColumnName,
            title: gr.DisplayName,
            type: 'text',
          };
          this.gridColumns.push(this.gridColumn);
        }
      }
    });

    this.OfflineReferralService.PostGridColumnList(
      this.gridSettings,
      'MovJournalAddTransGridColumns'
    ).subscribe(
      (response) => {
        if (response == 'true') {
          this.toastr.successToastr(
            'Selected Grid columns saved successfully.',
            'Success'
          );
        } else {
          this.toastr.errorToastr(
            'Selected Grid columns saved failed.',
            'Error'
          );
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  convertTransactionsDataUkFormat() {
    this.addTransactionsFinal.map((tr) => {
      tr.UnallocatedAmountText = this.pounds
        .format(Number(tr.UnallocatedAmount.toFixed(2)))
        .replace('£', '');
      tr.JournalAmountText = this.pounds
        .format(Number(tr.JournalAmount.toFixed(2)))
        .replace('£', '');
    });
  }
}
