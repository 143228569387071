import { Component, OnInit, Input } from '@angular/core';
import {
  DialogContentBase,
  ActionsLayout,
  DialogAction,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from '@progress/kendo-angular-dialog';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { TaxBusinessOutcomeService } from 'src/app/common/services/taxbusinessoutcomeservice/tax-business-outcome.service';
@Component({
  selector: 'app-delete-taxoutcomes',
  templateUrl: './delete-taxoutcomes.component.html',
  styleUrls: ['./delete-taxoutcomes.component.scss'],
})
export class DeleteTaxoutcomesComponent implements OnInit {
  public actionsLayout: ActionsLayout = 'normal';
  @Input() public delTaxoutcome: any;
  warnondelete: boolean = false;
  constructor(
    public dialog: DialogRef,
    public toastr: ToastrManager,
    private dialogService: DialogService,
    private globalService: GlobalService,
    private service: TaxBusinessOutcomeService
  ) {}

  ngOnInit(): void {}
  cancelClick() {
    this.dialog.close();
  }
  deleteTaxoutcome() {
    this.service.deleteBusinessOutcome(this.delTaxoutcome._id).subscribe(
      (res: any) => {
        if (res == 'Tax Outcome is deleted successfully') {
          this.toastr.successToastr(
            'Tax Outcome is deleted successfully',
            'Success'
          );
          this.dialog.close();
        } else if (res) {
          //this.dialog.close();
          this.warnondelete = true;
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
        }
      },
      (error) => {
        console.log(error);
        this.toastr.errorToastr(
          'Something went wrong try after sometime',
          'Oops!'
        );
        this.dialog.close();
      }
    );
  }
}
