import { Component, OnInit } from '@angular/core';
import {
  ActionsLayout,
  DialogCloseResult,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { process } from '@progress/kendo-data-query';
import { JournalService } from 'src/app/common/services/journal/journal-service';

import { ManageAdjustmentJournalComponent } from '../manage-adjustment-journal/manage-adjustment-journal.component';
import { Router } from '@angular/router';
import { OnlineAllocation } from 'src/app/_models/onlineAllocation';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DeleteJournalComponent } from '../delete-journal/delete-journal.component';

@Component({
  selector: 'app-manage-journals',
  templateUrl: './manage-journals.component.html',
  styleUrls: ['./manage-journals.component.scss'],
})
export class ManageJournalsComponent implements OnInit {
  constructor(
    private journalService: JournalService,
    private router: Router,
    private dialogService: DialogService,
    private toastr: ToastrManager
  ) {
    this.loadgridData();
  }

  onlineAllocations: OnlineAllocation[] = [];
  public gridData: OnlineAllocation[] = [];
  onlineAllocation!: OnlineAllocation;
  public actionsLayout: ActionsLayout = 'normal';
  isLoading: boolean = false;
  deleteOnlineAllocationList: number[] = [];

  ngOnInit(): void {
    this.getAllOnlineallocationList();
  }

  closeButtonClick() {}

  openAddJournal() {
    this.router.navigate(['/manageSelectJournal']);
  }

  getAllOnlineallocationList(): void {
    this.isLoading = true;
    this.journalService.getAllOnlineallocationList().subscribe(
      (response) => {
        this.onlineAllocations = response;
        this.onlineAllocations.map((adj) => {
          adj.IsChecked = false;
        });
        this.gridData = this.onlineAllocations;

        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  public editButtonClick(rowDetails: any): void {
    if (rowDetails.Type == 'Movement') {
      this.router.navigate([
        '/manageMovementJournal/edit/',
        rowDetails.OnlineAllocationId,
      ]);
    } else if (rowDetails.Type == 'Adjustment') {
      this.router.navigate([
        '/manageAdjustmentJournal/edit/',
        rowDetails.OnlineAllocationId,
      ]);
    }
  }

  deleteButtonClick(): void {
    if (this.deleteOnlineAllocationList.length == 0) {
      return;
    }
    const dialogRef = this.dialogService.open({
      content: DeleteJournalComponent,
      width: 500,
      height: 255,
    });
    const delJournal = dialogRef.content.instance as DeleteJournalComponent;
    dialogRef.result.subscribe((r: any) => {
      if (r === 'deleted') {
        this.deleteOnlineAllocationList = [];
        this.getAllOnlineallocationList();
      }
    });
    delJournal.deleteJournals = this.deleteOnlineAllocationList;
  }

  public onFilter(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.onlineAllocations = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'CreatedDate',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Type',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Ref',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Category',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'From',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'FiscalYear',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Description',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      this.loadgridData();
    }
  }
  public loadgridData(): void {
    this.getAllOnlineallocationList();
  }

  public toggle(args: OnlineAllocation): void {
    args.IsChecked = !args.IsChecked;
    if (args.IsChecked == true) {
      this.deleteOnlineAllocationList.push(args.OnlineAllocationId);
    } else {
      let bckDeleteOnlineAllocationList: number[] =
        this.deleteOnlineAllocationList;
      this.deleteOnlineAllocationList = [];
      bckDeleteOnlineAllocationList.map((adj) => {
        if (adj != args.OnlineAllocationId) {
          this.deleteOnlineAllocationList.push(adj);
        }
      });
    }
  }
}
