<div *ngIf="isLoading">
  <kendo-chunkprogressbar [chunkCount]="10" [min]="-10" [max]="10" [indeterminate]="true" [value]="30"
    style="width: 100%"></kendo-chunkprogressbar>
</div>
<!-- Header -->
<div class="header" style="margin-bottom: 0px; font-size: 20px">
  Adjustment journal
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <form class="k-form autoHeight" [formGroup]="addJournalForm" style="margin-top: 10px">
        <kendo-formfield>
          <kendo-label text="Financial year: " style="font-weight: bold">
            <span style="margin-left: 10px;">{{ fiscalYearValue == 0 ? "" : fiscalYearValue }}</span>
            <!-- &nbsp;&nbsp;&nbsp;<kendo-label>{{ fiscalYearValue == 0 ? "" : fiscalYearValue }}
            </kendo-label> -->
          </kendo-label>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="businessType" text="Business type:">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <kendo-combobox #businessType formControlName="businessType" [(ngModel)]="selectedBusinessType"
              [data]="businessTypes" style="width: 30%" textField="Description" valueField="BusinessTypeId"
              placeholder="Select business type" (valueChange)="handleBusinessTypeChange($event)"
              [disabled]="isTaxAmtData || mode == 'Edit'" [kendoDropDownFilter]="filterSettings">
            </kendo-combobox>
            &nbsp;*

            <!-- </kendo-formfield>  
                 <kendo-formfield> -->
            &nbsp;&nbsp;
            <!-- <kendo-label [for]="legalEntity" text="Legal entity:"> -->
            Legal entity: &nbsp;&nbsp;&nbsp;&nbsp;<kendo-combobox #legalEntity [kendoDropDownFilter]="filterSettings"
              formControlName="legalEntity" [data]="legalEntities" [(ngModel)]="selectedLegalEntity" style="width: 30%"
              textField="Description" valueField="ID" placeholder="Select legal entity"
              (valueChange)="handleLegalEntityChange($event)" [disabled]="isTaxAmtData || mode == 'Edit'">
            </kendo-combobox>
            &nbsp;*
            <!-- </kendo-label> -->
          </kendo-label>
        </kendo-formfield>
        <br />
        <kendo-tabstrip (tabClose)="onClose($event)" (tabSelect)="onSelect($event)">
          <kendo-tabstrip-tab title="Journal details" [selected]="true">
            <ng-template kendoTabContent>
              <kendo-formfield>
                <kendo-label [for]="journalRef" text="Journal ref:">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input formControlName="journalRef" style="width: 30%" kendoTextBox #journalRef />
                </kendo-label>
              </kendo-formfield>
              <kendo-formfield>
                <kendo-label [for]="journalDescription" text="Journal description:">
                  &nbsp;
                  <input formControlName="journalDescription" style="width: 30%" kendoTextBox #journalDescription />
                  &nbsp;*
                </kendo-label>
              </kendo-formfield>
              <kendo-formfield>
                <kendo-label [for]="journalNarrative" text="Journal narrative:">
                  &nbsp;&nbsp; &nbsp;&nbsp;
                  <input formControlName="journalNarrative" style="width: 30%" kendoTextBox #journalNarrative />
                </kendo-label>
              </kendo-formfield>
              <kendo-formfield>
                <kendo-label [for]="journalCategory" text="Journal category:">
                  &nbsp;&nbsp; &nbsp;&nbsp;<kendo-combobox #journalCategory formControlName="journalCategory"
                    [data]="journalCategories" style="width: 30%" textField="Name" valueField="JournalCategoryId"
                    placeholder="Select journal category" [kendoDropDownFilter]="filterSettings">
                  </kendo-combobox>
                  &nbsp;*
                </kendo-label>
              </kendo-formfield>
              <kendo-formfield>
                <kendo-label text="Journal period:">
                  &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                  <kendo-combobox #journalPeriod formControlName="journalPeriod" [data]="periods" style="width: 30%"
                    placeholder="Select journal period" [kendoDropDownFilter]="filterSettings"
                    (valueChange)="handleJournalPeriodChange($event)" *ngIf="isJourneyPeriodNew == false">
                  </kendo-combobox>
                  <input *ngIf="isJourneyPeriodNew" formControlName="journalPeriodNew" style="width: 30%" kendoTextBox
                    #journalPeriodNew />
                  &nbsp;*
                </kendo-label>
              </kendo-formfield>
              <kendo-formfield>
                <kendo-label [for]="journalDate" text="Journal date:">
                  &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                  <kendo-datepicker formControlName="journalDate" #journalDate [format]="format" [(value)]="value"
                    calendarType="classic">
                  </kendo-datepicker>
                  &nbsp;*
                </kendo-label>
              </kendo-formfield>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="Adjustment details">
            <ng-template kendoTabContent>
              <kendo-formfield>
                <kendo-label [for]="glAccount" text="GL account:">
                  &nbsp;&nbsp;
                  <kendo-combobox #glAccount formControlName="glAccount" [(ngModel)]="selectedGlAccount"
                    [data]="glTaxAccounts" style="width: 30%" textField="ShortDescription"
                    valueField="StatutoryAccountCodeId" placeholder="Select GL account"
                    [kendoDropDownFilter]="filterSettings" (valueChange)="handleGlAccountChange($event)">
                  </kendo-combobox>
                  &nbsp;&nbsp; Tax account: &nbsp;&nbsp;
                  <kendo-combobox #taxAccount formControlName="taxAccount" [(ngModel)]="selectedTaxAccount"
                    [data]="taxAccounts" style="width: 40%" textField="ShortDescription" valueField="TaxAccountCodeId"
                    placeholder="Select tax account" (valueChange)="handleTaxAccountChange($event)"
                    [kendoDropDownFilter]="filterSettings">
                  </kendo-combobox>
                </kendo-label>
              </kendo-formfield>
              <kendo-formfield>
                <kendo-label [for]="amount" text="Amount:">
                  &nbsp;&nbsp;
                  <input formControlName="amount" style="width: 10%; height: 90%" kendoTextBox #amount />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input type="radio" name="debitCredit" value="debit" #debit [(ngModel)]="debitCreditType"
                    kendoRadioButton formControlName="debitCredit" checked style="margin-top: 5px" />&nbsp;&nbsp;
                  <kendo-label [for]="debit" text="Debit" style="margin-top: 5px"></kendo-label>
                  &nbsp;&nbsp;
                  <input type="radio" name="debitCredit" value="credit" #credit [(ngModel)]="debitCreditType"
                    kendoRadioButton formControlName="debitCredit" style="margin-top: 5px" />&nbsp;&nbsp;
                  <kendo-label [for]="credit" text="Credit" style="margin-top: 5px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <ap-button kendoButton [btnType]="'secondary'" kendoTooltip title="New" [icon]="'plus-outline'"
                      [compact]="true" [add]="true" (onClick)="addTaxAmtClick()" style="margin-top: -4px"></ap-button>
                  </kendo-label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp; Check balance: &nbsp;&nbsp;
                  <kendo-textbox style="width: 17%; height: 90%" formControlName="totalAmount" [disabled]="true"
                    [(ngModel)]="totalAmt"></kendo-textbox>
                  &nbsp;&nbsp;
                  <!-- <button kendoButton class='actionButton btn-pwc-secondary' [disabled]="isGridEdited" (click)="checkBalanceClick()">Check Balance</button> -->
                  <!-- <ap-button kendoButton [btnType]="'secondary'" [label]="'Check Balance'" [disabled]="isGridEdited" (onClick)="checkBalanceClick()" ></ap-button>     -->
                </kendo-label>
              </kendo-formfield>
              <kendo-formfield>
                <kendo-grid #grid style="width: 100%; height: 210px" [data]="(view | async)!" [sort]="gridState.sort!"
                  [pageable]="false" [sortable]="true" (cellClick)="cellClickHandler($event)"
                  (cellClose)="cellCloseHandler($event)" (remove)="removeHandler($event)" [reorderable]="true"
                  [navigable]="true">
                  <kendo-grid-column field="TaxAccountDescription" title="Tax account"
                    [width]="500"></kendo-grid-column>
                  <kendo-grid-column field="DebitCredit" title="Debit / Credit" [width]="200"></kendo-grid-column>
                  <kendo-grid-column field="Amount" title="Amount" [width]="200" [hidden]="true"></kendo-grid-column>
                  <kendo-grid-column field="AmountText" title="Amount" [width]="200"></kendo-grid-column>
                  <kendo-grid-command-column title="" [width]="60">
                    <ng-template kendoGridCellTemplate let-isNew="isNew">
                      <button kendoTooltip title="Delete" kendoGridRemoveCommand>
                        <span class="Appkit4-icon icon-delete-outline"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-command-column>
                </kendo-grid>
              </kendo-formfield>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="Supplementary calculations">
            <ng-template kendoTabContent>
              1. Add supplementay calculations:
              <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl"
                (upload)="uploadEventHandler($event)" [withCredentials]="withCredential" [multiple]="false"
                (success)="success($event)" [restrictions]="supplementaryFileRestrictions" [chunkable]="chunkSettings"
                style="width: 60%">
              </kendo-upload>
              &nbsp;
              <div style="margin-bottom: 5px">
                File name:&nbsp; {{ fileName.replace("null.null", "") }}
              </div>
              <div *ngIf="isFileUploaded">
                <div class="d-grid gap-2 d-md-flex justify-content-md-start" style="margin-right: 100px">
                  <ap-button kendoButton [btnType]="'secondary'" [label]="'View'"
                    (onClick)="viewButtonClick()"></ap-button>

                  <ap-button kendoButton [btnType]="'secondary'" [label]="'Remove'"
                    (onClick)="removeButtonClick()"></ap-button>
                </div>
                <kendo-formfield>
                  <kendo-label [for]="supplementayTaxAccount" text="2. Select assicated tax account:">
                  </kendo-label>

                  <kendo-combobox #supplementayTaxAccount formControlName="supplementayTaxAccount"
                    [data]="supplementayTaxAccounts" style="width: 30%" textField="TaxAccountDescription"
                    valueField="TaxAccountCodeId" placeholder="Select tax account" [disabled]="!isFileUploaded">
                  </kendo-combobox>
                  &nbsp;*
                </kendo-formfield>
                <kendo-formfield>
                  <kendo-label [for]="includeSupplementay" text="3. Include supplementary calculations in the analysis
                    tax pack export.">
                    &nbsp;&nbsp;
                    <input type="checkbox" kendoCheckBox [disabled]="!isFileUploaded"
                      formControlName="includeSupplementay" #includeSupplementay />
                  </kendo-label>
                </kendo-formfield>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </form>

      <div class="clickToActions" style="padding: 20px 0px">
        <ap-button class="cancelButton okButton" kendoButton [btnType]="'secondary'" [label]="'Cancel'"
          (onClick)="closeButtonClick()"></ap-button>
        <ap-button class="okButton" kendoButton [btnType]="'primary'" [label]="'Save'" style="margin-right: 6px"
          [disabled]="!addJournalForm.valid || this.isGridEdited" (onClick)="saveJournal()"></ap-button>
      </div>
    </div>
  </div>
</div>