import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { CollectofflinereferralService } from 'src/app/common/services/collectOfflineReferral/collectofflinereferral.service';
import {
  ChunkSettings,
  FileInfo,
  FileRestrictions,
  SelectEvent,
  SuccessEvent,
  UploadEvent,
} from '@progress/kendo-angular-upload';
import { MenubarService } from 'src/app/common/menubarService/menubar.service';
import { Router } from '@angular/router';
import { ElementSchemaRegistry } from '@angular/compiler';
@Component({
  selector: 'app-collect-offline-referral',
  templateUrl: './collect-offline-referral.component.html',
  styleUrls: ['./collect-offline-referral.component.scss'],
})
export class CollectOfflineReferralComponent implements OnInit {
  activeIndex: number = 0;
  orientation = 'horizontal';
  readonly = true;
  space = 190;
  steps = [
    { label: 'Import wizard' },
    { label: 'Data validation' },
    { label: 'Create new accounts' },
    { label: 'Supplementary calculations' },
    { label: ' Importing data' },
  ];
  isLoading: boolean = false;
  uploadSaveUrl: string = '';
  baseUrl: string = '';
  apifolder: string = '';
  fileName: string = '';
  selectedClient: any;
  withCredential: boolean = false;
  validationError: boolean = false;
  validationErrorMessage: string = '';
  isTaxaccountsAvailable: boolean = false;
  importProcessingStartPayload: {} = {};
  processingOrchestrationId: string = '';
  isImportSuccess: boolean = false;
  isImportFailure: boolean = false;
  isErrorAvailable: boolean = false;
  public preProcessingImportData: any;
  afterImportDetails: any;
  taxAccounts: any = [];
  public chunkSettings: ChunkSettings = {
    size: 1048576,
  };
  myRestrictions: FileRestrictions = {
    allowedExtensions: ['.xls', '.xlsx'],
  };
  public model = {
    supplementaryCalc: 'Yes',
  };
  isCancelDisabled: boolean = false;
  isBackDisabled: boolean = true;
  isNextVisible: boolean = false;
  isNextDisabled: boolean = true;
  isConfirmOpen: boolean = false;
  importSummaryDetail: any;
  constructor(
    private cookieService: CookieService,
    private router: Router,
    public menubarService: MenubarService,
    public collectOfflineReferralService: CollectofflinereferralService
  ) {}

  ngOnInit(): void {
    this.menubarService.hide();
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    this.uploadSaveUrl =
      this.baseUrl +
      this.apifolder +
      '/OfflineReferral/UploadImportOfflineReferralFile?ClientdbName=' +
      this.selectedClient.DBName;
  }
  uploadEventHandler(e: UploadEvent) {
    let tokenValue: any = localStorage.getItem('OPENAMSESSION');
    e.headers = e.headers.append(
      'Authorization-Token',
      'Bearer ' + JSON.parse(tokenValue).access_token
    );
  }
  public success(e: SuccessEvent): void {
    if (e.files.length > 0) {
      this.fileName = e.files[0].name;
      // this.fileId = e.files[0].uid?.toString();

      // if (e.response.body.FileUploaded == true) {
      //   this.getSheetNames(this.fileId, this.fileName);
      // }
    }
    // this.isNextVisible = true;
    this.isNextDisabled = false;
  }
  handleBackButton() {
    if (this.activeIndex > 0) this.activeIndex = this.activeIndex - 1;
    if (this.activeIndex < 2) this.isCancelDisabled = false;
    if (this.activeIndex == 1) this.isCancelDisabled = true;
    if (this.activeIndex == 0) this.isBackDisabled = true;
  }
  handleNextButton() {
    //this.isNextDisabled = true;
    this.isBackDisabled = false;
    if (this.activeIndex == 0) this.validateImportOfflineReferral();
    if (this.activeIndex == 3) this.importOfflineReferralProcessing();
    if (this.activeIndex < this.steps.length - 1)
      this.activeIndex = this.activeIndex + 1;
  }
  validateImportOfflineReferral() {
    this.isLoading = true;
    this.collectOfflineReferralService
      .ValidateImportOfflineReferral(this.fileName)
      .subscribe(
        (response: any) => {
          if (response) {
            if (response.Status == true && response.TaxAccounts.length == 0)
              this.activeIndex = 3;
            else if (
              response.Status == true &&
              response.TaxAccounts.length > 0
            ) {
              this.activeIndex = 2;
              this.isTaxaccountsAvailable = true;
              this.taxAccounts = response.TaxAccounts;
            } else if (response.Status == false) {
              this.validationError = true;
              this.validationErrorMessage = response.FailedReason;
              this.isNextDisabled = true;
            }
            this.isLoading = false;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  importOfflineReferralProcessing() {
    this.isNextDisabled = true;
    this.isLoading = true;
    let IsSupplementaryVal: boolean = true;
    if (this.model.supplementaryCalc == 'Yes') IsSupplementaryVal = true;
    else IsSupplementaryVal = false;
    this.importProcessingStartPayload = {
      FileName: this.fileName,
      ClientdbName: this.selectedClient.DBName,
      IsSupplementary: IsSupplementaryVal,
      TriggerBy: '',
      OrchestrationId: '',
    };
    this.collectOfflineReferralService
      .importOfflineReferralProcessingStart(this.importProcessingStartPayload)
      .subscribe(
        (response: any) => {
          if (response) {
            this.processingOrchestrationId = response.orchestrationId;
            this.getProcessingStatus();
          }
          //this.isNextVisible = true;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  getProcessingStatus() {
    this.collectOfflineReferralService
      .getImportOfflineReferralProcessingStatus(this.processingOrchestrationId)
      .subscribe(
        (response: any) => {
          if (
            response.orchestrationRuntimeStatus == 'Completed' ||
            response.orchestrationRuntimeStatus == 'Failed'
          ) {
            this.isLoading = false;

            this.afterImportDetails = response;
            this.isImportSuccess = true;
            this.importSummaryDetail = response.summaryReport;
            // if (this.afterImportDetails.isAnyErrorRows) {
            //   this.isErrorAvailable = true;
            // }
          } else this.getProcessingStatus();
        },
        (error: any) => {
          console.log(error);
          return error;
        }
      );
  }
  downloadImportOfflineReferralReport() {
    this.collectOfflineReferralService
      .downloadImportOfflineReferralReport(
        this.afterImportDetails.detailedReportFileName
      )
      .subscribe(
        (response: any) => {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          const fileName = this.afterImportDetails.detailedReportFileName;
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  downloadOfflineReferralExcelFile() {
    this.collectOfflineReferralService
      .downloadOfflineReferralViewExcel(
        this.afterImportDetails.offlineReferralId
      )
      .subscribe(
        (response: any) => {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          const fileName = this.fileName;
          let blob: Blob = response.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
          this.isLoading = false;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  handleCancelButton() {
    this.isConfirmOpen = true;
  }
  finish() {
    this.menubarService.show();
    this.router.navigate(['/analysisreview']);
  }
  public close(status: string): void {
    if (status == 'yes') {
      this.isConfirmOpen = false;
      this.menubarService.show();
      this.router.navigate(['/analysisreview']);
    } else {
      this.isConfirmOpen = false;
    }
  }
}
