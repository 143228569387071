import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ShowGlAccounts } from 'src/app/_models/showGlaccounts';
import {
  TransactionsGroupFolder,
  GridSetting,
} from 'src/app/_models/transactionsGroupFolder';
import { ChooseColumn } from 'src/app/_models/choosecolumnsettings';

@Injectable({
  providedIn: 'root',
})
export class ShowDetailsService {
  private params: ShowGlAccounts[] = [];
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private mockApiUrl: any;
  private apiHost: string;
  private transactionParams: TransactionsGroupFolder[] = [];
  private saveColumn: ChooseColumn[] = [];
  tokenValue: any;
  constructor(
    public http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    //  this.apiPort = environment.apiPort;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getTransactionsforStatement: '/ShowDetails/GetTransactionsforStatement',
      getTransactionsforSubAnalysis:
        '/ShowDetails/GetTransactionsforSubAnalysis',
      getTransactionsforLineItem: '/ShowDetails/GetTransactionsforLineItem',
      showDetailsExportTransactions: '/ShowDetails/ExportTransactions',
      getGlAccountsDetails: '/ShowDetails/GetGLAccountDetails',
      showDetailsExportGLAccounts: '/ShowDetails/ExportGLAccount',
      getChooseColumnSetting: '/ShowDetails/GetChooseColumnSettings',
      saveChooseColumnSettings: '/ShowDetails/SaveChooseColumnSettings',
      GetAnalysisStmtColumnHeader: '/ShowDetails/GetAnalysisStmtColumnHeader',
      GetAnalysisStmtByID: '/ShowDetails/GetAnalysisStmtByID',
      GetGLAccountDetailsforStatement:
        '/ShowDetails/GetGLAccountDetailsforStatement',
      GetGLAccountDetailsforLineItem:
        '/ShowDetails/GetGLAccountDetailsforLineItem',
      GetGLAccountDetailsforSubAnalysis:
        '/ShowDetails/GetGLAccountDetailsforSubAnalysis',
      GetGLAccountDetailsforTaxAccount:
        '/ShowDetails/GetGLAccountDetailsforTaxAccount',
      GetTransactionCoulmnDetails: '/CommonEnlighten/GetGridColumnList',
      saveTransactionColumnSettings: '/CommonEnlighten/SaveGridColumnList',
      getCachedTransactionsforStatementCount:
        '/ShowDetails/GetCachedTransactionsforStatementCount',
      getCachedTransactionsforStatement:
        '/ShowDetails/GetCachedTransactionsforStatement',
      getCachedTransactionsforStatementFilter:
        '/ShowDetails/GetCachedTransactionsforStatementFilter',
      getCachedTransactionsforLineItem:
        '/ShowDetails/GetCachedTransactionsforLineItem',
      getCachedTransactionsforLineItemFilter:
        '/ShowDetails/GetCachedTransactionsforLineItemFilter',
      getCachedTransactionsforLineItemCount:
        '/ShowDetails/GetCachedTransactionsforLineItemCount',
      getCachedTransactionsforSubAnalysis:
        '/ShowDetails/GetCachedTransactionsforSubAnalysis',
      getCachedTransactionsforSubAnalysisFilter:
        '/ShowDetails/GetCachedTransactionsforSubAnalysisFilter',
      getCachedTransactionsforSubAnalysisCount:
        '/ShowDetails/GetCachedTransactionsforSubAnalysisCount',
      exportTransactionsData: '/ShowDetails/ExportTransactionsData',
      DownloadAnalysisStatement: '/ShowDetails/DownloadAnalysisStatement',
    };
  }

  getTransactionsforStatement(
    fiscalYear: number,
    statementId: number,
    legalEntityID: number
  ): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear)
      .set('statementID', statementId)
      .set('legalEntityID', legalEntityID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getTransactionsforStatement;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getTransactionsforLineItem(
    fiscalYear: number,
    lineItemID: number,
    legalEntityID: number
  ): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear)
      .set('lineItemID', lineItemID)
      .set('legalEntityID', legalEntityID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getTransactionsforLineItem;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getTransactionsforSubAnalysis(
    fiscalYear: number,
    blockID: number,
    legalEntityID: number,
    statementID: number
  ): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear)
      .set('blockID', blockID)
      .set('legalEntityID', legalEntityID)
      .set('statementID', statementID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getTransactionsforSubAnalysis;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getAnalysisStmtColumnHeader(statementId: number): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementId', statementId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.GetAnalysisStmtColumnHeader;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getAnalysisStmtByID(
    fiscalYear: number,
    statementId: number,
    guid: string
  ): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear)
      .set('statementId', statementId)
      .set('guid', guid);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.GetAnalysisStmtByID;
    return this.http.get<any[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  showDetailsExportTransactions(
    fiscalYear: number,
    statementId: number
  ): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear)
      .set('statementID', statementId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.showDetailsExportTransactions;
    return this.http.post(this.apiHost, this.transactionParams, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getGlAccountDetails(statementId: number): Observable<ShowGlAccounts[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementId', statementId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getGlAccountsDetails;
    return this.http.get<ShowGlAccounts[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  ExportGlAccount(statementID: number): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementID', statementID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.showDetailsExportGLAccounts;
    return this.http.post(this.apiHost, this.params, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getChooseColumnSetting(gridName: string): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('gridName', gridName);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getChooseColumnSetting;
    return this.http.get<any>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  saveChooseColumnSettings(saveColumn: any): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('userId', 1)
      .set('gridName', 'ShowDetailsColumns');
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.saveChooseColumnSettings;
    return this.http.post(this.apiHost, saveColumn, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }

  getGLAccountDetailsforStatement(
    fiscalYear: number,
    statementId: number,
    legalEntityID: number
  ): Observable<ShowGlAccounts[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear)
      .set('statementId', statementId)
      .set('legalEntityID', legalEntityID);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.GetGLAccountDetailsforStatement;
    return this.http.get<ShowGlAccounts[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getGLAccountDetailsforLineItem(
    fiscalYear: number,
    lineItemID: number,
    legalEntityID: number
  ): Observable<ShowGlAccounts[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear)
      .set('accountingAreaID', lineItemID)
      .set('legalEntityID', legalEntityID);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.GetGLAccountDetailsforLineItem;
    return this.http.get<ShowGlAccounts[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getGLAccountDetailsforSubAnalysis(
    fiscalYear: number,
    blockID: number,
    statementId: number,
    legalEntityID: number
  ): Observable<ShowGlAccounts[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear)
      .set('blockID', blockID)
      .set('statementId', statementId)

      .set('legalEntityID', legalEntityID);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.GetGLAccountDetailsforSubAnalysis;
    return this.http.get<ShowGlAccounts[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getCachedTransactionsforStatementCount(
    transactionsPayload: any
  ): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getCachedTransactionsforStatementCount;
    return this.http.post<any[]>(this.apiHost, transactionsPayload, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getCachedTransactionsforStatementFilter(
    transactionsPayload: any
  ): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getCachedTransactionsforStatementFilter;
    return this.http.post<any[]>(this.apiHost, transactionsPayload, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getCachedTransactionsforStatement(importPayload: any): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getCachedTransactionsforStatement;
    return this.http.post<any[]>(this.apiHost, importPayload, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getCachedTransactionsforLineItem(importPayload: any): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getCachedTransactionsforLineItem;
    return this.http.post<any[]>(this.apiHost, importPayload, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getCachedTransactionsforLineItemFilter(
    importPayload: any
  ): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getCachedTransactionsforLineItemFilter;
    return this.http.post<any[]>(this.apiHost, importPayload, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getCachedTransactionsforLineItemCount(importPayload: any): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getCachedTransactionsforLineItemCount;
    return this.http.post<any[]>(this.apiHost, importPayload, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getCachedTransactionsforSubAnalysis(importPayload: any): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getCachedTransactionsforSubAnalysis;
    return this.http.post<any[]>(this.apiHost, importPayload, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getCachedTransactionsforSubAnalysisFilter(
    importPayload: any
  ): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getCachedTransactionsforSubAnalysisFilter;
    return this.http.post<any[]>(this.apiHost, importPayload, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getCachedTransactionsforSubAnalysisCount(
    importPayload: any
  ): Observable<any[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getCachedTransactionsforSubAnalysisCount;
    return this.http.post<any[]>(this.apiHost, importPayload, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getTransactionColumnList(): Observable<ShowGlAccounts[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('gridName', 'ShowDetailsGridColumns');
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.GetTransactionCoulmnDetails;
    return this.http.get<ShowGlAccounts[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  saveTransactionColumnSettings(
    body: GridSetting[],
    gridName: string
  ): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('gridName', gridName);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.saveTransactionColumnSettings;

    return this.http.post<any>(this.apiHost, body, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }
  downloadTransactionData(payload: string): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.exportTransactionsData;
    return this.http.post(this.apiHost, payload, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
  downloadAnalysisStatement(
    fiscalYear: number,
    statementId: number,
    guid: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fiscalYear', fiscalYear)
      .set('statementId', statementId)
      .set('guid', guid);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.DownloadAnalysisStatement;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
