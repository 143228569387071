<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>
<!-- Header -->
<div class="header" style="margin-bottom: 0px; font-size: 20px">
  Manage journal
</div>
<div id="mainContent" class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <!-- search bar and action -->
      <div class="searchContainer">
        <ap-search
          [placeholder]="'Search here'"
          [searchType]="'secondary'"
          style="width: 100%; margin-right: 24px; padding: 0px !important"
          [disabled]="false"
          (onSearch)="onFilter($event)"
        ></ap-search>
        <ap-button
          [icon]="'delete-outline'"
          [compact]="true"
          [add]="true"
          (onClick)="deleteButtonClick()"
          kendoTooltip
          title="Delete"
        ></ap-button>
        <ap-button
          style="margin-left: 5px"
          [icon]="'plus-outline'"
          [compact]="true"
          [add]="true"
          (onClick)="openAddJournal()"
          kendoTooltip
          title="New"
        ></ap-button>
      </div>
      <!-- Data Grid -->
      <kendo-grid
        [kendoGridBinding]="onlineAllocations"
        [pageable]="true"
        [pageSize]="20"
        [sortable]="true"
        class="autoHeight"
        [reorderable]="true"
      >
        <kendo-grid-column
          field="IsChecked"
          title="Select"
          [width]="60"
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'left'
          }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <input
              type="checkbox"
              kendoCheckBox
              (click)="toggle(dataItem)"
              [checked]="dataItem.IsChecked"
            />
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'left'
          }"
          field="IsActive"
          title="Action"
          [width]="70"
        >
          <ng-template kendoGridCellTemplate let-data>
            <button
              kendoButton
              kendoTooltip
              title="Edit"
              (click)="editButtonClick(data)"
            >
              <span class="Appkit4-icon icon-edit-outline"></span>
            </button>
            <!-- &nbsp;
            <button kendoButton kendoTooltip title="Delete" (click)="deleteButtonClick(data)"><span class="Appkit4-icon icon-delete-outline"></span></button> -->
          </ng-template>
        </kendo-grid-column>
        <!-- <kendo-grid-column field="OnlineAllocationId" title="ID"  [headerStyle] ="{'font-weight' : 'bold','text-align': 'left', 'color': '#d04a02'}"></kendo-grid-column> -->
        <kendo-grid-column
          field="CreatedDate"
          title="Date Time"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="FiscalYear"
          title="Fiscal Year"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Type"
          title="Type"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Ref"
          title="Journal Ref"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Category"
          title="Category"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="From"
          title="From"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Description"
          title="Description"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
