import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { DataBindingDirective, slice } from '@progress/kendo-angular-grid';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from '../../common/globalservice/globalservice.service';
import { MyGlobalObject } from '../../common/myglobalobject';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { HabitatService } from 'src/app/common/services/habitatservice/habitat.service';
import { tick } from '@angular/core/testing';
import { Toastr, ToastrManager } from 'ng6-toastr-notifications';
import { HabitatsComponent } from '../habitats.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-managehabitats',
  templateUrl: './managehabitats.component.html',
  styleUrls: ['./managehabitats.component.scss'],
})
export class ManagehabitatsComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective | any;
  public actionsLayout: ActionsLayout = 'normal';
  public habitats: any[] = [];
  public gridData: any = [];
  selectedClient: string | any;

  businesstypeList: any = [];
  public selectedItem: any = this.businesstypeList[0];
  businesstypeListDB: any;

  folderList: any = [];
  public selectedItemFolder: any = '';
  folderListDB: any = [];

  checkboxSelected = false;
  checkboxDisabled = false;
  showCheckboxLabel = true;
  tempState = false;
  public gridViewScope: any[] = [];
  public mySelectionScope: string[] = [];
  public gridViewScopeSelected: any[] = [];
  public mySelectionScopeSelected: string[] = [];

  public gridViewMasterData: any[] = [];
  public mySelectionMasterData: string[] = [];
  public gridViewMasterDataSelected: any[] = [];
  public mySelectionMasterDataSelected: string[] = [];

  public gridViewKeyword: any[] = [];
  public mySelectionKeyword: string[] = [];
  public gridViewKeywordSelected: any[] = [];
  public mySelectionKeywordSelected: string[] = [];

  public gridView: any[] = [];
  public mySelection: string[] = [];

  @Input() data: any = {};
  @Input() action: any;
  habitat: any;

  tabs: any[] = [
    { label: 'Scope - Tax accounts' },
    { label: 'My master data' },
    { label: 'My keywords' },
  ];
  activeIndex: number = 0;
  selectedAccounts: any = [];
  selectedVocabulary: any = [];
  temp: any;
  isSet: boolean = false;
  actualgridViewScope: any = [];
  folderListDBFilter: any = [];

  constructor(
    // public dialog: DialogRef,
    private router: Router,
    private cookieService: CookieService,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    private globalService: GlobalService,
    private habitatService: HabitatService
  ) {}

  ngOnInit(): void {
    this.data = history.state.data;
    this.action = history.state.action;
    if (this.action == 'edit') {
      this.habitat = this.data.Name;
    } else {
      this.data = {};
      this.habitat = '';
      this.data.HabitatId = 0;
    }
    this.getRefData();

    this.selectedClient = localStorage.getItem('SELECTEDCLIENT');
  }

  async getFolderData() {
    // GetAllKeywordGroupFolder
    const response = await this.habitatService.getKeyword();
    this.folderList = [...JSON.parse(response)];
    this.folderListDBFilter = this.folderList;
    this.habitatService
      .getLinkHabitatVocabularyGroup(this.data.HabitatId)
      .then((res: any) => {
        this.folderListDB = JSON.parse(res);
        // console.log(this.folderListDB)
        // this.folderList=[...this.folderListDB.Item1]
        // this.folderListDB.Item1.forEach((response:any)=>{
        //   response.VocabularyGroupFolderId=1
        // })
        if (this.action == 'edit') {
          this.gridViewKeyword = [...this.folderListDB.Item1];
          // this.selectedItemFolder=this.folderList[0]
        }

        this.gridViewKeywordSelected = [...this.folderListDB.Item2];
        if (this.gridViewKeywordSelected == null) {
          this.gridViewKeywordSelected = [];
        }
        this.getKeywordData();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  getScopeGridData(action?: any) {
    let postData = {
      IsComplex: this.checkboxSelected,
      BusinessTypeId: this.selectedItem
        ? this.selectedItem.BusinessTypeId
        : '0',
      TaxAccountId: 0,
      HabitatId: this.data ? this.data.HabitatId : 0,
      TaxAccountDescription: '',
    };
    this.getTaxAccountDataByBusinessType(postData, action);
  }

  getTaxAccountDataByBusinessType(data: any, action: any) {
    this.habitatService
      .getTaxAccountDataByBusinessType(data)
      .then((res) => {
        this.gridViewScope = JSON.parse(res).Item1.filter((res: any) => {
          return (
            res.BusinessTypeId == data.BusinessTypeId &&
            !this.checkSelectedAccounts(res)
          );
        });
        if (JSON.parse(res).Item2 != null)
          this.gridViewScope = [
            ...this.gridViewScope,
            ...JSON.parse(res).Item2.filter((res: any) => {
              return (
                res.BusinessTypeId == data.BusinessTypeId &&
                !this.checkSelectedAccounts(res)
              );
            }),
          ];

        this.actualgridViewScope = [...this.gridViewScope];
        if (this.gridViewScopeSelected.length == 0 && !this.isSet) {
          this.isSet = true;
          this.gridViewScopeSelected = JSON.parse(res).Item2;
          if (this.gridViewScopeSelected == null) {
            this.gridViewScopeSelected = [];
          }
          this.selectedAccounts = [...this.gridViewScopeSelected];
        }

        if (action == 'checkBoxChange' && this.checkboxSelected) {
          this.gridViewScope = [
            ...this.gridViewScope.filter((res: any) => {
              return res.IsComplex == this.checkboxSelected;
            }),
          ];

          // this.gridViewScopeSelected=[...this.gridViewScopeSelected.filter((res:any)=>{
          //   console.log(res.IsComplex)
          //   console.log(action+" "+this.checkboxSelected)
          //   return res.IsComplex==this.checkboxSelected
          //   })]
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getHabitatMasterData() {
    this.habitatService
      .getHabitatMasterData(this.data.HabitatId)
      .then((res) => {
        this.gridViewMasterData = JSON.parse(res).Item1;
        this.gridViewMasterDataSelected = JSON.parse(res).Item2;
        if (this.gridViewMasterDataSelected == null) {
          this.gridViewMasterDataSelected = [];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getKeywordData() {
    if (this.folderListDB) {
      if (this.selectedItemFolder) {
        this.gridViewKeyword = [
          ...this.folderListDB.Item1.filter(
            (data: any) =>
              data.VocabularyGroupFolderId ==
                this.selectedItemFolder.VocabularyGroupFolderId &&
              !this.checkSelectedVocabulary(data)
          ),
        ];
      } else {
        this.gridViewKeyword = [];
      }
    }
  }

  closeButtonClick() {
    this.router.navigate(['./habitats']);
  }

  public selectedCallback = (args: any) => args.dataItem;

  getRefData() {
    this.globalService
      .getAllBusinessTypes()
      .then((res) => {
        this.businesstypeListDB = res;
        if (this.action == 'edit') {
          // this.selectedItem=res[0]
        } else {
          this.selectedItem = {};
        }
        this.businesstypeList = this.businesstypeListDB.slice();
        if (this.businesstypeList.length == 1)
          this.selectedItem = this.businesstypeList[0];
        this.getScopeGridData();

        this.getFolderData();

        this.getHabitatMasterData();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleBTchange(selBT: any) {
    this.getScopeGridData();
  }

  handleFDchange(sel: any) {
    this.getKeywordData();
  }

  onClickCheckBox(event: any): void {
    this.getScopeGridData('checkBoxChange');
  }

  handleFilterBusinessTypeId(value: any) {
    this.businesstypeList = this.businesstypeListDB.filter(
      (s: any) =>
        s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  handleFilterfolderList(value: any) {
    this.folderList = this.folderListDBFilter.filter(
      (s: any) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  saveChanges() {
    let data = {
      habitatModel: {
        HabitatId: this.action != 'edit' ? 0 : this.data.HabitatId,
        Name: this.habitat,
      },
      TaxAccountIds: this.gridViewScopeSelected
        .map((res: any) => {
          return res.TaxAccountId;
        })
        .toString(),
      MasterIds: this.gridViewMasterDataSelected
        .map((res: any) => {
          return res.HabitatExceptionMasterDataId;
        })
        .toString(),
      VocabularyGroupIds: this.gridViewKeywordSelected
        .map((res: any) => {
          return res.VocabularyGroupId;
        })
        .toString(),
    };
    this.temp = {
      HabitatId: this.action != 'edit' ? 0 : this.data.HabitatId,
      Name: this.habitat,
    };
    if (this.action == 'edit') {
      this.habitatService.editHabitat(data).then((res: any) => {
        this.toastr.successToastr(res);
        this.router.navigate(['./habitats']);
      });
    } else {
      this.habitatService.insertHabitat(data).then((res: any) => {
        this.toastr.successToastr(res);
        this.temp.Name = this.habitat;
        this.router.navigate(['./habitats']);
      });
    }
  }

  forwardScope() {
    this.moveData(
      'forward',
      this.mySelectionScope,
      this.mySelectionScopeSelected,
      this.gridViewScope,
      this.gridViewScopeSelected,
      'ltrscope'
    );
    this.onClickCheckBox('forwardScope');
  }
  backwardScope() {
    this.moveData(
      'backward',
      this.mySelectionScopeSelected,
      this.mySelectionScope,
      this.gridViewScopeSelected,
      this.gridViewScope,
      'rtlscope'
    );
    this.onClickCheckBox('forwardScope');
  }
  forwardScopeAll() {
    this.moveDataAll(
      this.gridViewScope,
      this.gridViewScopeSelected,
      'ltrscope'
    );
    this.onClickCheckBox('forwardScope');
  }
  backwardScopeAll() {
    this.moveDataAll(
      this.gridViewScopeSelected,
      this.gridViewScope,
      'rtlscope'
    );
    this.onClickCheckBox('forwardScope');
  }

  forwardMasterData() {
    this.moveDataMaster(
      this.mySelectionMasterData,
      this.mySelectionMasterDataSelected,
      this.gridViewMasterData,
      this.gridViewMasterDataSelected,
      'ltrmasterdata'
    );
  }
  backwardMasterData() {
    this.moveDataMaster(
      this.mySelectionMasterDataSelected,
      this.mySelectionMasterData,
      this.gridViewMasterDataSelected,
      this.gridViewMasterData,
      'rtlmasterdata'
    );
  }
  forwardMasterDataAll() {
    this.moveDataAllMaster(
      this.gridViewMasterData,
      this.gridViewMasterDataSelected,
      'ltrmasterdata'
    );
  }
  backwardMasterDataAll() {
    this.moveDataAllMaster(
      this.gridViewMasterDataSelected,
      this.gridViewMasterData,
      'rtlmasterdata'
    );
  }

  forwardKeyword() {
    this.moveDataKeyword(
      this.mySelectionKeyword,
      this.mySelectionKeywordSelected,
      this.gridViewKeyword,
      this.gridViewKeywordSelected,
      'ltrkeyword'
    );
  }
  backwardKeyword() {
    this.moveDataKeyword(
      this.mySelectionKeywordSelected,
      this.mySelectionKeyword,
      this.gridViewKeywordSelected,
      this.gridViewKeyword,
      'rtlkeyword'
    );
  }
  forwardKeywordAll() {
    this.moveDataAllKeyword(
      this.gridViewKeyword,
      this.gridViewKeywordSelected,
      'ltrkeyword'
    );
  }
  backwardKeywordAll() {
    this.moveDataAllKeyword(
      this.gridViewKeywordSelected,
      this.gridViewKeyword,
      'rtlkeyword'
    );
  }
  checkSelectedAccounts(grid: any) {
    for (let j = 0; j < this.selectedAccounts.length; j++) {
      if (
        grid.BusinessTypeId == this.selectedAccounts[j].BusinessTypeId &&
        grid.TaxAccountId == this.selectedAccounts[j].TaxAccountId
      ) {
        return true;
      }
    }
    return false;
  }
  checkSelectedVocabulary(grid: any) {
    for (let j = 0; j < this.selectedVocabulary.length; j++) {
      if (
        grid.VocabularyGroupFolderId ==
          this.selectedVocabulary[j].VocabularyGroupFolderId &&
        grid.VocabularyGroupId == this.selectedVocabulary[j].VocabularyGroupId
      ) {
        return true;
      }
    }
    return false;
  }

  getChildAccounts(taxAccountId: any) {
    return this.actualgridViewScope.filter(
      (item: any) => item.ParentTaxAccountId == taxAccountId
    );
  }
  moveData(
    action: any,
    selection: any,
    selectionSelected: any,
    grid: any,
    gridSelected: any,
    dir: any
  ) {
    if (dir == 'ltrscope') {
      for (let i = 0; i < selection.length; i++) {
        this.gridViewScopeSelected = [
          selection[i],
          ...this.gridViewScopeSelected,
        ];
        this.selectedAccounts.push(selection[i]);
        let values = this.getChildAccounts(selection[i].TaxAccountId);
        for (let i = 0; i < values.length; i++) {
          if (this.gridViewScopeSelected.indexOf(values[i]) < 0) {
            this.selectedAccounts.push(values[i]);
            this.gridViewScopeSelected = [
              values[i],
              ...this.gridViewScopeSelected,
            ];
          }
        }
        this.gridViewScope = [
          ...this.gridViewScope.filter(
            (item: any) =>
              item.TaxAccountId !== selection[i].TaxAccountId &&
              item.ParentTaxAccountId !== selection[i].TaxAccountId
          ),
        ];
      }
    }
    // if(dir=='ltrscope')
    // {
    //   this.gridViewScope=grid
    // }
    if (dir == 'rtlscope') {
      for (let i = 0; i < selection.length; i++) {
        let flag = false;
        //if (this.selectedItem.BusinessTypeId == selection[i].BusinessTypeId) {
        flag = true;
        // this.gridViewScope.push(selection[i])
        //   this.gridViewScope=[...this.gridViewScope]
        this.gridViewScopeSelected = [
          ...this.gridViewScopeSelected.filter((item: any) => {
            if (
              item.TaxAccountId == selection[i].TaxAccountId ||
              item.ParentTaxAccountId == selection[i].TaxAccountId
            ) {
              this.gridViewScope = [item, ...this.gridViewScope];
            }
            return (
              item.TaxAccountId !== selection[i].TaxAccountId &&
              item.ParentTaxAccountId != selection[i].TaxAccountId
            );
          }),
        ];
        //}
        // let tempSelectedAccount=[...this.selectedAccounts]
        for (let j = 0; j < this.selectedAccounts.length; j++) {
          if (
            selection[i].BusinessTypeId ==
              this.selectedAccounts[j].BusinessTypeId &&
            (selection[i].TaxAccountId ==
              this.selectedAccounts[j].TaxAccountId ||
              selection[i].TaxAccountId ==
                this.selectedAccounts[j].ParentTaxAccountId)
          ) {
            this.selectedAccounts.splice(j, 1);
            j--;
            flag = true;
            this.gridViewScopeSelected = [
              ...this.gridViewScopeSelected.filter((item: any) => {
                if (
                  item.TaxAccountId == selection[i].TaxAccountId ||
                  item.ParentTaxAccountId == selection[i].TaxAccountId
                ) {
                  this.gridViewScope = [item, ...this.gridViewScope];
                }
                return (
                  item.TaxAccountId !== selection[i].TaxAccountId &&
                  item.ParentTaxAccountId != selection[i].TaxAccountId
                );
              }),
            ];
            //  break;
          }
        }
      }
    }
    this.mySelection = [];
    this.mySelectionScope = [];
    this.mySelectionScopeSelected = [];

    this.gridViewScope = [
      ...this.gridViewScope.sort((a: any, b: any) =>
        a.TaxAccountDescription > b.TaxAccountDescription ? 1 : -1
      ),
    ];

    this.gridViewScopeSelected = [
      ...this.gridViewScopeSelected.sort((a: any, b: any) =>
        a.TaxAccountDescription > b.TaxAccountDescription ? 1 : -1
      ),
    ];

    if (!this.checkboxDisabled) {
    }
  }

  moveDataMaster(
    selection: any,
    selectionSelected: any,
    grid: any,
    gridSelected: any,
    dir: any
  ) {
    for (let i = 0; i < selection.length; i++) {
      gridSelected.push(selection[i]);
      grid = grid.filter(
        (item: any) =>
          item.HabitatExceptionMasterDataId !==
          selection[i].HabitatExceptionMasterDataId
      );

      if (dir == 'ltrmasterdata') {
        this.gridViewMasterData = grid;
      }
      if (dir == 'rtlmasterdata') {
        this.gridViewMasterDataSelected = grid;
      }
    }
    if (selection.length > 0) {
      gridSelected = gridSelected.sort((a: any, b: any) =>
        a.PropertyName > b.PropertyName ? 1 : -1
      );
    }
    this.mySelectionMasterData = [];
    this.mySelectionMasterDataSelected = [];
  }

  moveDataKeyword(
    selection: any,
    selectionSelected: any,
    grid: any,
    gridSelected: any,
    dir: any
  ) {
    if (dir == 'ltrkeyword') {
      for (let i = 0; i < selection.length; i++) {
        this.gridViewKeywordSelected = [
          selection[i],
          ...this.gridViewKeywordSelected,
        ];
        this.selectedVocabulary.push(selection[i]);
        // this.checkSelectedAccounts(grid,this.selectedAccounts)
        this.gridViewKeyword = [
          ...this.gridViewKeyword.filter(
            (item: any) =>
              item.VocabularyGroupId !== selection[i].VocabularyGroupId
          ),
        ];
      }
    }
    // if(dir=='ltrscope')
    // {
    //   this.gridViewScope=grid
    // }
    if (dir == 'rtlkeyword') {
      for (let i = 0; i < selection.length; i++) {
        let flag = false;
        if (
          this.selectedItemFolder.VocabularyGroupFolderId ==
          selection[i].VocabularyGroupFolderId
        ) {
          flag = true;
          this.gridViewKeyword.push(selection[i]);
          this.gridViewKeyword = [...this.gridViewKeyword];
        }
        this.gridViewKeywordSelected = [
          ...this.gridViewKeywordSelected.filter(
            (item: any) =>
              item.VocabularyGroupId !== selection[i].VocabularyGroupId
          ),
        ];

        for (let j = 0; j < this.selectedVocabulary.length; j++) {
          if (
            selection[i].VocabularyGroupId ==
            this.selectedVocabulary[j].VocabularyGroupId
          ) {
            this.selectedVocabulary.splice(j, 1);
            flag = true;
            this.gridViewKeywordSelected = [
              ...this.gridViewKeywordSelected.filter(
                (item: any) =>
                  item.VocabularyGroupId !== selection[i].VocabularyGroupId
              ),
            ];

            break;
          }
        }
        //  if(!flag){
        //  this.gridViewScope=[selection[i],...this.gridViewScope]

        // this.gridViewScopeSelected=[...this.gridViewScopeSelected.filter((item:any) => item.TaxAccountId !== selection[i].TaxAccountId)];
        //  }
      }
    }
    this.mySelectionKeyword = [];
    this.mySelectionKeywordSelected = [];

    this.gridViewKeyword = [
      ...this.gridViewKeyword.sort((a: any, b: any) =>
        a.VocabularyGroupName.toLowerCase() >
        b.VocabularyGroupName.toLowerCase()
          ? 1
          : -1
      ),
    ];

    this.gridViewKeywordSelected = [
      ...this.gridViewKeywordSelected.sort((a: any, b: any) =>
        a.VocabularyGroupName.toLowerCase() >
        b.VocabularyGroupName.toLowerCase()
          ? 1
          : -1
      ),
    ];
  }

  moveDataAll(fromData: any, toData: any, dir: any) {
    if (dir == 'ltrscope') {
      for (let i = 0; i < this.gridViewScope.length; i++) {
        this.selectedAccounts.push(this.gridViewScope[i]);
        this.gridViewScopeSelected.push(this.gridViewScope[i]);
      }
      this.gridViewScope = [];
    }
    if (dir == 'rtlscope') {
      for (let i = 0; i < this.gridViewScopeSelected.length; i++) {
        // if (
        //   this.gridViewScopeSelected[i].BusinessTypeId ==
        //   this.selectedItem.BusinessTypeId
        // ) {
        {
          this.gridViewScope.push(this.gridViewScopeSelected[i]);
        }
        //}
      }
      this.gridViewScopeSelected = [];
      this.selectedAccounts = [];
    }

    this.gridViewScope = [
      ...this.gridViewScope.sort((a: any, b: any) =>
        a.TaxAccountDescription > b.TaxAccountDescription ? 1 : -1
      ),
    ];

    this.gridViewScopeSelected = [
      ...this.gridViewScopeSelected.sort((a: any, b: any) =>
        a.TaxAccountDescription > b.TaxAccountDescription ? 1 : -1
      ),
    ];

    // for(let i=0;i<fromData.length;i++)
    // {
    //   toData.push(fromData[i])
    // }
    // toData=toData.sort((a:any, b:any) => a.TaxAccountDescription > b.TaxAccountDescription ? 1 : -1);
    // if(dir=="ltrscope")
    // {
    //   this.gridViewScope=[]
    // }
    // if(dir=="rtlscope")
    // {
    //   this.gridViewScopeSelected=[]
    // }

    this.mySelectionScope = [];
    this.mySelectionScopeSelected = [];
  }

  moveDataAllMaster(fromData: any, toData: any, dir: any) {
    for (let i = 0; i < fromData.length; i++) {
      toData.push(fromData[i]);
    }
    toData = toData.sort((a: any, b: any) =>
      a.PropertyName > b.PropertyName ? 1 : -1
    );

    if (dir == 'ltrmasterdata') {
      this.gridViewMasterData = [];
    }
    if (dir == 'rtlmasterdata') {
      this.gridViewMasterDataSelected = [];
    }
    this.mySelectionMasterData = [];
    this.mySelectionMasterDataSelected = [];
  }

  moveDataAllKeyword(fromData: any, toData: any, dir: any) {
    if (dir == 'ltrkeyword') {
      for (let i = 0; i < this.gridViewKeyword.length; i++) {
        this.selectedVocabulary.push(this.gridViewKeyword[i]);
        this.gridViewKeywordSelected.push(this.gridViewKeyword[i]);
      }
      this.gridViewKeyword = [];
    }
    if (dir == 'rtlkeyword') {
      for (let i = 0; i < this.gridViewKeywordSelected.length; i++) {
        if (
          this.gridViewKeywordSelected[i].VocabularyGroupFolderId ==
          this.selectedItemFolder.VocabularyGroupFolderId
        ) {
          {
            this.gridViewKeyword.push(this.gridViewKeywordSelected[i]);
          }
        }
      }
      this.gridViewKeywordSelected = [];

      this.selectedVocabulary = [];
    }

    this.mySelectionKeyword = [];
    this.mySelectionKeywordSelected = [];
  }
}
