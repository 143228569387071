<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>
<div class="header" style="font-size: 20px">GL account report</div>
<div class="row" style="margin-left: 0px; margin-right: 5px; height: 76vh">
  <form class="k-form" [formGroup]="statementDetailExportForm">
    <kendo-formfield>
      <div class="row">
        <div class="col-6" style="border: 0px solid; padding-top: 0%">
          <kendo-label text="Business type "></kendo-label>
          <kendo-combobox
            #businessType
            [data]="businessTypes"
            formControlName="businessType"
            textField="LookUpDescription"
            valueField="LookUpId"
            placeholder="Select business type"
            (valueChange)="handleBusinessTypeChange($event)"
            [(ngModel)]="selectedbusinessType"
            style="width: 100%"
            [kendoDropDownFilter]="filterSettings"
          >
          </kendo-combobox>
        </div>
        <div class="col-6" style="border: 0px solid">
          <kendo-label text="Legal entity "></kendo-label>
          <kendo-combobox
            #legalEntity
            formControlName="legalEntity"
            [data]="legalEntities"
            [(ngModel)]="selectedLegalEntity"
            style="width: 100%"
            textField="LookUpDescription"
            valueField="LookUpId"
            placeholder="Select legal entity"
            (valueChange)="handleLegalEntityChange($event)"
            [kendoDropDownFilter]="filterSettings"
          >
          </kendo-combobox>
        </div>
      </div>
    </kendo-formfield>
    <kendo-formfield>
      <div class="row">
        <div class="col-6" style="border: 0px solid">
          <kendo-label text="Analysis pack"></kendo-label>
          <kendo-combobox
            #analysisPack
            formControlName="analysisPack"
            [data]="analysisPacks"
            [(ngModel)]="selectedAnalysisPack"
            style="width: 100%"
            textField="LookUpDescription"
            valueField="LookUpId"
            placeholder="Select analysis pack"
            (valueChange)="handleAnalysisPackChange($event)"
            [kendoDropDownFilter]="filterSettings"
          >
          </kendo-combobox>
        </div>
        <div class="col-6" style="border: 0px solid">
          <kendo-label text="Statement options"></kendo-label>
          <kendo-combobox
            #statementOptions
            formControlName="statementOptions"
            [data]="statements"
            [(ngModel)]="value"
            style="width: 100%"
            textField="LookUpDescription"
            valueField="LookUpId"
            placeholder="Select statement options"
            (valueChange)="onValueChange($event)"
            [kendoDropDownFilter]="filterSettings"
          >
          </kendo-combobox>
        </div>
      </div>
    </kendo-formfield>
    <div class="row" style="margin-top: 8px">
      <span style="font-size: 16px !important"> <b>Reporting options</b> </span>
      <!-- <div class="col-8" style="display:flex;margin-top: 8px;">
        <div style="width:15%">
          <ap-checkbox
            #CreateTaxAccount
            [(ngModel)]="showPeriodList"
            (click)="subTotal()">
            <span style="font-size: 14px !important">sub-total</span>
          </ap-checkbox>
        </div>
      </div> -->
    </div>
    <div class="row">
      <div class="col-3">
        <div class="card-list">
          <mat-card>
            <mat-card-content>
              <div>
                <ul class="periodlist">
                  <li
                    class="period-list"
                    *ngFor="let period of periodArrayResponse"
                    (click)="getPeriodValue(period)"
                  >
                    <a
                      [class.active]="period === activeState"
                      class="period-active"
                    >
                      {{ period.Name }}</a
                    >
                  </li>
                </ul>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div class="col-5">
        <ap-button
          kendoButton
          [btnType]="'secondary'"
          class="mt-4"
          style="width: 50px; height: 37px; margin-bottom: 5px"
          (click)="onMoveUpButtonClick()"
          [disabled]="periodDisabled"
        >
          <span class="material-icons">keyboard_arrow_up</span>
        </ap-button>
        <ap-button
          kendoButton
          (click)="onMoveDownButtonClick()"
          [btnType]="'secondary'"
          style="width: 50px; height: 37px; margin-bottom: 5px"
          [disabled]="periodDisabled"
        >
          <span class="material-icons">keyboard_arrow_down</span>
        </ap-button>
      </div>
    </div>
    <div class="row">
      <div class="col-3 sub-total-right mt-2">
        <ap-button
          [btnType]="'primary'"
          style="width: 40%"
          (click)="subTotal()"
          kendoButton
          [label]="'Insert Sub Total'"
          class="okButton"
          [disabled]="subTotalDisabled"
        ></ap-button>
      </div>
    </div>
    <div class="run-button">
      <ap-button
        [btnType]="'primary'"
        (click)="exportExcelRun()"
        [label]="'Run'"
        class="okButton"
        [disabled]="!(selectedbusinessType && selectedLegalEntity)"
      ></ap-button>
    </div>
  </form>
</div>
