import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root',
})
export class HabitatService {
  private params: any;
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private mockApiUrl: any;
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    //  this.apiPort = environment.apiPort;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getAllHabitats: '/Habitat/GetAllHabitat',
      deleteHabitat: '/Habitat/DeleteHabitat',
      getTaxAccountDataByBusinessType:
        '/Habitat/GetTaxAccountDataByBusinessType',
      getHabitatMasterData: '/Habitat/GetHabitatMasterData',
      getLinkHabitatVocabularyGroup: '/Habitat/GetLinkHabitatVocabularyGroup',
      getKeyWord: '/Keyword/GetAllKeywordGroupFolder',
      reassignHabitat: '/Habitat/ReassignData',
      editHabitat: '/Habitat/UpdateHabitat',
      insertHabitat: '/Habitat/InsertHabitat',
    };
    //mock Json url to check in local
    this.mockApiUrl = {};
    // this.prepareRequests();
  }

  /*prepareRequests() {
    const headersObj = new Headers();
    console.log(headersObj)
    this.getArgs = {
      headers: headersObj,
      method: 'GET',
      withCredentials: false
    };
    this.postArgs = {
      headers: headersObj,
      method: 'POST',
      withCredentials: false
    };
    this.putArgs = {
      headers: headersObj,
      method: 'PUT',
      withCredentials: false
    };
    this.deleteArgs = {
      headers: headersObj,
      method: 'DELETE',
      withCredentials: false
    };
    this.formDataPostArgs = {
      headers: headersObj,
      method: 'POST',
      withCredentials: false
    };
  }*/
  insertHabitat(data: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.insertHabitat;

    return this.http
      .post(this.apiHost, data, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  editHabitat(data: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.editHabitat;

    return this.http
      .post(this.apiHost, data, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  postUserInfo(
    host: string,
    jsondata: any,
    access_token: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + access_token,
    };
    const headersObj = new HttpHeaders(headers);
    //const headersObj = new HttpHeaders();
    // headersObj.set('Content-Type', 'application/x-www-form-urlencoded');
    //headersObj.append('Authorization', 'Bearer ' + access_token);
    return this.http
      .post(host, jsondata, {
        headers: headersObj,
        withCredentials: false,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  fetchAllHabitats(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getAllHabitats;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }
  reassignHabitat(habitatId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.reassignHabitat;

    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  deleteHabitat(habitatId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('habitatId', habitatId);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.deleteHabitat;

    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getTaxAccountDataByBusinessType(data: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );

    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getTaxAccountDataByBusinessType;
    return this.http
      .post(this.apiHost, data, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }

  getHabitatMasterData(habitatId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('habitatId', habitatId);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getHabitatMasterData;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }

  getLinkHabitatVocabularyGroup(habitatId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('habitatId', habitatId);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getLinkHabitatVocabularyGroup;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }

  getKeyword(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );

    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getKeyWord;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }
}
