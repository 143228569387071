import {
  Component,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { MenusModule } from '@progress/kendo-angular-menu';
import { DialogService } from '@progress/kendo-angular-dialog';
import { MenuItem, MenuSelectEvent } from '@progress/kendo-angular-menu';
import { AppBarThemeColor } from '@progress/kendo-angular-navigation';
import { PopupModule } from '@progress/kendo-angular-popup';
import { BusinesstypegridpopupComponent } from '../businessType/businesstypegridpopup/businesstypegridpopup.component';
import { LegalentitygridpopupComponent } from '../legalEntity/legalentitygridpopup/legalentitygridpopup.component';
import { TaxaccountsgridpopupComponent } from '../taxaccounts/taxaccountsgridpopup/taxaccountsgridpopup.component';
import { GLaccountsgridpopupComponent } from '../glaccounts/glaccountsgridpopup/glaccountsgridpopup.component';
import { PopupService, PopupRef } from '@progress/kendo-angular-popup';
import { GlobalService } from '../common/globalservice/globalservice.service';
import { bordersInsideHorizontalIcon } from '@progress/kendo-svg-icons';
import { isWithStatement } from 'typescript';
import { AnalysisstatementgridpopupComponent } from '../analysisStatement/analysisstatementgridpopup/analysisstatementgridpopup.component';
import { LineitemsComponent } from '../lineitems/lineitems.component';
import { HabitatsComponent } from '../habitats/habitats.component';
import { KeywordGroupFormListComponent } from '../keyword/keyword-group-form-list/keyword-group-form-list.component';
import { MapGlAccountComponent } from '../map-gl-account/map-gl-account.component';
@Component({
  selector: 'app-menu',
  encapsulation: ViewEncapsulation.None,

  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  private popupRef: PopupRef | any;
  popupService: any;
  public data: any[] = [
    {
      text: 'Dashboard',
      click: (): void => {},
    },
    {
      text: 'Info',
      click: (): void => {},
    },
    {
      text: 'Transaction Data Assist Help',
      click: (): void => {},
    },
    {
      text: 'Exit',
      click: (): void => {},
    },
  ];

  constructor(
    private globalService: GlobalService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {}
  public onPaste(): void {}

  public handleSelectionChange(event: MenuSelectEvent): void {
    // process the event only if it's a leaf item
    if (event.item.text == 'Business Types') this.openBusinessTypeDialog();
    else if (event.item.text == 'Legal Entities') this.openLegalEntityDialog();
    else if (event.item.text == 'Analysis Statement')
      this.openAnalysisStatementDialog();
    else if (event.item.text == 'Line Items') this.openLineItemsDialog();
    else if (event.item.text == 'Tax Accounts') this.openTaxAccountsDialog();
    else if (event.item.text == 'Habitats') this.openHabitatsDialog();
    else if (event.item.text == 'GL Accounts') this.openGLAccountsDialog();
    else if (event.item.text == 'Keyword Groups')
      // this.openKeywordGroupDialogForm();
      this.openKeywordGroupListDialogForm();
    else if (event.item.text == 'Map GL Accounts') this.mapGLAccountsDialog();
  }
  public mapGLAccountsDialog(): void {
    const dialogRef1 = this.dialogService.open({
      content: MapGlAccountComponent,
      width: 1000,
      height: 600,
    });
    const clientInfo1 = dialogRef1.content.instance as MapGlAccountComponent;
    dialogRef1.result.subscribe((r1: any) => {});
  }
  public onSelect(e: { index: string }, template: TemplateRef<any>) {
    if (e.index === '0_0') {
      this.togglePopup(template);
    }
  }

  public togglePopup(template: TemplateRef<any>) {
    if (this.popupRef) {
      this.popupRef.close();
      this.popupRef = null;
    } else {
      this.popupRef = this.popupService.open({
        content: template,
        offset: { top: 100, left: 100 },
      });
    }
  }
  public openBusinessTypeDialog(): void {
    const dialogRef = this.dialogService.open({
      content: BusinesstypegridpopupComponent,
      width: 1000,
      height: 600,
    });

    const clientInfo = dialogRef.content
      .instance as BusinesstypegridpopupComponent;

    dialogRef.result.subscribe((r: any) => {});
  }
  public openLegalEntityDialog(): void {
    const dialogRef1 = this.dialogService.open({
      content: LegalentitygridpopupComponent,
      width: 1200,
      height: 600,
    });
    const clientInfo1 = dialogRef1.content
      .instance as LegalentitygridpopupComponent;
    dialogRef1.result.subscribe((r1: any) => {});
  }
  public openAnalysisStatementDialog(): void {
    const dialogRef1 = this.dialogService.open({
      content: AnalysisstatementgridpopupComponent,
      width: 1200,
      height: 600,
    });
    const clientInfo1 = dialogRef1.content
      .instance as AnalysisstatementgridpopupComponent;
    dialogRef1.result.subscribe((r1: any) => {});
  }
  public openLineItemsDialog(): void {
    const dialogRef1 = this.dialogService.open({
      content: LineitemsComponent,
      width: 1200,
      height: 600,
    });
    const clientInfo1 = dialogRef1.content.instance as LineitemsComponent;
    dialogRef1.result.subscribe((r1: any) => {});
  }
  public openTaxAccountsDialog(): void {
    const dialogRef1 = this.dialogService.open({
      content: TaxaccountsgridpopupComponent,
      width: 1350,
      height: 600,
    });
    const clientInfo1 = dialogRef1.content
      .instance as TaxaccountsgridpopupComponent;
    dialogRef1.result.subscribe((r1: any) => {});
  }
  public openGLAccountsDialog(): void {
    const dialogRef1 = this.dialogService.open({
      content: GLaccountsgridpopupComponent,
      width: 1300,
      height: 600,
    });
    const clientInfo1 = dialogRef1.content
      .instance as GLaccountsgridpopupComponent;
    dialogRef1.result.subscribe((r1: any) => {});
  }
  public openHabitatsDialog(): void {
    const dialogRef1 = this.dialogService.open({
      content: HabitatsComponent,
      width: 1200,
      height: 600,
    });
    const clientInfo1 = dialogRef1.content.instance as HabitatsComponent;
    dialogRef1.result.subscribe((r1: any) => {});
  }

  public openKeywordGroupListDialogForm(): void {
    const dialogRef = this.dialogService.open({
      content: KeywordGroupFormListComponent,
      width: 600,
      height: 600,
    });
  }
}
