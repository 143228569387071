import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { StatementDetailExportType } from 'src/app/_models/statementDetailExportType';
import {
  statementDetailTemplateProcessingStartBody,
  statementDetailTemplateProcessingStartResponse,
} from 'src/app/_models/statementDetailTemplateProcessingStart.ts';

@Injectable({
  providedIn: 'root',
})
export class StatementDetailService {
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private apiHost: string;
  private importAPIUrl: string = '';
  tokenValue: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.importAPIUrl = environment.importapiUrl;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getAllBusinessTypeDetail: '/StatementDetails/GetAllBusinessTypeDetail',
      getLegalEntitiesByBusinessTypeId:
        '/StatementDetails/GetLegalEntitiesByBusinessTypeId',
      getStatementGroupsByBusTypeAndLegalEntityId:
        '/StatementDetails/GetStatementGroupsByBusTypeAndLegalEntityId',
      getAnalysisStatementsForStatementGroupId:
        '/StatementDetails/GetAnalysisStatementsForStatementGroupId',
      exportStatementDetailTemplateProcessingStart:
        '/ExportStatementDetailTemplateProcessingStart',
      getExportStatementDetailTemplateProcessingStatus:
        '/GetExportStatementDetailTemplateProcessingStatus/',
      downloadStatementDetailTemplateExcelFile:
        '/DownloadStatementDetailTemplateExcelFile',
      DownloadStatementDetailImportReportTextFile:
        '/DownloadStatementDetailImportReportTextFile',
    };
  }
  getAllBusinessTypeDetail(): Observable<StatementDetailExportType[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllBusinessTypeDetail;
    return this.http.get<StatementDetailExportType[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  GetLegalEntitiesByBusinessTypeId(
    businessTypeId: any
  ): Observable<StatementDetailExportType[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('businessTypeId', businessTypeId);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getLegalEntitiesByBusinessTypeId;
    return this.http.get<StatementDetailExportType[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  GetStatementGroupsByBusTypeAndLegalEntityId(
    businessTypeId: number,
    legalEntityId: number
  ): Observable<StatementDetailExportType[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('businessTypeId', businessTypeId)
      .set('legalEntityId', legalEntityId);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getStatementGroupsByBusTypeAndLegalEntityId;
    return this.http.get<StatementDetailExportType[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  GetAnalysisStatementsForStatementGroupId(
    statementGroupId: number
  ): Observable<StatementDetailExportType[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementGroupId', statementGroupId);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getAnalysisStatementsForStatementGroupId;
    return this.http.get<StatementDetailExportType[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  PostExportStatementDetailTemplateProcessingStart(
    body: statementDetailTemplateProcessingStartBody
  ): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.exportStatementDetailTemplateProcessingStart;
    return this.http.post(this.apiHost, body, {
      headers: headersObj,
      withCredentials: false,
    });
  }

  GetExportStatementDetailTemplateProcessingStatus(
    instanceId: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.getExportStatementDetailTemplateProcessingStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      observe: 'response',
      //responseType: 'blob' as 'json',
    });
  }

  downloadStatementDetailTemplateExcelFile(
    generatedExportFileName: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementDetailTemplateExcelFileName', generatedExportFileName);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.downloadStatementDetailTemplateExcelFile;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
  DownloadStatementDetailImportReportTextFile(
    importReportTextFileName: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('importReportTextFileName', importReportTextFileName);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.DownloadStatementDetailImportReportTextFile;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'text',
    });
  }
}
