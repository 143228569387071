import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MyGlobalObject } from '../../myglobalobject';
import { StatementDetailExportType } from 'src/app/_models/statementDetailExportType';
import { Worksheet } from 'src/app/_models/worksheet';
@Injectable({
  providedIn: 'root',
})
export class ImportstatementdetailsService {
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any = {};
  public importAPIUrl: string = '';
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.importAPIUrl = environment.importapiUrl;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      GetAllBusinessTypeDetail: '/StatementDetails/GetAllBusinessTypeDetail/',
      GetLegalEntitiesByBusinessTypeId:
        '/StatementDetails/GetLegalEntitiesByBusinessTypeId/',
      GetStatementGroupsByBusTypeAndLegalEntityId:
        '/StatementDetails/GetStatementGroupsByBusTypeAndLegalEntityId',
      GetStatementDetailTemplateSheetName:
        '/StatementDetails/GetStatementDetailTemplateSheetName',
      ImportStatementDetailTemplateProcessingStart:
        '/ImportStatementDetailTemplateProcessingStart',
      GetImportStatementDetailTemplateProcessingStatus:
        '/GetImportStatementDetailTemplateProcessingStatus/',
      DownloadStatementDetailImportReportTextFile:
        '/DownloadStatementDetailImportReportTextFile',
    };
  }
  getAllBusinessTypeDetail(): Observable<StatementDetailExportType[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.GetAllBusinessTypeDetail;
    return this.http.get<any>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getLegalEntitiesByBusinessTypeId(
    businessTypeId: number
  ): Observable<StatementDetailExportType[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('businessTypeId', businessTypeId);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.GetLegalEntitiesByBusinessTypeId;
    return this.http.get<any>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getStatementGroupsByBusTypeAndLegalEntityId(
    businessTypeId: number,
    legalEntityId: number
  ): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('businessTypeId', businessTypeId)
      .set('legalEntityId', legalEntityId);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.GetStatementGroupsByBusTypeAndLegalEntityId;
    return this.http.get<any>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getSheetNames(fileName: string): Observable<Worksheet[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fileName', fileName);

    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.GetStatementDetailTemplateSheetName;
    return this.http.get<Worksheet[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  importStatementDetailTemplateProcessingStart(statDetailsStartPayload: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.ImportStatementDetailTemplateProcessingStart;
    return this.http.post(this.apiHost, statDetailsStartPayload, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  getImportStatementDetailTemplateProcessingStatus(instanceId: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.GetImportStatementDetailTemplateProcessingStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  downloadStatementDetailImportReportTextFile(
    importReportTextFileName: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'importReportTextFileName',
      importReportTextFileName
    );
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.DownloadStatementDetailImportReportTextFile;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'text',
    });
  }
}
