<!--**** Popup  for submenu Move in Main Menu of all nodes in Treelist *****-->
<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Move</div>
</kendo-dialog-titlebar>
<!-- <kendo-dialog title="Move" *ngIf="openedMove" (close)="closeMovePopup('cancel')" [minWidth]="250" [width]="450"> -->

<form class="k-form" [formGroup]="form">
  <kendo-formfield showHints="initial">
    <label class="k-label">Type of Confirmation</label>
    <ul class="k-radio-list">
      <li class="k-radio-item">
        <input
          type="radio"
          #up
          value="up"
          [disabled]="!statementStatus.Up"
          name="selectionvalue"
          kendoRadioButton
          (change)="onChange($event)"
        />
        <kendo-label [for]="up" text="Up"></kendo-label>
      </li>
      <li class="k-radio-item">
        <input
          type="radio"
          #down
          value="down"
          [disabled]="!statementStatus.Down"
          name="selectionvalue"
          kendoRadioButton
          (change)="onChange($event)"
        />
        <kendo-label [for]="down" text="Down"></kendo-label>
      </li>
      <li class="k-radio-item">
        <input
          type="radio"
          #top
          value="top"
          [disabled]="!statementStatus.Top"
          name="selectionvalue"
          kendoRadioButton
          (change)="onChange($event)"
        />
        <kendo-label [for]="top" text="Top"></kendo-label>
      </li>
      <li class="k-radio-item">
        <input
          type="radio"
          #bottom
          value="bottom"
          [disabled]="!statementStatus.Bottom"
          name="selectionvalue"
          kendoRadioButton
          (change)="onChange($event)"
        />
        <kendo-label [for]="bottom" text="Bottom"></kendo-label>
      </li>
    </ul>

    <kendo-formerror>Error: This field is required</kendo-formerror>
  </kendo-formfield>
</form>
<div class="clickToActions" style="padding: 10px 0px 0px 0px">
  <ap-button
    [btnType]="'secondary'"
    [label]="'Cancel'"
    (click)="cancelClick()"
    class="cancelButton"
    style="width: 100px"
  ></ap-button>
  <ap-button
    [btnType]="'primary'"
    [label]="'OK'"
    themeColor="primary"
    (click)="moveProceed()"
    class="okButton"
    style="margin-right: 6px"
  ></ap-button>
</div>
