<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Manage line item</div>
</kendo-dialog-titlebar>
<div>
  <form class="k-form" [formGroup]="newLineItemForm">
    <kendo-formfield>
      <kendo-label text="" style="font-weight: bold">
        Line item details - Business type:
        {{ AccountingAreaDescriptionListDB?.TaxChartDescription }}</kendo-label
      >
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="Description" text="Description:*"></kendo-label>
      <input
        formControlName="Description"
        kendoTextBox
        #Description
        [maxlength]="100"
        required
      />
      <kendo-formerror>{{ descriptionErrorMessage }}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [for]="AccountClass" text="Account Class:*"></kendo-label>
      <kendo-combobox
        #AccountClass
        required
        formControlName="AccountClass"
        [data]="AccountClassDescriptionList"
        [filterable]="true"
        textField="Description"
        valueField="ID"
        (valueChange)="handleAccountClassChange($event)"
        [kendoDropDownFilter]="filterSettings"
      >
      </kendo-combobox>
      <div *ngIf="Complex == 2">
        <kendo-formerror>{{ complexMessage }}</kendo-formerror>
      </div>
      <div *ngIf="Complex == 1">
        <kendo-formerror>{{ accountclassErrorMessage }}</kendo-formerror>
      </div>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [for]="ParentLineItem"
        text="Parent line item:"
      ></kendo-label>
      <kendo-combobox
        #ParentLineItem
        formControlName="ParentLineItem"
        [data]="AccountingAreaDescriptionList"
        [filterable]="true"
        textField="Description"
        valueField="LineID"
        (valueChange)="handleParentLineItemChange($event)"
        (filterChange)="handleFilterParentLineItem($event)"
        [kendoDropDownFilter]="filterSettings"
      >
      </kendo-combobox>
    </kendo-formfield>

    <br />
    <kendo-formfield>
      <kendo-label [for]="CreateTaxAccount" text="Create tax account:"
        >&nbsp;&nbsp;
        <ap-checkbox
          formControlName="CreateTaxAccount"
          #CreateTaxAccount
          (onChange)="onHighlightClick($event)"
        >
        </ap-checkbox>
      </kendo-label>
    </kendo-formfield>
  </form>
</div>
<!-- <mat-card class="userCard" style="padding: 0px">
  <mat-card-title>
    <h6>
     
    </h6>
  </mat-card-title>
  <mat-card-content>
    <div>
      
    </div>
  </mat-card-content>
</mat-card> -->

<div class="clickToActions" style="padding-top: 10px">
  <ap-button
    [btnType]="'secondary'"
    class="cancelButton"
    [label]="'Cancel'"
    (onClick)="cancelClick()"
  ></ap-button>

  <ap-button
    [btnType]="'primary'"
    class="okButton"
    [disabled]="!newLineItemForm.valid"
    [label]="'Save'"
    (onClick)="saveLineItem()"
    style="margin-right: 6px"
  ></ap-button>
</div>
