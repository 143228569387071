import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Worksheet } from 'src/app/_models/worksheet';
@Injectable({
  providedIn: 'root',
})
export class ImportService {
  private selectedClient: any;
  private baseUrl: string;
  private importAPIUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.importAPIUrl = environment.importapiUrl;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getAllSheetsTrialBalance: '/TrialBalance/GetSheetNames',
      getAllSheetsTransactions: '/TransactionImport/TransactionGetSheetNames',
      importTBPrePrcessingStart: '/ImportTbPreprocessingStart',
      importTBPrePrcessingStatus: '/GetImportTbPreprocessingStatus/',
      importTransactionsPreProcessingStart: '/ImportTransPreprocessingStart',
      importTransactionPreProcessingStatus:
        '/GetImportTransPreprocessingStatus/',
      importTBProcessingStart: '/ImportTbProcessingStart',
      importTBProcessingStatus: '/GetImportTbProcessingStatus/',
      ExportTBImportReportTextFile: '/ExportTBImportReportTextFile',
      ImportTbErrorRowExcelDownloadStart: '/ImportTbErrorRowExcelDownloadStart',
      GetImportTbErrorRowExcelDownloadStatus:
        '/GetImportTbErrorRowExcelDownloadStatus/',
      ExportTBErrorRowExcelFile: '/ExportTBErrorRowExcelFile',
      importTransactionValidationProcessingStart: '/ImportTransProcessingValidationStart',
      importTransactionValidationProcessingStatus: '/GetImportTransProcessingValidationStatus/',
      importTransactionProcessingStart: '/importTransProcessingStart',
      importTransactionProcessingStatus: '/GetImportTransProcessingStatus/',
      ExportTransactionImportReportTextFile: '/ExportTransImportReportTextFile',
      ImportTransErrorRowExcelDownloadStart:
        '/ImportTransErrorRowExcelDownloadStart',
      GetImportTransErrorRowExcelDownloadStatus:
        '/GetImportTransErrorRowExcelDownloadStatus/',
      ExportTransErrorRowExcelFile: '/ExportTransErrorRowExcelFile',
    };
  }
  getSheetNamesTrialBalance(fileName: string): Observable<Worksheet[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fileName', fileName);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllSheetsTrialBalance;
    return this.http.get<Worksheet[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }

  importTBPreProcessingStart(tbprocessStartPayload: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    //this.apiHost="https://wezehahzadap004.azurewebsites.net/api/ImportTbPreprocessingStart";
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.importTBPrePrcessingStart;
    return this.http.post(this.apiHost, tbprocessStartPayload, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  importTBPreProcessingStatus(instanceId: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);
    //this.apiHost="https://wezehahzadap004.azurewebsites.net/api/GetImportTbPreprocessingStatus/"+instanceId;
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.importTBPrePrcessingStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  importTBProcessingStart(tbprocessStartPayload: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    //this.apiHost="https://wezehahzadap004.azurewebsites.net/api/ImportTbPreprocessingStart";
    this.apiHost =
      this.importAPIUrl + this.apifolder + this.apiUrl.importTBProcessingStart;
    return this.http.post(this.apiHost, tbprocessStartPayload, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  importTBProcessingStatus(instanceId: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);
    //this.apiHost="https://wezehahzadap004.azurewebsites.net/api/GetImportTbPreprocessingStatus/"+instanceId;
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.importTBProcessingStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
    });
  }

  ExportTBReportTextFile(importReportTextFileName: string): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('importReportTextFileName', importReportTextFileName);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.ExportTBImportReportTextFile;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'text',
    });
  }
  ImportTbErrorRowExcelDownloadStart(tbdownloadXLStartPayload: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    //this.apiHost="https://wezehahzadap004.azurewebsites.net/api/ImportTbPreprocessingStart";
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.ImportTbErrorRowExcelDownloadStart;
    return this.http.post(this.apiHost, tbdownloadXLStartPayload, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  GetImportTbErrorRowExcelDownloadStatus(instanceId: string): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.GetImportTbErrorRowExcelDownloadStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      observe: 'response',
      //responseType: 'blob' as 'json',
    });
  }
  downloadErrorExcelFile(importErrorRowExcelFileName: string): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('importErrorRowExcelFileName', importErrorRowExcelFileName);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.ExportTBErrorRowExcelFile;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
  getSheetNamesTransactions(fileName: string): Observable<Worksheet[]> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fileName', fileName);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllSheetsTrialBalance;
    return this.http.get<Worksheet[]>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  importTransactionsPreProcessingStatus(instanceId: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);

    // this.apiHost =
    // this.baseUrl + this.apifolder + this.apiUrl.getAllSheetsTrialBalance;
    //this.apiHost="https://wezehahzadap004.azurewebsites.net/api/GetImportTbPreprocessingStatus/"+instanceId;
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.importTransactionPreProcessingStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  importTransactionsPreProcessingStart(tbprocessStartPayload: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    //     this.apiHost="https://wezehahzadap004.azurewebsites.net/api/ImportTbPreprocessingStart";
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.importTransactionsPreProcessingStart;
    return this.http.post(this.apiHost, tbprocessStartPayload, {
      headers: headersObj,
      withCredentials: false,
    });
  }

  importTransactionValidationProcessingStart(tbprocessStartPayload: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.importTransactionValidationProcessingStart;
    return this.http.post(this.apiHost, tbprocessStartPayload, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  importTransactionValidationProcessingStatus(instanceId: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.importTransactionValidationProcessingStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  importTransactionProcessingStart(tbprocessStartPayload: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    //this.apiHost="https://wezehahzadap004.azurewebsites.net/api/ImportTbPreprocessingStart";
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.importTransactionProcessingStart;
    return this.http.post(this.apiHost, tbprocessStartPayload, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  importTransactionProcessingStatus(instanceId: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);
    //this.apiHost="https://wezehahzadap004.azurewebsites.net/api/GetImportTbPreprocessingStatus/"+instanceId;
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.importTransactionProcessingStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
    });
  }

  ExportTransactionReportTextFile(
    importReportTextFileName: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('importReportTextFileName', importReportTextFileName);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.ExportTransactionImportReportTextFile;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'text',
    });
  }
  ImportTransactionErrorRowExcelDownloadStart(tbdownloadXLStartPayload: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    //this.apiHost="https://wezehahzadap004.azurewebsites.net/api/ImportTbPreprocessingStart";
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.ImportTransErrorRowExcelDownloadStart;
    return this.http.post(this.apiHost, tbdownloadXLStartPayload, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  GetImportTransactionErrorRowExcelDownloadStatus(
    instanceId: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('instanceId', instanceId);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.GetImportTransErrorRowExcelDownloadStatus +
      instanceId;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      observe: 'response',
      //responseType: 'blob' as 'json',
    });
  }
  downloadTransactionErrorExcelFile(
    importErrorRowExcelFileName: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('importErrorRowExcelFileName', importErrorRowExcelFileName);
    this.apiHost =
      this.importAPIUrl +
      this.apifolder +
      this.apiUrl.ExportTransErrorRowExcelFile;
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
