import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MyGlobalObject } from '../../myglobalobject';

@Injectable({
  providedIn: 'root'
})

export class Manageattachementservice {

  private params: any;
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  tokenValue: any;
  private mockApiUrl: any;
  private apiHost: string;

  constructor( public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService) {
      this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    this.apifolder = environment.apifolder;

    this.apiUrl = {
      getAttachment:'/ManageStandaloneAttachment/GetSupplimentaryManageAttachment',
      getManageAttachmentExport: '/OfflineReferral/GetManageAttachmentExport',
      deleteManageAttachment:'/ManageStandaloneAttachment/DeleteManageAttachment',
      uploadFile:'/ManageStandaloneAttachment/UploadFile',
      getAdditionalFileData:'/ManageStandaloneAttachment/GetAdditionalFileData',
      getAdditionalFileExport: '/ManageStandaloneAttachment/GetAdditionalDataExport',
      deleteAdditionalFile:'/ManageStandaloneAttachment/DeleteAdditionalFile',
      getManageAttachmentExcel:'/OfflineReferral/GetManageAttachmentExcel',
      getAdditionalFileExcel:'/ManageStandaloneAttachment/GetAdditionalFileExcel'
    }
}
private selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
getManageAttachementDetails(businessID:number,lineItemID:number,fiscalYear:number):Observable<any>{
  
  this.selectedClient = JSON.parse(this.selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' +
        JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('businessTypeID', businessID)
      .set('legalEntityID', lineItemID)
      .set('fiscalYear', fiscalYear);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getAttachment;
    return this.http.get<any>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
   
  }
  getAdditionalFileDetails():Observable<any>{
    this.selectedClient = JSON.parse(this.selectedClientVal);
      var headers = {
        'Content-Type': 'application/json',
        'Authorization-Token':
          'Bearer ' +
          JSON.parse(this.tokenValue).access_token,
      };
      const headersObj = new HttpHeaders(headers);
      let httpParams = new HttpParams()
        .set('ClientdbName', this.selectedClient.DBName);
      this.apiHost =
        this.baseUrl +
        this.apifolder +
        this.apiUrl.getAdditionalFileData;
      return this.http.get<any>(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      });
     
    }

  ExportManageAttachment(supllimentaryID: any): Observable<any> {
    this.selectedClient = JSON.parse(this.selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' +
        JSON.parse(this.tokenValue).access_token,
    };
    let year: any = localStorage.getItem('selectedFiscalYear');
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('supplimentaryId', supllimentaryID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getManageAttachmentExport;
    return this.http.post(
      this.apiHost,
      {},
      {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        observe: 'response',
        responseType: 'blob' as 'json',
      }
    );
  }

  deleteManageAttachment(ids: string,deleteoption:boolean): Observable<string> {
    this.selectedClient = JSON.parse(this.selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' +
        JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('suppIds', ids)
      .set('isSoftDelete', deleteoption);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteManageAttachment;
    return this.http.delete<string>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }



  ExportAdditionalFile(supllimentaryID: any): Observable<any> {
    this.selectedClient = JSON.parse(this.selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' +
        JSON.parse(this.tokenValue).access_token,
    };
    let year: any = localStorage.getItem('selectedFiscalYear');
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('additionalFileIds', supllimentaryID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAdditionalFileExport;
    return this.http.post(
      this.apiHost,
      {},
      {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        observe: 'response',
        responseType: 'blob' as 'json',
      }
    );
  }

  deleteAdditionalFile(isSoftDelete:boolean,ids: string): Observable<string> {
    this.selectedClient = JSON.parse(this.selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' +
        JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('isSoftDelete', isSoftDelete)  
      .set('fileIds', ids);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteAdditionalFile;
    return this.http.delete<string>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text' as 'json',
    });
  }
  ImportAttachment(worksheetParams: string[], fileName: string): Observable<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' +
        JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('fileName', fileName);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.uploadFile;
    return this.http.post<string>(this.apiHost, worksheetParams, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'text' as 'json',
    });
  }

  GetManageAttachmentExcel(supllimentaryID: number): Observable<any> {
    this.selectedClient = JSON.parse(this.selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' +
        JSON.parse(this.tokenValue).access_token,
    };
    let year: any = localStorage.getItem('selectedFiscalYear');
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('supplimentaryId', supllimentaryID)
      .set('fiscalYear', JSON.parse(year).FiscalYearId);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getManageAttachmentExcel;
    return this.http.post(
      this.apiHost,
      {},
      {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        observe: 'response',
        responseType: 'blob' as 'json',
      }
    );
  }

  GetAdditionalFileExcel(additionalFileID: number): Observable<any> {
    this.selectedClient = JSON.parse(this.selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' +
        JSON.parse(this.tokenValue).access_token,
    };
    let year: any = localStorage.getItem('selectedFiscalYear');
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('addFileID', additionalFileID);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAdditionalFileExcel;
    return this.http.post(
      this.apiHost,
      {},
      {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        observe: 'response',
        responseType: 'blob' as 'json',
      }
    );
  }

}
