import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { MyGlobalObject } from '../../myglobalobject';

@Injectable({
  providedIn: 'root',
})
export class TaxBusinessOutcomeService {
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getAllTaxOutcomes: '/TaxOutcomes/GetAllTaxOutcomes',
      getDefaultTaxOutcome: '/TaxOutcomes/GetDefaultTaxOutcome',
      deleteOutcome: '/TaxOutcomes/DeleteTaxOutcome',
      updateOutcome: '/TaxOutcomes/UpdateTaxOutcome',
      insertOutcome: '/TaxOutcomes/InsertTaxOutcome',
      defaultTaxOutcome: '/TaxOutcomes/ChangeDefaultTaxOutcome',
    };
  }
  deleteBusinessOutcome(id: any) {
    // console.log(id)
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.baseUrl = environment.baseUrl;
    //  this.apiPort = environment.apiPort;
    this.apifolder = environment.apifolder;
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.deleteOutcome;

    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('id', id);

    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  editBusinessOutcome(data: any) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.baseUrl = environment.baseUrl;
    //  this.apiPort = environment.apiPort;
    this.apifolder = environment.apifolder;
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.updateOutcome;

    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );

    return this.http.post(this.apiHost, data, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  inseetBusinessOutcome(data: any) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.baseUrl = environment.baseUrl;
    //  this.apiPort = environment.apiPort;
    this.apifolder = environment.apifolder;
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.insertOutcome;

    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );

    return this.http.post(this.apiHost, data, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  getDefaultTaxOutcome(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getDefaultTaxOutcome;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }
  getAllTaxOutcomes(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllTaxOutcomes;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }

  changeTaxOutcome(data: any) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.baseUrl = environment.baseUrl;
    //  this.apiPort = environment.apiPort;
    this.apifolder = environment.apifolder;
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.defaultTaxOutcome;

    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    ).set(
      'taxOutcomeId',
      data
    );

    return this.http.post(this.apiHost,null, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }

}
