<div>
  <!-- <kendo-grid [data]="gridView">
    <ng-template ngFor [ngForOf]="columns" let-column>
      <kendo-grid-column title="{{column}}"></kendo-grid-column>
    </ng-template>
  </kendo-grid>
  <kendo-grid [data]="gridView">
    <ng-template ngFor [ngForOf]="columns" let-column>
      <kendo-grid-column field="{{column}}"></kendo-grid-column>
    </ng-template>
  </kendo-grid> -->
  <kendo-chunkprogressbar
    *ngIf="isLoading"
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
  <kendo-grid
    *ngIf="
      (this.levelType == 'AS' ||
        this.levelType == 'SA' ||
        this.levelType == 'LI' ||
        this.levelType == 'CLI' ||
        this.levelType == 'SS') &&
      this.statementId != 0
    "
    [data]="gridView"
    scrollable="virtual"
    class="autoTreeHeight"
    [rowClass]="rowCallback"
    [resizable]="true"
    [groupable]="true"
    [group]="groups"
    [reorderable]="true"
  >
    <ng-template kendoGridToolbarTemplate>
      <button
        kendoButton
        (click)="exportData()"
        class="actionButton"
        [disabled]="isLoading"
        kendoTooltip
        title="Export to excel"
      >
        <span class="Appkit4-icon icon-download-outline"></span>
      </button>
    </ng-template>
    <ng-template ngFor [ngForOf]="columnHeaders" let-column>
      <kendo-grid-column
        title="{{ column.name }}"
        field="{{ column.value }}"
        [headerStyle]="{ 'font-weight': 'bold' }"
      >
        <ng-template
          *ngIf="column.name == 'Amount' "
          kendoGridCellTemplate
          let-dataItem
        >
          <span
            [style]="
              this.AddColorFormatHeader(
                dataItem[column.value] != null && dataItem['type'] == 'BlockRow'
                  ? dataItem[column.value]
                  : dataItem['type'] == 'BlockHeader'
                  ? getHeader(dataItem.BlockId, column.value)
                  : ''
              )
            "
          >
            {{
              this.NumberFormat(
                dataItem[column.value] != null && dataItem["type"] == "BlockRow"
                  ? dataItem[column.value]
                  : dataItem["type"] == "BlockHeader"
                  ? getHeader(dataItem.BlockId, column.value)
                  : ""
              )
            }}
          </span>
        </ng-template>

        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            class="dottedtext"
            [style]="
              this.AddColorFormat1(
                dataItem[column.value] != null && dataItem['type'] == 'BlockRow'
                  ? dataItem[column.value]
                  : dataItem['type'] == 'BlockHeader'
                  ? getHeader(dataItem.BlockId, column.value)
                  : ''
              )
            "
          >
            {{
              this.NumberFormat(
                dataItem[column.value] != null && dataItem["type"] == "BlockRow"
                  ? dataItem[column.value]
                  : dataItem["type"] == "BlockHeader"
                  ? getHeader(dataItem.BlockId, column.value)
                  : ""
              )
            }}
          </span></ng-template
        >
        <!-- <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.BlockHeaderText != null">{{
            dataItem.BlockHeaderText
          }}</span>
          <span
            *ngIf="
              dataItem.BlockHeaderText == null ||
              dataItem.BlockFooterText == null
            "
            >{{ dataItem[column] }}</span
          >
        </ng-template> -->
        <ng-template kendoGridGroupFooterTemplate let-group="group">
          <!-- <span *ngIf="column.value == 'Amount'">
            {{ this.NumberFormat(getGridFooter(Alignment(column.value))) }}
          </span> -->

          <span *ngIf="isNumber(getValues(group.value, column.value))">
            <span
              class="dottedtext"
              [style]="
                this.AddColorFormat(getValues(group.value, column.value))
              "
            >
              {{
                this.NumberFormat(
                  group.value != null
                    ? getValues(group.value, column.value)
                    : ""
                )
              }}
            </span>
          </span>
          <span
            class="dottedtext"
            *ngIf="!isNumber(getValues(group.value, column.value))"
            [ngStyle]="{
              'font-style': 'italic'
            }"
          >
            {{
              this.NumberFormat(
                group.value != null ? getValues(group.value, column.value) : ""
              )
            }}
          </span>
        </ng-template>

        <ng-template
          *ngIf="isGridFooterDisplay"
          kendoGridFooterTemplate
          let-column
          let-columnIndex="columnIndex"
        >
          <span *ngIf="isNumber(getGridFooter(column.field))">
            <span
              class="dottedtext"
              [style]="this.AddColorFormat(getGridFooter(column.field))"
            >
              {{ this.NumberFormat(getGridFooter(column.field)) }}
            </span>
          </span>

          <span
            class="dottedtext"
            *ngIf="!isNumber(getGridFooter(column.field))"
            [ngStyle]="{
              'font-style': 'italic'
            }"
          >
            {{ this.NumberFormat(getGridFooter(column.field)) }}
          </span>
        </ng-template>
      </kendo-grid-column>
    </ng-template>
    <kendo-grid-excel fileName="AnalysisStatement.xlsx"></kendo-grid-excel>
  </kendo-grid>
</div>
