import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  DialogCloseResult,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { KeywordData, keywordTotalData } from 'src/app/_models/keywordData';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { KeywordDeleteData } from 'src/app/_models/keywordDeleteData';
import { KeywordGroupFolder } from 'src/app/_models/keywordGroupFolder';
import { AllocationRulesService } from 'src/app/common/services/allocationrule/allocation-rules.service';
import { DeleteKeywordComponent } from '../delete-keyword/delete-keyword.component';
import {
  AddEvent,
  CancelEvent,
  EditEvent,
  RemoveEvent,
  SaveEvent,
  TreeListComponent,
} from '@progress/kendo-angular-treelist';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { Keyword } from 'src/app/_models/keyword';
import {
  ColumnBase,
  GridComponent,
  SelectionEvent,
} from '@progress/kendo-angular-grid';
import { SelectableSettings } from '@progress/kendo-angular-treelist';
import { SortDescriptor, process, orderBy } from '@progress/kendo-data-query';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-add-keyword',
  templateUrl: './add-keyword.component.html',
  styleUrls: ['./add-keyword.component.scss'],
})
export class AddKeywordComponent implements OnInit {
  constructor(
    private router: Router,
    private dialogService: DialogService,
    public toastr: ToastrManager,
    private fb: FormBuilder,
    private globalService: GlobalService,
    private allocationRulesService: AllocationRulesService
  ) {
    this.addKeywordForm = this.fb.group({
      dataColumns: [''],
      habitat: [''],
    });
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  ngOnInit(): void {
    this.getAllKeywordsData();
    this.getExpressionDataColumnsList();
    this.getAllHabitats();
  }
  public addKeywordForm!: FormGroup;
  public dataColumnsList: any[] = [];
  public habitatList: any | undefined;
  public selecteddataColumns: any = [];
  public selectedhabitat: any = [];
  isLoading: boolean = false;
  public keywordCountList: any = [];
  public gridData: any[] = [];
  keywordData: KeywordData[] = [];
  public editedItem!: KeywordData;
  formGroup!: FormGroup;
  public keywordDeleteData!: KeywordDeleteData;
  selectedId: number = 0;
  public expandedNames!: any[];
  keyword!: Keyword;
  keywordGroupFolders: KeywordGroupFolder[] = [];
  keywordGroupFolder!: KeywordGroupFolder;
  public allowCopy = true;
  public firstGridData = this.keywordCountList.slice(0, 3);
  public secondGridData = this.keywordCountList.slice(3, 6);
  public dropIndex = 0;
  public noRecordsMsg = 'No records available yet. Please drop a row.';
  public currentGridRef: GridComponent | undefined;
  public settings: SelectableSettings = {
    mode: 'cell',
    multiple: false,
    drag: false,
    enabled: true,
    readonly: false,
  };

  public selected: any = [];
  public vocabularyModels: any[] = [];
  public keyWord: string = '';
  public isKeywordLoaded: boolean = false;

  handledataColumnChange(event: any) {
    if (event != undefined) {
      let habitatID = this.selectedhabitat.HabitatId;
      let column = event.PropertyName;
      if (habitatID != undefined) {
        let response = this.getMatchingKeywords(column, 2020, habitatID);
      }
    }
  }

  handlehabitatChange(event: any) {
    this.vocabularyModels = [];
    let column = this.selecteddataColumns.PropertyName;
    let habitatID = event.HabitatId;
    if (column != undefined) {
      let response = this.getMatchingKeywords(column, 2020, habitatID);
    }
  }

  closeButtonClick() {
    this.router.navigate(['/newExpression']);
  }
  public addHandler({ sender, parent = '' }: AddEvent): void {
    if (parent.level == 'Keyword') {
      this.toastr.errorToastr(
        'Adding new entry not allowed in Keyword level.',
        'Error',
        { timeOut: 2000 }
      );
      return;
    }
    this.closeEditor(sender);

    // Expand the parent.
    if (parent) {
      sender.expand(parent);
    }

    this.formGroup = new FormGroup({
      description: new FormControl('', Validators.required),
    });

    // Show the new row editor, with the `FormGroup` build above
    sender.addRow(this.formGroup, parent);
  }
  public editHandler({ sender, dataItem }: EditEvent): void {
    // Close the current edited row, if any.
    this.closeEditor(sender, dataItem);

    // Define all editable fields validators and default values
    this.formGroup = new FormGroup({
      description: new FormControl(dataItem.description, Validators.required),
    });

    this.editedItem = dataItem;

    // Put the row in edit mode, with the `FormGroup` build above
    sender.editRow(dataItem, this.formGroup);
  }

  public cancelHandler({ sender, dataItem, isNew }: CancelEvent): void {
    // Close the editor for the given row
    this.closeEditor(sender, dataItem, isNew);
  }
  private closeEditor(
    treelist: TreeListComponent,
    dataItem: any = this.editedItem,
    isNew = false
  ): void {
    treelist.closeRow(dataItem, isNew);
  }

  public removeHandler({ sender, dataItem, parent }: RemoveEvent): void {
    this.resetKeywordDeleteData();

    if (dataItem.level === 'Folder') {
      this.keywordDeleteData.level = dataItem.level;
      this.keywordDeleteData.id = dataItem.id;
      this.keywordDeleteData.title = 'Delete ' + this.keywordDeleteData.level;
      this.keywordDeleteData.message =
        'You have selected to permanently delete the selected folder. Any keyword groups and keywords within the folder will also be deleted. Are you sure you wish to continue?';
    } else if (dataItem.level === 'Group') {
      this.keywordDeleteData.level = dataItem.level;
      this.keywordDeleteData.id = dataItem.id;
      this.keywordDeleteData.title = 'Delete ' + this.keywordDeleteData.level;
      this.keywordDeleteData.message =
        'You have selected to permanently delete the selected keyword group(s). Any keywords associated with the group will also be deleted. Are you sure you wish to continue?';
    } else if (dataItem.level === 'Keyword') {
      this.keywordDeleteData.level = dataItem.level;
      this.keywordDeleteData.id = dataItem.id;
      this.keywordDeleteData.title = 'Delete ' + this.keywordDeleteData.level;
      this.keywordDeleteData.message =
        'You have selected to permanently delete the selected keywords. Are you sure you wish to continue?';
    }
    const dialogRef = this.dialogService.open({
      content: DeleteKeywordComponent,
      width: 500,
      height: 'auto',
    });
    const deleteKeywordPopup = dialogRef.content
      .instance as DeleteKeywordComponent;
    dialogRef.result.subscribe((r: any) => {
      if (r === 'deleted') this.getAllKeywordsData();
    });
    deleteKeywordPopup.keywordDeleteData = this.keywordDeleteData;
  }

  resetKeywordDeleteData(): void {
    this.keywordDeleteData = {
      id: 0,
      message: '',
      level: '',
      title: '',
    };
  }

  getAllKeywordsData(): void {
    this.resetAllKeyWordData();
    this.isLoading = true;
    this.isKeywordLoaded = false;
    this.globalService.getAllKeywordsData().subscribe(
      (response) => {
        this.keywordData = response;
        if (this.selectedId != 0) {
          this.expandedNames = [this.selectedId];
        }

        this.isLoading = false;
        this.isKeywordLoaded = true;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
        this.isKeywordLoaded = true;
      }
    );
  }

  resetAllKeyWordData(): void {
    this.keywordData = [];
  }

  public onExpandedNamesChange(names: any[]): void {
    if (names.length > 0) {
      this.selectedId = names[0];
    }
  }

  public saveHandler({
    sender,
    dataItem,
    parent = '',
    formGroup,
    isNew = false,
  }: SaveEvent): void {
    if (isNew && parent == '') {
      this.resetKeywordGroupFolderData();
      this.keywordGroupFolder.Name = formGroup.value.description;
      this.globalService.createKeywordFolder(this.keywordGroupFolder).subscribe(
        (response) => {
          if (response == 'The Keyword group folder is created successfully') {
            this.toastr.successToastr(response, 'Success');
            sender.closeRow(dataItem, isNew);
            this.getAllKeywordsData();
          } else {
            this.toastr.errorToastr(response, 'Error', { timeOut: 2000 });
            return;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else if (isNew && parent != '') {
      this.resetKeywordGroupFolderData();
      if (parent.level === 'Folder') {
        this.keywordGroupFolder.Name = formGroup.value.description;
        this.keywordGroupFolder.VocabularyGroupFolderId = parent.id;
        this.globalService
          .CreateKeywordGroup(this.keywordGroupFolder)
          .subscribe(
            (response) => {
              if (response == 'The keyword group is created successfully.') {
                this.toastr.successToastr(response, 'Success');
                this.getAllKeywordsData();
              } else {
                this.toastr.errorToastr(response, 'Error', { timeOut: 20000 });
                return;
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } else if (parent.level === 'Group') {
        this.keywordGroupFolder.Name = formGroup.value.description;
        this.keywordGroupFolder.VocabularyGroupFolderId = parent.id;
        this.globalService.CreateKeyword(this.keywordGroupFolder).subscribe(
          (response) => {
            if (response == 'The keyword is created successfully') {
              this.toastr.successToastr(response, 'Success');
              this.getAllKeywordsData();
            } else {
              this.toastr.errorToastr(response, 'Error', { timeOut: 2000 });
              return;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }

    if (!isNew) {
      if (dataItem.level === 'Folder') {
        this.resetKeywordGroupFolderData();
        this.keywordGroupFolder.Name = formGroup.value.description;
        this.keywordGroupFolder.VocabularyGroupFolderId = dataItem.id;
        this.globalService.editKeyWordFolder(this.keywordGroupFolder).subscribe(
          (response) => {
            if (response == 'The keyword group folder updated successfully.') {
              this.toastr.successToastr(response, 'Success');
              this.getAllKeywordsData();
            } else {
              this.toastr.errorToastr(response, 'Error', { timeOut: 2000 });
              return;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } else if (dataItem.level === 'Group') {
        this.resetKeywordData();
        this.keyword.vocabularyGroupId = dataItem.parentId;
        this.keyword.vocabularyId = dataItem.id;
        this.keyword.description = formGroup.value.description;

        this.globalService.editKeywordGroup(this.keyword).subscribe(
          (response) => {
            if (response == 'The keyword group updated successfully.') {
              this.toastr.successToastr(response, 'Success');
              this.getAllKeywordsData();
            } else {
              this.toastr.errorToastr(response, 'Error', { timeOut: 2000 });
              return;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } else if (dataItem.level === 'Keyword') {
        this.resetKeywordData();
        this.keyword.vocabularyGroupId = dataItem.parentId;
        this.keyword.vocabularyId = dataItem.id;
        this.keyword.description = formGroup.value.description;

        this.globalService.editKeyword(this.keyword).subscribe(
          (response) => {
            if (response == 'The keyword updated successfully.') {
              this.toastr.successToastr(response, 'Success');
              this.getAllKeywordsData();
            } else {
              this.toastr.errorToastr(response, 'Error', { timeOut: 2000 });
              return;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }

  resetKeywordData(): void {
    this.keyword = {
      vocabularyGroupId: 0,
      vocabularyId: 0,
      description: '',
    };
  }
  resetKeywordGroupFolderData(): void {
    this.keywordGroupFolder = {
      Name: '',
      VocabularyGroupFolderId: 0,
    };
  }
  public excludeColumnList: any = [
    'Fiscal year',
    'Amount',
    'Posting date',
    'Document date',
    'Transaction date',
    'Custom12',
    'Absolute amount',
  ];
  getExpressionDataColumnsList(): void {
    // this.isLoading = true;
    this.allocationRulesService.getExpressionDataColumnsList().subscribe(
      (response) => {
        if (response != undefined && response != null) {
          this.dataColumnsList = response;
          for (let i = 0; i < this.excludeColumnList.length; i++) {
            var index = this.dataColumnsList
              .map((x) => x.Name)
              .indexOf(this.excludeColumnList[i]);
            this.dataColumnsList.splice(index, 1).slice(0);
          }
        }
        //   this.dataColumnsList=this.dataColumnsList.filter((val) =>
        // val.Type==="String"
        //   );

        // this.isLoading = false;
      },
      (error) => {
        console.log(error);
        // this.isLoading = false;
      }
    );
  }

  getAllHabitats() {
    this.allocationRulesService
      .fetchAllHabitats()
      .then((res: any[]) => {
        this.habitatList = res;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  getMatchingKeywords(
    columnName: string,
    fiscalYear: number,
    habitatId: number
  ) {
    this.isLoading = true;
    this.allocationRulesService
      .getMatchingKeywords(columnName, fiscalYear, habitatId)
      .then((res: any[]) => {
        this.keywordCountList = res;
        this.gridData = res;
        if (this.isKeywordLoaded == true) {
          this.isLoading = false;
        }
      })
      .catch((err: any) => {
        console.log(err);
        if (this.isKeywordLoaded == true) {
          this.isLoading = false;
        }
      });
  }

  public MapData() {
    if (this.selected.length == 0) {
      this.keyWord = 'Please select any keyword group';
      return;
    }
    let vocabularyGroupID = this.selected[0].itemKey.id;
    if (this.selected[0].itemKey.level == 'Group') {
      if (this.vocabularyModels.length == 0) {
        this.keyWord = 'Please select keyword from keyword list';
        return;
      }
      this.savetColumnMapping(this.vocabularyModels, vocabularyGroupID);
    } else {
      this.keyWord = 'Please select keyword group';
    }
  }

  public savetColumnMapping(vocabularyModels: any, vocabularyGroupID: number) {
    this.allocationRulesService
      .SaveColumnMapping(vocabularyModels, vocabularyGroupID)
      .subscribe(
        (res) => {
          if (res == 'Data Mapping added successfully') {
            this.toastr.successToastr(res, 'Success');
            this.vocabularyModels = [];
            this.getAllKeywordsData();
            this.keywordCountList = [];
            this.selectedhabitat = undefined;
            this.selecteddataColumns = undefined;
            this.keyWord = '';
          } else {
            this.toastr.errorToastr(res, 'Failed');
          }
        },
        (error) => {
          this.isLoading = false;
          this.toastr.errorToastr('Failed to Save Allocation Rule...', 'Error');
          console.log(error);
        }
      );
  }

  public onGridSelectionChange(event: SelectionEvent) {
    var count = event.selectedRows != undefined ? event.selectedRows.length : 0;
    for (let i = 0; i < count; i++) {
      var item =
        event.selectedRows != undefined
          ? event.selectedRows[i].dataItem.Keyword
          : '';
      let vocabularyModel = {
        VocabularyID: 0,
        Description: item,
        Observation: false,
        ObservationNotes: '',
      };
      this.vocabularyModels.push(vocabularyModel);
    }
    count = event.deselectedRows != undefined ? event.deselectedRows.length : 0;
    for (let i = 0; i < count; i++) {
      var item =
        event.deselectedRows != undefined
          ? event.deselectedRows[i].dataItem.Keyword
          : '';
      var index = this.vocabularyModels.map((x) => x.Description).indexOf(item);
      this.vocabularyModels.splice(index, 1).slice(0);
    }
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.keywordCountList = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }

  public sort: SortDescriptor[] = [
    {
      field: 'Priority',
      dir: 'asc',
    },
  ];

  public onFilter(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.keywordCountList = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'Keyword',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      let habitatID = this.selectedhabitat.HabitatId;
      let column = this.selecteddataColumns.PropertyName;
      if (habitatID != undefined) {
        let response = this.getMatchingKeywords(column, 2020, habitatID);
      }
    }
  }
}
