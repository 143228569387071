<!-- Header -->
<div class="header">Manage business types</div>
<div id="mainContent" class="container-fluid">
  <div class="row">
    <!-- <div class="col-md-12 col-xs-12"></div> -->
    <div class="col-md-12 col-xs-12">
      <!-- search bar and action -->
      <div class="searchContainer">
        <ap-search
          [placeholder]="'Search here'"
          [searchType]="'secondary'"
          style="width: 100%; margin-right: 24px; padding: 0px !important"
          [disabled]="false"
          (onSearch)="onFilter($event)"
        ></ap-search>
        <ap-button
          [icon]="'plus-outline'"
          [compact]="true"
          kendoTooltip
          title="New"
          [add]="true"
          (onClick)="openAddBusinessPopup()"
        ></ap-button>
      </div>
      <!-- Sub Header -->
      <div class="subHeader">Business type</div>
      <!-- Data grid -->
      <kendo-grid
        [data]="businessTypes"
        scrollable="virtual"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        [resizable]="true"
        [reorderable]="true"
      >
        <kendo-grid-command-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'left'
          }"
          field="IsActive"
          title="Actions"
          [width]="150"
        >
          <ng-template kendoGridCellTemplate let-data>
            <button
              kendoButton
              name="edit"
              kendoTooltip
              title="Edit"
              (click)="editButtonClick(data)"
              style="margin-right: 5px"
            >
              <span class="Appkit4-icon icon-edit-outline"></span>
            </button>
            <button
              kendoButton
              name="copy"
              kendoTooltip
              title="Copy"
              (click)="copyButtonClick(data)"
              style="margin-right: 5px"
            >
              <span class="Appkit4-icon icon-copy-outline"></span>
            </button>
            <button
              kendoButton
              name="delete"
              kendoTooltip
              title="Delete"
              (click)="deleteButtonClick(data)"
            >
              <span class="Appkit4-icon icon-delete-outline"></span>
            </button>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column
          field="BusinessTypeId"
          title="Business type id"
          [width]="120"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Description"
          title="Description"
          [width]="320"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="CountryName"
          title="Default country"
          [width]="220"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="IndustryName"
          title="Default industry"
          [width]="250"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'left',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
