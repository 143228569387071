<div class="">
    <kendo-appbar position="top" *ngIf="menubarService.visible">
      <kendo-appbar-section>
        <img
          class="sepspace"
          (click)="homeClick()"
          width="42"
          height="16"
          src="./assets/images/Logo.png"
          title="PricewaterhouseCoopers"
        />
      </kendo-appbar-section>
      <span class="k-appbar-separator"></span>
  
      <kendo-appbar-section>
        <p class="customAppkitStyleMenuItem">Enlighten</p>
      </kendo-appbar-section>
      <kendo-appbar-section>
        <span class="customAppkitStyleMenuItem" *ngIf="this.displaySelectedClient"
          >Client: {{ this.displaySelectedClient.ClientEntityName }}</span
        >
      </kendo-appbar-section>
      <kendo-appbar-spacer></kendo-appbar-spacer>
      <kendo-appbar-section>
        <kendo-menu>
          <kendo-menu-item text="Home" cssClass="customAppkitStyleMenuItem">
            <kendo-menu-item text="Roll Forward"></kendo-menu-item>
            <kendo-menu-item text="Select Year"></kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
      </kendo-appbar-section>
  
      <kendo-appbar-section>
        <kendo-menu (select)="handleSelectionChange($event)">
          <kendo-menu-item text="Setup">
            <kendo-menu-item text="Business Types"></kendo-menu-item>
            <kendo-menu-item text="Legal Entities"></kendo-menu-item>
            <kendo-menu-item text="Analysis Statement"></kendo-menu-item>
            <kendo-menu-item text="Statement Details">
              <kendo-menu-item text="Export Template"></kendo-menu-item>
              <kendo-menu-item text="Import Template"></kendo-menu-item>
            </kendo-menu-item>
            <kendo-menu-item text="Line Items"></kendo-menu-item>
            <kendo-menu-item text="Accounts">
              <kendo-menu-item text="Tax Accounts"></kendo-menu-item>
              <kendo-menu-item text="GL Accounts"></kendo-menu-item>
              <kendo-menu-item text="Import GL Accounts"></kendo-menu-item>
              <kendo-menu-item text="Map GL Accounts"></kendo-menu-item>
            </kendo-menu-item>
            <kendo-menu-item text="Statutory Treatment"></kendo-menu-item>
            <kendo-menu-item text="Management">
              <kendo-menu-item text="Tax Outcomes"></kendo-menu-item>
              <kendo-menu-item text="Keyword Groups"></kendo-menu-item>
              <kendo-menu-item text="Habitats"></kendo-menu-item>
            </kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
      </kendo-appbar-section>
  
      <kendo-appbar-section>
        <kendo-menu (select)="handleSelectionChange($event)">
          <kendo-menu-item text="Import">
            <kendo-menu-item text="Trial Balance"></kendo-menu-item>
            <kendo-menu-item text="Transactions"></kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
      </kendo-appbar-section>
      <kendo-appbar-section>
        <kendo-menu (select)="handleSelectionChange($event)">
          <kendo-menu-item text="Allocate">
            <kendo-menu-item text="Analyse and review"></kendo-menu-item>
            <kendo-menu-item text="Allocation rules"></kendo-menu-item>
            <kendo-menu-item text="Percentage allocation rules"></kendo-menu-item>
            <kendo-menu-item text="Transaction"></kendo-menu-item>
            <kendo-menu-item text="Offline referral">
              <kendo-menu-item text="Balance level"></kendo-menu-item>
              <kendo-menu-item text="Breakdown level"></kendo-menu-item>
              <kendo-menu-item text="Collect offline referrals"></kendo-menu-item>
              <kendo-menu-item text="Manage offline referrals"></kendo-menu-item>
            </kendo-menu-item>
            <!-- <kendo-menu-item text="Offline statement">
                     <kendo-menu-item text="With control total"></kendo-menu-item>
                 <kendo-menu-item text="Without control total"></kendo-menu-item>
                 <kendo-menu-item text="Check in"></kendo-menu-item>
                 <kendo-menu-item text="Check out"></kendo-menu-item>
                 <kendo-menu-item text="Manage offline statement"></kendo-menu-item>
                   </kendo-menu-item> -->
            <kendo-menu-item text="Journals">
              <kendo-menu-item text="New Journals"></kendo-menu-item>
              <kendo-menu-item text="Manage journals"></kendo-menu-item>
            </kendo-menu-item>
            <kendo-menu-item text="Manage attachments"></kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
      </kendo-appbar-section>
      <kendo-appbar-section>
        <kendo-menu>
          <kendo-menu-item text="Export">
            <kendo-menu-item
              text="Analysis Statements"
              cssClass="submenu"
            ></kendo-menu-item>
            <kendo-menu-item text="Analysis View"></kendo-menu-item>
            <kendo-menu-item text="Settings"></kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
      </kendo-appbar-section>
      <kendo-appbar-section>
        <kendo-menu (select)="handleSelectionChange($event)">
          <kendo-menu-item text="Analysis & Reporting">
            <kendo-menu-item text="Analysis & Review"></kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
      </kendo-appbar-section>
      <kendo-appbar-section>
        <kendo-menu>
          <kendo-menu-item text="Administration" cssClass="mycustom">
            <kendo-menu-item text="Preferences"></kendo-menu-item>
            <kendo-menu-item text="Manage Fiscal Years"></kendo-menu-item>
            <kendo-menu-item text="Manage Period Aliases"></kendo-menu-item>
            <kendo-menu-item text="Manage Custom Columns"></kendo-menu-item>
            <kendo-menu-item text="Transaction Data"></kendo-menu-item>
            <kendo-menu-item text="Restore Factory Settings"></kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
      </kendo-appbar-section>
      <kendo-appbar-spacer></kendo-appbar-spacer>
  
      <kendo-appbar-section class="actions" style="margin-right: 10px">
        <img
          class="sepspace"
          width="16"
          height="14"
          src="./assets/images/DashboardIcon.png"
          title="Home"
        />
      </kendo-appbar-section>
      <kendo-appbar-section class="actions">
        <button mat-button [matMenuTriggerFor]="menu">
          <kendo-avatar
            [initials]="initials"
            shape="circle"
            width="26px"
            height="26px"
          ></kendo-avatar>
        </button>
        <mat-menu
          #menu="matMenu"
          style="position: absolute; top: 58px; right: 0px"
        >
          <button mat-menu-item>Info</button>
          <button mat-menu-item>Enlighten help</button>
          <button mat-menu-item (click)="logout()">Exit</button>
        </mat-menu>
      </kendo-appbar-section>
    </kendo-appbar>
    <!-- <app-menu></app-menu> -->
    <div class="enlightenDialog">
      <div kendoDialogContainer></div>
    </div>
    <div kendoWindowContainer></div>
    <router-outlet></router-outlet>
  </div>
  