<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>
<div class="header" style="font-size: 20px">Manage allocation rule</div>
<div class="container-fluid">
  <div class="row">
    <!-- <div class="col-md-12 col-xs-12">
            <div class="subHeader">Allocation rule name and scope</div>
        </div> -->
    <div class="col-md-8 col-xs-12">
      <form class="k-form" [formGroup]="addAllocationRuleForm">
        <div class="flexbox">
          <div class="row">
            <div class="col-md-5">
              <kendo-formfield style="margin: 0px" style="display: flex">
                <kendo-label
                  text="Expression"
                  style="font-weight: bold"
                ></kendo-label>
              </kendo-formfield>
            </div>
            <div class="col-md-7">
              <div style="display: flex; justify-content: end">
                <ap-button
                  (click)="newButtonClick()"
                  kendoButto
                  kendoTooltip
                  style="display: inline-flex; margin: 5px 2px 5px 0px"
                  title="New"
                  [icon]="'plus-outline'"
                ></ap-button>
                <button
                  [disabled]="getDisable()"
                  kendoButton
                  (click)="copyButtonClick()"
                  kendoTooltip
                  style="margin: 10px 5px"
                  title="Copy"
                >
                  <span class="Appkit4-icon icon-copy-outline"></span>
                </button>
                <button
                  [disabled]="getDisable()"
                  (click)="editButtonClick()"
                  kendoButton
                  kendoTooltip
                  title="Edit"
                  style="margin: 10px 5px"
                >
                  <span class="Appkit4-icon icon-edit-outline"></span>
                </button>
              </div>
            </div>
          </div>
          <kendo-formfield style="margin-top: 0px">
            <kendo-label [for]="expressionName" text="Name*"></kendo-label>
            <kendo-combobox
              #expressionName
              formControlName="expressionName"
              [kendoDropDownFilter]="filterSettings"
              [(ngModel)]="selectedexpressionName"
              [data]="expressionNameList"
              textField="RuleDescription"
              valueField="RuleId"
              placeholder="Select expression"
              (valueChange)="handleExpressionNameChange($event)"
            >
            </kendo-combobox>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label text="Expression"></kendo-label>
            <kendo-textarea
              #txtExpression
              [style.width.%]="100"
              resizable="auto"
              formControlName="txtExpression"
            >
            </kendo-textarea>
          </kendo-formfield>
        </div>
        <br />
        <div class="flexbox">
          <kendo-formfield>
            <kendo-label
              text="Allocation rule name and scope"
              style="font-weight: bold"
            >
            </kendo-label>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label [for]="txtName" text="Name*"></kendo-label>
            <input formControlName="txtName" kendoTextBox #txtName required />

            <kendo-formerror>{{ descriptionErrorMessage }}</kendo-formerror>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label
              [for]="businessType"
              text="Business type*"
            ></kendo-label>
            <kendo-combobox
              #businessType
              formControlName="businessType"
              [(ngModel)]="selectedBusinessType"
              [data]="businessTypesList"
              textField="Description"
              placeholder="Select business type"
              valueField="BusinessTypeId"
              (valueChange)="handleBusinessTypeChange($event)"
              [kendoDropDownFilter]="filterSettings"
            >
            </kendo-combobox>
          </kendo-formfield>
          <!-- <br> -->
          <kendo-formfield>
            <kendo-label [for]="habitat" text="Habitat*"></kendo-label>
            <kendo-combobox
              #habitat
              formControlName="habitat"
              [(ngModel)]="selectedhabitat"
              [data]="habitatList"
              textField="Name"
              valueField="HabitatId"
              placeholder="Select habitats"
              (valueChange)="handleHabitatChange($event)"
              [kendoDropDownFilter]="filterSettings"
            >
            </kendo-combobox>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label
              [for]="ComplexTaxAccount"
              text="{{ complexDescription }}"
            ></kendo-label>
            <kendo-combobox
              #ComplexTaxAccount
              [kendoDropDownFilter]="filterSettings"
              formControlName="ComplexTaxAccount"
              [(ngModel)]="selectedComplexTaxAccount"
              [data]="ComplexTaxAccountList"
              textField="ShortDescription"
              valueField="TaxAccountCodeId"
              (valueChange)="handleComplexTaxAccountChange($event)"
            >
            </kendo-combobox>
          </kendo-formfield>

          <kendo-formfield>
            <kendo-label
              [for]="SimpleTaxAccount"
              text="Simple tax account*"
            ></kendo-label>
            <kendo-combobox
              #SimpleTaxAccount
              [kendoDropDownFilter]="filterSettings"
              formControlName="SimpleTaxAccount"
              [(ngModel)]="selectedSimpleTaxAccount"
              [data]="SimpleTaxAccountList"
              textField="ShortDescription"
              valueField="TaxAccountCodeId"
              placeholder="Select simple account"
              (valueChange)="handleSimpleTaxAccountChange($event)"
              [disabled]="isSimpleEnable"
            >
            </kendo-combobox>
          </kendo-formfield>
        </div>

        <!-- <ap-field [title]="'Expression'">
                    <textarea appkit-field  maxlength="420" formControlName="txtExpression"></textarea>          
                  </ap-field>  -->
        <!-- <br/> -->

        <!-- <kendo-formfield>
                    <kendo-label text="Target" style="font-weight:bold">
                    </kendo-label>
                </kendo-formfield>

                <kendo-formfield orientation="horizontal">
                    <ul class="k-radio-list k-list-horizontal">
                        <li class="k-radio-item"> 
                            <input
                                type="radio"
                                name="account"
                                value="Simple"
                                #simple
                                [(ngModel)]="accountType"
                                kendoRadioButton
                                formControlName="account"
                                (ngModelChange)="selectAccount($event)"
                                checked 
                            />
                            <kendo-label [for]="simple" text="Simple"></kendo-label>
                        </li>
                        <li class="k-radio-item">
                            <input
                                type="radio"
                                name="account"
                                value="Complex"
                                #complex
                                [(ngModel)]="accountType"
                                kendoRadioButton
                                formControlName="account"
                                (ngModelChange)="selectAccount($event)"
                            />
                            <kendo-label [for]="complex" text="Complex"></kendo-label>
                        </li>
                    </ul>
                </kendo-formfield> -->

        <!-- <kendo-formfield>
                    <kendo-label [for]="simple" text="Simple">
                        <input
                        type="radio"
                        name="account"
                        value="Simple"
                        #simple
                        [(ngModel)]="accountType"
                        kendoRadioButton
                        formControlName="account"
                        (ngModelChange)="selectAccount($event)"
                        checked 
                        />
                    </kendo-label>
                    &nbsp;
                    <kendo-label [for]="complex" text="Complex" style="margin-top: -2.3%;
                    margin-left: 9%;">
                        <input
                        type="radio"
                        name="account"
                        value="Complex"
                        #complex
                        [(ngModel)]="accountType"
                        kendoRadioButton
                        formControlName="account"
                        (ngModelChange)="selectAccount($event)"
                        />
                    </kendo-label>
                </kendo-formfield> -->
      </form>

      <div class="clickToActions" style="padding: 20px 0px">
        <ap-button
          kendoButton
          [btnType]="'secondary'"
          [label]="'Cancel'"
          (onClick)="closeButtonClick()"
        ></ap-button>
        &nbsp;&nbsp;&nbsp;
        <ap-button
          class="okButton"
          kendoButton
          [btnType]="'primary'"
          [label]="'Save'"
          [disabled]="!addAllocationRuleForm.valid"
          (onClick)="saveAllocationRule()"
        ></ap-button>
      </div>
    </div>
    <div class="col-md-4 col-xs-12"></div>
  </div>
</div>
