import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedserviceMoveService {

  constructor() { }
  public moveFormData = new BehaviorSubject<any>([]);
  public moveRouteData = new BehaviorSubject<any>([]);
  setRoutedata(data:any){
    this.moveRouteData.next(data)
  }
  getRoutedata(){
   return this.moveRouteData.asObservable();
  }
  setmoveFormData(data: any) {
     this.moveFormData.next(data);
  }

  getmoveFormData() {
     return this.moveFormData.asObservable();
  }
}
