import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Entry } from '../analysisReviewTree';
import { AttachmentSubMenuPopupComponent } from '../attachment-sub-menu-popup/attachment-sub-menu-popup.component';
import { Employee, employees } from '../employees';
import {
  subLEOPtions,
  LEOPtions,
  SAoptions,
  APoptions,
  ASoptions,
  CLIoptions,
  SSOPtions,
  SS_ASoptions,
  SS_SAoptions,
  SS_Childoptions,
  SS_LEoptions,
  SS_LIOptions,
  ULI_Childoptions,
} from '../contextMenu';
import { MoveSubMenuPopupComponent } from '../move-sub-menu-popup/move-sub-menu-popup.component';
import { DialogService, WindowCloseResult, WindowRef, WindowService } from '@progress/kendo-angular-dialog';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { MenuSelectEvent } from '@progress/kendo-angular-menu';
import { TreeListComponent, ExpandEvent, RowClassArgs } from '@progress/kendo-angular-treelist';
import { take } from 'rxjs/operators';
import { AnalysisDeleteLineComponent } from '../analysis-line-item/analysis-delete-line/analysis-delete-line.component';
import { AnalysisEditLineComponent } from '../analysis-line-item/analysis-edit-line/analysis-edit-line.component';
import { AnalysisEditTaxaccountComponent } from '../analysis-line-item/analysis-edit-taxaccount/analysis-edit-taxaccount.component';
import { AnalysisNewLineComponent } from '../analysis-line-item/analysis-new-line/analysis-new-line.component';
import { AnalysisPackPopupComponent } from '../analysis-pack-popup/analysis-pack-popup.component';
import { DeleteAnalysisPackPopupComponent } from '../delete-analysis-pack-popup/delete-analysis-pack-popup.component';
import { DeleteStatementComponent } from '../delete-statement/delete-statement.component';
import { DisplayAccountPopupComponent } from '../display-account-popup/display-account-popup.component';
import { EditAnalysisPackComponent } from '../edit-analysis-pack/edit-analysis-pack.component';
import { LegendComponent } from '../legend/legend.component';
import { LinkAnalysisStatementPopupComponent } from '../link-analysis-statement-popup/link-analysis-statement-popup.component';
import { LinkLineItemPopupComponent } from '../link-line-item-popup/link-line-item-popup.component';
import { NoLineItemComponent } from '../no-line-item/no-line-item.component';
import { SummaryStatementComponent } from '../summary-statement/summary-statement.component';
import { TaxOutcomeComponent } from '../tax-outcome/tax-outcome.component';
import { UnlinkAnalysisStatementPopupComponent } from '../unlink-analysis-statement-popup/unlink-analysis-statement-popup.component';
import { UnlinkLineItemPopupComponent } from '../unlink-line-item-popup/unlink-line-item-popup.component';

@Component({
  selector: 'app-analysis-review-layout',
  templateUrl: './analysis-review-layout.component.html',
  styleUrls: ['./analysis-review-layout.component.scss'],
})
export class AnalysisReviewLayoutComponent implements OnInit {
  public data: Employee[] = employees;
  public LEOPtions: any[] = LEOPtions;
  public APoptions: any[] = APoptions;
  public SSoptions: any[] = SSOPtions;
  public ASoptions: any[] = ASoptions;
  public CLIoptions: any[] = CLIoptions;
  public SAoptions: any[] = SAoptions;
  public subLEOPtions: any[] = subLEOPtions;
  public SS_SAoptions: any[] = SS_SAoptions;
  public SS_ASoptions: any[] = SS_ASoptions;
  public SS_LEoptions: any[] = SS_LEoptions;
  public SS_Childoptions: any[] = SS_Childoptions;
  public SS_LIOptions: any[] = SS_LIOptions;
  public ULI_Childoptions: any[] = ULI_Childoptions;
  isLoading: boolean = false;
  public selectedRow: any;
  public listindex: number = 0;
  tabs: any[] = [
    { label: 'Analysis statement', icon: 'notebook-outline' },
    { label: 'GLAccounts', icon: 'cost-dollar-outline' },
    { label: 'Transactions', icon: 'notebook-outline' },
  ];

  activeIndex: number = 5;
  public panel: number = 50;
  public event: any;
  checked: any;
  public rootData: Entry[] = [];
  public displayRight: boolean = false;
  public toggleTaxOutcome: boolean = false;
  public lineItems: any;
  gridData: any;
  public lineItemsGridForm: FormGroup | any;
  businesstypeListDB: any;
  businesstypeList: any;
  isLoadId: any;
  sendData: any;
  public treeViewReport = 'TreeView';
  public reportType = 'Value';
  @ViewChild(TreeListComponent)
  public treelist: TreeListComponent | undefined;
  public IsResizable: boolean = false;
  constructor(
    private cookieService: CookieService,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private globalService: GlobalService,
    private windowService: WindowService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone
  ) {}

  public openWindow(): void {
    const window: WindowRef = this.windowService.open({
      title: 'New Window',
      content: AnalysisReviewLayoutComponent,
      width: 1200,
    });

    window.result.subscribe((result) => {
      if (result instanceof WindowCloseResult) {
      }
    });
  }
  ngOnInit(): void {
    if (localStorage.getItem('expanded')) {
      let a = localStorage.getItem('expanded')?.split(',');
      a?.forEach((ele: any) => {
        var y: number = +ele;
        this.expandedIds.push(y);
      });
    }

    if (
      this.route.snapshot.paramMap.get('treeViewReport') != '' &&
      this.route.snapshot.paramMap.get('treeViewReport') != null
    ) {
      this.treeViewReport =
        this.route.snapshot.paramMap.get('treeViewReport') ?? '';
      this.IsResizable = true;
    } else {
      this.IsResizable = false;
    }
    this.getAnalysisReviewTreeData(this.treeViewReport);
  }
  private expandedIds: number[] = [];
  public isExpanded = (dataItem: any): boolean => {
    return this.expandedIds.indexOf(dataItem.id) > -1;
  };
  public onCollapse(args: ExpandEvent): void {
    this.expandedIds = this.expandedIds.filter((id) => id !== args.dataItem.id);
  }

  public onExpand(args: ExpandEvent): void {
    this.expandedIds.push(args.dataItem.id);
  }
  getAnalysisReviewTreeData(reportName: string) {
    this.isLoading = true;
    this.globalService.getAnalysisReviewTreeData(reportName).then(
      (response: any) => {
        this.rootData = response;
        if (response != null && response != undefined)
          this.reportType = response[0].ValueType;
        this.isLoading = false;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  refresh() {
    localStorage.removeItem('expanded');
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }

  onSelect(menuitem: any, nodedata: any) {
    localStorage.removeItem('expanded');
    localStorage.setItem('expanded', this.expandedIds + '');
    if (menuitem.text == 'Attachments') this.openAttachmentPopup();
    if (menuitem.text == 'Move') this.openMovePopup(nodedata);
    if (menuitem.text == 'New Analysis Pack')
      this.openAnalysisPackPopup(nodedata);
    if (menuitem.text == 'Edit Analysis Pack')
      this.editAnalysisPackPopup(nodedata);
    if (menuitem.text == 'Edit Analysis Statement')
      this.summaryStatementPopup(nodedata, false, false, menuitem.text);
    if (menuitem.text == 'New Summary statement')
      this.summaryStatementPopup(nodedata, true, true, menuitem.text);
    if (menuitem.text == 'New Analysis Statement')
      this.summaryStatementPopup(nodedata, true, false, menuitem.text);
    if (menuitem.text == 'Edit Summary statement')
      this.summaryStatementPopup(nodedata, false, true, menuitem.text);
    if (menuitem.text == 'Delete Analysis Statement')
      this.deleteStatementPopup(nodedata);
    if (menuitem.text == 'Link Line Item') this.linkLineitemPopup(nodedata);
    if (menuitem.text == 'Unlink Line Item') this.UnlinkLineitemPopup(nodedata);
    if (menuitem.text == 'Delete Analysis Pack')
      this.deleteAnalysisPackPopup(nodedata);
    if (menuitem.text == 'Edit Line Item') this.editlineItemPopup(nodedata);
    if (menuitem.text == 'Delete Line Item') this.deletelineItemPopup(nodedata);
    if (menuitem.text == 'New Line Item')
      this.newlineItemPopup(
        nodedata,
        false,
        false,
        menuitem.text,
        this.rootData
      );
    if (menuitem.text == 'Edit Tax Account') this.openEditTaxaccount(nodedata);
    if (menuitem.text == 'Link Analysis Statement')
      this.linkAnalysisStatementPopup(nodedata);
    if (menuitem.text == 'Unlink Analysis Statement')
      this.unlinkAnalysisStatementPopup(nodedata);
  }

  // Use an arrow function to capture the 'this' execution context of the class.
  public fetchChildren = (item: Entry): Entry[] => {
    if (item.type == 'SA') {
      var items = item.contents.filter((a) => a.type == 'AS' || a.type == 'SS');
      items.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
      var items1 = item.contents.filter(
        (a) => a.type == 'LI' || a.type == 'CLI'
      );
      items1.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
      item.contents = items;
      item.contents = item.contents.concat(items1);
    } else {
      item.contents.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    }
    if (item.type == 'SS') {
      for (let i = 0; i < item.contents.length; i++) {
        item.contents[i].summarySubAnalysis = true;
      }
    } else if (item.summarySubAnalysis == true && item.type == 'SA') {
      for (let i = 0; i < item.contents.length; i++) {
        item.contents[i].parentStatementAnalysis = true;
        item.contents[i].parentStatementID = item.Satementid;
        item.contents[i].isSummary = true;
      }
    } else if (item.parentStatementAnalysis == true && item.type == 'AS') {
      for (let i = 0; i < item.contents.length; i++) {
        item.contents[i].isSummary = true;
      }
    } else if (item.isSummary == true) {
      for (let i = 0; i < item.contents.length; i++) {
        item.contents[i].isSummary = true;
      }
    }
    return item.contents;
  };

  public hasChildren = (item: Entry): boolean => {
    return item.contents && item.contents.length > 0;
  };
  togglePanel() {
    this.displayRight = !this.displayRight;
    if (this.displayRight == true) {
      this.setindex(1);
    } else {
      this.setindex(0);
    }
  }
  openMovePopup(nodedata: any): void {
    const dialogRef = this.dialogService.open({
      content: MoveSubMenuPopupComponent,
      width: 400,
      height: 'auto',
    });
    dialogRef.result.subscribe((r: any) => {});
    const movepopup = dialogRef.content.instance as MoveSubMenuPopupComponent;
    movepopup.nodeData = nodedata;
    const moveLineItem = dialogRef.content
      .instance as MoveSubMenuPopupComponent;
    moveLineItem.lineItemID = nodedata;
  }
  openAttachmentPopup(): void {
    const dialogRef = this.dialogService.open({
      content: AttachmentSubMenuPopupComponent,
      width: 400,
      height: 300,
    });
  }
  deleteAnalysisPackPopup(node: any): void {
    const dialogRef = this.dialogService.open({
      content: DeleteAnalysisPackPopupComponent,
      width: 500,
      height: 'auto',
    });
    const deleteAPPopup = dialogRef.content
      .instance as DeleteAnalysisPackPopupComponent;

    deleteAPPopup.node = node;
    deleteAPPopup.delAnalysisPackdata = node;
  }
  openAnalysisPackPopup(nodedata: any): void {
    const dialogRef = this.dialogService.open({
      content: AnalysisPackPopupComponent,
      width: 700,
      height: 460,
    });
    dialogRef.result.subscribe((r: any) => {});
    const LEDetails = dialogRef.content.instance as AnalysisPackPopupComponent;
    dialogRef.result.subscribe((r: any) => {});
    LEDetails.nodeData = nodedata;
  }
  editAnalysisPackPopup(nodedata: any): void {
    const dialogRef = this.dialogService.open({
      content: EditAnalysisPackComponent,
      width: 700,
      height: 460,
    });
    dialogRef.result.subscribe((r: any) => {});
    const LEDetails = dialogRef.content.instance as EditAnalysisPackComponent;
    dialogRef.result.subscribe((r: any) => {});
    LEDetails.nodeData = nodedata;
    //LEDetails.isEdit = true;
  }
  summaryStatementPopup(
    node: any,
    isNew: boolean,
    isSummary: boolean,
    menuText: string
  ): void {
    const dialogRef = this.dialogService.open({
      content: SummaryStatementComponent,
      width: 700,
      height: 'auto',
    });

    const analysisStatPopup = dialogRef.content
      .instance as SummaryStatementComponent;
    analysisStatPopup.node = node;
    analysisStatPopup.isNew = isNew;
    analysisStatPopup.isSummary = isSummary;
    analysisStatPopup.popupCaption = menuText;
  }

  deleteStatementPopup(node: any): void {
    const dialogRef = this.dialogService.open({
      content: DeleteStatementComponent,
      width: 500,
      height: 'auto',
    });

    const deleteStatPopup = dialogRef.content
      .instance as DeleteStatementComponent;
    deleteStatPopup.statement = node.name;
    deleteStatPopup.statementid = node.id;
  }

  linkLineitemPopup(node: any): void {
    const dialogRef = this.dialogService.open({
      content: LinkLineItemPopupComponent,
      width: 700,
      height: 500,
    });

    const linklineitemPopup = dialogRef.content
      .instance as LinkLineItemPopupComponent;
    linklineitemPopup.node = node;
  }

  UnlinkLineitemPopup(node: any): void {
    const dialogRef = this.dialogService.open({
      content: UnlinkLineItemPopupComponent,
      width: 500,
      height: 'auto',
    });
    const unlinklineitemPopup = dialogRef.content
      .instance as UnlinkLineItemPopupComponent;
    unlinklineitemPopup.node = node;
  }

  newlineItemPopup(
    node: any,
    isNew: boolean,
    isSummary: boolean,
    menuText: string,
    rootData: any
  ) {
    const dialogRef = this.dialogService.open({
      content: AnalysisNewLineComponent,
      width: 700,
      height: 'auto',
    });
    dialogRef.result.subscribe((r: any) => {});
    const newlineItemPopup = dialogRef.content
      .instance as AnalysisNewLineComponent;
    newlineItemPopup.nodedata = node.BusinessTypeID;
    newlineItemPopup.Satement = node.Satementid;
    newlineItemPopup.BlockId = node.id;
    newlineItemPopup.node = node;
    newlineItemPopup.isNew = isNew;
    newlineItemPopup.isSummary = isSummary;
    newlineItemPopup.popupCaption = menuText;
  }

  editlineItemPopup(node: any) {
    const dialogRef = this.dialogService.open({
      content: AnalysisEditLineComponent,
      width: 700,
      height: 'auto',
    });
    dialogRef.result.subscribe((r: any) => {});
    const editlineItemPopup = dialogRef.content
      .instance as AnalysisEditLineComponent;
    editlineItemPopup.BusinessType = node.BusinessTypeID;
    editlineItemPopup.editLineItemId = node.id;
    editlineItemPopup.nodedata = node;
    editlineItemPopup.nodedata = node.BusinessTypeID;
  }

  deletelineItemPopup(node: any) {
    const dialogRef = this.dialogService.open({
      content: AnalysisDeleteLineComponent,
      width: 500,
      height: 'auto',
    });
    const editlineItemPopup = dialogRef.content
      .instance as AnalysisDeleteLineComponent;
    editlineItemPopup.BusinessType = node.BusinessTypeID;
    editlineItemPopup.delLIdata = node.id;
  }
  linkAnalysisStatementPopup(node: any): void {
    const dialogRef = this.dialogService.open({
      content: LinkAnalysisStatementPopupComponent,
      width: 700,
      height: 500,
    });
    const linkAnalysStatementPopup = dialogRef.content
      .instance as LinkAnalysisStatementPopupComponent;
    linkAnalysStatementPopup.node = node;
  }
  unlinkAnalysisStatementPopup(node: any): void {
    const dialogRef = this.dialogService.open({
      content: UnlinkAnalysisStatementPopupComponent,
      width: 500,
      height: 200,
    });
    const unlinkAnalysStatementPopup = dialogRef.content
      .instance as UnlinkAnalysisStatementPopupComponent;
    unlinkAnalysStatementPopup.node = node;
  }

  async openEditTaxaccount(node: any) {
    let response = await this.globalService.GetTaxAccountByLineItem(node.id);

    if (response.length == 0) {
      const dialogRef = this.dialogService.open({
        content: NoLineItemComponent,
        width: 700,
        height: 200,
      });
    } else if (response.length == 1) {
      const dialogRef = this.dialogService.open({
        content: AnalysisEditTaxaccountComponent,
        width: 700,
        height: 500,
      });

      const AnalysisEditTaxaccount = dialogRef.content
        .instance as AnalysisEditTaxaccountComponent;
      dialogRef.result.subscribe((r: any) => {});
      AnalysisEditTaxaccount.gridBusinessType = node.BusinessTypeID;
      AnalysisEditTaxaccount.isCreateFromLI = true;
      AnalysisEditTaxaccount.LICreateTaxData = node;
      AnalysisEditTaxaccount.nodedata = node.BusinessTypeID;
      AnalysisEditTaxaccount.TaxAccountCodeId = response[0].TaxAccountCodeId;
    } else {
      this.AddAccountPopup(response, node);
    }
  }
  AddAccountPopup(response: any, lineItemNode: any): void {
    const dialogRef = this.dialogService.open({
      content: DisplayAccountPopupComponent,
      width: 550,
      height: 380,
    });

    const displayAccountPopup = dialogRef.content
      .instance as DisplayAccountPopupComponent;
    displayAccountPopup.data = response;
    displayAccountPopup.LineitemNode = lineItemNode;
    dialogRef.result.subscribe((node: any) => {
      if (node != 'onCancel') {
        const dialogRef = this.dialogService.open({
          content: AnalysisEditTaxaccountComponent,
          width: 1000,
          height: 500,
        });
        const newTaxAccount = dialogRef.content
          .instance as AnalysisEditTaxaccountComponent;
        newTaxAccount.gridBusinessType = lineItemNode.BusinessTypeID;
        newTaxAccount.isCreateFromLI = true;
        newTaxAccount.LICreateTaxData = lineItemNode;
        newTaxAccount.TaxAccountCodeId = node.TaxAccountCodeId;
        newTaxAccount.nodedata = node.BusinessTypeID;
      }
    });
  }

  taxOutcomeReportPopup(): void {
    const dialogRef = this.dialogService.open({
      content: TaxOutcomeComponent,
      width: 850,
      height: 500,
    });
  }

  Legend(): void {
    const dialogRef = this.dialogService.open({
      content: LegendComponent,
      width: 1200,
      height: 160,
    });
  }

  loadTaxoutComegrid(): void {
    this.getAnalysisReviewTreeData('TaxOutComeReport');
  }

  rowSelect(event: any) {
    this.selectedRow = event.items[0].dataItem;

    if (this.activeIndex > 3) this.activeIndex = 0;
  }

  roundedPercentage(myValue: number, totalValue: number, index: number) {
    var result = myValue;
    if (index != 0 && this.reportType == 'Percentage') {
      if (totalValue == 0 || myValue == 0) {
        return 0;
      }
      return Math.round((myValue / totalValue) * 100);
    } else {
      return result.toLocaleString();
    }
  }

  public ngAfterViewChecked(): void {
    this.fitColumns();
  }
  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.Highlighted == true) {
      return { highlighted: true };
    } else {
      return { highlighted: false };
    }
  };
  public fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.treelist?.autoFitColumns();
      });
  }
  public setindex(i: any): void {
    this.listindex = i;
  }
  public diableSplitpanel(): void {
    this.displayRight = false;
  }
  public handleTaxOutcome(event: MenuSelectEvent): void {
    if (event.item.text == 'Options') {
      this.taxOutcomeReportPopup();
      this.setindex(5);
      //this.diableSplitpanel()
    }
    if (event.item.text == 'Toggle') {
      // this.toggleTaxOutcome = !this.toggleTaxOutcome
      this.toggleTax();
    }
  }
  public toggleTax(): void {
    if (this.router.url === '/analysisreview') {
      this.router.navigate([
        '/analysisreview/treeViewReport/',
        'TaxOutComeReport',
      ]);
    } else if (
      this.router.url === '/analysisreview/treeViewReport/TaxOutComeReport'
    ) {
      this.router.navigate(['/analysisreview']);
    }
  }
  public handleComparartiveAnalysis(event: MenuSelectEvent): void {
    if (event.item.text == 'Options') this.loadTaxoutComegrid();
    this.setindex(4);
    //this.diableSplitpanel()
  }
}
