import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  DialogRef,
  DialogService,
  ActionsLayout,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { Keyword } from 'src/app/_models/keyword';
import { KeywordGroupFolder } from 'src/app/_models/keywordGroupFolder';

@Component({
  selector: 'app-keyword-export',
  templateUrl: './keyword-export.component.html',
  styleUrls: ['./keyword-export.component.scss'],
})
export class KeywordExportComponent implements OnInit {
  public value: KeywordGroupFolder[] = [];
  keywordGroupFolders: KeywordGroupFolder[] = [];
  keywordGroupFolder!: KeywordGroupFolder;
  isLoading: boolean = false;
  public actionsLayout: ActionsLayout = 'normal';
  keyWordParams: number[] = [];

  constructor(
    // public dialog: DialogRef,
    // private dialogService: DialogService,
    private globalService: GlobalService,
    public toastr: ToastrManager,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAllKeywordGroupFolders();
  }

  resetKeywordGroupFolderData(): void {
    this.keywordGroupFolder = {
      Name: '',
      VocabularyGroupFolderId: 0,
    };
  }

  closeButtonClick() {
    // this.dialog.close();
    this.router.navigate(['/manageKeyword']);
  }

  getAllKeywordGroupFolders(): void {
    this.resetKeywordGroupFolderData();
    this.isLoading = true;
    this.globalService.getAllKeywordGroupFolders().subscribe(
      (response) => {
        this.keywordGroupFolders = response;

        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getDownloadExcel(): void {
    this.keyWordParams = [];
    this.value.map((key) => {
      this.keyWordParams.push(key.VocabularyGroupFolderId);
    });

    this.isLoading = true;
    this.globalService.ExportKeywords(this.keyWordParams).subscribe(
      (response) => {
        this.keywordGroupFolders = response;

        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const fileName = 'KeywordExport_' + timestamp;
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.toastr.successToastr('Data exported successfully.', 'Success');
        this.getAllKeywordGroupFolders();
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  export(): void {
    if (this.value.length > 0) {
      this.getDownloadExcel();
    }
  }
}
