import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, WindowService } from '@progress/kendo-angular-dialog';
import { MenuSelectEvent } from '@progress/kendo-angular-menu';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AnalysisstatementgridpopupComponent } from '../analysisStatement/analysisstatementgridpopup/analysisstatementgridpopup.component';
import { ClientselectpopupComponent } from '../clientselectpopup/clientselectpopup.component';
import { AuthenticationService } from '../common/authentication/authentication.service';
import { AuthorizationService } from '../common/authorization/authorization.service';
import { GlobalService } from '../common/globalservice/globalservice.service';
import { MenubarService } from '../common/menubarService/menubar.service';
import { MyGlobalObject } from '../common/myglobalobject';
import { GLaccountsgridpopupComponent } from '../glaccounts/glaccountsgridpopup/glaccountsgridpopup.component';
import { LockstatusComponent } from '../lockstatus/lockstatus.component';
import { TaxaccountsgridpopupComponent } from '../taxaccounts/taxaccountsgridpopup/taxaccountsgridpopup.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user: any;
  initials: any;
  displaySelectedClient: any;
  ismenuVisible: boolean = true;
  constructor(
    public myGlobalObject: MyGlobalObject,
    private globalService: GlobalService,
    private authenticationService: AuthenticationService,
    private auth: AuthorizationService,
    private dialogService: DialogService,
    private windowService: WindowService,
    private router: Router,
    private cookieService: CookieService,
    public menubarService: MenubarService
  ) {
    //this.authenticationService.checkAuthentication();
  }

  ngOnInit(): void {
    // this.authenticationService.getUserInfo().then((response) => {
    //   this.user = response;
    //   this.initials =
    //     this.user.given_name.charAt(0).toUpperCase() +
    //     this.user.family_name.charAt(0).toUpperCase();
    //   this.myGlobalObject.loggedinUserDetail = response;
    // });
    // if (this.cookieService.get('SELECTEDCLIENT'))
    //   this.displaySelectedClient = JSON.parse(
    //     this.cookieService.get('SELECTEDCLIENT')
    //   );
    // this.globalService
    //   .getLoggedUserInfo()
    //   .then((response) => {
    //     if (response != undefined) this.fetchClientList();
    //   })
    //   .catch((error) => {});

    if (this.myGlobalObject.userDetailsEnlightenPortal !== undefined) {
      let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');

      if (selectedClientVal)
        this.displaySelectedClient = JSON.parse(selectedClientVal);
    }
    this.checkLockStatus('Import transactions');
    this.checkLockStatus('Import trial balance');
  }
  fetchClientList() {
    this.globalService
      .fetchClientsList()
      .then((res) => {
        this.myGlobalObject.mappedClientsForUser = res;
        let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');

        if (selectedClientVal == '') {
          if (res.length < 1) {
            this.router.navigate(['/unauthorized']);
          } else this.openDialogForm();
        }
      })
      .catch((err) => {});
  }
  UnlockSegment(segmentName: string) {
    this.globalService.UnLockSegment(segmentName).subscribe(
      (response) => {},
      (error) => {
        console.log(error);
      }
    );
  }
  checkLockStatus(segmentName: string) {
    var details: any;
    this.globalService.segmentLockDetail(segmentName).subscribe(
      (response) => {
        details = response;
        if (
          details.IsSegmentLocked &&
          details.LockOwners[0] == this.myGlobalObject.loggedinUserDetail.mail
        )
          this.UnlockSegment(segmentName);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  public openDialogForm(): void {
    const dialogRef = this.dialogService.open({
      content: ClientselectpopupComponent,
      width: 500,
      height: 200,
    });
    const clientInfo = dialogRef.content.instance as ClientselectpopupComponent;
    dialogRef.result.subscribe((r: any) => {
      if (r.text === 'Submit') {
        let selectedClient = this.myGlobalObject.mappedClientsForUser.find(
          (obj: any) => {
            return obj.ClientId === clientInfo.formGroup.value.client.value;
          }
        );
        this.myGlobalObject.selectedClient = selectedClient;
        localStorage.setItem('SELECTEDCLIENT', JSON.stringify(selectedClient));
        let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');

        this.displaySelectedClient = JSON.parse(selectedClientVal);
        this.checkLockStatus('Import trial balance');
        this.checkLockStatus('Import transactions');
      }
    });
  }
  public handleSelectionChange(event: MenuSelectEvent): void {
    // process the event only if it's a leaf item
    if (event.item.text == 'Business Types')
      this.router.navigate(['/manageBusinessType']);
    else if (event.item.text == 'Legal Entities')
      this.router.navigate(['/legalEntities']);
    else if (event.item.text == 'Analysis Statement')
      this.openAnalysisStatementDialog();
    else if (event.item.text == 'Line Items')
      this.router.navigate(['/lineItems']);
    else if (event.item.text == 'Tax Accounts')
      this.router.navigate(['/taxAccounts']);
    else if (event.item.text == 'Habitats') this.router.navigate(['/habitats']);
    else if (event.item.text == 'GL Accounts')
      this.router.navigate(['/glAccounts']);
    else if (event.item.text == 'Keyword Groups')
      this.router.navigate(['/manageKeyword']);
    else if (event.item.text == 'Manage journals')
      this.router.navigate(['/manageJournal']);
    else if (event.item.text == 'Map GL Accounts')
      this.router.navigate(['/mapGlAccounts']);
    else if (event.item.text == 'Trial Balance') this.trialBalanceClick();
    else if (event.item.text == 'Transactions') this.transactionsClick();
    else if (event.item.text == 'Import GL Accounts') this.GlAccount();
    else if (event.item.text == 'Transaction')
      this.router.navigate(['/transactionsAllocate']);
    else if (event.item.text == 'Analysis & Review')
      this.router.navigate(['/analysisreview']);
    else if (event.item.text == 'Allocation rules')
      this.router.navigate(['/manageAllocationRule']);
    else if (event.item.text == 'Breakdown level')
      this.router.navigate(['/offlineReferralBreakdownLevel']);
  }

  public openAnalysisStatementDialog(): void {
    const dialogRef1 = this.dialogService.open({
      content: AnalysisstatementgridpopupComponent,
      width: '100%',
      height: '100%',
    });
    const clientInfo1 = dialogRef1.content
      .instance as AnalysisstatementgridpopupComponent;
    dialogRef1.result.subscribe((r1: any) => {});
  }
  public openTaxAccountsDialog(): void {
    const dialogRef1 = this.dialogService.open({
      content: TaxaccountsgridpopupComponent,
      width: 1350,
      height: 600,
    });
    const clientInfo1 = dialogRef1.content
      .instance as TaxaccountsgridpopupComponent;
    dialogRef1.result.subscribe((r1: any) => {});
  }
  public openGLAccountsDialog(): void {
    const dialogRef1 = this.dialogService.open({
      content: GLaccountsgridpopupComponent,
      width: 1300,
      height: 600,
    });
    const clientInfo1 = dialogRef1.content
      .instance as GLaccountsgridpopupComponent;
    dialogRef1.result.subscribe((r1: any) => {});
  }
  public trialBalanceClick() {
    this.globalService.LockSegment('Import trial balance').subscribe(
      (response) => {
        if (response.IsLockSuccessful == false) {
          const dialogRef1 = this.dialogService.open({
            content: LockstatusComponent,
            width: 500,
            height: 200,
          });
          const clientInfo1 = dialogRef1.content
            .instance as LockstatusComponent;
          dialogRef1.result.subscribe((r1: any) => {});
          const lockststatusdetails = dialogRef1.content
            .instance as LockstatusComponent;
          lockststatusdetails.statusDetails = response;
        } else if (response.IsLockSuccessful == true) {
          this.router.navigate(['/trialBalanceImport']);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  public transactionsClick() {
    this.globalService.LockSegment('Import transactions').subscribe(
      (response) => {
        if (response.IsLockSuccessful == false) {
          const dialogRef1 = this.dialogService.open({
            content: LockstatusComponent,
            width: 500,
            height: 200,
          });
          const clientInfo1 = dialogRef1.content
            .instance as LockstatusComponent;
          dialogRef1.result.subscribe((r1: any) => {});
          const lockststatusdetails = dialogRef1.content
            .instance as LockstatusComponent;
          lockststatusdetails.statusDetails = response;
        } else if (response.IsLockSuccessful == true) {
          this.router.navigate(['/transactionsImport']);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public GlAccount() {
    this.router.navigate(['/ImportglAccounts']);
  }
  homeClick() {
    this.router.navigate(['/home']);
  }
  logout(): void {
    // Logout  OpenAM session
    // Hard  OpenAM token as logout does not clears.
    // this.cookieService.set('OPENAMSESSION', '');
    // this.cookieService.delete('OPENAMSESSION');
    // this.cookieService.set('SELECTEDCLIENT', '');
    // this.cookieService.delete('SELECTEDCLIENT');
    localStorage.removeItem('OPENAMSESSION');
    localStorage.removeItem('tokenCreated');
    localStorage.removeItem('SELECTEDCLIENT');
    localStorage.removeItem('selectedFiscalYear');
    localStorage.removeItem('selectedTreenode');
    window.location.href = environment.openam.logoutUrl;
  }
}
