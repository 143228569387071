import { Component, OnInit } from '@angular/core';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { AllocationRulesService } from 'src/app/common/services/allocationrule/allocation-rules.service';
import { DefineExpressions } from 'src/app/_models/defineExpressionRulesResponse';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { DeleteExpressionComponent } from '../delete-expression/delete-expression.component';
import { DialogService } from '@progress/kendo-angular-dialog';
import { Router } from '@angular/router';
import { MyGlobalObject } from 'src/app/common/myglobalobject';

@Component({
  selector: 'app-define-expressions',
  templateUrl: './define-expressions.component.html',
  styleUrls: ['./define-expressions.component.scss'],
})
export class DefineExpressionsComponent implements OnInit {
  public defineExpressions: DefineExpressions[] = [];
  public gridData: DefineExpressions[] = [];
  defineExpression!: DefineExpressions;
  public gridView!: GridDataResult;
  public pageSize = 20;
  public skip = 0;
  public totalCount: any = 0;
  public startIndex = 0;
  public maxRows = 20;
  public isSearch: boolean = false;
  public inputValue: any;
  isLoading: boolean = false;
  private items: DefineExpressions[] = this.gridData;
  expressions: any;
  localCopyIndex: any;
  constructor(
    private allocationRulesService: AllocationRulesService,
    private dialogService: DialogService,
    private router: Router,
    public myGlobalObject: MyGlobalObject
  ) {
    this.loadgridData();
  }

  ngOnInit(): void {}

  public loadgridData(): void {
    if (Object.keys(this.myGlobalObject.expressionRowsIndex).length != 0) {
      this.localCopyIndex = this.myGlobalObject.expressionRowsIndex;
      this.skip = this.localCopyIndex.startIndex;
      this.startIndex = this.localCopyIndex.startIndex;
      this.maxRows = this.localCopyIndex.endIndex;
      this.myGlobalObject.expressionRowsIndex = {};
      this.getDefineExpressionsList(
        this.localCopyIndex.startIndex,
        this.localCopyIndex.endIndex
      );
    } else this.getDefineExpressionsList(this.startIndex, this.maxRows);
  }
  // data grid format for pagination
  public loadData(): void {
    this.items = this.gridData;
    this.gridView = {
      data: this.items,
      total: this.totalCount,
    };
  }
  // total expression count and get all define expression method
  getDefineExpressionsList(startIndex: number, maxRows: number): void {
    this.isLoading = true;
    this.getTotalCountExpressions('');
    this.allocationRulesService
      .getDefineExpressionsDataList(startIndex, maxRows)
      .subscribe(
        (response) => {
          this.gridData = response;
          this.isLoading = false;
          this.loadData();
        },
        (error) => {
          console.log('error from api', error);
        }
      );
  }
  // onpage change click method
  public pageChange(event: any): void {
    this.skip = event.skip;
    if (this.isSearch) {
      this.getSearchDefineExpressions(
        this.inputValue,
        (this.startIndex = event.skip),
        this.maxRows
      );
    } else {
      this.getDefineExpressionsList(
        (this.startIndex = event.skip),
        this.maxRows
      );
    }
  }
  // on search event method
  public onSearch(event: { originEvent: any; queryStr: string }): void {
    this.inputValue = event.queryStr;
    this.isSearch = this.inputValue.length ? true : false;
    this.skip = 0;
    if (this.inputValue.length) {
      this.getSearchDefineExpressions(
        this.inputValue,
        (this.startIndex = 0),
        this.maxRows
      );
      this.getTotalCountExpressions(this.inputValue);
    } else {
      this.getDefineExpressionsList((this.startIndex = 0), this.maxRows);
      this.getTotalCountExpressions('');
    }
  }

  // get search define api call
  public getSearchDefineExpressions(
    inputValue: any,
    startIndex: number,
    maxRows: number
  ): void {
    this.allocationRulesService
      .getDefineExpressionsSearchList(inputValue, startIndex, maxRows)
      .subscribe(
        (response) => {
          this.gridData = response;
          this.loadData();
        },
        (error) => {
          console.log(error);
        }
      );
  }
  // get total count api call
  public getTotalCountExpressions(inputValue: any): void {
    this.allocationRulesService
      .getDefineExpressionsTotalCount(inputValue ? inputValue : '')
      .subscribe(
        (response) => {
          this.totalCount = response;
          this.loadData();
        },
        (error) => {
          console.log(error);
        }
      );
  }
  deleteButtonClick(rowDetails: any): void {
    const dialogRef = this.dialogService.open({
      content: DeleteExpressionComponent,
      width: 500,
      height: 200,
    });
    const deleteExpressionPopup = dialogRef.content
      .instance as DeleteExpressionComponent;
    dialogRef.result.subscribe((r: any) => {
      this.loadgridData();
    });
    deleteExpressionPopup.delExpressiondata = rowDetails;
  }

  public allocationrulebuttonClick() {
    this.router.navigate(['/manageAllocationRule']);
  }

  public newButtonClick() {
    this.router.navigate(['/newExpression/null']);
  }

  public editButtonClick(rowDetails: any): void {
    this.myGlobalObject.expressionRowsIndex.startIndex = this.startIndex;
    this.myGlobalObject.expressionRowsIndex.endIndex = this.maxRows;
    this.router.navigate(['/editExpression/edit/', rowDetails.RuleId]);
  }

  public copyButtonClick(rowDetails: any): void {
    this.router.navigate(['/copyExpression/copy/', rowDetails.RuleId]);
  }
}
