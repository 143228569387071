import { Component, OnInit, Input } from '@angular/core';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import {
  FormBuilder,
  FormGroup,
  RequiredValidator,
  Validators,
} from '@angular/forms';
import {
  DialogRef,
  DialogContentBase,
  ActionsLayout,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'app-editstatementcolumn',
  templateUrl: './editstatementcolumn.component.html',
  styleUrls: ['./editstatementcolumn.component.scss'],
})
export class EditstatementcolumnComponent
  extends DialogContentBase
  implements OnInit
{
  @Input() public editStatementColumnData: any;
  newColumnForm: FormGroup | any;
  ColumnNameErrorMessage: string = 'Header is required';

  constructor(
    public dialog: DialogRef,
    private router: Router,
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {
    super(dialog);
    this.newColumnForm = this.fb.group({
      ColumnName: [''],
    });
  }

  ngOnInit(): void {
    if (this.editStatementColumnData) {
      var result = this.getColumnLayoutData(
        this.editStatementColumnData.ChildNodeLayoutId
      );
    }
  }

  public getColumnLayoutData(childNodeLayoutId: number) {
    this.globalService
      .getLayoutColumnData(childNodeLayoutId)
      .then((res) => {
        if (res != null && res != undefined) {
          this.newColumnForm.controls['ColumnName'].setValue(
            res.ColumnHeaderText
          );
        }
      })
      .catch((err) => {});
  }

  cancelClick() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/manageanalysisstatementlayouts']));
  }

  saveColumn() {
    let statementColumn = this.newColumnForm.controls['ColumnName'].value;
    let statementID = this.editStatementColumnData.ChildNodeLayoutId;

    this.globalService
      .savestatementColumn(statementID, statementColumn)
      .then((res: any) => {
        if (res == 'Analysis Statement Column updated successfully') {
          this.toastr.successToastr(
            'Analysis statement column updated successfully',
            'Success'
          );
          this.displayGrid();
        } else if (res == 'Analysis Statement Column already exists') {
          this.newColumnForm.controls['ColumnName'].markAsTouched();
          this.ColumnNameErrorMessage =
            'Please modify Header as it is not unique';
          this.newColumnForm.controls['ColumnName'].setErrors({
            incorrect: true,
          });
          return;
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.displayGrid();
        }
      })
      .catch((err) => {});
  }

  displayGrid() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/manageanalysisstatementlayouts']));
  }
}
