import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { NewtaxaccountComponent } from 'src/app/taxaccounts/newtaxaccount/newtaxaccount.component';
import { LineitemsComponent } from '../lineitems.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newlineitem',
  templateUrl: './newlineitem.component.html',
  styleUrls: ['./newlineitem.component.scss'],
})
export class NewlineitemComponent implements OnInit {
  @Input() public BusinessType: any;
  @Input() public LineItemId: any;
  @Input() public copyLineItemId: any;
  @Input() public delLIDetails: any;
  selectedClient: string | any;
  requireFurtherAnalysis: boolean = false;
  BusinessTypeId: any;
  BusinessDesc: any;
  AccountClassDescriptionList: any;
  AccountClassDescriptionListDB: any;
  AccountingAreaDescriptionList: any;
  AccountingAreaDescriptionListDB: any;
  newLineItemForm: FormGroup | any;
  LIDetailsfromDb: any;
  descriptionErrorMessage: any = 'Description is required';
  AccountingAreaID: any = 'AccountingAreaID is required';
  AccountClass: any = 'AccountClass is required';
  public actionsLayout: ActionsLayout = 'normal';
  accountclassErrorMessage: any = 'Account Class is required';
  complexMessage: any = 'This needs analysing further';
  Complex: number = 0;
  AccountError: boolean = false;

  constructor(
    // public dialog: DialogRef,
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {
    // super(dialog);
    this.newLineItemForm = this.fb.group({
      Description: [''],
      AccountingAreaID: [''],
      AccountClass: [''],
      ParentLineItem: null,
      CreateTaxAccount: true,
    });
  }

  ngOnInit(): void {
    this.BusinessType = history.state.BusinessType;
    this.BusinessTypeId = this.BusinessType.BusinessTypeId;
    this.BusinessDesc = this.BusinessType.Description;

    this.getRefData();
    this.selectedClient = localStorage.getItem('SELECTEDCLIENT');
  }
  getRefData() {
    this.globalService
      .getAllAccountClass()
      .then((res) => {
        this.AccountClassDescriptionListDB = res;
        this.AccountClassDescriptionList =
          this.AccountClassDescriptionListDB.slice();
      })
      .catch((err) => {});
    this.globalService
      .getAllLineItemsByBusinessType(this.BusinessTypeId)
      .then((res) => {
        this.AccountingAreaDescriptionListDB = res;
        this.AccountingAreaDescriptionList =
          this.AccountingAreaDescriptionListDB.slice();
      })
      .catch((err) => {}); // this.globalService.getParentLineItem().then((res) => { //   this.AccountingAreaDescriptionListDB=res; //   this.AccountingAreaDescriptionList=this.AccountingAreaDescriptionListDB.slice(); // }).catch((err) => { // });
  }

  getLineItemById(LineItemId: any) {
    this.globalService
      .getLineItemById(LineItemId)
      .then((res) => {
        if (res) {
          this.LIDetailsfromDb = res;
          this.newLineItemForm.controls['Description'].setValue(
            this.LIDetailsfromDb.Description
          );
          this.newLineItemForm.controls['AccountClass'].setValue(
            this.LIDetailsfromDb.AccountClass
          );
          this.newLineItemForm.controls['AccountingArea'].setValue(
            this.LIDetailsfromDb.AccountingArea
          );
          this.newLineItemForm.controls['AmountDisplayIndicator'].setValue(
            this.LIDetailsfromDb.AmountDisplayIndicator
          );
        }
      })
      .catch((err) => {});
  }
  handleParentLineItemChange(value: any) {
    if (value != undefined) {
      this.newLineItemForm.controls['CreateTaxAccount'].setValue(true);
      this.newLineItemForm.controls['CreateTaxAccount'].disable();
    } else {
      this.newLineItemForm.controls['CreateTaxAccount'].enable();
    }
  }
  handleAccountClassChange(value: any) {
    if (value == undefined) {
      this.Complex = 1;
    } else if (value.Description === 'Complex') {
      this.Complex = 2;
      this.newLineItemForm.controls['ParentLineItem'].setValue(null);
      this.newLineItemForm.controls['ParentLineItem'].disable();
      this.newLineItemForm.controls['CreateTaxAccount'].setValue(true);
      this.newLineItemForm.controls['CreateTaxAccount'].disable();
      this.requireFurtherAnalysis = true;
    } else {
      this.newLineItemForm.controls['ParentLineItem'].enable();
      //this.newLineItemForm.controls['CreateTaxAccount'].setValue(false);
      this.newLineItemForm.controls['CreateTaxAccount'].enable();
      this.requireFurtherAnalysis = false;
      // this.Complex=false
    }
    if (value.Description == 'Simple') {
      this.Complex = 0;
    }
    // if(value.Description === 'undefined' ){
    //   this.Complex=false
    // }
    // this.AccountClassDescriptionList = this.AccountClassDescriptionListDB.filter(
    //   (s:any) => s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
    // );
  }

  handleFilterParentLineItem(value: any) {
    this.AccountingAreaDescriptionList =
      this.AccountingAreaDescriptionListDB.filter(
        (s: any) =>
          s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
  }
  handleFilterAccountClass(value: any) {
    this.AccountClassDescriptionList =
      this.AccountClassDescriptionListDB.filter(
        (s: any) =>
          s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
  }
  cancelClick() {
    this.router.navigate(['/lineItems'], {
      state: {
        BusinessType: this.BusinessType,
      },
    });
    // this.dialog.close();
    // this.dialogService.open({
    //   content: LineitemsComponent,
    //   width: 1200,
    //   height:600
    // });
    //this.displayGrid();
  }

  /*saveLineItem(){

  var formObject = this.newLineItemForm.getRawValue();
    let AccountingAreaVal,AccountingAreaIdVal;
    if(formObject.AccountClass.ID ==3 || formObject.ParentLineItem==null){
      AccountingAreaVal=null;
      const accountclassErrorMessage = "This will be analyse further";
      AccountingAreaIdVal="";
    }else{
      
     AccountingAreaVal=formObject.ParentLineItem.LineID;
      AccountingAreaIdVal=formObject.ParentLineItem.AccountingAreaID;
    }
    let LineItemObject={
      "ID": 0,
      "AccountingAreaID": null,
       "Description": formObject.Description,
       "RequireFurtherAnalysis": this.requireFurtherAnalysis,
       "AccountDerivation": null,
       "AmountDisplayIndicator": true,
       "TaxChartOfAccounts": this.BusinessTypeId,
       "AccountClass": formObject.AccountClass.ID,
       "AccountAreaGroup": null,
      "StatementLevel": null,
       "AccountingArea": AccountingAreaVal,
       "HelpId": ""
      }

      this.globalService.insertLineItem(LineItemObject).then((res) => {      
               if(res ==  "Line Item added successfully")
              {
                this.toastr.successToastr(
                  res,
                  'Success'
                );
                 this.displayGrid();
              }
              else{
                this.toastr.errorToastr('Something went wrong try after sometime', 'Oops!' );
                // this.displayGrid();
              }
           
            }).catch((err) => {
              
            });
}*/

  saveLineItem() {
    var formObject = this.newLineItemForm.getRawValue();

    let AccountingAreaVal, AccountingAreaIdVal;
    if (formObject.AccountClass.ID == 3 || formObject.ParentLineItem == null) {
      AccountingAreaVal = null;
      const accountclassErrorMessage = 'This needs analysing further';
      AccountingAreaIdVal = '';
    } else {
      AccountingAreaVal = formObject.ParentLineItem.LineID;
      AccountingAreaIdVal = formObject.ParentLineItem.AccountingAreaID;
    }

    let LineItemObject = {
      ID: 0,
      AccountingAreaID: null,
      Description: formObject.Description,
      RequireFurtherAnalysis: this.requireFurtherAnalysis,
      AccountDerivation: null,
      AmountDisplayIndicator: true,
      TaxChartOfAccounts: this.BusinessTypeId,
      AccountClass: formObject.AccountClass.ID,
      AccountAreaGroup: null,
      StatementLevel: null,
      AccountingArea: AccountingAreaVal,
      HelpId: '',
    };

    this.globalService
      .insertLineItem(LineItemObject)
      .then((res) => {
        // if (res) {
        if (res.Item2 == 'Line Item added successfully') {
          this.toastr.successToastr(res.Item2, 'Success');
          if (this.newLineItemForm.getRawValue().CreateTaxAccount == true) {
            this.router.navigate(['/newTaxAccount'], {
              state: {
                gridBusinessType: this.BusinessType,
                isCreateFromLI: true,
                LICreateTaxData: res.Item1,
              },
            });
            //this.dialog.close();
            // const dialogRef = this.dialogService.open({
            //   content: NewtaxaccountComponent,
            //   width: 950,
            //   height:595
            // });
            // const newTaxAccount = dialogRef.content.instance as NewtaxaccountComponent;
            // dialogRef.result.subscribe((r: any) => {
            // });
            // newTaxAccount.gridBusinessType = this.BusinessType;
            // newTaxAccount.isCreateFromLI=true;
            // newTaxAccount.LICreateTaxData=res.Item1;
          } else {
            //this.displayGrid();

            this.router.navigate(['/lineItems'], {
              state: {
                BusinessType: this.BusinessType,
              },
            });
          }
        } else if (res.Item2 == 'Line Item Description already exists') {
          this.newLineItemForm.controls['Description'].markAsTouched();
          this.descriptionErrorMessage =
            'Please modify Description as it is not unique';
          this.newLineItemForm.controls['Description'].setErrors({
            incorrect: true,
          });
          return;
        } else {
          this.toastr.errorToastr(res.Item2, 'Failed');
        }
      })
      // else {

      // this.displayGrid();
      // }
      // })
      .catch((err) => {
        console.log(err);
      });
  }

  displayGrid() {
    //this.dialog.close();
    const dialogRef = this.dialogService.open({
      content: LineitemsComponent,
      width: 1200,
      height: 600,
    });
    const editLineItem = dialogRef.content.instance as LineitemsComponent;
    var defaultBT = {
      BusinessTypeId: this.BusinessTypeId,
      Description: this.BusinessDesc,
      Library: false,
      CountryName: 'UNITED KINGDOM',
      IndustryName: null,
      HelpId: '',
      CountryId: 231,
      IndustryId: null,
    };
    dialogRef.result.subscribe((r: any) => {});
    editLineItem.isLoadId = true;
    editLineItem.lineItemsGridForm = new FormGroup({
      businessTypeId: new FormControl(defaultBT),
    });
  }
}
