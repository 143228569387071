<!-- <kendo-dialog-titlebar (close)="cancelClick()">
    <div style="font-size: 18px; line-height: 1.1em;">
         Delete statutory treatment
    </div>
</kendo-dialog-titlebar>
<div>
    <p><span class="k-icon k-i-warning"></span></p>
    <p style="margin-left:80px;font-size: medium;">You have selected to delete the selected statutory treatment(s)</p> 
    <p style="margin-left:80px;font-size: medium;">Are you sure you wish to continue?</p>
</div> -->
<kendo-dialog-titlebar (click)="cancelClick()">
    <div style="font-size: 20px; line-height: 1.3em"> Delete statutory treatment</div>
  </kendo-dialog-titlebar>
  <div style="display: flex">
    <p>
      <span
        class="Appkit4-icon icon-circle-warning-outline"
        style="font-size: 24px; color: #d04a02"
      ></span>
    </p>
    <p style="margin-left: 10px; font-size: medium">
      Are you sure you want to delete <b>{{ delStatutoryTreatmentdata.Description }}</b> statutory treatment?
    </p>
  </div>
<!-- Click to Actions -->
<div class="clickToActions" style="padding: 10px 0px 0px 0px">
    <ap-button
      [btnType]="'secondary'"
      [label]="'No'"
      (click)="cancelClick()"
      class="cancelButton"
      style="width: 100px;"
  
    ></ap-button>
    <ap-button
      [btnType]="'primary'"
      [label]="'Yes'"
      themeColor="primary"
      (click)="deleteStatutoryTreatment()"
      class="okButton"
      style="margin-right: 6px"
    ></ap-button>
  </div>
