<mat-card-content class="gridstyle">
  <kendo-appbar position="top" style="background-color:#464646">
    <div>
    <kendo-appbar-section>
      <kendo-splitbutton
      (buttonClick)="onPaste()"
      [data]="data"
      icon="menu"
      fillMode="flat"
      style="color:#d04a02;"
    >
    </kendo-splitbutton>
    </kendo-appbar-section>
</div>

      <kendo-appbar-section>
      <span class="gap"></span>
      <kendo-menu>
        <kendo-menu-item text="Home" cssClass="mycustom">
            <kendo-menu-item text="Roll Forward"></kendo-menu-item>
            <kendo-menu-item text="Select Year"></kendo-menu-item>
        </kendo-menu-item>
      </kendo-menu>
      </kendo-appbar-section>

      <kendo-appbar-section>
        <span class="gap"></span>
        <kendo-menu
        (select)="handleSelectionChange($event)">
          <kendo-menu-item text="Setup" cssClass="mycustom">
              <kendo-menu-item text="Business Types"></kendo-menu-item>
              <kendo-menu-item text="Legal Entities"></kendo-menu-item>
              <kendo-menu-item text="Analysis Statement"></kendo-menu-item>
              <kendo-menu-item text="Statement Details">
                <kendo-menu-item text="Export Template"></kendo-menu-item>
              <kendo-menu-item text="Import Template"></kendo-menu-item>
              </kendo-menu-item>
              <kendo-menu-item text="Line Items"></kendo-menu-item>
              <kendo-menu-item text="Accounts">
                <kendo-menu-item text="Tax Accounts"></kendo-menu-item>
              <kendo-menu-item text="GL Accounts"></kendo-menu-item>
              <kendo-menu-item text="Import GL Accounts"></kendo-menu-item>
              <kendo-menu-item text="Map GL Accounts"></kendo-menu-item>
              </kendo-menu-item>
              <kendo-menu-item text="Statutory Treatment"></kendo-menu-item>
              <kendo-menu-item text="Management">
                <kendo-menu-item text="Tax Outcomes"></kendo-menu-item>
              <kendo-menu-item text="Keyword Groups"></kendo-menu-item>
              <kendo-menu-item text="Habitats"></kendo-menu-item>
              </kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
        </kendo-appbar-section>

        <kendo-appbar-section>
          <span class="gap"></span>
          <kendo-menu>
            <kendo-menu-item text="Import" cssClass="mycustom">
                <kendo-menu-item text="Trail Balance"></kendo-menu-item>
                <kendo-menu-item text="Transactions"></kendo-menu-item>
            </kendo-menu-item>
          </kendo-menu>
          </kendo-appbar-section>
          <kendo-appbar-section>
            <span class="gap"></span>
            <kendo-menu>
              <kendo-menu-item text="Allocate" cssClass="mycustom">
                  <kendo-menu-item text="Analyse and review"></kendo-menu-item>
                  <kendo-menu-item text="Allocation rules"></kendo-menu-item>
                  <kendo-menu-item text="Percentage allocation rules"></kendo-menu-item>
                  <kendo-menu-item text="Transactions"></kendo-menu-item>
                  <kendo-menu-item text="Offline referral">
                    <kendo-menu-item text="Balance level"></kendo-menu-item>
                <kendo-menu-item text="Breakdown level"></kendo-menu-item>
                <kendo-menu-item text="Collect offline referrals"></kendo-menu-item>
                <kendo-menu-item text="Manage offline referrals"></kendo-menu-item>
                  </kendo-menu-item>
                  <kendo-menu-item text="Offline statement">
                    <kendo-menu-item text="With control total"></kendo-menu-item>
                <kendo-menu-item text="Without control total"></kendo-menu-item>
                <kendo-menu-item text="Check in"></kendo-menu-item>
                <kendo-menu-item text="Check out"></kendo-menu-item>
                <kendo-menu-item text="Manage offline statement"></kendo-menu-item>
                  </kendo-menu-item>
                  <kendo-menu-item text="Journals">
                    <kendo-menu-item text="New Journals"></kendo-menu-item>
                <kendo-menu-item text="Manage journals"></kendo-menu-item>
                  </kendo-menu-item>
                  <kendo-menu-item text="Manage attachments"></kendo-menu-item>
              </kendo-menu-item>
            </kendo-menu>
            </kendo-appbar-section>
            <kendo-appbar-section>
              <span class="gap"></span>
              <kendo-menu>
                <kendo-menu-item text="Export" cssClass="mycustom">
                    <kendo-menu-item text="Analysis Statements"></kendo-menu-item>
                    <kendo-menu-item text="Analysis View"></kendo-menu-item>
                    <kendo-menu-item text="Settings"></kendo-menu-item>
                </kendo-menu-item>
              </kendo-menu>
              </kendo-appbar-section>
              <kendo-appbar-section>
                <span class="gap"></span>
                <kendo-menu>
                  <kendo-menu-item text="Analysis & Reporting" cssClass="mycustom">
                      <kendo-menu-item text="Analysis & Review"></kendo-menu-item>
                  </kendo-menu-item>
                </kendo-menu>
                </kendo-appbar-section>
                <kendo-appbar-section>
                  <span class="gap"></span>
                  <kendo-menu>
                    <kendo-menu-item text="Administration" cssClass="mycustom">
                        <kendo-menu-item text="Preferences"></kendo-menu-item>
                        <kendo-menu-item text="Manage Fiscal Years"></kendo-menu-item>
                        <kendo-menu-item text="Manage Period Aliases"></kendo-menu-item>
                        <kendo-menu-item text="Manage Custom Columns"></kendo-menu-item>
                        <kendo-menu-item text="Transaction Data"></kendo-menu-item>
                        <kendo-menu-item text="Restore Factory Settings"></kendo-menu-item>
                    </kendo-menu-item>
                  </kendo-menu>
                  </kendo-appbar-section>
      
  </kendo-appbar>
    