import { Component, OnInit, Input } from '@angular/core';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import {
  FormBuilder,
  FormGroup,
  RequiredValidator,
  Validators,
} from '@angular/forms';
import {
  DialogRef,
  DialogContentBase,
  ActionsLayout,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newsubanalysis',
  templateUrl: './newsubanalysis.component.html',
  styleUrls: ['./newsubanalysis.component.scss'],
})
export class NewsubanalysisComponent
  extends DialogContentBase
  implements OnInit
{
  @Input() public ASId: any;
  @Input() public parentDetails: any;
  @Input() public editSubAnalysisData: any;
  isEdit: any;
  mandatoryFlag: boolean | any = false;
  newSubAnalysisForm: FormGroup | any;
  BlockNameErrorMessage: string = 'Sub-analysis is required';
  ASDetailsfromDb: any;
  public actionsLayout: ActionsLayout = 'normal';
  LayoutString: any;

  constructor(
    public dialog: DialogRef,
    private router: Router,
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {
    super(dialog);
    this.newSubAnalysisForm = this.fb.group({
      StatementLayoutBlockId: 0,
      ParentStatementLayoutId: 1,
      DisplayOrder: 0,
      BlockName: [''],
      BlockHeaderText: [''],
      BlockHeaderIncludeTotal: false,
      BlockHeaderSuppress: false,
      BlockFooterText: [''],
      BlockFooterIncludeTotal: false,
      BlockFooterSuppress: false,
      CalculateTotalAs: [''],
      HideSubAnalysisInTree: false,
    });
  }

  ngOnInit(): void {
    if (this.editSubAnalysisData) {
      this.isEdit = true;
      if (
        this.editSubAnalysisData.BlockFooterIncludeTotal ||
        this.editSubAnalysisData.BlockHeaderIncludeTotal
      )
        this.mandatoryFlag = true;
      this.newSubAnalysisForm.controls['BlockName'].setValue(
        this.editSubAnalysisData.ChildDescription
      );
      this.newSubAnalysisForm.controls['BlockHeaderText'].setValue(
        this.editSubAnalysisData.BlockHeaderText
      );
      this.newSubAnalysisForm.controls['BlockHeaderIncludeTotal'].setValue(
        this.editSubAnalysisData.BlockHeaderIncludeTotal
      );
      this.newSubAnalysisForm.controls['BlockFooterText'].setValue(
        this.editSubAnalysisData.BlockFooterText
      );
      this.newSubAnalysisForm.controls['BlockFooterIncludeTotal'].setValue(
        this.editSubAnalysisData.BlockFooterIncludeTotal
      );
      this.newSubAnalysisForm.controls['CalculateTotalAs'].setValue(
        this.editSubAnalysisData.CalculateTotalAs
      );
      this.newSubAnalysisForm.controls['HideSubAnalysisInTree'].setValue(
        this.editSubAnalysisData.HideSubAnalysisInTree
      );
    }
  }
  onCheckboxChange(e: any) {
    if (
      this.newSubAnalysisForm.getRawValue().BlockHeaderIncludeTotal == true ||
      this.newSubAnalysisForm.getRawValue().BlockFooterIncludeTotal == true
    ) {
      this.newSubAnalysisForm
        .get('CalculateTotalAs')
        .setValidators([Validators.required]);

      this.mandatoryFlag = true;
    } else {
      this.mandatoryFlag = false;
    }
  }

  cancelClick() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/manageanalysisstatementlayouts']));
  }

  saveNewSubAnalysis() {
    let PayloadArr = [];
    var formObject = this.newSubAnalysisForm.getRawValue();
    let ASobject;
    if (this.isEdit) {
      ASobject = {
        StatementLayoutBlockId: this.editSubAnalysisData.ChildNodeLayoutId,
        ParentStatementLayoutId:
          this.editSubAnalysisData.ParentStatementLayoutId,
        DisplayOrder: this.editSubAnalysisData.DisplayOrder,
        BlockName: formObject.BlockName,
        BlockHeaderText: formObject.BlockHeaderText,
        BlockHeaderIncludeTotal: formObject.BlockHeaderIncludeTotal,
        BlockHeaderSuppress: false,
        BlockFooterText: formObject.BlockFooterText,
        BlockFooterIncludeTotal: formObject.BlockFooterIncludeTotal,
        BlockFooterSuppress: false,
        CalculateTotalAs: formObject.CalculateTotalAs,
        HideSubAnalysisInTree: false,
      };
    } else {
      ASobject = {
        StatementLayoutBlockId: 0,
        ParentStatementLayoutId: this.parentDetails.StatementLayoutId,
        DisplayOrder: 0,
        BlockName: formObject.BlockName,
        BlockHeaderText: formObject.BlockHeaderText,
        BlockHeaderIncludeTotal: formObject.BlockHeaderIncludeTotal,
        BlockHeaderSuppress: false,
        BlockFooterText: formObject.BlockFooterText,
        BlockFooterIncludeTotal: formObject.BlockFooterIncludeTotal,
        BlockFooterSuppress: false,
        CalculateTotalAs: formObject.CalculateTotalAs,
        HideSubAnalysisInTree: false,
      };
    }
    this.globalService
      .saveAnalysisStatement(ASobject)
      .then((res: any) => {
        if (
          res == 'Analysis Statement Block added successfully' 
          
        ) {
          this.toastr.successToastr('Analysis statement block added successfully', 'Success');
          // this.dialog.close();
          this.displayGrid();
          
        } else if (
          res == 'Analysis Statement Block updated successfully'
        ) {
          this.toastr.successToastr('Analysis statement block updated successfully', 'Success');
          // this.dialog.close();
          this.displayGrid();
        }else if (res == 'new sub Analysis name already exists') {
          this.newSubAnalysisForm.controls['BlockName'].markAsTouched();

          this.BlockNameErrorMessage =
            'Please modify Sub-analysis as it is not unique';
          this.newSubAnalysisForm.controls['BlockName'].setErrors({
            incorrect: true,
          });
          return;
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          // this.dialog.close();
          this.displayGrid();
        }
      })
      .catch((err) => {});
  }

  private radioOnchange() {
    var Calc: any = model.define({
      fields: {
        VehicleEndLeading: {
          type: 'string',
          editable: true,
        },
      },
    });
    var model = new Calc();
    model.Subanalysistotal = 'subAnalysistotal';
  }

  displayGrid() {
    // this.dialogService.open({
    //   content: AnalysisstatementgridpopupComponent,
    //   width: '100%',
    //   height:'100%'
    // });
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/manageanalysisstatementlayouts']));
    // this.router.navigate(['/manageanalysisstatementlayouts']);
  }
}
