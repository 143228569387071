import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root',
})
export class StatutoryTreatmentService {
  private params: any;
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private mockApiUrl: any;
  private apiHost: string;
  tokenValue: any;

  constructor(
    public http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    //  this.apiPort = environment.apiPort;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getAllStatutoryTreatment: '/StatutoryTreatment/GetAllAccountTypes',
      deleteStatutoryTreatment: '/StatutoryTreatment/DeleteAccountType',
      insertStatutoryTreatment: '/StatutoryTreatment/InsertAccountType',
      updateStatutoryTreatment: '/StatutoryTreatment/UpdateAccountType',
    };
    //mock Json url to check in local
    this.mockApiUrl = {};
    // this.prepareRequests();
  }

  /*prepareRequests() {
    const headersObj = new Headers();
    console.log(headersObj)
    this.getArgs = {
      headers: headersObj,
      method: 'GET',
      withCredentials: false
    };
    this.postArgs = {
      headers: headersObj,
      method: 'POST',
      withCredentials: false
    };
    this.putArgs = {
      headers: headersObj,
      method: 'PUT',
      withCredentials: false
    };
    this.deleteArgs = {
      headers: headersObj,
      method: 'DELETE',
      withCredentials: false
    };
    this.formDataPostArgs = {
      headers: headersObj,
      method: 'POST',
      withCredentials: false
    };
  }*/

  getAllStatutoryTreatment(): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getAllStatutoryTreatment;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }

  deleteStatutoryTreatment(id: any) {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('clientdbName', this.selectedClient.DBName)
      .set('id', id);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteStatutoryTreatment;
    // console.log(this.apiHost);
    return this.http.get(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  editStatutoryTreatment(data: any) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.baseUrl = environment.baseUrl;
    //  this.apiPort = environment.apiPort;
    this.apifolder = environment.apifolder;
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.updateStatutoryTreatment;

    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );

    return this.http.post(this.apiHost, data, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  insertStatutoryTreatment(data: any) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.baseUrl = environment.baseUrl;
    //  this.apiPort = environment.apiPort;
    this.apifolder = environment.apifolder;
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.insertStatutoryTreatment;

    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );

    return this.http.post(this.apiHost, data, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
}
