<!-- Header -->
<div class="header" style="font-size: 20px">Manage tax accounts</div>
<div id="mainContent" class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <!-- Sub Header -->
      <div class="subHeader" style="font-size: 18px">Business type</div>
      <!-- Select business type -->
      <div>
        <form class="form-group" style="margin-bottom: 10px">
          <kendo-combobox
            #businessTypeId
            [(ngModel)]="businessTypeValue"
            [data]="businesstypeList"
            [filterable]="true"
            textField="Description"
            valueField="BusinessTypeId"
            (valueChange)="handleBTchange($event)"
            (filterChange)="handleFilterBusinessTypeId($event)"
          >
          </kendo-combobox>
        </form>
      </div>
      <!-- search bar and action -->
      <div style="display: flex; padding-bottom: 10px">
        <ap-search
          [placeholder]="'Search here'"
          [searchType]="'secondary'"
          style="width: 100%; margin-right: 24px; padding: 0px !important"
          [disabled]="false"
          (onSearch)="onFilter($event)"
        ></ap-search>
        <ap-button
          [icon]="'plus-outline'"
          [compact]="true"
          [add]="true"
          (onClick)="openNewTaxAccountsPopup()"
          kendoTooltip
          title="New"
        ></ap-button>
      </div>
      <!-- Sub Header -->
      <div class="subHeader" style="font-size: 18px">Tax account</div>
      <kendo-grid
        [data]="taxAccounts"
        scrollable="virtual"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        [resizable]="true"
        class="gridHeight"
        [reorderable]="true"
      >
        <kendo-grid-command-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'center'
          }"
          field="IsActive"
          title="Actions"
          [width]="120"
        >
          <ng-template kendoGridCellTemplate let-data>
            <button
              kendoButton
              name="edit"
              kendoTooltip
              title="Edit"
              (click)="openEditTaxAccountsPopup(data)"
              style="margin-right: 5px"
            >
              <span class="Appkit4-icon icon-edit-outline"></span>
            </button>
            <button
              kendoButton
              name="delete"
              kendoTooltip
              title="Delete"
              (click)="openDeleteTaxAccountsPopup(data)"
            >
              <span class="Appkit4-icon icon-delete-outline"></span>
            </button>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column
          field="TaxAccountCode"
          title="Tax account"
          [width]="150"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="shortdescription"
          title="Tax account description"
          [width]="200"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="AccountingAreaDescription"
          title="Line item"
          [width]="220"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="AccountTypeDescription"
          title="Statutory treatment"
          [width]="120"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="AccountClassDescription"
          title="Account class"
          [width]="130"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="ParentTaxAccountCode"
          title="Parent tax a/c"
          [width]="220"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
        <kendo-grid-column
          field="TaxOutcomeDescription"
          title="Tax outcome"
          [width]="120"
          [headerStyle]="{
            'font-weight': 'bold',
            'text-align': 'center',
            color: '#d04a02'
          }"
        ></kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
