import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MyGlobalObject } from '../../myglobalobject';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getStatementGroupsByBusTypeAndLegalEntityId:
        '/StatementDetails/GetStatementGroupsByBusTypeAndLegalEntityId',
      getTaxEngineFormat: '/Export/TaxEngineFormat',
      exportAnalysisStatement: '/Export/ExportAnalysisStatement',
    };
  }
  getStatementGroupsByBusTypeAndLegalEntityId(
    businessTypeId: number,
    legalEntityID: number
  ): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('businessTypeId', businessTypeId)
      .set('legalEntityID', legalEntityID);
    this.apiHost =
      this.baseUrl +
      this.apifolder +
      this.apiUrl.getStatementGroupsByBusTypeAndLegalEntityId;
    return this.http.get<any>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  getTaxEngineFormat(): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getTaxEngineFormat;
    return this.http.get<any>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  exportAnalysisStatement(
    CurrentYear: any,
    BT: number,
    LE: number,
    AP: number,
    engineFormat: number,
    taxOutcome: boolean,
    exportTransactions: boolean
  ): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('engineFormat', engineFormat)
      .set('CurrentYear', CurrentYear)
      .set('BT', BT)
      .set('LE', LE)
      .set('AP', AP)
      .set('taxOutcome', taxOutcome)
      .set('exportTransactions', exportTransactions);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.exportAnalysisStatement;

    return this.http.post<any>(this.apiHost, headers, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
