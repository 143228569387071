<div *ngIf="isLoading">
  <kendo-chunkprogressbar [chunkCount]="10" [min]="-10" [max]="10" [indeterminate]="true" [value]="30"
    style="width: 100%"></kendo-chunkprogressbar>
</div>
<div class="header">Tax Account Report</div>
<div class="container-fluid">
  <!-- Add Business Type Form -->
  <div class="row">
    <div class="col-md-8 col-xs-12">
      <form class="k-form" [formGroup]="TaxAccountReportForm">
        <kendo-formfield>
          <kendo-label style="font-weight: bold" [for]="fiscalYears" text="Fiscal year:">
            <span style="margin-left: 10px;">{{ this.fiscalYearID }}</span>
            <!-- &nbsp;&nbsp;&nbsp;<kendo-label>{{
              this.fiscalYearID }} -->
            <!-- </kendo-label> -->
          </kendo-label>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="businessType" text="Business type *"></kendo-label>
          <kendo-combobox #businessType [data]="businessTypes" formControlName="businessType" textField="Description"
            valueField="BusinessTypeId" placeholder="Select business type"
            (valueChange)="handleBusinessTypeChange($event)" [(ngModel)]="selectedbusinessType" style="width: 100%">
          </kendo-combobox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="legalEntity" text="Legal entity *"></kendo-label>
          <kendo-combobox #legalEntity formControlName="legalEntity" [data]="legalEntities" style="width: 100%"
            textField="Description" valueField="ID" placeholder="Select legal entity" [(ngModel)]="selectedLegalEntity"
            (valueChange)="handleLegalEntityChange($event)">
          </kendo-combobox>
        </kendo-formfield>
        <kendo-formfield>
          <ap-checkbox (onChange)="complexCheckBox($event)" style="margin-left: 1px">Exclude data with no associated
            transactions
          </ap-checkbox>
        </kendo-formfield>
      </form>
      <!-- Click to Actions -->
      <div class="clickToActions btndiv" style="padding: 20px 0px">
        <ap-button [btnType]="'secondary'" [label]="'Close'" (click)="close()" style="margin-left: 7px"
          class="cancelButton okButton"></ap-button>
        <ap-button [btnType]="'primary'" (click)="run()" [label]="'Run'" class="okButton"
          [disabled]="!(selectedbusinessType && selectedLegalEntity)"></ap-button>
      </div>
    </div>
    <div class="col-md-4 col-xs-12"></div>
  </div>
</div>