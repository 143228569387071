import { Component, OnInit, Input } from '@angular/core';
import {
  DialogContentBase,
  ActionsLayout,
  DialogAction,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from '@progress/kendo-angular-dialog';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { AnalysisReviewService } from 'src/app/common/services/analysisReview/analysis-review.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-analysis-delete-line',
  templateUrl: './analysis-delete-line.component.html',
  styleUrls: ['./analysis-delete-line.component.scss'],
})
export class AnalysisDeleteLineComponent implements OnInit {
  @Input() public BusinessType: any;
  @Input() public delLIdata: any;
  dialogTitle: any = 'Delete Line items(s)';
  confirmText: any;
  warnondelete: boolean = false;
  public actionsLayout: ActionsLayout = 'normal';
  delLIDetails: any;
  deleteLineItemId: any;
  responceData: any;
  BusinessTypeId: any;
  BusinessDesc: any;

  constructor(
    public dialog: DialogRef,
    public toastr: ToastrManager,
    private analysis: AnalysisReviewService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.BusinessTypeId = this.BusinessType.BusinessTypeId;
    this.BusinessDesc = this.BusinessType.Description;
  }

  cancelClick() {
    this.dialog.close();
  }

  deleteLineItem() {
    this.analysis
      .deleteLineItem(this.delLIdata)
      .then((res: any) => {
        this.responceData = res;
        if (res == 'Line Item is deleted successfully') {
          this.toastr.successToastr('Line item is deleted successfully', 'Success');
          this.displayGrid();
        } else if (res != '' && res != 'Line Item is deleted successfully') {
          //this.dialog.close();
          this.warnondelete = true;
          this.responceData = res;
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.displayGrid();
        }
      })
      .catch((err: any) => {});
  }
  displayGrid() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }
}
