import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { CookieService } from 'ngx-cookie-service';
import { HabitatService } from '../common/services/habitatservice/habitat.service';
import { MyGlobalObject } from '../common/myglobalobject';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { DeletehabitatsComponent } from './deletehabitats/deletehabitats.component';
import { ManagehabitatsComponent } from './managehabitats/managehabitats.component';
import { style } from '@angular/animations';
import { ReassignHabitatsComponent } from './reassign-habitats/reassign-habitats.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-habitats',
  templateUrl: './habitats.component.html',
  styleUrls: ['./habitats.component.scss'],
})
export class HabitatsComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective | any;
  public actionsLayout: ActionsLayout = 'normal';
  public habitats: any = [];
  public gridData: any = [];
  public habitat: any = 'Habitats';
  selectedClient: string | any;
  public mySelection: any = [];
  constructor(
    private router: Router,
    private cookieService: CookieService,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private fb: FormBuilder,

    private habitatService: HabitatService
  ) {
    this.loadgridData();
  }

  ngOnInit(): void {
    this.getAllHabitats();
    this.selectedClient = localStorage.getItem('SELECTEDCLIENT');
  }
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.habitats = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }
  getAllHabitats() {
    this.habitatService
      .fetchAllHabitats()
      .then((res: any[]) => {
        this.habitats = res;
        this.gridData = res;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public loadgridData(): void {
    this.getAllHabitats();
  }
  public selectedCallback = (args: any) => args.dataItem;

  openDeleteHabitatPopup(data: any): void {
    const dialogRef = this.dialogService.open({
      content: DeletehabitatsComponent,
      width: 500,
      height: 'auto',
    });
    const delHabitatDetails = dialogRef.content
      .instance as DeletehabitatsComponent;
    dialogRef.result.subscribe((r: any) => {
      this.getAllHabitats();
    });
    delHabitatDetails.delHabitatdata = data;
  }

  public onFilter(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.habitats = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'Name',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Name',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }
  actionButton(action: any, data?: any) {
    if (action == 'edit' || action == 'new') {
      this.router.navigate(['./manageHabitats'], {
        state: {
          data: data,
          action: action,
        },
      });
    } else if (action == 'delete') {
      const dialogRef = this.dialogService.open({
        content: DeletehabitatsComponent,
        width: 500,
        height: 'auto',
      });
      const deleteHabitatDetails = dialogRef.content
        .instance as DeletehabitatsComponent;
      dialogRef.result.subscribe((r: any) => {});
      deleteHabitatDetails.delHabitatdata = this.mySelection[0];
    } else if (action == 'reassign') {
      const dialogRef = this.dialogService.open({
        content: ReassignHabitatsComponent,
        width: 500,
        height: 'auto',
      });
      const deleteHabitatDetails = dialogRef.content
        .instance as ReassignHabitatsComponent;
      dialogRef.result.subscribe((r: any) => {
        // this.getAllTaxOutcomes();
      });

      deleteHabitatDetails.reassignData = this.mySelection[0];
    }
  }
}
