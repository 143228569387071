import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { NewtaxaccountComponent } from 'src/app/taxaccounts/newtaxaccount/newtaxaccount.component';
import { LineitemsComponent } from '../lineitems.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-copylineitem',
  templateUrl: './copylineitem.component.html',
  styleUrls: ['./copylineitem.component.scss'],
})
export class CopylineitemComponent implements OnInit {
  @Input() public BusinessType: any;
  @Input() public copyLineItemId: any;
  //copyLineItemId: any;
  copyLineItemForm: FormGroup;
  selectedClient: string | any;
  requireFurtherAnalysis: boolean = false;
  BusinessTypeId: any;
  BusinessDesc: any;
  AccountClassDescriptionList: any;
  AccountClassDescriptionListDB: any;
  AccountingAreaDescriptionList: any;
  AccountingAreaDescriptionListDB: any;

  isCopy: boolean = false;
  isDuplicateEntry: boolean = false;
  LIDetailsfromDb: any;
  copiedDescription: any;
  descriptionErrorMessage: any = 'Description is required';
  public actionsLayout: ActionsLayout = 'normal';
  accountclassErrorMessage: any = 'Account Class is required';
  AccountClass: any;
  AccountingArea: any;
  AccountingAreaVal: any;
  CreateTaxAccount: any;
  ParentLineItem: any;

  constructor(
    //public dialog: DialogRef,
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private dialogService: DialogService,
    private globalService: GlobalService
  ) {
    //super(dialog);
    this.copyLineItemForm = this.fb.group({
      AccountingAreaID: [null],
      Description: [''],
      AccountClass: [],
      ParentLineItem: null,
      CreateTaxAccount: true,
    });
  }

  ngOnInit(): void {
    //    this.getRefData();
    this.BusinessType = history.state.BusinessType;
    this.copyLineItemId = history.state.copyLineItemId;
    this.getLineItemById(this.copyLineItemId);
  }

  async getLineItemById(LineItemId: any) {
    await this.globalService
      .getLineItemById(LineItemId)
      .then((res) => {
        if (res) {
          this.LIDetailsfromDb = JSON.parse(res);
          this.copiedDescription = this.LIDetailsfromDb.Description;
          this.isDuplicateEntry = true;
          this.BusinessTypeId = this.LIDetailsfromDb.TaxChartOfAccounts;
          this.copyLineItemForm.controls['Description'].setValue(
            this.LIDetailsfromDb.Description
          );
          this.copyLineItemForm.controls['ParentLineItem'].setValue(
            this.LIDetailsfromDb.AccountingArea
          );
          this.copyLineItemForm.controls['AccountClass'].setValue(
            this.LIDetailsfromDb.AccountClass
          );
          this.copyLineItemForm.controls['AccountingArea'].setValue(
            this.LIDetailsfromDb.AccountingArea
          );
          this.copyLineItemForm.controls['AmountDisplayIndicator'].setValue(
            this.LIDetailsfromDb.AmountDisplayIndicator
          );
        }
      })
      .catch((err) => {});

    this.getRefData();
  }
  handleParentLineItemChange(value: any) {
    if (value != undefined) {
      this.copyLineItemForm.controls['CreateTaxAccount'].setValue(true);
      this.copyLineItemForm.controls['CreateTaxAccount'].disable();
    } else {
      this.copyLineItemForm.controls['CreateTaxAccount'].enable();
    }
  }
  handleAccountClassChange(value: any) {
    if (value.Description == 'Complex') {
      this.copyLineItemForm.controls['ParentLineItem'].disable();
      this.copyLineItemForm.controls['CreateTaxAccount'].setValue(true);
      this.copyLineItemForm.controls['CreateTaxAccount'].disable();
      this.requireFurtherAnalysis = true;
    } else {
      this.copyLineItemForm.controls['ParentLineItem'].enable();
      this.copyLineItemForm.controls['CreateTaxAccount'].enable();
      this.requireFurtherAnalysis = false;
    }
  }

  handleFilterParentLineItem(value: any) {
    this.AccountingAreaDescriptionList =
      this.AccountingAreaDescriptionListDB.filter(
        (s: any) =>
          s.Description.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
  }
  getRefData() {
    this.globalService
      .getAllAccountClass()
      .then((res) => {
        this.AccountClassDescriptionListDB = res;
        this.AccountClassDescriptionList =
          this.AccountClassDescriptionListDB.slice();
      })
      .catch((err) => {});

    this.globalService
      .getAllLineItemsByBusinessType(this.BusinessTypeId)
      .then((res) => {
        this.AccountingAreaDescriptionListDB = res;
        this.AccountingAreaDescriptionList =
          this.AccountingAreaDescriptionListDB.slice();
      })
      .catch((err) => {});
  }

  cancelClick() {
    this.router.navigate(['/lineItems'], {
      state: {
        BusinessType: this.BusinessType,
      },
    });
    //this.dialog.close();
    // const dialogRef=this.dialogService.open({
    //   content: LineitemsComponent,
    //   width: 1200,
    //   height: 600
    // });
    // const editLineItem = dialogRef.content.instance as LineitemsComponent;
    // var defaultBT ={"BusinessTypeId":this.BusinessTypeId,"Description":this.BusinessDesc,"Library":false,"CountryName":"UNITED KINGDOM","IndustryName":null,"HelpId":"","CountryId":231,"IndustryId":null};
    // dialogRef.result.subscribe((r: any) => {

    // });
    // editLineItem.isLoadId = true;
    // editLineItem.lineItemsGridForm = new FormGroup({
    //   businessTypeId: new FormControl(defaultBT)
    // });
  }

  saveCopyLineItem() {
    var formObject = this.copyLineItemForm.getRawValue();
    let AccountingAreaVal, AccountingAreaIdVal;
    if (formObject.AccountClass.ID == 3 || formObject.ParentLineItem == null) {
      AccountingAreaVal = null;
      AccountingAreaIdVal = '';
    } else {
      AccountingAreaVal = formObject.ParentLineItem.LineID;
      AccountingAreaIdVal = formObject.ParentLineItem.AccountingAreaID;
    }
    if (this.isCopy && formObject.Description == this.copiedDescription) {
      this.copyLineItemForm.markAllAsTouched();
      this.descriptionErrorMessage =
        'Please modify Description as it is not unique';
      this.copyLineItemForm.controls['Description'].setErrors({
        incorrect: true,
      });
      return;
    }
    this.isDuplicateEntry = false;

    var lineId = null;
    var AccountClass = null;

    this.requireFurtherAnalysis = true;
    if (
      formObject.ParentLineItem != null &&
      formObject.ParentLineItem != undefined
    ) {
      lineId = formObject.ParentLineItem.LineID;
      AccountClass = this.LIDetailsfromDb.AccountClass;
      this.requireFurtherAnalysis = false;
    }
    let LineItemObject = {
      ID: 0,
      AccountingAreaID: formObject.AccountingAreaID,
      Description: formObject.Description,
      RequireFurtherAnalysis: this.requireFurtherAnalysis,
      AccountDerivation: null,
      AmountDisplayIndicator: true,
      TaxChartOfAccounts: this.BusinessTypeId,
      AccountClass: this.LIDetailsfromDb.AccountClass,
      AccountAreaGroup: null,
      StatementLevel: null,
      AccountingArea: lineId,
      HelpId: '',
      ParentLineItem: null,
    };
    this.globalService
      .insertLineItem(LineItemObject)
      .then((res) => {
        if (res.Item2 == 'Line Item added successfully') {
          this.toastr.successToastr('Line item added successfully', 'Success');
          if (this.copyLineItemForm.getRawValue().CreateTaxAccount == true) {
            //this.dialog.close();
            this.router.navigate(['/newTaxAccount'], {
              state: {
                gridBusinessType: this.BusinessType,
                isCreateFromLI: true,
                LICreateTaxData: res.Item1,
              },
            });
            // const dialogRef = this.dialogService.open({
            //   content: NewtaxaccountComponent,
            //   width: 950,
            //   height:595
            // });
            // const newTaxAccount = dialogRef.content.instance as NewtaxaccountComponent;
            // dialogRef.result.subscribe((r: any) => {
            // });
            // console.log(this.BusinessType)
            // console.log(res.Item1)
            // newTaxAccount.gridBusinessType = this.BusinessType;
            // newTaxAccount.isCreateFromLI=true;
            // newTaxAccount.LICreateTaxData=res.Item1;
          } else {
            this.displayGrid();
          }
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.displayGrid();
        }
      })
      .catch((err) => {});
  }

  displayGrid() {
    this.router.navigate(['/lineItems'], {
      state: {
        BusinessType: this.BusinessType,
      },
    });
    //this.dialog.close();
    // const dialogRef = this.dialogService.open({
    //   content: LineitemsComponent,
    //   width: 1200,
    //   height:600
    // });
    // const editLineItem = dialogRef.content.instance as LineitemsComponent;
    // var defaultBT ={"BusinessTypeId":this.BusinessTypeId,"Description":this.BusinessDesc,"Library":false,"CountryName":"UNITED KINGDOM","IndustryName":null,"HelpId":"","CountryId":231,"IndustryId":null};
    // dialogRef.result.subscribe((r: any) => {

    // });
    // editLineItem.isLoadId = true;
    // editLineItem.lineItemsGridForm = new FormGroup({
    //   businessTypeId: new FormControl(defaultBT)
    // });
  }
}
