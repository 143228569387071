<kendo-dialog [width]="dialogWidth" [height]="dialogHeight">
  <kendo-dialog-titlebar (close)="cancelClick()">
    <div
      *ngIf="hasAccountMapping && (hasGLmapping || hasTAmapping)"
      style="font-size: 18px; line-height: 1.3em"
    >
      Cannot delete tax account(s)
    </div>
    <div
      *ngIf="
        hasAccountMapping &&
        !(hasGLmapping || hasTAmapping || isNoMapping || hasAllocation)
      "
      style="font-size: 18px; line-height: 1.3em"
    >
      Cannot delete tax account(s)
    </div>
    <div
      *ngIf="isNoMapping || hasAllocation"
      style="font-size: 18px; line-height: 1.3em"
    >
      Delete tax account(s)
    </div>
  </kendo-dialog-titlebar>
  <div style="position: relative; width: 100%" *ngIf="isNoMapping">
    <div style="float: left; width: 5%">
      <p>
        <span
          class="Appkit4-icon icon-circle-warning-outline"
          style="font-size: 24px; color: #d04a02"
        ></span>
      </p>
    </div>
    <div style="float: right; width: 95%">
      <p>
        Note:All GL account mappings to the selected tax accounts will be
        deleted and the tax accounts will be removed from any habitats they
        appear in.
      </p>
      <p>Are you sure you want to delete the tax account(s)?</p>
    </div>
  </div>
  <div class="row" style="background-color: #f2f2f2">
    <div class="col-md-12" *ngIf="hasAccountMapping && !hasAllocation">
      <div style="float: left; width: 5%">
        <p>
          <span
            class="Appkit4-icon icon-circle-warning-outline"
            style="font-size: 24px; color: #d04a02"
          ></span>
        </p>
      </div>
      <div style="float: right; width: 95%">
        <p>
          The following Tax account(s) could not be deleted as there is data
          associated with them:
        </p>
      </div>
      <div>
        <p>Tax account:{{ taxAccountRowData.shortdescription }}</p>
      </div>
      <div
        *ngIf="hasGLmapping || hasTAmapping"
        style="width: 100%; background-color: white"
        class="listItems"
      >
        <p *ngIf="hasGLmapping">
          &nbsp; &nbsp;The tax account is mapped to the following GL accounts
          with associated transactions
        </p>
        <ul *ngIf="hasGLmapping">
          <li *ngFor="let item of GLMappingList">{{ item }}</li>
        </ul>
        <p *ngIf="hasTAmapping">
          &nbsp;The tax account is linked to the following child tax accounts.
        </p>
        <ul *ngIf="hasTAmapping">
          <li *ngFor="let item of TAmappingList">{{ item }}</li>
        </ul>
      </div>
      <br />
      <p>
        To delete you must first delete all data associated with the tax
        account(s)
      </p>
    </div>
    <div class="col-md-12" *ngIf="hasAllocation">
      <b>Delete mappings and habitats</b>
      <div class="container2">
        <span
          >Note:All GL account mappings to the selected tax account will be
          deleted and the tax account will be removed from any habitats they
          appear in.</span
        >
      </div>
      <b>Choose tax account</b>
      <div class="container2">
        <span
          >The tax account listed below are associated with allocations rules.
          Select tax account to manage the allocation rules.</span
        >
      </div>
      <div
        id="mainContent"
        class="container-fluid"
        style="
          overflow: auto;
          background-color: white;
          border: 1px solid rgb(209, 209, 209);
        "
      >
        <div class="container4">
          <b>{{ taxAccountRowData.shortdescription }}</b>
          <br />
          <form>
            <ap-checkbox [(ngModel)]="deleteTaxAccCheckbox" [disabled]="true">
              <span>{{ taxAccountRowData.shortdescription }}</span>
            </ap-checkbox>
          </form>
          <br />
        </div>
      </div>
      <br />
      <b>Manage allocation rules</b>
      <div class="container2">
        <span
          >For each allocation rule, select to delete it or choose a new
          destination tax account.</span
        >
      </div>
      <div style="border: 1px solid rgb(209, 209, 209)">
        <kendo-grid
          [data]="allocationMappingList"
          [kendoGridSelectBy]="selectedCallback"
          [(selectedKeys)]="mySelectionSecondGrid"
          [height]="300"
          [resizable]="true"
          [selectable]="true"
        >
          <kendo-grid-column field="delete" title="Delete" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
              <input
                type="checkbox"
                kendoCheckBox
                (click)="toggle(dataItem, 'delete')"
                [checked]="dataItem.delete"
              />
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="Name"
            title="Allocation rule"
            [width]="220"
            [headerStyle]="{ 'background-color': '#f2f2f2' }"
          ></kendo-grid-column>
          <kendo-grid-column title="New destination account" [width]="250">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-combobox
                [(ngModel)]="dataItem.destinationAccount"
                (valueChange)="destinationAccChange(dataItem, $event)"
                textField="shortdescription"
                valueField="TaxAccountCodeId"
                [data]="dropDownData"
              >
              </kendo-combobox>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="hasExported"
            title="Has exported"
            [width]="120"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <input
                type="checkbox"
                kendoCheckBox
                (click)="toggle(dataItem, 'hasExported')"
                [checked]="dataItem.hasExported"
              />
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
  <!-- Click to Actions isNoMapping -->
  <div
    *ngIf="isNoMapping"
    class="clickToActions"
    style="padding: 10px 0px 0px 0px; float: right"
  >
    <ap-button
      [btnType]="'secondary'"
      [label]="'No'"
      (click)="cancelClick()"
      style="width: 80px; margin-left: 5px"
    ></ap-button>
    <ap-button
      [btnType]="'primary'"
      [label]="'Yes'"
      themeColor="primary"
      (click)="deleteTaxaccount()"
      style="margin-right: 6px; width: 80px"
    ></ap-button>
  </div>
  <!-- Click to Actions if hasGLMapping -->
  <div
    *ngIf="
      hasAccountMapping &&
      !(hasGLmapping || hasTAmapping || isNoMapping || hasAllocation)
    "
    class="clickToActions"
    style="padding: 10px 0px 0px 0px"
  >
    <ap-button
      [btnType]="'primary'"
      [label]="'OK'"
      (click)="cancelClick()"
      style="width: 80px"
    ></ap-button>
  </div>
  <div
    *ngIf="hasGLmapping || hasTAmapping"
    class="clickToActions"
    style="padding: 10px 0px 0px 0px"
  >
    <ap-button
      [btnType]="'primary'"
      [label]="'OK'"
      (click)="cancelClick()"
      style="width: 80px"
    ></ap-button>
  </div>
  <!-- Click to Actions hasAllocation -->
  <div
    *ngIf="hasAllocation"
    class="clickToActions"
    style="padding: 10px 0px 0px 0px"
  >
    <ap-button
      [btnType]="'secondary'"
      [label]="'No'"
      (click)="cancelClick()"
      style="margin-left: 5px; width: 80px"
    ></ap-button>
    <ap-button
      [btnType]="'primary'"
      [label]="'Yes'"
      themeColor="primary"
      [disabled]="!deleteTaxAccCheckbox"
      (click)="deleteTaxaccount()"
      style="margin-right: 6px; width: 80px"
    ></ap-button>
  </div>
</kendo-dialog>
