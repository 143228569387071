import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MyGlobalObject } from '../../myglobalobject';

@Injectable({
  providedIn: 'root',
})
export class LineitemmoveService {
  private params: any;
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private mockApiUrl: any;
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    this.apiUrl = {
      getLineItemOrder: '/AnalysisTreeview/GetLineItemOrder',
      lineItemMoveUp: '/AnalysisTreeview/LineItemMoveUp',
      lineItemMoveDown: '/AnalysisTreeview/LineItemMoveDown',
      lineItemMoveTop: '/AnalysisTreeview/LineItemMoveTop',
      lineItemMoveBottom: '/AnalysisTreeview/LineItemMoveBottom',
      dragDropCreateJournals: '/AnalysisTreeView/CreateJournals',
    };
  }
  getLineItemOrderDetails(
    statementID: number,
    lineItemID: number
  ): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementID', statementID)
      .set('lineItemID', lineItemID);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.getLineItemOrder;
    return this.http.get<any>(this.apiHost, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
    });
  }
  moveLineItemTop(statementId: number, lineItemId: number): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementID', statementId)
      .set('lineItemId', lineItemId);

    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.lineItemMoveTop;
    return this.http.post(this.apiHost, statementId, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  moveLineItemBottom(statementId: number, lineItemId: number): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementID', statementId)
      .set('lineItemId', lineItemId);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.lineItemMoveBottom;
    return this.http.post(this.apiHost, statementId, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  moveLineItemUp(statementId: number, lineItemId: number): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementID', statementId)
      .set('lineItemId', lineItemId);

    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.lineItemMoveUp;
    return this.http.post(this.apiHost, statementId, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  moveLineItemDown(statementId: number, lineItemId: number): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('statementID', statementId)
      .set('lineItemId', lineItemId);

    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.lineItemMoveDown;
    return this.http.post(this.apiHost, statementId, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
  moveTransactionsDragDrop(
    lineiTemId: number,
    transactionIdList: []
  ): Observable<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('destinationLineItemId', lineiTemId);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.dragDropCreateJournals;
    return this.http.post(this.apiHost, transactionIdList, {
      headers: headersObj,
      withCredentials: false,
      params: httpParams,
      responseType: 'text',
    });
  }
}
