<div class="header">New expression rules</div>

<div id="mainContent" class="container-fluid">
  <div class="row">
    <!-- <div class="col-md-12 col-xs-12"></div> -->
    <div class="col-md-12 col-xs-12">
      <!-- search bar and action -->

      <ap-button
        [btnType]="'primary'"
        themeColor="primary"
        class="ap-button ap-button-primary"
        kendoTooltip
        title="Add keyword"
        style="float: right"
        (onClick)="AddKeyWordGroup()"
        >Add keyword</ap-button
      >
    </div>
  </div>
</div>

<div class="container-fluid" style="padding: 0px">
  <div class="row" style="display: flex; margin: 0px 15px 10px 15px">
    <div class="row" style="display: flex; padding: 0px">
      <div style="width: 60%">
        <mat-card-title style="padding: 0px"
          ><b> Create expression </b>
          <!-- <span
            class="Appkit4-icon icon-view-outline"
            style="cursor: pointer"
          ></span> -->
          <button
            style="background-color: white; padding-left: 0; min-width: 0px"
            kendoTooltip
            (click)="eyeIconClick()"
            title="Custom column details"
          >
            <span class="Appkit4-icon icon-view-outline"></span>
          </button>
        </mat-card-title>
      </div>
      <div style="width: 38%; margin-left: 25px">
        <mat-card-title style="padding: 0px"
          ><b> View expression </b></mat-card-title
        >
      </div>
    </div>

    <div
      style="width: 60%; border: 1px solid #d9d9d9; padding: 0px 12px 12px 12px"
    >
      <!-- <br /> -->
      <div>
        <form class="k-form">
          <kendo-formfield>
            <kendo-label text="Enter expression name*"></kendo-label>
            <input
              [(ngModel)]="expressionName"
              kendoTextBox
              #txtName
              required
            />
            <kendo-formerror>{{ descriptionErrorMessage }}</kendo-formerror>
          </kendo-formfield>
          <!-- <kendo-formfield>
          <kendo-label [for]="txtName" text="Name*"></kendo-label>
          <input  [(ngModel)]="expressionName" kendoTextBox #txtName required/>
          
          <kendo-formerror>{{descriptionErrorMessage}}</kendo-formerror>
          
      </kendo-formfield> -->
          <!-- <ap-field
        
          (onKeydown)="onInputchange($event)"
          [error]="isinputerror"
        >
          <input appkit-field [(ngModel)]="expressionName" />
        </ap-field> -->
        </form>
      </div>
      <br />
      <h5 *ngIf="!isDisplay">Loading expression data..</h5>
      <div *ngIf="!isDisplay">
        <kendo-chunkprogressbar
          [chunkCount]="10"
          [min]="-10"
          [max]="10"
          [indeterminate]="true"
          [value]="30"
          style="width: 100%"
        ></kendo-chunkprogressbar>
      </div>
      <query-builder
        class="container-fluid"
        *ngIf="isDisplay"
        [(ngModel)]="query"
        [config]="config"
        style="padding: 0px"
        (ngModelChange)="onQueryBuilderChange($event)"
      >
        <!-- <ng-container *queryField="let rule; let fields=fields; let onChange=onChange; let getFields = getFields">
          
            <select class="input-control" [(ngModel)]="rule.field" (ngModelChange)="onChange($event, rule)" [ngModelOptions]="{standalone: true}">
              <option *ngFor="let field of getFields(rule.entity)" [value]="field.value">
                {{ field.name }}
              </option>
            </select>
          
    
        </ng-container> -->

        <ng-container
          *queryButtonGroup="
            let ruleset;
            let addRule = addRule;
            let addRuleSet = addRuleSet;
            let removeRuleSet = removeRuleSet;
            let getDisabledState = getDisabledState
          "
        >
          <ap-button
            type="button"
            [btnType]="'secondary'"
            (click)="addRule()"
            [disabled]="getDisabledState()"
            class="two"
          >
            + Add filter</ap-button
          >&nbsp;&nbsp;
          <ap-button
            type="button"
            [btnType]="'secondary'"
            class="one"
            (click)="addRuleSet()"
            [disabled]="getDisabledState()"
          >
            + Add group
          </ap-button>
          <ap-button
            type="button"
            [btnType]="'secondary'"
            *ngIf="removeRuleSet"
            (click)="removeRuleSet()"
            class="two"
          >
            - Remove group
          </ap-button>
        </ng-container>

        <ng-container
          *queryOperator="
            let rule;
            let operators = operators;
            let onChange = onChange
          "
        >
          <select
            class="input-control"
            [(ngModel)]="rule.operator"
            (ngModelChange)="onChange(rule)"
            [ngModelOptions]="{ standalone: true }"
            (change)="onChangeOperator(rule)"
          >
            <option *ngFor="let value of operators" [value]="value">
              {{ value.split("~")[0] }}
            </option>
          </select>
        </ng-container>

        <!-- <ng-container  *ngIf="!isempty > -->
        <ng-container *queryInput="let rule; type: 'number'; let field = field">
          <ng-container
            *ngIf="
              !(
                rule.operator.split('~')[0] === 'Is Empty' ||
                rule.operator.split('~')[0] === 'Is Not Empty'
              )
            "
          >
            <!-- {{rule | json}} -->
            <div #group1 id="group1">
              <br />
              <input
                type="number"
                class="input-control"
                [(ngModel)]="rule.value"
                [placeholder]="'Enter value'"
              />
              <br /><br />
              <div style="width: fit-content">
                <ap-button
                  [btnType]="'primary'"
                  themeColor="primary"
                  (click)="toggleChange(group1, group2, rule)"
                  style="font-weight: 500"
                >
                  Select keyword group
                </ap-button>
              </div>
            </div>
            <div #group2 style="display: none">
              <br />
              <select
                name="demo"
                class="input-control"
                id="#"
                [(ngModel)]="rule.value"
              >
                <option value="" disabled selected hidden>
                  Select keyword group
                </option>

                <option
                  *ngFor="let obj of keywordgroup"
                  [ngValue]="obj.id + '~' + obj.name"
                >
                  {{ obj.name }}
                </option>
              </select>
              <br /><br />
              <div style="width: fit-content">
                <ap-button
                  [btnType]="'primary'"
                  themeColor="primary"
                  class="ap-button ap-button-primary"
                  (click)="toggleChange(group2, group1, rule)"
                >
                  Select custom value
                </ap-button>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *queryInput="let rule; type: 'string'; let field = field">
          <ng-container
            *ngIf="
              !(
                rule.operator.split('~')[0] === 'Is Empty' ||
                rule.operator.split('~')[0] === 'Is Not Empty'
              )
            "
          >
            <div #group1>
              <br />
              <input
                type="text"
                class="input-control"
                [(ngModel)]="rule.value"
                [placeholder]="'Enter value'"
              />
              <br /><br />
              <div style="width: fit-content">
                <ap-button
                  [btnType]="'primary'"
                  themeColor="primary"
                  style="font-weight: 500"
                  (click)="toggleChange(group1, group2, rule)"
                >
                  Select keyword group
                </ap-button>
              </div>
            </div>
            <div #group2 style="display: none">
              <br />

              <select
                name="demo"
                class="input-control"
                id="#"
                [(ngModel)]="rule.value"
              >
                <option value="" disabled selected hidden>
                  Select keyword group
                </option>

                <option
                  *ngFor="let obj of keywordgroup"
                  [ngValue]="obj.id + '~' + obj.name"
                >
                  {{ obj.name }}
                </option>
              </select>
              <br /><br />
              <div style="width: fit-content">
                <ap-button
                  [btnType]="'primary'"
                  themeColor="primary"
                  (click)="toggleChange(group2, group1, rule)"
                  style="padding: 8px 12px !important; width: 180px"
                >
                  Select custom value
                </ap-button>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *queryInput="let rule; type: 'date'; let field = field">
          <ng-container
            *ngIf="
              !(
                rule.operator.split('~')[0] === 'Is Empty' ||
                rule.operator.split('~')[0] === 'Is Not Empty'
              )
            "
          >
            <div #group1>
              <br />
              <input
                type="date"
                class="input-control"
                [(ngModel)]="rule.value"
                [placeholder]="'Enter value'"
              />
              <br /><br />
              <div style="width: fit-content">
                <ap-button
                  [btnType]="'primary'"
                  themeColor="primary"
                  (click)="toggleChange(group1, group2, rule)"
                  style="font-weight: 500"
                >
                  Select keyword group
                </ap-button>
              </div>
            </div>
            <div #group2 style="display: none">
              <br />
              <select
                name="demo"
                class="input-control"
                id="#"
                [(ngModel)]="rule.value"
              >
                <option value="" disabled selected hidden>
                  Select keyword group
                </option>
                <option
                  *ngFor="let obj of keywordgroup"
                  [ngValue]="obj.id + '~' + obj.name"
                >
                  {{ obj.name }}
                </option>
              </select>
              <br /><br />
              <div style="width: fit-content">
                <ap-button
                  [btnType]="'primary'"
                  themeColor="primary"
                  class="ap-button ap-button-primary"
                  (click)="toggleChange(group2, group1, rule)"
                >
                  Select custom value
                </ap-button>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container
          *queryInput="let rule; type: 'decimal'; let field = field"
        >
          <ng-container
            *ngIf="
              !(
                rule.operator.split('~')[0] === 'Is Empty' ||
                rule.operator.split('~')[0] === 'Is Not Empty'
              )
            "
          >
            <div #group1>
              <br />
              <input
                type="number"
                class="input-control"
                [(ngModel)]="rule.value"
                [placeholder]="'Enter value'"
              />
              <br /><br />
              <div style="width: fit-content">
                <ap-button
                  [btnType]="'primary'"
                  themeColor="primary"
                  (click)="toggleChange(group1, group2, rule)"
                  style="font-weight: 500"
                >
                  Select keyword group
                </ap-button>
              </div>
            </div>
            <div #group2 style="display: none">
              <br />
              <kendo-combobox
                [data]="keywordgroup"
                [(ngModel)]="selectedkeyword"
                textField="name"
                valueField="id"
              ></kendo-combobox>
              <select
                name="demo"
                class="input-control"
                id="#"
                [(ngModel)]="rule.value"
              >
                <option value="" disabled selected hidden>
                  Select keyword group
                </option>
                <option
                  *ngFor="let obj of keywordgroup"
                  [ngValue]="obj.id + '~' + obj.name"
                >
                  {{ obj.name }}
                </option>
              </select>
              <br /><br />

              <div style="width: fit-content">
                <ap-button
                  [btnType]="'primary'"
                  themeColor="primary"
                  class="ap-button ap-button-primary"
                  (click)="toggleChange(group2, group1, rule)"
                >
                  Select custom value
                </ap-button>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <!-- </ng-container> -->
      </query-builder>
    </div>
    <div
      style="
        width: 38%;
        border: 1px solid #d9d9d9;
        margin-left: 25px;
        padding: 12px;
      "
    >
      <!-- <mat-card-title><b> View Expression </b></mat-card-title> -->

      <br />
      <div class="expression" [innerHTML]="query | transformexpression"></div>
    </div>
  </div>

  <div class="clickToActions" style="padding: 10px 5px">
    &nbsp;&nbsp;
    <ap-button
      kendoButton
      [btnType]="'secondary'"
      [label]="'Cancel'"
      (onClick)="closeButtonClick()"
      class="cancelButton"
    ></ap-button>
    &nbsp;&nbsp;
    <ap-button
      kendoButton
      [btnType]="'primary'"
      [label]="'Save'"
      (onClick)="saveExpression()"
      class="okButton"
    ></ap-button>
  </div>
</div>
