<!-- <kendo-appbar position="top">
  <kendo-appbar-section>
    <div class="menu">
      <ul>
        <li (click)="togglePanel()" [class.activated]="listindex == 1">
          <span>Show detail</span>
        </li>
        <li (click)="setindex(2)" [class.activated]="listindex == 2">
          <span>CR+</span>
        </li>
        <li (click)="setindex(3)" [class.activated]="listindex == 3">
          <span>Tax Account</span>
        </li>
        
        <kendo-menu (select)="handleComparartiveAnalysis($event)">
          <kendo-menu-item text="Comparative Analysis">
            <kendo-menu-item
              text="Options"
              [cssStyle]="{ width: '150px' }"
            ></kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
        <kendo-menu (select)="handleTaxOutcome($event)">
          <kendo-menu-item text="Tax Outcome">
            <kendo-menu-item
              text="Toggle"
              [cssStyle]="{ width: '120px' }"
            ></kendo-menu-item>
            <kendo-menu-item text="Options"></kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>

        <li (click)="setindex(6)" [class.activated]="listindex == 6">
          <span>Master Data</span>
        </li>
        <li (click)="setindex(7)" [class.activated]="listindex == 7">
          <span>GL Account</span>
        </li>
        <li (click)="setindex(8)" [class.activated]="listindex == 8">
          <span>Habitat Exceptions</span>
        </li>
        <li (click)="Legend(); setindex(5)" [class.activated]="listindex == 9">
          <span>Legend</span>
        </li>
      </ul>
    </div>
  </kendo-appbar-section>
3
  <kendo-appbar-spacer></kendo-appbar-spacer>
</kendo-appbar> -->
<kendo-splitter>
  <kendo-splitter-pane style="width: 50%">
    <div id="mainContent" class="container-fluid" style="overflow: auto">
      <!-- <div *ngFor="let data of data">
                {{data.name}}
                </div> -->

      <div #target class="target">
        <kendo-chunkprogressbar
          *ngIf="isLoading"
          [chunkCount]="10"
          [min]="-10"
          [max]="10"
          [indeterminate]="true"
          [value]="30"
          style="width: 100%"
        ></kendo-chunkprogressbar>
        <kendo-treelist
          [data]="rootData"
          [fetchChildren]="fetchChildren"
          [hasChildren]="hasChildren"
          [selectable]="true"
          (selectionChange)="rowSelect($event)"
          kendoTreeListExpandable
          kendoTreeListSelectable
          [resizable]="IsResizable"
          (expand)="fitColumns(); onExpand($event)"
          (collapse)="fitColumns(); onCollapse($event)"
          (dataStateChange)="fitColumns()"
          [isExpanded]="isExpanded"
          [rowClass]="rowCallback"
          [isSelected]="isSelected"
          class="autoTreeHeight"
        >
          <ng-template kendoTreeListToolbarTemplate>
            <ap-search
              class="searchContainerWidth"
              [placeholder]="'Search here'"
              [searchType]="'secondary'"
              style="padding: 0px !important"
              [disabled]="false"
            ></ap-search>
            <button
              kendoButton
              class="actionButton"
              (click)="togglePanel()"
              [class.activated]="listindex == 1"
              kendoTooltip
              title="Show Details"
            >
              <span class="Appkit4-icon icon-news-report-outline"></span>
            </button>
            <button
              kendoButton
              class="actionButton"
              (click)="Legend()"
              kendoTooltip
              title="Legend"
            >
              <span class="Appkit4-icon icon-platform-outline"></span>
            </button>

            <!-- <button
              kendoButton
              class="actionButton"
              (click)="openWindow()"
              kendoTooltip
              title="Open window"
            >
              <span class="Appkit4-icon icon-open-in-new-window-outline"></span>
            </button>
            <button
              kendoButton
              class="refreshButton"
              (click)="refresh()"
              kendoTooltip
              title="Refresh"
            >
              <span class="Appkit4-icon icon-refresh-outline"></span>
            </button> -->
          </ng-template>
          <kendo-treelist-column
            [expandable]="true"
            [width]="500"
            [style]="{ 'font-size': '13px', height: '47px' }"
          >
            <ng-template kendoTreeListCellTemplate let-dataItem>
              <kendo-icon
                class="Appkit4-icon icon-network-fill"
                *ngIf="dataItem.type === 'BT'"
                style="cursor: pointer; color: #a7452c"
              ></kendo-icon>
              <kendo-icon
                class="Appkit4-icon icon-data-fill"
                style="cursor: pointer; color: #c34c2f"
                *ngIf="dataItem.type === 'LE'"
              >
              </kendo-icon>
              <kendo-icon
                class="Appkit4-icon icon-library-reports-fill"
                style="cursor: pointer; color: #eb8c00"
                *ngIf="dataItem.type === 'AP'"
              ></kendo-icon>
              <kendo-icon
                class="Appkit4-icon icon-folder-opened-fill"
                style="cursor: pointer; color: #d93954"
                *ngIf="dataItem.type === 'AS'"
              ></kendo-icon>
              <kendo-icon
                class="Appkit4-icon icon-gantt-chart-fill"
                style="cursor: pointer; color: #e0301e"
                *ngIf="dataItem.type === 'SA'"
              ></kendo-icon>
              <kendo-icon
                class="Appkit4-icon icon-library-fill"
                style="cursor: pointer; color: #e0301e"
                *ngIf="dataItem.type === 'SS'"
              ></kendo-icon>
              <kendo-icon
                [attr.data-target]="dataItem.id"
                class="Appkit4-icon icon-paper-outline {{ dataItem.id }}"
                style="cursor: pointer; color: #7d7d7d"
                *ngIf="dataItem.type === 'LI'"
              >
              </kendo-icon>
              <kendo-icon
                class="Appkit4-icon icon-link-outline"
                style="cursor: pointer; color: #7d7d7d"
                (click)="getSupplimentaryExcel(dataItem.SupplimentaryID)"
                *ngIf="dataItem.type === 'LI' && dataItem.SupplimentaryID > 0"
              >
              </kendo-icon>
              <kendo-icon
                class="Appkit4-icon icon-notebook-fill"
                style="cursor: pointer; color: #464646"
                *ngIf="dataItem.type === 'CLI'"
              ></kendo-icon>

              <kendo-icon
                class="Appkit4-icon icon-link-outline"
                style="cursor: pointer; color: #464646"
                (click)="getSupplimentaryExcel(dataItem.SupplimentaryID)"
                *ngIf="dataItem.type === 'CLI' && dataItem.SupplimentaryID > 0"
              ></kendo-icon>
              {{ dataItem.name }}
              <kendo-menu #target *ngIf="dataItem.type === 'LE'">
                <kendo-menu-item icon="menu" [cssClass]="'menu-right'">
                  <ng-container *ngFor="let item of LEOPtions">
                    <kendo-menu-item [cssClass]="item.cssClass">
                      <ng-template kendoMenuItemTemplate>
                        <span (click)="onSelect(item, dataItem)">{{
                          item.text
                        }}</span>
                      </ng-template>
                    </kendo-menu-item>
                  </ng-container>
                </kendo-menu-item>
              </kendo-menu>
              <kendo-menu *ngIf="dataItem.type === 'AP'">
                <kendo-menu-item icon="menu" [cssClass]="'menu-right'">
                  <ng-container *ngFor="let item of APoptions">
                    <kendo-menu-item [cssClass]="item.cssClass">
                      <ng-template kendoMenuItemTemplate>
                        <span (click)="onSelect(item, dataItem)">{{
                          item.text
                        }}</span>
                      </ng-template>
                    </kendo-menu-item>
                  </ng-container>
                </kendo-menu-item>
              </kendo-menu>
              <kendo-menu *ngIf="dataItem.type === 'SS'">
                <kendo-menu-item icon="menu" [cssClass]="'menu-right'">
                  <ng-container *ngFor="let item of SSoptions">
                    <kendo-menu-item [cssClass]="item.cssClass">
                      <ng-template kendoMenuItemTemplate>
                        <span (click)="onSelect(item, dataItem)">{{
                          item.text
                        }}</span>
                      </ng-template>
                    </kendo-menu-item>
                  </ng-container>
                </kendo-menu-item>
              </kendo-menu>
              <kendo-menu
                *ngIf="
                  dataItem.type === 'AS' &&
                  dataItem.parentStatementAnalysis != true &&
                  dataItem.isSummary != true &&
                  dataItem.name != 'Unused Line Item'
                "
              >
                <kendo-menu-item icon="menu" [cssClass]="'menu-right'">
                  <ng-container *ngFor="let item of ASoptions">
                    <kendo-menu-item [cssClass]="item.cssClass">
                      <ng-template kendoMenuItemTemplate>
                        <span (click)="onSelect(item, dataItem)">{{
                          item.text
                        }}</span>
                      </ng-template>
                    </kendo-menu-item>
                  </ng-container>
                </kendo-menu-item>
              </kendo-menu>
              <kendo-menu
                *ngIf="
                  dataItem.type === 'AS' &&
                  dataItem.name === 'Unused Line Item' &&
                  dataItem.parentStatementAnalysis != true &&
                  dataItem.isSummary != true
                "
              >
              </kendo-menu>
              <kendo-menu
                *ngIf="
                  dataItem.type === 'SA' &&
                  dataItem.summarySubAnalysis != true &&
                  dataItem.isSummary != true
                "
              >
                <kendo-menu-item icon="menu" [cssClass]="'menu-right'">
                  <ng-container *ngFor="let item of SAoptions">
                    <kendo-menu-item [cssClass]="item.cssClass">
                      <ng-template kendoMenuItemTemplate>
                        <span (click)="onSelect(item, dataItem)">{{
                          item.text
                        }}</span>
                      </ng-template>
                    </kendo-menu-item>
                  </ng-container>
                </kendo-menu-item>
              </kendo-menu>
              <kendo-menu
                *ngIf="
                  dataItem.type === 'LI' &&
                  dataItem.isSummary != true &&
                  dataItem.summaryLineItem != true &&
                  dataItem.Satementid != 0
                "
              >
                <kendo-menu-item icon="menu">
                  <ng-container *ngFor="let item of SLIoptions">
                    <kendo-menu-item
                      [disabled]="
                        (item.text == 'Add attachment' &&
                          dataItem.IsAddAttchment == false) ||
                        (item.text == 'Remove attachment' &&
                          dataItem.SupplimentaryID == 0)
                      "
                    >
                      <ng-template kendoMenuItemTemplate>
                        <span (click)="onSelect(item, dataItem)">{{
                          item.text
                        }}</span>
                      </ng-template>
                    </kendo-menu-item>
                  </ng-container>
                </kendo-menu-item>
              </kendo-menu>
              <kendo-menu
                *ngIf="
                  dataItem.type === 'CLI' &&
                  dataItem.isSummary != true &&
                  dataItem.summaryLineItem != true &&
                  dataItem.Satementid != 0
                "
              >
                <kendo-menu-item icon="menu">
                  <ng-container *ngFor="let item of CLIoptions">
                    <kendo-menu-item
                      [disabled]="
                        (item.text == 'Add attachment' &&
                          dataItem.IsAddAttchment == false) ||
                        (item.text == 'Remove attachment' &&
                          dataItem.SupplimentaryID == 0)
                      "
                    >
                      <ng-template kendoMenuItemTemplate>
                        <span (click)="onSelect(item, dataItem)">{{
                          item.text
                        }}</span>
                      </ng-template>
                    </kendo-menu-item>
                  </ng-container>
                </kendo-menu-item>
              </kendo-menu>
              <kendo-menu
                *ngIf="
                  (dataItem.type === 'LI' || dataItem.type === 'CLI') &&
                  dataItem.isSummary != true &&
                  dataItem.Satementid == 0
                "
              >
                <kendo-menu-item icon="menu">
                  <ng-container *ngFor="let item of ULI_Childoptions">
                    <kendo-menu-item
                      [disabled]="
                        (item.text == 'Add attachment' &&
                          dataItem.IsAddAttchment == false) ||
                        (item.text == 'Remove attachment' &&
                          dataItem.SupplimentaryID == 0)
                      "
                    >
                      <ng-template kendoMenuItemTemplate>
                        <span (click)="onSelect(item, dataItem)">{{
                          item.text
                        }}</span>
                      </ng-template>
                    </kendo-menu-item>
                  </ng-container>
                </kendo-menu-item>
              </kendo-menu>
              <kendo-menu
                *ngIf="
                  dataItem.type === 'SA' &&
                  dataItem.summarySubAnalysis === true &&
                  dataItem.isSummary != true
                "
              >
                <kendo-menu-item icon="menu">
                  <ng-container *ngFor="let item of SS_SAoptions">
                    <kendo-menu-item>
                      <ng-template kendoMenuItemTemplate>
                        <span (click)="onSelect(item, dataItem)">{{
                          item.text
                        }}</span>
                      </ng-template>
                    </kendo-menu-item>
                  </ng-container>
                </kendo-menu-item>
              </kendo-menu>
              <kendo-menu
                *ngIf="
                  dataItem.type === 'AS' &&
                  dataItem.parentStatementAnalysis === true &&
                  dataItem.isSummary != true
                "
              >
                <kendo-menu-item icon="menu">
                  <ng-container *ngFor="let item of SS_ASoptions">
                    <kendo-menu-item>
                      <ng-template kendoMenuItemTemplate>
                        <span (click)="onSelect(item, dataItem)">{{
                          item.text
                        }}</span>
                      </ng-template>
                    </kendo-menu-item>
                  </ng-container>
                </kendo-menu-item>
              </kendo-menu>
              <kendo-menu
                *ngIf="
                  (dataItem.type === 'LI' || dataItem.type === 'CLI') &&
                  dataItem.isSummary === true
                "
              >
                <kendo-menu-item icon="menu">
                  <ng-container *ngFor="let item of SS_LIOptions">
                    <kendo-menu-item
                      [disabled]="
                        (item.text == 'Add attachment' &&
                          dataItem.IsAddAttchment == false) ||
                        (item.text == 'Remove attachment' &&
                          dataItem.SupplimentaryID == 0)
                      "
                    >
                      <ng-template kendoMenuItemTemplate>
                        <span (click)="onSelect(item, dataItem)">{{
                          item.text
                        }}</span>
                      </ng-template>
                    </kendo-menu-item>
                  </ng-container>
                </kendo-menu-item>
              </kendo-menu>
              <!-- <kendo-menu  [openOnClick]="true" *ngIf="(dataItem.type==='LI' || dataItem.type==='CLI') && dataItem.isSummary===true">
                                        <kendo-menu-item icon="menu" >
                                          <ng-container *ngFor="let item of SS_LEoptions">
                                            <kendo-menu-item >
                                              <ng-template  kendoMenuItemTemplate>
                                                <span (click)="onSelect(item,dataItem)">{{item.text}}</span>
                                              </ng-template>
                                            </kendo-menu-item>
                                          </ng-container>
                                        </kendo-menu-item>
                                        </kendo-menu> -->
              <!-- <kendo-menu  [openOnClick]="true" *ngIf="dataItem.type==='SA' && dataItem.isSummary===true">
                                          <kendo-menu-item icon="menu" >
                                            <ng-container *ngFor="let item of SS_Childoptions">
                                              <kendo-menu-item >
                                                <ng-template  kendoMenuItemTemplate>
                                                  <span (click)="onSelect(item,dataItem)">{{item.text}}</span>
                                                </ng-template>
                                              </kendo-menu-item>
                                            </ng-container>
                                          </kendo-menu-item>
                                          </kendo-menu> -->
              <!-- <kendo-menu  [openOnClick]="true" *ngIf="dataItem.type==='AS' && dataItem.isSummary===true">
                                            <kendo-menu-item icon="menu" >
                                              <ng-container *ngFor="let item of SS_Childoptions">
                                                <kendo-menu-item >
                                                  <ng-template  kendoMenuItemTemplate>
                                                    <span (click)="onSelect(item,dataItem)">{{item.text}}</span>
                                                  </ng-template>
                                                </kendo-menu-item>
                                              </ng-container>
                                            </kendo-menu-item>
                                            </kendo-menu> -->
            </ng-template>
          </kendo-treelist-column>
          <!-- <kendo-treelist-column-group title="RollingAmountData"  [style]="{'overflow':'scroll'}"> -->
          <kendo-treelist-column
            [width]="500"
            [style]="{ 'text-align': 'left', height: '47px' }"
          >
            <ng-template kendoTreeListCellTemplate let-dataItem>
              <!-- {{dataItem.RollingAmount[0].year}} -->
              <!--  -->
              <div *ngFor="let i of dataItem.RollingAmountData; let k = index">
                <table class="table">
                  <tr *ngIf="dataItem.firstItem === true">
                    <th style="text-align: right; padding: 8px 0px">
                      {{ i.year }}
                    </th>
                  </tr>
                  <tr
                    *ngIf="
                      i.RollingAmount > 0 ||
                      i.RollingAmount == 0 ||
                      i.RollingAmount < 0
                    "
                  >
                    <!-- <td style="text-align: right; padding: 0px"> -->
                    <td
                      [style]="
                        AddColorFormat(
                          roundedPercentage(
                            i.RollingAmount,
                            dataItem.RollingAmountData[0].RollingAmount,
                            k
                          ) | tonumber
                        )
                      "
                    >
                      {{
                        roundedPercentage(
                          i.RollingAmount,
                          dataItem.RollingAmountData[0].RollingAmount,
                          k
                        ) | tonumber
                      }}
                    </td>
                  </tr>
                  <!-- <tr *ngIf="i.RollingAmount < 0">
                    <td
                      [style]="
                        reportType == 'Percentage' && k != 0
                          ? 'text-align: right'
                          : 'color: red; text-align: right; padding: 0px'
                      "
                    >
                      {{
                        roundedPercentage(
                          i.RollingAmount,
                          dataItem.RollingAmountData[0].RollingAmount,
                          k
                        )| tonumber 
                      }}
                    </td>
                  </tr> -->
                </table>
              </div>
            </ng-template>
          </kendo-treelist-column>
          <!-- </kendo-treelist-column-group> -->
        </kendo-treelist>
      </div>
    </div>
  </kendo-splitter-pane>

  <kendo-splitter-pane *ngIf="displayRight" style="width: 50%">
    <ap-tabset [type]="'filled'" [(activeIndex)]="activeIndex">
      <ap-tab
        *ngFor="let tab of tabs; let i = index"
        [label]="tab.label"
        [icon]="tab.icon"
        [disabled]="tab.disabled"
      >
        <!-- custom content here -->
        <!-- <kendo-grid [data]='analysisStatementData' scrollable="none">                
            </kendo-grid> -->

        <app-show-gl-accounts
          *ngIf="tab.label === 'GL Accounts' && activeIndex === 1"
          [selectedRow]="selectedRow"
        ></app-show-gl-accounts>

        <app-analysis-statement
          *ngIf="tab.label === 'Analysis statement' && activeIndex === 0"
          [selectedRow]="selectedRow"
        ></app-analysis-statement>
        <app-show-transactions
          *ngIf="tab.label === 'Transactions' && activeIndex === 2"
          [selectedRow]="selectedRow"
        ></app-show-transactions>

        <!--<div class="buttonalignright">
          <button kendoButton (click)="openWindow()">
            <span
              class="Appkit4-icon icon-open-in-new-window-outline"
            ></span></button
          >&nbsp;
          <button kendoButton>
            <span class="Appkit4-icon icon-search-outline"></span>
          </button>
          &nbsp;
          <button kendoButton>
            <span class="Appkit4-icon icon-upload-light-outline"></span>
          </button>
          &nbsp;
        </div> -->
      </ap-tab>
    </ap-tabset>
  </kendo-splitter-pane>
</kendo-splitter>
<div kendoWindowContainer></div>
