import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { TaxaccountserviceService } from 'src/app/common/services/taxaccounts/taxaccountservice.service';
import { LineitemsComponent } from 'src/app/lineitems/lineitems.component';
import { TaxaccountsgridpopupComponent } from 'src/app/taxaccounts/taxaccountsgridpopup/taxaccountsgridpopup.component';

@Component({
  selector: 'app-analysis-edit-taxaccount',
  templateUrl: './analysis-edit-taxaccount.component.html',
  styleUrls: ['./analysis-edit-taxaccount.component.scss'],
})
export class AnalysisEditTaxaccountComponent implements OnInit {
  @Input() public nameDesc: any;
  @Input() public gridBusinessType: any;
  @Input() public editTaxAccountbyCode: any;
  @Input() public isCreateFromLI: any;
  @Input() public LICreateTaxData: any;
  @Input() public BusinessType: any;
  @Input() public nodedata: any;

  public actionsLayout: ActionsLayout = 'normal';
  taxAccountForm: FormGroup;
  isEdit: boolean = false;
  parentTaxAccLabel: string = 'Parent tax account';
  editDetails: any;
  parentTaxAccountList: any[] = [];
  AccountClassDescriptionList: any;
  lineItemList: any;
  STList: any;
  TaxOutcomeList: any;
  dropdownIndicator: any[] = [
    { label: 'B', value: 'B' },
    { label: 'P', value: 'P' },
  ];
  BusinessTypeId: any;
  LICreateTax: any;
  @Input() TaxAccountCodeId: any;
  @Input() TaxAccountCode: any;
  BusinessDesc: any;
  AccountingAreaDescriptionListDB: any;
  AccountingAreaDescriptionList: any;
  constructor(
    public dialog: DialogRef,
    private router: Router,
    private cookieService: CookieService,
    private fb: FormBuilder,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private dialogService: DialogService,
    private globalService: GlobalService,
    private taxAccountService: TaxaccountserviceService
  ) {
    this.taxAccountForm = this.fb.group({
      taxAccount: [''],
      shortDesc: ['', Validators.required],
      longDesc: [''],
      indicator: [{ label: 'P', value: 'P' }],
      parentTaxAccount: [''],
      accountClass: [''],
      lineItem: [''],
      showAll: [false],
      statutoryTreatment: [''],
      taxOutcome: [''],
      highlighted: [false],
      taxSpecific: [false],
    });
  }

  ngOnInit(): void {
    this.BusinessTypeId = this.gridBusinessType;

    this.LICreateTax = this.LICreateTaxData;

    this.getDetailsByCode();
    this.getStatementBlockByStatementID();
  }

  getLineItemlist() {
    let TAlineItempayload = {
      AccountingAreaId: 0,
      AccountClassId: 0,
      TaxChartOfAccountId: this.gridBusinessType,
      TaxAccountCodeId: 0,
      ShowAll: this.taxAccountForm.getRawValue().showAll ? true : false,
      AccountClass: 'string',
      ParentAccountCodeId: 0,
    };

    if (this.isEdit) {
      TAlineItempayload.TaxAccountCodeId = this.editDetails.TaxAccountCodeId;
      TAlineItempayload.AccountClassId = this.editDetails.AccountClassId;
    }
    if (this.isCreateFromLI) {
      TAlineItempayload.AccountClassId = this.LICreateTaxData.AccountClassId;
    }
    if (this.taxAccountForm.getRawValue().accountClass !== '') {
      if (this.taxAccountForm.getRawValue().accountClass.ID) {
        TAlineItempayload.AccountClassId =
          this.taxAccountForm.getRawValue().accountClass.ID;
      } else
        TAlineItempayload.AccountClassId =
          this.taxAccountForm.getRawValue().accountClass;
    }

    this.globalService
      .getTaxaccountLineitem(TAlineItempayload)
      .then((res: any) => {
        this.lineItemList = JSON.parse(res).slice();
      });
  }
  getDetailsByCode() {
    this.isEdit = true;

    this.taxAccountService
      .getTaxAccountDetailsByCode(this.TaxAccountCodeId)
      .then((res) => {
        this.editDetails = res;

        this.taxAccountForm.controls['taxAccount'].setValue(
          this.editDetails.TaxAccountCode
        );
        this.taxAccountForm.controls['shortDesc'].setValue(
          this.editDetails.ShortDescription
        );
        this.taxAccountForm.controls['longDesc'].setValue(
          this.editDetails.LongDescription
        );
        this.taxAccountForm.controls['indicator'].setValue(
          this.editDetails.BalanceSheetPLIndicator
        );
        this.taxAccountForm.controls['parentTaxAccount'].setValue(
          this.editDetails.fk_ParentTaxAccountCode
        );
        this.taxAccountForm.controls['accountClass'].setValue(
          this.editDetails.AccountClassId
        );
        this.taxAccountForm.controls['lineItem'].setValue(
          this.editDetails.AccountingAreaId
        );
        this.taxAccountForm.controls['showAll'].setValue(
          this.editDetails.ShowAll
        );
        this.taxAccountForm.controls['statutoryTreatment'].setValue(
          this.editDetails.AccountTypeId
        );
        this.taxAccountForm.controls['taxOutcome'].setValue(
          this.editDetails.TaxOutcomeId
        );
        this.taxAccountForm.controls['highlighted'].setValue(
          this.editDetails.Highlighted
        );
        this.taxAccountForm.controls['taxSpecific'].setValue(
          this.editDetails.TaxSpecific
        );
        if (this.editDetails.fk_ParentTaxAccountCode != null) {
          this.taxAccountForm.controls['accountClass'].disable();
        }
        this.getRefData();
        this.getLineItemlist();
        this.IsEditTax();
      });
  }
  getRefData() {
    this.globalService
      .getAllAccountClass()
      .then((res) => {
        this.AccountClassDescriptionList = res.slice();
      })
      .catch((err) => {});

    let ParentDatapayload = {
      AccountingAreaId: 0,
      AccountClassId: 0,
      TaxChartOfAccountId: this.BusinessTypeId,
      TaxAccountCodeId: 0,
      ShowAll: false,
      AccountClass: 'string',
      ParentAccountCodeId: 0,
    };
    if (this.isEdit) {
      ParentDatapayload.TaxAccountCodeId = this.editDetails.TaxAccountCodeId;
    }
    this.globalService
      .getTaxAccountParentData(ParentDatapayload)
      .then((res: any) => {
        this.parentTaxAccountList = JSON.parse(res).slice();
      })
      .catch((err) => {});

    this.globalService
      .getAllTaxOutcomes()
      .then((res: any) => {
        this.TaxOutcomeList = res;
      })
      .catch((err) => {});
    this.globalService
      .getAllAccountTypes()
      .then((res: any) => {
        this.STList = res;
      })
      .catch((err) => {});
  }
  getStatementBlockByStatementID() {
    this.globalService
      .getAllLineItemsByBusinessType(this.BusinessTypeId)
      .then((res) => {
        this.AccountingAreaDescriptionListDB = res[0];
        this.AccountingAreaDescriptionList =
          this.AccountingAreaDescriptionListDB.slice();
      })
      .catch((err) => {});
  }

  IsEditTax() {
    if (this.BusinessTypeId != undefined) {
      this.globalService
        .getAllLineItemsByBusinessType(this.BusinessTypeId.BusinessTypeID)
        .then((res) => {
          this.BusinessTypeId.BusinessTypeID;
          this.AccountingAreaDescriptionListDB = res[0];
          this.AccountingAreaDescriptionList =
            this.AccountingAreaDescriptionListDB.slice();
        })
        .catch((err) => {});
    }
  }

  handleFilterParentTaxaccount(value: any) {
    this.parentTaxAccountList = this.parentTaxAccountList.filter(
      (s: any) =>
        s.shortdescription.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  handleAccountClassChange(value: any) {
    let formValue = this.taxAccountForm.getRawValue();

    let selectedAccountClassValue = value;
    if (selectedAccountClassValue !== undefined) {
      this.taxAccountForm.controls['lineItem'].setValue('');
      let ACpayload = {
        AccountingAreaId: 0,
        AccountClassId: selectedAccountClassValue.ID,
        TaxChartOfAccountId: this.BusinessTypeId,
        TaxAccountCodeId: 0,
        ShowAll: this.taxAccountForm.getRawValue().showAll,
        AccountClass: 'string',
        ParentAccountCodeId: 0,
      };
      this.taxAccountService
        .AccountClassChange(ACpayload)
        .then((res: any) => {
          this.lineItemList = res.slice();
        })
        .catch((err) => {});
    }
  }

  handleParentTaxAccountChange(value: any) {
    let selectedParentTax = value;
    if (selectedParentTax !== '' && selectedParentTax !== null) {
      this.taxAccountForm.controls['accountClass'].setValue(1);
      this.taxAccountForm.controls['accountClass'].disable();
      let parentCodeId;
      if (selectedParentTax == undefined) parentCodeId = 0;
      else parentCodeId = selectedParentTax.TaxAccountCodeId;
      let ParentDatapayload = {
        AccountingAreaId: 0,
        AccountClassId: 0,
        TaxChartOfAccountId: this.BusinessTypeId,
        TaxAccountCodeId: 0,
        ShowAll: this.taxAccountForm.getRawValue().showAll,
        AccountClass: 'string',
        ParentAccountCodeId: parentCodeId,
      };
      this.taxAccountService
        .TaxAccountParentChange(ParentDatapayload)
        .then((res: any) => {
          this.lineItemList = res.Item2.slice();
        })
        .catch((err) => {});
    } else {
      this.taxAccountForm.controls['accountClass'].enable();
    }
  }
  handleLineItemChange(value: any) {
    let selectedLI = value;
    let formValue = this.taxAccountForm.getRawValue();

    if (selectedLI !== '' && selectedLI !== null && selectedLI !== undefined) {
      this.taxAccountForm.controls['accountClass'].setValue(
        selectedLI.AccountClass
      );
      let accValue, accArea;
      if (selectedLI.AccountingArea) {
        this.taxAccountForm.controls['parentTaxAccount'].setValidators([
          Validators.required,
        ]);
        this.taxAccountForm.controls['parentTaxAccount'].markAsTouched();
        this.taxAccountForm.controls['parentTaxAccount'].setErrors({
          incorrect: true,
        });
        this.parentTaxAccLabel = 'Parent tax account*';
        accArea = selectedLI.AccountingArea;
      } else {
        this.taxAccountForm.controls['parentTaxAccount'].setValidators(null);
        this.taxAccountForm.controls['parentTaxAccount'].setErrors(null);
        this.parentTaxAccLabel = 'Parent tax account';
        accArea = selectedLI.AccountingAreaID;
      }
      let LIpayload = {
        AccountingAreaId: accArea,
        AccountClassId: selectedLI.AccountClass,
        TaxChartOfAccountId: this.BusinessTypeId,
        TaxAccountCodeId: 0,
        ShowAll: this.taxAccountForm.getRawValue().showAll,
        AccountClass: 'string',
        ParentAccountCodeId: 0,
      };

      this.taxAccountService
        .LineItemChange(LIpayload)
        .then((res: any) => {
          this.parentTaxAccountList = res;
          if (res.length == 1)
            this.taxAccountForm.controls['parentTaxAccount'].setValue(
              res[0].TaxAccountCodeId
            );
        })
        .catch((err) => {});
    }
  }
  handleSTChange(value: any) {}
  handleTOChange(value: any) {}
  onShowAllChange(value: any) {
    this.getLineItemlist();
  }
  cancelClick() {
    this.dialog.close();
  }

  saveNewTaxAccount() {
    var formObject = this.taxAccountForm.getRawValue();

    let accountClassId;
    if (formObject.accountClass.ID) {
      accountClassId = formObject.accountClass.ID;
    } else {
      accountClassId = formObject.accountClass;
    }
    let parentTaxAccountCodeId = null;

    if (this.isEdit) {
      let indicatorVal, lineItemVal, taxOutcomeVal, statutoryTreatmentVal;
      if (formObject.parentTaxAccount) {
        if (formObject.parentTaxAccount.TaxAccountCodeId)
          parentTaxAccountCodeId = formObject.parentTaxAccount.TaxAccountCodeId;
        else {
          parentTaxAccountCodeId = formObject.parentTaxAccount;
        }
      }
      if (formObject.indicator.value) {
        indicatorVal = formObject.indicator.value;
      } else {
        indicatorVal = formObject.indicator;
      }
      if (formObject.lineItem.ID) {
        lineItemVal = formObject.lineItem.ID;
      } else {
        lineItemVal = formObject.lineItem;
      }
      if (formObject.taxOutcome) {
        if (formObject.taxOutcome._id)
          taxOutcomeVal = formObject.taxOutcome._id;
        else taxOutcomeVal = formObject.taxOutcome;
      }
      if (formObject.statutoryTreatment) {
        if (formObject.statutoryTreatment.Id)
          statutoryTreatmentVal = formObject.statutoryTreatment.Id;
        else statutoryTreatmentVal = formObject.statutoryTreatment;
      }
      let TAobject = {
        TaxAccountCodeId: this.TaxAccountCodeId,
        TaxChartOfAccountId: this.BusinessTypeId,
        TaxAccountCode: formObject.taxAccount,
        ShortDescription: formObject.shortDesc,
        LongDescription: formObject.longDesc,
        EntryTypeAuto: false,
        BalanceSheetPLIndicator: indicatorVal,
        Highlighted: formObject.highlighted,
        TaxSpecific: formObject.taxSpecific,
        ShowAll: formObject.showAll,
        AllocationManual: false,
        AllocationRule: false,
        AllocationSuggestion: false,
        AllocationTemp: false,
        SourceBased: false,
        SummaryLevel: false,
        SummaryComparative: false,
        SummaryVariance: false,
        SummaryRelative: false,
        fk_AnalysisTypeTargetTaxAccountCode: null,
        GroupLevel: false,
        AccountingAreaId: lineItemVal,
        TaxOutcomeId: taxOutcomeVal,
        AccountTypeId: statutoryTreatmentVal,
        AccountClassId: accountClassId,
        DataSourceId: 1,
        fk_ParentTaxAccountCode: parentTaxAccountCodeId,
        HelpId: null,
        EntryTypeAutoExplanation: null,
        fk_BaselineTaxAccount: null,
      };
      this.taxAccountService
        .updateTaxAccountCode(TAobject)
        .then((res) => {
          if (res) {
            this.toastr.successToastr(res, 'Success');
            this.displayGrid();
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
            this.displayGrid();
          }
        })
        .catch((err) => {});
    } else {
      if (formObject.parentTaxAccount) {
        parentTaxAccountCodeId = formObject.parentTaxAccount.TaxAccountCodeId;
      }
      let LineItemValue;
      if (formObject.lineItem.ID) {
        LineItemValue = formObject.lineItem.ID;
      } else {
        LineItemValue = formObject.lineItem;
      }
      let TAobject = {
        TaxAccountCodeId: 0,
        TaxChartOfAccountId: this.BusinessTypeId,
        TaxAccountCode: formObject.taxAccount,
        ShortDescription: formObject.shortDesc,
        LongDescription: formObject.longDesc,
        EntryTypeAuto: false,
        BalanceSheetPLIndicator: formObject.indicator.value,
        Highlighted: formObject.highlighted,
        TaxSpecific: formObject.taxSpecific,
        AllocationManual: false,
        AllocationRule: false,
        AllocationSuggestion: false,
        AllocationTemp: false,
        SourceBased: false,
        SummaryLevel: false,
        SummaryComparative: false,
        SummaryVariance: false,
        SummaryRelative: false,
        fk_AnalysisTypeTargetTaxAccountCode: null,
        GroupLevel: false,
        AccountingAreaId: LineItemValue,
        TaxOutcomeId: formObject.taxOutcome._id,
        AccountTypeId: formObject.statutoryTreatment.Id,
        AccountClassId: accountClassId,
        DataSourceId: 1,
        fk_ParentTaxAccountCode: parentTaxAccountCodeId,
        HelpId: null,
        EntryTypeAutoExplanation: null,
        fk_BaselineTaxAccount: null,
      };
      if (this.isCreateFromLI) {
        TAobject.TaxAccountCode = formObject.taxAccount.toString();
      }

      this.globalService
        .insertTaxAccountCode(TAobject)
        .then((res) => {
          if (res) {
            this.toastr.successToastr(res, 'Success');
            if (this.isCreateFromLI) {
            } else {
              this.displayGrid();
            }
          } else {
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
            this.displayGrid();
          }
        })
        .catch((err) => {});
    }
  }

  onHighlightClick(data: any) {}
  onTaxSpecificClick(data: any) {}
  displayGrid() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }
}
