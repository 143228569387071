<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 18px; line-height: 1.1em">
    {{ action[0].toUpperCase() + action.slice(1).toLowerCase() }} tax outcome
  </div>
</kendo-dialog-titlebar>

<div>
  <form class="k-form" [formGroup]="taxOutcomeForm">
    <kendo-formfield>
      <kendo-label
        [for]="ShortDescription"
        text="Short Description*"
      ></kendo-label>
      <input
        formControlName="ShortDescription"
        maxlength="25"
        (keypress)="omit_special_char($event)"
        kendoTextBox
        #ShortDescription
        required
      />
      <kendo-formerror>{{ shortdescriptionErrorMessage }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label
        [for]="LongDescription"
        text="Long Description"
      ></kendo-label>
      <kendo-textarea
        formControlName="LongDescription"
        [maxlength]="longDesLength"
        (keypress)="omit_special_char($event)"
        kendoTextBox
        #LongDescription
      >
      </kendo-textarea>
    </kendo-formfield>
  </form>
</div>

<div class="clickToActions" style="padding: 10px 0px 0px 0px">
  <ap-button
    [btnType]="'secondary'"
    [label]="'Cancel'"
    (click)="cancelClick()"
    class="cancelButton"
    style="width: 100px"
  ></ap-button>
  <ap-button
    [btnType]="'primary'"
    [label]="'Save'"
    themeColor="primary"
    [disabled]="!taxOutcomeForm.valid"
    (click)="saveBusinessTaxOutcome()"
    class="okButton"
    style="margin-right: 6px"
  ></ap-button>
</div>
