import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AllocationRulesService } from 'src/app/common/services/allocationrule/allocation-rules.service';

@Component({
  selector: 'app-delete-expression',
  templateUrl: './delete-expression.component.html',
  styleUrls: ['./delete-expression.component.scss'],
})
export class DeleteExpressionComponent implements OnInit {
  @Input() public delExpressiondata: any;
  constructor(
    public dialog: DialogRef,
    private route: ActivatedRoute,
    private router: Router,
    private allocationRulesService: AllocationRulesService,
    public toastr: ToastrManager
  ) {}

  ngOnInit(): void {}

  deleteExpression() {
    this.allocationRulesService
      .deleteRuleExpression(this.delExpressiondata.RuleId)
      .then((res) => {
        let response = JSON.parse(res);
        if (response.msg === 'Rule expression is deleted successfully') {
          this.toastr.successToastr(response.msg, 'Success');
          this.displayGrid();
        } else if (
          response.msg ===
          'The following rules cannot be deleted as they are currently in use by the system'
        ) {
          // this.warnondelete=true;
          //warning: 'toast-warning'
          this.toastr.warningToastr(
            'Sorry...you cannot delete this Expression as it is in use by Allocation Rules',
            'Warning!'
          );
          this.displayGrid();
        } else {
          this.toastr.errorToastr(response.msg, 'Failed');
          this.displayGrid();
        }
      });
  }
  cancelClick() {
    this.dialog.close();
  }
  displayGrid() {
    this.router.navigate(['/defineExpressions']);
    this.dialog.close();
  }
}
//{"msg":"The following rules cannot be deleted as they are currently in use by the system","automaticAllocationName":["TestCopy","Adv product costs - ALLOWTest"]}
