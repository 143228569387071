<kendo-dialog-titlebar (close)="cancelClick()">
  <div style="font-size: 20px; line-height: 1.3em">Delete Journals</div>
</kendo-dialog-titlebar>
<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"
  ></kendo-chunkprogressbar>
</div>
<div style="display: flex; margin-top: 5px">
  <p>
    <span
      class="Appkit4-icon icon-circle-warning-outline"
      style="font-size: 24px; color: #d04a02"
    ></span>
  </p>
  <p style="margin-left: 8px; font-size: medium">
    Deleting the chosen allocations will remove linked journals completely from
    the system.<br />
    Are you sure you want to continue and delete selected Allocation(s)?
  </p>
</div>
<!-- Click to Actions -->
<div class="clickToActions">
  <ap-button
    class="cancelButton"
    [btnType]="'secondary'"
    [label]="'No'"
    (click)="cancelClick()"
    style="width: 100px"
  ></ap-button>
  <ap-button
    class="okButton"
    [btnType]="'primary'"
    [label]="'Yes'"
    themeColor="primary"
    (click)="delJournal()"
    style="margin-right: 6px"
  ></ap-button>
</div>
