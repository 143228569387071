import { Component, OnInit, Input } from '@angular/core';
import {
  ActionsLayout,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { Router } from '@angular/router';
import { AnalysispackService } from 'src/app/common/services/analysispackservice/analysispack.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BusinessType } from 'src/app/_models/businessType';
import { LegalEntity } from 'src/app/_models/legalEntity';
import { CookieService } from 'ngx-cookie-service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { AnalysisPack } from 'src/app/_models/analysis-pack';
@Component({
  selector: 'app-edit-analysis-pack',
  templateUrl: './edit-analysis-pack.component.html',
  styleUrls: ['./edit-analysis-pack.component.scss'],
})
export class EditAnalysisPackComponent implements OnInit {
  @Input() public nodeData: any;
  isLoading: boolean = false;
  businessTypes: BusinessType[] = [];
  legalEntities: LegalEntity[] = [];
  parentLEdetails: any;
  selectedBusinessType: BusinessType | undefined;
  selectedLegalEntity: LegalEntity | undefined;
  countryName: string = '';
  industryName: string = '';
  packDetails: any;
  editAnalysisPack: FormGroup | any;
  public actionsLayout: ActionsLayout = 'normal';
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dialogService: DialogService,
    public dialog: DialogRef,
    private cookieService: CookieService,
    public toastr: ToastrManager,
    public myGlobalObject: MyGlobalObject,
    private globalService: GlobalService,
    private analysisPackService: AnalysispackService
  ) {
    this.editAnalysisPack = this.fb.group({
      businessType: ['', Validators.required],
      LegalEntity: ['', Validators.required],
      packDescription: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getRefData();
  }

  getRefData() {
    this.globalService
      .getAllBusinessTypes()
      .then(async (res) => {
        this.businessTypes = res.slice();
        await this.getAnalysisPackDetails();
      })
      .catch((err) => {});
  }
  getAnalysisPackDetails() {
    this.isLoading = true;
    this.analysisPackService.getAnalysisPackByID(this.nodeData.id).subscribe(
      (response) => {
        if (response) {
          let temppackdetails = response;
          this.packDetails = temppackdetails[0];

          this.getLegalEntityList(this.packDetails.BusinessTypeId);
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }
  getLegalEntityList(businessTypeId: number): void {
    this.isLoading = true;
    this.analysisPackService
      .getAllLegalEntityByBusinessType(businessTypeId)
      .subscribe(
        (response) => {
          if (response != null) {
            this.legalEntities = response.slice();
            this.editAnalysisPack.controls['businessType'].setValue(
              this.packDetails.BusinessTypeId
            );
            this.editAnalysisPack.controls['LegalEntity'].setValue(
              this.packDetails.LegalEntityId
            );
            this.editAnalysisPack.controls['packDescription'].setValue(
              this.packDetails.StatemenetGroupDesc
            );
            this.editAnalysisPack.controls['businessType'].disable();
            this.editAnalysisPack.controls['LegalEntity'].disable();
            if (this.packDetails.Country)
              this.countryName = this.packDetails.Country;
            if (this.packDetails.Industry)
              this.industryName = this.packDetails.Industry;
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }
  savePack() {
    var formObject = this.editAnalysisPack.getRawValue();
    if (formObject.packDescription == this.packDetails.StatemenetGroupDesc) {
      this.editAnalysisPack.controls['packDescription'].setErrors({
        incorrect: true,
      });
      return;
    }

    let pack = {
      StatementGroupId: this.packDetails.StatemenetGroupId,
      LegalEntityId: this.packDetails.LegalEntityId,
      StatementGroupTypeId: this.packDetails.StatementGroupTypeId,
      Description: formObject.packDescription,
      BusinessTypeId: this.packDetails.BusinessTypeId,
      HelpId: '',
    };

    this.analysisPackService.updateAnalysisPack(pack).subscribe(
      (response: any) => {
        if (response == 'Analysis Pack updated successfully') {
          this.toastr.successToastr(response, 'Success');
          this.displaytreePack();
        } else if (response == 'Analysis Pack Name already exists') {
          this.toastr.errorToastr(response, 'Failed');
          return;
        } else {
          this.toastr.errorToastr(
            'Something went wrong try after sometime',
            'Oops!'
          );
          this.dialog.close();
        }
      },
      (error: any) => {
        console.log(error);
      },
      () => console.log('Success')
    );
  }
  cancelClick() {
    this.dialog.close();
  }
  displaytreePack() {
    this.dialog.close();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/analysisreview']));
  }
}
