import { Component, Input, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Router } from '@angular/router';
import { LineitemmoveService } from 'src/app/common/services/lineitemmoveservice/lineitemmove.service';
import { ToastrManager } from 'ng6-toastr-notifications';
@Component({
  selector: 'app-move-transactions-popup',
  templateUrl: './move-transactions-popup.component.html',
  styleUrls: ['./move-transactions-popup.component.scss'],
})
export class MoveTransactionsPopupComponent implements OnInit {
  @Input() public transactionsList: any;
  @Input() public dropLineItemId: any;
  @Input() public targetLineItemDescription: any;
  public transacIdList: [] = [];
  constructor(
    private router: Router,
    public dialog: DialogRef,
    private lineitemmoveService: LineitemmoveService,
    public toastr: ToastrManager
  ) {}

  ngOnInit(): void {}
  moveProceed() {
    this.transacIdList = this.transactionsList.map((item: any) => {
      return item.TransactionID;
    });
    this.lineitemmoveService
      .moveTransactionsDragDrop(this.dropLineItemId, this.transacIdList)
      .subscribe(
        (response: any) => {
          if (response) {
            this.toastr.successToastr(response);
            this.cancelClick();
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/analysisreview']));
          } else
            this.toastr.errorToastr(
              'Something went wrong try after sometime',
              'Oops!'
            );
          this.cancelClick();
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigate(['/analysisreview']));
        },
        (error: any) => {
          console.log(error);
        },
        () => console.log('Success')
      );
    this.cancelClick();
  }
  cancelClick() {
    this.dialog.close();
  }
}
