import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
@Injectable({
  providedIn: 'root',
})
export class TaxaccountserviceService {
  private selectedClient: any;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private apiHost: string;
  tokenValue: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    this.apifolder = environment.apifolder;
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    //Api urls
    this.apiUrl = {
      getDeleteTaxAccountdata: '/TaxAccountCode/GetDeleteTaxAccountData',
      deleteTaxAccountCode: '/TaxAccountCode/DeleteTaxAccountCode',
      TaxAccountParentChange: '/TaxAccountCode/TaxAccountParentChange',
      LineItemChange: '/TaxAccountCode/LineItemChange',
      AccountClassChange: '/TaxAccountCode/AccountClassChange',
      getTaxAccountByCode: '/TaxAccountCode/GetTaxAccountCodeById',
      UpdateTaxAccountCode: '/TaxAccountCode/UpdateTaxAccountCode',
    };
  }
  //get dropdown data
  TaxAccountParentChange(ParentDatapayload: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.TaxAccountParentChange;
    return this.http
      .post(this.apiHost, ParentDatapayload, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  LineItemChange(ParentDatapayload: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.LineItemChange;
    return this.http
      .post(this.apiHost, ParentDatapayload, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  AccountClassChange(ParentDatapayload: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.AccountClassChange;
    return this.http
      .post(this.apiHost, ParentDatapayload, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  //delete tax account
  getdeleteTaxAccountData(TaxChartAccountCode: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('taxChartAccountId', TaxChartAccountCode);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getDeleteTaxAccountdata;

    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }

  deleteTaxAccountCode(deleteTAData: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.deleteTaxAccountCode;
    return this.http
      .post(this.apiHost, deleteTAData, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
  //edit tax account
  getTaxAccountDetailsByCode(taxAccountCodeId: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams()
      .set('ClientdbName', this.selectedClient.DBName)
      .set('taxAccountCodeId', taxAccountCodeId);

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getTaxAccountByCode;

    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  }
  //update
  updateTaxAccountCode(TAobject: any): Promise<any> {
    let selectedClientVal: any = localStorage.getItem('SELECTEDCLIENT');
    this.selectedClient = JSON.parse(selectedClientVal);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set(
      'ClientdbName',
      this.selectedClient.DBName
    );

    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.UpdateTaxAccountCode;
    return this.http
      .post(this.apiHost, TAobject, {
        headers: headersObj,
        withCredentials: false,
        params: httpParams,
        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {});
  }
}
