<kendo-dialog-titlebar (close)="closeButtonClick()">
  <div
    style="
    font-size: 18px;
    line-height: 1.1em;

    font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) 24px/24px
    var(--unnamed-font-family-helvetica-neue-lt-pro);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--default-color);
    text-align: left;
    font: normal normal medium 24px/24px Helvetica Neue LT Pro;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    ">
    GL account summary
  </div>
</kendo-dialog-titlebar>
<div *ngIf="isLoading">
  <kendo-chunkprogressbar
    [chunkCount]="10"
    [min]="-10"
    [max]="10"
    [indeterminate]="true"
    [value]="30"
    style="width: 100%"></kendo-chunkprogressbar>
</div>

<div class="row">
  <div class="col">
    <kendo-grid
      [data]="GLaccountSummary"
      scrollable="virtual"
      [selectable]="true"
      [sortable]="true"
      [sort]="sort"
      (sortChange)="sortChange($event)"
      [height]="350"
      width="50">
      <kendo-grid-column
        field="AccountNumber"
        title="GL account"
        [width]="20"
        [headerStyle]="{
        'font-weight': 'bold',
        'text-align': 'center',
        color: '#d04a02'
        }"></kendo-grid-column>
      <kendo-grid-column
        field="LongDescription"
        title="Description"
        [width]="40"
        [headerStyle]="{
        'font-weight': 'bold',
        'text-align': 'center',
        color: '#d04a02'
        }"></kendo-grid-column>
      <kendo-grid-column
        field="TotalAmount"
        title="Amount"
        [width]="30"
        [style]="{ 'text-align': 'right' }"
        [headerStyle]="{
        'font-weight': 'bold',
        'text-align': 'right !important',
        color: '#d04a02'
        }">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [style]="ColorFormat(dataItem.TotalAmount)">
            {{
            (dataItem.TotalAmount == null
            ? dataItem.TotalAmount
            : dataItem.TotalAmount
            ) | tonumber
            }}
          </span>
        </ng-template>
        <ng-template kendoGridFooterTemplate>
          Total sum: <span style="float: right;">{{ total["TotalAmount"].sum |
            number:'1.2-2' }}</span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
