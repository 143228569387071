<div class="header">Privacy statement: Transaction Data Assist</div>
<div class="container-fluid privacyContent">
  <div class="row">
    <!-- <div class="col-2"></div> -->
    <div class="col-12">
      <div>
        <div class="sub-header">Introduction</div>
        <p>
          This privacy statement describes why and how PricewaterhouseCoopers
          LLP (“PwC”, “we”, “us”, or “our”) collects and uses personal data in
          connection with Transaction Data Assist (“application”) and provides
          information about your rights as a user of the application (“you”,
          “user” or “individual”).
        </p>
        <p>
          Personal data is any information relating to an identified or
          identifiable living person. This privacy statement applies to personal
          data provided to us, both by you or by others and any personal data
          created in connection with your use of the application. We may use
          personal data provided to us for any of the purposes described in this
          privacy statement or as otherwise stated at the point of collection.
        </p>
        <p>
          When collecting and using personal data, our policy is to be
          transparent about why and how we process personal data. To find out
          more about our specific processing activities in connection with the
          application, please go to the relevant sections of this statement.
        </p>
      </div>
      <div>
        <div class="sub-header">Security</div>
        <p>
          We adhere to internationally recognised security standards. Our
          information security management system relating to client confidential
          data is independently certified as complying with the requirements of
          ISO/IEC 27001: 2013 and ISO/IEC 27701:2019. We have a framework of
          policies, procedures and training in place covering data protection,
          confidentiality and security and regularly review the appropriateness
          of the measures we have in place to keep the data we hold secure.
        </p>
      </div>
      <div>
        <div class="sub-header">Changes to this privacy statement</div>
        <p>
          We recognise that transparency is an ongoing responsibility so we will
          keep this privacy statement under regular review.
        </p>
        <p>This privacy statement was last updated on 11 May 2021.</p>
      </div>
      <div>
        <div class="sub-header">Data controller and contact information</div>
        <p>
          The data controller is PricewaterhouseCoopers LLP (the limited
          partnership registered in England under registration no. OC303525 and
          with its registration address at 1 Embankment Place, London, WC2N
          6RH).
        </p>
        <p>
          If you have any questions about this privacy statement or how and why
          we process personal data, please contact us at:
        </p>
        <address>
          Data Protection Officer<br />
          PricewaterhouseCoopers LLP<br />
          1 Embankment Place<br />
          London<br />
          WC2N 6RH
        </address>
        <p>
          Email:
          <span
            ><a
              href="mailto:UK_privacy_information_management@pwc.com"
              target="_blank"
              >UK_privacy_information_management@pwc.com</a
            ></span
          >
        </p>
      </div>

      <div>
        <div class="sub-header">Collection and use of personal data</div>
        <p>
          We will collect personal data in connection with the application as
          described below.
        </p>
        <p>Personal data provided directly by you:</p>
        <ul>
          <li>Name and business contact details (such as email address)</li>
        </ul>
        <p>
          We use personal data in order to provide access to the Transaction
          Data Assist application. More information about how we process your
          personal data to fulfil an engagement can be found in our main
          <a href="https://www.pwc.co.uk/who-we-are/privacy-statement.html"
            >privacy statement</a
          >.
        </p>
        <p>
          We will only share personal data with others when we are legally
          permitted to do so. When we share data with others, we put contractual
          arrangements and security mechanisms in place to protect the data and
          to comply with our data protection, confidentiality and security
          standards.
        </p>
        <p>
          We share personal data processed in connection with the application as
          follows:
        </p>
        <ul>
          <li>
            <p>
              <b
                >Third party organisations that provide
                applications/functionality, data processing or IT services to
                us</b
              ><br />
              We are part of a global network of firms and in common with other
              professional service providers, we use third parties to help us
              run our business and these third parties may be located in other
              countries. Where the locations of processing are outside of the
              EU, we have the European Commission approved standard contractual
              clauses in place to provide appropriate safeguards for personal
              data transferred outside of the EU. The standard contractual
              clauses are available
              <a
                href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
                >here</a
              >.
            </p>
          </li>
        </ul>
      </div>
      <div>
        <div class="sub-header">Data retention</div>
        <p>
          Personal data of business contacts will be retained in line with our
          retention policy. Personal data may be held for longer periods where
          extended retention periods are required by law or regulation, and/or
          where this may be needed to establish, exercise or defend our legal
          rights.
        </p>
      </div>
      <div>
        <div class="sub-header">
          Individuals’ rights and how to exercise them
        </div>
        <p>
          You have certain rights over your personal data. Data controllers are
          responsible for fulfilling these rights. Where we decide how and why
          personal data is processed, we are a data controller.
        </p>
        <p>
          Individuals’ rights are the right of access to personal data, to
          rectification of personal data, to erasure of personal data / right to
          be forgotten, to restrict processing of personal data, to object to
          processing of personal data, to data portability, the right to
          withdraw consent at any time (where processing is based on consent)
          and the right to lodge a complaint with a supervisory authority.
        </p>
        <p>
          Please see further information about these rights and how to exercise
          them below.
        </p>
      </div>
      <div>
        <div class="sub-header">Right of access</div>
        <p>
          You have the right to obtain confirmation as to whether we process
          personal data about you, receive a copy of your personal data held by
          us as a data controller and obtain certain other information about how
          and why we process your personal data (similar to the information
          provided in this privacy statement).
        </p>
        <p>
          You may exercise this right by emailing us at
          <span
            ><a
              href="mailto:UK_privacy_information_management@pwc.com"
              target="_blank"
              >UK_privacy_information_management@pwc.com</a
            ></span
          >. We will aim to respond to any requests for information promptly,
          and in any event within the legally required time limits.
        </p>
      </div>
      <div>
        <div class="sub-header">Right to rectification</div>
        <p>
          You have the right to request for your personal data to be amended or
          rectified where it is inaccurate (for example, if you change your name
          or address) and to have incomplete personal data completed.
        </p>
        <p>
          To update personal data submitted to us, please email us at
          <span
            ><a
              href="mailto:UK_privacy_information_management@pwc.com"
              target="_blank"
              >UK_privacy_information_management@pwc.com</a
            ></span
          >. When practically possible, once we are informed that any personal
          data processed by us is no longer accurate, we will make updates, as
          appropriate, based on your updated information.
        </p>
      </div>
      <div>
        <div class="sub-header">
          The right to erasure / right to be forgotten
        </div>
        <div>
          You have the right to obtain deletion of your personal data in the
          following cases:
          <ul>
            <li>
              the personal data are no longer necessary in relation to the
              purposes for which they were collected and processed;
            </li>
            <li>
              our lawful basis for processing is consent, you withdraw consent
              and we have no other lawful basis for the processing;
            </li>
            <li>
              our lawful basis for processing is that the processing is
              necessary for a legitimate interest pursued by us, you object to
              our processing and we do not have overriding legitimate grounds;
            </li>
            <li>you object to our processing for direct marketing purposes;</li>
            <li>your personal data have been unlawfully processed; and</li>
            <li>
              your personal data must be erased to comply with a legal
              obligation to which we are subject.
            </li>
          </ul>
          <p>
            To request deletion of your personal data, please email us at
            <span
              ><a
                href="mailto:UK_privacy_information_management@pwc.com"
                target="_blank"
                >UK_privacy_information_management@pwc.com</a
              ></span
            >.
          </p>
        </div>
      </div>
      <div>
        <div class="sub-header">The right to restrict processing</div>
        <div>
          You have the right to restrict our processing of your personal data in
          the following cases:
          <ul>
            <li>
              for a period enabling us to verify the accuracy of the personal
              data where you have contested the accuracy of the personal data
            </li>
            <li>
              your personal data have been unlawfully processed and you request
              restriction of processing instead of deletion;
            </li>
            <li>
              the personal data are no longer necessary in relation to the
              purposes for which they were collected and processed but the
              personal data are required by you to establish, exercise or defend
              legal claims; and
            </li>
            <li>
              for a period enabling us to verify whether the legitimate grounds
              relied on by us override your interests (where you have objected
              to processing based on it being necessary for the pursuit of a
              legitimate interest identified by us).
            </li>
          </ul>
          <p>
            To restrict our processing of your personal data, please email us at
            <span
              ><a
                href="mailto:UK_privacy_information_management@pwc.com"
                target="_blank"
                >UK_privacy_information_management@pwc.com</a
              ></span
            >.
          </p>
        </div>
      </div>
      <div>
        <div class="sub-header">The right to object to processing</div>
        <div>
          You have the right to object to our processing of your personal data
          in the following cases:
          <ul>
            <li>
              our lawful basis for processing is that the processing is
              necessary for a legitimate interest pursued by us; and
            </li>
            <li>our processing for direct marketing purposes.</li>
          </ul>
        </div>
      </div>
      <div>
        <div class="sub-header">Right to data portability</div>
        <p>
          You have a right to receive your personal data provided to us and have
          the right to send the data to another organisation (or ask us to do so
          if technically feasible) where our lawful basis for processing the
          personal data is consent or necessity for the performance of our
          contract with you and the processing is carried out by automated
          means.
        </p>
        <p>
          To exercise your right to data portability, please email us at
          <span
            ><a
              href="mailto:UK_privacy_information_management@pwc.com"
              target="_blank"
              >UK_privacy_information_management@pwc.com</a
            ></span
          >.
        </p>
      </div>
      <div>
        <div class="sub-header">Complaints</div>
        <p>
          We hope that you won’t ever need to, but if you do want to complain
          about our use of personal data, please send an email with the details
          of your complaint to
          <span
            ><a
              href="mailto:UK_privacy_information_management@pwc.com"
              target="_blank"
              >UK_privacy_information_management@pwc.com</a
            ></span
          >. We will look into and respond to any complaints we receive.
        </p>
        <p>
          You also have the right to lodge a complaint with the Information
          Commissioner’s Office (“ICO”), or with the supervisory authority in
          your country of residence, place of work or the country in which an
          alleged infringement of data protection law has occurred within the
          EU. For further information on your rights and how to complain to the
          ICO, please refer to the
          <a href="https://ico.org.uk/your-data-matters/raising-concerns/"
            >ICO website</a
          >.
        </p>
      </div>
    </div>

    <!-- <div class="col-2"></div> -->
  </div>
</div>
